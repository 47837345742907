import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { CampaignStatus } from '../../../../core/enum/campaign-status.enum';
import { Campaign } from '../../../../core/models/campaign/campaign';
import { DefaultBanner, DefaultLogo } from '../../../../core/services/constant';
import { ShareType } from '../../../../core/enum/share-type.enum';

@Component({
  selector: 'app-campaign-card',
  templateUrl: './campaign-card.component.html',
  styleUrls: ['./campaign-card.component.scss'],
})
export class CampaignCardComponent {
  @Input() campaign: Campaign;

  defaultBanner = DefaultBanner;
  defaultLogo = DefaultLogo;

  CampaignStatus: typeof CampaignStatus = CampaignStatus;
  ShareType: typeof ShareType = ShareType;

  calculateProgress(percentage: number): number {
    return percentage > 100 ? 100 : percentage;
  }

  calculateDayLeft(selectedDateTime: Date) {
    const now = moment(new Date());
    const end = moment(selectedDateTime);
    return end.diff(now, 'days');
  }

  calculateRaisedPercentange() {
    return this.campaign.raisedPercentage > 0 && this.campaign.raisedPercentage < 1
      ? 1
      : Math.round(this.campaign.raisedPercentage);
  }
}
