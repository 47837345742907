import { AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import * as prismicH from '@prismicio/helpers';
import { finalize } from 'rxjs/operators';
import { PrismicService } from '../../../core/services/api/prismic.service';
import { SettingService } from '../../../core/services/api/setting.service';
import { ErrorService } from '../../../core/services/error.service';
import { StatusCodeResponseService } from '../../../core/services/status-code-response.service';
import { LearnGroup } from '../../../core/enum/learn-group.enum';
import { LearnHandle } from '../../../core/enum/learn-handle.enum';
import { HeaderService } from '../../../shared/services/header/header.service';
import { GtmDataLayerService } from '../../../core/services/gtm-data-layer.service';
import { ArticleTableContent } from '../../../core/models/learn/article-table-content';

@Component({
  selector: 'app-learn-article',
  templateUrl: './learn-article.component.html',
  styleUrls: ['./learn-article.component.scss'],
})
export class LearnArticleComponent implements OnInit, OnDestroy, AfterViewInit {
  slug: string;
  group: string;
  title: string;
  content: string;
  subMenus: any[] = [];
  category: string;
  userType: string;
  tableOfContent: ArticleTableContent;
  tableOfContentByCategory: object = {};
  isCorrectGroup: boolean;
  notFoundPage: boolean = false;
  isLoading: boolean = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private prismicService: PrismicService,
    private settingService: SettingService,
    private errorService: ErrorService,
    private statusCodeResponseService: StatusCodeResponseService,
    private headerService: HeaderService,
    private _gtmDataLayerService: GtmDataLayerService,
    private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platform: Object,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.notFoundPage = false;
      this.isLoading = true;

      this.group = params.get('group');
      this.slug = params.get('slug');

      if (isPlatformBrowser(PLATFORM_ID) && !Object.keys(LearnHandle).includes(this.group)) {
        this.settingService.notFoundPage.next(true);
        return;
      }

      setTimeout(
        () => {
          this.getArticle();
          this.getSubmenu();
          this.getTableOfContent();
        },
        isPlatformBrowser(PLATFORM_ID) ? 200 : 0,
      );
    });
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platform)) {
      this.settingService.notFoundPage.subscribe((notFoundPage) => {
        this.notFoundPage = notFoundPage;
      });
    }
  }

  ngOnDestroy() {
    this.settingService.notFoundPage.next(false);
  }

  getArticle() {
    const fragment: string = this.activatedRoute.snapshot.fragment;

    this.prismicService
      .getArticle(this.slug, LearnGroup[LearnHandle[this.group]])
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          const newHtmlObj = this.reconstructContent(res.data?.html);

          this.title = res.data?.title;
          this.content = this.renderHtml(newHtmlObj);
          this.category = res.data?._group?.data?.category;
          this.userType = res.data?._group?.data?.user_type;
          this.isCorrectGroup = res.data?._group?.data?._handle?.uid === this.group;

          this.setPageTitle();

          if (fragment) {
            setTimeout(() => {
              this.scrollToAnchor(fragment);
            }, 10);
          }
        },
        (err) => {
          if (err?.error?.code === 'NOTFOUND') {
            this.settingService.notFoundPage.next(true);
            this.statusCodeResponseService.setNotFound();
            return;
          }

          this.errorService.showError(err);
        },
      );
  }

  getSubmenu() {
    this.prismicService.getSubmenu(LearnGroup[LearnHandle[this.group]]).subscribe(
      (res: any) => {
        this.subMenus = this.prismicService.sortArticle(res.results);
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  getTableOfContent() {
    this.prismicService.getHomepage().subscribe(
      (res: any) => {
        this.tableOfContent = res.data;

        Object.keys(res.data).forEach((item) => {
          this.tableOfContentByCategory[item] = res.data[item].reduce((acc, i) => {
            acc[i.category] = {
              ...i,
            };
            return acc;
          }, {});
        });
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  public getCurrentCategoryDetails() {
    if (!(this.tableOfContent && this.userType && this.category)) return null;

    return this.tableOfContentByCategory[this.userType][this.category];
  }

  scrollToAnchor(anchor: string): void {
    const element = document.querySelector('#' + anchor);

    if (element) {
      element.scrollIntoView({ block: 'start' });
    }
  }

  // This function is a workaround to add the id into headings, as well as render <ol> properly
  // without this, ol might reset its count when for-loop
  reconstructContent(contentObj) {
    return contentObj.map((item) => {
      if (item.type.includes('heading') && !item.text.includes(' id=')) {
        const elementTag = 'h' + item.type.replace('heading', '');

        item.text = `
                    <div id="${this.slugify(item.text)}" class="tw-scroll-mt-16">
                        <${elementTag}>${item.text}</${elementTag}>
                    </div>
                `;
      }

      return { ...item };
    });
  }

  renderHtml(element: []) {
    const htmlSerializer = (type, element, text, children) => {
      if (type === 'preformatted' || type.includes('heading')) {
        return text;
      }
      return null;
    };

    return this.sanitizer.bypassSecurityTrustHtml(
      prismicH.asHTML(element, null, htmlSerializer),
    ) as string;
  }

  slugify(text: string): string {
    return text
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]|^-+|-+$/g, '')
      .replace(/[\s_-]+/g, '-');
  }

  setPageTitle() {
    const pageTitle = `${this.title} | pitchIN`;

    this.headerService.setHeaderTag(pageTitle);
    this._gtmDataLayerService.logPageView(this.router.url, pageTitle);
  }
}
