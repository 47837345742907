import { Component, Input, OnChanges } from '@angular/core';
import { Sector } from '../../../core/models/company/sector';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
})
export class TagListComponent implements OnChanges {
  @Input() tags: Sector[] = [];
  @Input() max: number;
  @Input() excludes: string[] = [];

  constructor() {}

  ngOnChanges() {
    if (this.excludes.length > 0) {
      this.tags = this.tags?.filter((tag) => !this.excludes.includes(tag?.name));
    }
  }
}
