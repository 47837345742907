<section class="section section-shaped">
  <div class="container">
    <div class="row">
      <h3 class="col-12 text-center font-weight-extrabold">
        {{ 'mysec.teamMember' | translate }}
      </h3>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-lg-8">
        <form [formGroup]="form" [class.show-form-error]="showFormError">
          <div class="mb-4">
            <div class="input-field" *ngIf="!isLoading">
              <div
                class="profile-upload"
                agmoUploader
                [accept]="accept"
                (selectFiles)="selectFile($event)"
              >
                <div class="profile-image pitchin-icon-profile" *ngIf="!profileImage"></div>
                <img class="profile-image" [src]="profileImage" *ngIf="profileImage" />
                <div class="pitchin-icon pitchin-icon-add"></div>
              </div>
            </div>
            <div class="text-primary text-center pt-3" *ngIf="!profileImage">
              {{ 'common.dragAndDropImage' | translate }}
            </div>
            <div class="text-primary text-center pt-3" *ngIf="profileImage">
              {{ 'common.lookingGood' | translate }}
            </div>
            <agmo-loading *ngIf="isLoading"></agmo-loading>
          </div>
          <div class="form-group">
            <label class="input-label"> {{ 'mysec.fullNameAsPerIC' | translate }}* </label>
            <input
              type="text"
              class="form-control"
              placeholder="{{ 'signUp.fullNamePlaceholder' | translate }}"
              formControlName="name"
              maxLength="200"
            />
          </div>
          <div class="form-group">
            <label class="input-label"> {{ 'mysec.position' | translate }}* </label>
            <input
              type="text"
              class="form-control"
              placeholder="{{ 'mysec.positionPlaceholder' | translate }}"
              formControlName="position"
              maxLength="200"
            />
          </div>
          <div class="form-group">
            <label class="input-label">{{ 'mysec.aboutYourTeamMember' | translate }}</label>
            <textarea
              class="form-control"
              cols="30"
              rows="5"
              formControlName="details"
              placeholder="{{ 'mysec.aboutPlaceholder' | translate }}"
              maxLength="500"
            ></textarea>
          </div>
          <div class="d-flex justify-content-center mt-5">
            <button class="btn btn-primary" (click)="submit()">
              {{ 'common.submit' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
