import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from '../storage.service';
import { AuthService } from './auth.service';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class AuthGuard {
  constructor(
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platform: Object,
    private router: Router,
    private storageService: StorageService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (isPlatformServer(this.platform)) return;

    if (this.authService.isAuthenticated()) {
      if (
        route.data &&
        route.data.roles &&
        route.data.roles.length > 0 &&
        !route.data.roles.includes(this.storageService.role)
      ) {
        this.router.navigateByUrl('/404');
        return false;
      }

      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
