<ng-container *ngIf="campaignHistories.length">
  <section class="section tw-border-t tw-border-divider">
    <div class="container-fluid">
      <h3 class="p-text-h3 tw-mb-5">
        {{ 'campaign.otherEquityCrowdfundingCampaigns' | translate }}
      </h3>

      <ng-container *ngIf="!isLoading; else loading">
        <ul
          class="tw-mb-0 tw-grid tw-list-none tw-gap-5 tw-pl-0 md:tw-grid-cols-2 lg:tw-grid-cols-3"
        >
          <li *ngFor="let item of campaignHistories" class="">
            <a
              [routerLink]="item?.slug ? '/equity/' + item.slug : null"
              class="tw-flex tw-h-full tw-flex-col tw-rounded-10px tw-border tw-border-divider tw-p-8 tw-text-black"
            >
              <div class="tw-flex-auto">
                <div *ngIf="item?.campaignStatus" class="tw-mb-2.5">
                  <campaign-badge [status]="item?.campaignStatus"></campaign-badge>
                </div>

                <div class="tw-text-lg tw-font-bold tw-leading-snug tw-text-black">
                  {{ item.name }}
                </div>
                <div class="tw-text-sm tw-text-black tw-opacity-50">
                  {{ item.endAt | amDateFormat: 'DD MMM yyyy' }}
                </div>
              </div>
              <div class="tw-mt-5 tw-font-medium tw-leading-snug tw-text-black">
                {{ item.bankedInAmount | numberSuffix }}
                <span class="tw-lowercase">{{ 'campaign.raised' | translate }}</span>
              </div>
              <div class="tw-font-medium tw-lowercase tw-leading-snug tw-text-black">
                {{ item.investorCount }}
                <span class="tw-lowercase">{{ 'campaign.investors' | translate }}</span>
              </div>
            </a>
          </li>
        </ul>

        <div class="paging mt-3" *ngIf="total > params.take">
          <ngb-pagination
            [collectionSize]="total"
            [pageSize]="params.take"
            [page]="params.currentPage"
            (pageChange)="onPageChange($event)"
            [maxSize]="5"
            [rotate]="true"
          >
            <ng-template ngbPaginationPrevious><i class="fa fa-chevron-left"></i></ng-template>
            <ng-template ngbPaginationNumber let-page let-pages="pages">{{ page }}</ng-template>
            <ng-template ngbPaginationNext><i class="fa fa-chevron-right"></i></ng-template>
          </ngb-pagination>
        </div>
      </ng-container>
    </div>
  </section>

  <ng-template #loading>
    <agmo-loading></agmo-loading>
  </ng-template>
</ng-container>
