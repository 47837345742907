<section class="section">
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12">
        <h1 class="title font-weight-bold text-dark text-center">
          Personal Data Protection Act Policy
        </h1>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8 offset-lg-2 legal-list">
        <!-- Personal Data Policy -->
        <div class="tw-mb-4">
          <h2 class="tw-uppercase">Personal Data Policy</h2>
          <ol>
            <li>
              <p>
                As a company that deals regularly with the personal data of its customers, the
                Company is committed to upholding data protection and privacy in line with the
                Personal Data Protection Act 2010 (“the Act”).
              </p>
            </li>
            <li>
              <p>
                To comply with the responsibilities under the Act, the Company sets out this policy
                as a framework for its obligations. The end objective is to ensure that all data
                processed by the Company is protected and managed in accordance with the latest
                applicable standards.
              </p>
            </li>
          </ol>
        </div>

        <!-- Scope -->
        <div class="tw-mb-8">
          <h2 class="tw-uppercase">Scope</h2>
          <p>
            This Policy sets out the responsibilities and obligations of the Company, its Officers,
            affiliates and third parties acting on its behalf for the collection, processing, use,
            storage and management of personal data. Any references to the responsibilities of
            “Officers” shall also be interpreted as the responsibilities of all parties specified
            under this Policy.
          </p>
        </div>

        <!-- Defining Personal Data -->
        <div class="tw-mb-8">
          <h2 class="tw-uppercase">Defining Personal Data</h2>
          <ol>
            <li>
              <p>
                Personal Data means information that identifies customers and shall include but not
                be limited to the following information:
              </p>

              <div class="alpha-list">
                <ol>
                  <li>
                    <p>name, job title and company name;</p>
                  </li>
                  <li>
                    <p>
                      age, gender, date of birth, identity card number, passport number, photograph;
                    </p>
                  </li>
                  <li>
                    <p>race, ethnic origin, nationality, physical and mental health;</p>
                  </li>
                  <li>
                    <p>contact information including address, email address and phone number;</p>
                  </li>
                  <li>
                    <p>
                      marital status, details of children, occupation, work history, performance
                      reviews, income, employer, references and any other details of past employers;
                    </p>
                  </li>
                  <li>
                    <p>type of loans applied for in relation to purchase of property;</p>
                  </li>
                  <li>
                    <p>
                      financial and credit card details, bank name and other pertinent banking
                      information, tax file identification number, EPF number, SOCSO number,
                      criminal history; and
                    </p>
                  </li>
                  <li>
                    <p>
                      such other relevant information and data provided by the customer in the
                      course of business relationship.
                    </p>
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <p>
                This definition includes information that has been obtained through any means,
                whether formally or informally, so long as the information is obtained in the course
                of the business relationship.
              </p>
            </li>
            <li>
              <p>
                “Business relationship” includes all of the Company’s business process and
                execution, services, client relationship, management and business development
                activities.
              </p>
            </li>
          </ol>
        </div>

        <!-- Collection of Personal Data -->
        <div class="tw-mb-8">
          <h2 class="tw-uppercase">Collection of Personal Data</h2>
          <ol>
            <li>
              <p>
                Officers must collect personal data only by lawful and fair means that have been
                consented to by the customer, and not in an unreasonably intrusive way.
              </p>
            </li>
            <li>
              <p>
                Officers may obtain personal data about customers from other sources so long as such
                sources are legal and credible. Examples of such sources are:
              </p>
              <div class="alpha-list">
                <ol>
                  <li>
                    <p>
                      official registration forms (either electronic or printed), newsletter
                      subscription or any other promotional events;
                    </p>
                  </li>
                  <li>
                    <p>
                      official request for information forms that are provided to customers by the
                      Officers;
                    </p>
                  </li>
                  <li>
                    <p>
                      any queries, emails or any correspondences that Officers have received from
                      customer requesting for information or making enquiries;
                    </p>
                  </li>
                  <li>
                    <p>
                      any ‘contact us’ forms that customers have submitted through the Company’s
                      Platform;
                    </p>
                  </li>
                  <li>
                    <p>
                      from a government agency or registry or any referrals that Officers may have
                      obtained from existing customers with whom the Company has established a
                      business relationship.
                    </p>
                  </li>
                </ol>
              </div>
            </li>
          </ol>
        </div>

        <!-- Maintaining the integrity of Personal Data -->
        <div class="tw-mb-8">
          <h2 class="tw-uppercase">Maintaining the Integrity of Personal Data</h2>
          <p>
            The Company shall do the following to protect the personal data from any loss, misuse,
            modifications, unauthorized or accidental access or disclosure, alteration or
            destruction:
          </p>

          <ol>
            <li>
              <p>Register all Officers involved in the processing of personal data.</p>
            </li>
            <li>
              <p>
                Terminate the Officer’s access rights in the event of resignation, termination or
                any adjustment to the Officer’s role in relation to personal data processing.
              </p>
            </li>
            <li>
              <p>
                Control and limit the Officer’s access to personal data on a need-to-know basis.
                Officers whose role do not include the necessity of processing personal data shall
                not be given access. Any access shall be recorded in a register and properly
                maintained.
              </p>
            </li>
            <li>
              <p>Provide user ID and password for Officers to access personal data.</p>
            </li>
            <li>
              <p>
                Update the Back up/Recovery System and anti-virus to prevent personal data
                intrusion.
              </p>
            </li>
            <li>
              <p>
                Safeguard the computer systems from malware threats to prevent attacks on personal
                data.
              </p>
            </li>
            <li>
              <p>
                Ensure that all relevant agreements between the Company and its Officers, affliates
                and third parties include confidentiality obligations in respect of personal data.
              </p>
            </li>
          </ol>
        </div>

        <!-- Right to access -->
        <div class="tw-mb-8">
          <h2 class="tw-uppercase">Right to Access & Correct Personal Data</h2>
          <ol>
            <li>
              <p>
                Customers have a statutory right to request for access and correction of personal
                data. The Company in upholding this right shall ensure that the personal data
                collected and processed is accurate, complete, not misleading and kept updated.
              </p>
            </li>
            <li>
              <p>
                The Company shall provide an avenue for the correction of personal data, either by
                way of the Platform or any other means of communication.
              </p>
            </li>
            <li>
              <p>
                The Officers shall update personal data records immediately once a data correction
                notice is received from a customer.
              </p>
            </li>
          </ol>
        </div>

        <!-- Disclosure of Personal Data -->
        <div class="tw-mb-8">
          <h2 class="tw-uppercase">Disclosure of Personal Data</h2>

          <ol>
            <li>
              <p>
                All personal data must generally be kept confidential by the Officers, save for
                disclosure to the following categories of parties on a need-to-know basis:
              </p>

              <div class="alpha-list">
                <ol>
                  <li>
                    <p>
                      Any persons, government agencies, statutory authorities and/or industry
                      regulators where the Company is compelled to disclose Personal Data pursuant
                      to any law or regulation;
                    </p>
                  </li>
                  <li>
                    <p>
                      The Company’s affiliates, partners, third parties, service providers,
                      representatives and agents, to provide related and/or supporting services in
                      connection with the Company’s business activities;
                    </p>
                  </li>
                  <li>
                    <p>
                      The Company’s customer’s auditors, consultants, accountants or any relevant
                      financial institutions or professional advisers;
                    </p>
                  </li>
                  <li>
                    <p>
                      Counterparties or counterparties’ advisers, agents and representatives in any
                      legal and/or commercial transaction, financial institutions for loan
                      documentation processing, relevant state authority and the Inland Revenue
                      Board.
                    </p>
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <p>
                All disclosure requests must be reviewed by the Legal & Compliance team and approved
                by the CEO/CSO before the disclosure can be made.
              </p>
            </li>
          </ol>
        </div>

        <!-- Retention of Personal Data -->
        <div>
          <h2 class="tw-uppercase">Retention of Personal Data</h2>
          <p>
            The Company shall ensure that all personal data is destroyed or permanently deleted if
            it is no longer required for the purpose that is it processed through the following:
          </p>

          <ol>
            <li>
              <p>
                Determine the retention period of all categories of documents or materials
                containing personal data prior to destroying the data.
              </p>
            </li>
            <li>
              <p>
                Maintain a proper record of personal data disposal periodically and make such
                records available for submission when directed by the Personal Data Protection
                Commissioner.
              </p>
            </li>
            <li>
              <p>
                Review and dispose of all unwanted personal data that is in the database of the
                Company periodically.
              </p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</section>
