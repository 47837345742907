import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Attachment } from '../../../core/models/attachment/attachment';
import { PopupService } from '../../../shared/services/popup/popup.service';
import { VideoFileFormat, PdfAndXlsxFileFormat } from '../../../core/services/constant';
import { RaiseFundApplicationActionType } from '../../../core/enum/raise-fund-application-action-type.enum';
import { FundRaiseProcess } from '../../../core/models/raise/fund-raise-process';
import { TabContent } from '../../../core/models/raise/tab-content';
import { ActivatedRoute, Router } from '@angular/router';
import { RaiseFundApplicationProcessModule } from '../../../core/enum/raise-fund-application-process-module.enum';
import { RaiseFundApplicationProcessStatusType } from '../../../core/enum/raise-fund-application-process-status-type.enum';
import { FundRaiseItem } from '../../../core/models/raise/fund-raise-item';
import { finalize } from 'rxjs/operators';
import { RaiseService } from '../../../core/services/api/raise.service';
import { ErrorService } from '../../../core/services/error.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadDocumentComponent } from '../../signup/documents/upload-document/upload-document.component';
import { BlockUiService } from '../../../shared/services/blockUi/block-ui.service';
import { FileValidationService } from '../../../core/services/file-validation.service';
import { FileUploadOption } from '../../../core/models/attachment/file-upload-option';
import { DocumentRequirementStatus } from '../../../core/enum/document-requirement-status.enum';

@Component({
  selector: 'app-process-list',
  templateUrl: './process-list.component.html',
  styleUrls: ['./process-list.component.scss'],
})
export class ProcessListComponent implements OnInit {
  @Input() fundRaiseProcess: FundRaiseProcess;
  @Input() tabContent: TabContent;
  @Input() fundRaiseApplicationId: number;
  @Input() campaignCreated: boolean;
  @Output() next = new EventEmitter();
  @Output() refresh = new EventEmitter();

  isLoading: boolean = false;

  RaiseFundApplicationProcessModule: typeof RaiseFundApplicationProcessModule =
    RaiseFundApplicationProcessModule;
  RaiseFundApplicationActionType: typeof RaiseFundApplicationActionType =
    RaiseFundApplicationActionType;
  RaiseFundApplicationProcessStatusType: typeof RaiseFundApplicationProcessStatusType =
    RaiseFundApplicationProcessStatusType;

  // Group by status
  requiredForPreLiveItems: FundRaiseItem[] = [];
  requiredForLiveItems: FundRaiseItem[] = [];
  optionalItems: FundRaiseItem[] = [];
  noRequirementItems: FundRaiseItem[] = [];

  constructor(
    private popupService: PopupService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private raiseService: RaiseService,
    private sanitizer: DomSanitizer,
    private errorService: ErrorService,
    private modalService: NgbModal,
    private blockUiService: BlockUiService,
    private fileValidationService: FileValidationService,
  ) {}

  ngOnInit(): void {
    this.splitStatutoryForms();
    this.groupByStatus();
  }

  groupByStatus() {
    this.fundRaiseProcess.items.forEach((item) => {
      if (item.isOptional) {
        this.optionalItems.push(item);
      } else if (item.documentRequirementStatus == DocumentRequirementStatus.RFPL) {
        this.requiredForPreLiveItems.push(this.filterSubItems(item, false));
      } else if (item.documentRequirementStatus == DocumentRequirementStatus.RFL) {
        this.requiredForLiveItems.push(this.filterSubItems(item, false));
      } else {
        this.noRequirementItems.push(item);
      }
    });
  }

  filterSubItems(item: FundRaiseItem, getIsOptional: boolean) {
    return {
      ...item,
      subItems: item.subItems.filter((subItem) => subItem.isOptional === getIsOptional),
    };
  }

  // PLT-1302 some child items in the statutory forms are optional, then should remain in the optional section
  // Only those that are required should be moved to the required section
  splitStatutoryForms() {
    const fundRaiseProcessCopy = JSON.parse(JSON.stringify(this.fundRaiseProcess));
    const statutoryForm = fundRaiseProcessCopy.items.find((item) => item.id === 92);

    if (statutoryForm === undefined || statutoryForm?.isOptional) return;

    // Create another parent for the optional items
    const duplicateStatutoryForm = {
      ...statutoryForm,
      isOptional: true,
      subItems: statutoryForm?.subItems.filter((subItem) => subItem.isOptional),
    };

    fundRaiseProcessCopy.items.push(duplicateStatutoryForm);

    this.fundRaiseProcess = fundRaiseProcessCopy;
  }

  async selectFile(file: File, item: FundRaiseItem) {
    let uploadOption: FileUploadOption = {
      fileType: PdfAndXlsxFileFormat,
      errorMsg: 'common.onlyPdfAndXlasxAccepted',
    };

    if (item.raiseFundApplicationActionType == RaiseFundApplicationActionType.UploadCampaignVideo) {
      uploadOption = {
        fileType: VideoFileFormat,
        errorMsg: 'common.onlyVideoAccepted',
      };
    }

    if (await this.fileValidationService.validFile(file, uploadOption)) {
      item.attachment = file;
      item.attachmentUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
      this.uploadAttachment(item, item.id);
    }
  }

  uploadAttachment(item: FundRaiseItem, stepId: number, documentName?: string) {
    this.blockUiService.open();

    this.raiseService
      .uploadAttachment(item.attachment, this.fundRaiseApplicationId, stepId, documentName)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        (data) => {
          item.attachmentUrl = data.url;
          this.refresh.emit();
        },
        (err) => {
          this.errorService.showError(err);
          this.deleteAttachment(stepId);
        },
      );
  }

  removeAttachment(document: Attachment) {
    document.file = null;
    this.refresh.emit();
  }

  viewDetails(url: string) {
    window.open(url);
  }

  nextStep() {
    if (
      this.tabContent.tab == RaiseFundApplicationProcessModule.OfferCreation &&
      !this.campaignCreated
    ) {
      this.router.navigate(['/raise/new-campaign']);
    }

    this.next.emit();
  }

  getAcceptType(raiseFundApplicationActionType: RaiseFundApplicationActionType) {
    return raiseFundApplicationActionType == RaiseFundApplicationActionType.UploadDocument
      ? PdfAndXlsxFileFormat.toString()
      : VideoFileFormat.toString();
  }

  makePayment() {
    this.router.navigate(['make-payment', this.fundRaiseApplicationId], {
      relativeTo: this.activatedRoute,
    });
  }

  uploadAdditionalDocument(item: FundRaiseItem) {
    const option: any = {
      size: 'md',
      centered: true,
      windowClass: 'modal-550',
      backdropClass: 'backdrop-popup',
    };

    let document = {} as Attachment;
    const modalRef = this.modalService.open(UploadDocumentComponent, option);
    modalRef.componentInstance.document = document;
    modalRef.componentInstance.returnFile = true;
    modalRef.componentInstance.fileTypes = PdfAndXlsxFileFormat;
    modalRef.result.then(
      (document: Attachment) => {
        item.attachment = document.file;
        this.uploadAttachment(item, item.id, document.fileName);
      },
      () => {},
    );
  }

  removeAttachmentByAttachmentId(attachmentId: number, parentId: number) {
    this.popupService.confirm('company.confirmDeleteDocument').then(
      () => {
        this.blockUiService.open();

        this.raiseService
          .deleteAttachmentByAttachmentId(this.fundRaiseApplicationId, parentId, attachmentId)
          .pipe(finalize(() => this.blockUiService.close()))
          .subscribe(
            (data) => {
              this.refresh.emit();
            },
            (err) => {
              this.errorService.showError(err);
            },
          );
      },
      () => {},
    );
  }

  deleteAttachment(stepId: number) {
    this.blockUiService.open();

    this.raiseService
      .deleteAttachment(this.fundRaiseApplicationId, stepId)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        (data) => {
          this.refresh.emit();
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }
}
