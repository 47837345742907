import { Component, NgZone, ViewChild, ViewEncapsulation } from '@angular/core';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
@Component({
  selector: 'app-partner-testimonial',
  templateUrl: './partner-testimonial.component.html',
  styleUrls: ['./partner-testimonial.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PartnerTestimonialComponent {
  constructor(private readonly ngZone: NgZone) {}

  selectedIndex: number = 0;
  config: SwiperOptions = {
    autoHeight: true,
    autoplay: true,
  };

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  setIndex(index: number) {
    this.selectedIndex = index;
  }

  isSelectedIndex(index: number) {
    return index === this.selectedIndex;
  }

  onSlideChange(swiperEl: any) {
    this.ngZone.run(() => {
      this.selectedIndex = swiperEl ? swiperEl?.activeIndex : 0;
    });
  }

  onButtonClick(index: number) {
    this.ngZone.run(() => {
      this.swiper.swiperRef.slideTo(index);
    });
  }

  testimonials = [
    {
      name: 'Nadhir Ashafiq',
      title: 'Co-founder, TheLorry',
      imgSrc: 'nadhir-ashafiq.png',
      quotes: [
        '“WatchTower & Friends (WTF) Accelerator has played a pivotal role in launching TheLorry’s business by connecting us to top mentors, industry players and investors.”',
      ],
    },
    {
      name: 'John Ooi',
      title: 'Founder, Material in Works',
      imgSrc: 'john-ooi.png',
      quotes: [
        "“The most significant benefit we've gained from Beyond4's mentorship has been the strategic guidance in securing funding and broadening our network.",
        'This led to us successfully acquiring of the Cradle grant, which provided vital financial support for our sustainability and environmental projects.”',
      ],
    },
  ];
}
