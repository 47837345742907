import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BuyerSellerTabContent } from 'src/app/core/models/mysec/buy-sell-content';

@Component({
  selector: 'app-inner-tab-content',
  templateUrl: './inner-tab-content.component.html',
  styleUrls: ['./inner-tab-content.component.scss'],
})
export class InnerTabContentComponent implements OnChanges {
  @Input() content: BuyerSellerTabContent;

  orderBooks = [];

  ngOnChanges(changes: SimpleChanges) {
    if (!this.content) return;

    const list = this.content.orderBook;

    const buyOrders = list.buyOrders || [];
    const sellOrders = list.sellOrders || [];

    const minLength = 3;
    const maxLength = Math.max(buyOrders.length, sellOrders.length, minLength);

    this.orderBooks = Array.from({ length: maxLength }, (_, index) => ({
      buyOrder: buyOrders[index] || {},
      sellOrder: sellOrders[index] || {},
    }));
  }
}
