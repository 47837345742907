<div class="tw-mb-10 tw-mt-16 tw-border-t tw-border-solid tw-border-divider tw-pt-5">
  <div class="tw-flex tw-text-sm">
    <div *ngIf="prev" class="tw-flex-[0_0_50%]">
      <a [routerLink]="[prev.url]" class="tw-group tw-inline-block tw-w-full tw-pr-3">
        <div class="tw-text-xs">
          <i class="fa fa-angle-left tw-leading-none tw-text-primary"></i>
          <span class="tw-ml-2 tw-inline-block tw-text-primary">
            {{ 'learn.sidebar.prev' | translate }}
          </span>
        </div>
        <div class="tw-text-body tw-text-opacity-80 group-hover:tw-text-opacity-60">
          {{ prev.data.menu_title || prev.data.title }}
        </div>
      </a>
    </div>

    <div *ngIf="next" class="tw-ml-auto tw-flex-[0_0_50%]">
      <a [routerLink]="[next.url]" class="tw-group tw-inline-block tw-w-full tw-pl-3 tw-text-right">
        <div class="tw-text-xs">
          <span class="tw-mr-2 tw-inline-block tw-text-primary">{{
            'learn.sidebar.next' | translate
          }}</span>
          <i class="fa fa-angle-right tw-leading-none tw-text-primary"></i>
        </div>
        <div class="tw-text-body tw-text-opacity-80 group-hover:tw-text-opacity-60">
          {{ next.data.menu_title || next.data.title }}
        </div>
      </a>
    </div>
  </div>
</div>
