import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { SavedOffersFilter } from '../../../pages/saved-offers/saved-offers-filter';
import { PagedResult } from '../../../shared/models/paged-result/paged-result';
import { CampaignStatus } from '../../enum/campaign-status.enum';
import { ConsentType } from '../../enum/concent-type.enum';
import { ContainerType } from '../../enum/container-type.enum';
import { DeviceType } from '../../enum/device-type.enum';
import { OAuthType } from '../../enum/oauth-type.enum';
import { DeviceModel } from '../../models/account/device.model';
import { LoginOAuthModel } from '../../models/account/login-oauth.model';
import { LoginProfile } from '../../models/account/login-profile';
import { LoginModel } from '../../models/account/login.model';
import { Attachment } from '../../models/attachment/attachment';
import { Campaign } from '../../models/campaign/campaign';
import { CampaignNews } from '../../models/campaign/campaign-news';
import { CompanyModel } from '../../models/company/company.model';
import { EkycResult } from '../../models/ekyc/ekyc-result';
import { PresignedUrl } from '../../models/image/presigned-url';
import { InvestmentCount } from '../../models/investment/investment-count';
import { InvestmentModel } from '../../models/investment/investment.model';
import { IncomingNotification } from '../../models/notification/incoming-notification';
import { PaymentMethod } from '../../models/payment/payment-method';
import { InvestorProfile } from '../../models/user/investor-profile';
import { Representative } from '../../models/user/representative';
import { RepresentativeModel } from '../../models/user/representative.model';
import { SubRepresentative } from '../../models/user/sub-representative';
import { SubRepresentativeModel } from '../../models/user/sub-representative.model';
import { UpdateProfileModel } from '../../models/user/update-profile.model';
import { UserProfile } from '../../models/user/user-profile';
import { UserProfileModel } from '../../models/user/user-profile.model';
import { AuthService } from '../auth/auth.service';
import { EncryptService } from '../encrypt.service';
import { StorageService } from '../storage.service';
import { InvestorType } from './../../enum/investor-type.enum';
import { SignUpModel } from '../../models/account/signup.model';

@Injectable()
export class AccountService {
  private route = environment.apiServer + 'Api/v1/Users';
  private raiseRoute = environment.apiServer + 'Api/v1/users/{userId}/{role}/raiseFund';
  currentUser: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null);
  notifications: BehaviorSubject<IncomingNotification[]> = new BehaviorSubject<
    IncomingNotification[]
  >(null);

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private authService: AuthService,
    private encryptService: EncryptService,
  ) {}

  login = (model: LoginModel): Observable<any> => {
    const params = { 'noToken': 'noToken' };
    model.Password = this.encryptService.encryptData(model.Password);

    return this.http.post(this.route + '/loginEncryption', model, { params: params }).pipe(
      map((data: any) => {
        // Force an error if response has error content. This is used because 2FA returns as 206 (which is not an error), but we need the error handler to handle 206.
        if (data?.error) {
          throw data?.error;
        }

        let loginProfile = new LoginProfile(data.result);
        this.storageService.setFromLogin(loginProfile);
        return loginProfile;
      }),
    );
  };

  loginOAuth(oAuthToken: string, type: OAuthType, oAuthSecret?: string): Observable<any> {
    const params = { 'noToken': 'noToken' };
    var model: LoginOAuthModel = {
      Device: new DeviceModel(this.storageService.deviceId),
      Provider: type,
      OAuthToken: oAuthToken,
    };

    if (type == OAuthType.Twitter) model.OAuthSecret = oAuthSecret;

    return this.http.post(this.route + '/loginOAuth', model, { params: params }).pipe(
      map((data: any) => {
        let loginProfile = new LoginProfile(data.result);
        this.storageService.setFromLogin(loginProfile);
        return loginProfile;
      }),
    );
  }

  logout() {
    const params = { 'noToken': 'noToken' };

    return this.http.post(this.route + '/logout', {}, { params: params }).pipe(
      tap((data: any) => {
        this.storageService.clear();
        this.currentUser.next(null);
        this.notifications.next(null);
      }),
    );
  }

  refreshToken(): Observable<any> {
    const params = { 'noToken': 'noToken' };
    const endpoint = this.route + '/refreshToken';

    return this.http.post(endpoint, null, { params: params }).pipe(
      map((data: any) => {
        let loginProfile = new LoginProfile(data.result);
        this.storageService.setFromLogin(loginProfile);
        return data.result.accessTokenExpiredAt;
      }),
    );
  }

  profile(reload = false, completeEkyc = false): Observable<UserProfile> {
    if (!reload && this.currentUser.value && this.authService.isAuthenticated()) {
      return of(this.currentUser.value);
    }

    return this.http.get(this.route + '/{userId}/{role}/basicInfo').pipe(
      map((data: any) => {
        let profile: UserProfile = data.result;

        if (profile.timeZone) {
          this.storageService.timeZone = profile.timeZone;
          moment.tz.setDefault(profile.timeZone);
        }

        profile.completeEkyc = completeEkyc;
        this.currentUser.next(data.result);
        return this.currentUser.value;
      }),
    );
  }

  registerUser(model: SignUpModel): Observable<any> {
    const params = { 'noToken': 'noToken' };
    const body = Object.assign({}, model, {
      Password: this.encryptService.encryptData(model.Password),
    });

    this.storageService.fullName = model.Fullname;
    return this.http.post(this.route, body, { params: params });
  }

  resendVerifyEmail(email: string): Observable<any> {
    const params = { 'noToken': 'noToken' };
    const body = {
      Email: email,
    };

    return this.http.post(this.route + '/resendEmail', body, { params: params });
  }

  verifyEmail(token: string, email: string): Observable<any> {
    const params = { 'noToken': 'noToken' };
    const body = {
      Email: email,
      Device: new DeviceModel(this.storageService.deviceId, this.storageService.registrationId),
    };
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
    });

    return this.http
      .post(this.route + '/verifyEmail', body, { headers: headers, params: params })
      .pipe(
        tap((data: any) => {
          let loginProfile = new LoginProfile(data.result);
          this.storageService.setFromLogin(loginProfile);
        }),
      );
  }

  forgotPassword(email: string): Observable<any> {
    const params = { 'noToken': 'noToken' };
    const body = {
      email: email,
    };

    return this.http.post(this.route + '/reset', body, { params: params });
  }

  resetPassword(token: string, email: string, newPassword: string): Observable<any> {
    const params = { 'noToken': 'noToken', 'noCredential': 'noCredential' };
    const body = {
      email: email,
      newPassword: this.encryptService.encryptData(newPassword),
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
    });

    return this.http.post(this.route + '/resetPasswordEncryption', body, {
      headers: headers,
      params: params,
    });
  }

  uploadAttachmentPresignedURL(file: File, containerType: ContainerType): Observable<any> {
    const params = { 'noToken': 'noToken' };
    let formData = new FormData();
    formData.append('filename', file.name);
    formData.append('containerType', containerType.toString());

    return this.http
      .post(this.route + '/' + this.storageService.userId + '/{role}/attachment', formData, {
        params: params,
      })
      .pipe(
        mergeMap((data: any) => {
          if (data && data.result) {
            return this.uploadImage(data.result, file);
          } else {
            return data.result;
          }
        }),
      );
  }

  uploadImage(presignedUrl: PresignedUrl, file: File): Observable<PresignedUrl> {
    const options = {
      headers: new HttpHeaders({
        'x-ms-blob-type': 'BlockBlob',
        'x-ms-blob-content-type': presignedUrl.fileType,
      }),
    };

    let formData = new FormData();
    formData.append('file', file);

    return this.http.post(presignedUrl.writeUrl, formData).pipe(
      map((data: any) => {
        if (data && data.result) {
          presignedUrl.id = data.result.id;
          presignedUrl.url = data.result.url;
        }
        return presignedUrl;
      }),
    );
  }

  registerIndividual(userProfile: UserProfile): Observable<any> {
    const params = { 'noToken': 'noToken' };
    const body = new UserProfileModel(userProfile);

    return this.http.post(this.route + '/individualInvestor/signUpFlow/1', body, {
      params: params,
    });
  }

  registerInvestorProfile(investorType: InvestorType, isAnonymous: boolean): Observable<any> {
    const params = { 'noToken': 'noToken' };

    const body = {
      investorType: parseInt(investorType.toString()),
      isAnonymous: isAnonymous,
    };

    return this.http
      .post(this.route + '/individualInvestor/signUpFlow/2', body, { params: params })
      .pipe(
        tap((data: any) => {
          this.profile(true).subscribe(
            () => {},
            () => {},
          );
        }),
      );
  }

  getSignUpFlowStatus(): Observable<any> {
    return this.http.get(this.route + '/signUpFlow').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  registerCompanyRep(corporateRep: Representative): Observable<any> {
    const params = { 'noToken': 'noToken' };
    let body = new RepresentativeModel(corporateRep);

    return this.http.post(this.route + '/companyRep/signUpFlow/3', body, { params: params });
  }

  registerCompanyInfo(company: CompanyModel): Observable<any> {
    const params = { 'noToken': 'noToken' };

    return this.http.post(this.route + '/companyRep/signUpFlow/1', company, { params: params });
  }

  registerCorporateProfile(investorProfile: InvestorProfile): Observable<any> {
    const params = { 'noToken': 'noToken' };

    const body = investorProfile;

    return this.http.post(this.route + '/companyRep/signUpFlow/2', body, { params: params });
  }

  registerCompanyDocuments(): Observable<any> {
    const params = { 'noToken': 'noToken' };

    return this.http.post(this.route + '/companyRep/signUpFlow/4', null, { params: params }).pipe(
      tap((data: any) => {
        this.profile(true).subscribe(
          () => {},
          () => {},
        );
      }),
    );
  }

  registerIssuerCompany(company: CompanyModel): Observable<any> {
    const params = { 'noToken': 'noToken' };

    return this.http.post(this.route + '/issuerRep/signUpFlow/1', company, { params: params });
  }

  registerIssuerRep(issuerRep: Representative): Observable<any> {
    const params = { 'noToken': 'noToken' };
    let body = new RepresentativeModel(issuerRep);

    return this.http.post(this.route + '/issuerRep/signUpFlow/2', body, { params: params });
  }

  registerIssuerDocuments(): Observable<any> {
    const params = { 'noToken': 'noToken' };

    return this.http.post(this.route + '/issuerRep/signUpFlow/3', null, { params: params }).pipe(
      tap((data: any) => {
        this.profile(true).subscribe(
          () => {},
          () => {},
        );
      }),
    );
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    const body = {
      NewPassword: this.encryptService.encryptData(newPassword),
      OldPassword: this.encryptService.encryptData(oldPassword),
    };

    return this.http.post(this.route + '/changePasswordEncryption', body);
  }

  getProfilePaymentMethods(): Observable<any> {
    return this.http.get(this.route + '/{userId}/{role}/paymentMethods').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  updateProfilePaymentMethods(paymentMethod: PaymentMethod): Observable<any> {
    return this.http.put(this.route + '/{userId}/{role}/paymentMethods', paymentMethod);
  }

  updateProfile(profile: UserProfile): Observable<any> {
    const body = new UpdateProfileModel(profile);

    return this.http.put(this.route + '/{userId}/{role}/basicInfo', body);
  }

  getProfileInvestment(): Observable<InvestorProfile> {
    return this.http.get(this.route + '/{userId}/{role}/investment').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  updateProfileInvestment(investorProfile: InvestorProfile): Observable<any> {
    const body = investorProfile;

    return this.http.put(this.route + '/{userId}/{role}/investment', body);
  }

  getProfileSectors(): Observable<any> {
    return this.http.get(this.route + '/{userId}/{role}/favouriteSectors').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  addProfileSector(id: number): Observable<any> {
    const body = {
      id: id,
    };

    return this.http.put(this.route + '/{userId}/{role}/favouriteSector', body);
  }

  deleteProfileSector(id: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: {
        id: id,
      },
    };

    return this.http.delete(this.route + '/{userId}/{role}/favouriteSector', options);
  }

  getProfileNotification(): Observable<any> {
    return this.http.get(this.route + '/{userId}/{role}/notifications').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  toggleNotificationType(id: number): Observable<any> {
    const body = {
      id: id,
    };

    return this.http.post(this.route + '/{userId}/{role}/notifications/type', body);
  }

  toggleNotificationPlatform(id: number): Observable<any> {
    const body = {
      id: id,
    };

    return this.http.post(this.route + '/{userId}/{role}/notifications/platform', body);
  }

  getCompanyInfo(): Observable<CompanyModel> {
    return this.http.get(this.route + '/{userId}/{role}/companyInfo').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  updateCompanyInfo(company: CompanyModel): Observable<any> {
    let body = company;

    return this.http.put(this.route + '/{userId}/{role}/companyInfo', body);
  }

  uploadDocument(document: Attachment): Observable<any> {
    const params = { 'noToken': 'noToken' };
    let formData = new FormData();
    formData.append(
      'AttachmentInfo.EncryptedAttachmentInfo',
      document.attachmentInfo.encryptedAttachmentInfo.toString(),
    );
    formData.append('AttachmentInfo.Token', document.attachmentInfo.token.toString());
    formData.append('type', document.attachmentType.id.toString());

    if (document.fileName) formData.append('documentName', document.fileName);

    return this.http
      .put(this.route + '/{userId}/{role}/document', formData, { params: params })
      .pipe(
        map((data: any) => {
          return data.result;
        }),
      );
  }

  getDocuments(): Observable<Attachment[]> {
    return this.http.get(this.route + '/{userId}/{role}/documents').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  deleteDocument(id: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: {
        Id: id,
      },
    };

    return this.http.delete(this.route + '/{userId}/{role}/document', options);
  }

  getRepresentatives(): Observable<SubRepresentative[]> {
    return this.http.get(this.route + '/{userId}/{role}/otherReps').pipe(
      map((data: any) => {
        return data.result?.users;
      }),
    );
  }

  notifyBusiness(companyId: number): Observable<any> {
    const body = {
      companyId: companyId,
    };

    return this.http.post(this.route + '/{userId}/companies/notification', body);
  }

  updateAdditionalReps(corporateReps: SubRepresentative[]): Observable<any> {
    const body = {
      users: corporateReps ? corporateReps.map((rep) => new SubRepresentativeModel(rep)) : [],
    };

    return this.http.put(this.route + '/{userId}/{role}/otherReps', body);
  }

  getSavedCampaigns(filter: SavedOffersFilter): Observable<PagedResult<Campaign>> {
    const params: any = Object.assign({}, filter);

    return this.http
      .get(this.route + '/{userId}/{role}/campaigns/favourite', { params: params })
      .pipe(
        map((data: any) => {
          return {
            data: data.result.data || [],
            total: data.result.totalCount,
          };
        }),
      );
  }

  saveCampaign(campaignId: number): Observable<any> {
    return this.http.post(this.route + '/{userId}/campaigns/' + campaignId + '/favourite', null);
  }

  giveConsent(concentType: ConsentType): Observable<any> {
    const body = {
      TypeOfConsent: concentType,
      Version: environment.kycVersion,
    };

    return this.http.post(this.route + '/{userId}/{role}/consent', body);
  }

  createEkyc(deviceType: DeviceType): Observable<EkycResult> {
    const body = {
      DeviceType: deviceType,
      ResponseUrl: environment.baseUrl + '/e-kyc-result',
      remark: 'CTOS ID Sign Up',
    };
    return this.http.post(this.route + '/{userId}/{role}/ekyc/createTransaction', body).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getMyCampaigns(currentPage: number, take: number): Observable<PagedResult<Campaign>> {
    let params = {
      currentPage: currentPage.toString(),
      take: take.toString(),
    };

    return this.http.get(this.route + '/{userId}/{role}/campaigns', { params: params }).pipe(
      map((data: any) => {
        return {
          data: data.result.data || [],
          total: data.result.totalCount,
        };
      }),
    );
  }

  getCampaignNews(currentPage: number, take: number): Observable<PagedResult<CampaignNews>> {
    let params = {
      currentPage: currentPage.toString(),
      take: take.toString(),
    };

    return this.http.get(this.route + '/{userId}/{role}/campaignNews', { params: params }).pipe(
      map((data: any) => {
        return {
          data: data.result.data || [],
          total: data.result.totalCount,
        };
      }),
    );
  }

  getCampaignDocuments(
    campaignId: number,
    currentPage: number,
    take: number,
  ): Observable<PagedResult<Attachment>> {
    let params: any = {
      currentPage: currentPage.toString(),
      take: take.toString(),
    };

    if (!this.storageService.userId) params.noToken = 'noToken';

    let route =
      this.storageService.userId && this.storageService.role
        ? this.route + '/{userId}/{role}/campaigns/' + campaignId + '/documents'
        : this.route + '/{role}/campaigns/' + campaignId + '/documents';

    return this.http.get(route, { params: params }).pipe(
      map((data: any) => {
        return {
          data: data.result.data || [],
          total: data.result.totalCount,
        };
      }),
    );
  }

  getMyInvestments(
    currentPage: number,
    take: number,
    campaignStatuses: CampaignStatus[],
  ): Observable<Array<InvestmentModel>> {
    let params: any = {
      currentPage: currentPage.toString(),
      take: take.toString(),
    };

    if (campaignStatuses.length) {
      params.campaignStatuses = campaignStatuses;
    }

    return this.http.get(this.route + '/{userId}/{role}/investments', { params: params }).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getMyInvestmentsCount(): Observable<InvestmentCount> {
    return this.http.get(this.route + '/{userId}/{role}/investments/count').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  uploadDocWithStepId(
    presignedUrl: PresignedUrl,
    file: File,
    stepId?: number,
    fundRaiseApplicationid?: number,
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'x-ms-blob-type': 'BlockBlob',
        'x-ms-blob-content-type': presignedUrl.fileType,
      }),
    };
    let formData = new FormData();
    formData.append('file', file);

    return this.http.post(presignedUrl.writeUrl, formData).pipe(
      map((data: any) => {
        if (data && data.result) {
          presignedUrl.id = data.result.id;
          presignedUrl.url = data.result.url;
        }
        return presignedUrl;
      }),
      catchError((err) => {
        if (err.error.code == 'ERR_MALICIOUS_FILE_FOUND' && stepId) {
          this.http
            .delete(
              this.raiseRoute +
                '/' +
                fundRaiseApplicationid +
                '/steps/' +
                stepId +
                '/attachment/' +
                presignedUrl.subItemId,
            )
            .subscribe();
        }
        return throwError(err);
      }),
    );
  }

  loginOnBehalf(id: string, token: string): Observable<any> {
    const body = {
      userId: id,
      device: new DeviceModel(this.storageService.deviceId),
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
    });

    const params = { 'noToken': 'noToken' };

    return this.http
      .post(`${this.route}/loginOnBehalf`, body, { headers: headers, params: params })
      .pipe(
        map((data: any) => {
          let loginProfile = new LoginProfile(data.result);
          this.storageService.setFromLogin(loginProfile);
          return loginProfile;
        }),
      );
  }
}
