import { isPlatformBrowser } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, PLATFORM_ID, Inject } from '@angular/core';
import { BusinessType } from 'src/app/core/enum/business-type.enum';
import { CampaignStatus } from 'src/app/core/enum/campaign-status.enum';
import { InvestmentFilterType } from 'src/app/core/enum/investment-filter-type.enum';
import { TaxIncentivesEligibility } from 'src/app/core/enum/tax-incentives-eligibility.enum';
import { CampaignFiltersMenu } from 'src/app/core/models/campaign/campaign-filters-menu';
import { CampaignFiltersTag } from 'src/app/core/models/campaign/campaign-filters-tag';
import { SectorList } from 'src/app/core/models/company/sector-list';
import { SettingService } from 'src/app/core/services/api/setting.service';
import { ErrorService } from 'src/app/core/services/error.service';
import { QueryParamsService } from 'src/app/core/services/query-params.service';
import { EnumToArrayPipe } from 'src/app/shared/pipes/enum-to-array.pipe';
import { CampaignSectionFilter } from '../campaign-section-filter';
import { CampaignSectionFilterService } from '../campaign-section-filter.service';

@Component({
  selector: 'app-campaign-filters',
  templateUrl: './campaign-filters.component.html',
  styleUrls: ['./campaign-filters.component.scss'],
  providers: [EnumToArrayPipe],
})
export class CampaignFiltersComponent implements OnInit {
  @Input() params!: CampaignSectionFilter;
  @Output() paramsChange = new EventEmitter<CampaignSectionFilter>();
  sectors: SectorList;
  selectedBusinessTypes: number[] = [];
  businessTypes: CampaignFiltersMenu[] = this.enumToArrayPipe.transform(BusinessType).map((biz) => {
    return {
      key: biz.key,
      label: $localize`campaign.${biz.value}`,
    };
  });

  InvestmentFilterType: typeof InvestmentFilterType = InvestmentFilterType;

  TaxIncentivesEligibility: typeof TaxIncentivesEligibility = TaxIncentivesEligibility;

  // Toggle menus
  filtersMenus: CampaignFiltersMenu[] = [
    {
      key: 'selectedSectors',
      label: 'Sectors',
      isArray: true,
      isMultiSelect: true,
      children: [
        { key: 1, label: 'AdTech & Media' },
        { key: 8, label: 'Agriculture' },
        { key: 58, label: 'Consumer' },
        { key: 85, label: 'E-Commerce' },
        { key: 120, label: 'FinTech' },
        { key: 122, label: 'Food & Beverages' },
        { key: 141, label: 'Healthcare' },
        { key: 192, label: 'Manufacturing' },
        { key: 207, label: 'Mobile Application' },
        { key: 274, label: 'Services' },
        { key: 298, label: 'Technology' },
        { key: 248, label: 'PropTech' },
      ],
    },
    // Hide for now, but when api supports will make it visible
    // {
    //     key: "businessIds",
    //     label: "Business Types",
    //     children: this.businessTypes,
    // },
    {
      key: 'more',
      label: 'More',
      childrenGroups: [
        {
          key: 'landingPageAngelTaxIncentiveEligibility',
          label: 'Tax Eligibilities',
          isArray: false,
          isMultiSelect: false,
          children: [
            { key: 1, label: 'ATI' },
            { key: 2, label: 'None' },
          ],
        },
      ],
    },
  ];
  allFiltersMenus: CampaignFiltersMenu[] = this.filtersMenus.reduce((acc, menu) => {
    if (menu.key === 'more') {
      acc = [...acc, ...menu.childrenGroups];
    } else {
      acc.push(menu);
    }

    return acc;
  }, []);
  filterTags: Array<CampaignFiltersTag> = [];
  selectedFilterMenu: string;
  selectedFilterMenuIndex: number;

  constructor(
    private campaignSectionFilterService: CampaignSectionFilterService,
    private settingService: SettingService,
    private errorService: ErrorService,
    private queryParamsService: QueryParamsService,
    private enumToArrayPipe: EnumToArrayPipe,
    @Inject(PLATFORM_ID) private platform: Object,
  ) {
    this.selectedFilterMenu = '';
    this.selectedFilterMenuIndex = -1;
  }

  ngOnInit(): void {
    // Note: This affects SSR as it wouldn't load the query string and list
    // Without this condition the page frozen issue no longer exist as well
    // Todo: Require to remove this code completely if no issue arise until deployed
    // if (isPlatformBrowser(this.platform)) {
    this.filterTags = this.getFilterTags(this.params);
    this.queryFilters();
    // }
  }

  submit(params: CampaignSectionFilter, reload?: boolean) {
    this.params = params;
    this.campaignSectionFilterService.listingFilter = params;
    this.paramsChange.emit(params);
  }

  // Toggle filters related
  // ------------------
  getFilterTags(params: any): Array<CampaignFiltersTag> {
    // generate `CampaignFiltersTag` based on the query string
    let tags = [];
    let filters = this.queryParamsService.filterProps(params, [
      'selectedSectors',
      'campaignStatus',
      'currentPage',
      'filterString',
      'orderBy',
      'take',
    ]);

    Object.keys(filters).forEach((key) => {
      switch (key) {
        case 'sectorIds':
          let sectorIds = filters[key];

          if (sectorIds && !Array.isArray(sectorIds)) {
            sectorIds = sectorIds.split(',');
          }

          if (!Array.isArray(sectorIds)) return;

          sectorIds.map((sectorId) => {
            const tag = this.generateFilterTag('selectedSectors', sectorId);
            if (tag) tags.push(tag);
          });
          break;

        default:
          const tag = this.generateFilterTag(key, filters[key]);
          if (tag) tags.push(tag);
      }
    });

    return tags;
  }

  getParentMenu(menuKey: string | number): CampaignFiltersMenu {
    return this.allFiltersMenus.find((f) => f.key == menuKey);
  }

  generateFilterTag(parentKey: string | number, childKey: string | number): CampaignFiltersTag {
    const menu: CampaignFiltersMenu = this.getParentMenu(parentKey);
    let child: CampaignFiltersMenu;

    if (menu?.children) {
      child = menu.children.find((m) => m.key == childKey);
    }

    return child
      ? {
          key: childKey,
          label: child.label,
          parentKey: parentKey,
          isArray: menu.isArray,
          isMultiSelect: menu.isMultiSelect,
        }
      : null;
  }

  handleSelectedMenuChange(selectedMenu: string) {
    this.selectedFilterMenu = selectedMenu;
    this.selectedFilterMenuIndex = this.filtersMenus.findIndex((m) => m.key === selectedMenu);
  }

  handleFiltersChange(tag: CampaignFiltersTag) {
    // Will emit this function on each click event
    // So when `tag` is `active` will create as new tag
    // Otherwise remove it
    if (tag.isActive) {
      // For `singleSelect` tag, will need to deselect the existing filters
      if (!tag.isMultiSelect) {
        this.removeFilter(tag, true);
      }
      this.filterTags.push(tag);
      this.queryFilters();
    } else {
      this.removeFilter(tag, !tag.isMultiSelect);
      this.queryFilters();
    }
  }

  handleRemoveTag(tag: CampaignFiltersTag) {
    this.removeFilter(
      tag,
      tag.parentKey == 'landingPageAngelTaxIncentiveEligibility' ? true : false,
    );

    // Clear the query params related to the removed tag
    this.clearQueryParam(tag);

    this.queryFilters();
  }

  removeFilter(tag: CampaignFiltersTag, isRemoveExistingRequired: boolean = false) {
    const menuIndex = this.filterTags.findIndex((t) =>
      isRemoveExistingRequired ? t.parentKey == tag.parentKey : t.key == tag.key,
    );

    if (menuIndex >= 0) this.filterTags.splice(menuIndex, 1);

    // Update the params for the tags
    if (tag.parentKey == 'selectedSectors') {
      delete this.params.selectedSectors;
    }
    if (tag.parentKey == 'landingPageAngelTaxIncentiveEligibility') {
      this.params.landingPageAngelTaxIncentiveEligibility = 0;
    }
  }

  queryFilters() {
    // Reset certain filters to raw state
    if (this.filterTags.length === 0) {
      delete this.params.selectedSectors;
    }

    const filters = this.filterTags.reduce((acc, tag) => {
      // Special handling for certain tag that need to render as Array
      if (tag.isArray) {
        let s = [];

        if (Array.isArray(acc[tag.parentKey])) {
          s = acc[tag.parentKey];
        } else if (acc[tag.parentKey]) {
          s.push(acc[tag.parentKey]);
        }

        return {
          ...acc,
          [tag.parentKey]: [...s, tag],
        };
      }

      return {
        ...acc,
        [tag.parentKey]: tag.key,
      };
    }, {});

    this.params = { ...this.params, ...filters };
    this.submit(this.params);
  }

  // Filters related
  // ------------------
  selectFilterType(type: InvestmentFilterType) {
    this.params = { ...this.params, ...{ orderBy: type } };
    this.submit(this.params);
  }

  enableATI(taxIncentivesEligibility: TaxIncentivesEligibility) {
    this.params = {
      ...this.params,
      ...{
        landingPageAngelTaxIncentiveEligibility:
          this.params.landingPageAngelTaxIncentiveEligibility == taxIncentivesEligibility
            ? TaxIncentivesEligibility.All
            : taxIncentivesEligibility,
      },
    };
  }

  selectBusinessType(type: number) {
    let findType = this.selectedBusinessTypes.includes(type);

    if (findType)
      this.selectedBusinessTypes = this.selectedBusinessTypes.filter(
        (selectedBusinessType) => selectedBusinessType != type,
      );
    else this.selectedBusinessTypes.push(type);
  }

  getSectors(keyword: string) {
    this.settingService.getSectors(keyword).subscribe(
      (data) => {
        this.sectors = data;
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  changeTab(status: CampaignStatus, event?: PointerEvent) {
    if (event) {
      const link = event.target as HTMLElement;
    }

    this.params = {
      ...this.params,
      ...{
        campaignStatus: status,
      },
    };

    this.submit(this.params);
  }

  clearQueryParam(tag: CampaignFiltersTag) {
    const queryParamsToRemove = {};

    if (tag.parentKey === 'selectedSectors') {
      queryParamsToRemove['sectorIds'] = null;
    } else {
      queryParamsToRemove[tag.parentKey] = null;
    }

    this.queryParamsService.updateUrl(queryParamsToRemove);
  }
}
