<section class="section">
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12">
        <h1 class="title font-weight-bold text-dark text-center">
          TCF Issuer and Investor Terms and Conditions
        </h1>
      </div>
    </div>

    <div class="row">
      <div class="col-11 mx-auto col-md-8 offset-md-2">
        <div class="legal-list">
          <div class="upperalpha-list">
            <ol>
              <!-- A -->
              <li>
                <p>
                  These Issuer & Investor Terms and Conditions (<strong>“Terms”</strong>) shall
                  apply to each Issuer and Investor, and where applicable, shall also apply to the
                  User.
                </p>
              </li>
              <!-- B -->
              <li>
                <p>
                  By accessing, using, or continuing to access and use the Platform, or using the
                  services provided via the Platform, each Issuer, Investor and User confirm that
                  they have read, understood and agreed to these Terms, any other terms and
                  conditions of the Platform, any other agreements or rules on the specific services
                  offered under the Platform and Privacy Policy on the Platform (each as may be
                  varied, amended or supplemented from time to time).
                </p>
              </li>
              <!-- C -->
              <li>
                <p>
                  The Issuer, the Investor and the User (as the case may be) shall stop using the
                  Platform immediately in the event any of them do not agree with these Terms, any
                  other terms and conditions of the Platform and Privacy Policy.
                </p>
              </li>
            </ol>
          </div>

          <p>Please read these Terms carefully and print a copy for your future reference.</p>

          <ol>
            <li>
              <!-- 1 -->
              <h2>DEFINITION AND INTERPRETATION</h2>

              <ol>
                <!-- 1.1 -->
                <li>
                  <p>
                    Unless the context otherwise requires, the following words shall have the
                    following meanings assigned to it:
                  </p>

                  <dl class="legal-description-list">
                    <dt>“Application”</dt>
                    <dd>
                      Means the application made to the Platform Operator by an applicant to become
                      an Issuer by filling (a) the relevant application forms, and (b) such other
                      forms as may be required by the Platform Operator;
                    </dd>

                    <dt>“Authorised Representative”</dt>
                    <dd>
                      Means a person authorised to act on behalf of the Issuer, including the
                      directors and senior management of the Issuer;
                    </dd>

                    <dt>“Business Days”</dt>
                    <dd>
                      Means the days on which banks are open for business other than Saturday,
                      Sunday or public holiday in Kuala Lumpur and/or in Wilayah Persekutuan Kuala
                      Lumpur;
                    </dd>

                    <dt>“Campaign”</dt>
                    <dd>
                      Refers to the portion of the TCF Exercise which is Hosted on the TCF Platform
                      and is open to receiving investments from Investors;
                    </dd>

                    <dt>“Campaign Period”</dt>
                    <dd>
                      Refers to the period of time when the Campaign is Hosted on the TCF Platform;
                    </dd>

                    <dt>“Cooling-Off Period”</dt>
                    <dd>
                      Refers to the period of six (6) Business Days after the Campaign Period which
                      allows the Investor to opt-out or proceed with investing in the Company;
                    </dd>

                    <dt>“Directors”</dt>
                    <dd>Refers to any individual director of the Company</dd>

                    <dt>“Fees”</dt>
                    <dd>Has the meaning specified in Clause 12;</dd>

                    <dt>“Guidelines”</dt>
                    <dd>
                      Means the Guidelines on Digital Asset
                      (https://www.sc.com.my/regulation/guidelin es/digital-assets) issued by the SC
                      as may be amended from time to time, and include such other guidelines,
                      orders, directives, by-laws, regulations and policies as may be issued from
                      time to time by the relevant authority in respect of token crowdfunding in
                      Malaysia
                    </dd>

                    <dt>“Host or Hosted or Hosting”</dt>
                    <dd>
                      Means the act of listing the Company and its Offer on the TCF Platform for the
                      purpose of receiving investments from Investors.
                    </dd>

                    <dt>“Investment Amount”</dt>
                    <dd>
                      Means the total amount invested by the Investors pursuant to the Hosting
                    </dd>

                    <dt>“Investor”</dt>
                    <dd>Means the Investors who have invested in the Campaign;</dd>

                    <dt>“Issuer"</dt>
                    <dd>
                      Means a person who has been approved by the Platform Operator to be Hosted on
                      the Platform as an Issuer;
                    </dd>

                    <dt>“Material Change”</dt>
                    <dd>
                      Means any change that meets the following criteria: (a) The discovery of a
                      false or misleading statement in any disclosures; (b) The occurrence of a
                      material change affecting a matter disclosed in the White Paper; (c) The
                      discovery of any material omission of information that may affect Investors;
                      (d) Any material development in the circumstances; (e) The discovery of any
                      material change in the information submitted to the Securities Commission (f)
                      The occurrence of a matter and information in respect of that matter would
                      have been required by the TCF Guidelines to be disclosed in the White Paper if
                      the matter has arisen at the time the White Paper was prepared; or (g) Any
                      change requested by the Company and confirmed by the TCF Operator to be a
                      Material Change;
                    </dd>

                    <dt>“Offer”</dt>
                    <dd>
                      Means the investment offer by the Company to Investors pursuant to the
                      Campaign; and includes the Tokens and Offer Document;
                    </dd>

                    <dt>“Offer Documents”</dt>
                    <dd>
                      Means the documents prepared by the Company setting out the relevant
                      information of the Campaign to be circulated on the TCF Platform and to
                      potential Investors; and shall include the White Paper;
                    </dd>

                    <dt>“Offer Period"</dt>
                    <dd>
                      In respect of a particular Hosting, means the period during which the
                      Investors may pay the TCF Funds into the Trust Account;
                    </dd>

                    <dt>“Platform Operator”</dt>
                    <dd>
                      Means Pitch Platforms Sdn.Bhd. [Registration No.201501033144 (1158464-T)],
                      being the owner and operator of the Platform, and its subsidiaries;
                    </dd>

                    <dt>“Proposal”</dt>
                    <dd>
                      Means the proposal of the Issuer for a Hosting and shall include supporting
                      documents and information requested by the Platform Operator;
                    </dd>

                    <dt>“Review”</dt>
                    <dd>
                      Means the review of the Proposal carried out by the Platform Operator in
                      accordance with its internal guidelines and policies (as may be varied,
                      amended or supplemented from time to time);
                    </dd>

                    <dt>“Securities Commission (SC)”</dt>
                    <dd>
                      Refers to the Securities Commission of Malaysia; , being the regulatory
                      authority on TCF in Malaysia;
                    </dd>

                    <dt>“Successful Campaign”</dt>
                    <dd>
                      Means a Campaign which has been declared as successful by the TCF Operator
                      upon the Campaign achieving the Minimum Funding Target upon the expiry of the
                      Campaign Period and Cooling-Off Period;
                    </dd>

                    <dt>“Targeted Amount”</dt>
                    <dd>
                      In respect of a particular Hosting, means the range of minimum and maximum
                      amount sought to be raised by an Issuer;
                    </dd>

                    <dt>“TCF Agreement”</dt>
                    <dd>Means Token Crowdfunding Agreement;</dd>

                    <dt>“TCF Exercise”</dt>
                    <dd>
                      Means a fundraising exercise on the TCF Platform undertaken by the Company
                      through issuance and allocation of Tokens;
                    </dd>

                    <dt>“TCF Guidelines”</dt>
                    <dd>
                      Means the Guidelines on Digital Assets issued by the Securities Commission of
                      Malaysia, including any latest versions or any other guidelines applicable and
                      in force;
                    </dd>

                    <dt>“TCF Platform”</dt>
                    <dd>
                      Means the token crowdfunding platform operated by the Platform Operator with
                      https://www.pitchin.my being its website domain and includes any other
                      affiliate platforms operated by TCF Operator;
                    </dd>

                    <dt>“Token Crowdfunding (“TCF”)”</dt>
                    <dd>
                      Has the same meaning as Initial Exchange Offering (IEO) as defined by the
                      Securities Commission under the TCF Guidelines;
                    </dd>

                    <dt>“Tokens”</dt>
                    <dd>
                      Means the digital tokens being offered by the Company to the Investors
                      pursuant to the Campaign;
                    </dd>

                    <dt>“Total Investment Amount”</dt>
                    <dd>
                      Means the total Investors' Funds raised through the Campaign from Investors;
                    </dd>

                    <dt>“Trust Account”</dt>
                    <dd>
                      Means a trust account created and maintained by pitchIN with a Trustee
                      registered with the SC under the latest Guidelines on Registration and Conduct
                      of Capital Market Services Providers for the purpose of holding any funds
                      raised from the investors during the Campaign;
                    </dd>

                    <dt>“Trust Fund”</dt>
                    <dd>Means the TCF Funds deposited in the Trust Account;</dd>

                    <dt>“Trustee”</dt>
                    <dd>
                      Means the trustee appointed by the Platform Operator to hold the Trust Fund on
                      trust for the relevant person; and
                    </dd>

                    <dt>“User”</dt>
                    <dd>
                      Means any person who browses, visits, access and/or uses the Platform, whether
                      active, semi-active or inactive user, and includes the Issuer and the
                      Investor, and <strong>“Users”</strong> means any two or more of them.
                    </dd>

                    <dt>“White Paper”</dt>
                    <dd>
                      Means the white paper prepared by the Company for the TCF Exercise based on
                      requirements in the TCF Guidelines and shall include any supplementary white
                      paper approved by the TCF Operator or Securities Commission;
                    </dd>
                  </dl>
                </li>

                <!-- 1.2 -->
                <li>
                  <p>
                    Any reference to a statutory provision shall include such provision and any
                    regulations made in pursuance thereof as from time to time modified, amended,
                    replaced or re-enacted whether before, on or after the date of these Terms. Any
                    reference to any “law” shall include common law applicable in Malaysia and the
                    provisions of statutes, regulations, orders and other subsidiary legislation
                    issued pursuant to such statute, as well as directions, guidelines and circulars
                    issued by any regulatory authority pursuant to any authority granted by any such
                    statute.
                  </p>
                </li>

                <!-- 1.3 -->
                <li>
                  <p>
                    Unless the context otherwise requires or permits, references to the singular
                    number shall include references to the plural and vice versa, references to a
                    particular gender shall include all genders, and references to natural persons
                    shall include bodies corporate and vice versa.
                  </p>
                </li>

                <!-- 1.4 -->
                <li>
                  <p>
                    Any reference to a
                    <strong>"day"</strong>, <strong>"week"</strong>, <strong>"month"</strong> or
                    <strong>"year"</strong> is to that day, week, month or year in accordance with
                    the Gregorian calendar.
                  </p>
                </li>

                <!-- 1.5 -->
                <li>
                  <p>
                    The headings are inserted for convenience only and shall not affect the
                    construction of these Terms.
                  </p>
                </li>

                <!-- 1.6 -->
                <li>
                  <p>
                    The expression
                    <strong>"Platform Operator"</strong>, <strong>"Issuer"</strong>,
                    <strong>"Investor"</strong> and <strong>"User"</strong> shall, where the context
                    permits, include their respective successors, personal representatives and
                    permitted assigns.
                  </p>
                </li>

                <!-- 1.7 -->
                <li>
                  <p>
                    Where a word or phrase is given a defined meaning in these Terms, any other part
                    of speech or other grammatical form in respect of such word or phrase has a
                    corresponding meaning.
                  </p>
                </li>

                <!-- 1.8 -->
                <li>
                  <p>
                    Any reference to
                    <strong>“these Terms”</strong> is a reference to these Terms and any written
                    amendments, supplementals or novation of these Terms from time to time, and
                    includes a reference to any document which amends, waives, is supplemental to or
                    novates the terms of these Terms.
                  </p>
                </li>

                <!-- 1.9 -->
                <li>
                  <p>
                    Any reference to
                    <strong>“writing”</strong>, or cognate expressions, includes any communication
                    effected via the Platform, electronic mail, telex, cable, facsimile transmission
                    or other comparable means but shall not include short messaging services,
                    instant messaging services or internet chat.
                  </p>
                </li>

                <!-- 1.10 -->
                <li>
                  <p>
                    Any reference to
                    <strong>“person’</strong> includes individual, partnership, association, company
                    or corporation.
                  </p>
                </li>

                <!-- 1.11 -->
                <li>
                  <p>
                    If any period of time is specified from a given day, or the day of a given act
                    or event, it is to be calculated exclusive of that day and if any period of time
                    falls on a day which is not a Business Day, then that period is to be deemed to
                    only expire on the next Business Day.
                  </p>
                </li>

                <!-- 1.12 -->
                <li>
                  <p>
                    No rule of construction applies to the disadvantage of a party because the party
                    was responsible for the preparation of these Terms or any part of it.
                  </p>
                </li>

                <!-- 1.13 -->
                <li>
                  <p>
                    All transactions and payments must be completed and cleared or received during
                    the normal business hours of the Platform Operator (09.00 to 16.00 local time in
                    Kuala Lumpur). Where a transaction is not confirmed before the close of business
                    hours on one Business Day then that transaction will be confirmed and deemed to
                    have become final and binding at the opening of business hours on the next
                    Business Days.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 2 -->
              <h2>CONDITIONS PRECEDENT</h2>

              <ol>
                <li>
                  <p>
                    Before a person may carry out a Hosting, the person shall first apply to the
                    Platform Operator to become an Issuer, and thereafter shall submit its Proposal
                    to the Platform Operator for its Review. The Platform Operator reserves the
                    right at its sole discretion, at any time, to prevent, stop, withdraw and/or
                    reject the Application and/or Proposal of any person without assigning any
                    reason whatsoever.
                  </p>
                </li>
                <li>
                  <p>
                    The Platform Operator uses its own internal guidelines and policies when
                    assessing the Applications and Proposal of any person and has absolute
                    discretion as to (a) whether an applicant may become an Issuer; and (b) whether
                    an Issuer may carry out the Hosting.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 3 -->
              <h2>APPLICATION AS AN ISSUER</h2>

              <ol>
                <!-- 3.1 -->
                <li>
                  <p>To qualify as an Issuer, the applicant shall fulfil the following criteria:</p>

                  <div class="alpha-list">
                    <ol>
                      <!-- 3.1.a -->
                      <li class="lowerroman-list">
                        <p>The applicant can be either of the following entities:</p>
                        <ol>
                          <li>
                            <p>a locally incorporated company;</p>
                          </li>
                          <li>
                            <p>a limited liability partnership incorporated in Malaysia;</p>
                          </li>
                          <li>
                            <p>an unlisted subsidiary of a public-listed company; or</p>
                          </li>
                          <li>
                            <p>a special purpose vehicle (SPV) of a public-listed company;</p>
                          </li>
                        </ol>
                      </li>
                      <!-- 3.1.b -->
                      <li class="lowerroman-list">
                        <p>It shall not be any of the following entities:</p>
                        <ol>
                          <li>
                            <p>Exempt private company;</p>
                          </li>
                          <li>
                            <p>Public-listed companies;</p>
                          </li>
                        </ol>
                      </li>
                      <!-- 3.1.c -->
                      <li>
                        <p>
                          At the point of Hosting or any period specified in the Offer Document, is
                          not undergoing an ECF, P2P or TCF campaign with any other Recognised
                          Market Operator (RMO)
                        </p>
                      </li>
                      <!-- 3.1.d -->
                      <li>
                        <p>Have its main business operations carried out in Malaysia; and</p>
                      </li>
                      <!-- 3.1.e -->
                      <li>
                        <p>
                          Where the Issuer is other than an LLP, the Issuer must have a minimum of:
                        </p>
                        <div class="lowerroman-list">
                          <ol>
                            <li>
                              <p>RM500,000.00 paid-up capital; and</p>
                            </li>
                            <li>
                              <p>RM500,000.00 of shareholders’ funds maintained at all times;</p>
                            </li>
                          </ol>
                        </div>
                      </li>
                      <!-- 3.1.f -->
                      <li>
                        <p>
                          Where an Issuer is an LLP, the Issuer must have a minimum capital of
                          RM500,000.00 maintained at all times;
                        </p>
                      </li>
                      <!-- 3.1.g -->
                      <li>
                        <p>
                          The Issuer complies with any other additional financial requirements
                          imposed by the SC on the Issuer at any time that commensurate with the
                          nature, operations and risks posed by the Issuer;
                        </p>
                      </li>
                      <!-- 3.1.h -->
                      <li>
                        <p>
                          The Issuer at all times have at least two (2) directors whose principal or
                          only place of residence is in Malaysia;
                        </p>
                      </li>
                      <!-- 3.1.i -->
                      <li>
                        <p>
                          The Issuer ensures that its directors and senior management are fit and
                          proper at all times;
                        </p>
                      </li>
                      <!-- 3.1.j -->
                      <li>
                        <p>
                          The Issuer’s directors and senior management must, in aggregate, own at
                          least 50% equity holding in the Issuer on the date of the issuance of the
                          Tokens;
                        </p>
                      </li>
                      <!-- 3.1.k -->
                      <li>
                        <p>
                          Post issuance of the Tokens, the Issuer’s directors and senior management
                          may only sell, transfer or assign not more than 50% of their respective
                          equity holding until completion of TCF project. For the avoidance of
                          doubt, directors and senior management include those who are subsequently
                          appointed as a director or senior management post issuance of the Tokens.
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>
                <!-- 3.2 -->
                <li>
                  <p>
                    The applicant hereby agrees to allow the Platform Operator to carry out any
                    identity, credit and fraud checks on the applicant and its officers, directors,
                    major shareholders, employees and representatives (including but not limited to
                    the Authorised Representatives). The Platform Operator may engage the service of
                    any third-party service providers to conduct such checks. When requested by the
                    Platform Operator, the applicant and its officers, directors, major
                    shareholders, employees and representatives (including but not limited to the
                    Authorised Representatives) shall promptly submit their identity documents, bank
                    details and any other information and/or documents that the Platform Operator
                    may reasonably request in order to assess the Application.
                  </p>
                </li>
                <!-- 3.3 -->
                <li>
                  <p>
                    In the event the information and/or documents supplied to the Platform Operator
                    are insufficient or are not supplied to the satisfaction of the Platform
                    Operator to confirm the identity of the relevant persons and relevant checks are
                    unable to be conducted to the satisfaction of the Platform Operator, the
                    Platform Operator shall have the indisputable right not to approve the
                    Application and/or not to permit the Issuer to carry out the Hosting.
                  </p>
                </li>
                <!-- 3.4 -->
                <li>
                  <p>
                    The applicant may be approved as an Issuer upon notified by the Platform
                    Operator in writing if the applicant successfully fulfils Clause 3 and any other
                    requirement deemed necessary by the Platform Operator.
                  </p>
                </li>
                <!-- 3.5 -->
                <li>
                  <p>
                    Upon approval as an Issuer, the Issuer shall sign up on the Platform with a
                    username and email address, enter a secure password and fulfil such other
                    security processes as may be required on the Platform in order to log into the
                    Platform. The login details are personal to the Issuer and are not transferable.
                  </p>
                </li>
                <!-- 3.6 -->
                <li>
                  <p>
                    The Issuer shall not seek a Hosting of its Tokens with another Recognized Market
                    Operator until the expiry of a period of six (6) months from the date of the
                    Token Crowdfunding agreement being executed by the Issuer or until the Hosting
                    is terminated by either party, whichever is later.
                  </p>
                </li>
                <!-- 3.7 -->
                <li>
                  <p>
                    Each Issuer shall ensure that only the Issuer and its Authorised Representatives
                    shall have access to the Platform by using the Issuer’s login details. Any login
                    to the Platform by using the Issuer’s login details shall be deemed to have been
                    made by the Issuer. The Issuer shall not and shall ensure that its Authorised
                    Representatives do not misuse its login details and the Platform to the
                    detriment of the Platform Operator and the Platform. The Issuer shall be
                    responsible for all information and activities carried out on the Platform by
                    anyone using the Issuer’s login details. The Issuer shall immediately notify the
                    Platform Operator in writing of any breach of security, loss, theft or
                    unauthorised use of the login details of the Issuer.
                  </p>
                </li>
                <!-- 3.8 -->
                <li>
                  <p>
                    The Issuer and its Authorised Representatives shall only use and access the
                    Platform for the purposes contemplated by these Terms and for no other purposes,
                    and they hereby agree not to adapt or circumvent the systems in place in
                    connection with the Platform, nor access the Platform for any unauthorised,
                    malicious, illegal or fraudulent purpose. The Platform Operator reserves the
                    right at its sole discretion not to act on any instructions received from the
                    Issuer where the Platform Operator in its reasonable opinion, suspects any
                    unauthorised, malicious, illegal or fraudulent activity.
                  </p>
                </li>
                <!-- 3.9 -->
                <li>
                  <p>
                    The Platform Operator reserves the right at its sole discretion, at any time and
                    from time to time, to prevent, stop and/or disallow by any means, any Issuer to
                    continue using the Platform without assigning any reason whatsoever, and/or to
                    revoke the registration status and deactivate the account of the Issuer from the
                    Platform.
                  </p>
                </li>
                <!-- 3.10 -->
                <li>
                  <p>
                    The revocation of the status of the Issuer under these Terms shall not result in
                    a termination of any contract or other agreement to which the Issuer is a party
                    at that time.
                  </p>
                </li>
                <!-- 3.11 -->
                <li>
                  <p>
                    The Platform Operator reserves the right to waive any of the requirements in
                    Section 3. For Section 3.1, waiver by the Platform Operator will be subjected to
                    a confirmation of waiver and approval by the SC.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 4 -->
              <h2>APPROVAL FOR HOSTING</h2>

              <ol>
                <!-- 4.1 -->
                <li>
                  <p>
                    Before an Issuer may carry out a Hosting, the Issuer shall submit the Proposal,
                    together with such other information and documents as may be requested by the
                    Platform Operator, to the Platform Operator in a form and manner as may be
                    requested by the Platform Operator, for its Review.
                  </p>
                </li>
                <!-- 4.2 -->
                <li>
                  <p>
                    The Issuer must ensure that the Proposal complies with the TCF Guidelines and
                    any other Platform requirements deemed necessary by the Platform Operator in
                    making an application to raise funds through a TCF exercise.
                  </p>
                </li>
                <!-- 4.3 -->
                <li>
                  <p>
                    Prior to each Hosting, the Issuer shall inform all of the Issuer’s current
                    shareholders of the Proposal in accordance with the Issuer’s current Memorandum
                    and Articles of Association or Constitution and any relevant agreement(s)
                    between the Issuer and its shareholders, and ensure that all and any necessary
                    approvals and/or consents for the Hosting are obtained and provided to the
                    Platform Operator immediately upon request (including where applicable, any
                    waivers of pre-emption rights).
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 5 -->
              <h2>THE PROPOSAL</h2>

              <ol>
                <!-- 5.1 -->
                <li>
                  <p>
                    The Issuer shall ensure that the Proposal contains all information and documents
                    which may be relevant to an Investor to make an informed decision to invest on
                    the Hosting.
                  </p>
                </li>
                <!-- 5.2 -->
                <li>
                  <p>
                    Subject to the Guidelines and the TCF Guidelines (as may be varied, amended or
                    supplemented from time to time), the Issuer shall submit the relevant
                    information and documents to the Platform Operator for the purpose of the
                    Review, including but not limited to the following:
                  </p>

                  <div class="alpha-list">
                    <ol>
                      <li>
                        <p>
                          a fit and proper declaration of all the Issuer’s directors and senior
                          management;
                        </p>
                      </li>
                      <li>
                        <p>
                          the white paper which complies with the TCF Guidelines, which includes the
                          following information:
                        </p>
                        <div class="lowerroman-list">
                          <ol>
                            <li>
                              <p>
                                brief description of the Issuer’s directors, senior management, key
                                personnel and advisers including name, designation, nationality,
                                address, professional qualifications and related experience;
                              </p>
                            </li>
                            <li>
                              <p>
                                the objective or purpose of the TCF, including detailed information
                                on the TCF Project to be managed and operate by the Issuer;
                              </p>
                            </li>
                            <li>
                              <p>the key characteristics of the Token;</p>
                            </li>
                            <li>
                              <p>
                                detailed description of the sustainability and scalability of the
                                TCF Project;
                              </p>
                            </li>
                            <li>
                              <p>the business plan of the Issuer;</p>
                            </li>
                            <li>
                              <p>
                                the targeted amount to be raised through the TCF, and subsequent use
                                and application of the proceeds thereafter illustrated in a
                                scheduled timeline for drawdown and utilisation of proceeds
                                (schedule of proceeds);
                              </p>
                            </li>
                            <li>
                              <p>
                                any rights, conditions or functions attached to Tokens including any
                                specific rights attributed to a token holder;
                              </p>
                            </li>
                            <li>
                              <p>
                                discussion on the determination of the accounting and valuation
                                treatments for the digital tokens including all valuation
                                methodology and reasonable presumptions adopted in such calculation;
                              </p>
                            </li>
                            <li>
                              <p>
                                associated challenges and risks as well as mitigating measures
                                thereof;
                              </p>
                            </li>
                            <li>
                              <p>
                                information in relation to the distribution of the Tokens and where
                                applicable, the distribution policy of the Issuer;
                              </p>
                            </li>
                            <li>
                              <p>
                                a technical description of the protocol, platform or application of
                                the Token, as the case may be, and the associated benefits of the
                                technology;
                              </p>
                            </li>
                            <li>
                              <p>audited financial statements of the Issuer; and</p>
                            </li>
                            <li>
                              <p>a statement of disclaimer as follows:</p>

                              <div class="alpha-list">
                                <ol>
                                  <li>
                                    <p>
                                      “the furnishing on this white paper to the Securities
                                      Commission Malaysia should not be taken to indicate that the
                                      Securities Commission Malaysia assumes responsibility for the
                                      correctness of any statement made in this white paper.”
                                    </p>
                                  </li>
                                </ol>
                              </div>
                            </li>
                          </ol>
                        </div>
                      </li>
                      <li>
                        <p>
                          In addition to the requirements in 5.2(B) herewith, the white paper must
                          also contain the following statement: "Investors are reminded that Bank
                          Negara Malaysia (the Bank) does not recognise digital tokens as legal
                          tender nor as a form of payment instrument that is regulated by the Bank
                          and that the Bank will not provide any avenues of redress for aggrieved
                          token holders”.
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>
                <!-- 5.3 -->
                <li>
                  <p>
                    Where a white paper has been furnished to the Platform Operator and the SC, but
                    before the closing of the offer period, the Issuer becomes aware that:
                  </p>

                  <div class="alpha-list">
                    <ol>
                      <li>
                        <p>
                          A matter has arisen and information in respect of that matter would have
                          been required by the TCF Guidelines to be disclosed in white paper if the
                          matter has arisen at the time the white paper was prepared;
                        </p>
                      </li>
                      <li>
                        <p>
                          There has been material change affecting a matter disclosed in the white
                          paper; or
                        </p>
                      </li>
                      <li>
                        <p>
                          The white paper contains a statement or information that is false or
                          misleading, or from which there is a material omission,
                        </p>
                      </li>
                    </ol>
                  </div>

                  <p>
                    The Issuer must notify the Platform Operator, and, as soon as practicable,
                    furnish a supplementary white paper to the Platform Operator and the SC.
                  </p>
                </li>
                <!-- 5.4 -->
                <li>
                  <p>
                    Upon completion of the Review, the Platform Operator may, in its sole absolute
                    discretion, approve or reject the Proposal:
                  </p>

                  <div class="alpha-list">
                    <ol>
                      <li>
                        <p>
                          In the event the Proposal shall be rejected, the Issuer shall not conduct
                          the Hosting, and the Platform Operator shall have the right to revoke the
                          registration status of the Issuer and/or to deactivate the account of the
                          Issuer from the Platform, and the Issuer may submit a fresh Application
                          and fresh Proposal; and
                        </p>
                      </li>
                      <li>
                        <p>
                          In the event the Proposal shall be approved, the Issuer shall proceed with
                          the Hosting in accordance with the terms of approval of the Platform by
                          issuing the Offer Documents. For the avoidance of doubt, the Platform
                          Operator shall be entitled to impose terms and conditions on the approval
                          of the Proposal. When requested by the Platform Operator from time to
                          time, the Issuer shall promptly make such adjustments, amendments,
                          variations, modifications or supplements to the Offer Documents.
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>
                <!-- 5.5 -->
                <li>
                  <p>
                    The Issuer and its Authorised Representatives agree that they alone shall bear
                    all responsibility for the content of the Proposal and the Offer Documents and
                    each of the Issuer and its Authorised Representatives shall, jointly and
                    severally, indemnify the Platform Operator against all claims, liabilities,
                    demands, penalties, costs, expenses, damages and losses of whatsoever nature
                    (including but not limited to any direct, indirect or consequential losses, loss
                    of profit, loss of reputation and all interest, penalties and legal costs and
                    all other professional costs and expenses) suffered or incurred by, arising out
                    of or in connection with the Proposal and the Offer Documents (and the contents
                    thereof).
                  </p>
                </li>
                <!-- 5.6 -->
                <li>
                  <p>
                    The Issuer hereby represents and warrants in respect of the Proposal and Offer
                    Documents as follows:
                  </p>

                  <div class="alpha-list">
                    <ol>
                      <li>
                        <p>
                          that the Issuer complies with the requirements of the TCF Guidelines at
                          all times;
                        </p>
                      </li>

                      <li>
                        <p>
                          ensures that it does not submit or provide any document or information
                          that is false or misleading, or from which there is material omission;
                        </p>
                      </li>

                      <li>
                        <p>
                          in relation to the requirement of 3.1(i), submit to the Platform Operator
                          a fit and proper declaration of its directors and senior management within
                          three (3) business days from the appointment or reappointment of the
                          director or senior management; and
                        </p>
                      </li>

                      <li>
                        <p>
                          immediately notify the Platform Operator and the SC of any failure to
                          comply with any requirement the TCF Guidelines.
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>
                <!-- 5.7 -->
                <li>
                  <p>
                    The Issuer shall retain all relevant documents and agreement related to the TCF
                    Project and Hosting for a period of seven (7) years from the date the documents
                    or agreements are submitted to the Platform Operator.
                  </p>
                </li>
                <!-- 5.8 -->
                <li>
                  <p>
                    The Issuer must provide to the SC any information or assistance relating to the
                    Tokens as the SC deems necessary.
                  </p>
                </li>
                <!-- 5.9 -->
                <li>
                  <p>
                    The Issuer must provide a confirmation to the SC that the drawdowns have been
                    utilised for the purposes stated in the white paper in such form and manner as
                    the SC may specify.
                  </p>
                </li>
                <!-- 5.10 -->
                <li>
                  <p>
                    The Issuer must not make any changes to the utilisation of the proceeds post the
                    issuance of the Tokens or any changes that affect the Token holder’s rights,
                    unless prior approval, of the token holders representing 75% of the total amount
                    of Tokens held by all Token holders present and voting has been obtained.
                  </p>
                </li>
                <!-- 5.11 -->
                <li>
                  <p>
                    The Issuer must establish a framework which sets out the policies and procedures
                    to effectively and efficiently manage issues of conflict of interest including
                    potential conflicts of interest and any related party transactions which may
                    arise in the course of the TCF Project.
                  </p>
                </li>
                <!-- 5.12 -->
                <li>
                  <p>
                    Where material change occurs affecting the TCF, the TCF Project or the Issuer,
                    the Issuer must, immediately upon knowing or becomes aware of the event, inform
                    the Platform Operator of such change.
                  </p>
                </li>
                <!-- 5.13 -->
                <li>
                  <p>
                    In addition to 5.12 herewith, post issuance of the Tokens, the Issuer must also
                    immediately notify the Platform Operator of any sale, transfer or assignment
                    involving five (5) per centum or more shareholding of the Issuer.
                  </p>
                </li>
                <!-- 5.14 -->
                <li>
                  <p>
                    The Issuer hereby assigns absolutely to the Platform Operator all title,
                    benefits and interest relating to copyright on the contents published on the
                    Platform in respect of the Hosting, including but not limited to texts,
                    graphics, drawings, layouts and photographs and the Platform Operator may
                    reproduce whether in whole and in part such content.
                  </p>
                </li>
                <!-- 5.15 -->
                <li>
                  <p>
                    The Issuer acknowledges that if the Platform Operator approves the Proposal, the
                    Platform Operator may include in the Proposal and the Offer Documents such
                    information as the Platform Operator may in its absolute discretion deem
                    appropriate including (without limitation) a disclaimer of its liability and a
                    risk warning to Investors.
                  </p>
                </li>
                <!-- 5.16 -->
                <li>
                  <p>
                    The Issuer acknowledges that the Proposal and/or Offer Documents will be
                    reviewed by potential Investors and that whilst the Platform Operator attempts
                    to impose duties of confidentiality on such persons, the Platform Operator
                    cannot guarantee that the information contained in the Proposal and/or Offer
                    Documents will remain confidential. The Platform Operator accepts no
                    responsibility for any such information coming into the public domain by reason
                    of the acts of third parties, potential Investors or otherwise save for where
                    this occurs by reason of the negligence, fraud or breach of these Terms by the
                    Platform Operator.
                  </p>
                </li>
                <!-- 5.17 -->
                <li>
                  <p>
                    Where there are any changes to the information contained in the Proposal and/or
                    the Offer Documents, the Issuer shall inform the Platform Operator of this fact
                    immediately in writing.
                  </p>
                </li>
              </ol>
            </li>

            <!-- 6 -->
            <li>
              <h2>CAMPAIGN</h2>

              <ol>
                <!-- 6.1 -->
                <li>
                  <p>
                    Upon approval by the Platform Operator and the SC, the Issuer may proceed to
                    Hosting.
                  </p>
                </li>
                <!-- 6.2 -->
                <li>
                  <p>
                    The Issuer may run any marketing campaign in respect of the Hosting to attract
                    Investors, provided that the Issuer shall obtain the Platform Operator’s prior
                    approval in writing in respect of each marketing campaign (whether or not to be
                    run on the Platform) before running it. The Platform Operator reserves the right
                    to approve or reject any marketing campaign in its absolute discretion. When
                    requested, the Issuer shall vary, amend and/or supplement the marketing campaign
                    materials in accordance with the recommendations of the Platform Operator.
                  </p>
                </li>
                <!-- 6.3 -->
                <li>
                  <p>
                    The Issuer shall link any campaign run outside the Platform to the Platform
                    Operator and the Platform.
                  </p>
                </li>
                <!-- 6.4 -->
                <li>
                  <p>
                    In addition to the above, potential Investors may post questions to the Issuers
                    on the Platform. The Issuers shall answer such questions with answers that shall
                    be true, accurate, complete, current, not misleading and do not contain any
                    material omission, and if required by the Platform Operator, to provide
                    supporting evidence for such answers.
                  </p>
                </li>
                <!-- 6.5 -->
                <li>
                  <p>
                    The Issuer must ensure that all information disseminated for marketing or
                    promotion is consistent with the contents of its white paper and are
                    appropriately displayed in all marketing and promotional materials including its
                    website.
                  </p>
                </li>
                <!-- 6.6 -->
                <li>
                  <p>
                    The Issuer must not engage any third-party individual(s) or entity, other that
                    the Platform Operator, to endorse or represent the Issuer with the intended
                    purpose of marketing, promoting, gaining publicity or soliciting funds for its
                    TCF.
                  </p>
                </li>
              </ol>
            </li>

            <!-- 7 -->
            <li>
              <h2>REGISTRATION AS INVESTORS</h2>

              <ol>
                <!-- 7.1 -->
                <li>
                  <p>
                    The portions of the Platform that relate to the viewing of Hostings or to making
                    investments on the Issuers are available only to certain qualified, registered
                    and authorised Users of the Platform, who are registered as Investors.
                  </p>
                </li>
                <!-- 7.2 -->
                <li>
                  <p>
                    Pursuant to the Guidelines and TCF Guidelines, Investors shall be categorised
                    into three (3) categories:
                  </p>

                  <div class="alpha-list">
                    <ol>
                      <li>
                        <p>Sophisticated Investors;</p>
                      </li>
                      <li>
                        <p>Angel Investors; and</p>
                      </li>
                      <li>
                        <p>Retail Investors.</p>
                      </li>
                    </ol>
                  </div>
                </li>
                <!-- 7.3 -->
                <li>
                  <p>
                    Sophisticated Investors are individuals and entities that meet the requirements
                    in the SC’s Guidelines on Categories of Sophisticated Investors
                    (https://www.sc.com.my/regulation/guidelines/categories-of-
                    sophisticated-investors)
                  </p>
                </li>
                <!-- 7.4 -->
                <li>
                  <p>
                    Angel Investors refers to individuals with an income of RM180,000 per annum or
                    above (RM15,000 per month or above) or an individual who, jointly with his or
                    her spouse, has a gross annual income of RM250,000 per annum or above.
                  </p>
                </li>
                <!-- 7.5 -->
                <li>
                  <p>
                    Retail Investors are all other individuals above the age of 18 and corporations
                    and partnerships that are legally able to invest in businesses, and who falls
                    outside the definition of sophisticated investors and angel investors.
                  </p>
                </li>
                <!-- 7.6 -->
                <li>
                  <p>
                    Persons who are resident outside of Malaysia are allowed access to Hostings and
                    investment opportunities on the Platform only if such access does not violate
                    the laws of their country of residence. The portions of the Platform that relate
                    to the viewing of Hostings or to making investments on the Issuers may not be
                    used by any person or entity in any jurisdiction where the provision or use
                    thereof would be contrary to applicable laws, rules or regulations of any
                    governmental authority or where the Platform Operator or one of its affiliates
                    is not authorized to provide such information or services.
                  </p>
                </li>
                <!-- 7.7 -->
                <li>
                  <p>
                    The registration of the Investor on the Platform shall be subject to the
                    approval of the Platform Operator. The Platform Operator shall have the sole
                    discretion to approve or to reject the User’s application to become an Investor
                    on the Platform and shall not be required to provide any reasons for its
                    decisions. The Platform Operator may attach conditions to its acceptance of the
                    Investor’s registration.
                  </p>
                </li>
                <!-- 7.8 -->
                <li>
                  <p>
                    The Investor hereby agrees to allow the Platform Operator to carry out any
                    identity, credit and fraud checks on the Investor and where appropriate its
                    officers, directors, employees and representatives. The Platform Operator may
                    engage the service of any third-party service providers to conduct such checks.
                    When requested by the Platform Operator, the Investor and where applicable, its
                    officers, directors, employees and representatives shall promptly submit their
                    identity documents, bank details and any other information and/or documents that
                    the Platform Operator may reasonably request, to enable the completion of
                    Anti-Money Laundering (AML) and Know-Your-Customer (KYC) processes as required
                    under the applicable laws and regulations.
                  </p>
                </li>
                <!-- 7.9 -->
                <li>
                  <p>
                    Each Investor shall ensure that only the Investor shall have access to the
                    Platform by using his own login details. Any login to the Platform by using the
                    Investor’s login details shall be deemed to have been made by the Investor. The
                    Investor shall not misuse its login details and the Platform to the detriment of
                    the Platform Operator and the Platform. The Investor shall be responsible for
                    all information and activities carried out on the Platform by anyone using the
                    Investor’s login details. The Investor shall immediately notify the Platform
                    Operator in writing of any breach of security, loss, theft or unauthorised use
                    of the login details of the Investor.
                  </p>
                </li>
                <!-- 7.10 -->
                <li>
                  <p>
                    The Investor shall only use and access the Platform for the purposes
                    contemplated by these Terms and for no other purposes, and they hereby agree not
                    to adapt or circumvent the systems in place in connection with the Platform, nor
                    access the Platform for any unauthorised, malicious, illegal or fraudulent
                    purpose. The Platform Operator reserves the right at its sole discretion not to
                    act on any instructions received from the Investor where the Platform Operator
                    in its reasonable opinion, suspects any unauthorised, malicious, illegal or
                    fraudulent activity.
                  </p>
                </li>
                <!-- 7.11 -->
                <li>
                  <p>
                    The Investor hereby assigns absolutely to the Platform Operator all title,
                    benefits and interest relating to copyright on the contents published on the
                    Platform by the Investor, including but not limited to texts, graphics,
                    drawings, layouts and photographs.
                  </p>
                </li>
                <!-- 7.12 -->
                <li>
                  <p>
                    The Platform Operator reserves the right at its sole discretion, at any time and
                    from time to time, to prevent, stop and/or disallow by any means, any Investor
                    to continue using the Platform without assigning any reason whatsoever, and/or
                    to revoke the registration status and deactivate the account of the Investor
                    from the Platform.
                  </p>
                </li>
                <!-- 7.13 -->
                <li>
                  <p>
                    The revocation of the status of the Investor under these Terms shall not result
                    in a termination of any contract or other agreement to which the Investor is a
                    party at that time.
                  </p>
                </li>
              </ol>
            </li>

            <!-- 8 -->
            <li>
              <h2>INVESTMENT LIMITS</h2>

              <ol>
                <!-- 8.1 -->
                <li>
                  <p>
                    There is no restriction on the investment amount that may be made by a
                    Sophisticated Investor.
                  </p>
                </li>
                <!-- 8.2 -->
                <li>
                  <p>
                    An Angel Investor is allowed to invest a maximum of RM500,000.00 within a
                    12-month period on the Platform and any other TCF platform.
                  </p>
                </li>
                <!-- 8.3 -->
                <li>
                  <p>
                    A Retail Investor is only allowed to invest a maximum of RM2,000.00 per Issuer
                    with a total investment limit not exceeding RM20,000.00 within a 12-month period
                    on the Platform and any other TCF platform.
                  </p>
                </li>
                <!-- 8.4 -->
                <li>
                  <p>
                    The investment limits specified in Clause 8 are applicable to local and foreign
                    investors.
                  </p>
                </li>
              </ol>
            </li>

            <!-- 9 -->
            <li>
              <h2>DECLARATION BY INVESTORS</h2>

              <ol>
                <!-- 9.1 -->
                <li>
                  <p>
                    The Investor shall declare his own Investor category and any investment made on
                    any TCF platform for the immediately preceding 12-month period before making the
                    investment on each Hosting. That declaration places the responsibility of
                    truthfulness upon the Investor.
                  </p>
                </li>
                <!-- 9.2 -->
                <li>
                  <p>
                    The Platform Operator shall not be held responsible to any person for any false
                    declarations made by the Investor and the Investor shall indemnify and keep
                    indemnified in full the Platform Operator against all losses, costs, expenses,
                    damages, penalties, fines, liabilities or claims of whatsoever nature that may
                    be suffered or incurred by the Platform Operator as a result of any false
                    declarations made by the Investor. In addition, prior to making any investment
                    through the Platform, the Investor may be required to provide any information
                    and/or supporting documents that provide proof that the Investor is a
                    Sophisticated or Angel Investor. The Investor’s failure to provide any
                    information and documentation requested to confirm the Investor’s status as a
                    Sophisticated or Angel Investor shall be cause for the Platform Operator to
                    immediately discontinue the Investor’s use of the Platform.
                  </p>
                </li>
              </ol>
            </li>

            <!-- 10 -->
            <li>
              <h2>INVESTMENT PROCESS</h2>

              <ol>
                <!-- 10.1 -->
                <li>
                  <p>
                    The Investors shall be entitled to participate in a Hosting by depositing the
                    TCF Funds into the Trust Account during the Offer Period.
                  </p>
                </li>
                <!-- 10.2 -->
                <li>
                  <p>The Offer Period shall end on the earlier of the following:</p>

                  <div class="alpha-list">
                    <ol>
                      <li>
                        <p>the Targeted Amount is raised; or</p>
                      </li>
                      <li>
                        <p>
                          the last date of the Offer Period as set out in the Offer Documents or on
                          the Hosting webpage,
                        </p>
                      </li>
                      <li>
                        <p>
                          and for the avoidance of doubt, in case of paragraph (a) above, in the
                          event the Targeted Amount is not maintained at the expiry of the
                          Cooling-Off Period, and subject to the prior written approval of the
                          Platform Operator, the Offer Period may be extended to the last date of
                          the Offer Period as set out in the Offer Documents or on the Hosting
                          webpage (<strong>“Extended Offer Period”</strong>).
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>
                <!-- 10.3 -->
                <li>
                  <p>During the Offer Period, the Issuer shall not:</p>

                  <div class="alpha-list">
                    <ol>
                      <li>
                        <p>
                          communicate any offer or inducement to engage in investment activity to
                          any person other than to the Investors on the Platform; and
                        </p>
                      </li>
                      <li>
                        <p>
                          participate in any other TCF platform or engage in discussion with any
                          registered market operator.
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>
                <!-- 10.4 -->
                <li>
                  <p>
                    The Issuer shall indemnify the Platform Operator against all claims, demands,
                    liabilities, costs, expenses, damages, fines, penalties and losses of whatsoever
                    nature (including but not limited to any direct, indirect or consequential
                    losses, loss of profit, loss of reputation and all interest, penalties and legal
                    costs and all other professional costs and expenses) suffered or incurred by,
                    arising out of or in connection with a breach of these Terms by the Issuer.
                  </p>
                </li>
                <!-- 10.5 -->
                <li>
                  <p>
                    Where the Targeted Amount has been met whether on or before the expiry of the
                    Offer Period or the Extended Offer Period (if applicable), the Issuer shall
                    allow the Cooling-Off Period to expire before the Targeted Amount raised may be
                    released to the Issuer.
                  </p>
                </li>
                <!-- 10.6 -->
                <li>
                  <p>
                    During the Cooling-Off Period, the Investors are allowed to withdraw their TCF
                    Funds, free of interest, and any other charges imposed on the day the Token was
                    purchased, from the Trust Account by putting a written request to the Platform
                    Operator on or before the expiry of the Cooling-Off Period. The refund of the
                    TCF Funds to the Investor shall be contingent on the Trustee releasing the TCF
                    Funds to the Platform Operator or the Investor.
                  </p>
                </li>
                <!-- 10.7 -->
                <li>
                  <p>
                    If the Hosting is successful (i.e., the Targeted Amount has been raised and
                    remains so upon the expiry of the Cooling-Off Period), a contract for
                    subscription and issuance of Tokens (<strong>“Contract”</strong>) shall arise
                    between the Issuer and the relevant Investors. The Issuer shall procure the
                    relevant persons to execute all necessary acts, deeds and actions to give effect
                    to the Hosting and Offer Documents before the Targeted Amount raised may be
                    released to the Issuer. The Investor shall consent and authorise the Platform
                    Operator and/or the Issuer to open a Token-holding account on any Token-storage
                    mechanism for the purpose of maintaining custody over the Tokens, whether
                    operated by the Platform Operator or otherwise. The Investor acknowledges that
                    this consent and authorisation may be more convenient and easier to coordinate
                    for the purpose of Token allocation, bearing in mind that there may be a large
                    group of Investors who have similarly invested in the TCF Exercise.
                  </p>
                </li>
                <!-- 10.8 -->
                <li>
                  <p>
                    To facilitate the process in 10.7, the Investor further consents to the use and
                    processing of their personal data by Platform Operator and/or the Issuer for the
                    opening of the Token-holding accounts.
                  </p>
                </li>
                <!-- 10.9 -->
                <li>
                  <p>
                    In the event the Targeted Amount is not met or the Targeted Amount is not
                    maintained at the expiry of the Cooling-Off Period, the Hosting shall be deemed
                    unsuccessful and the Trust Funds shall be refunded free of interest to the
                    relevant Investors after the expiry of the Cooling-Off Period. The refund of the
                    Trust Funds to the relevant persons shall be contingent on the Trustee releasing
                    the funds.
                  </p>
                </li>
              </ol>
            </li>

            <!-- 11 -->
            <li>
              <h2>LIMIT TO FUND RAISING</h2>

              <ol>
                <!-- 11.1 -->
                <li>
                  <p>
                    The Issuer shall only utilise the Platform to raise a maximum amount, amounting
                    to the following:
                  </p>
                  <table class="table table-bordered">
                    <tr>
                      <td>
                        Issuer’s shareholders’ funds or in the case of an LLP, Issuer’s capital
                      </td>
                      <td>X</td>
                      <td>Multiple (20)</td>
                      <td>=</td>
                      <td>
                        Maximum quantum of fund permitted to be raised within any continuous
                        12-month period, subject to a ceiling of RM100 million
                      </td>
                    </tr>
                  </table>
                </li>
                <!-- 11.2 -->
                <li>
                  <p>
                    The Issuer must demonstrate to the Platform Operator that the gross proceeds to
                    be raised from the TCF would be sufficient to undertake the TCF project as
                    proposed in the white paper.
                  </p>
                </li>
                <!-- 11.3 -->
                <li>
                  <p>
                    The Tokens issued by the Issuer must be fully subscribed, and an Issuer is not
                    allowed to keep any amount exceeding the target amount raised, unless approved
                    by the Platform Operator and SC.
                  </p>
                </li>
                <!-- 11.4 -->
                <li>
                  <p>
                    In the event the Issuer is unable to comply with the requirement in 11.3, the
                    Platform Operator shall refund all monies collected from the Token holders
                    within six (6) business days from the date of the closing of the offer period.
                  </p>
                </li>
              </ol>
            </li>

            <!-- 12 -->
            <li>
              <h2>FEES</h2>

              <ol>
                <!-- 12.1 -->
                <li>
                  <p>
                    The Issuer shall pay the following fees (inclusive of Goods and Services Tax) to
                    the Platform Operator (together read as the <strong>“Fees”</strong>):
                  </p>

                  <div class="alpha-list">
                    <ol>
                      <li>
                        <p>
                          A non-refundable fee to be paid to the TCF Operator upon the execution of
                          this Agreement to start the onboarding process (<strong
                            >“Onboarding Fee”</strong
                          >).
                        </p>
                      </li>
                      <li>
                        <p>
                          A percentage of the Total Investment Amount to be paid to the TCF Operator
                          in the event of a Successful Campaign (<strong>“Success Fees”</strong>).
                        </p>
                      </li>
                      <li>
                        <p>
                          A fee to be paid to the TCF Operator in the event of a Successful Campaign
                          to conduct an independent review of the relevant codes and / or programs
                          relating to the Tokens (<strong>“Smart Contract Audit Fees”</strong>).
                        </p>
                      </li>
                      <li>
                        <p>
                          A fee to be paid annually to the TCF Operator commencing from the date of
                          a Successful Campaign until the expiry of the Term (<strong
                            >“Post-Campaign Management Fees”</strong
                          >).
                        </p>
                      </li>
                      <li>
                        <p>
                          Any other costs and expenses as may be advanced by the TCF Operator,
                          provided that it has been mutually agreed by the Parties (<strong
                            >“Other Fees”</strong
                          >).
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>
                <!-- 12.2 -->
                <li>
                  <p>
                    The fee schedule will be reflected on the Platform Operator’s website
                    (https://www.pitchin.my/fee-structure), and to be agreed upon by the Issuer and
                    Platform Operator before the execution of the TCF agreement.
                  </p>
                </li>
                <!-- 12.3 -->
                <li>
                  <p>
                    The Issuer acknowledges that ancillary charges or fees, including legal fees,
                    may be payable by the Issuer to third parties in connection with the Proposal,
                    Offer Documents and/or hosting. The Issuer agrees to indemnify the Platform
                    Operator against any claims, losses, costs or expenses resulting from a breach
                    of these Terms.
                  </p>
                </li>
                <!-- 12.4 -->
                <li>
                  <p>
                    The Platform Operator shall be entitled to deduct the Fees and any amount owing
                    from the Issuer to the Platform Operator from the Targeted Amount raised before
                    releasing such amount to the Issuer.
                  </p>
                </li>
              </ol>
            </li>

            <!-- 13 -->
            <li>
              <h2>WARRANTIES AND UNDERTAKINGS</h2>

              <ol>
                <!-- 13.1 -->
                <li>
                  <p>
                    The Issuer and its Authorised Representatives warrant and represent to the
                    Platform Operator as follows:
                  </p>

                  <div class="alpha-list">
                    <ol>
                      <li>
                        <p>
                          the Issuer is an entity authorised by the TCF Guidelines to participate in
                          an TCF Exercise;
                        </p>
                      </li>
                      <li>
                        <p>
                          in respect of each hosting, the Issuer does not breach the limit to fund
                          raising prescribed by the Guidelines and TCF Guidelines; and
                        </p>
                      </li>
                      <li>
                        <p>
                          the investment structure contained in the Hosting complies with all legal
                          and regulatory requirements (relating to financial promotions or
                          otherwise).
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>

                <!-- 13.2 -->
                <li>
                  <p>The Investor warrants and represents to the Platform Operator as follows:</p>

                  <div class="alpha-list">
                    <ol>
                      <li>
                        <p>he is a natural person of full age and of sound mind;</p>
                      </li>
                      <li>
                        <p>
                          it is a company duly incorporated in accordance with the laws of its place
                          of incorporation (if applicable); and
                        </p>
                      </li>
                      <li>
                        <p>
                          the declaration as may be made from time to time by the Investor pursuant
                          to Clause 9 is true, accurate, complete, not misleading and does not
                          contain material omission.
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>

                <!-- 13.3 -->
                <li>
                  <p>
                    The Issuer and its Authorised Representatives, and the Investor severally
                    represent and warrant to the Platform Operator as follows:
                  </p>

                  <div class="alpha-list">
                    <ol>
                      <li>
                        <p>
                          all information and documents provided to the Platform Operator is true,
                          accurate, complete, current, not misleading and does not contain any
                          material omission, in all respects and that they shall update the Platform
                          Operator if any of the information they have provided is not up-to-date;
                        </p>
                      </li>
                      <li>
                        <p>
                          there is no action, suit or proceeding at law or in equity or before any
                          court, tribunal, governmental body, agency or official (whether pending or
                          threatened) that is likely to affect the legality, validity or
                          enforceability of these Terms, or affect its ability to perform its
                          obligations hereunder;
                        </p>
                      </li>
                      <li>
                        <p>
                          they shall be personally responsible for the use and access of the
                          Platform using their account or login details;
                        </p>
                      </li>
                      <li>
                        <p>they shall use and access the Platform at their own risk;</p>
                      </li>
                      <li>
                        <p>
                          they shall use and access the Platform in accordance with these Terms and
                          other terms and conditions of the Platform Operator, and in accordance
                          with any law for the time being in force in Malaysia, including but not
                          limited to the Communications and Multimedia Act 1998, and shall not use
                          the Platform for any unlawful purpose;
                        </p>
                      </li>
                      <li>
                        <p>
                          they shall not adapt, modify, copy, translate, distribute, transmit,
                          display, perform, reproduce, publish, license, transfer, download, post,
                          create derivative works from, framing and using, any contents belonging to
                          the Platform Operator or published by other Users on the Platform;
                        </p>
                      </li>
                      <li>
                        <p>
                          they shall not transfer and/or sell any information, software, user list,
                          database, texts, graphics, logos, photos, audio files, visual files and
                          other information, data and document provided through or obtained from the
                          Platform to any person;
                        </p>
                      </li>
                      <li>
                        <p>
                          they shall not decompile, reverse engineer or otherwise attempt to
                          discover the source code of the Platform, and/or any contents available on
                          the Platform;
                        </p>
                      </li>
                      <li>
                        <p>
                          they shall not, without written authorization from the Platform Operator,
                          access, hack into, interfere with, disrupt, disable, over-burden or
                          otherwise impair the proper functioning of the Platform, including but not
                          limited to the following means: denial-of-service attacks, spoof attacks,
                          session hacking, sniffing, tampering, reverse engineering or
                          reprogramming; and
                        </p>
                      </li>
                      <li>
                        <p>
                          they shall not initiate any transaction or transact, transmit or receive
                          payment transfers via the Platform for or in connection with any criminal
                          or illegal purpose or any purpose that may contravene any anti-money
                          laundering laws.
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>
              </ol>
            </li>

            <!-- 14 -->
            <li>
              <h2>THE ROLE OF PITCHIN</h2>

              <ol>
                <!-- 14.1 -->
                <li>
                  <p>
                    The Platform Operator performs an administrative role in matching Investors and
                    Issuers and distributing documentation and information between such parties. For
                    the avoidance of doubt, the Platform Operator is not a party to any contract
                    between the Investors and the Issuers (unless in its capacity as an Investor)
                    and it does not accept receipt of any monies from the Investors or from the
                    Issuers other than for payment of the Fees.
                  </p>
                </li>
                <!-- 14.2 -->
                <li>
                  <p>
                    The Platform Operator does not provide any advice (including but not limited to
                    investment, legal, taxation or other advice) with respect to any aspect of the
                    transactions conducted through the Platform, other than advice on the technical
                    use of the Platform. Nothing on the Platform or any communications sent to the
                    Users shall constitute or is intended to constitute advice.
                  </p>
                </li>
              </ol>
            </li>

            <!-- 15 -->
            <li>
              <h2>FORCE MAJEURE</h2>

              <ol>
                <!-- 15.1 -->
                <li>
                  <p>
                    The Platform Operator shall not be liable to any person and shall not be deemed
                    to be in breach of these Terms by reason of any delay in performing or failure
                    to perform any of its obligations under these Terms if the delay or failure was
                    caused by an event of force majeure.
                  </p>
                </li>
                <!-- 15.2 -->
                <li>
                  <p>
                    Without prejudice to the generality of the foregoing provision, force majeure
                    shall include but not limited to the following:
                  </p>

                  <div class="alpha-list">
                    <ol>
                      <li>
                        <p>
                          act of God, including but not limited to fires, explosions, earthquakes,
                          drought, tidal waves and floods, or accident;
                        </p>
                      </li>
                      <li>
                        <p>
                          war, threat of war, act of terrorism or threat of terrorism, sabotage,
                          insurrection, civil disturbance or requisition;
                        </p>
                      </li>
                      <li>
                        <p>
                          restrictions imposed by any law, regulations, by-laws, prohibitions or
                          measures of any kind on the part of any government, parliamentary or local
                          authority;
                        </p>
                      </li>
                      <li>
                        <p>
                          interruption of traffic, strikes, lock-outs, or other industrial actions
                          or trade disputes (whether involving the employees of the Platform
                          Operator or third party);
                        </p>
                      </li>
                      <li>
                        <p>
                          breakdown of internet services for any reason whatsoever, save for the
                          wilful misconduct of the Platform Operator;
                        </p>
                      </li>
                      <li>
                        <p>
                          other unforeseeable circumstances beyond the control of the Platform
                          Operator against which it would have been unreasonable for the Platform
                          Operator to take precautions and which the Platform Operator cannot avoid
                          even by using its best efforts.
                        </p>
                      </li>
                      <li>
                        <p>
                          Upon the occurrence of any force majeure event, the performance of the
                          Platform Operator’s obligations under these Terms shall be suspended
                          during that period of force majeure and the Platform Operator shall be
                          granted an extension of time for performance equal to the period of delay.
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>
              </ol>
            </li>

            <!-- 16 -->
            <li>
              <h2>TERMINATION</h2>

              <ol>
                <!-- 16.1 -->
                <li>
                  <p>
                    The Issuer shall not terminate the hosting and/or withdraw the Proposal fourteen
                    (14) days before the commencement of the Offer Period or at any time during the
                    Offer Period without the prior written consent of the Platform Operator.
                  </p>
                </li>
                <!-- 16.2 -->
                <li>
                  <p>
                    The Issuer must provide an explanation that complies with the Guidelines, TCF
                    Guidelines and any other SC requirements to the Platform Operator.
                  </p>
                </li>
                <!-- 16.3 -->
                <li>
                  <p>
                    The Platform Operator may terminate the hosting and/or the Proposal at any time
                    and for any reason, including but not limited to if:
                  </p>
                  <div class="alpha-list">
                    <ol>
                      <li>
                        <p>the Issuer or its Authorised Representative breaches these Terms; or</p>
                      </li>
                      <li>
                        <p>
                          the Platform Operator suspects that the Issuer or its Authorised
                          Representative has committed fraud, been involved in money laundering,
                          other criminal activities or any other improper activities; or
                        </p>
                      </li>
                      <li>
                        <p>
                          the Issuer or its Authorised Representative uses the Platform or any
                          information accessible on or obtained from it for the purpose of
                          canvassing or soliciting any person or enticing any person away from using
                          the Platform; or
                        </p>
                      </li>
                      <li>
                        <p>the Issuer becomes insolvent; or</p>
                      </li>
                      <li>
                        <p>
                          the Issuer or its Authorised Representative uses the Platform in any of
                          the following ways:
                        </p>

                        <div class="lowerroman-list">
                          <ol>
                            <li>
                              <p>
                                in any way that causes, or is likely to cause, the Platform or
                                access to it to be interrupted or damaged in any way;
                              </p>
                            </li>
                            <li>
                              <p>
                                for fraudulent purposes, or in connection with a criminal offence;
                              </p>
                            </li>
                            <li>
                              <p>
                                to send, use or reuse any material that is illegal, offensive,
                                abusive, indecent, defamatory, obscene or menacing; or in breach of
                                copyright, trademark, confidence, privacy or any other right; or is
                                otherwise injurious to third parties; or objectionable; or which
                                consists of or contains software viruses, political campaigning,
                                commercial solicitation, chain letters, mass mailings or any “spam”;
                                or
                              </p>
                            </li>
                            <li>
                              <p>
                                to cause annoyance, inconvenience or needless anxiety to any person.
                              </p>
                            </li>
                          </ol>
                        </div>
                      </li>
                    </ol>
                  </div>
                </li>
              </ol>
            </li>

            <!-- 17 -->
            <li>
              <h2>DISCLAIMERS</h2>

              <ol>
                <!-- 17.1 -->
                <li>
                  <p>
                    Each Issuer and each Investor shall only be permitted to open one account on the
                    Platform.
                  </p>
                </li>
                <!-- 17.2 -->
                <li>
                  <p>
                    Access to the Platform is permitted on a temporary basis subject to these Terms,
                    other terms and conditions, agreement or rules governing the specific services
                    provided by the Platform Operator. The Platform Operator reserves the right to
                    terminate, withdraw, amend or suspend the service provided or the account on the
                    Platform without notice. The Platform Operator will endeavour to allow
                    uninterrupted access to the Platform, but access may be suspended, restricted or
                    terminated from time to time. The Platform Operator shall not be liable to the
                    Issuer and Investor if for any reason the Platform is unavailable at any time or
                    for any period.
                  </p>
                </li>
                <!-- 17.3 -->
                <li>
                  <p>
                    The Platform (and the contents thereof) are not intended for distribution to, or
                    use by, any person or entity in any jurisdiction where such distribution or use
                    would be contrary to applicable law or regulation.
                  </p>
                </li>
                <!-- 17.4 -->
                <li>
                  <p>
                    The Platform Operator shall not be responsible or liable for the content or
                    accuracy of any information or material downloaded or posted by the Users nor
                    shall the Platform Operator be obliged to edit the content downloaded or posted
                    on the Platform. However, the Platform Operator shall retain the right to remove
                    any material or posting made on the Platform at its absolute discretion.
                  </p>
                </li>
                <!-- 17.5 -->
                <li>
                  <p>
                    The User shall use the Platform only for lawful purposes and in a way which does
                    not infringe the rights of any other person or restrict or inhibit any person’s
                    use or enjoyment of the Platform. The User shall not use the Platform or any
                    information accessible on or obtained from it for the purpose of canvassing or
                    soliciting any person or enticing any person away from using the services on our
                    Platform.
                  </p>
                </li>
                <!-- 17.6 -->
                <li>
                  <p>
                    Each User warrants that it has taken all reasonable precautions to ensure that
                    any data it uploaded or otherwise submitted to the Platform is free from
                    viruses, Trojans, worms or other material which may have a contaminating or
                    destructive effect on any part of the Platform.
                  </p>
                </li>
                <!-- 17.7 -->
                <li>
                  <p>
                    The Platform Operator accepts no responsibility or liability for the User’s use
                    of content on the Platform and such use is entirely at the User’s own risk.
                    While the Platform Operator takes reasonable precautions to prevent the
                    existence of computer viruses or other malicious programs on the Platform, the
                    Platform Operator accepts no liability for them if they do exist. The User shall
                    use, update and maintain appropriate antivirus software on their computer.
                  </p>
                </li>
              </ol>
            </li>

            <!-- 18 -->
            <li>
              <h2>LIABILITY</h2>

              <ol>
                <!-- 18.1 -->
                <li>
                  <p>
                    The User shall be liable to the Platform Operator for any loss or damage, costs,
                    expenses, liabilities, fines, penalties, demands, or claims of any nature
                    whatsoever suffered by the Platform Operator as a result of any breach of these
                    Terms or the fraudulent use of the Platform by the User or its employees,
                    officers, servants, agents or representatives.
                  </p>
                </li>
                <!-- 18.2 -->
                <li>
                  <p>
                    The User agrees to indemnify the Platform Operator against all liabilities,
                    costs, expenses, damages and losses (including but not limited to any direct,
                    indirect or consequential losses, loss of profit, loss of reputation and all
                    interest, penalties and legal costs and all other professional costs and
                    expenses) suffered or incurred by, arising out of or in connection with any
                    breach by the User or its employees, officers, servants, agents or
                    representatives of these Terms and other terms and conditions as may be
                    formulated from time to time by the Platform Operator.
                  </p>
                </li>
                <!-- 18.3 -->
                <li>
                  <p>
                    The Platform Operator shall not be liable to the User for any loss or damage,
                    costs, expenses, liabilities, fines, penalties, demands, or claims of any nature
                    whatsoever which the User may suffer as a result of using the services provided
                    via the Platform, or posting the Proposal, Offer Documents or hosting on the
                    Platform except where such loss or damage arises from the Platform Operator’s
                    breach of these Terms or was caused by negligence, wilful default or fraud by
                    the Platform Operator or its employees.
                  </p>
                </li>
                <!-- 18.4 -->
                <li>
                  <p>
                    The Platform Operator makes no warranties or representations and assume no
                    liability in respect of the User for the content of the Proposal, Offer
                    Documents and Hosting or the validity of the investment structure detailed in
                    the Proposal, Offer Documents and Hosting or its compliance with any and all
                    legal and regulatory requirements or any other documents submitted to the
                    Platform Operator. The Platform Operator strongly advises the User to seek
                    independent advice in respect thereof and the User shall be personally liable
                    for all costs and expenses in respect of such advice.
                  </p>
                </li>
                <!-- 18.5 -->
                <li>
                  <p>
                    The User acknowledges that successfully passing the Review, or the investment in
                    the Issuer by the Platform Operator or any company in its group or any person
                    connected or associated with the Platform Operator, is not an indication of
                    approval of the Proposal, Offer Document and Hosting generally and the User
                    confirms that it shall not rely on or take any inference from the same.
                  </p>
                </li>
              </ol>
            </li>

            <!-- 19 -->
            <li>
              <h2>GENERAL</h2>

              <ol>
                <!-- 19.1 -->
                <li>
                  <p>
                    Nothing herein shall be construed to create a partnership, joint venture, agency
                    or employment relationship between the parties. No party has authority to enter
                    into agreements of any kind on behalf of the other Party.
                  </p>
                </li>
                <!-- 19.2 -->
                <li>
                  <p>
                    No failure on the part of any party to exercise and no delay on the part of any
                    party in exercising any right hereunder will operate as a release or waiver
                    thereof, nor will any single or partial exercise of any right under these Terms
                    preclude any other or further exercise of it.
                  </p>
                </li>
                <!-- 19.3 -->
                <li>
                  <p>
                    No remedy conferred by any of the provisions of these Terms is intended to be
                    exclusive of any other remedy which is otherwise available at law, in equity, by
                    statute or otherwise, and each and every other remedy shall be cumulative and
                    shall be in addition to every other remedy given hereunder or now or hereafter
                    existing at law, in equity, by statute or otherwise. The election of any one or
                    more of such remedies by any of the parties shall not constitute a waiver by
                    such party of the right to pursue any other available remedies.
                  </p>
                </li>
                <!-- 19.4 -->
                <li>
                  <p>
                    The Platform Operator reserves the right to amend, vary and/or supplement these
                    Terms from time to time. Any amendments, variations and/or supplements to these
                    Terms shall be effective when posted on the Platform. The User’s continued use
                    of the Platform on or after the date on which such amendments, variations and/or
                    supplements come into effect shall be construed as the User’s agreement to be
                    bound by the amended Terms.
                  </p>
                </li>
                <!-- 19.5 -->
                <li>
                  <p>
                    The communications between the Platform Operator and the User shall be made
                    through the Platform, or mailed, personally delivered, faxed or emailed to the
                    recipient at the current address held in the Platform Operator’s record.
                  </p>
                </li>
                <!-- 19.6 -->
                <li>
                  <p>
                    These Terms shall be binding upon the respective successors-in-title and
                    permitted assigns of the parties. The User shall not assign or transfer its
                    rights, title, interests and benefits under these Terms or any of its
                    liabilities and obligations hereunder without the prior written consent of the
                    Platform Operator.
                  </p>
                </li>
                <!-- 19.7 -->
                <li>
                  <p>
                    Insofar as these Terms are inconsistent with any other terms and conditions,
                    agreement or rulebook established by the Platform Operator to govern the
                    specific service provided to the User, the more relevant and specific terms or
                    document shall apply.
                  </p>
                </li>
                <!-- 19.8 -->
                <li>
                  <p>
                    If any provision of these Terms or part thereof is or may become under any
                    written law, or is found by any court or administrative body or competent
                    jurisdiction to be illegal, void, invalid, prohibited or unenforceable then:
                  </p>

                  <div class="alpha-list">
                    <ol>
                      <li>
                        <p>
                          such provision or part thereof shall be read down or severed only to the
                          extent of such illegality, voidness, invalidity, prohibition or
                          unenforceability;
                        </p>
                      </li>
                      <li>
                        <p>
                          the illegality, voidness, invalidity, prohibition or unenforceability of
                          any provision or part thereof in any jurisdiction shall not affect the
                          legality, validity or enforceability of any other provision or of that
                          provision in any other jurisdiction, and the remaining provisions of these
                          Terms shall remain in full force and effect; and
                        </p>
                      </li>
                      <li>
                        <p>
                          the parties shall use their respective best endeavours to negotiate and
                          agree on a substitute provision which is valid and enforceable and
                          achieves to the greatest extent possible the economic, legal and
                          commercial objectives of such illegal, void, invalid, prohibited or
                          unenforceable term, condition, stipulation, provision, covenant or
                          undertaking.
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>
                <!-- 19.9 -->
                <li>
                  <p>
                    The Parties hereby agree that the provisions contained in these Terms and other
                    terms and conditions as may be formulated by the Platform Operator from time to
                    time shall constitute the entire agreement between the parties in respect of the
                    subject matter of these Terms and supersede all previous memoranda,
                    expectations, understandings, communications, representations and agreements
                    whether oral or written between them in respect of the subject matter hereof.
                  </p>
                </li>
                <!-- 19.10 -->
                <li>
                  <p>
                    These Terms shall be governed by and construed in accordance with the laws of
                    Malaysia.
                  </p>
                </li>
                <!-- 19.11 -->
                <li>
                  <p>
                    In the event any dispute or difference shall arise amongst the Platform Operator
                    and the User and/or between the Users as to the construction of these Terms or
                    as to any matter or thing of whatsoever nature arising thereunder or in
                    connection therewith, including any question regarding its existence, validity
                    or termination, such dispute or difference shall be submitted to a single
                    arbitrator to be appointed by the parties in dispute or, failing agreement
                    within 14 days after either party in dispute has given to the other party in
                    dispute a written request to concur in the appointment of an arbitrator, a
                    single arbitrator to be appointed by the Chairman for the time being of the
                    Asian International Arbitration Centre (“AIAC”) and such submission shall be a
                    submission to arbitration in accordance with the Rules of the AIAC as presently
                    in force by which the Parties in dispute agree to be so bound. The place of
                    arbitration shall be Malaysia and the arbitration shall be conducted wholly in
                    the English language.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </div>

        <p>Last Updated: 27 February 2024 (v1.1)</p>
      </div>
    </div>
  </div>
</section>
