import { Component, OnInit } from '@angular/core';
import { PartnershipService } from '../../core/services/api/partnership.service';
import { BlockUiService } from '../../shared/services/blockUi/block-ui.service';
import { ErrorService } from '../../core/services/error.service';
import { finalize } from 'rxjs/operators';
import { PartnerPageCategory } from '../../core/models/partnership/partner';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss'],
})
export class PartnerComponent implements OnInit {
  categories: PartnerPageCategory[];
  constructor(
    private readonly partnershipService: PartnershipService,
    private readonly blockUiService: BlockUiService,
    private readonly errorService: ErrorService,
  ) {}

  ngOnInit(): void {
    this.getPartners();
  }

  getPartners() {
    this.blockUiService.open();
    this.partnershipService
      .getPartnerPage()
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe({
        next: (data) => {
          this.categories = data;
        },
        error: (err) => this.errorService.showError(err),
      });
  }

  reasons = [
    {
      title: 'Proven track record',
      description:
        'Invest in startups backed by seasoned industry leaders, allowing you to tap into their expertise for the potential of higher returns',
      imgSrc: 'proven-track-record.svg',
    },
    {
      title: 'Mentorship & guidance',
      description:
        'Partners provide invaluable advice, support, and access to a thriving network, empowering companies navigate challenges and drive growth.',
      imgSrc: 'mentorship-guidance.svg',
    },
    {
      title: 'Better risk insight',
      description:
        'Partners use their expertise to identify promising startups, offering valuable insights that can reduce investment risks, though success is not guaranteed.',
      imgSrc: 'better-risk-insight.svg',
    },
  ];

  footerItems = [
    {
      title: 'Revenue generation potential',
      description:
        'Identify high-potential companies raising funds and support their growth to generate revenue. Aligning with these startups offers opportunities for future returns.',
      imgSrc: 'revenue-generation-potential.svg',
    },
    {
      title: 'Increased visibility within the startup ecosystem',
      description:
        'Your brand will be prominently featured on our platform, reaching startups, investors, and entrepreneurs, helping you establish authority and attract new business opportunities.',
      imgSrc: 'increased-visibility.svg',
    },
    {
      title: 'Cross promotion & joint marketing opportunities',
      description:
        'Collaborate with us on marketing activities like events, webinars, and content creation to reach a wider audience and amplify your brand message.',
      imgSrc: 'cross-promotion.svg',
    },
  ];
}
