<section class="tw-pt-8 lg:tw-pt-20">
  <div class="tw-rounded-tl-3xl tw-bg-gradient-to-r tw-from-[#FFE5BF] tw-to-[#FFA85D]">
    <div class="container-fluid tw-flex max-[550px]:tw-block">
      <div class="tw-p-10 md:tw-flex-[0_0_65%]">
        <p class="p-text-preheader">FOR INVESTORS</p>
        <h2 class="p-text-h2 tw-mb-12">
          <strong>Join {{ investorCount.count }} investors</strong>
        </h2>
        <div class="tw-mb-8 tw-flex tw-flex-col tw-gap-6">
          <div class="tw-flex tw-items-center tw-gap-2">
            <img
              src="assets/img/icons/common/check_icon_orange.svg"
              alt="orange checkmark icon"
              class="tw-max-w-[24px] tw-self-start"
            />
            <p class="tw-mb-0 tw-leading-[1.2rem] tw-text-black">
              Access unique investment opportunities that are not available through traditional
              investment channels
            </p>
          </div>
          <div class="tw-flex tw-items-center tw-gap-2">
            <img
              src="assets/img/icons/common/check_icon_orange.svg"
              alt="orange checkmark icon"
              class="tw-max-w-[24px] tw-self-start"
            />
            <p class="tw-mb-0 tw-leading-[1.2rem] tw-text-black">
              Diversify your investment portfolio by owning multiple assets
            </p>
          </div>
          <div class="tw-flex tw-items-center tw-gap-2">
            <img
              src="assets/img/icons/common/check_icon_orange.svg"
              alt="orange checkmark icon"
              class="tw-max-w-[24px] tw-self-start"
            />
            <p class="tw-mb-0 tw-leading-[1.2rem] tw-text-black">
              Able to invest as little as RM500, suitable for first time investors
            </p>
          </div>
          <div class="tw-flex tw-items-center tw-gap-2">
            <img
              src="assets/img/icons/common/padlock.svg"
              alt="padlock icon"
              class="tw-max-w-[24px] tw-self-start"
            />
            <p class="tw-mb-0 tw-leading-[1.2rem] tw-text-black">
              Recognised market operator (RMO) with Securities Commission Malaysia
            </p>
          </div>
        </div>
        <a
          class="tw-inline-block tw-rounded-full tw-bg-white tw-px-4 tw-py-2 tw-font-bold tw-text-black"
          routerLink="/equity/invest"
          >Why invest with pitchIN &#8594;</a
        >
      </div>
      <div class="tw-flex-auto md:tw-relative">
        <img
          src="assets/img/icons/common/investors-side-profiles.png"
          alt="4 investor side profiles illustration"
          class="tw-w-full max-[550px]:tw-h-[350px] max-[550px]:tw-w-auto md:tw-absolute lg:-tw-mt-[43px]"
        />
      </div>
    </div>
  </div>
  <img
    src="assets/img/icons/common/arrow_down_orange.svg"
    alt="orange downwards arrow"
    class="tw-mx-auto tw-block tw-max-w-[50px]"
  />
  <div class="container-fluid tw-mt-12 tw-text-center">
    <h2 class="p-text-h2 tw-relative tw-z-10 tw-mb-4">
      Be part of
      <span
        class="tw-inline-block tw-bg-gradient-to-r tw-from-[#D67315] tw-via-[#FFE5BF] tw-to-[#D67315] tw-bg-clip-text tw-text-transparent"
        >great companies</span
      >
      like these
    </h2>
    <a routerLink="/equity/success" class="p-link-primary p-link-underline tw-mr-2 tw-font-bold"
      >View all successful campaigns</a
    ><strong class="tw-text-black">&#8594;</strong>
    <div class="tw-pb-10 tw-pt-[4.5rem]">
      <div class="logos tw-relative tw-overflow-hidden tw-whitespace-nowrap tw-bg-white">
        <div class="logos-slide">
          <div class="tw-flex">
            <div *ngFor="let slide of logoSlider">
              <div class="tw-mx-6 tw-inline-block">
                <div
                  class="tw-flex tw-w-[150px] tw-flex-col tw-items-center tw-gap-2 tw-text-center"
                >
                  <div
                    class="tw-h-12 tw-w-12 tw-overflow-hidden tw-rounded-md tw-shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]"
                  >
                    <img
                      src="assets/img/logo/{{ slide.src }}"
                      alt="{{ slide.name }} logo"
                      class="tw-w-full"
                    />
                  </div>
                  <p class="tw-mb-0 tw-font-bold tw-text-black">{{ slide.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="logos-slide">
          <div class="tw-flex">
            <div *ngFor="let slide of logoSlider">
              <div class="tw-mx-6">
                <div
                  class="tw-flex tw-w-[150px] tw-flex-col tw-items-center tw-gap-2 tw-text-center"
                >
                  <div
                    class="tw-h-12 tw-w-12 tw-overflow-hidden tw-rounded-md tw-shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]"
                  >
                    <img
                      src="assets/img/logo/{{ slide.src }}"
                      alt="{{ slide.name }} logo"
                      class="tw-w-full"
                    />
                  </div>
                  <p class="tw-mb-0 tw-font-bold tw-text-black">{{ slide.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
