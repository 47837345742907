import { Component, Input, OnInit } from '@angular/core';
import { BusinessesService } from 'src/app/core/services/api/mysec/businesses.service';
import { ShareholderFilter } from '../../businesses/shareholder-filter';
import { ShareHolderOwnership } from 'src/app/core/models/mysec/share-holder-ownership';
import { DATE_FORMATS } from 'src/app/core/services/constant';
import { ShareOwnership } from 'src/app/core/enum/share-ownership.enum';
import { finalize } from 'rxjs';
import { TickerCode } from '../../../../core/models/mysec/ticker-code';

@Component({
  selector: 'app-shareholder-information',
  templateUrl: './shareholder-information.component.html',
  styleUrls: ['./shareholder-information.component.scss'],
})
export class ShareholderInformationComponent implements OnInit {
  @Input() tickerCodes: TickerCode[];
  displayColumns: string[] = ['Name', 'ShareOwnership', 'SharePercentage'];

  isLoading = true;
  total: number;
  totalShareholders: number;
  totalShares: number;

  tickerCodeList: { key: string; value: string }[] = [];
  selectedTickerCode: string = 'All';

  params = new ShareholderFilter();
  shareholderList: ShareHolderOwnership[] = [];

  dateFormat = DATE_FORMATS.defaultDate;

  ShareOwnership: typeof ShareOwnership = ShareOwnership;

  constructor(private businessesService: BusinessesService) {}

  ngOnInit() {
    this.getShareholderList();
  }

  getShareholderList() {
    this.isLoading = true;

    this.businessesService
      .getShareholderList(this.params)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((data) => {
        this.shareholderList = data.data;
        this.total = data.total;
        this.totalShareholders = data?.totalShareHolders;
        this.totalShares = data?.totalShares;
        this.initTickerCodes();
      });
  }

  initTickerCodes() {
    const tickerCodeList = this.tickerCodes.map((item) => {
      return {
        key: item.id.toString(),
        value: item.tickerCode.toUpperCase(),
      };
    });

    this.tickerCodeList = [
      {
        key: '',
        value: 'All',
      },
      ...tickerCodeList,
    ];
  }

  handlePageChange() {
    this.getShareholderList();
  }

  handleFilter(paramKey: string, value: string) {
    this.params[paramKey] = value;
    this.params.currentPage = 1;
    this.getShareholderList();
  }

  handleFilterTickerCode(tickerCode: string) {
    this.selectedTickerCode = tickerCode;

    const getTickerCode = this.tickerCodeList.find((t) => t.value === tickerCode);

    if (getTickerCode) {
      this.handleFilter('tickerCode', getTickerCode.key);
    }
  }
}
