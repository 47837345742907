import { OrderStatus } from '../../enum/mysec/order-status.enum';
import { OrderType } from '../../enum/mysec/order-type.enum';
import { TradingType } from '../../enum/mysec/trading-type.enum';
import { OpenOrder } from './open-order';

export class OpenOrderModel {
  companyName: string;
  registrationNumber: string;
  tickerCode: string;
  id: number;
  mySecShareId: number;
  mySecOrderContractId: number;
  ecfAccountNo: string;
  orderNo: string;
  allowPartiallyFill: boolean;
  action: TradingType;
  orderType: OrderType;
  orderQuantity: number;
  matchedQuantity: number;
  orderAmount: number;
  createdAt: string;
  price: number;
  status: OrderStatus;
  continuePlaceOrder: boolean;
  password: string;
  partiallyMatchedStatus?: number;

  constructor(model: OpenOrder) {
    this.allowPartiallyFill = model.allowPartiallyFill;
    this.action = model.tradingType;
    this.orderType = model.type;
    this.orderQuantity = model.orderQuantity;
    this.price = model.price;
    this.continuePlaceOrder = model.continuePlaceOrder;
    this.password = model.password;
    if (model.orderNo) {
      this.orderNo = model.orderNo;
    }
  }
}
