export enum CampaignStatus {
  New = 1,
  InProcess = 2,
  PreLive = 3,
  Live = 4,
  Success = 5,
  Fail = 6,
  Rejected = 7,
  Cooling = 8, //The period after the campaign has ended, and the paid amount exceeds the campaign minimum target. Users can refund in this phase, and the campaign will Fail if enough users refund during this phase.
  NotApplicable = 9,
  ShareAllotment = 10, //The period after cooling off but before campaign is successful. All shares must be alloted to convert into a success status. Refund is not allowed in this period.
}
