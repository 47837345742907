import { Component, OnInit, Optional, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingService } from '../../core/services/api/setting.service';
import { isPlatformServer } from '@angular/common';
import { StatusCodeResponseService } from '../../core/services/status-code-response.service';
import { HeaderService } from 'src/app/shared/services/header/header.service';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent implements OnInit {
  isImage = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private settingService: SettingService,
    private activatedRoute: ActivatedRoute,
    private statusCodeResponseService: StatusCodeResponseService,
  ) {}

  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      this.statusCodeResponseService.setNotFound();
    }

    this.isImage = this.activatedRoute.snapshot.data.isImage || false;
  }

  reset() {
    this.settingService.notFoundPage.next(false);
  }
}
