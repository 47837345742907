import { Component, OnInit } from '@angular/core';
import { ErrorService } from '../../../core/services/error.service';
import { finalize } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignInfo } from '../../../core/models/campaign/campaign-info';
import { CampaignService } from '../../../core/services/api/campaign.service';
import { CampaignDetailApprovalStatus } from '../../../core/enum/campaign-detail-approval-status';

@Component({
  selector: 'app-campaign-draft-reject',
  templateUrl: './campaign-draft-reject.component.html',
  styleUrls: ['./campaign-draft-reject.component.scss'],
})
export class CampaignDraftRejectComponent implements OnInit {
  fundRaiseApplicationId: number;
  campaignInfo: CampaignInfo;
  campaignId: number;
  isLoading = false;
  route = '/my-campaign/edit-campaign';

  CampaignDetailApprovalStatus: typeof CampaignDetailApprovalStatus = CampaignDetailApprovalStatus;

  constructor(
    private errorService: ErrorService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private campaignService: CampaignService,
  ) {}

  ngOnInit() {
    this.fundRaiseApplicationId = this.activatedRoute.snapshot.params.fundRaiseApplicationId;
    this.campaignId = this.activatedRoute.snapshot.params.campaignId;
    this.getCampaignDraft();
  }

  getCampaignDraft() {
    this.isLoading = true;

    this.campaignService
      .getCampaignDraft(this.campaignId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.campaignInfo = data;

          if (
            this.campaignInfo.campaignDetailApprovalStatus != CampaignDetailApprovalStatus.Rejected
          ) {
            this.back();
          }
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  back() {
    this.location.back();
  }
}
