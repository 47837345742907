import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, mergeMap } from 'rxjs/operators';
import { Banner } from '../../models/banner/banner';
import { InvestedItem } from '../../models/banner/invested-item';
import { AccountService } from './account.service';

@Injectable()
export class HomeService {
  private route = environment.apiServer + 'Api/v1/ECF';

  constructor(
    private http: HttpClient,
    private accountService: AccountService,
  ) {}

  getBannerList(): Observable<Banner[]> {
    const params = { 'noToken': 'noToken' };

    return this.http.get(this.route + '/banners', { params: params }).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getLatestInvestmentList(): Observable<InvestedItem[]> {
    const params = { 'noToken': 'noToken' };

    return this.http.get(this.route + '/latestInvestmentList', { params: params }).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  uploadBusinessFeePresignedURL(fundRaiseApplicationId: number, file: File): Observable<any> {
    const body = {
      FileName: file.name,
    };

    return this.http
      .post(
        this.route + '/{role}/raiseFund/' + fundRaiseApplicationId + '/payment/attachment',
        body,
      )
      .pipe(
        mergeMap((data: any) => {
          if (data && data.result) return this.accountService.uploadImage(data.result, file);
          else return data.result;
        }),
      );
  }
}
