<div class="row text-black">
  <div class="col-md-6" *ngIf="selectedRisk">
    <div
      class="py-3 font-size-smaller"
      [class.text-primary]="selectedRisk.id == risk.id"
      *ngFor="let risk of risks"
    >
      <span class="cursor-pointer" (click)="selectRisk(risk)">
        {{ risk.title }}
        <i *ngIf="selectedRisk.id == risk.id" class="pl-3 fa fa-angle-right"></i>
      </span>
    </div>
  </div>
  <div class="col-md-6">
    <div class="description">
      <div
        class="ck-content"
        *ngIf="selectedRisk"
        [innerHTML]="selectedRisk.description | trustSrc"
      ></div>
    </div>
  </div>
</div>
