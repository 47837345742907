import { Directive, HostListener } from '@angular/core';
import { GtmDataLayerService } from '../../core/services/gtm-data-layer.service';

@Directive({ selector: '[data-t-blur]' })
export class GtmInputBlurDirective {
  constructor(private _gtmDataLayerService: GtmDataLayerService) {}

  @HostListener('blur', ['$event.target'])
  onInputBlur(target: HTMLElement) {
    const inputName = target?.attributes['formcontrolname']?.value;

    if (inputName) {
      this._gtmDataLayerService.logFormBlur(inputName);
    }
  }
}
