import { Component, Input, OnInit } from '@angular/core';
import { Term } from '../../../../core/models/share/term';

@Component({
  selector: 'app-risks',
  templateUrl: './risks.component.html',
  styleUrls: ['./risks.component.scss'],
})
export class RisksComponent implements OnInit {
  @Input() risks: Term[];
  selectedRisk: Term;

  constructor() {}

  ngOnInit() {
    this.selectedRisk = this.risks[0];
  }

  selectRisk(risk: Term) {
    this.selectedRisk = risk;
  }
}
