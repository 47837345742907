<main class="tw-flex tw-h-full tw-flex-col tw-items-center">
  <section
    class="tw-flex tw-min-h-[30rem] tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-px-4 tw-py-32"
  >
    <h1
      class="tw-max-w-3xl tw-bg-gradient-to-r tw-from-black tw-from-50% tw-to-primary tw-bg-clip-text tw-text-center tw-font-semibold tw-text-transparent"
    >
      {{ 'partnership.header' | translate }}
      <br />
      {{ 'partnership.headerTag' | translate }}
    </h1>
    <div
      class="tw-flex tw-max-w-xl tw-flex-col tw-items-center tw-text-center tw-text-black md:tw-max-w-none lg:tw-flex-row"
    >
      Empower your portfolio with insights from the
      <div class="tw-flex">
        <span class="animation">
          <ul class="mask">
            <li>experts</li>
            <li>venture capitalists</li>
            <li>angel investors</li>
            <li>accelerators</li>
            <li>incubators</li>
            <li>industry partners</li>
            <li>universities</li>
          </ul>
        </span>
        who know what it takes to succeed
      </div>
    </div>
  </section>

  <section class="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-4 tw-px-4 tw-pb-64">
    <h2 class="tw-text-sm tw-font-bold tw-uppercase tw-tracking-widest tw-text-gray-600">
      {{ 'partnership.ourPartners' | translate }}
    </h2>
    <div class="tw-flex tw-w-full tw-flex-col tw-gap-12">
      <ng-container *ngFor="let item of categories">
        <app-partner-category
          [category]="item.category"
          [partners]="item.partners"
          class="tw-flex tw-w-full tw-flex-col tw-items-center"
        />
      </ng-container>
    </div>
  </section>

  <div class="tw-w-full tw--skew-y-6 tw-bg-[#f3f3f3]">
    <section
      class="tw-mt-8 tw-flex tw-w-full tw-skew-y-6 tw-flex-col tw-items-center tw-gap-2 tw-bg-gradient-to-b tw-px-4 tw-py-64"
    >
      <h3 class="tw-text-sm tw-font-bold tw-uppercase tw-tracking-widest tw-text-black">
        {{ 'partnership.whyInvest' | translate }}
      </h3>
      <p class="tw-max-w-2xl tw-text-center tw-text-3xl tw-font-semibold tw-text-black">
        {{ 'partnership.whyInvestDesc' | translate }}
      </p>
      <section class="tw-flex tw-flex-col tw-gap-8 lg:tw-flex-row">
        <ng-container *ngFor="let reason of reasons">
          <ng-template *ngTemplateOutlet="infoCard; context: { value: reason }" />
        </ng-container>
      </section>
    </section>
  </div>

  <div class="tw-w-full -tw-skew-y-6 tw-bg-white">
    <section
      class="tw-flex tw-w-full tw-skew-y-6 tw-flex-col tw-items-center tw-gap-4 tw-px-4 tw-py-64 lg:tw-flex-row lg:tw-items-start lg:tw-justify-center lg:tw-gap-12"
    >
      <div class="tw-flex tw-flex-col tw-items-center lg:tw-items-start">
        <h3 class="tw-text-sm tw-font-bold tw-uppercase tw-tracking-widest tw-text-black">
          {{ 'partnership.forFounders' | translate }}
        </h3>
        <p
          class="text-gradient tw-mb-8 tw-text-center tw-text-3xl tw-font-semibold tw-text-black lg:tw-max-w-xl lg:tw-text-left"
        >
          {{ 'partnership.forFoundersTag' | translate }}
        </p>
        <a
          target="_blank"
          href="mailto:partnership@pitchin.my"
          class="tw-mb-12 tw-cursor-pointer tw-rounded tw-bg-black tw-px-4 tw-py-2 tw-font-semibold tw-text-white hover:tw-text-white"
          >{{ 'partnership.forFoundersCta' | translate }}</a
        >
      </div>
      <div class="tw-w-full lg:tw-w-96">
        <app-partner-testimonial />
      </div>
    </section>
  </div>

  <div class="tw-w-full tw-bg-[#e1e1e1]">
    <div class="tw-w-full -tw-skew-y-6 tw-bg-[#e1e1e1]">
      <section
        class="tw-flex tw-w-full tw-skew-y-6 tw-flex-col tw-items-center tw-gap-4 tw-bg-gradient-to-b tw-px-4 tw-py-64"
      >
        <h3 class="tw-text-sm tw-font-bold tw-uppercase tw-tracking-widest tw-text-black">
          {{ 'partnership.partnerWithUs' | translate }}
        </h3>
        <p class="tw-max-w-2xl tw-text-center tw-text-3xl tw-font-semibold tw-text-black">
          {{ 'partnership.partnerWithUsTag' | translate }}
        </p>
        <a
          target="_blank"
          href="mailto:partnership@pitchin.my"
          class="tw-mb-12 tw-cursor-pointer tw-rounded tw-border tw-bg-black tw-px-4 tw-py-2 tw-font-semibold tw-text-white hover:tw-text-white"
          >{{ 'partnership.partnerWithUsCta' | translate }}</a
        >
        <section class="tw-flex tw-flex-col tw-gap-8 lg:tw-flex-row">
          <ng-container *ngFor="let reason of footerItems">
            <ng-template *ngTemplateOutlet="infoCard; context: { value: reason }" />
          </ng-container>
        </section>
      </section>
    </div>
  </div>
</main>

<ng-template #infoCard let-value="value">
  <article
    class="tw-flex tw-flex-col tw-items-start tw-gap-4 tw-rounded-xl tw-border tw-border-black tw-border-opacity-80 tw-p-4 lg:tw-max-w-64"
  >
    <img
      [src]="'../../../assets/img/icons/common/' + value.imgSrc"
      [alt]="value.title"
      class="tw-w-10"
    />
    <h5 class="!tw-m-0 tw-text-base tw-font-semibold tw-text-black">{{ value.title }}</h5>
    <p class="tw-m-0 tw-text-base tw-text-black">{{ value.description }}</p>
  </article>
</ng-template>
