import { Component } from '@angular/core';

@Component({
  selector: 'app-nominee-agreement',
  templateUrl: './nominee-agreement.component.html',
  styleUrls: ['./nominee-agreement.component.scss'],
})
export class NomineeAgreementComponent {
  constructor() {}
}
