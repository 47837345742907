import { Component } from '@angular/core';

@Component({
  selector: 'app-e-kyc-result',
  templateUrl: './e-kyc-result.component.html',
  styleUrls: ['./e-kyc-result.component.scss'],
})
export class EKycResultComponent {
  constructor() {}
}
