<section class="section section-md section-shaped">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="title" [innerHTML]="'raise.myApplications' | translate"></div>
      </div>
      <div class="col-lg-6 col-md-12 d-flex align-items-center justify-content-lg-end new-btn-wrap">
        <div class="btn btn-primary new-btn" routerLink="/raise/raise-fund-form">
          {{ 'raise.newApplication' | translate }}
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="fundRaiseApplications && fundRaiseApplications.length > 0">
      <div class="col-lg-6">
        <div class="input-label input-md">{{ 'raise.selectApplication' | translate }}</div>
        <div class="input-field input-md">
          <mat-select
            [placeholder]="'common.selectPlaceholder' | translate"
            class="form-control select-app"
            [(ngModel)]="applicationId"
            (selectionChange)="getApplicationProgress(true)"
          >
            <mat-option
              class="long-text-option"
              *ngFor="let application of fundRaiseApplications"
              [value]="application.id"
            >
              {{ application.name }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
    <agmo-loading *ngIf="!fundRaiseApplications"></agmo-loading>
  </div>
  <div class="container-fluid mt-4">
    <ng-container
      *ngIf="
        selectedFundRaiseProcess &&
        selectedApplication.campaignStatus != CampaignStatus.Rejected &&
        !selectedApplication.isLegacySystem
      "
    >
      <div class="tab-panel">
        <div
          *ngFor="let content of tabContents"
          class="tab-item d-flex"
          [class.active]="selectedTab === content.tab"
        >
          <a (click)="changeTab(content)">{{ content.name | translate }}</a>
          <div
            class="hover-dropdown"
            ngbDropdown
            *ngIf="content.hoverText"
            (click)="$event.stopPropagation(); $event.preventDefault()"
          >
            <!--Waiting designer to provide white icon for active tab-->
            <div
              class="pitchin-icon pitchin-icon-info-icon-grey ml-2 no-caret"
              ngbDropdownToggle
            ></div>
            <div class="font-size-smaller p-3 hover-text-panel" ngbDropdownMenu>
              {{ content.hoverText | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <!-- <app-application-process-list *ngIf="!isLoading" [fundRaiseProcess]="selectedFundRaiseProcess" [tabContent]="selectedTabContent"
                    [fundRaiseApplicationId]="applicationId" (next)="next(selectedTab)"
                    (refresh)="getApplicationProgress()" [campaignCreated]="campaignCreated"></app-application-process-list> -->
        <app-process-list
          *ngIf="!isLoading"
          [fundRaiseProcess]="selectedFundRaiseProcess"
          [tabContent]="selectedTabContent"
          [fundRaiseApplicationId]="applicationId"
          (next)="next(selectedTab)"
          (refresh)="getApplicationProgress()"
          [campaignCreated]="campaignCreated"
        >
        </app-process-list>
        <agmo-loading *ngIf="isLoading"></agmo-loading>
      </div>
    </ng-container>
    <ng-container *ngIf="fundRaiseApplications && fundRaiseApplications.length == 0">
      {{ 'raise.noApplication' | translate }}
    </ng-container>
    <ng-container
      *ngIf="selectedApplication && selectedApplication.campaignStatus == CampaignStatus.Rejected"
    >
      <div class="pb-2">
        {{ 'raise.rejectedApplication' | translate }}
      </div>
      <div class="font-weight-bold text-black">
        {{ selectedApplication.remark }}
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        selectedApplication &&
        selectedApplication.campaignStatus != CampaignStatus.Rejected &&
        selectedApplication.isLegacySystem
      "
    >
      <div class="pb-2">
        {{ 'raise.findCampaignDetails' | translate }}
      </div>
      <div class="btn btn-primary" [routerLink]="['/equity', selectedApplication.slug]">
        {{ 'raise.viewCampaignDetails' | translate }}
      </div>
    </ng-container>
  </div>
</section>
