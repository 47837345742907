import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AttachmentInfo } from '../../../../../core/models/attachment/attachment-info';
import { ContainerType } from '../../../../../core/enum/container-type.enum';
import { IdentificationType } from '../../../../../core/enum/identification-type.enum';
import { FileUploadOption } from '../../../../../core/models/attachment/file-upload-option';
import { TeamMember } from '../../../../../core/models/mysec/team-member';
import { AccountService } from '../../../../../core/services/api/account.service';
import { TeamsService } from '../../../../../core/services/api/mysec/teams.service';
import { ImageFileFormat } from '../../../../../core/services/constant';
import { ErrorService } from '../../../../../core/services/error.service';
import { FileValidationService } from '../../../../../core/services/file-validation.service';
import { BlockUiService } from '../../../../../shared/services/blockUi/block-ui.service';
import { PopupService } from '../../../../../shared/services/popup/popup.service';

@Component({
  selector: 'app-add-team-member',
  templateUrl: './add-team-member.component.html',
  styleUrls: ['./add-team-member.component.scss'],
})
export class AddTeamMemberComponent implements OnInit {
  mySecCompanyTeamId: number;
  form: UntypedFormGroup;
  showFormError: boolean = false;
  isLoading: boolean = false;
  profileImage: any;
  attachmentInfo: AttachmentInfo;
  teamMember: TeamMember;
  accept: string = ImageFileFormat.toString();

  IdentificationType: typeof IdentificationType = IdentificationType;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private popupService: PopupService,
    private sanitizer: DomSanitizer,
    private teamService: TeamsService,
    private errorService: ErrorService,
    private blockUiService: BlockUiService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private fileValidationService: FileValidationService,
  ) {}

  ngOnInit(): void {
    this.mySecCompanyTeamId = this.activatedRoute.snapshot.params.mySecCompanyTeamId;
    this.buildForm();

    if (this.mySecCompanyTeamId) this.getTeamMember();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      position: ['', Validators.required],
      details: [''],
    });
  }

  getTeamMember() {
    this.teamService.getTeamMember(this.mySecCompanyTeamId).subscribe((data) => {
      this.form.patchValue(data);
      this.profileImage = data.imageUrl;
      this.attachmentInfo = data.attachmentInfo;
    });
  }

  async selectFile(file: File) {
    const uploadOption: FileUploadOption = {
      fileType: ImageFileFormat,
      errorMsg: 'common.onlyImageAccepted',
    };

    if (await this.fileValidationService.validFile(file, uploadOption)) {
      this.isLoading = true;
      this.profileImage = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));

      this.accountService
        .uploadAttachmentPresignedURL(file, ContainerType.MySecCompanyTeam)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          (data) => {
            this.profileImage = data.url;
            this.attachmentInfo = new AttachmentInfo(data);
          },
          (err) => {
            this.errorService.showError(err);
            this.profileImage = null;
            this.attachmentInfo = null;
          },
        );
    }
  }

  submit() {
    if (this.form.invalid) {
      this.popupService.alert('common.missingFieldsAlert');
      this.showFormError = true;
      return;
    }

    this.blockUiService.open();
    let teamMember: TeamMember = this.form.value;
    teamMember.attachmentInfo = this.attachmentInfo;

    let observable = this.mySecCompanyTeamId
      ? this.teamService.updateTeamMember(this.mySecCompanyTeamId, teamMember)
      : this.teamService.addTeamMember(teamMember);

    observable.pipe(finalize(() => this.blockUiService.close())).subscribe(
      () => {
        this.back();
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  back() {
    this.location.back();
  }
}
