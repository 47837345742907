<ng-container>
  <div class="row justify-content-center">
    <div class="col-lg-8 d-flex pt-5 pb-3 title-panel border-bottom">
      <h2 class="font-weight-extrabold align-self-center mb-0">
        {{ 'investment.DuitNow' | translate }}
      </h2>
      <div class="pitchin-icon-duitnow tw-h-20 tw-w-36 tw-object-contain"></div>
    </div>
  </div>
  <div class="row py-5 invest-panel justify-content-center">
    <div class="col-lg-8">
      <div
        class="mb-4 tw-flex tw-rounded-2xl tw-bg-[#e7fdf2] tw-p-6 tw-text-2xl tw-font-bold tw-text-secondary"
      >
        <div class="w-75">{{ 'investment.totalCost' | translate }}</div>
        <div class="w-25 text-right">{{ paymentAmount | numberSuffix }}</div>
      </div>
      <form [formGroup]="form" [class.show-form-error]="showFormError" (ngSubmit)="submit()">
        <div class="input-panel">
          <div class="input-label">{{ 'investment.accountType' | translate }}</div>
          <div class="input-field">
            <mat-select
              placeholder="{{ 'investment.selectAccountType' | translate }}"
              class="form-control"
              formControlName="businessModel"
              (selectionChange)="getBankList()"
            >
              <mat-option *ngFor="let type of accountTypes" [value]="type">
                {{ type }}
              </mat-option>
            </mat-select>
            <app-validation-error-message
              [inputName]="'investment.accountType' | translate"
              [showFormError]="showFormError"
              [control]="form.get('businessModel')"
            >
            </app-validation-error-message>
          </div>
        </div>
        <div class="input-panel">
          <div class="input-label">{{ 'investment.bank' | translate }}</div>
          <div class="input-field">
            <mat-select
              placeholder="{{ 'investment.selectBank' | translate }}"
              class="form-control"
              formControlName="bankID"
            >
              <mat-option
                *ngFor="let bank of banks"
                [value]="bank.bankId"
                [disabled]="!bank.status"
              >
                {{ bank.bankName }}
              </mat-option>
            </mat-select>
            <app-validation-error-message
              [inputName]="'investment.bank' | translate"
              [showFormError]="showFormError"
              [control]="form.get('bankID')"
            >
            </app-validation-error-message>
          </div>
        </div>
        <div class="input-panel mt-4 text-center">
          <button class="btn btn-primary font-size-larger tw-w-full lg:tw-w-1/2" type="submit">
            {{ 'investment.payNow' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-container>
