import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToArray',
})
export class EnumToArrayPipe implements PipeTransform {
  transform(data: Object, isString?: boolean) {
    let keys = [];

    for (var enumMember in data) {
      if (!isNaN(parseInt(enumMember, 10)))
        keys.push({ key: parseInt(enumMember), value: data[enumMember] });
      else if (isString) keys.push({ key: enumMember, value: data[enumMember] });
    }

    return keys;
  }
}
