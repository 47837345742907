<section class="section">
  <div class="container">
    <h1 class="title tw-mb-10 tw-text-center tw-font-semibold">Raya with pitchIN</h1>

    <img
      class="tw-mx-auto tw-block tw-w-full tw-max-w-[800px]"
      src="/assets/img/banner/raya-pitchin-2023.jpg"
      alt="raya with pitchIN hero image"
    />

    <div class="content-wrapper tw-my-10 tw-text-center">
      <p>Get RM20 Touch 'n Go credit for every RM2,000 investment, and</p>
      <p>Get an additional of RM50 Touch 'n Go credit for an investment of RM10,000 and above*</p>

      <div class="tw-mt-10">
        <strong>Campaign period:</strong>
        <p>15th April to 31st May 2023</p>
      </div>
    </div>

    <div
      class="tw-mx-auto tw-max-w-3xl tw-border-t tw-border-solid tw-border-divider tw-pt-10 tw-text-sm tw-text-body tw-text-opacity-80"
    >
      <strong class="tw-mb-2.5 tw-block tw-text-lg">Eligibility:</strong>
      <div>
        This campaign shall be applicable to all Individual pitchIN investors (“Investors”).
      </div>

      <strong class="tw-mb-2.5 tw-mt-7 tw-block tw-text-lg">Terms and Conditions:</strong>
      <ol class="tw-space-y-2 tw-pl-5">
        <li>
          To participate in #RayawithpitchIN campaign, investors must make an investment of at least
          RM2,000 in a single campaign or cumulatively across multiple campaigns during the campaign
          period. Pledges will not be considered as a participation.
        </li>
        <li>
          All investments must be made to pitchIN by <strong>31st of May 2023</strong> to be
          eligible to participate in the campaign.
        </li>
        <li>
          For investment by tranches, only the amount that we have sighted during the campaign
          period would be eligible for the rewards.
        </li>
        <li>RM20 credit will be issued <strong>for every RM2,000 invested</strong>.</li>
        <li>
          Any investment above RM10,000 will <strong>receive an additional RM50 credit</strong>.
        </li>
        <li>
          The maximum credit any investor will receive is cappped at RM1,000 across any investment
          campaign during the #RayawithpitchIN campaign period.
        </li>
        <li>
          The credit will be transferred to the investor’s Touch N Go ewallet. The credit is
          non-transferrable to other parties and will only be transferred to the investor’s ewallet.
        </li>
        <li>
          The credit will be sent to the investor’s registered email address within 14 working days
          upon the closing of the successful equity crowdfunding campaign.
        </li>
        <li>
          In the event that the equity crowdfunding campaign is removed from pitchIN platform for
          any reason, investments made in the campaign will be automatically deemed disqualified and
          the investments will be refunded.
        </li>
        <li>
          Investors who request a refund on their investments upon receiving the credits will be
          deemed disqualified.
        </li>
        <li>
          pitchIN reserves the right to amend or cancel the campaign or rewards at any time without
          prior notice. Any dispute arising from or in connection with this campaign shall be
          resolved by pitchIN, whose decision shall be final.
        </li>
      </ol>
    </div>
  </div>
</section>
