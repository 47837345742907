import { OrderType } from '../../enum/mysec/order-type.enum';
import { TradingType } from '../../enum/mysec/trading-type.enum';
import { OrderDraft } from './order-draft';

export class OrderDraftModel {
  price?: number;
  orderQuantity: number;
  action: TradingType;
  orderType: OrderType;

  constructor(model: OrderDraft) {
    this.price = +model.price;
    this.orderQuantity = model.orderQuantity || 0;
    this.action = model.action;
    this.orderType = model.orderType;
  }
}
