import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { Announcement } from '../../core/models/setting/announcement';
import { SettingService } from '../../core/services/api/setting.service';
import { CookieService } from '../../core/services/cookie.service';
import * as moment from 'moment';

@Component({
  selector: 'app-announcement-bar',
  templateUrl: './announcement-bar.component.html',
  styleUrls: ['./announcement-bar.component.scss'],
})
export class AnnouncementBarComponent implements AfterViewInit {
  @ViewChild('announcement', { static: false }) announcementBar: ElementRef;

  announcement: Announcement;
  content: string;
  shouldShowAnnouncementBar: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platform: Object,
    private cookieService: CookieService,
    private settingService: SettingService,
  ) {}

  ngAfterViewInit() {
    if (!isPlatformBrowser(this.platform)) return;

    this.getUiSettings();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    const announcementBar = this.announcementBar?.nativeElement;

    if (!announcementBar || !this.shouldShowAnnouncementBar) return;

    if (!this.shouldShowAnnouncementBar) return;

    announcementBar.style.height = 'auto';
  }

  settingUpAnnouncementBar(announcementProperties: Announcement) {
    this.announcement = announcementProperties;
    this.content = announcementProperties?.annBarHtmlContent;
  }

  // Set the height for the announcementBar to trigger the push down animation
  // as `auto` will not work
  initAnnouncementBar() {
    if (!this.content) return;

    const lastClosedDate = this.cookieService.getCookie('announcementBarLastClosedDate');
    const lastModifiedDate = this.cookieService.getCookie('announcementBarLastModifiedAt');

    const closedUntil = moment(lastClosedDate).add(12, 'hours');

    // Show the anouncement bar in 3 conditions:
    // - never seen this
    // - when last closed is over 12 hours
    // - when last modified date from localStorage is after the modifiedAt from API
    this.shouldShowAnnouncementBar =
      lastClosedDate === null ||
      moment().isAfter(moment(closedUntil)) ||
      moment(lastModifiedDate).isBefore(moment(this.announcement?.modifiedAt));
    if (!this.shouldShowAnnouncementBar) return;

    const announcementBar = this.announcementBar?.nativeElement;
    if (!announcementBar) return;

    this.cookieService.setCookie('announcementBarLastModifiedAt', this.announcement?.modifiedAt);

    const announcementBarHeight = announcementBar.scrollHeight;
    announcementBar.style.height = announcementBarHeight + 'px';
  }

  // When cookie has `announcementBarLastClosedDate` meaning the user has closed the bar before
  closeAnnouncementBar() {
    const announcementBar = this.announcementBar.nativeElement;
    announcementBar.style.height = '0px';

    this.cookieService.setCookie('announcementBarLastClosedDate', new Date().toISOString());
    this.shouldShowAnnouncementBar = false;
  }

  getUiSettings() {
    this.settingService.getUiSettings().subscribe({
      next: (data) => {
        if (moment().isBefore(moment(data?.annBarExpiryDate))) {
          this.settingUpAnnouncementBar(data);

          setTimeout(() => {
            this.initAnnouncementBar();
          }, 3000);
        }
      },
      error: (err) => {
        console.error('failed to load ui settings', err);
      },
    });
  }
}
