import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CKEditorComponent, ChangeEvent } from '@ckeditor/ckeditor5-angular';
import plainTextToHtml from '@ckeditor/ckeditor5-clipboard/src/utils/plaintexttohtml';
import { TranslateService } from '@ngx-translate/core';
import { IState, State } from 'country-state-city';
import { finalize } from 'rxjs/operators';
import { CorporateBusinessType } from '../../../../app/core/enum/corporate-business-type.enum';
import { ContainerType } from '../../../core/enum/container-type.enum';
import { Role } from '../../../core/enum/role.enum';
import { AttachmentInfo } from '../../../core/models/attachment/attachment-info';
import { FileUploadOption } from '../../../core/models/attachment/file-upload-option';
import { CompanyModel } from '../../../core/models/company/company.model';
import { SectorList } from '../../../core/models/company/sector-list';
import { CountryCode } from '../../../core/models/country/country-code';
import { Item } from '../../../core/models/share/item';
import { AccountService } from '../../../core/services/api/account.service';
import { CountriesService } from '../../../core/services/countries.service';
import { FileValidationService } from '../../../core/services/file-validation.service';
import { StorageService } from '../../../core/services/storage.service';
import { UploadAdapter } from '../../../core/services/upload-adapter';
import { FormValidators } from '../../../core/validator/form.validators';
import { PopupService } from '../../../shared/services/popup/popup.service';
import { SocialLinkType } from './../../../core/enum/social-link-type.enum';
import { Sector } from './../../../core/models/company/sector';
import { EDITOR_CONFIG } from './../../../core/models/editor/editor-config';
import { SettingService } from './../../../core/services/api/setting.service';
import {
  DefaultCountry,
  DefaultCountryCode,
  DefaultEmailPattern,
  DefaultURLOptionalProtocolPattern,
  DefaultURLPattern,
  DefaultVideoURLPattern,
  ImageFileFormat,
} from './../../../core/services/constant';
import { ErrorService } from './../../../core/services/error.service';

const othersId: number = 255;

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit, OnChanges {
  @Input() company: CompanyModel;
  @Input() isUpdate: boolean;
  @Output() submitForm = new EventEmitter();

  showFormError: boolean = false;
  form: UntypedFormGroup;
  isPreloading: boolean = true;
  isLoading: boolean = false;
  isBannerLoading: boolean = false;

  logoUrl: any;
  companyLogoAttachment: AttachmentInfo = new AttachmentInfo();
  bannerUrl: any;
  companyBannerAttachment: AttachmentInfo = new AttachmentInfo();
  sectors: SectorList;
  selectedSectors: Sector[] = [];
  countries: CountryCode[];
  states: IState[];
  businessStates: IState[];
  minDate: Date = new Date(1700, 0, 1);
  todayDate = new Date();
  accept: string = ImageFileFormat.toString();
  msicCode: string;
  msicCodes: string[] = [];
  isIssuer: boolean = false;
  grossAnnualRevenue: Item[];
  corporateCategory: Item[];
  othersId: number = othersId;

  corporateBusinessType = [
    //CorporateBusinessType.SoleProprietor,
    CorporateBusinessType.SdnBhd,
    CorporateBusinessType.LLP,
    CorporateBusinessType.Berhad,
    //CorporateBusinessType.Other
  ];
  issuerBusinessType = [
    CorporateBusinessType.SdnBhd,
    CorporateBusinessType.LLP,
    CorporateBusinessType.UnlistedBerhad,
    // CorporateBusinessType.Berhad
    //CorporateBusinessType.Other
  ];

  Role: typeof Role = Role;
  SocialLinkType: typeof SocialLinkType = SocialLinkType;
  CorporateBusinessType: typeof CorporateBusinessType = CorporateBusinessType;

  socialLinkTypes = [
    { type: SocialLinkType.Facebook },
    { type: SocialLinkType.Instagram },
    { type: SocialLinkType.Twitter },
    { type: SocialLinkType.LinkedIn },
    { type: SocialLinkType.TikTok },
  ];

  Editor: any;
  ckEditorConfig = EDITOR_CONFIG;
  ckEditorLoaded = false;

  @ViewChild('editor') editorComponent: CKEditorComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private popupService: PopupService,
    private sanitizer: DomSanitizer,
    private errorService: ErrorService,
    private settingService: SettingService,
    @Inject(PLATFORM_ID) private platform: Object,
    private storageService: StorageService,
    private accountService: AccountService,
    private translateService: TranslateService,
    private fileValidationService: FileValidationService,
    private countriesService: CountriesService,
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      const Editor = require('../../../../assets/scripts/ckeditor5/ckeditor');
      this.Editor = Editor.Editor;
      this.ckEditorLoaded = true;
      this.ckEditorConfig.extraPlugins = [
        function (editor: any) {
          editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new UploadAdapter(loader);
          };
        },
      ];
      this.ckEditorConfig.placeholder = this.translateService.instant(
        'company.aboutTheBusinessPlaceholder',
      );
    }

    let role = this.activatedRoute.snapshot.params.role || this.storageService.role;
    this.isIssuer = role == Role.IssuerRep;

    if (this.isIssuer) this.corporateBusinessType = this.issuerBusinessType;

    this.countries = this.countriesService.getCountryList();
    this.getCompanyDropdown();
    this.buildForm();
    this.getSectors('');
    this.patchForm();
  }

  getCompanyDropdown() {
    this.settingService.getCompanyDropdown().subscribe({
      next: (data) => {
        this.grossAnnualRevenue = data.grossAnnualRevenue;
        this.corporateCategory = data.corporateCategory;
      },
      error: (err) => this.errorService.showError(err),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('company' in changes) this.patchForm();
  }

  patchForm() {
    this.showFormError = false;

    if (!this.form) return;

    if (this.company) this.patchFormData();
    // For registration only, can skip the preloader if no data
    else if (!this.isUpdate) this.isPreloading = false;
  }

  buildForm() {
    this.form = this.formBuilder.group(
      {
        name: ['', Validators.required],
        brandName: ['', this.isIssuer ? Validators.required : null],
        registrationNumber: ['', Validators.required],
        incorporatedDate: ['', Validators.required, FormValidators.minYearValidator(1700)],
        companyType: ['', Validators.required],
        tagline: [''],
        description: ['', this.isIssuer ? Validators.required : null],
        info: [''],
        address: ['', Validators.required],
        postCode: ['', Validators.required],
        city: ['', Validators.required],
        stateId: [''],
        countryId: [this.isIssuer ? DefaultCountry : null, Validators.required],
        sameAsRegisteredAddress: [false],
        businessAddress: ['', !this.isIssuer ? Validators.required : null],
        businessPostCode: ['', !this.isIssuer ? Validators.required : null],
        businessCity: ['', !this.isIssuer ? Validators.required : null],
        businessStateId: [''],
        businessCountryId: [null, !this.isIssuer ? Validators.required : null],
        phoneCountryCode: [DefaultCountryCode],
        phoneNumber: ['', Validators.required],
        email: [
          '',
          Validators.compose([Validators.required, Validators.pattern(DefaultEmailPattern)]),
        ],
        websiteUrl: ['', Validators.pattern(DefaultURLPattern)],
        videoUrl: [
          '',
          this.isIssuer ? Validators.compose([Validators.pattern(DefaultVideoURLPattern)]) : null,
        ],
        otherCompanyType: [''],
        companySocialLinks: this.formBuilder.array([]),
        grossAnnualRevenueType: [null, !this.isIssuer ? Validators.required : null],
        corporateCategoryType: [null, !this.isIssuer ? Validators.required : null],
        othersCorporateCategoryType: [''],
        isCheck: [false, Validators.required],
      },
      {
        validators: [FormValidators.countryCode('phoneNumber', 'phoneCountryCode')],
      },
    );

    this.socialLinkTypes.forEach((socialLink) => {
      this.socialFormControl.controls.push(this.buildSocialLinkForm(socialLink.type));
    });

    if (this.form.value.countryId) this.getStates(this.form.value.countryId, 'stateId');

    if (!this.isIssuer && this.form.value.businessCountryId)
      this.getStates(this.form.value.businessCountryId, 'businessStateId');
  }

  buildSocialLinkForm(type: number) {
    return this.formBuilder.group({
      type: [type],
      url: ['', Validators.pattern(DefaultURLOptionalProtocolPattern)],
    });
  }

  get socialFormControl() {
    return <UntypedFormArray>this.form.controls.companySocialLinks;
  }

  patchFormData() {
    this.form.patchValue(this.company);

    if (this.form.value.countryId) this.getStates(this.form.value.countryId, 'stateId');

    if (!this.isIssuer && this.form.value.businessCountryId)
      this.getStates(this.form.value.businessCountryId, 'businessStateId');

    this.changeValidity('corporateCategoryType', 'othersCorporateCategoryType', this.othersId);
    this.changeValidity('companyType', 'otherCompanyType', CorporateBusinessType.Other);
    this.logoUrl = this.company.companyLogoUrl;
    this.bannerUrl = this.company.companyBannerUrl;

    this.form.controls.description.setValue(this.company.companyDetail?.description);

    if (this.company.companyDetail?.info) {
      this.form.controls.info.setValue(this.company.companyDetail?.info);
    }

    if (this.company.companySectors && this.company.companySectors.length > 0) {
      this.selectedSectors = this.company.companySectors;
    }

    if (this.company.msicCodes && this.company.msicCodes.length > 0) {
      this.msicCodes = this.company.msicCodes;
    }

    if (this.company.companySocialLinks && this.company.companySocialLinks.length > 0) {
      this.form.controls.companySocialLinks = this.formBuilder.array([]);

      this.socialLinkTypes.forEach((socialLink) => {
        this.socialFormControl.controls.push(this.buildSocialLinkForm(socialLink.type));
      });

      this.socialFormControl.controls.forEach((socialLink: UntypedFormGroup) => {
        let findLink = this.company.companySocialLinks.find(
          (link) => link.type == socialLink.value.type,
        );

        if (findLink) socialLink.patchValue(findLink);
      });
    }

    this.form.controls.isCheck.reset();
    this.isPreloading = false;
  }

  submit() {
    this.socialFormControl.updateValueAndValidity();

    if (this.form.invalid || this.socialFormControl.invalid || this.selectedSectors.length == 0) {
      this.popupService.alert('common.missingFieldsAlert');
      this.showFormError = true;
      return;
    }

    // if (!this.logoUrl) {
    //   this.popupService.alert('company.pleaseUploadCompanyLogo');
    //   return;
    // }

    let company = this.form.value;
    company.companyLogoAttachment = this.companyLogoAttachment;
    company.companySocialLinks = this.form.controls.companySocialLinks.value;
    company.companySocialLinks = company.companySocialLinks.filter(
      (company) => company.url && company.url != '',
    );
    company.companySectors = this.selectedSectors.map((sector) => sector.id);
    company.msicCodes = this.msicCodes;
    company.brandName =
      company.brandName && company.brandName != '' ? company.brandName : company.name;

    if (this.isIssuer) {
      company.companyBannerAttachment = this.companyBannerAttachment;
      company.companyDetail = {
        description: this.form.value.description,
        info: this.form.value.info,
      };
    }

    this.submitForm.emit(company);
  }

  async selectFile(file: File, type: number) {
    const uploadOption: FileUploadOption = {
      fileType: ImageFileFormat,
      errorMsg: 'common.onlyImageAccepted',
    };

    if (await this.fileValidationService.validFile(file, uploadOption)) {
      if (type == 1) {
        this.isLoading = true;
        this.logoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
        this.accountService
          .uploadAttachmentPresignedURL(file, ContainerType.CompanyLogo)
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe({
            next: (data) => {
              this.logoUrl = data.url;
              this.companyLogoAttachment.attachmentInfo = new AttachmentInfo(data);
              this.companyLogoAttachment.isDeleted = false;
            },
            error: (err) => {
              this.errorService.showError(err);
              this.logoUrl = null;
              this.companyLogoAttachment = new AttachmentInfo();
            },
          });
      } else {
        this.isBannerLoading = true;
        this.bannerUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
        this.accountService
          .uploadAttachmentPresignedURL(file, ContainerType.CompanyBanner)
          .pipe(finalize(() => (this.isBannerLoading = false)))
          .subscribe({
            next: (data) => {
              this.bannerUrl = data.url;
              this.companyBannerAttachment.attachmentInfo = new AttachmentInfo(data);
              this.companyBannerAttachment.isDeleted = false;
            },
            error: (err) => {
              this.errorService.showError(err);
              this.bannerUrl = null;
              this.companyBannerAttachment = new AttachmentInfo();
            },
          });
      }
    }
  }

  removeImage() {
    this.logoUrl = null;
    this.companyLogoAttachment = new AttachmentInfo();
    this.companyLogoAttachment.isDeleted = true;
  }

  removeBanner() {
    this.bannerUrl = null;
    this.companyBannerAttachment = new AttachmentInfo();
    this.companyBannerAttachment.isDeleted = true;
  }

  checkNoOfWords() {
    let tagline = this.form.value.tagline;
    let wordCount = tagline ? tagline.split(' ').length : 0;

    if (wordCount > 15) {
      this.form.controls.tagline.setErrors({ 'incorrect': true });
    }
  }

  getSectors(keywords: string) {
    this.settingService.getSectors(keywords).subscribe({
      next: (data) => (this.sectors = data),
      error: (err) => this.errorService.showError(err),
    });
  }

  getStates(id: string, stateControl: string) {
    let states = State.getStatesOfCountry(id);

    if (stateControl == 'stateId') this.states = states;
    else this.businessStates = states;

    if (states && states.length > 0) {
      this.form.controls[stateControl].setValidators([Validators.required]);

      if (!states.find((state) => state.isoCode == this.form.controls[stateControl].value))
        this.form.controls[stateControl].setValue(null);
    } else {
      this.form.controls[stateControl].clearValidators();
      this.form.controls[stateControl].setValue(null);
    }

    this.form.controls[stateControl].updateValueAndValidity();
  }

  removeRichText({ editor }: ChangeEvent) {
    const editingView = editor.editing.view;

    editingView.document.on('clipboardInput', (evt, data) => {
      if (editor.isReadOnly || data.method != 'paste') {
        return;
      }

      const dataTransfer = data.dataTransfer;
      let content = plainTextToHtml(dataTransfer.getData('text/plain'));
      data.content = editor.data.htmlProcessor.toView(content);

      editingView.scrollToTheSelection();
    });
  }

  changeBusinessType() {
    if (this.form.controls.companyType.value == CorporateBusinessType.Other) {
      this.form.controls.otherCompanyType.enable();
      this.form.controls.otherCompanyType.setValidators([Validators.required]);
      return;
    }

    this.form.controls.otherCompanyType.setValue(null);
    this.form.controls.otherCompanyType.disable();
    this.form.controls.otherCompanyType.clearValidators();
  }

  addCode() {
    if (
      this.msicCode &&
      (this.msicCode = this.msicCode.trim()) &&
      !this.msicCodes.includes(this.msicCode)
    ) {
      this.msicCodes.push(this.msicCode);
      this.msicCode = null;
    }
  }

  deleteCode(msicCode: string) {
    this.msicCodes.splice(this.msicCodes.indexOf(msicCode), 1);
  }

  copyAddress() {
    if (!this.form.value.sameAsRegisteredAddress) return;

    this.form.controls.businessAddress.setValue(this.form.value.address);
    this.form.controls.businessPostCode.setValue(this.form.value.postCode);
    this.form.controls.businessCity.setValue(this.form.value.city);
    this.form.controls.businessCountryId.setValue(this.form.value.countryId);

    this.getStates(this.form.value.businessCountryId, 'businessStateId');
    this.form.controls.businessStateId.setValue(this.form.value.stateId);
  }

  changeValidity(fieldName: string, otherFieldName: string, othersId: number) {
    if (this.form.value[fieldName] == othersId) {
      this.form.controls[otherFieldName].setValidators([Validators.required]);
    } else {
      this.form.controls[otherFieldName].setValue(null);
      this.form.controls[otherFieldName].clearValidators();
    }

    this.form.controls[otherFieldName].updateValueAndValidity();
  }
}
