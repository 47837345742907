<ng-container *ngIf="name === 'ecf'">
  <a
    class="p-badge p-badge--hoverable tw-mb-2 tw-bg-ecf tw-text-ecf"
    [routerLink]="badges[name].redirect"
  >
    {{ 'menu.' + name + 'Title' | translate }}
  </a>
</ng-container>

<ng-container *ngIf="name === 'pstx'">
  <a class="tw-mb-2 tw-inline-block" [routerLink]="badges[name].redirect">
    <img
      src="./assets/img/logo/logo_pstx.png"
      alt="PSTX"
      [height]="size === 'lg' ? '32' : '24'"
      class="tw-m-auto tw-inline-block"
    />
  </a>
</ng-container>

<ng-container *ngIf="name === 'ieo'">
  <a
    class="p-badge p-badge--hoverable tw-mb-2 tw-bg-ieo tw-text-ieo"
    [href]="badges[name].redirect"
  >
    {{ 'menu.' + name + 'Title' | translate }}
  </a>
</ng-container>
