import { Injectable } from '@angular/core';
import { FieldKey } from '../models/share/field-key';

@Injectable()
export class CsvService {
  downloadFile(jsonData: Object[], fieldNames: FieldKey[], filename: string = 'data') {
    let csvData = this.ConvertToCSV(jsonData, fieldNames);

    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);

    let isSafariBrowser =
      navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }

    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(jsonData: Object[], fieldNames: FieldKey[]) {
    let array = typeof jsonData != 'object' ? JSON.parse(jsonData) : jsonData;
    let str = '';
    let row = 'No.,';

    fieldNames.forEach((field) => {
      row += field.title + ',';
    });

    row = row.slice(0, -1);
    str += row + '\r\n';

    for (let i = 0; i < array.length; i++) {
      let line = i + 1 + '';

      fieldNames.forEach((field) => {
        let head = field.key;
        let content = array[i][head];

        if (typeof content === 'string') line += ',' + JSON.stringify(array[i][head]);
        else line += ',' + array[i][head];
      });

      str += line + '\r\n';
    }

    return str;
  }
}
