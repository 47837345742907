// credit to: https://dev.to/shrihari/efficiently-manage-repeated-requests-in-angular-with-interceptors-4amn
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Injectable()
export class CancelPreviousRequestInterceptor implements HttpInterceptor {
  private cache = new Map<string, Subject<void>>();

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const shouldAbort = request.params.keys().includes('isAbortable');

    // Only cancel GET requests or `isAbortable` is exist in the params
    if (request.method !== 'GET' || !shouldAbort) {
      return next.handle(request);
    }

    const url = request.url;

    // check if the request is already cached
    const cachedResponse = this.cache.get(url);

    // cancel any previous requests
    if (cachedResponse) {
      cachedResponse.next();
    }

    const cancelRequests$ = new Subject<void>();

    // cache the new request , so that we can cancel it if needed.
    this.cache.set(url, cancelRequests$);

    const newRequest = next.handle(request).pipe(
      // cancel the request if a same request comes in.
      takeUntil(cancelRequests$),

      // complete the subject when the request completes.
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.cache.delete(url);
        }
      }),
    );

    return newRequest;
  }
}
