import { Component } from '@angular/core';
import { SlideContent } from '../../../core/models/mysec/logo-slider';
import { ForInvestorsCount } from '../../../core/models/mysec/for-investors-count';

@Component({
  selector: 'app-for-investors',
  templateUrl: './for-investors.component.html',
  styleUrls: ['./for-investors.component.scss'],
})
export class ForInvestorsComponent {
  investorCount: ForInvestorsCount = {
    'count': '18,000+',
  };

  logoSlider: SlideContent[] = [
    {
      'src': 'logo_square_chargehere.jpg',
      'name': 'ChargeHere',
    },
    {
      'src': 'logo_square_commerce_asia.jpg',
      'name': 'Commerce.Asia',
    },
    {
      'src': 'logo_square_fundaztic.png',
      'name': 'Fundaztic',
    },
    {
      'src': 'logo_square_kakitangan.jpg',
      'name': 'Kakitangan',
    },
    {
      'src': 'logo_square_lapasar.png',
      'name': 'Lapasar',
    },
    {
      'src': 'logo_square_moneymatch.png',
      'name': 'MoneyMatch',
    },
    {
      'src': 'logo_square_newleaf_agro.jpg',
      'name': 'Newleaf Agro',
    },
    {
      'src': 'logo_square_nu_vending.jpg',
      'name': 'NU VENDING',
    },
    {
      'src': 'logo_square_oxwhite.png',
      'name': 'OXWHITE',
    },
    {
      'src': 'logo_square_policy_street.png',
      'name': 'PolicyStreet',
    },
    {
      'src': 'logo_square_signature_market.jpeg',
      'name': 'Signature Market',
    },
    {
      'src': 'logo_square_slurp.png',
      'name': 'Slurp',
    },
    {
      'src': 'logo_square_speedhome.jpg',
      'name': 'Speedhome',
    },
    {
      'src': 'logo_square_superceed.jpg',
      'name': 'Superceed',
    },
    {
      'src': 'logo_square_the_lorry.png',
      'name': 'The Lorry',
    },
    {
      'src': 'logo_square_union_chain.png',
      'name': 'Union Artisan Coffee',
    },
  ];
}
