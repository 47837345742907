import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from '../../core/services/error.service';
import { Banner } from '../../core/models/banner/banner';
import { HomeService } from '../../core/services/api/home.service';
import { AuthService } from '../../core/services/auth/auth.service';
import { CampaignSectionFilterService } from './campaign-section/campaign-section-filter.service';
import { Sector } from '../../core/models/company/sector';
import { InvestedItem } from '../../core/models/banner/invested-item';
import { DefaultBanner, DefaultLogo } from '../../core/services/constant';
import { isPlatformBrowser } from '@angular/common';
import SwiperCore, { Pagination, Autoplay, SwiperOptions, Navigation } from 'swiper';
import { PaginationOptions } from 'swiper/types';
import { CampaignStatus } from '../../core/enum/campaign-status.enum';
import { BannerType } from '../../core/enum/banner-type.enum';
import { CampaignSectionFilter } from './campaign-section/campaign-section-filter';
import { CampaignService } from 'src/app/core/services/api/campaign.service';
import { finalize } from 'rxjs';
import { DataStatus } from '../../core/models/data-status';
import { environment } from '../../../environments/environment';
import { TCFCampaign } from '../../core/models/campaign/tcf-campaign.model';
import { TCFCampaignService } from '../../core/services/api/tcf-campaign.service';
import * as moment from 'moment';

SwiperCore.use([Pagination, Autoplay, Navigation]);

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, AfterViewInit {
  public environment = environment;

  isLogin: boolean;
  index: number = 0;
  banners: Banner[];
  bannerIndex: number = 0;

  defaultLogo = DefaultLogo;
  defaultBanner = DefaultBanner;

  loadSwiper: boolean = false;
  loadBannerSwiper: boolean = false;
  activeSlide: number = 0;

  CampaignStatus: typeof CampaignStatus = CampaignStatus;
  selectedCampaignStatus: CampaignStatus = CampaignStatus.Live;

  liveOfferTcfCampaigns: TCFCampaign[] = [];
  preLiveTcfCampaigns: TCFCampaign[] = [];
  coolingOffTcfCampaigns: TCFCampaign[] = [];
  tcfBaseUrl: string = environment.ieoBaseUrl;
  loadingTCFCampaigns = true;

  campaigns: {
    [CampaignStatus.Live]: DataStatus;
    [CampaignStatus.PreLive]: DataStatus;
  } = {
    [CampaignStatus.Live]: {
      isLoading: true,
      total: 0,
      data: undefined,
    },
    [CampaignStatus.PreLive]: {
      isLoading: true,
      total: 0,
      data: undefined,
    },
  };

  private pagination: PaginationOptions = {
    el: '.swiper-pagination',
    clickable: true,
  };

  bannerConfig: SwiperOptions = {
    direction: 'horizontal',
    keyboard: true,
    pagination: this.pagination,
    slidesPerView: 1,
    updateOnImagesReady: true,
    navigation: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    loop: true,
  };

  investedConfig: SwiperOptions = {
    direction: 'horizontal',
    keyboard: true,
    breakpoints: {
      1800: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      1500: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      1024: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      750: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      550: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
    },
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
  };

  sectorsConfig: SwiperOptions = {
    direction: 'horizontal',
    keyboard: true,
    navigation: true,
    loop: true,
    slidesPerView: 1,
  };

  investedData: InvestedItem[];

  sectors = [
    {
      id: 298,
      title: 'Tech',
      name: 'Technology',
      rssReferenceName: 'Technology',
      textColor: 'white',
      imageUrl: 'assets/img/brand/sector_tech_image@2x.png',
    },
    {
      id: 274,
      title: 'Services',
      name: 'Services',
      rssReferenceName: 'Services',
      textColor: 'white',
      imageUrl: 'assets/img/brand/sector_services_image@2x.png',
    },
    {
      id: 120,
      title: 'Fintech',
      name: 'Finance & Insurance Tech',
      rssReferenceName: 'Finance & Insurance Tech',
      textColor: 'black',
      imageUrl: 'assets/img/brand/sector_fintech_image@2x.png',
    },
  ];

  // (PLT-1422): For PSTX banner takeover only
  // Todo: Can remove after pstx is launched fully
  @ViewChild('pstxBanner') pstxBanner: ElementRef;
  @ViewChild('pstxBannerLive') pstxBannerLive: ElementRef;

  constructor(
    private authService: AuthService,
    private campaignSectionFilterService: CampaignSectionFilterService,
    private router: Router,
    private homeService: HomeService,
    private errorService: ErrorService,
    private campaignService: CampaignService,
    private TCFCampaignService: TCFCampaignService,
    @Inject(PLATFORM_ID) private platform: Object,
  ) {}

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.isLogin = true;
    }

    if (isPlatformBrowser(this.platform)) {
      this.getBannerList();
      this.getLatestInvestmentList();
    }

    this.getCampaigns(CampaignStatus.Live);
    this.loadTCFCampaigns();
  }

  ngAfterViewInit() {
    // Todo: (PLT-1422) Can remove after pstx is launched fully
    if (isPlatformBrowser(this.platform)) {
      this.initPstxBanner();
    }
  }

  // Todo: (PLT-1422) Can remove after pstx is launched fully
  initPstxBanner() {
    const currentDate = moment();
    const pstxLaunchDate = moment.tz('2024-07-10 23:59:59', 'Asia/Singapore');
    const expireDate = moment.tz('2024-08-10 23:59:59', 'Asia/Singapore');

    if (currentDate.isBefore(pstxLaunchDate)) {
      this.pstxBanner.nativeElement.style.display = 'block';
      return;
    }

    if (currentDate.isBefore(expireDate)) {
      this.pstxBannerLive.nativeElement.style.display = 'block';
      return;
    }
  }

  selectSector(sector: Sector) {
    this.campaignSectionFilterService.clear();
    this.router.navigate(['equity'], { queryParams: { sectorIds: sector.id } });
  }

  getBannerList() {
    this.homeService.getBannerList().subscribe(
      (data) => {
        this.banners = data;

        if (this.banners) {
          this.banners = this.banners.filter((banner) => banner.bannerType == BannerType.ECF);
        }

        if (this.banners && this.banners.length == 1) {
          this.bannerConfig.loop = false;
          this.bannerConfig.navigation = false;
          this.bannerConfig.pagination = null;
        }

        setTimeout(() => {
          this.loadBannerSwiper = true;
        }, 500);
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  getLatestInvestmentList() {
    this.homeService.getLatestInvestmentList().subscribe(
      (data) => {
        this.investedData = data;

        setTimeout(() => {
          this.loadSwiper = true;
        }, 500);
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  checkIsActive(isActive: boolean, index: number) {
    if (isActive) {
      this.activeSlide = index;
      return true;
    }

    if (!isActive && (index == this.activeSlide + 1 || index == this.activeSlide + 2)) return true;

    return false;
  }

  handleLoadCampaigns(campaignStatus: CampaignStatus) {
    if (this.campaigns[campaignStatus].data) return;

    this.getCampaigns(campaignStatus);
  }

  getCampaigns(campaignStatus: CampaignStatus) {
    const take = campaignStatus == CampaignStatus.Live ? 12 : 6;
    const campaign = this.campaigns[campaignStatus];

    campaign.isLoading = true;
    campaign.data = [];

    this.campaignService
      .getCampaigns(new CampaignSectionFilter(campaignStatus, take))
      .pipe(finalize(() => (campaign.isLoading = false)))
      .subscribe({
        next: (data) => {
          campaign.data = data.data;
          campaign.total = data.total;

          // PLT-1200 switch to prelive tabs if live is empty
          if (campaignStatus === CampaignStatus.Live && data.total === 0) {
            this.selectedCampaignStatus = CampaignStatus.PreLive;
            this.handleLoadCampaigns(CampaignStatus.PreLive);
          }
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }

  loadTCFCampaigns() {
    this.loadingTCFCampaigns = true;
    this.TCFCampaignService.getCampaignsFrontPage()
      .pipe(finalize(() => (this.loadingTCFCampaigns = false)))
      .subscribe({
        next: (data) => {
          this.liveOfferTcfCampaigns = data.liveOffer;
          this.preLiveTcfCampaigns = data.preLive;
          this.coolingOffTcfCampaigns = data.coolingDown;
        },
        error: (error) => {
          this.errorService.showError(error);
        },
      });
  }
}
