import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CampaignFiltersMenu } from '../../../../../core/models/campaign/campaign-filters-menu';
import { CampaignFiltersTag } from '../../../../../core/models/campaign/campaign-filters-tag';

@Component({
  selector: 'app-campaign-filters-toggle',
  templateUrl: './campaign-filters-toggle.component.html',
  styleUrls: ['./campaign-filters-toggle.component.scss'],
})
export class CampaignFiltersToggleComponent implements OnInit {
  isCollapsed: boolean;

  @Input() filterMenus: CampaignFiltersMenu[];
  @Input() filterTags: Array<CampaignFiltersTag>;
  @Input() selectedMenu!: string;
  @Input() selectedMenuIndex: number;
  @Input() portalName: string;
  @Output() onSelectedMenuChange = new EventEmitter<string>();
  @Output() onFiltersChange = new EventEmitter<object>();

  isSingleMenu = false;

  constructor() {
    this.isCollapsed = true;
  }

  ngOnInit(): void {
    this.isSingleMenu = this.filterMenus && this.filterMenus.length == 1;
  }

  tabClick(labelName) {
    this.onSelectedMenuChange.emit(labelName !== this.selectedMenu ? labelName : '');

    if (labelName === this.selectedMenu) {
      this.isCollapsed = true;
    } else {
      // Wait until the content is loaded only collapse
      // to ensure the height is calculated correctly
      setTimeout(() => {
        this.isCollapsed = false;
      }, 100);
    }
  }

  handleChange(menu: CampaignFiltersTag) {
    this.onFiltersChange.emit(menu);
  }

  isActive(childMenu: CampaignFiltersMenu) {
    const isTagExist = this.filterTags.some(
      (t) => t.key == childMenu.key && t.label == childMenu.label,
    );
    return isTagExist;
  }
}
