import { Component, Input } from '@angular/core';
import { Article } from '../../../../core/models/learn/article';

@Component({
  selector: 'app-learn-article-submenu',
  templateUrl: './learn-article-submenu.component.html',
  styleUrls: ['./learn-article-submenu.component.scss'],
})
export class LearnArticleSubmenuComponent {
  @Input() articles: Article[];
  @Input() category: string;
  @Input() currentArticle: string;

  constructor() {}
}
