<div class="text-primary pb-3 d-none d-md-block overview-title">
  {{ 'campaign.businessOverview' | translate }}
</div>
<button
  type="button"
  class="btn btn-lightgrey d-md-none"
  (click)="collapse.toggle()"
  [attr.aria-expanded]="!isCollapsed"
  aria-controls="collapseCampaign"
>
  {{ 'campaign.businessOverview' | translate }}
  <i
    class="fa pull-right"
    [class.fa-caret-up]="!isCollapsed"
    [class.fa-caret-down]="isCollapsed"
  ></i>
</button>
<div class="overview-detail" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
  <div class="pb-3">
    <div class="overview-label">{{ 'company.name' | translate }}</div>
    <div class="overview-desc font-weight-bold text-black">
      {{ businessDirectory.name || '-' }}
    </div>
  </div>
  <div class="pb-3">
    <div class="overview-label">{{ 'campaign.location' | translate }}</div>
    <div class="overview-desc font-weight-bold text-black">
      {{ businessDirectory.address || '-' }}
    </div>
  </div>
  <div class="pb-3" *ngIf="businessDirectory.socialLinks.length > 0">
    <div class="overview-label">{{ 'campaign.socialMedia' | translate }}</div>
    <div class="overview-desc d-flex flex-wrap">
      <a
        [href]="social.url | weblink"
        target="_blank"
        *ngFor="let social of businessDirectory.socialLinks"
      >
        <div
          class="pitchin-icon mr-3"
          [class.pitchin-icon-facebook]="social.type == SocialLinkType.Facebook"
          [class.pitchin-icon-twitter]="social.type == SocialLinkType.Twitter"
          [class.pitchin-icon-instagram]="social.type == SocialLinkType.Instagram"
          [class.pitchin-icon-linkedin]="social.type == SocialLinkType.LinkedIn"
          [class.pitchin-icon-tiktok]="social.type == SocialLinkType.TikTok"
        ></div>
      </a>
      <span
        *ngIf="!businessDirectory.socialLinks || businessDirectory.socialLinks.length == 0"
        class="font-weight-bold text-black"
        >-</span
      >
    </div>
  </div>
  <div class="pb-3">
    <div class="overview-label">{{ 'campaign.website' | translate }}</div>
    <div class="overview-desc font-weight-bold text-break-all text-black">
      <a
        *ngIf="businessDirectory.websiteUrl; else empty"
        [href]="businessDirectory.websiteUrl | weblink"
        target="_blank"
      >
        {{ businessDirectory.websiteUrl | weblink: 'true' }}</a
      >
      <ng-template #empty>-</ng-template>
    </div>
  </div>
  <div class="pb-3">
    <div class="overview-label">{{ 'campaign.sectors' | translate }}</div>
    <div
      *ngIf="businessDirectory.sectors.length > 0; else empty"
      class="overview-desc d-flex flex-wrap"
    >
      <div class="category-badge" *ngFor="let sector of businessDirectory.sectors">
        {{ sector.name }}
      </div>
    </div>
  </div>
  <div class="pb-3">
    <div class="overview-label">{{ 'campaign.registrationNo' | translate }}</div>
    <div class="coverview-desc font-weight-bold text-black">
      {{ businessDirectory.registrationNumber || '-' }}
    </div>
  </div>
</div>
