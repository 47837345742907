<div class="guide1-step-3 tw-flex tw-flex-wrap tw-items-center">
  <div
    class="tw-mb-3 tw-w-full md:tw-pr-2 lg:tw-mb-0"
    [ngClass]="{
      'md:tw-flex-1 min-[1201px]:!tw-w-3/12 min-[1201px]:tw-flex-none': isLogin,
      'lg:tw-flex-1': !isLogin,
    }"
  >
    <search-input
      [placeholder]="'mysec.searchBusinesses'"
      [value]="params.filterString"
      (onSubmit)="handleSearch($event)"
    ></search-input>
  </div>

  <div *ngIf="isLogin" class="tw-flex tw-max-w-[50%] tw-pr-6 max-[1200px]:tw-hidden">
    <div
      class="btn"
      *ngIf="!params.isWatchList"
      [class.active]="showAllActive()"
      (click)="showAll()"
      data-t="filterClick"
    >
      {{ 'Show All' | translate }}
    </div>
    <ng-container>
      <ng-container *ngTemplateOutlet="status"></ng-container>
    </ng-container>
  </div>
  <div class="tw-mb-2 tw-flex tw-items-center lg:tw-mb-0 lg:tw-ml-auto lg:tw-flex-none lg:tw-pl-4">
    <div class="dropdown tw-block tw-cursor-pointer tw-select-none tw-text-body" ngbDropdown>
      <div
        id="filterButton"
        [ngClass]="'tw-flex tw-items-center'"
        aria-controls="menu"
        ngbDropdownToggle
      >
        <span class="tw-pr-1 tw-font-semibold">{{ 'common.sortBy' | translate }}:</span>
        <span
          class="tw-line-clamp-1 tw-inline-block tw-overflow-ellipsis tw-whitespace-nowrap min-[1201px]:tw-max-w-[180px]"
          >{{ InvestmentSortType[params.orderBy] | translate }}</span
        >
        <i class="fa fa-caret-down tw-ml-2 tw-opacity-50"></i>
      </div>
      <div
        class="dropdown-menu tw-min-w-[250px] tw-max-w-full"
        ngbDropdownMenu
        role="menu"
        aria-labelledby="filterButton"
      >
        <div
          role="menuitem"
          *ngFor="let type of InvestmentSortType | enumToArray"
          class="dropdown-item font-weight-bold font-size-regular tw-cursor-pointer"
          (click)="selectFilterType(type.key)"
          data-t="sortClick"
        >
          {{ type.value | translate }}
        </div>
      </div>
    </div>

    <div class="c-filter__divider"></div>

    <div class="tw-whitespace-nowrap">
      <app-campaign-filters-toggle
        [filterMenus]="filtersMenus"
        [filterTags]="filterTags"
        [selectedMenu]="selectedFilterMenu"
        [selectedMenuIndex]="selectedFilterMenuIndex"
        [portalName]="'#c-filter__dropdown-section-pstx'"
        (onSelectedMenuChange)="handleSelectedMenuChange($event)"
        (onFiltersChange)="handleFiltersChange($event)"
      >
      </app-campaign-filters-toggle>
    </div>
  </div>

  <div id="c-filter__dropdown-section-pstx" class="c-filter__dropdown-section tw-w-full"></div>

  <div class="tw-mt-1 tw-w-full" *ngIf="filterTags.length > 0">
    <ul class="filter-tag">
      <li class="filter-tag__item" (click)="handleRemoveTag(tag)" *ngFor="let tag of filterTags">
        {{ tag.label }}
        <i class="fa fa-times"></i>
      </li>
    </ul>
  </div>

  <div class="tw-flex tw-w-full tw-pb-1 tw-pt-2 min-[1201px]:tw-hidden" *ngIf="isLogin">
    <ng-container *ngTemplateOutlet="status"></ng-container>
  </div>
</div>

<ng-template #status>
  <ng-container *ngFor="let status of InvestorShareMarketStatusFilter | enumToArray">
    <div
      class="btn"
      *ngIf="
        status.key != InvestorShareMarketStatusFilter.Watched ||
        (status.key == InvestorShareMarketStatusFilter.Watched && !params.isWatchList)
      "
      [class.active]="params.investorShareMarketStatus.includes(status.key)"
      (click)="filterStatus(status.key)"
      data-t="filterClick"
    >
      {{ status.value | translate }}
      <div
        *ngIf="params.investorShareMarketStatus.includes(status.key)"
        class="pitchin-icon-clear tw-ml-2 tw-h-3 tw-w-3"
      ></div>
    </div>
  </ng-container>
</ng-template>
