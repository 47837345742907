import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-signup-layout',
  templateUrl: './signup-layout.component.html',
  styleUrls: ['./signup-layout.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: '0' })),
      state('in', style({ opacity: '1' })),
      transition('void => *', animate('350ms ease-in')),
    ]),
  ],
})
export class SignupLayoutComponent {
  @Input() backgroundClass: string;
  @Input() backgroundImage: string;
  @Input() backgroundImageAlt: string = 'Sign up';
  @Input() isFullWidth: boolean = false;
}
