import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, ReplaySubject, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { MarketDetailsStatisticCount } from '../../../../core/models/campaign/statistic-count';
import { BusinessesFilter } from '../../../../pages/mysec/businesses/businesses-filter';
import { OrderFilter } from '../../../../pages/mysec/my-orders/order-filter';
import { TradeFilter } from '../../../../pages/mysec/trade-list/trade-filter';
import { PagedResult } from '../../../../shared/models/paged-result/paged-result';
import { InvestmentSortType } from '../../../enum/investment-sort-type.enum';
import { ClosingPriceRange } from '../../../enum/mysec/closing-price-range.enum';
import { Role } from '../../../enum/role.enum';
import { Attachment } from '../../../models/attachment/attachment';
import { Announcement } from '../../../models/campaign/announcements';
import { FinancialOverview } from '../../../models/campaign/financial-overview';
import { Business } from '../../../models/mysec/business';
import { ClosingPrice } from '../../../models/mysec/closing-price';
import { FinancialSummary } from '../../../models/mysec/financial-summary';
import { OpenOrder } from '../../../models/mysec/open-order';
import { OpenOrderList } from '../../../models/mysec/open-order-list';
import { OpenOrderModel } from '../../../models/mysec/open-order.model';
import { OrderBook } from '../../../models/mysec/order-book';
import { OrderDraft } from '../../../models/mysec/order-draft';
import { OrderDraftModel } from '../../../models/mysec/order-draft.model';
import { OrderPaymentDetail } from '../../../models/mysec/order-payment-detail';
import { Rss } from '../../../models/mysec/rss';
import { Trade } from '../../../models/mysec/trade';
import { TradingSession } from '../../../models/mysec/trading-session';
import { EncryptService } from '../../encrypt.service';
import { StorageService } from '../../storage.service';
import { ShareholderFilter } from 'src/app/pages/mysec/businesses/shareholder-filter';
import { ShareMovementParams } from '../../../../pages/mysec/mysec-settings/share-movement-tracker/share-movement-params';
import { TickerCode } from '../../../models/mysec/ticker-code';

@Injectable()
export class BusinessesService {
  private route = environment.apiServer + 'Api/v1/MySec/{role}';
  marketDetailStatisticCount: ReplaySubject<MarketDetailsStatisticCount> =
    new ReplaySubject<MarketDetailsStatisticCount>(1);

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private encryptService: EncryptService,
  ) {}

  getBusinessesList(filter: BusinessesFilter): Observable<PagedResult<Business>> {
    const params: any = Object.assign({}, { ...filter, isAbortable: true });

    if (params.orderBy) {
      //} && params.orderBy != 0) {
      params.IsOrderByDesc = params.orderBy != InvestmentSortType.TickerCode;
      params.orderBy = InvestmentSortType[params.orderBy];
    } else delete params.orderBy;

    if (!params.filterString) delete params.filterString;

    if (!params.investorShareMarketStatus || params.investorShareMarketStatus.length == 0)
      delete params.investorShareMarketStatus;

    if (!params.isWatchList) delete params.isWatchList;

    // If `selectedSectors` is empty, don't override the `sectorIds`
    // as it might be from the url
    if (params.selectedSectors && params.selectedSectors.length > 0) {
      params.sectorIds = params.selectedSectors.map((sector) => sector.id || sector.key);

      delete params.selectedSectors;
    }

    if (
      !this.storageService.role ||
      ![Role.IndividualInvestor, Role.CompanyRep, Role.IssuerRep].includes(this.storageService.role)
    )
      params['noToken'] = 'noToken';

    return this.http.get(this.route + '/markets', { params: params }).pipe(
      map((data: any) => {
        return {
          data: data.result.data ? data.result.data.map((item) => new Business(item)) : [],
          total: data.result.totalCount,
        };
      }),
    );
  }

  getMarketDetails(tickerCode: string): Observable<Business> {
    const params = !this.storageService.role ? { 'noToken': 'noToken' } : null;

    return this.http.get(this.route + '/markets/' + tickerCode, { params: params }).pipe(
      catchError(() => of(null)),
      switchMap((data: any) => {
        return this.getRelatedTickerCodes(data?.result?.id).pipe(
          catchError(() => of(null)),
          map((data2: any) => {
            return new Business({
              ...data.result,
              relatedShares: data2,
            });
          }),
        );
      }),
    );
  }

  getFinancialSummary(tickerCode: string): Observable<FinancialSummary> {
    const params = !this.storageService.role ? { 'noToken': 'noToken' } : null;

    return this.http
      .get(this.route + '/markets/' + tickerCode + '/financialSummary', { params: params })
      .pipe(
        map((data: any) => {
          return new FinancialSummary(data.result);
        }),
      );
  }

  getRecentTrades(tickerCode: string, filter: TradeFilter): Observable<PagedResult<Trade>> {
    let params: any = Object.assign({}, filter);

    if (params.transactionDateFrom)
      params.transactionDateFrom = moment(params.transactionDateFrom).startOf('day').utc().format();
    if (params.transactionDateTo)
      params.transactionDateTo = moment.utc(params.transactionDateTo).format();

    if (!this.storageService.role) params['noToken'] = 'noToken';

    return this.http
      .get(this.route + '/markets/' + tickerCode + '/orders/recent', { params: params })
      .pipe(
        map((data: any) => {
          return {
            data: data.result.data ? data.result.data.map((item) => new Trade(item)) : [],
            total: data.result.totalCount,
          };
        }),
      );
  }

  getOrderBook(tickerCode: string): Observable<OrderBook> {
    let params = {};

    if (!this.storageService.role) params['noToken'] = 'noToken';

    return this.http
      .get(this.route + '/markets/' + tickerCode + '/orders/book', { params: params })
      .pipe(
        map((data: any) => {
          return new OrderBook(data.result);
        }),
      );
  }

  getOrderList(filter: OrderFilter): Observable<PagedResult<OpenOrderList>> {
    const params: any = Object.assign(
      {},
      {
        ...filter,
        isAbortable: true,
      },
    );

    if (params.orderContractStatus === null) {
      delete params.orderContractStatus;
    }

    if (params.transactionDateFrom === null && params.transactionDateTo === null) {
      delete params.transactionDateFrom;
      delete params.transactionDateTo;
    }

    return this.http.get(this.route + '/orders', { params: params }).pipe(
      map((data: any) => {
        return {
          data: data.result.data,
          total: data.result.totalCount,
        };
      }),
    );
  }

  getOrderHistoryList(filter: OrderFilter): Observable<PagedResult<OpenOrderList>> {
    const params: any = Object.assign({}, filter);

    return this.http.get(this.route + '/orders/history', { params: params }).pipe(
      map((data: any) => {
        return {
          data: data.result.data,
          total: data.result.totalCount,
        };
      }),
    );
  }

  getClosingPrices(tickerCode: string, range: ClosingPriceRange): Observable<ClosingPrice[]> {
    let params = {
      ChartDateRange: range.toString(),
    };

    if (!this.storageService.role) params['noToken'] = 'noToken';

    return this.http
      .get(this.route + '/markets/' + tickerCode + '/charts', { params: params })
      .pipe(
        map((data: any) => {
          return data.result && data.result.length > 0
            ? data.result.map((item) => new ClosingPrice(item))
            : [];
        }),
      );
  }

  getWatchList(filter: BusinessesFilter): Observable<PagedResult<Business>> {
    const params: any = Object.assign({}, filter);

    if (!params.filterString) delete params.filterString;

    return this.http.get(this.route + '/watchlist', { params: params }).pipe(
      map((data: any) => {
        return {
          data:
            data.result && data.result.data
              ? data.result.data.map((item) => new Business(item))
              : [],
          total: data.result.totalCount,
        };
      }),
    );
  }

  getFinancialOverview(
    tickerCode: string,
    currentPage: number,
    take: number,
  ): Observable<PagedResult<FinancialOverview>> {
    let params = {
      currentPage: currentPage.toString(),
      take: take.toString(),
    };

    if (!this.storageService.role) params['noToken'] = 'noToken';

    return this.http
      .get(this.route + '/markets/' + tickerCode + '/Companies/financialOverview', {
        params: params,
      })
      .pipe(
        map((data: any) => {
          return {
            data: data.result.data
              ? data.result.data.map((item) => new FinancialOverview(item))
              : [],
            total: data.result.totalCount,
          };
        }),
      );
  }

  getAnnouncements(
    tickerCode: string,
    currentPage: number,
    take: number,
  ): Observable<PagedResult<Announcement>> {
    let params = {
      currentPage: currentPage.toString(),
      take: take.toString(),
    };

    if (!this.storageService.role) params['noToken'] = 'noToken';

    return this.http
      .get(this.route + '/markets/' + tickerCode + '/Companies/announcements', { params: params })
      .pipe(
        map((data: any) => {
          return {
            data: data.result.data ? data.result.data.map((item) => new Announcement(item)) : [],
            total: data.result.totalCount,
          };
        }),
      );
  }

  getAllAnnouncements(currentPage: number, take: number): Observable<PagedResult<Announcement>> {
    let params = {
      currentPage: currentPage.toString(),
      take: take.toString(),
      noToken: 'noToken',
    };

    return this.http
      .get(environment.apiServer + 'Api/v1/MySec/public/Companies/announcement', { params: params })
      .pipe(
        map((data: any) => {
          return {
            data: data.result.data || [],
            total: data.result.totalCount,
          };
        }),
      );
  }

  getDocuments(tickerCode: string): Observable<PagedResult<Attachment>> {
    return this.http.get(this.route + '/markets/' + tickerCode + '/Companies/documents').pipe(
      map((data: any) => {
        return {
          data: data.result.data || [],
          total: data.result.totalCount,
        };
      }),
    );
  }
  x;
  getRelatedTickerCodes(companyId: number) {
    const params = !this.storageService.role ? { 'noToken': 'noToken' } : null;

    return this.http
      .get(environment.apiServer + 'Api/v1/MySec/public/companies/' + companyId + '/tickerCode', {
        params: params,
      })
      .pipe(
        map((data: any) => {
          return data.result;
        }),
      );
  }

  addOrder(tickerCode: string, openOrder: OpenOrder): Observable<any> {
    let body = new OpenOrderModel(openOrder);
    body.password = this.encryptService.encryptData(body.password);
    body.price = +body.price;

    return this.http.post(this.route + '/markets/' + tickerCode + '/orders/encryption', body).pipe(
      map((data: any) => {
        return data.result.orderNo;
      }),
    );
  }

  getPaymentDetails(tickerCode: string, orderDraft: OrderDraft): Observable<OrderPaymentDetail> {
    const params = !this.storageService.role ? { 'noToken': 'noToken' } : null;

    const body = new OrderDraftModel(orderDraft);

    return this.http
      .post(this.route + '/markets/' + tickerCode + '/orders/paymentDetails', body, {
        params: params,
      })
      .pipe(
        map((data: any) => {
          return new OrderPaymentDetail(data.result);
        }),
      );
  }

  getRssUrl(tickerCode: string): Observable<Rss> {
    let params = {};

    if (!this.storageService.role) params['noToken'] = 'noToken';

    return this.http
      .get(this.route + '/markets/' + tickerCode + '/companies/news/rss', { params: params })
      .pipe(
        map((data: any) => {
          return data.result;
        }),
      );
  }

  addWatchlist(tickerCode: string): Observable<any> {
    return this.http.post(this.route + '/markets/' + tickerCode + '/watchlist', null);
  }

  removeWatchlist(tickerCode: string): Observable<any> {
    return this.http.delete(this.route + '/markets/' + tickerCode + '/watchlist');
  }

  getTickerCodeList(filter: BusinessesFilter): Observable<any> {
    const params: any = Object.assign({}, filter);

    if (!params.filterString) delete params.filterString;

    delete params.orderBy;

    return this.http.get(this.route + '/companies/shareMarket', { params: params }).pipe(
      map((data: any) => {
        return {
          data: data.result.data ? data.result.data.map((item) => new Business(item)) : [],
          total: data.result.totalCount,
          totalShares: data.result?.totalShares,
          totalNomineeShares: data.result?.totalNomineeShares,
        };
      }),
    );
  }

  getShareholderList(filter: ShareholderFilter): Observable<any> {
    const params: any = Object.assign({}, filter);

    return this.http.get(this.route + '/companies/shareholders', { params: params }).pipe(
      map((data: any) => {
        return {
          data: data.result.data || [],
          total: data.result.totalCount,
          totalShares: data.result?.totalShares,
          totalShareHolders: data.result?.totalShareHolders,
          tickerCodes: data.result?.tickerCodes,
        };
      }),
    );
  }

  getShareMovement(query: ShareMovementParams): Observable<any> {
    const params: any = Object.assign({}, query);

    return this.http.get(this.route + '/orders/history', { params: params }).pipe(
      map((data: any) => {
        return {
          data: data.result.data || [],
          total: data.result.totalCount,
        };
      }),
    );
  }

  getTradingSessions(): Observable<TradingSession> {
    let params = {};

    if (!this.storageService.role) params['noToken'] = 'noToken';

    return this.http
      .get(environment.apiServer + 'Api/v1/MySec/tradingSessions', { params: params })
      .pipe(
        map((data: any) => {
          return new TradingSession(data.result);
        }),
      );
  }

  cancelOrder(mySecOrderId: number): Observable<any> {
    return this.http.put(this.route + '/orders/' + mySecOrderId + '/cancel', null);
  }

  getMysecDocuments(
    tickerCode: string,
    currentPage: number,
    take: number,
  ): Observable<PagedResult<Attachment>> {
    let params = {
      currentPage: currentPage.toString(),
      take: take.toString(),
    };

    return this.http
      .get(this.route + '/markets/' + tickerCode + '/Companies/documents/investorOnly', {
        params: params,
      })
      .pipe(
        map((data: any) => {
          return {
            data: data.result.data || [],
            total: data.result.totalCount,
          };
        }),
      );
  }

  getStatisticCount(tickerCode: string): Observable<MarketDetailsStatisticCount> {
    const params = !this.storageService.role ? { 'noToken': 'noToken' } : null;

    return this.http
      .get(`${this.route}/markets/${tickerCode}/statisticCount`, { params: params })
      .pipe(
        map((data: any) => {
          return data.result;
        }),
      );
  }

  getTickerCodes(): Observable<TickerCode[]> {
    return this.http.get(`${this.route}/tickerCode`).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }
}
