import { Component, Input, OnChanges } from '@angular/core';
import { Article } from 'src/app/core/models/learn/article';

@Component({
  selector: 'app-learn-article-footer',
  templateUrl: './learn-article-footer.component.html',
  styleUrls: ['./learn-article-footer.component.scss'],
})
export class LearnArticleFooterComponent implements OnChanges {
  @Input() articles: Article[];
  @Input() currentArticle: string;
  prev: Article;
  next: Article;
  currentIndex: number;

  constructor() {}

  ngOnChanges() {
    if (this.articles && this.currentArticle) {
      this.currentIndex = this.articles.findIndex((article) => article.uid == this.currentArticle);

      this.prev = this.articles[this.currentIndex - 1];
      this.next = this.articles[this.currentIndex + 1];
    }
  }
}
