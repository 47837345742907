import { TeamMember } from './team-member';
import { AttachmentInfo } from '../attachment/attachment-info';
export class TeamMemberModel {
  name: string;
  position: string;
  details: string;
  attachmentInfo: AttachmentInfo;

  constructor(model: TeamMember) {
    this.name = model.name;
    this.position = model.position;
    this.details = model.details;
    this.attachmentInfo = model.attachmentInfo;
  }
}
