<div class="tw-relative tw-h-[320px] tw-w-full tw-bg-[#d00000]"></div>

<div class="tw-relative -tw-mt-[220px] tw-h-[220px]">
  <svg
    viewBox="0 0 500 150"
    preserveAspectRatio="none"
    class="tw-min-w-500px tw-absolute tw-h-[220px] tw-w-full"
  >
    <path
      d="M-60.00,181.04 C241.25,18.04 269.37,17.03 558.12,181.04 L500.00,150.00 L0.00,150.00 Z"
      style="stroke: none; fill: #ffffff"
    ></path>
  </svg>
</div>

<div class="tw-relative -tw-mt-[185px] tw-flex tw-flex-col tw-items-center">
  <img src="/assets/img/banner/ekyc.png" alt="kyc_image" class="tw-z-20" />
  <div class="tw-w-full tw-justify-center tw-bg-white tw-py-4">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 tw-mx-auto" *ngIf="!isProcess && !isLoading">
          <form
            #form
            [class.show-form-error]="showFormError"
            (ngSubmit)="proceed()"
            class="tw-flex tw-flex-col tw-items-center tw-px-2"
          >
            <h2 class="tw-py-2 tw-text-center tw-font-black">
              {{ 'signUp.verifyYourIdentity' | translate }}
            </h2>
            <div class="tw-py-2 tw-text-black">
              <p
                class="tw-text-md tw-mb-0 tw-text-center"
                [innerHTML]="'signUp.whyKYC' | translate"
              ></p>
              <p class="tw-text-md tw-mb-0 tw-text-center">
                {{ 'signUp.whatYouNeed' | translate }}
              </p>
            </div>
            <ul class="tw-list-none tw-py-8 tw-text-black">
              <li class="tw-flex tw-items-start">
                <img
                  src="/assets/img/icons/common/check_icon_green@2x.png"
                  alt="bullet"
                  class="tw-mr-4 tw-w-4 tw-pt-1"
                />
                <span [innerHTML]="'signUp.documentsNeeded' | translate"></span>
              </li>
              <li class="tw-flex tw-items-start">
                <img
                  src="/assets/img/icons/common/check_icon_green@2x.png"
                  alt="bullet"
                  class="tw-mr-4 tw-w-4 tw-pt-1"
                />
                <span [innerHTML]="'signUp.timeNeeded' | translate"></span>
              </li>
            </ul>
            <div class="mb-4 d-flex align-items-center tw-w-4/5 tw-pb-6 tw-text-sm">
              <mat-checkbox [(ngModel)]="isAccept" name="isAccept" required> </mat-checkbox>
              <div [innerHTML]="'signUp.declarationKYC' | translate"></div>
            </div>
            <div class="tw-w-1/2">
              <button
                type="submit"
                class="btn btn-green mb-4 tw-w-full"
                [disabled]="ekycStatus == EkycStatus.Verified"
              >
                {{ 'signUp.verifyMe' | translate }}
              </button>
            </div>
            <div (click)="skip()" class="mb-4 tw-cursor-pointer tw-text-sm tw-underline">
              {{ 'signUp.verifyLater' | translate }}
            </div>
          </form>
        </div>
        <div class="col-lg-8 tw-mx-auto" *ngIf="isProcess">
          <h2 class="text-center font-weight-extrabold">{{ 'signUp.eKYC' | translate }}</h2>
          <div class="text-center proceed-ekyc mb-5">{{ 'signUp.eKYCProcessing' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
  <agmo-loading *ngIf="isLoading"></agmo-loading>
</div>
