import { IdentificationType } from '../../enum/identification-type.enum';
import { SubRepresentative } from './sub-representative';

export class SubRepresentativeModel {
  UserIdentityNoType: IdentificationType;
  FullName: string;
  IdentityNo: string;
  Email: string;
  Id: string;

  constructor(model: SubRepresentative) {
    this.UserIdentityNoType = model.userIdentityNoType;
    this.FullName = model.fullName;
    this.IdentityNo = model.identityNo ? model.identityNo.replace(/-/g, '') : null;
    this.Email = model.email;
    this.Id = model.id;
  }
}
