<div class="container-fluid py-5">
  <p class="mb-4">{{ 'login.dearName' | translate: { name: name } }}</p>
  <p>{{ 'login.forceUpdateMsg1' | translate }}</p>
  <p>{{ 'login.forceUpdateMsg2' | translate }}</p>
  <p>{{ 'login.forceUpdateMsg3' | translate }}</p>
  <p [innerHTML]="'login.forceUpdateMsg4' | translate"></p>
  <p>{{ 'login.forceUpdateMsg5' | translate }}</p>
  <p class="mb-0 mt-4">{{ 'login.bestRegards' | translate }}</p>
  <p>{{ 'login.teamPitchIN' | translate }}</p>
  <div class="mt-4 text-center">
    <div class="btn btn-primary" (click)="close()">{{ 'common.ok' | translate }}</div>
  </div>
</div>
