import { Component, DEFAULT_CURRENCY_CODE, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ReceiptType } from '../../../../../core/enum/receipt-type.enum';
import { ContainerType } from '../../../../../core/enum/container-type.enum';
import { EkycStatus } from '../../../../../core/enum/ekyc-status.enum';
import { PaymentType } from '../../../../../core/enum/payment-type.enum';
import { AttachmentInfo } from '../../../../../core/models/attachment/attachment-info';
import { Campaign } from '../../../../../core/models/campaign/campaign';
import { InvestmentModel } from '../../../../../core/models/investment/investment.model';
import { Payment } from '../../../../../core/models/payment/payment';
import { AccountService } from '../../../../../core/services/api/account.service';
import { CampaignService } from '../../../../../core/services/api/campaign.service';
import { WalletService } from '../../../../../core/services/api/mysec/wallet.service';
import { ErrorService } from '../../../../../core/services/error.service';
import { BlockUiService } from '../../../../../shared/services/blockUi/block-ui.service';
import { PopupService } from '../../../../../shared/services/popup/popup.service';
import { GtmDataLayerService } from '../../../../../core/services/gtm-data-layer.service';
import { InvestmentInfo } from '../../../../../core/models/investment/investment-info';

@Component({
  selector: 'app-choose-payment',
  templateUrl: './choose-payment.component.html',
  styleUrls: ['./choose-payment.component.scss'],
})
export class ChoosePaymentComponent implements OnInit {
  slug: string;
  investmentId: number;
  tickerCode: string;
  campaign: Campaign;
  investment: InvestmentModel;
  investmentInfo: InvestmentInfo;
  amount: number;
  refNo: string;
  continueLink: string;
  step: number = 1;

  isLoading: boolean = false;
  isLoadingInvestmentInfo: boolean = false;
  isPSTX: boolean = false;
  isDuitNowEnabled: boolean = false;

  receiptType: ReceiptType;
  attachmentInfo: AttachmentInfo;
  document: File;

  selectedPaymentType: PaymentType = PaymentType.MaybankFPX;

  ReceiptType: typeof ReceiptType = ReceiptType;
  PaymentType: typeof PaymentType = PaymentType;

  constructor(
    private activatedRoute: ActivatedRoute,
    private popupService: PopupService,
    private campaignService: CampaignService,
    private errorService: ErrorService,
    private router: Router,
    private accountService: AccountService,
    private blockUiService: BlockUiService,
    private walletService: WalletService,
    private _gtmDataLayerService: GtmDataLayerService,
    @Inject(DEFAULT_CURRENCY_CODE) private defaultCurrencyCode: string,
  ) {}

  ngOnInit() {
    this.slug = this.activatedRoute.snapshot.params.slug;
    this.investmentId = this.activatedRoute.snapshot.params.investmentId;
    this.amount = this.activatedRoute.snapshot.params.amount;
    this.tickerCode = this.activatedRoute.snapshot.params.tickerCode;

    if (this.slug && this.investmentId) {
      this.receiptType = ReceiptType.Investment;
      this.continueLink = '/dashboard/pledges';
      this.getCampaign();
      this.isPSTX = false;
    }

    if (this.amount) {
      this.receiptType = ReceiptType.Wallet;
      this.continueLink = '/pstx/account';
      this.isDuitNowEnabled = true;
      this.isPSTX = true;
    }
  }

  logBeginCheckout() {
    if (this.step === 1) {
      this._gtmDataLayerService.logBeginCheckout({
        currency: this.defaultCurrencyCode.trim(),
        value: this.investment.pledgedAmount,
        items: [
          {
            item_name: this.campaign.company?.brandName,
            item_id: this.campaign.id,
            item_category: 'ecf',
            price: this.investmentInfo.pricePerShare * this.investmentInfo.sharePerLot,
            quantity: Number(this.investment.lotQuantity),
          },
        ],
      });
    }
  }

  getCampaign() {
    this.isLoadingInvestmentInfo = true;

    this.campaignService.getCampaign(this.slug).subscribe({
      next: (data) => {
        this.campaign = data;
        this.getInvestment();
      },
      error: (err) => {
        this.errorService.showError(err);
      },
    });
  }

  getInvestment() {
    this.campaignService
      .getInvestment(this.campaign.id, this.investmentId)
      .pipe(finalize(() => (this.isLoadingInvestmentInfo = false)))
      .subscribe({
        next: (data) => {
          this.investment = data;
          this.amount = this.investment.pledgedAmount;
          this.refNo = this.investment.referenceNumber;
          this.isDuitNowEnabled = this.investment.paymentType === PaymentType.DuitNow;
          this.getCampaignInvestmentInfo();
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }

  getCampaignInvestmentInfo() {
    this.campaignService.getCampaignInvestmentInfo(this.campaign.id).subscribe({
      next: (data) => {
        this.investmentInfo = data;
        this.logBeginCheckout();
      },
      error: (err) => {
        this.errorService.showError(err);
      },
    });
  }

  setPayment(paymentModel: Payment) {
    const manualPaymentTypes = [PaymentType.BankIn, PaymentType.Swift, PaymentType.Cheque];

    if (manualPaymentTypes.includes(paymentModel.paymentType) && !this.attachmentInfo) {
      this.popupService.alert('common.pleaseUploadReceipt');
      return;
    }

    this.blockUiService.open();
    paymentModel.attachmentInfo = this.attachmentInfo;

    if (this.receiptType == ReceiptType.Wallet && this.tickerCode)
      paymentModel.tickerCode = this.tickerCode;

    let observable =
      this.receiptType == ReceiptType.Investment
        ? this.campaignService.setInvestmentPayment(
            this.campaign.id,
            this.investmentId,
            paymentModel,
          )
        : this.walletService.depositWallet(this.amount, paymentModel);

    observable.pipe(finalize(() => this.blockUiService.close())).subscribe(
      (data) => {
        if (data && data.fpxPaymentGatewayUrl) window.location.href = data.fpxPaymentGatewayUrl;
        else this.getProfile();
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  private getProfile(): void {
    this.accountService.profile(true).subscribe(
      (profile) => {
        if ([EkycStatus.Verified, EkycStatus.NotRequired].includes(profile.ekycStatus)) {
          this.popupService
            .success('campaign.investmentWillBeConfirmed', {
              titleLabel: '',
              okLabel:
                this.receiptType == ReceiptType.Investment
                  ? 'campaign.goToMyInvestment'
                  : 'mysec.viewBalance',
              cancelLabel: '',
              showTitle: this.receiptType == ReceiptType.Investment ? false : true,
            })
            .then(() => {
              this.router.navigate([this.continueLink]);
            });
          return;
        } else {
          this.popupService.alert('investment.userMustCompleteEkyc').then(() => {
            this.router.navigate(['/proceed-e-kyc']);
          });
        }
      },
      (err) => this.errorService.showError(err),
    );
  }

  nextStep(response: { isNext: boolean; paymentType: PaymentType }) {
    if (response.isNext) {
      this.step = 2;
      this.selectedPaymentType = response.paymentType;
    }
  }

  uploadReceipt(file: File) {
    this.isLoading = true;

    this.accountService
      .uploadAttachmentPresignedURL(file, ContainerType.InvestmentReceipt)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.attachmentInfo = new AttachmentInfo(data);
          this.document = file;
        },
        (err) => {
          this.errorService.showError(err);
          this.attachmentInfo = null;
          this.document = null;
        },
      );
  }
}
