import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent {
  @Input() title: string;
  @Input() message: string = 'common.noRecord';
  @Input() placement: string = 'campaign';
  @Input() isRequireLogin: boolean = false;
  @Input() loginRedirectionQueryString: string;

  @Input() ctaUrl: string;
  @Input() ctaText: string;

  constructor(private router: Router) {}

  handleLoginRedirection(event: MouseEvent) {
    const currentUrl = this.router.url;
    const queryString = this.loginRedirectionQueryString
      ? `?${this.loginRedirectionQueryString}`
      : '';

    event.preventDefault();

    this.router
      .navigate(['/login'], {
        queryParams: {
          returnUrl: `${currentUrl}${queryString}`,
        },
      })
      .catch((err) => {
        console.error('fail to navigate to login: ', err);
      });
  }
}
