import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PartnershipService {
  private readonly route = environment.apiServer + 'Api/v1/Partnership';

  constructor(public http: HttpClient) {}

  getPartnerPage(): Observable<any> {
    const params = { 'noToken': 'noToken' };
    return this.http.get(this.route + '/partners', { params }).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }
}
