<div class="bg-hero tw-mb-8 tw-overflow-hidden tw-bg-hero-gradient tw-from-ecf-light tw-to-white">
  <div class="container-fluid bg-img tw-relative">
    <div class="tw-py-10">
      <div class="sm:tw-max-w-[70%] md:tw-max-w-lg lg:tw-max-w-2xl">
        <product-badge></product-badge>

        <h1 class="p-text-h2" [innerHTML]="'campaign.LiveCampaignsTitle' | translate"></h1>

        <p class="p-text-subtitle p-text-subtitle--sm p-text-subtitle--light tw-mb-0">
          {{ 'campaign.LiveCampaignsDescription' | translate }}
        </p>
      </div>
      <div class="tw-mt-10 tw-flex">
        <a class="btn btn-primary !tw-mr-5 tw-inline-block" routerLink="/equity/invest">
          {{ 'whyInvest.whyInvest' | translate }}
        </a>
        <ul class="hero-nav">
          <!-- Hide the learn portal for now -->
          <!-- <li>
                        <a routerLink="/learn/invest/how-it-works">
                            {{ "raise.howItWorks" | translate }}
                        </a>
                    </li> -->
          <li>
            <a
              href="https://support.pitchin.my/hc/en-us/categories/12971286982557-Investor-FAQs"
              target="_blank"
            >
              {{ 'footer.faqs' | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <app-campaign-filters
    class="tw-mb-1 tw-inline-block tw-w-full"
    [params]="params"
    (paramsChange)="updateUrl($event)"
  ></app-campaign-filters>

  <div class="tw-mb-5">
    <ul class="p-tab">
      <li class="p-tab__item" [class.active]="selectedStatusTab == null">
        <a class="p-tab__link" (click)="filterByStatus()" data-t="filterClick">{{
          'equity.AllOpportunities' | translate
        }}</a>
      </li>
      <li
        class="p-tab__item"
        *ngFor="let tab of campaignStatusTabs"
        [class.active]="selectedStatusTab == tab.key"
      >
        <a class="p-tab__link" (click)="filterByStatus(tab.key)" data-t="filterClick">{{
          tab.value | translate
        }}</a>
      </li>
    </ul>
  </div>

  <div class="tw-mb-5">
    <app-campaign-list
      [campaigns]="campaigns"
      [params]="params"
      [total]="total"
      [isLoading]="isLoading"
      (getCampaignList)="updateUrl($event)"
    ></app-campaign-list>
  </div>
</div>
