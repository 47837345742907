import { Component } from '@angular/core';
import { TwoFactorservice } from '../../../../core/services/auth/two-factor.service';
import { Router } from '@angular/router';
import { ToastService } from '../../../../core/services/toast.service';
import { AccountService } from '../../../../core/services/api/account.service';

@Component({
  selector: 'app-remove-two-factor-auth',
  templateUrl: './remove-two-factor-auth.component.html',
})
export class RemoveTwoFactorAuthComponent {
  constructor(
    private readonly twoFactorService: TwoFactorservice,
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly accountService: AccountService,
  ) {}

  handleRemove(value: string) {
    this.twoFactorService.removeTwoFactor(value).subscribe({
      next: (value) => {
        this.accountService.profile(true).subscribe();
        this.router.navigate(['/profile/settings/security']);
      },
      error: (err) => {
        this.toastService.error('Failed to validate code');
      },
    });
  }
}
