import { Component, Input } from '@angular/core';
import { BuyerSellerContent } from 'src/app/core/models/mysec/buy-sell-content';
import { BuySellTutorialComponent } from '../buy-sell-tutorial.component';
import { ViewChild } from '@angular/core';
import { InnerTabComponent } from './inner-tab/inner-tab.component';

@Component({
  selector: 'app-buy-sell-tutorial-content',
  templateUrl: './buy-sell-tutorial-content.component.html',
  styleUrls: ['./buy-sell-tutorial-content.component.css'],
})
export class BuySellTutorialContentComponent {
  selectedIndex: number = 0;

  @Input() type: 'buyer' | 'seller' = 'buyer';
  @Input() content: BuyerSellerContent;
  @Input() parentTutorialContent: string;
  @ViewChild(InnerTabComponent, { static: false }) innerTabComponent: InnerTabComponent;

  setIndex(index: number) {
    this.selectedIndex = index;
    this.innerTabComponent.selectedIndex = 0;
  }

  constructor(private parentComponent: BuySellTutorialComponent) {}

  resetContent() {
    this.parentComponent.resetContent();
    this.handleMenuClick();
  }

  handleMenuClick() {
    const section = document.getElementById('buy-sell-tutorial');

    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
