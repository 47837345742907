import { Countries } from './../../../core/enum/countries.enum';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FundRaiseRole } from '../../../core/enum/fund-raise-role.enum';

@Component({
  selector: 'app-shareholders-and-directors',
  templateUrl: './shareholders-and-directors.component.html',
  styleUrls: ['./shareholders-and-directors.component.scss'],
})
export class ShareholdersAndDirectorsComponent implements OnInit {
  @Output() submitForm = new EventEmitter();

  showFormError: boolean = false;
  form: UntypedFormGroup;

  Countries: typeof Countries = Countries;
  FundRaiseRole: typeof FundRaiseRole = FundRaiseRole;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      directors: this.formBuilder.array([this.buildDirectorForm()]),
      shareholders: this.formBuilder.array([this.buildShareholderForm()]),
    });
  }

  buildDirectorForm() {
    return this.formBuilder.group({
      fullName: ['', Validators.required],
      country: ['', Validators.required],
      identityNo: ['', Validators.required],
    });
  }

  buildShareholderForm() {
    return this.formBuilder.group({
      fullName: ['', Validators.required],
      country: ['', Validators.required],
      identityNo: ['', Validators.required],
      shareholdings: [null, Validators.required],
    });
  }

  get directorFormControl() {
    return <UntypedFormArray>this.form.controls.directors;
  }

  get shareholderFormControl() {
    return <UntypedFormArray>this.form.controls.shareholders;
  }

  add(type: FundRaiseRole) {
    if (this.directorFormControl.invalid || this.shareholderFormControl.invalid) {
      this.showFormError = true;
      return;
    }

    if (type == FundRaiseRole.Director) this.directorFormControl.push(this.buildDirectorForm());
    else this.shareholderFormControl.push(this.buildShareholderForm());

    this.showFormError = false;
  }

  remove(index: number, type: FundRaiseRole) {
    if (type == FundRaiseRole.Director && this.directorFormControl.controls.length > 1)
      this.directorFormControl.removeAt(index);

    if (type == FundRaiseRole.Shareholder && this.shareholderFormControl.controls.length > 1)
      this.shareholderFormControl.removeAt(index);
  }

  submit() {
    if (this.form.invalid) {
      this.showFormError = true;
      return;
    }

    this.submitForm.emit(this.form.value);
  }
}
