import { Term } from './term';

export class TermModel {
  Id: number;
  Title: string;
  Description: string;

  constructor(model: Term) {
    this.Id = model.id;
    this.Title = model.title;
    this.Description = model.description;
  }
}
