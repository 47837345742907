import { environment } from '../../../environments/environment';
import { ContainerType } from '../enum/container-type.enum';
import { FileTypeMime } from '../models/attachment/file-type-mime';
import { FileUploadOption } from '../models/attachment/file-upload-option';
import { FileExtension, FileNameLimit, FileSizeLimit, FileType, ImageFileFormat } from './constant';

export class UploadAdapter {
  loader;
  xhr;

  invalidFileError = 'Invalid File Format';
  fileNameError =
    'Sorry, filename is limited to 50 characters. Filename must not contain / ? . < > \\ : * | and ". Please contact us if you feel this is an error';
  fileSizeError =
    'Sorry, file must be less than 30MB. Please contact us if you feel this is an error.';

  constructor(loader) {
    this.loader = loader;
  }

  async upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this.validatefile(resolve, reject, file);
        }),
    );
  }

  async validatefile(resolve, reject, file) {
    const uploadOption: FileUploadOption = {
      fileType: ImageFileFormat,
      errorMsg:
        'Sorry, file must either be JPG, JPEG or PNG. Please contact us if you feel this is an error.',
    };

    if (await this.validFile(file, uploadOption)) {
      this._initRequest();
      this._initListeners(resolve, reject, file);
      this._sendRequest(resolve, reject, file);
    } else {
      resolve({
        default: false,
      });
    }
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    let role = localStorage.getItem('role') || null;
    let userId = localStorage.getItem('userId') || '';
    let route = environment.apiServer + 'Api/v1/Users';

    xhr.open('POST', route + '/' + userId + '/' + role + '/attachment', true);
    xhr.responseType = 'json';
    xhr.withCredentials = true;
  }

  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener('error', () => {
      reject(genericErrorText);
    });
    xhr.addEventListener('abort', () => {
      reject();
    });
    xhr.addEventListener('load', () => {
      if (!xhr.response) return;

      const response = xhr.response.result;
      if (!response || response.error) {
        return reject(response && response.error ? response.error.message : genericErrorText);
      }

      const xhr2 = new XMLHttpRequest();

      xhr2.open('POST', response.writeUrl, true);
      xhr2.setRequestHeader('x-ms-blob-type', 'BlockBlob');
      xhr2.setRequestHeader('x-ms-blob-content-type', response.fileType);
      xhr2.responseType = 'json';

      const formData = new FormData();
      formData.append('file', file);
      xhr2.send(formData);

      xhr2.addEventListener('load', () => {
        resolve({
          default: xhr2.response.result.url,
        });
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  _sendRequest(resolve, reject, file) {
    const data = new FormData();
    data.append('filename', file.name);
    data.append('containerType', ContainerType.RichTextHtml.toString());
    this.xhr.onreadystatechange = () => {
      if (this.xhr.readyState == XMLHttpRequest.DONE && this.xhr.status == 401) {
        this.refreshToken(resolve, reject, file);
      }
    };
    this.xhr.send(data);
  }

  // CUSTOM VALIDATOR FOR EDITOR FILE UPLOAD
  async validFile(file: File, option: FileUploadOption) {
    const validFileType = this.validFileType(file, option);
    if (!validFileType) {
      return;
    }

    const validSign = await this.validSignature(file, option);
    return validFileType && validSign;
  }

  validFileType(file: File, option: FileUploadOption): boolean {
    const fileType = this.getFileType(file);
    if (!fileType) {
      alert(option.errorMsg || this.invalidFileError);
      return false;
    }
    const sanitize = require('sanitize-filename');
    if (sanitize(file.name) !== file.name) {
      alert(this.fileNameError);
      return false;
    }
    let dots = [];
    for (var i = 0; i < file.name.length; i++) {
      if (file.name[i] == '.') {
        dots.push(i);
      }
    }
    if (dots.length > 1) {
      alert(this.fileNameError);
      return false;
    }
    if (file.name.length > (option.fileNameLimit || FileNameLimit)) {
      alert(this.fileNameError);
      return false;
    }
    if (file.size > (option.fileSizeLimit || FileSizeLimit)) {
      alert(this.fileSizeError);
      return false;
    }
    if (!option.fileType.includes(file.type)) {
      alert(option.errorMsg || this.invalidFileError);
      return false;
    }
    if (!fileType.ext.includes(FileExtension.exec(file.name)[1].toLowerCase())) {
      alert(option.errorMsg || this.invalidFileError);
      return false;
    }

    return true;
  }

  validSignature(file: File, option: FileUploadOption) {
    return new Promise((resolve, reject) => {
      const fileType = this.getFileType(file);

      if (!fileType) {
        alert(option.errorMsg || this.invalidFileError);
        resolve(false);
        return;
      }

      if (fileType.mime == 'text/csv') {
        resolve(true);
        return;
      }

      var fileReader = new FileReader();
      fileReader.onloadend = (e: any) => {
        var arr = new Uint8Array(e.target.result as ArrayBuffer).subarray(
          fileType.rules.start,
          fileType.rules.end,
        );
        var header = '';

        for (var i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }

        if (!fileType.sign.includes(header)) {
          alert(option.errorMsg || this.invalidFileError);
        }
        resolve(fileType.sign.includes(header));
      };
      fileReader.readAsArrayBuffer(file);
    });
  }

  getFileType(file: File): FileTypeMime {
    return FileType.find(function (data) {
      return data.mime == file.type;
    });
  }

  refreshToken(resolve, reject, file) {
    var xhr = new XMLHttpRequest();

    let route = environment.apiServer + 'Api/v1/Users';
    xhr.open('POST', route + '/' + 'refreshToken', true);
    xhr.withCredentials = true;
    xhr.onreadystatechange = () => {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        const response = JSON.parse(xhr.response);

        if (xhr.status == 200) {
          localStorage.setItem('tokenExpiry', response.result.accessTokenExpiredAt);
          this.abort();
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(resolve, reject, file);
        } else if (xhr.status == 401) {
          window.location.replace(window.location.origin);
        }
      }
    };

    xhr.send();
  }
}
