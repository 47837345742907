<button
  class="pitchin-icon pitchin-icon-close tw-absolute -tw-right-2 -tw-top-2 tw-rounded-full"
  (click)="close()"
></button>
<div class="modal-header">
  <div class="modal-title">{{ 'portfolio.shareOwnershipSummary' | translate }}</div>
</div>
<div class="modal-body pt-0">
  <ng-container *ngIf="portfolioListModel">
    <div class="m-0 mb-4 company-name-panel">
      <div class="tw-mb-8">
        <app-shares-pop-up
          [imageUrl]="portfolioListModel.company.companyLogoUrl"
          [companyName]="'Business' | translate"
          [companyDescription]="portfolioListModel?.company?.brandName || '-'"
          [counterName]="'Counter' | translate"
          [counterDescription]="portfolioListModel?.tickerCode?.toUpperCase() || '-'"
          [nameClass]="'!tw-text-body !tw-font-normal'"
          [descriptionClass]="'!tw-font-bold !tw-text-black'"
          [isMoratorium]="portfolioListModel?.isMoratorium"
        >
        </app-shares-pop-up>
      </div>

      <!-- Table -->
      <div class="tw-grid tw-grid-cols-3 tw-font-bold tw-text-black">
        <div class="tw-col-span-2">Share Quantity</div>
        <div class="tw-text-right tw-tabular-nums">
          {{ portfolioListModel.portfolio.shareQuantity | number }}
        </div>
      </div>

      <hr class="tw-my-2.5" />

      <ng-container *ngIf="portfolioListModel?.isMoratorium === true">
        <div class="tw-mb-1.5 tw-font-medium tw-text-black">
          <div *ngIf="portfolioListModel?.moratoriumExpiryAt; else founderText">
            <div
              [innerHTML]="
                'portfolio.underMoratoriumUntil'
                  | translate
                    : { date: (portfolioListModel?.moratoriumExpiryAt | date: 'd MMMM yyyy') }
              "
            ></div>
          </div>
          <ng-template #founderText>
            {{ 'raise.founderUnderMoratorium' | translate }}
          </ng-template>
        </div>
      </ng-container>

      <div class="tw-grid tw-grid-cols-3">
        <div class="tw-col-span-2">{{ 'portfolio.directHeld' | translate }}</div>
        <div class="tw-text-right tw-tabular-nums">{{ directShare | number }}</div>
      </div>

      <div class="tw-grid tw-grid-cols-3">
        <div class="tw-col-span-2">{{ 'portfolio.nomineeHeld' | translate }}</div>
        <div class="tw-text-right tw-tabular-nums">{{ nomineeShare | number }}</div>
      </div>

      <div
        *ngIf="untradableShare > 0"
        class="tw-mt-5 tw-grid tw-grid-cols-3 tw-font-medium tw-text-black"
      >
        <div class="tw-col-span-2">
          {{ 'portfolio.untradable' | translate }}

          <button
            type="button"
            class="btn-transparent tw-align-middle"
            [ngbPopover]="untradableTooltip"
            container=".tooltip-container"
            triggers="mouseenter click"
          >
            <div
              class="pitchin-icon pitchin-icon-help-circle !tw-h-6 !tw-w-6 tw-opacity-50 hover:tw-opacity-80"
            ></div>
          </button>
          <ng-template #untradableTooltip>
            <div class="tooltip-container tw-max-w-[200px] md:tw-max-w-[250px]">
              {{ 'portfolio.untradableTooltip' | translate }}
              <a
                href="https://support.pitchin.my/hc/en-us/articles/14082663278749"
                target="_blank"
                >{{ 'common.learnMore' | translate }}</a
              >
            </div>
          </ng-template>
        </div>
        <div class="tw-text-right tw-tabular-nums">{{ untradableShare }}</div>
      </div>
    </div>
  </ng-container>
  <agmo-loading *ngIf="!portfolioListModel"></agmo-loading>
</div>
