<div class="container">
  <div class="row justify-content-center my-5">
    <div class="col-lg-6 text-center">
      <ng-container *ngIf="!isLoading">
        <img
          class="email-img"
          alt="Email"
          src="../../../../assets/img/icons/common/email_open_icon@2x.png"
        />
        <h2 class="font-weight-extrabold mb-4 mt-5">{{ 'signUp.emailExpired' | translate }}</h2>
        <div>{{ 'signUp.clickLinkOnEmailResend' | translate: { email: email } }}</div>
        <div class="text-primary font-weight-bold mt-4 cursor-pointer" (click)="resendEmail()">
          {{ 'signUp.resendEmail' | translate }}
        </div>
      </ng-container>
      <agmo-loading *ngIf="isLoading"></agmo-loading>
    </div>
  </div>
</div>
