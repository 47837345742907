<div class="tw-mb-5 tw-text-xl tw-font-bold tw-text-black">
  {{ 'raise.shareholderInformation' | translate }}
</div>

<div
  class="tw-mb-5 tw-flex tw-items-center tw-gap-2.5 tw-bg-gray-100 tw-bg-opacity-50 tw-p-2 tw-px-4 max-sm:tw-flex-wrap"
>
  <div class="tw-flex tw-items-center">
    <div class="dropdown" ngbDropdown>
      <div
        class="d-flex align-items-center cursor-pointer tw-select-none"
        id="ownershipType"
        aria-controls="menu"
        ngbDropdownToggle
      >
        <span class="pr-1 tw-font-semibold">{{ 'Ownership type' | translate }}:</span>
        {{ 'portfolio.' + ShareOwnership[params.ownership] | translate }}
        <i class="fa fa-caret-down tw-ml-2 tw-opacity-50"></i>
      </div>
      <div class="dropdown-menu" ngbDropdownMenu role="menu" aria-labelledby="ownershipType">
        <div
          role="menuitem"
          *ngFor="let type of ShareOwnership | enumToArray"
          class="dropdown-item font-weight-bold font-size-regular cursor-pointer"
          (click)="handleFilter('ownership', type.key)"
        >
          {{ 'portfolio.' + type.value | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="tw-mx-2.5 tw-h-8 tw-w-px tw-bg-divider"></div>

  <div class="tw-flex tw-items-center">
    <div class="dropdown" ngbDropdown>
      <div
        class="d-flex align-items-center cursor-pointer tw-select-none"
        id="ownershipType"
        aria-controls="menu"
        ngbDropdownToggle
      >
        <span class="pr-1 tw-font-semibold">{{ 'Counter name' | translate }}:</span>
        {{ selectedTickerCode }}
        <i class="fa fa-caret-down tw-ml-2 tw-opacity-50"></i>
      </div>
      <div
        class="dropdown-menu tw-max-h-[300px] tw-overflow-auto"
        ngbDropdownMenu
        role="menu"
        aria-labelledby="ownershipType"
      >
        <div
          role="menuitem"
          *ngFor="let type of tickerCodeList"
          class="dropdown-item font-weight-bold font-size-regular cursor-pointer"
          (click)="handleFilterTickerCode(type.value)"
        >
          {{ type.value | translate }}
        </div>
      </div>
    </div>
  </div>
</div>

<div [class.is-loading]="isLoading">
  <div class="tw-mb-5 tw-max-w-2xl">
    <div class="tw-grid tw-gap-5 sm:tw-grid-cols-2">
      <div class="tw-rounded tw-border tw-border-divider tw-p-5">
        <div class="p-skeleton-bar tw-mb-2.5 !tw-block tw-text-lg">
          {{ 'offerCreation.numberOfShareholders' | translate }}
        </div>
        <div class="p-skeleton-bar tw-text-2xl tw-font-medium tw-text-black">
          {{ totalShareholders | number }}
        </div>
      </div>

      <div class="tw-rounded tw-border tw-border-divider tw-p-5">
        <div class="p-skeleton-bar tw-mb-2.5 !tw-block tw-text-lg">
          {{ 'offerCreation.totalShareIssue' | translate }}
        </div>
        <div class="p-skeleton-bar tw-text-2xl tw-font-medium tw-text-black">
          {{ totalShares | number }}
        </div>
      </div>
    </div>
  </div>

  <div class="p-table__wrapper">
    <div class="p-table__body">
      <mat-table
        class="responsive-table p-table max-lg:tw-w-[800px]"
        [dataSource]="shareholderList"
      >
        <ng-container matColumnDef="Name">
          <mat-header-cell *matHeaderCellDef>
            {{ 'profile.Shareholder' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let item" class="tw-flex-col tw-items-start">
            <div class="p-skeleton-bar tw-line-clamp-1 tw-font-semibold">
              {{ item.shareHolderName }}
            </div>
            <div class="p-skeleton-hidden">{{ item.shareHolderEmail }}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ShareOwnership">
          <mat-header-cell *matHeaderCellDef class="tw-flex">
            <div class="tw-mr-3 tw-flex-[0_0_150px]">
              {{ 'portfolio.dateOfFirstOwnership' | translate }}
            </div>
            <div class="tw-flex-auto">{{ 'portfolio.shareOwnership' | translate }}</div>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            class="tw-flex tw-flex-col tw-divide-y tw-divide-divider/80"
          >
            <div
              *ngFor="let share of item?.shares"
              class="tw-flex tw-w-full tw-py-2.5 first:tw-pt-0 last:tw-pb-0"
            >
              <div class="tw-mr-3 tw-flex-[0_0_150px]">
                <span class="p-skeleton-bar">
                  {{ share.dateOfOwnership | amDateFormat: dateFormat }}
                </span>
              </div>
              <div class="tw-flex tw-flex-auto tw-flex-col tw-gap-1.5">
                <div *ngIf="share?.directShareQuantity > 0">
                  <span class="p-skeleton-bar tw-font-bold tw-uppercase" *ngIf="share?.tickerCode"
                    >{{ share?.tickerCode }}:
                  </span>
                  <div class="p-skeleton-hidden">
                    {{ share?.directShareQuantity | number }}
                    {{ 'raise.share_plural' | translate: { count: share?.directShareQuantity } }}
                    (Direct)
                  </div>
                </div>

                <div *ngIf="share?.nomineeShareQuantity > 0">
                  <span class="p-skeleton-bar tw-font-bold tw-uppercase" *ngIf="share?.tickerCode"
                    >{{ share?.tickerCode }}:
                  </span>
                  <div class="p-skeleton-hidden">
                    {{ share?.nomineeShareQuantity | number }}
                    {{
                      'raise.share_plural' | translate: { count: isharetem?.nomineeShareQuantity }
                    }}
                    (Nominee)
                  </div>
                </div>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="SharePercentage">
          <mat-header-cell *matHeaderCellDef>
            {{ 'portfolio.sharePercentage' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let item" class="tw-items-start">
            <div class="p-skeleton-bar">{{ item.sharePercentage | number: '1.2-2' }}%</div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayColumns"> </mat-header-row>

        <mat-row *matRowDef="let item; columns: displayColumns"> </mat-row>
      </mat-table>

      <div
        class="tw-w-full tw-py-4 tw-text-center"
        *ngIf="!isLoading && (!shareholderList || shareholderList.length === 0)"
      >
        {{ 'common.noRecord' | translate }}
      </div>

      <div class="p-table__footer" *ngIf="total > params.take">
        <div class="p-table__pagination p-skeleton-pagination">
          <ngb-pagination
            [collectionSize]="total"
            [(pageSize)]="params.take"
            [(page)]="params.currentPage"
            (pageChange)="handlePageChange()"
            [maxSize]="5"
            [rotate]="true"
          >
            <ng-template ngbPaginationPrevious>
              <span class="p-table__pagination-icon">←</span>
              <div class="p-table__pagination-label tw-pl-2">{{ 'common.prev' | translate }}</div>
            </ng-template>
            <ng-template class="page-no" ngbPaginationNumber let-page>
              <div class="page-no">{{ page }}</div>
            </ng-template>
            <ng-template ngbPaginationNext>
              <div class="p-table__pagination-label tw-pr-2">{{ 'common.next' | translate }}</div>
              <span class="p-table__pagination-icon">→</span>
            </ng-template>
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
