import { Role } from './../../core/enum/role.enum';
import { StorageService } from './../../core/services/storage.service';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth/auth.service';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  constructor(
    private storageService: StorageService,
    private router: Router,
    private authService: AuthService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let params = new HttpParams({ fromString: req.params.toString() });
    let headers = req.headers;
    let withCredentials: boolean = undefined;
    let url = req.url;

    if (
      (url.indexOf(environment.apiServer) != -1 || url.indexOf(environment.ieoApiServer) != -1) &&
      !params.get('noCredential')
    ) {
      withCredentials = true;
    }

    if (
      (url.indexOf(environment.apiServer) != -1 || url.indexOf(environment.ieoApiServer) != -1) &&
      !params.get('noToken') &&
      !this.authService.isAuthenticated()
    )
      return EMPTY;

    if (params.get('noCredential')) {
      params = params.delete('noCredential');
    }

    if (url.indexOf('{role}') != -1) {
      let role = Role.Public;

      if (
        this.storageService.role &&
        [Role.IndividualInvestor, Role.CompanyRep, Role.IssuerRep].includes(
          this.storageService.role,
        )
      )
        role = this.storageService.role;

      url = url.replace(/{role}/, Role[role]);
    }

    if (url.indexOf('{userId}') != -1) {
      if (!this.storageService.userId) this.throwErrorFunction();

      url = url.replace(/{userId}/, this.storageService.userId);
    }

    req = req.clone({
      url: url,
      params: params,
      headers: headers,
      withCredentials: withCredentials,
    });

    return next.handle(req);
  }

  throwErrorFunction() {
    this.router.navigate(['login']);
    return throwError({
      error: {
        message: 'common.sessionExpired',
      },
    });
  }
}
