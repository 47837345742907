import { ErrorService } from '../../../../core/services/error.service';
import { Attachment } from './../../../../core/models/attachment/attachment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { InvestmentService } from '../../../../core/services/api/investment.service';
import { MyInvestment } from '../../../../core/models/investment/my-investment';
import { BusinessesService } from '../../../../core/services/api/mysec/businesses.service';

@Component({
  selector: 'app-documents-reports',
  templateUrl: './documents-reports.component.html',
  styleUrls: ['./documents-reports.component.scss'],
})
export class DocumentsReportsComponent implements OnInit {
  @Input() campaignId: number;
  @Input() isDocumentLoading: boolean = true;
  @Input() documents: Attachment[] = [];
  @Input() documentsTotal: number = 0;
  @Input() myInvestment: MyInvestment;
  @Output() loadMoreDocuments = new EventEmitter();
  @Input() tickerCode: string;
  @Input() mysecFinancialAndDocument = false;

  attachments: Attachment[] = [];
  total: number;
  params = {
    currentPage: 1,
    take: 12,
  };
  isLoading = false;

  constructor(
    private errorService: ErrorService,
    private investmentService: InvestmentService,
    private businessesService: BusinessesService,
  ) {}

  ngOnInit() {
    if (this.campaignId) {
      this.getInvestmentDocuments();
    }

    if (this.mysecFinancialAndDocument) {
      this.getDocuments();
    }

    if (this.tickerCode) {
      this.getMysecDocuments();
    }
  }

  getInvestmentDocuments() {
    // If never invested before, skip entirely
    if (!(this.myInvestment?.investments && this.myInvestment?.investments.length > 0)) return;

    this.isLoading = true;

    this.investmentService
      .getInvestmentDocuments(this.campaignId, this.params.currentPage, this.params.take)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.attachments = this.attachments.concat(data.data);
          this.total = data.total;
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }

  getMysecDocuments() {
    this.isLoading = true;

    this.businessesService
      .getMysecDocuments(this.tickerCode, this.params.currentPage, this.params.take)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.attachments = this.attachments.concat(data.data);
          this.total = data.total;
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  loadMore() {
    this.params.currentPage++;
    this.getInvestmentDocuments();
  }

  handleLoadMoreDocuments() {
    this.loadMoreDocuments.emit();
  }

  private getDocuments(): void {
    this.businessesService
      .getDocuments(this.tickerCode)
      .pipe(finalize(() => (this.isDocumentLoading = false)))
      .subscribe({
        next: (data) => {
          this.documents = this.documents.concat(data.data);
          this.documentsTotal = data.total;
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }
}
