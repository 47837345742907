import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ErrorService } from '../../../core/services/error.service';
import { AccountService } from '../../../core/services/api/account.service';
import { BlockUiService } from '../../../shared/services/blockUi/block-ui.service';
import { EkycResult } from '../../../core/models/ekyc/ekyc-result';
import { DeviceType } from '../../../core/enum/device-type.enum';
import { isPlatformBrowser, Location } from '@angular/common';
import * as signalR from '@microsoft/signalr';
import { environment } from '../../../../environments/environment';
import { StorageService } from '../../../core/services/storage.service';
import { PopupService } from '../../../shared/services/popup/popup.service';
import { NgForm } from '@angular/forms';
import { ConsentType } from 'src/app/core/enum/concent-type.enum';
import { Role } from '../../../core/enum/role.enum';
import { EkycStatus } from '../../../core/enum/ekyc-status.enum';

@Component({
  selector: 'app-proceed-e-kyc',
  templateUrl: './proceed-e-kyc.component.html',
  styleUrls: ['./proceed-e-kyc.component.scss'],
})
export class ProceedEKycComponent implements OnInit, OnDestroy {
  ekycTransaction: EkycResult;
  win: Window;
  isProcess: boolean = false;
  isProfile: boolean = false;
  private hubConnection: signalR.HubConnection;
  showFormError: boolean = false;
  isAccept: boolean = false;
  role: Role;
  ekycStatus: EkycStatus;
  isLoading = false;

  Role: typeof Role = Role;
  EkycStatus: typeof EkycStatus = EkycStatus;

  @ViewChild('form', { read: NgForm }) form: NgForm;

  constructor(
    private router: Router,
    private blockUiService: BlockUiService,
    private accountService: AccountService,
    private errorService: ErrorService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private popupService: PopupService,
    @Inject(PLATFORM_ID) private platform: Object,
  ) {}

  ngOnInit() {
    this.isProfile = this.activatedRoute.snapshot.data.isProfile;
    this.role = this.storageService.role;

    this.getBasicProfile();
  }

  getBasicProfile() {
    this.isLoading = true;

    this.accountService
      .profile(true)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (profile) => {
          this.ekycStatus = profile.ekycStatus;

          if (!this.isProfile && this.ekycStatus == EkycStatus.Verified) {
            this.router.navigate(['/']);
          }
        },
        (err) => this.errorService.showError(err),
      );
  }

  proceed() {
    if (this.isAccept != true) {
      this.popupService.alert('common.acceptT&C');
      return;
    }

    this.blockUiService.open();

    this.accountService.giveConsent(ConsentType.Ekyc).subscribe(
      (result) => {
        this.proceedEkyc();
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  proceedEkyc() {
    let deviceType = DeviceType.Web;

    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) deviceType = DeviceType.iOS;
    else if (/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      deviceType = DeviceType.Android;

    this.accountService
      .createEkyc(deviceType)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        (data) => {
          this.ekycTransaction = data;

          if (this.ekycTransaction.onboardingUrl) {
            if (deviceType == DeviceType.Android || deviceType == DeviceType.iOS)
              this.win = window.open(this.ekycTransaction.onboardingUrl, '_self');
            else {
              this.buildConnection();
              this.win = window.open(this.ekycTransaction.onboardingUrl);
              this.isProcess = true;

              if (!this.win || typeof this.win.closed == 'undefined') {
                this.popupService.alert('common.enablePopupMessage');
                this.isProcess = false;
              }
            }
          }
        },
        (err) => {
          if (err && err.error && err.error.statusCode == 400) {
            this.popupService.alert(err.error.message).then(() => {
              this.router.navigate(['/profile/settings/profile-details']);
            });
          } else this.errorService.showError(err);
        },
      );
  }

  skip() {
    if (!this.isProfile) this.router.navigate(['/']);
    else this.back();
  }

  back() {
    this.location.back();
  }

  buildConnection() {
    if (isPlatformBrowser(this.platform)) {
      this.hubConnection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Debug)
        .withUrl(environment.signalRUrl + '?userId=' + this.storageService.userId, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
        })
        .build();

      this.hubConnection
        .start()
        .then(() => console.log('Connected!'))
        .catch((err) => console.log('Error while starting connection: ' + err));

      this.hubConnection.on('EkycComplete', (data) => {
        this.win.close();
        this.router.navigate(['/e-kyc-result']);
        this.stopConnection();
      });

      this.hubConnection.on('EkycFailure', (data) => {
        this.stopConnection();

        this.popupService.alert('settings.eKycVerifiedFailed').then(() => {
          this.router.navigate(['/profile/settings/profile-details']);
        });
      });
    }
  }

  stopConnection() {
    this.hubConnection.stop().then(() => console.log('Stop Connection!'));
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platform) && this.hubConnection) {
      this.stopConnection();
    }
  }
}
