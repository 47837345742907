export enum ShareType {
  Ordinary = 1,
  Icps = 2,
  Rcps = 3,
  Rps = 4,
  Rcnps = 5,
  Rccps = 6,
  Icnps = 7,
  Iccps = 8,
  Rnps = 9,
  iRps = 10,
  iIcps = 11,
  iRcps = 12,
  iRcnps = 13,
  iRccps = 14,
  iIcnps = 15,
  iIccps = 16,
  iRnps = 17,
  iOrdinary = 18,
  Incps = 19,
  iIncps = 20,
  Rncps = 21,
  Ulp = 22,
}
