<!-- Campaign What's New -->
<div *ngIf="isVisible" class="tw-pb-10">
  <div class="tw-pb-4 tw-font-bold tw-text-black">{{ 'common.whatsNew' | translate }}</div>
  <div class="tw-grid tw-grid-cols-1 tw-flex-wrap tw-gap-2.5 sm:tw-grid-cols-2">
    <div
      class="whatsnew-card is-clickable tw-bg-[#D9B019] tw-text-[#D9B019]"
      *ngIf="campaignInfo.campaignWhatsNew.upcomingEventCount > 0"
      (click)="handleChangeTab(5, $event)"
    >
      <div class="whatsnew-card--icon">
        <p-icon name="events" size="lg"></p-icon>
      </div>
      <div class="whatsnew-card--content">
        <span class="whatsnew-card--number">{{
          campaignInfo.campaignWhatsNew.upcomingEventCount
        }}</span>
        <div
          class="whatsnew-card--text"
          [innerHTML]="
            'common.upcomingEvent'
              | translate: { 'count': campaignInfo.campaignWhatsNew.upcomingEventCount }
          "
        ></div>
      </div>
    </div>
    <div
      class="whatsnew-card tw-bg-[#5D873B] tw-text-[#5D873B]"
      *ngIf="
        campaignInfo.campaignWhatsNew.isNewLargestInvestment && getInvestmentPledgedCondition()
      "
      (click)="handleChangeTab(11, $event)"
    >
      <div class="whatsnew-card--icon"><p-icon name="invest" size="lg"></p-icon></div>
      <div class="whatsnew-card--content">
        <div class="whatsnew-card--text" [innerHTML]="'common.largestInvestment' | translate"></div>
      </div>
    </div>
    <div
      class="whatsnew-card is-clickable tw-bg-[#B26B53] tw-text-[#B26B53]"
      *ngIf="campaignInfo.campaignWhatsNew.updateCount > 0"
      (click)="handleChangeTab(3, $event)"
    >
      <div class="whatsnew-card--icon"><p-icon name="news" size="lg"></p-icon></div>
      <div class="whatsnew-card--content">
        <span class="whatsnew-card--number">{{ campaignInfo.campaignWhatsNew.updateCount }}</span>
        <div
          class="whatsnew-card--text"
          [innerHTML]="
            'common.updateAdded' | translate: { 'count': campaignInfo.campaignWhatsNew.updateCount }
          "
        ></div>
      </div>
    </div>
    <div
      class="whatsnew-card is-clickable tw-bg-[#21B3F5] tw-text-[#21B3F5]"
      *ngIf="campaignInfo.campaignWhatsNew.questionCount > 0"
      (click)="handleChangeTab(4, $event)"
    >
      <div class="whatsnew-card--icon"><p-icon name="announcement" size="lg"></p-icon></div>
      <div class="whatsnew-card--content">
        <span class="whatsnew-card--number">{{ campaignInfo.campaignWhatsNew.questionCount }}</span>
        <div class="whatsnew-card--text" [innerHTML]="'common.questionPosted' | translate"></div>
      </div>
    </div>
    <div
      class="whatsnew-card is-clickable tw-bg-[#E88945] tw-text-[#E88945]"
      *ngIf="campaignInfo.campaignWhatsNew.newEventCount > 0"
      (click)="handleChangeTab(5, $event)"
    >
      <div class="whatsnew-card--icon"><p-icon name="calendar" size="lg"></p-icon></div>
      <div class="whatsnew-card--content">
        <span class="whatsnew-card--number">{{ campaignInfo.campaignWhatsNew.newEventCount }}</span>
        <div
          class="whatsnew-card--text"
          [innerHTML]="
            'common.eventAdded'
              | translate: { 'count': campaignInfo.campaignWhatsNew.newEventCount }
          "
        ></div>
      </div>
    </div>
  </div>
</div>
