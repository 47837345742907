<form (ngSubmit)="submit()" [formGroup]="form" [class.show-form-error]="showFormError">
  <div class="add-panel">
    <div class="dropdown align-self-end" ngbDropdown placement="bottom-right">
      <div class="btn btn-primary btn-add no-caret" ngbDropdownToggle>
        <i class="fa fa-plus mr-2"></i>{{ 'common.add' | translate }}
      </div>
      <div class="dropdown-menu" ngbDropdownMenu>
        <div
          class="dropdown-item font-weight-bold font-size-regular"
          (click)="add(FundRaiseRole.Shareholder)"
        >
          {{ 'raise.shareholder' | translate }}
        </div>
        <div
          class="dropdown-item font-weight-bold font-size-regular"
          (click)="add(FundRaiseRole.Director)"
        >
          {{ 'raise.director' | translate }}
        </div>
      </div>
    </div>
    <ng-container formArrayName="directors">
      <div
        class="details-panel director-bg"
        *ngFor="let director of directorFormControl.controls; let i = index"
        formGroupName="{{ i }}"
      >
        <div class="d-flex mb-3">
          <div class="title">{{ 'raise.director' | translate }}</div>
          <div
            class="pitchin-icon pitchin-icon-delete"
            (click)="remove(i, FundRaiseRole.Director)"
          ></div>
        </div>
        <div class="row">
          <div class="col-lg-6 input-panel">
            <div class="input-label">{{ 'common.name' | translate }}</div>
            <div class="input-field">
              <input
                class="form-control"
                placeholder="{{ 'raise.nameDirectorPlaceholder' | translate }}"
                formControlName="fullName"
                type="text"
              />
            </div>
          </div>
          <div class="col-lg-6 input-panel">
            <div class="input-label">{{ 'raise.icNo' | translate }}</div>
            <div class="input-field">
              <input
                class="form-control"
                placeholder="{{ 'common.icNoPlaceholder' | translate }}"
                formControlName="identityNo"
                type="text"
              />
            </div>
          </div>
          <div class="col-lg-6 input-panel">
            <div class="input-label">{{ 'raise.country' | translate }}</div>
            <div class="input-field">
              <mat-select
                [placeholder]="'common.selectPlaceholder' | translate"
                class="form-control"
                formControlName="country"
              >
                <mat-option *ngFor="let country of Countries | enumToArray" [value]="country.value">
                  {{ country.value }}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container formArrayName="shareholders">
      <div
        class="details-panel shareholder-bg"
        *ngFor="let shareholder of shareholderFormControl.controls; let i = index"
        formGroupName="{{ i }}"
      >
        <div class="d-flex mb-3">
          <div class="title">{{ 'raise.shareholder' | translate }}</div>
          <div
            class="pitchin-icon pitchin-icon-delete"
            (click)="remove(i, FundRaiseRole.Shareholder)"
          ></div>
        </div>
        <div class="row">
          <div class="col-lg-6 input-panel">
            <div class="input-label">{{ 'common.name' | translate }}</div>
            <div class="input-field">
              <input
                class="form-control"
                placeholder="{{ 'raise.nameShareholderPlaceholder' | translate }}"
                formControlName="fullName"
                type="text"
              />
            </div>
          </div>
          <div class="col-lg-6 input-panel">
            <div class="input-label">{{ 'raise.icNo' | translate }}</div>
            <div class="input-field">
              <input
                class="form-control"
                placeholder="{{ 'common.icNoPlaceholder' | translate }}"
                formControlName="identityNo"
                type="text"
              />
            </div>
          </div>
          <div class="col-lg-6 input-panel">
            <div class="input-label">{{ 'raise.country' | translate }}</div>
            <div class="input-field">
              <mat-select
                [placeholder]="'common.selectPlaceholder' | translate"
                class="form-control"
                formControlName="country"
              >
                <mat-option *ngFor="let country of Countries | enumToArray" [value]="country.value">
                  {{ country.value }}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="col-lg-6 input-panel">
            <div class="input-label">{{ 'raise.shareholdings' | translate }}</div>
            <div class="input-field">
              <input
                class="form-control"
                placeholder="{{ 'raise.shareholdingsPlaceholder' | translate }}"
                formControlName="shareholdings"
                type="number"
              />
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="text-center pt-5">
    <button type="submit" class="btn btn-primary">
      {{ 'common.next' | translate }}
    </button>
  </div>
</form>
