import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[alphanumeric]',
})
export class AlphanumericDirective implements OnInit {
  private allowedKeys: string[] = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];

  constructor(
    private el: ElementRef,
    private ren: Renderer2,
  ) {}

  ngOnInit() {
    this.ren.setAttribute(
      this.el.nativeElement,
      'keyup',
      'return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || event.charCode === 45',
    );
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');

    const sanitizedText = this.sanitizeText(pastedText);
    this.replacePastedText(sanitizedText);

    event.preventDefault();
  }

  private sanitizeText(text: string): string {
    const alphanumericRegex = /[a-zA-Z0-9-]+/g;
    const alphanumericMatches = text.match(alphanumericRegex);

    if (alphanumericMatches) {
      return alphanumericMatches.join('');
    }

    return '';
  }

  private replacePastedText(text: string): void {
    const selectionStart = this.el.nativeElement.selectionStart;
    const selectionEnd = this.el.nativeElement.selectionEnd;

    const currentValue = this.el.nativeElement.value;
    const newValue =
      currentValue.slice(0, selectionStart) + text + currentValue.slice(selectionEnd);

    this.el.nativeElement.value = newValue;
    this.el.nativeElement.dispatchEvent(new Event('input'));
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (!this.isKeyAllowed(event.key)) {
      event.preventDefault();
    }
  }

  private isKeyAllowed(key: string): boolean {
    const alphanumericRegex = /^[a-zA-Z0-9-]$/;
    return alphanumericRegex.test(key) || this.allowedKeys.includes(key);
  }
}
