<section
  class="section-shaped mt-5"
  *ngIf="!isLoading && isIssuer && fundRaiseApplications && fundRaiseApplications.length > 0"
>
  <div class="container-fluid">
    <div class="row applications-row">
      <div class="col-md-6 d-flex">
        <div class="pitchin-icon pitchin-icon-clock-ongoing"></div>
        <div class="ml-3 applications-text">
          {{ 'raise.ongoingApplication' | translate: { noOfApplications:
          fundRaiseApplications.length } }}
        </div>
      </div>
      <div class="col-md-6 d-flex justify-content-end">
        <div class="mr-3 applications-text">
          {{ 'raise.goToMyApplications' | translate }}
        </div>
        <div
          class="pitchin-icon pitchin-icon-next-arrow-green"
          routerLink="my-application"
        ></div>
      </div>
    </div>
  </div>
</section>
<agmo-loading *ngIf="isLoading"></agmo-loading>
<section class="section section-md section-shaped">
  <div class="container-fluid">
    <div class="row title-row flex">
      <div class="col-lg-7 left-panel">
        <h1
          class="p-text-headline !tw-mb-5"
          [innerHTML]="
            'raise.raiseFundsAndGrow'
              | translate
                : {
                    value: (stats.raised | numberSuffix: { isSuffix: true, numberFormat: '1.0-0' }),
                  }
          "
        ></h1>
        <p class="mb-5 !tw-text-[1.2rem] tw-font-normal">
          {{ 'raise.createFundraisingCampaignDesc' | translate }}
        </p>
        <a href="/sign-up/register/4">
          <button
            class="btn btn-primary !tw-mr-4 tw-text-base"
            [disabled]="isCheckFlowLoading"
          >
            {{ 'raise.signUp' | translate }}
          </button>
        </a>
      </div>
      <div class="col-lg-5 tw-pt-10 lg:tw-pt-0">
        <img
          src="assets/img/brand/raise_image_1@2x.png"
          class="tw-mx-auto tw-w-full tw-max-w-[500px]"
          alt="pitch-in-raise-funds"
        />
      </div>
    </div>
  </div>
</section>
<section>
  <div
    class="logos tw-relative tw-overflow-hidden tw-whitespace-nowrap tw-bg-white"
  >
    <div class="logos-slide">
      <div class="overflow-hidden tw-flex tw-items-center tw-gap-x-4">
        <div *ngFor="let slide of slider">
          <div class="tw-mx-6">
            <div class="tw-rounded-md">
              <img
                src="assets/img/logo/{{ slide.src }}"
                alt="{{ slide.name }} logo"
                class="tw-max-h-[124px]"
              />
            </div>
          </div>
        </div>
        <div *ngFor="let slide of slider">
          <div class="tw-mx-6">
            <div class="tw-rounded-md">
              <img
                src="assets/img/logo/{{ slide.src }}"
                alt="{{ slide.name }} logo"
                class="tw-max-h-[124px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section section-md section-stats tw-mb-0 tw-pb-0">
  <div class="container-fluid">
    <div class="tw-flex tw-flex-col tw-items-center">
      <p class="tw-text-center sm:tw-text-left">
        {{ 'raise.recognisedMarketOperatorWithSecuritiesCommissionMalaysia' |
        translate }}
      </p>

      <div
        class="row tw-mb-4 tw-w-full tw-items-stretch tw-justify-center tw-gap-2 tw-text-center max-md:tw-flex"
      >
        <div class="col-md-3 col-10 md:tw-px-auto tw-flex tw-flex-col !tw-px-0">
          <div
            class="card tw-grow tw-rounded-lg tw-border-[#333333] tw-border-opacity-20"
          >
            <div class="card-body">
              <div class="tw-text-2xl tw-font-bold tw-text-black">
                {{ stats.raised | numberSuffix: { isSuffix: true, numberFormat:
                '1.0-0' } }}+
              </div>
              <div
                class="tw-max-md:mt-2 tw-text-lg tw-font-semibold tw-text-black"
              >
                {{ 'landing.raised' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-5 md:tw-px-auto tw-flex tw-flex-col !tw-px-0">
          <div
            class="card tw-grow tw-rounded-lg tw-border-[#333333] tw-border-opacity-20"
          >
            <div class="card-body">
              <div class="tw-text-2xl tw-font-bold tw-text-black">
                {{ stats.investors | number }}+
              </div>
              <div
                class="tw-text-lg tw-font-semibold tw-text-black max-md:tw-mt-2"
              >
                {{ 'landing.investors' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-5 md:tw-px-auto tw-flex tw-flex-col !tw-px-0">
          <div
            class="card tw-grow tw-rounded-lg tw-border-[#333333] tw-border-opacity-20"
          >
            <div class="card-body">
              <div class="tw-text-2xl tw-font-bold tw-text-black">
                {{ stats.campaigns | number }}+
              </div>
              <div
                class="tw-text-lg tw-font-semibold tw-text-black max-md:tw-mt-2"
              >
                {{ 'landing.campaignsDone' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path
    fill="#BF2026"
    fill-opacity="1"
    d="M0,128L48,154.7C96,181,192,235,288,234.7C384,235,480,181,576,170.7C672,160,768,192,864,208C960,224,1056,224,1152,202.7C1248,181,1344,139,1392,117.3L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
  ></path>
</svg>
<section
  class="section section-md section-shaped tw--mt-1 tw-bg-[#BF2026] tw-pb-12 sm:tw-pb-24"
>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-8 text-center tw-mx-auto">
        <p
          class="p-text-preheader tw-mb-2 tw-text-center tw-text-sm tw-text-white"
        >
          {{ 'raise.whyPitchIN' | translate }}
        </p>
        <h2 class="p-text-headline text-white tw-pt-2">
          {{ 'raise.fundraisingHub' | translate }}
        </h2>
      </div>
      <div
        class="tw-mb-0 tw-mt-8 tw-grid tw-grid-cols-2 tw-gap-x-8 md:tw-gap-16 lg:tw-my-16"
      >
        <div class="text-white tw-col-span-2 md:tw-col-span-1 md:tw-pr-8">
          <ul
            class="tw-mb-8 tw-flex tw-list-none tw-flex-col tw-gap-y-8 max-sm:tw-pr-8 sm:tw-mb-12 sm:tw-gap-y-12"
          >
            <li *ngFor="let advantage of raiseAdvantages">
              <p class="tw-text-xl tw-font-black">
                {{ 'raise.' + advantage.titleKey | translate }}
              </p>
              <p
                class="tw-border-l-2 tw-border-white tw-pl-6 tw-text-base tw-font-light"
              >
                {{ 'raise.' + advantage.descriptionKey | translate }}
              </p>
            </li>
          </ul>
          <a
            class="btn btn-outline-primary text-white tw-border-px tw-ml-10 tw-flex-none tw-border-white tw-px-8 hover:tw-border-white"
            href="/sign-up/register/4"
            >{{ 'raise.signUp' | translate }}</a
          >
        </div>
        <div
          class="tw-col-span-2 tw-grid tw-place-items-center md:tw-col-span-1"
        >
          <ul class="list-stacked tw-relative tw-list-none tw-px-4">
            <li
              *ngFor="let company of featuredCompanies | slice: 1 : 4"
              class="list-none tw-relative tw-max-w-[225px] tw-flex-[0_0_225px]"
            >
              <div
                class="tw-flex tw-h-full tw-flex-col tw-justify-between tw-rounded-lg tw-border tw-border-solid tw-border-divider tw-bg-white tw-p-5 lg:tw-w-64"
              >
                <div class="relative">
                  <img
                    class="tw-h-16 tw-w-16 tw-rounded tw-object-contain tw-shadow-xl"
                    [src]="company.companyLogoUrl"
                    [alt]="company.brandName"
                  />

                  <div
                    class="tw-mt-4 tw-font-bold tw-leading-snug tw-text-black"
                  >
                    {{ company.brandName }}
                  </div>
                </div>

                <div>
                  <div class="tw-mb-3 tw-mt-5">
                    <app-tag-list [tags]="company.sectors"></app-tag-list>
                  </div>

                  <div
                    class="tw-grid tw-grid-cols-2 tw-divide-x tw-divide-y-0 tw-divide-solid tw-divide-divider"
                  >
                    <div class="tw-pr-1">
                      <div class="p-text-tag">
                        {{ 'campaign.raised' | translate }}
                      </div>
                      <div
                        class="tw-text-lg tw-font-bold tw-text-secondary-light"
                      >
                        {{ company.stats.raised | numberSuffix: { isSuffix:
                        true, numberFormat: '1.0-1' } }}
                      </div>
                    </div>
                    <div class="tw-pl-2.5">
                      <div class="p-text-tag">
                        {{ 'campaign.investors' | translate }}
                      </div>
                      <div class="tw-text-lg tw-font-bold tw-text-black">
                        {{ company.stats.investors | number }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section tw-py-12 sm:tw-py-24">
  <div class="container-fluid">
    <p
      class="p-text-preheader tw-mb-2 tw-text-center tw-text-sm tw-text-[#BF2026]"
    >
      {{ 'raise.rightForMe' | translate }}
    </p>
    <h2 class="p-text-headline tw-mb-5 tw-text-center">
      {{ 'raise.suitableForEveryBusiness' | translate }}
    </h2>
    <p class="tw-mb-20 tw-text-center sm:tw-px-32">
      {{ 'raise.companiesWeHelped' | translate }}
    </p>

    <div class="tw-mb-12 tw-overflow-x-auto">
      <div class="tw-flex tw-flex-row tw-gap-x-6 tw-pb-4 sm:tw-gap-x-12">
        <ng-container *ngFor="let sector of sector">
          <div
            class="card col-10 col-md-8 col-lg-5 tw-flex tw-flex-col tw-justify-between tw-rounded-xl tw-border-gray-300 tw-p-4 sm:tw-p-8"
          >
            <div class="tw-flex tw-flex-col">
              <p
                class="tw-mb-4 tw-text-xl tw-font-semibold tw-text-black sm:tw-text-2xl"
              >
                {{ sector.title }}
              </p>
              <p class="tw-text-base">{{ sector.desc }}</p>
            </div>
            <div
              class="tw-flex tw-w-full tw-flex-wrap tw-items-center tw-justify-start tw-gap-2 tw-border-t tw-border-t-gray-300 tw-pt-6 sm:tw-gap-4 sm:tw-border-0"
            >
              <ng-container *ngFor="let company of sector.company">
                <img
                  src="assets/img/logo/{{ company.logo }}"
                  alt="{{ company.name }}"
                  class="tw-max-h-[24px] tw-object-contain sm:tw-max-h-[32px]"
                  style="filter: grayscale(100%)"
                  [ngClass]="{ 'max-sm:tw-hidden': company.mobileHide }"
                />
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="tw-mb-12 tw-overflow-x-auto">
      <div class="tw-flex tw-flex-row tw-gap-x-6 tw-pb-4 sm:tw-gap-x-12">
        <ng-container *ngFor="let fund of funded">
          <div
            class="card col-10 col-md-8 col-lg-5 tw-flex tw-flex-col tw-justify-between tw-rounded-xl tw-border-gray-300 tw-p-4 sm:tw-p-8"
          >
            <div class="tw-flex tw-flex-col">
              <p
                class="tw-mb-4 tw-text-xl tw-font-semibold tw-text-black sm:tw-text-2xl"
              >
                {{ fund.title }}
              </p>
              <p class="tw-text-base">{{ fund.desc }}</p>
            </div>
            <div
              class="tw-flex tw-w-full tw-flex-wrap tw-items-center tw-justify-start tw-gap-2 tw-border-t tw-border-t-gray-300 tw-pt-6 sm:tw-gap-4 sm:tw-border-0"
            >
              <ng-container *ngFor="let company of fund.company">
                <img
                  src="assets/img/logo/{{ company.logo }}"
                  alt="{{ company.name }}"
                  class="tw-max-h-[24px] tw-object-contain sm:tw-max-h-[32px]"
                  style="filter: grayscale(100%)"
                  [ngClass]="{ 'max-sm:tw-hidden': company.mobileHide }"
                />
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>

<section class="section tw-bg-[#f3f3f3] tw-py-12 sm:tw-py-24">
  <div class="container-fluid">
    <h2
      class="p-text-headline mb-5 tw-text-center"
      [innerHTML]="'raise.crowdfundingCreatesOpportunity' | translate"
    ></h2>

    <div class="tw-mb-5 tw-flex tw-flex-col tw-items-center sm:tw-mb-12">
      <div class="col-lg-6 tw-mb-7 lg:tw-mb-0">
        <div class="tw-flex tw-gap-3">
          <div class="tw-flex-1">
            <div
              class="tw-mb-1.5 tw-text-lg tw-font-semibold tw-text-[#333333]"
            >
              {{ 'raise.accessToAGlobalAudience' | translate }}
            </div>
            <p>{{ 'raise.accessToAGlobalAudienceDesc' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 tw-mb-7 lg:tw-mb-0">
        <div class="tw-flex tw-gap-3">
          <div class="tw-flex-1">
            <div
              class="tw-mb-1.5 tw-text-lg tw-font-semibold tw-text-[#333333]"
            >
              {{ 'raise.buildCommunityForLongTermGrowth' | translate }}
            </div>
            <p>{{ 'raise.buildCommunityForLongTermGrowthDesc' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
    <h2
      class="p-text-headline mb-5 tw-text-center"
      [innerHTML]="'raise.multipleWaysToRaiseCapital' | translate"
    ></h2>
    <div
      class="tw-flex tw-justify-center tw-gap-4 max-sm:tw-flex-col md:tw-gap-12"
    >
      <div
        class="col-lg-4 tw-flex tw-flex-col tw-gap-4 tw-rounded-lg tw-bg-[#EDF6FF] tw-p-4"
      >
        <div class="tw-ml-8 tw-flex tw-items-center tw-justify-between">
          <h3
            class="tw-mb-4 tw-mt-2 tw-text-2xl tw-font-semibold tw-text-black"
          >
            {{ 'raise.equityCrowdfunding' | translate }}
          </h3>
          <img
            src="assets/img/icons/common/equity-crowdfunding-icon.png"
            alt="equity crowdfunding"
            height="70px"
            class="tw-object-contain"
          />
        </div>
        <div class="tw-mx-8 tw-flex tw-flex-col tw-gap-4">
          <div class="tw-flex tw-gap-4">
            <img
              src="assets/img/icons/common/green-tick-with-border.png"
              alt="bullet"
              height="32px"
            />
            <p>{{ 'raise.equityCrowdfundingDesc1' | translate }}</p>
          </div>
          <div class="tw-flex tw-gap-4">
            <img
              src="assets/img/icons/common/green-tick-with-border.png"
              alt="bullet"
              height="32px"
            />
            <p>{{ 'raise.equityCrowdfundingDesc2' | translate }}</p>
          </div>
          <div class="tw-flex tw-gap-4">
            <img
              src="assets/img/icons/common/green-tick-with-border.png"
              alt="bullet"
              height="32px"
            />
            <p>{{ 'raise.equityCrowdfundingDesc3' | translate }}</p>
          </div>
        </div>
        <a
          class="tw-ml-8 tw-text-sm tw-text-black tw-underline hover:tw-cursor-pointer"
          href="/learn/raise/who-can-raise-capital-through-an-ecf-platform"
          >{{ 'raise.learnMore' | translate }} -></a
        >
      </div>
      <div
        class="col-lg-4 tw-flex tw-flex-col tw-gap-4 tw-rounded-lg tw-bg-[#FCF2E9] tw-p-4"
      >
        <div class="tw-ml-8 tw-flex tw-items-center tw-justify-between">
          <h3
            class="tw-mb-4 tw-mt-2 tw-text-2xl tw-font-semibold tw-text-black"
          >
            {{ 'raise.tokenCrowdfunding' | translate }}
          </h3>
          <img
            src="assets/img/icons/common/token-crowdfunding-icon.png"
            alt="token crowdfunding"
            height="60px"
            class="tw-object-contain"
          />
        </div>
        <div class="tw-mx-8 tw-flex tw-flex-col tw-gap-4">
          <div class="tw-flex tw-gap-4">
            <img
              src="assets/img/icons/common/green-tick-with-border.png"
              alt="bullet"
              height="32px"
            />
            <p>{{ 'raise.tokenCrowdfundingDesc1' | translate }}</p>
          </div>
          <div class="tw-flex tw-gap-4">
            <img
              src="assets/img/icons/common/green-tick-with-border.png"
              alt="bullet"
              height="32px"
            />
            <p>{{ 'raise.tokenCrowdfundingDesc2' | translate }}</p>
          </div>
          <div class="tw-flex tw-gap-4">
            <img
              src="assets/img/icons/common/green-tick-with-border.png"
              alt="bullet"
              height="32px"
            />
            <p>{{ 'raise.tokenCrowdfundingDesc3' | translate }}</p>
          </div>
        </div>
        <a
          class="tw-ml-8 tw-text-sm tw-text-black tw-underline hover:tw-cursor-pointer"
          href="/learn/token-issue/raising-with-token-crowdfunding"
          >{{ 'raise.learnMore' | translate }} -></a
        >
      </div>
    </div>
  </div>
</section>

<section class="section tw-bg-[#006937] tw-py-12 sm:tw-py-24">
  <div class="container-fluid">
    <div class="tw-mb-12">
      <h2
        class="p-text-headline tw-mb-5 tw-text-center tw-text-white max-sm:tw-col-span-2"
      >
        {{ 'raise.equityVSTokenCrowdfunding' | translate }}
      </h2>
      <div
        class="tw-mb-12 tw-grid tw-grid-cols-[2fr_5fr_5fr] tw-text-center tw-text-white max-sm:tw-grid-cols-2"
      >
        <div class="tw-border-b tw-border-white tw-py-8 max-sm:tw-hidden"></div>

        <div class="tw-border-b tw-border-white tw-py-8">
          <div class="tw-mx-auto tw-max-w-[330px]">
            <div class="tw-mt-auto tw-px-4 tw-text-xl tw-font-semibold">
              {{ 'raise.ECF' | translate }}<br />
              <a
                class="tw-text-base tw-font-medium tw-text-white tw-underline hover:tw-cursor-pointer hover:tw-text-white"
                href="/equity"
                >{{ 'raise.learnMore' | translate }}</a
              >
            </div>
          </div>
        </div>
        <div class="tw-border-b tw-border-white tw-py-8">
          <div class="tw-mx-auto tw-max-w-[330px]">
            <div class="tw-mt-auto tw-px-4 tw-text-xl tw-font-semibold">
              {{ 'raise.TCF' | translate }}<br />
              <a
                class="tw-text-base tw-font-medium tw-text-white tw-underline hover:tw-cursor-pointer hover:tw-text-white"
                [href]="ieoBaseUrl"
                >{{ 'raise.learnMore' | translate }}</a
              >
            </div>
          </div>
        </div>

        <ng-container *ngFor="let item of comparisonData">
          <div
            class="tw-col-span-1 tw-m-0 tw-flex tw-items-center tw-justify-center tw-border-b tw-border-white tw-p-0 tw-font-semibold max-sm:tw-col-span-2"
          >
            <div
              class="tw-mx-auto tw-flex tw-max-w-[330px] tw-flex-col tw-items-center tw-justify-center tw-py-2"
            >
              {{ item.category }}
            </div>
          </div>
          <div
            class="tw-m-0 tw-flex tw-items-center tw-justify-center tw-border-b tw-border-white tw-p-0 max-sm:tw-col-span-1"
          >
            <div
              class="tw-mx-auto tw-flex tw-max-w-[380px] tw-flex-col tw-items-center tw-justify-center tw-px-4 tw-py-6 sm:tw-px-10"
              [innerHTML]="item.equity"
            ></div>
          </div>
          <div
            class="tw-m-0 tw-flex tw-items-center tw-justify-center tw-border-b tw-border-white tw-p-0 max-sm:tw-col-span-1"
          >
            <div
              class="tw-mx-auto tw-flex tw-max-w-[380px] tw-flex-col tw-items-center tw-justify-center tw-px-4 tw-py-6 sm:tw-px-10"
              [innerHTML]="item.token"
            ></div>
          </div>
        </ng-container>
      </div>
      <p
        class="tw-text-center tw-text-sm tw-font-light tw-text-white"
        [innerHTML]="'raise.requireAssistanceContactUs' | translate"
      ></p>
    </div>
  </div>
</section>

<section
  class="section tw-border-b tw-border-black tw-border-opacity-20 tw-py-12 sm:tw-py-24"
>
  <div class="container-fluid">
    <p
      class="p-text-preheader tw-mb-2 tw-text-center tw-text-sm tw-text-[#BF2026]"
    >
      {{ 'raise.pitchInAcademy' | translate }}
    </p>
    <h2 class="p-text-headline tw-mb-5 tw-text-center">
      {{ 'raise.fundraisingAccelerator' | translate }}
    </h2>
    <p class="tw-mb-5 tw-text-center tw-text-2xl tw-font-semibold sm:tw-mb-12">
      {{ 'raise.pitchInAcademySubheading' | translate }}
    </p>
    <div
      class="tw-flex tw-flex-col-reverse tw-justify-center tw-gap-x-8 md:tw-flex-row"
    >
      <div class="gap-4 col-lg-6 tw-flex tw-flex-col sm:tw-gap-16">
        <ul class="tw-m-4 tw-flex tw-flex-col tw-gap-4 sm:tw-pr-16">
          <li class="p-text-p">
            {{ 'raise.fundraisingAcceleratorDesc1' | translate }}
          </li>
          <li class="p-text-p">
            {{ 'raise.fundraisingAcceleratorDesc2' | translate }}
          </li>
          <li class="p-text-p">
            {{ 'raise.fundraisingAcceleratorDesc3' | translate }}
          </li>
        </ul>
        <div class="p-link-primary p-link-underline tw-mb-6 tw-ml-8 sm:tw-mb-0">
          <a
            class="tw-text-black hover:tw-cursor-pointer hover:tw-text-black"
            target="_blank"
            href="https://academy.pitchin.my"
            >{{ 'raise.learnHowToAttendAFundraisingAccelerator' | translate
            }}</a
          >
        </div>
      </div>

      <div class="col-lg-6 tw-flex tw-flex-col tw-gap-y-8">
        <div
          class="card tw-flex tw-flex-row tw-gap-4 tw-border-gray-300 tw-p-8"
        >
          <img
            class="tw-h-20 tw-rounded-full tw-object-cover"
            src="assets/img/partners/tharma-bwave.png"
            alt="Bwave CEO Tharma"
          />
          <div class="tw-flex tw-flex-col">
            <p class="!tw-mb-0 !tw-pb-0 tw-font-semibold tw-text-black">
              {{ 'raise.bwaveCEOName' | translate }}
            </p>
            <p>{{ 'raise.bwaveCEO' | translate }}</p>
            <p class="!tw-mb-0 !tw-pb-0 tw-text-sm">
              {{ 'raise.bwaveDesc' | translate }}
            </p>
          </div>
        </div>
        <div
          class="card tw-flex tw-flex-row tw-gap-4 tw-border-gray-300 tw-p-8"
        >
          <img
            class="tw-h-20 tw-rounded-full tw-object-cover"
            src="assets/img/partners/raden-wahdah.png"
            alt="Wahdah CEO Raden"
          />
          <div class="tw-flex tw-flex-col">
            <p class="!tw-mb-0 !tw-pb-0 tw-font-semibold tw-text-black">
              {{ 'raise.wahdahCEOName' | translate }}
            </p>
            <p>{{ 'raise.wahdahCEO' | translate }}</p>
            <p class="!tw-mb-0 !tw-pb-0 tw-text-sm">
              {{ 'raise.wahdahDesc' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tw-mt-6 tw-grid tw-grid-cols-4 tw-grid-rows-2 max-md:tw-grid-cols-2 sm:tw-mt-20"
    >
      <img
        class="tw-col-span-2 tw-col-start-1 tw-row-span-2 tw-row-start-1 tw-w-full tw-object-cover"
        src="assets/img/banner/fundraising-accelerator-1.png"
        alt="fundraising accelerator campaign"
      />
      <img
        class="tw-w-full tw-object-cover"
        src="assets/img/banner/fundraising-accelerator-2.png"
        alt="fundraising accelerator campaign"
      />
      <img
        class="tw-w-full tw-object-cover"
        src="assets/img/banner/fundraising-accelerator-3.png"
        alt="fundraising accelerator campaign"
      />
      <img
        class="tw-w-full tw-object-cover"
        src="assets/img/banner/fundraising-accelerator-4.png"
        alt="fundraising accelerator campaign"
      />
      <img
        class="tw-w-full tw-object-cover"
        src="assets/img/banner/fundraising-accelerator-5.png"
        alt="fundraising accelerator campaign"
      />
    </div>
  </div>
</section>

<section class="section tw-py-12 sm:tw-py-24">
  <div class="container-fluid">
    <h2 class="p-text-headline mb-5 tw-text-center">
      {{ 'raise.issuerTestimonials' | translate }}
    </h2>
    <app-testimonial></app-testimonial>
  </div>
</section>

<section class="sm:pt-6 tw-pb-12 sm:tw-pb-24">
  <div class="container-fluid">
    <h2 class="p-text-headline tw-mb-5 tw-text-center">
      {{ 'raise.faqTitle' | translate }}
    </h2>

    <div class="row tw-flex tw-flex-col tw-items-center">
      <app-json-ld [itemLd]="pageFaqs"></app-json-ld>
      <app-faq-accordion
        [accordionItems]="pageFaqs"
        [showTitle]="false"
      ></app-faq-accordion>
    </div>
  </div>
</section>

<section class="section tw-bg-[#BF2026] tw-py-12 sm:tw-py-16">
  <div class="container-fluid">
    <h2 class="tw-mb-5 tw-text-3xl tw-font-semibold tw-text-white">
      {{ 'raise.raiseWithEquityOrTokenCrowdfunding' | translate }}
    </h2>

    <div class="col-md-6 tw-flex tw-items-center tw-gap-4 !tw-px-0">
      <a
        class="btn btn-outline-primary text-white tw-border-px tw-flex-none tw-border-white hover:tw-border-white"
        href="/sign-up/register/4"
        >{{ 'raise.signUp' | translate }}</a
      >
    </div>
  </div>
</section>
