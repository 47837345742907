import { ErrorService } from './../../../../core/services/error.service';
import { CampaignService } from './../../../../core/services/api/campaign.service';
import { CampaignEventModel } from '../../../../core/models/campaign/campaign-event.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { PopupService } from './../../../../shared/services/popup/popup.service';
import { BlockUiService } from './../../../../shared/services/blockUi/block-ui.service';
import * as moment from 'moment';
import { StorageService } from './../../../../core/services/storage.service';
import { Role } from './../../../../core/enum/role.enum';
import { ImageFileFormat } from '../../../../core/services/constant';
import { AccountService } from '../../../../core/services/api/account.service';
import { ContainerType } from '../../../../core/enum/container-type.enum';
import { FileValidationService } from '../../../../core/services/file-validation.service';
import { FileUploadOption } from '../../../../core/models/attachment/file-upload-option';
import { AttachmentInfo } from '../../../../core/models/attachment/attachment-info';
import { FormValidators } from '../../../../core/validator/form.validators';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  @Input() campaignId: number;
  @Input() isCreator: boolean;
  @Output() onUpdate = new EventEmitter();

  events: CampaignEventModel[] = [];
  total: number = 0;
  params = {
    currentPage: 1,
    take: 10,
  };

  minStartDate: Date = new Date(1990, 0, 1);

  isLoading: boolean = false;
  isAddEvent: boolean = false;
  showFormError: boolean = false;
  form: UntypedFormGroup;
  eventTags: string[] = [];
  selectable = true;
  removable = true;
  addOnBlur = true;
  imageUrl: any;
  file: File;
  event: CampaignEventModel;
  showActions: boolean = this.storageService.role == Role.IssuerRep;
  accept: string = ImageFileFormat.toString();
  attachmentInfo: AttachmentInfo;
  isImageLoading = false;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private campaignService: CampaignService,
    private errorService: ErrorService,
    private formBuilder: UntypedFormBuilder,
    private popupService: PopupService,
    private blockUiService: BlockUiService,
    private storageService: StorageService,
    private accountService: AccountService,
    private fileValidationService: FileValidationService,
  ) {}

  ngOnInit() {
    this.getEvents(true);
  }

  getEvents(reload?: boolean) {
    if (reload) {
      this.params.currentPage = 1;
      this.events = [];
    }

    this.isLoading = true;

    this.campaignService
      .getEvents(this.campaignId, this.params.currentPage, this.params.take)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.events = this.events.concat(data.data);
          this.events.forEach((event) => {
            event.isOngoing = moment().isBetween(event.startDate, event.endDate);
            event.isUpcoming = moment().isBefore(event.startDate);

            if (event.eventTags) event.eventTagsArray = JSON.parse(event.eventTags);
          });
          this.total = data.total;
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }

  addEvent() {
    this.isAddEvent = !this.isAddEvent;

    if (this.isAddEvent) {
      this.buildForm();
      this.resetFormData();
    }
  }

  buildForm() {
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      startDate: ['', Validators.required, FormValidators.minYearValidator(1990)],
      startTime: ['', Validators.required],
      endDate: ['', Validators.required],
      endTime: ['', Validators.required],
      eventLink: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(?=.+)((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
          ),
        ],
      ],
    });
  }

  get formControl() {
    return this.form.controls;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) this.eventTags.push(value.trim());

    if (input) input.value = '';
  }

  remove(tag: string): void {
    const index = this.eventTags.indexOf(tag);

    if (index >= 0) this.eventTags.splice(index, 1);
  }

  async selectFile(file: File) {
    const uploadOption: FileUploadOption = {
      fileType: ImageFileFormat,
      errorMsg: 'common.onlyImageAccepted',
    };

    if (await this.fileValidationService.validFile(file, uploadOption)) {
      this.file = file;
      this.isImageLoading = true;

      this.accountService
        .uploadAttachmentPresignedURL(file, ContainerType.CampaignEvent)
        .pipe(finalize(() => (this.isImageLoading = false)))
        .subscribe(
          (data) => {
            this.imageUrl = data.url;
            this.attachmentInfo = new AttachmentInfo(data);
          },
          (err) => {
            this.errorService.showError(err);
            this.imageUrl = null;
            this.attachmentInfo = null;
          },
        );
    }
  }

  removeImage() {
    this.attachmentInfo = null;
    this.imageUrl = null;
  }

  submit() {
    if (this.form.invalid) {
      this.showFormError = true;
      return;
    }

    if (!this.attachmentInfo && !this.imageUrl) {
      this.showFormError = true;
      this.popupService.alert('campaign.pleaseUploadEventImage');
      return;
    }

    let startDate = moment(
      moment(this.form.value.startDate).format('YYYY-MM-DD') + ' ' + this.form.value.startTime,
      'YYYY-MM-DD hh:mm A',
    );
    let endDate = moment(
      moment(this.form.value.endDate).format('YYYY-MM-DD') + ' ' + this.form.value.endTime,
      'YYYY-MM-DD hh:mm A',
    );

    if (startDate.isAfter(endDate)) {
      this.popupService.alert('campaign.dateIncorrectMessage');
      return;
    }

    let event = this.form.value;
    event.eventTags = this.eventTags;
    event.attachmentInfo = this.attachmentInfo;
    event.startDate = startDate.toISOString();
    event.endDate = endDate.toISOString();

    let observable =
      this.event && this.event.id
        ? this.campaignService.udpateEvent(event, this.campaignId, this.event.id)
        : this.campaignService.addEvent(event, this.campaignId);

    this.blockUiService.open();

    observable.pipe(finalize(() => this.blockUiService.close())).subscribe({
      next: () => {
        this.isAddEvent = !this.isAddEvent;
        this.getEvents(true);
        this.getStatisticCount();
        this.onUpdate.emit();
      },
      error: (err) => this.errorService.showError(err),
    });
  }

  loadMore() {
    this.params.currentPage++;
    this.getEvents();
  }

  deleteEvent(id: number) {
    this.popupService.confirm('campaign.confirmDeleteEvent').then(
      () => {
        this.blockUiService.open();

        this.campaignService
          .deleteEvent(id, this.campaignId)
          .pipe(finalize(() => this.blockUiService.close()))
          .subscribe({
            next: () => {
              this.getEvents(true);
              this.getStatisticCount();
              this.onUpdate.emit();
            },
            error: (err) => this.errorService.showError(err),
          });
      },
      () => {},
    );
  }

  editEvent(event: CampaignEventModel) {
    this.isAddEvent = !this.isAddEvent;
    this.event = event;
    this.buildForm();
    this.form.patchValue(event);
    this.form.controls['startDate'].setValue(moment(event.startDate));
    this.form.controls['endDate'].setValue(moment(event.endDate));
    this.form.controls['startTime'].setValue(moment(event.startDate).format('hh:mm a'));
    this.form.controls['endTime'].setValue(moment(event.endDate).format('hh:mm a'));
    this.imageUrl = event.photoAttachmentUrl;
    this.attachmentInfo = event.attachmentInfo;
    this.eventTags = event.eventTags ? JSON.parse(event.eventTags) : [];
  }

  cancel() {
    this.isAddEvent = !this.isAddEvent;
    this.resetFormData();
  }

  resetFormData() {
    this.showFormError = false;
    this.event = null;
    this.eventTags = [];
    this.file = null;
    this.attachmentInfo = null;
    this.imageUrl = null;
  }

  getStatisticCount() {
    this.campaignService.getStatisticCount(this.campaignId).subscribe({
      next: (data) => this.campaignService.statisticCount.next(data),
      error: (err) => this.errorService.showError(err),
    });
  }

  getTags(tagList) {
    return tagList.map((tag) => {
      return {
        name: tag,
      };
    });
  }
}
