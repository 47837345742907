<div class="tw-mb-5 tw-mt-10 tw-text-xl tw-font-bold tw-text-black">
  {{ 'shareMovement.tracker' | translate }}
</div>

<section class="tw-mb-4 tw-flex tw-gap-4">
  <div class="tw-flex tw-items-center tw-gap-4">
    <div class="dropdown" ngbDropdown>
      <div
        class="d-flex align-items-center cursor-pointer tw-select-none"
        id="ownershipType"
        aria-controls="menu"
        ngbDropdownToggle
      >
        <span class="pr-1 tw-font-semibold">{{ 'shareMovement.searchBy' | translate }}:</span>
        {{ selectedFilterBy.value }}
        <i class="fa fa-caret-down tw-ml-2 tw-opacity-50"></i>
      </div>
      <div
        class="dropdown-menu tw-max-h-[300px] tw-overflow-auto"
        ngbDropdownMenu
        role="menu"
        aria-labelledby="ownershipType"
      >
        <button
          role="menuitem"
          *ngFor="let filterOption of filterBy"
          class="dropdown-item font-weight-bold font-size-regular cursor-pointer"
          (click)="handleFilterBy(filterOption)"
        >
          {{ filterOption.value | translate }}
        </button>
      </div>
    </div>
    <search-input [value]="params.filterString" (onSubmit)="handleSearch($event)"></search-input>
  </div>

  <div class="tw-flex tw-items-center">
    <div class="dropdown" ngbDropdown>
      <div
        class="d-flex align-items-center cursor-pointer tw-select-none"
        id="ownershipType"
        aria-controls="menu"
        ngbDropdownToggle
      >
        <span class="pr-1 tw-font-semibold">{{ 'Counter name' | translate }}:</span>
        {{ selectedTickerCode }}
        <i class="fa fa-caret-down tw-ml-2 tw-opacity-50"></i>
      </div>
      <div
        class="dropdown-menu tw-max-h-[300px] tw-overflow-auto"
        ngbDropdownMenu
        role="menu"
        aria-labelledby="ownershipType"
      >
        <button
          role="menuitem"
          *ngFor="let type of tickerCodeList"
          class="dropdown-item font-weight-bold font-size-regular cursor-pointer"
          (click)="handleFilterTickerCode(type.value)"
        >
          {{ type.value | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="tw-flex tw-flex-1">
    <app-date-range-picker
      (goSearch)="filterByDate($event)"
      [dateFrom]="params.createdAtFrom"
      [dateTo]="params.createdAtTo"
      [maxRange]="maxDateRange"
      [readonly]="true"
      [col]="4"
      [showClear]="false"
      [showLabel]="false"
    >
    </app-date-range-picker>
  </div>
  <button (click)="handleReset()" type="button" class="tw-cursor-pointer">
    {{ 'resetPassword.reset' | translate }}
  </button>
</section>
<div class="p-table__wrapper">
  <div class="p-table__body">
    <mat-table class="responsive-table p-table max-lg:tw-w-[800px]" [dataSource]="shareMovement">
      <ng-container matColumnDef="InvestorName">
        <mat-header-cell *matHeaderCellDef class="tw-flex-[2] tw-items-start">
          {{ 'profile.Shareholder' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let item" class="tw-flex-[2] tw-flex-col tw-items-start">
          <div>
            <div class="p-skeleton-bar tw-font-semibold">
              {{ item.investorName }}
            </div>
            <div class="p-skeleton-hidden">
              {{ item.isCorpRep ? 'Corporate' : 'Individual' }} <br />
              {{ item.investorEmail }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="DateRange">
        <mat-header-cell *matHeaderCellDef class="tw-items-start">{{
          'common.dateRange' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let item" class="tw-flex-col tw-items-start">
          <div class="p-skeleton-bar">
            {{ item.oldestCreatedAt | amDateFormat: 'DD/MM/YYYY' }}
          </div>
          <div>-</div>
          <div class="p-skeleton-bar">
            {{ item.recentCreatedAt | amDateFormat: 'DD/MM/YYYY' }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="TickerCode">
        <mat-header-cell *matHeaderCellDef class="tw-items-start">{{
          'settings.counter' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let item" class="tw-flex-col tw-items-start">
          <div class="p-skeleton-bar tw-font-semibold tw-uppercase">
            {{ item.tickerCode }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="PreviousHolding">
        <mat-header-cell *matHeaderCellDef class="tw-items-start tw-justify-end tw-text-right">{{
          'shareMovement.prevHolding' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let item" class="tw-flex-col tw-items-end">
          <div class="p-skeleton-bar">
            {{ item.oldestBalance }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Movement">
        <mat-header-cell *matHeaderCellDef class="tw-items-start tw-justify-end tw-text-right">{{
          'shareMovement.movement' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let item" class="tw-flex-col tw-items-end">
          <div class="p-skeleton-bar" [class.tw-text-primary]="item.netShareMovement < 0">
            {{ item.netShareMovement > 0 ? '+' : '' }}{{ item.netShareMovement }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CurrentBalance">
        <mat-header-cell *matHeaderCellDef class="tw-items-start tw-justify-end tw-text-right">{{
          'shareMovement.currentholding' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let item" class="tw-flex-col tw-items-end">
          <div class="p-skeleton-bar">
            {{ item.recentBalance }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="AveragePricePerShare">
        <mat-header-cell *matHeaderCellDef class="tw-items-start tw-justify-end tw-text-right">{{
          'shareMovement.avePrice' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let item" class="tw-flex-col tw-items-end">
          <div class="p-skeleton-bar">
            {{ 'portfolio.Buy' | translate }} - {{ item.averagePriceBought | number: '1.2-2' }}
          </div>
          <div class="p-skeleton-bar">
            {{ 'portfolio.Sell' | translate }} - {{ item.averagePriceSold | number: '1.2-2' }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ShareholderType">
        <mat-header-cell *matHeaderCellDef class="tw-items-start tw-justify-end tw-text-right">{{
          'shareMovement.shareholderType' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let item" class="tw-flex-col tw-items-end">
          <div
            [class]="
              'p-skeleton-bar tw-font-semibold ' + getShareholderTypeStyle(item.shareholderType)
            "
          >
            {{
              item.shareholderType === ShareholderType.Undetermined
                ? '-'
                : ShareholderType[item.shareholderType]
            }}
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayColumns"> </mat-header-row>

      <mat-row *matRowDef="let item; columns: displayColumns"> </mat-row>
    </mat-table>

    <div
      class="tw-w-full tw-py-4 tw-text-center"
      *ngIf="!isLoading && (!shareMovement || shareMovement.length === 0)"
    >
      No records found from {{ params.createdAtFrom | amDateFormat: dateFormat }} to
      {{ params.createdAtTo | amDateFormat: dateFormat }}
    </div>

    <div class="p-table__footer" *ngIf="total > params.take">
      <div class="p-table__pagination p-skeleton-pagination">
        <ngb-pagination
          [collectionSize]="total"
          [(pageSize)]="params.take"
          [(page)]="params.currentPage"
          (pageChange)="handlePageChange()"
          [maxSize]="5"
          [rotate]="true"
        >
          <ng-template ngbPaginationPrevious>
            <span class="p-table__pagination-icon">←</span>
            <div class="p-table__pagination-label tw-pl-2">{{ 'common.prev' | translate }}</div>
          </ng-template>
          <ng-template class="page-no" ngbPaginationNumber let-page>
            <div class="page-no">{{ page }}</div>
          </ng-template>
          <ng-template ngbPaginationNext>
            <div class="p-table__pagination-label tw-pr-2">{{ 'common.next' | translate }}</div>
            <span class="p-table__pagination-icon">→</span>
          </ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>
