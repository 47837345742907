import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent implements OnInit {
  @Input() width: number | string = '100%';
  @Input() height: number = 1;
  @Input() marginBottom: number = 0.625;
  @Input() className: string;

  computedWidth: string;
  computedHeight: string;
  computedMarginBottom: string;

  constructor() {}

  ngOnInit(): void {
    this.computedWidth = this.calculateComputedValue(this.width);
    this.computedHeight = this.calculateComputedValue(this.height);
    this.computedMarginBottom = this.calculateComputedValue(this.marginBottom);
  }

  private calculateComputedValue(value: number | string): string {
    if (typeof value === 'string') {
      return value;
    }

    return `${value}rem`;
  }
}
