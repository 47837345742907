import { Component } from '@angular/core';

@Component({
  selector: 'app-ieo-terms-conditions',
  templateUrl: './ieo-terms-conditions.component.html',
  styleUrls: ['./ieo-terms-conditions.component.scss'],
})
export class IeoTermsConditionsComponent {
  constructor() {}
}
