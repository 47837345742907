import { RaiseFundApplicationProcessModule } from '../../../core/enum/raise-fund-application-process-module.enum';

export class MyApplicationFilter {
  applicationId: number;
  selectedTab: RaiseFundApplicationProcessModule;

  constructor() {
    this.applicationId = null;
    this.selectedTab = RaiseFundApplicationProcessModule.FundRaiseApplication;
  }
}
