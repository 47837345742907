import { Attachment } from './../../../core/models/attachment/attachment';
import { UploadDocumentType } from './../../../core/enum/upload-document-type.enum';
import { PopupService } from '../../../shared/services/popup/popup.service';
import { Role } from '../../../core/enum/role.enum';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PDFFileFormat } from '../../../core/services/constant';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { AccountService } from '../../../core/services/api/account.service';
import { ContainerType } from '../../../core/enum/container-type.enum';
import { finalize } from 'rxjs/operators';
import { ErrorService } from '../../../core/services/error.service';
import { FileValidationService } from '../../../core/services/file-validation.service';
import { FileUploadOption } from '../../../core/models/attachment/file-upload-option';
import { AttachmentInfo } from '../../../core/models/attachment/attachment-info';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit, OnChanges {
  @Input() attachments: Attachment[];
  @Input() description: Attachment[];
  @Input() isHideDelete: boolean = false;
  @Input() fileTypes: string[] = PDFFileFormat;
  @Output() uploadDocument = new EventEmitter();
  @Output() deleteDocument = new EventEmitter();
  @Output() uploadOtherDocument = new EventEmitter();

  role: number;
  isLoading: boolean = false;
  documents: Attachment[];
  accept: string = this.fileTypes.toString();

  Role: typeof Role = Role;
  UploadDocumentType: typeof UploadDocumentType = UploadDocumentType;

  constructor(
    private activatedRoute: ActivatedRoute,
    private popupService: PopupService,
    private modalService: NgbModal,
    private accountService: AccountService,
    private errorService: ErrorService,
    private fileValidationService: FileValidationService,
  ) {}

  ngOnInit() {
    this.role = this.activatedRoute.snapshot.params.role;
  }

  ngOnChanges() {
    this.documents = this.attachments;
    this.accept = this.fileTypes.toString();
  }

  async selectFile(file: File, document: Attachment) {
    const uploadOption: FileUploadOption = {
      fileType: this.fileTypes,
      errorMsg: 'common.onlyPdfAccepted',
    };

    if (await this.fileValidationService.validFile(file, uploadOption)) {
      document.file = file;
      document.isUploading = true;

      this.accountService
        .uploadAttachmentPresignedURL(file, ContainerType.Company)
        .pipe(finalize(() => (document.isUploading = false)))
        .subscribe(
          (data) => {
            document.attachmentInfo = new AttachmentInfo(data);
            document.url = data.url;

            this.uploadDocument.emit(document);
          },
          (err) => {
            this.errorService.showError(err);
            document.file = null;
            document.attachmentInfo = null;
          },
        );
    }
  }

  removeDocument(document: Attachment, index: number) {
    this.popupService.confirm('company.confirmDeleteDocument').then(
      () => {
        if (document.attachmentType?.isAdditional) {
          this.documents.splice(index, 1);
        } else {
          document.file = null;
          this.deleteDocument.emit(document.id);
        }
      },
      () => {},
    );
  }

  viewSample(url: string) {
    window.open(url);
  }

  uploadAdditionalDocument(document: Attachment) {
    const option: any = {
      size: 'md',
      centered: true,
      windowClass: 'modal-550',
      backdropClass: 'backdrop-popup',
    };
    const modalRef = this.modalService.open(UploadDocumentComponent, option);
    modalRef.componentInstance.document = document;
    modalRef.result.then(
      (document) => {
        this.uploadOtherDocument.emit(document);
      },
      () => {},
    );
  }
}
