import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Campaign } from '../../../../core/models/campaign/campaign';
import { DefaultBanner } from '../../../../core/services/constant';
import { CampaignInfo } from '../../../../core/models/campaign/campaign-info';
import { BusinessDirectoryInfo } from '../../../../core/models/business-directory/business-directory-info';

@Component({
  selector: 'campaign-video',
  templateUrl: './campaign-video.component.html',
  styleUrls: ['./campaign-video.component.scss'],
})
export class CampaignVideoComponent implements OnChanges {
  defaultBanner = DefaultBanner;
  isLoading: boolean = true;

  @Input() campaign: Campaign;
  @Input() campaignInfo: CampaignInfo;
  @Input() businessDirectoryInfo: BusinessDirectoryInfo;
  @Input() isCampaignEnded: boolean;

  videoUrl: string;

  ngOnChanges(changes: SimpleChanges) {
    this.videoUrl = this.isCampaignEnded
      ? this.businessDirectoryInfo?.videoUrl
      : this.campaignInfo?.videoUrl;

    if (this.videoUrl) {
      this.isLoading = false;
    }
  }
}
