<section class="section section-md section-shaped">
  <div class="container-fluid">
    <div class="row title-row">
      <div class="col-lg-6 left-panel">
        <h1 class="p-text-headline !tw-mb-5" [innerHTML]="'whyInvest.pageTitle' | translate"></h1>
        <p class="mb-5 !tw-text-[1.2rem] tw-font-normal">
          {{ 'whyInvest.pageSubtitle' | translate }}
        </p>
        <div>
          <a
            *ngIf="!isLogin"
            [routerLink]="['/sign-up']"
            class="btn btn-primary !tw-mr-4 tw-text-base"
            data-t="signUpClick"
            data-t-location="page"
          >
            {{ 'whyInvest.becomeInvestor' | translate }}
          </a>
          <a
            [routerLink]="['/equity']"
            class="btn btn-link tw-px-0 tw-text-base tw-font-normal tw-underline"
          >
            {{ 'whyInvest.viewOpenInvestments' | translate }}
          </a>
        </div>
      </div>
      <div class="col-lg-6 tw-pt-10 lg:tw-pt-0">
        <img
          src="../../../assets/img/banner/why-invest-hero.png"
          class="tw-mx-auto tw-w-full tw-max-w-[500px]"
          alt="why-raise-pitchin"
        />
      </div>
    </div>
  </div>
</section>

<section class="section section-md section-stats">
  <div class="container-fluid">
    <div class="mb-4 tw-grid tw-grid-cols-2 tw-gap-[30px] lg:tw-grid-cols-4">
      <div class="tw-h-full">
        <div class="card tw-h-full tw-rounded-2xl tw-border tw-border-divider">
          <div class="card-body">
            <div class="price tw-text-[1.75rem] tw-font-bold tw-text-black">
              {{ stats.raised | numberSuffix: { isSuffix: true, numberFormat: '1.0-0' } }}+
              <div class="tw-pb-2 tw-text-lg tw-font-semibold">
                {{ 'landing.raised' | translate }}
              </div>
              <hr class="tw-my-3 md:tw-mb-5 md:tw-mt-5" />
              <div class="tw-text-sm tw-font-normal tw-text-[#6a6a6a]">
                {{ 'landing.inTotal' | translate: { month: 12 } }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-h-full">
        <div class="card tw-h-full tw-rounded-2xl tw-border tw-border-divider">
          <div class="card-body">
            <div class="price tw-text-[1.75rem] tw-font-bold tw-text-black">
              {{ stats.investors | number }}+
              <div class="tw-pb-2 tw-text-lg tw-font-semibold">
                {{ 'landing.investors' | translate }}
              </div>
              <hr class="tw-my-3 md:tw-mb-5 md:tw-mt-5" />
              <div class="tw-text-sm tw-font-normal tw-text-[#6a6a6a]">
                {{ 'landing.indiviualComunity' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-col-span-2 tw-h-full">
        <div class="card tw-h-full tw-rounded-2xl tw-border tw-border-divider">
          <div class="card-body">
            <div
              class="price tw-pb-2.5 tw-text-[1.75rem] tw-font-bold tw-leading-tight tw-text-black"
            >
              {{ 'whyInvest.rmo' | translate }}
            </div>
            <hr class="tw-my-3 md:tw-mb-5 md:tw-mt-4" />
            <div class="tw-text-sm tw-font-normal tw-text-[#6a6a6a]">
              {{ 'whyInvest.rmoDescription' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section">
  <div class="container-fluid tw-text-center">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h2 class="tw-font-bold">{{ 'whyInvest.earlyStageTitle' | translate }}</h2>
        <p
          class="mb-5 tw-mx-auto tw-max-w-[660px] tw-font-semibold tw-text-body tw-text-opacity-80"
          [innerHtml]="'whyInvest.earlyStageDesc' | translate"
        ></p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="pitchin-icon-why-invest-fire tw-mx-auto tw-mb-3 tw-h-24 tw-w-24"></div>
        <div class="tw-mb-2.5 tw-text-lg tw-font-semibold">
          {{ 'whyInvest.earlyStageSubtitle1' | translate }}
        </div>
        <p>{{ 'whyInvest.earlyStageDesc1' | translate }}</p>
      </div>
      <div class="col-md-6 col-lg-4">
        <div
          class="pitchin-icon-why-invest-low-investment tw-mx-auto tw-mb-3 tw-h-24 tw-w-24"
        ></div>
        <div class="tw-mb-2.5 tw-text-lg tw-font-semibold">
          {{ 'whyInvest.earlyStageSubtitle2' | translate }}
        </div>
        <p>{{ 'whyInvest.earlyStageDesc2' | translate }}</p>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="pitchin-icon-why-invest-diversify tw-mx-auto tw-mb-3 tw-h-24 tw-w-24"></div>
        <div class="tw-mb-2.5 tw-text-lg tw-font-semibold">
          {{ 'whyInvest.earlyStageSubtitle3' | translate }}
        </div>
        <p>{{ 'whyInvest.earlyStageDesc3' | translate }}</p>
      </div>
    </div>
  </div>
</section>

<section class="">
  <div
    class="container-fluid section--wave tw-relative tw-block tw-h-full tw-w-full tw-text-xl tw-font-semibold md:tw-text-center lg:tw-text-3xl"
  >
    <div
      class="tw-absolute tw-left-[60px] tw-top-[50px] tw-w-[180px] tw-text-primary sm:tw-w-[225px] md:tw-bottom-[150px] md:tw-left-[80px] md:tw-top-auto md:tw-w-[30%] lg:tw-left-[50px] xl:tw-bottom-[195px] xl:tw-left-[110px]"
    >
      Help create the next wave of success stories
    </div>
    <div
      class="tw-absolute tw-bottom-[50px] tw-left-[120px] tw-w-[150px] tw-text-secondary sm:tw-w-[200px] md:tw-bottom-auto md:tw-left-auto md:tw-right-[8%] md:tw-top-[45%] md:tw-w-[30%] xl:tw-top-[25%]"
    >
      By investing early, there’s potential for greater reward
    </div>
  </div>
</section>

<section class="section section--lg">
  <div class="container-fluid">
    <h2 class="mb-5 tw-text-center tw-font-bold">{{ 'whyInvest.startInvest' | translate }}</h2>

    <div class="row">
      <div class="col-md-6 col-lg-4 tw-mb-7 lg:tw-mb-0">
        <div class="tw-flex tw-gap-3">
          <div class="pitchin-icon-why-invest-hand-ok tw-h-16 tw-w-16 lg:tw-h-24 lg:tw-w-24"></div>
          <div class="tw-flex-1">
            <div class="tw-mb-1.5 tw-text-lg tw-font-semibold">
              {{ 'whyInvest.startInvestSubtitle1' | translate }}
            </div>
            <p>{{ 'whyInvest.startInvestDesc1' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 tw-mb-7 lg:tw-mb-0">
        <div class="tw-flex tw-gap-3">
          <div
            class="pitchin-icon-why-invest-hand-good tw-h-16 tw-w-16 lg:tw-h-24 lg:tw-w-24"
          ></div>
          <div class="tw-flex-1">
            <div class="tw-mb-1.5 tw-text-lg tw-font-semibold">
              {{ 'whyInvest.startInvestSubtitle2' | translate }}
            </div>
            <p>{{ 'whyInvest.startInvestDesc2' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="tw-flex tw-gap-3">
          <div
            class="pitchin-icon-why-invest-hand-high5 tw-h-16 tw-w-16 lg:tw-h-24 lg:tw-w-24"
          ></div>
          <div class="tw-flex-1">
            <div class="tw-mb-1.5 tw-text-lg tw-font-semibold">
              {{ 'whyInvest.startInvestSubtitle3' | translate }}
            </div>
            <p>{{ 'whyInvest.startInvestDesc3' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section section--lg tw-bg-secondary tw-bg-opacity-10">
  <div class="container-fluid">
    <h2 class="tw-font-bold">{{ 'whyInvest.howVetTitle' | translate }}</h2>
    <p class="mb-5 tw-font-normal">{{ 'whyInvest.howVetDesc' | translate }}</p>

    <div class="row">
      <div class="col-md-6 col-lg-3 tw-mb-7 lg:tw-mb-0">
        <div class="tw-flex tw-items-start tw-gap-3 lg:tw-block">
          <div class="tw-mb-2 tw-inline-block tw-rounded-full tw-bg-white tw-p-3 lg:tw-p-5">
            <div class="pitchin-icon-why-invest-report tw-h-8 tw-w-8 lg:tw-h-10 lg:tw-w-10"></div>
          </div>
          <div>
            <div class="tw-mb-1.5 tw-text-lg tw-font-semibold">
              {{ 'whyInvest.howVetSubtitle1' | translate }}
            </div>
            <p>{{ 'whyInvest.howVetDesc1' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 tw-mb-7 lg:tw-mb-0">
        <div class="tw-flex tw-items-start tw-gap-3 lg:tw-block">
          <div class="tw-mb-2 tw-inline-block tw-rounded-full tw-bg-white tw-p-3 lg:tw-p-5">
            <div
              class="pitchin-icon-why-invest-screening tw-h-8 tw-w-8 lg:tw-h-10 lg:tw-w-10"
            ></div>
          </div>
          <div>
            <div class="tw-mb-1.5 tw-text-lg tw-font-semibold">
              {{ 'whyInvest.howVetSubtitle2' | translate }}
            </div>
            <p>{{ 'whyInvest.howVetDesc2' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 tw-mb-7 lg:tw-mb-0">
        <div class="tw-flex tw-items-start tw-gap-3 lg:tw-block">
          <div class="tw-mb-2 tw-inline-block tw-rounded-full tw-bg-white tw-p-3 lg:tw-p-5">
            <div
              class="pitchin-icon-why-invest-due-diligence tw-h-8 tw-w-8 lg:tw-h-10 lg:tw-w-10"
            ></div>
          </div>
          <div>
            <div class="tw-mb-1.5 tw-text-lg tw-font-semibold">
              {{ 'whyInvest.howVetSubtitle3' | translate }}
            </div>
            <p>{{ 'whyInvest.howVetDesc3' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 tw-mb-7 lg:tw-mb-0">
        <div class="tw-flex tw-items-start tw-gap-3 lg:tw-block">
          <div class="tw-mb-2 tw-inline-block tw-rounded-full tw-bg-white tw-p-3 lg:tw-p-5">
            <div
              class="pitchin-icon-why-invest-campaign-live tw-h-8 tw-w-8 lg:tw-h-10 lg:tw-w-10"
            ></div>
          </div>
          <div>
            <div class="tw-mb-1.5 tw-text-lg tw-font-semibold">
              {{ 'whyInvest.howVetSubtitle4' | translate }}
            </div>
            <p>{{ 'whyInvest.howVetDesc4' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section section--lg tw-text-center">
  <div class="container-fluid">
    <h2 class="tw-font-bold">{{ 'whyInvest.dontMissTitle' | translate }}</h2>
    <p class="mb-5 tw-font-normal">{{ 'whyInvest.dontMissDesc' | translate }}</p>

    <ul
      class="tw-grid tw-list-none tw-grid-cols-2 tw-gap-5 tw-pl-0 tw-text-left sm:tw-mb-0 sm:tw-flex sm:tw-flex-wrap sm:tw-justify-center"
    >
      <li
        *ngFor="let company of featuredCompanies"
        class="tw-col-span-1 tw-flex tw-max-w-[250px] tw-flex-[0_0_250px]"
      >
        <div
          class="tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-between tw-rounded-lg tw-border tw-border-solid tw-border-divider tw-p-5"
        >
          <div>
            <img
              class="tw-h-16 tw-w-16 tw-rounded tw-object-contain tw-shadow-xl"
              [src]="company.companyLogoUrl"
              [alt]="company.brandName"
            />

            <div class="tw-mt-4 tw-font-bold tw-leading-snug tw-text-black">
              {{ company.brandName }}
            </div>
          </div>

          <div>
            <div class="tw-mb-3 tw-mt-5">
              <app-tag-list [tags]="company.sectors"></app-tag-list>
            </div>

            <div
              class="tw-grid tw-grid-cols-2 tw-divide-x tw-divide-y-0 tw-divide-solid tw-divide-divider"
            >
              <div>
                <div class="p-text-tag">
                  {{ 'campaign.raised' | translate }}
                </div>
                <div class="tw-text-md tw-font-bold tw-text-secondary-light md:tw-text-lg">
                  {{
                    company.stats.raised | numberSuffix: { isSuffix: true, numberFormat: '1.0-1' }
                  }}
                </div>
              </div>
              <div class="tw-pl-2.5">
                <div class="p-text-tag">
                  {{ 'campaign.investors' | translate }}
                </div>
                <div class="tw-text-md tw-font-bold tw-text-black md:tw-text-lg">
                  {{ company.stats.investors | number }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</section>

<section class="section tw-bg-secondary tw-bg-opacity-[0.03] sm:tw-pb-40">
  <div class="container-fluid">
    <h2 class="tw-mb-5 tw-text-center tw-font-bold">{{ 'whyInvest.faqTitle' | translate }}</h2>

    <div class="row tw-flex tw-flex-col tw-items-center">
      <app-json-ld [itemLd]="faqLd"></app-json-ld>
      <app-faq-accordion [accordionItems]="pageFaqs" [showTitle]="false"></app-faq-accordion>
    </div>
  </div>
</section>

<section
  *ngIf="!isLogin"
  class="section section--pitchin section--lg tw-bg-secondary tw-text-white lg:tw-text-center"
>
  <div class="container-fluid">
    <h2 class="tw-font-bold tw-text-white">{{ 'whyInvest.becomeInvestorTitle' | translate }}</h2>

    <div class="tw-mx-auto tw-mt-5 tw-text-left lg:tw-max-w-[240px]">
      <div class="tw-mb-2 tw-font-bold">
        {{ 'whyInvest.becomeInvestorRequirement' | translate }}
      </div>
      <ul class="tw-pl-5 lg:tw-text-left">
        <li>{{ 'whyInvest.becomeInvestorRequirement1' | translate }}</li>
        <li>{{ 'whyInvest.becomeInvestorRequirement2' | translate }}</li>
        <li>{{ 'whyInvest.becomeInvestorRequirement3' | translate }}</li>
      </ul>
    </div>
    <a
      class="btn btn-outline-white tw-mx-auto tw-mt-10"
      [routerLink]="['/sign-up']"
      data-t="signUpClick"
      data-t-location="page"
      >{{ 'whyInvest.becomeInvestor' | translate }}</a
    >
  </div>
</section>
