export class QuestionBase<T> {
  value: T;
  key: string;
  label: string;
  required: boolean;
  controlType: string;
  type: string;
  options: { key: string; value: string }[];
  class?: string;
  readonly?: boolean;
  description?: string;
  pattern?: string;
  isToday?: boolean;
  hoverText?: string;
  placeholder?: string;
  allowNegatives?: boolean;

  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      required?: boolean;
      controlType?: string;
      type?: string;
      options?: { key: string; value: string }[];
      class?: string;
      readonly?: boolean;
      description?: string;
      pattern?: string;
      isToday?: boolean;
      hoverText?: string;
      placeholder?: string;
      allowNegatives?: boolean;
    } = {},
  ) {
    this.value = options.value
      ? options.type == 'number'
        ? parseFloat(options.value.toString())
        : options.type == 'array'
          ? JSON.parse(options.value.toString())
          : options.value
      : null;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.options = options.options || [];
    this.class = options.class || 'col-lg-12';
    this.readonly = options.readonly || false;
    this.description = options.description || null;
    this.pattern = options.pattern || null;
    this.isToday = options.isToday || false;
    this.hoverText = options.hoverText || '';
    this.placeholder = options.placeholder || '';
    this.allowNegatives = options.allowNegatives || false;
  }
}
