import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platform: Object,
  ) {}

  filterProps(obj: object, omittedProperties?: Array<string>) {
    if (!omittedProperties) return obj;

    // Some params in query string is not needed, can use this function to remove it
    return Object.keys(obj)
      .filter((key) => !omittedProperties.includes(key))
      .reduce((o, k) => {
        o[k] = obj[k];
        return o;
      }, {});
  }

  getParam(queryKey: string): any {
    let param;

    this.route.queryParams.subscribe((p) => {
      param = p[queryKey];
    });

    return param;
  }

  getParams(): any {
    let params;

    this.route.queryParams.subscribe((p) => {
      params = p;
    });

    return params;
  }

  updateUrl(queryParams: object, omittedProperties?: Array<string>) {
    // Update the query string without reloading
    // credit to: https://stackoverflow.com/a/50830593/3141298
    // this.router.navigate([], {
    //     relativeTo: this.route,
    //     queryParams: this.filterProps(queryParams, omittedProperties),
    //     replaceUrl: true,
    // });

    if (isPlatformBrowser(this.platform)) {
      this.updateUrlParams(this.filterProps(queryParams, omittedProperties));
    }
  }

  // Use JS function to update URL to prevent the page scrollTop whenever update Url
  updateUrlParams(params) {
    const searchParams = new URLSearchParams(window.location.search);

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        searchParams.set(key, params[key]);
      }
    }

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;

    window.history.replaceState(null, '', newUrl);
  }
}
