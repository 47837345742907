<div
  class="pitchin-icon pitchin-icon-close tw-absolute -tw-right-2 -tw-top-2 tw-z-[2]"
  (click)="close()"
></div>

<div class="modal-body text-center p-5">
  <p-icon name="limit" iconClass="!tw-w-20 !tw-h-20 tw-text-black tw-mb-5"></p-icon>

  <h1 class="tw-font-body tw-text-xl tw-font-bold">
    {{
      'investment.investmentExceededLimitTitle'
        | translate
          : {
              investorType:
                'investment.investorType.' + InvestorType[currentInvestorType] | translate,
            }
    }}
  </h1>

  <div class="tw-mb-10">
    <span class="tw-block">
      {{
        (exceedLimitType === 'campaign'
          ? 'investment.annualLimitPerIssuer'
          : 'investment.annualLimit'
        ) | translate
      }}:
      <span>{{ investmentLimitConfig?.InvestmentLimit | numberSuffix }}</span>
    </span>
    <span class="tw-block tw-text-primary">
      {{ 'investment.remainingBalanceAllowed' | translate }}:
      <span class="tw-font-bold">{{ investmentLimitConfig?.RemainingBalance | numberSuffix }}</span>
    </span>
  </div>

  <h2 class="tw-font-body tw-text-base tw-font-bold">
    {{
      'investment.areYouTitle'
        | translate
          : {
              investorType:
                'investment.investorTypeWithArticles.' + InvestorType[recommendedInvestorType]
                | translate,
            }
    }}
  </h2>

  <div
    class="tw-mb-5 tw-text-left tw-text-base"
    [innerHTML]="'investment.investorCriteria.' + InvestorType[recommendedInvestorType] | translate"
  ></div>

  <div class="tw-mb-10">
    <a
      class="p-link-primary p-link-underline tw-text-sm"
      tabindex="-1"
      [routerLink]="['/learn/invest/investment-requirements-and-limitations-investors']"
      target="_blank"
    >
      {{ 'common.moreInfo' | translate }} →
    </a>
  </div>

  <div class="tw-flex tw-flex-col tw-gap-2.5">
    <button
      type="button"
      data-t="popupButton"
      class="btn btn-primary font-weight-extrabold !tw-mr-0 !tw-whitespace-normal !tw-leading-snug"
      (click)="confirm(true)"
      [innerHTML]="
        'investment.investmentExceededCta'
          | translate
            : {
                investorType:
                  'investment.investorType.' + InvestorType[recommendedInvestorType] | translate,
              }
      "
    ></button>
    <button
      type="button"
      data-t="popupButton"
      class="btn btn-primary-border font-weight-extrabold"
      (click)="close()"
    >
      {{ 'investment.investmentExceededCancelBtn' | translate }}
    </button>
  </div>
</div>
