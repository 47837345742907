import { PaymentType } from '../../enum/payment-type.enum';
import { AttachmentInfo } from '../attachment/attachment-info';
import { Payment } from './payment';

export class PaymentModel {
  PaymentType: PaymentType;
  AttachmentInfo: AttachmentInfo;
  EmailAddress: string;
  BusinessModel: string;
  BankID: string;
  amount: number;
  tickerCode: string;

  constructor(model: Payment) {
    this.PaymentType = model.paymentType;

    if (this.PaymentType === PaymentType.MaybankFPX || this.PaymentType === PaymentType.DuitNow) {
      this.EmailAddress = model.emailAddress;
      this.BusinessModel = model.businessModel;
      this.BankID = model.bankID;
      this.tickerCode = model.tickerCode;
    } else {
      this.AttachmentInfo = model.attachmentInfo;
    }
  }
}
