<section
  class="tw-mx-auto tw-mt-8 tw-flex tw-max-w-[1280px] tw-flex-col tw-items-center tw-gap-4 tw-px-4 md:tw-flex-row md:tw-justify-between md:tw-py-4"
>
  <div class="tw-flex tw-flex-col tw-gap-4">
    <h1
      class="tw-m-0 tw-text-left tw-text-3xl tw-font-bold tw-tracking-wide tw-text-black md:tw-text-4xl"
    >
      Invest the <span class="tw-text-ieo-green">Shariah-compliant</span> way
    </h1>
    <p class="tw-m-0 tw-max-w-xl tw-text-sm tw-text-black md:tw-text-base">
      Discover Shariah-compliant investment opportunities that uphold your values, providing
      transparency and confidence in every decision you make
    </p>
    <a
      class="tw-w-fit tw-rounded tw-bg-primary tw-px-4 tw-py-2 tw-text-sm tw-font-semibold tw-text-white hover:tw-text-white md:tw-text-base"
      [routerLink]="['/equity']"
      [queryParams]="{ sectorIds: 333 }"
      routerLinkActive="router-link-active"
      >Browse campaigns</a
    >
  </div>
  <img src="/assets/img/hero/shariah-hero.svg" alt="shariah compliant" />
</section>

<section
  class="tw-mx-auto tw-flex tw-max-w-[1280px] tw-flex-col tw-items-center tw-gap-4 tw-px-4 tw-py-12"
>
  <h3 class="tw-font-body tw-text-sm tw-uppercase tw-tracking-wide tw-text-gray-400">
    About shariah compliance
  </h3>
  <h2 class="tw-m-0 tw-text-center tw-text-3xl tw-font-semibold tw-text-blue">
    What is Shariah-compliant investment?
  </h2>
  <p class="tw-m-0 tw-text-center tw-text-sm md:tw-text-base">
    All the financial transactions must comply and conform to the following Islamic rules and
    principles
  </p>

  <ul
    class="tw-m-0 tw-flex tw-w-full tw-list-none tw-flex-col tw-gap-4 tw-p-0 md:tw-flex-row md:tw-flex-wrap md:tw-justify-center"
  >
    <li
      *ngFor="let principle of principles"
      class="tw-flex tw-w-full tw-flex-col tw-gap-4 tw-rounded-md tw-border tw-border-gray-400 tw-p-4 md:tw-w-96"
    >
      <img
        [src]="'/assets/img/icons/svgs/' + principle.imgSrc"
        [alt]="principle.title"
        class="tw-w-24"
      />
      <h4 class="tw-font-body tw-text-xl tw-font-semibold">{{ principle.title }}</h4>
      <p class="tw-m-0 tw-text-sm tw-text-black md:tw-text-base">{{ principle.description }}</p>

      <section class="tw-grid tw-grid-cols-2 tw-gap-2">
        <ul class="tw-m-0 tw-flex tw-list-none tw-flex-col tw-gap-2 tw-p-0">
          <li *ngFor="let positive of principle.positives" class="tw-flex tw-items-start tw-gap-2">
            <img
              src="/assets/img/icons/svgs/green-tick.svg"
              alt="green tick"
              class="tw-h-5"
              class="tw-text-blue"
            />
            <p class="tw-m-0 tw-text-sm md:tw-text-base">
              {{ positive }}
            </p>
          </li>
        </ul>
        <ul class="tw-m-0 tw-flex tw-list-none tw-flex-col tw-gap-2 tw-p-0">
          <li *ngFor="let negative of principle.negatives" class="tw-flex tw-items-start tw-gap-2">
            <img src="/assets/img/icons/svgs/red-cross.svg" alt="red cross" class="tw-h-6" />
            <p class="tw-m-0 tw-text-sm md:tw-text-base">
              {{ negative }}
            </p>
          </li>
        </ul>
      </section>
    </li>
  </ul>
</section>

<div class="tw-w-full tw-bg-blue">
  <section class="tw-mx-auto tw-flex tw-max-w-4xl tw-flex-col tw-gap-8 tw-py-12">
    <div class="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-p-4">
      <h3 class="tw-m-0 tw-font-body tw-text-sm tw-uppercase tw-tracking-wide tw-text-gray-200">
        ADHERING TO STANDARDS
      </h3>
      <h2 class="tw-m-0 tw-text-center tw-text-3xl tw-font-semibold tw-text-white">
        What makes a campaign Shariah compliant?
      </h2>
      <p class="tw-m-0 tw-text-center tw-text-sm tw-text-white md:tw-text-base">
        Our Shariah advisors carefully review the Securities Commission of Malaysia's toolkit to
        ensure compliance with Islamic principles
      </p>
    </div>
    <div class="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-p-4 md:tw-items-start">
      <h2
        class="tw-m-0 tw-mb-2 tw-text-center tw-font-body tw-text-xl tw-font-bold tw-tracking-wide tw-text-white md:tw-text-left md:tw-text-2xl"
      >
        Business or activity screening
      </h2>
      <p class="tw-m-0 tw-text-center tw-text-sm tw-text-white md:tw-text-left md:tw-text-base">
        An Issuer's revenue or profit before tax (PBT) from non Shariah-compliant activities must
        stay within set benchmarks:
      </p>

      <article
        class="tw-flex tw-w-full tw-flex-col tw-gap-4 tw-rounded-lg tw-border tw-border-white"
      >
        <div class="tw-divide-y tw-p-4 md:tw-flex md:tw-divide-x md:tw-divide-y-0">
          <section
            class="tw-flex tw-flex-col tw-items-start tw-gap-4 tw-p-4"
            *ngFor="let item of businessScreening"
          >
            <h5 class="tw-m-0 tw-font-body tw-text-4xl tw-font-bold tw-text-primary md:tw-text-5xl">
              {{ item.percentage }}
            </h5>
            <p class="tw-m-0 tw-text-sm tw-text-white md:tw-text-base">
              Applicable to the following businesses/activities:
            </p>
            <ol>
              <li *ngFor="let activity of item.activities" class="tw-text-white">{{ activity }}</li>
            </ol>
          </section>
        </div>

        <section class="tw-border-t tw-border-t-white tw-p-4">
          <a
            [routerLink]="['/learn/invest/shariah-compliant-equity-crowdfunding-ecf-investors']"
            routerLinkActive="router-link-active"
            class="tw-text-primary"
            >Read more &#8594;</a
          >
        </section>
      </article>
    </div>

    <div class="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-p-4 md:tw-items-start">
      <h2
        class="tw-m-0 tw-mb-2 tw-text-center tw-font-body tw-text-xl tw-font-bold tw-tracking-wide tw-text-white md:tw-text-left md:tw-text-2xl"
      >
        Financial ratio screening
      </h2>
      <p class="tw-m-0 tw-text-center tw-text-sm tw-text-white md:tw-text-left md:tw-text-base">
        The interest and interest-based elements in the Issuer's balance sheet must not exceed the
        specified benchmarks:
      </p>

      <article
        class="tw-flex tw-w-full tw-flex-col tw-gap-4 tw-rounded-lg tw-border tw-border-white"
      >
        <div class="tw-divide-y tw-p-4 md:tw-flex md:tw-divide-x md:tw-divide-y-0">
          <section class="tw-flex tw-flex-1 tw-items-start tw-gap-4 tw-border-opacity-5 tw-p-4">
            <h5 class="tw-m-0 tw-font-body tw-text-4xl tw-font-bold tw-text-primary md:tw-text-5xl">
              &lt;33%
            </h5>
            <p class="tw-m-0 tw-text-sm tw-text-white md:tw-text-xl">
              Cash over <br />total assets
            </p>
          </section>
          <section class="tw-flex tw-flex-1 tw-items-start tw-gap-4 tw-border-opacity-5 tw-p-4">
            <h5 class="tw-m-0 tw-font-body tw-text-4xl tw-font-bold tw-text-primary md:tw-text-5xl">
              &lt;49%
            </h5>
            <p class="tw-m-0 tw-text-sm tw-text-white md:tw-text-xl">
              Debt over <br />total assets
            </p>
          </section>
        </div>

        <section class="tw-border-t tw-border-t-white tw-p-4">
          <p class="tw-m-0 tw-text-sm tw-text-white md:tw-text-base">
            Only cash in conventional accounts is included. Cash in Shariah-compliant accounts is
            excluded.
          </p>
        </section>
      </article>
    </div>
  </section>
</div>

<section
  class="tw-mx-auto tw-flex tw-max-w-[1280px] tw-flex-col tw-items-center tw-gap-4 tw-p-4 tw-py-12"
>
  <h3 class="tw-font-body tw-text-sm tw-uppercase tw-tracking-wide tw-text-gray-400">
    WHO IS IT FOR
  </h3>
  <h2 class="tw-m-0 tw-text-center tw-text-3xl tw-font-semibold tw-text-blue">
    Ethical investing is for everyone
  </h2>

  <ul class="tw-m-0 tw-flex tw-w-full tw-list-none tw-flex-col tw-gap-4 tw-p-0">
    <li
      *ngFor="let guideline of investmentGuidelines"
      class="tw-flex tw-w-full tw-flex-col tw-gap-4 tw-rounded-md tw-border tw-border-gray-300 tw-p-4 md:tw-items-center md:tw-gap-8"
      [class]="guideline.isPositive ? 'md:tw-flex-row' : 'md:tw-flex-row-reverse'"
    >
      <div class="tw-flex-1">
        <h4 class="tw-mb-4 tw-font-body tw-text-xl tw-font-semibold">{{ guideline.question }}</h4>
        <p class="tw-m-0 tw-text-sm tw-text-black md:tw-text-base">{{ guideline.description }}</p>
      </div>

      <section class="tw-mb-4 tw-mt-8 tw-flex tw-flex-col tw-gap-4 md:tw-mt-4 md:tw-max-w-md">
        <div
          class="tw-flex tw-w-full tw-gap-2 tw-rounded-full tw-border tw-border-gray-300 tw-px-4 tw-py-2"
        >
          <div class="pitchin-icon pitchin-icon-search"></div>
          <p class="tw-m-0 tw-text-sm tw-font-semibold tw-text-black md:tw-text-base">
            {{ guideline.category }}
          </p>
        </div>

        <ul class="tw-m-0 tw-flex tw-list-none tw-flex-wrap tw-gap-2 tw-p-0">
          <li
            *ngFor="let impact of guideline.impacts"
            class="tw-flex tw-items-center tw-gap-2 tw-rounded-full tw-px-4 tw-py-1"
            [class]="guideline.isPositive ? 'tw-bg-[#13BE5A]' : 'tw-bg-primary'"
          >
            <p
              class="tw-m-0 tw-text-xs tw-font-medium tw-tracking-wide tw-text-white md:tw-text-base"
            >
              {{ impact }}
            </p>
            <img
              [src]="
                '/assets/img/icons/svgs/' +
                (guideline.isPositive ? 'white-tick.svg' : 'white-cross.svg')
              "
              alt="green tick"
              class="tw-h-6"
            />
          </li>
        </ul>
      </section>
    </li>
  </ul>
</section>

<div class="tw-w-full tw-bg-[#F6F3F0]">
  <section
    class="tw-mx-auto tw-flex tw-max-w-[1280px] tw-flex-col tw-items-center tw-gap-4 tw-px-4 tw-py-12"
  >
    <h3 class="tw-font-body tw-text-sm tw-uppercase tw-tracking-wide tw-text-gray-400">
      FOR INVESTORS
    </h3>
    <h2 class="tw-text-center tw-text-3xl tw-font-semibold tw-text-blue">
      Benefits of investing in <span class="tw-text-primary">Shariah-compliant</span> investments
    </h2>

    <ul
      class="tw-m-0 tw-flex tw-w-full tw-list-none tw-flex-col tw-gap-4 tw-p-0 md:tw-grid md:tw-grid-cols-2"
    >
      <li
        *ngFor="let benefit of benefits"
        class="gradient-border tw-flex tw-w-full tw-flex-col tw-gap-4 tw-p-4"
      >
        <img
          [src]="'/assets/img/icons/svgs/' + benefit.imgSrc"
          [alt]="benefit.title"
          class="tw-w-24"
        />
        <h4 class="tw-font-body tw-text-xl tw-font-semibold">{{ benefit.title }}</h4>
        <p class="tw-m-0 tw-text-sm tw-text-black md:tw-text-base">{{ benefit.description }}</p>
      </li>
    </ul>
  </section>
</div>

<section
  class="tw-mx-auto tw-flex tw-max-w-[1280px] tw-flex-col tw-items-center tw-gap-4 tw-px-4 tw-py-12"
>
  <h3 class="tw-font-body tw-text-sm tw-uppercase tw-tracking-wide tw-text-gray-400">
    FOR FOUNDERS
  </h3>
  <h2 class="tw-m-0 tw-text-center tw-text-3xl tw-font-semibold tw-text-blue">
    Why fundraise a Shariah-compliant campaign?
  </h2>
  <a
    class="tw-mb-4 tw-mt-8 tw-w-fit tw-rounded tw-border tw-border-primary tw-px-4 tw-py-2 tw-text-base tw-font-semibold tw-text-primary hover:tw-text-primary"
    [routerLink]="handleStartApplication()"
    routerLinkActive="router-link-active"
    *ngIf="role === Role.IssuerRep || role === null"
    >Start a campaign application &rarr;</a
  >

  <ul
    class="tw-m-0 tw-flex tw-w-full tw-list-none tw-flex-col tw-gap-4 tw-p-0 md:tw-grid md:tw-grid-cols-2"
  >
    <li
      *ngFor="let benefit of founderBenefits"
      class="tw-flex tw-w-full tw-flex-col tw-gap-4 tw-rounded-md tw-border tw-border-gray-400 tw-p-4"
    >
      <img
        [src]="'/assets/img/icons/svgs/' + benefit.imgSrc"
        [alt]="benefit.title"
        class="tw-w-20"
      />
      <h4 class="tw-font-body tw-text-xl tw-font-semibold">{{ benefit.title }}</h4>
      <p class="tw-m-0 tw-text-sm tw-text-black md:tw-text-base">{{ benefit.description }}</p>
    </li>
  </ul>
</section>

<div class="tw-w-full tw-bg-blue">
  <section
    class="tw-mx-auto tw-flex tw-max-w-[1280px] tw-flex-col tw-items-center tw-gap-4 tw-px-4 tw-py-12 md:tw-flex-row md:tw-justify-between md:tw-py-20"
  >
    <div class="tw-flex tw-max-w-2xl tw-flex-col tw-items-center tw-gap-4 md:tw-items-start">
      <h3 class="tw-font-body tw-text-sm tw-uppercase tw-tracking-wide tw-text-gray-200">
        IDENTIFYING SHARIAH COMPLIANT CAMPAIGNS
      </h3>
      <h2 class="tw-m-0 tw-text-center tw-text-3xl tw-font-semibold tw-text-white md:tw-text-left">
        How to spot Shariah-compliant campaigns on our website?
      </h2>
      <p class="tw-m-0 tw-text-center tw-text-sm tw-text-white md:tw-text-left md:tw-text-base">
        Simply look for the tag '<b>Shariah-compliant</b>' when browsing campaigns
      </p>
      <a
        class="tw-mb-4 tw-mt-8 tw-w-fit tw-rounded tw-bg-primary tw-px-4 tw-py-2 tw-font-semibold tw-text-white hover:tw-text-white"
        [routerLink]="['/equity']"
        [queryParams]="{ sectorIds: 333 }"
        routerLinkActive="router-link-active"
        >Browse campaigns &rarr;</a
      >
    </div>

    <img
      src="/assets/img/icons/svgs/shariah-blur-campaign.svg"
      alt="shariah campaign card"
      class="tw-w-full tw-max-w-md md:tw-hidden"
    />

    <img
      src="/assets/img/icons/svgs/shariah-blur-campaign-desktop.svg"
      alt="shariah campaign card"
      class="tw-hidden tw-w-80 md:tw-flex"
    />
  </section>
</div>

<section
  class="tw-mx-auto tw-flex tw-max-w-[1280px] tw-flex-col tw-items-center tw-gap-4 tw-px-4 tw-py-12"
>
  <h3 class="tw-font-body tw-text-sm tw-uppercase tw-tracking-wide tw-text-gray-400">
    OUR ASSURANCE
  </h3>
  <h2 class="tw-m-0 tw-text-center tw-text-3xl tw-font-semibold tw-text-blue">
    Leave <span class="tw-text-primary">no room for doubt</span> in your portfolio
  </h2>
  <p class="tw-m-0 tw-max-w-xl tw-text-center tw-text-sm md:tw-mb-8 md:tw-text-base">
    We guarantee that all Shariah-compliant investment opportunities fully meet regulatory
    standards, offering you peace of mind with every investment
  </p>

  <ul
    class="tw-m-0 tw-flex tw-w-full tw-list-none tw-flex-col tw-gap-2 tw-p-0 md:tw-grid md:tw-grid-cols-3"
  >
    <li
      *ngFor="let assurance of assurances"
      class="tw-flex tw-w-full tw-flex-col tw-gap-4 tw-rounded-md tw-border tw-border-gray-400 tw-p-4"
    >
      <img
        [src]="'/assets/img/icons/svgs/' + assurance.imgSrc"
        [alt]="assurance.title"
        class="tw-w-20"
      />
      <h4 class="tw-m-0 tw-font-body tw-text-xl tw-font-semibold">{{ assurance.title }}</h4>
      <p class="tw-m-0 tw-text-sm tw-text-black md:tw-text-base">{{ assurance.description }}</p>
    </li>
  </ul>
</section>

<section class="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-bg-gray-100 tw-px-4 tw-py-12">
  <h2 class="p-text-h3 tw-mb-8 tw-text-center">Frequently asked questions</h2>
  <app-faq-accordion [accordionItems]="pageFaqs" [showTitle]="false" headerClass="tw-bg-white" />
</section>
