import { ErrorService } from '../../../core/services/error.service';
import { AccountService } from '../../../core/services/api/account.service';
import { Representative } from '../../../core/models/user/representative';
import { PopupService } from '../../../shared/services/popup/popup.service';
import { Role } from '../../../core/enum/role.enum';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { finalize } from 'rxjs/operators';
import {
  DefaultEmailPattern,
  ICMaskFormat,
  ImageFileFormat,
  PassportFormat,
} from '../../../core/services/constant';
import { IdentificationType } from '../../../core/enum/identification-type.enum';
import { ContainerType } from '../../../core/enum/container-type.enum';
import { FileValidationService } from '../../../core/services/file-validation.service';
import { FileUploadOption } from '../../../core/models/attachment/file-upload-option';
import { AttachmentInfo } from '../../../core/models/attachment/attachment-info';

@Component({
  selector: 'app-issuer-representative',
  templateUrl: './issuer-representative.component.html',
  styleUrls: ['./issuer-representative.component.scss'],
})
export class IssuerRepresentativeComponent implements OnInit {
  @Output() submitForm = new EventEmitter();
  @Input() role: Role;

  showFormError: boolean = false;
  form: UntypedFormGroup;
  isLoading: boolean = false;
  profileImage: any;
  issuerRep: Representative;
  accept: string = ImageFileFormat.toString();
  icMaskFormat = ICMaskFormat;
  attachmentInfo: AttachmentInfo;

  IdentificationType: typeof IdentificationType = IdentificationType;
  Role: typeof Role = Role;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private popupService: PopupService,
    private sanitizer: DomSanitizer,
    private accountService: AccountService,
    private errorService: ErrorService,
    private fileValidationService: FileValidationService,
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      userIdentityNoType: [IdentificationType.MyKad, Validators.required],
      fullName: ['', Validators.required],
      identityNo: ['', Validators.required],
      representatives: this.formBuilder.array([this.buildRepForm()]),
    });

    this.getBasicProfile();
  }

  buildRepForm() {
    return this.formBuilder.group({
      userIdentityNoType: [IdentificationType.MyKad, Validators.required],
      fullName: ['', Validators.required],
      email: [
        '',
        Validators.compose([Validators.required, Validators.pattern(DefaultEmailPattern)]),
      ],
      identityNo: ['', Validators.required],
    });
  }

  get repFormControl() {
    return <UntypedFormArray>this.form.controls.representatives;
  }

  addRep() {
    if (this.form.controls.representatives.invalid) {
      this.showFormError = true;
      return;
    }

    if (this.repFormControl.controls.length < 3) {
      this.repFormControl.push(this.buildRepForm());
      this.showFormError = false;
    }
  }

  removeRep(index: number) {
    if (this.repFormControl.controls.length > 0) this.repFormControl.removeAt(index);
  }

  submit() {
    if (this.form.invalid) {
      this.popupService.alert('common.missingFieldsAlert');
      this.showFormError = true;
      return;
    }

    let representative = this.form.value;
    representative.attachmentInfo = this.attachmentInfo;

    this.submitForm.emit(this.form.value);
  }

  async selectFile(file: File) {
    const uploadOption: FileUploadOption = {
      fileType: ImageFileFormat,
      errorMsg: 'common.onlyImageAccepted',
    };

    if (await this.fileValidationService.validFile(file, uploadOption)) {
      this.isLoading = true;
      this.profileImage = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));

      this.accountService
        .uploadAttachmentPresignedURL(file, ContainerType.ProfilePicture)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          (data) => {
            this.profileImage = data.url;
            this.attachmentInfo = new AttachmentInfo(data);
          },
          (err) => {
            this.errorService.showError(err);
            this.profileImage = null;
            this.attachmentInfo = null;
          },
        );
    }
  }

  changeIdentificationType(form: UntypedFormGroup) {
    let type = form.value.userIdentityNoType;

    if (type != IdentificationType.Passport) {
      form.controls.identityNo.setValidators([Validators.required]);
      form.controls.identityNo.updateValueAndValidity();
    } else {
      form.controls.identityNo.setValidators([
        Validators.required,
        Validators.pattern(PassportFormat),
      ]);
      form.controls.identityNo.updateValueAndValidity();
    }
  }

  getBasicProfile() {
    this.accountService.profile(true).subscribe(
      (profile) => {
        this.form.patchValue(profile);

        if (!this.form.value.userIdentityNoType)
          this.form.controls.userIdentityNoType.setValue(IdentificationType.MyKad);

        this.profileImage = profile.photoAttachmentUrl;
      },
      (err) => this.errorService.showError(err),
    );
  }
}
