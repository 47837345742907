import { Component } from '@angular/core';

@Component({
  selector: 'app-equity-crowdfunding',
  templateUrl: './equity-crowdfunding.component.html',
  styleUrls: ['./equity-crowdfunding.component.scss'],
})
export class EquityCrowdfundingComponent {
  visibleSection: string = 'equity-crowdfunding';

  constructor() {}

  onSectionChange(sectionId: string) {
    this.visibleSection = sectionId || 'equity-crowdfunding';
  }
}
