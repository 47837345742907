import { Component, Input } from '@angular/core';
import { DefaultLogo } from '../../../../core/services/constant';
@Component({
  selector: 'app-shares-pop-up',
  templateUrl: './shares-pop-up.component.html',
  styleUrls: ['./shares-pop-up.component.css'],
})
export class SharesPopUpComponent {
  @Input() size: 'lg' | 'sm' = 'sm';
  @Input() showImage: boolean = true;
  @Input() imageUrl: string;

  @Input() name: string;
  @Input() description: string;
  @Input() companyName: string;
  @Input() companyDescription: string;
  @Input() counterName: string;
  @Input() counterDescription: string;
  @Input() isMoratorium: boolean;

  @Input() nameClass: string;
  @Input() descriptionClass: string;

  defaultLogo = DefaultLogo;
}
