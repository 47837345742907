import { DefaultLogo, YEAR_FORMATS } from '../../core/services/constant';
import { InvestmentType } from '../../core/enum/investment-type.enum';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { AccountService } from '../../core/services/api/account.service';
import { finalize } from 'rxjs/operators';
import { ErrorService } from '../../core/services/error.service';
import { CampaignStatus } from '../../core/enum/campaign-status.enum';
import { Campaign } from '../../core/models/campaign/campaign';
import { CampaignDetailApprovalStatus } from '../../core/enum/campaign-detail-approval-status';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-my-campaign',
  templateUrl: './my-campaign.component.html',
  styleUrls: ['./my-campaign.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: YEAR_FORMATS }],
})
export class MyCampaignComponent implements OnInit {
  public ieoCampaignUrl = environment.ieoBaseUrl + '/issuer/campaigns';
  campaigns: Campaign[];
  selectedType: InvestmentType = InvestmentType.ECF;
  params = {
    currentPage: 1,
    take: 12,
  };
  isLoading: boolean = false;
  total: number = 0;
  defaultLogo = DefaultLogo;

  InvestmentType: typeof InvestmentType = InvestmentType;
  CampaignStatus: typeof CampaignStatus = CampaignStatus;
  CampaignDetailApprovalStatus: typeof CampaignDetailApprovalStatus = CampaignDetailApprovalStatus;

  constructor(
    private accountService: AccountService,
    private errorService: ErrorService,
  ) {}

  ngOnInit() {
    this.getList(true);
  }

  getList(reload?: boolean) {
    if (reload) this.params.currentPage = 1;

    this.isLoading = true;
    this.campaigns = [];

    this.accountService
      .getMyCampaigns(this.params.currentPage, this.params.take)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.campaigns = data.data;
          this.total = data.total;

          this.campaigns.forEach((campaign) => {
            campaign.showDetails = false;
          });
        },
        error: (err) => this.errorService.showError(err),
      });
  }

  changeTab(selectedType: InvestmentType) {
    this.selectedType = selectedType;
  }

  showDetails(campaign: Campaign) {
    campaign.showDetails = !campaign.showDetails;
  }
}
