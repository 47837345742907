// credit to: https://itnext.io/checking-out-angulars-cdk-portals-d34bfd8a5647
import { CdkPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-campaign-filters-portal',
  templateUrl: './campaign-filters-portal.component.html',
  styleUrls: ['./campaign-filters-portal.component.scss'],
})
export class CampaignFiltersPortalComponent implements AfterViewInit, OnDestroy {
  @Input() portalName: string;

  @ViewChild(CdkPortal)
  private portal: CdkPortal;
  private host: DomPortalOutlet;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector,
    @Inject(PLATFORM_ID) private platform: Object,
  ) {}

  ngAfterViewInit(): void {
    this.host = new DomPortalOutlet(
      document.querySelector(this.portalName),
      this.componentFactoryResolver,
      this.applicationRef,
      this.injector,
    );

    this.host.attach(this.portal);
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platform)) this.host.detach();
  }
}
