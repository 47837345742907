import { Component, HostListener, Input } from '@angular/core';
import { FloatingMenu } from '../../models/floating-menu/floating-menu';

@Component({
  selector: 'app-floating-menu',
  templateUrl: './floating-menu.component.html',
  styleUrls: ['./floating-menu.component.scss'],
})
export class FloatingMenuComponent {
  @Input() floatingMenus: FloatingMenu[];
  @Input() selectedMenu: string;
  hideNavigationMenu: boolean = true;

  constructor() {}

  selectMenu(route: string, parentMenu: FloatingMenu) {
    this.selectedMenu = route;

    if (parentMenu.menuItems.find((menu) => menu.route == this.selectedMenu))
      this.scrollTo(this.selectedMenu, parentMenu);
  }

  scrollTo(route: string, parentMenu: FloatingMenu) {
    let el = document.getElementById(route);

    if (el) window.scroll({ top: el.offsetTop + parentMenu.scrollBuffer, behavior: 'smooth' });
  }

  @HostListener('document:click', ['$event'])
  hideNavigation() {
    this.hideNavigationMenu = true;
  }
}
