<section class="section section-shaped">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <h2 class="text-center font-weight-extrabold mb-4">
          {{ 'resetPassword.title' | translate }}
        </h2>
        <form (ngSubmit)="submit()" [formGroup]="form" [class.show-form-error]="showFormError">
          <div class="mb-3">
            <div class="password-panel">
              <input
                class="form-control"
                [attr.type]="showPassword ? 'text' : 'password'"
                placeholder="{{ 'login.password' | translate }}"
                formControlName="newPassword"
                (focus)="onFocus()"
                (blur)="onBlur()"
                (keyup)="checkPassword()"
                maxlength="30"
              />
              <div class="show-password" (click)="showPassword = !showPassword">
                <i
                  class="fa"
                  [class.fa-eye-slash]="!showPassword"
                  [class.fa-eye]="showPassword"
                ></i>
              </div>
              <div class="dropdown-menu password-hint" *ngIf="showPasswordHint">
                <div class="d-flex">
                  <div
                    class="fa align-self-center mr-3"
                    [ngClass]="
                      atLeast8Characters ? 'fa-check text-success' : 'fa-times text-danger'
                    "
                  ></div>
                  <div>{{ 'signUp.atLeast8Characters' | translate }}</div>
                </div>
                <div class="d-flex">
                  <div
                    class="fa align-self-center mr-3"
                    [ngClass]="includesDigit ? 'fa-check text-success' : 'fa-times text-danger'"
                  ></div>
                  <div>{{ 'signUp.includesDigit' | translate }}</div>
                </div>
                <div class="d-flex">
                  <div
                    class="fa align-self-center mr-3"
                    [ngClass]="
                      includesUpperAndLowercase ? 'fa-check text-success' : 'fa-times text-danger'
                    "
                  ></div>
                  <div>{{ 'signUp.includesUpperAndLowercase' | translate }}</div>
                </div>
                <div class="d-flex">
                  <div
                    class="fa align-self-center mr-3"
                    [ngClass]="includesSymbol ? 'fa-check text-success' : 'fa-times text-danger'"
                  ></div>
                  <div>{{ 'signUp.includesSymbol' | translate }}</div>
                </div>
              </div>
            </div>
            <div
              class="error"
              *ngIf="
                showFormError &&
                form.controls.newPassword.errors &&
                form.controls.newPassword.errors.pattern
              "
            >
              {{ 'resetPassword.passwordPattern' | translate }}
            </div>
          </div>

          <div class="mb-3">
            <div class="password-panel">
              <input
                class="form-control"
                [attr.type]="showConfirmPassword ? 'text' : 'password'"
                placeholder="{{ 'resetPassword.confirmPassword' | translate }}"
                formControlName="confirmPassword"
                maxlength="30"
              />
              <div class="show-password" (click)="showConfirmPassword = !showConfirmPassword">
                <i
                  class="fa"
                  [class.fa-eye-slash]="!showConfirmPassword"
                  [class.fa-eye]="showConfirmPassword"
                ></i>
              </div>
            </div>
            <div
              class="error"
              *ngIf="
                showFormError &&
                form.controls.confirmPassword.errors &&
                form.controls.confirmPassword.errors.notMatch
              "
            >
              {{ 'resetPassword.passwordMismatch' | translate }}
            </div>
          </div>

          <div class="btn-panel">
            <button type="submit" class="btn btn-primary">
              {{ 'resetPassword.reset' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
