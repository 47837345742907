export enum RaiseFundApplicationProcessStatusType {
  None = 0,
  Pending = 1,
  Approved = 2,
  Rejected = 3,
  PendingPayment = 4,
  Paid = 5,
  BypassPayment = 6,
  PendingIssuerApproval = 7,
  RejectedByIssuer = 8,
  PendingFPXApproval = 9,
}
