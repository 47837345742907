import { Component, Input } from '@angular/core';
import { Partner, PartnerCategory } from '../../../core/models/partnership/partner';

@Component({
  selector: 'app-partner-category',
  templateUrl: './partner-category.component.html',
})
export class PartnerCategoryComponent {
  @Input() category: PartnerCategory;
  @Input() partners: Partner[];

  PartnerCategory: typeof PartnerCategory = PartnerCategory;
}
