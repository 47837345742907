<div class="tw-relative tw-h-[320px] tw-w-full tw-bg-[#d00000]"></div>

<div class="tw-relative -tw-mt-[220px] tw-h-[220px]">
  <svg
    viewBox="0 0 500 150"
    preserveAspectRatio="none"
    class="tw-min-w-500px tw-absolute tw-h-[220px] tw-w-full"
  >
    <path
      d="M-60.00,181.04 C241.25,18.04 269.37,17.03 558.12,181.04 L500.00,150.00 L0.00,150.00 Z"
      style="stroke: none; fill: #ffffff"
    ></path>
  </svg>
</div>

<div class="tw-relative -tw-mt-[185px] tw-mb-10 tw-flex tw-flex-col tw-items-center">
  <img src="/assets/img/banner/ekycSuccess.png" alt="kyc_image" class="tw-z-20" />
  <div class="tw-w-full tw-justify-center tw-py-4">
    <div class="container">
      <div class="tw-mx-auto">
        <h2 class="tw-py-2 tw-text-center tw-font-black">
          {{ 'signUp.signUpVerified' | translate }}
        </h2>
        <div class="tw-mx-auto tw-max-w-lg">
          <div class="tw-mb-10 tw-py-2 tw-text-black">
            <p
              class="tw-text-md tw-mb-2.5 tw-text-center"
              [innerHTML]="'signUp.signUpVerifiedDesc' | translate"
            ></p>
            <p
              class="tw-text-md tw-mb-0 tw-text-center"
              [innerHTML]="'signUp.signUpVerifiedTip' | translate"
            ></p>
          </div>
          <div class="tw-grid tw-gap-2.5 md:tw-grid-cols-2">
            <a routerLink="/profile/settings" class="btn btn-green-border tw-w-full">
              {{ 'signUp.signUpVerifiedCta1' | translate }}
            </a>
            <a routerLink="/equity" class="btn btn-green tw-w-full">
              {{ 'signUp.signUpVerifiedCta2' | translate }}
            </a>
          </div>
          <a
            routerLink="/profile/settings/security"
            class="p-link-primary p-link-underline tw-mb-5 tw-mt-10 tw-block tw-cursor-pointer tw-text-center tw-text-sm"
          >
            {{ 'signUp.signUpAddAuthenticator' | translate }}→
          </a>
          <div class="tw-mx-auto tw-flex tw-max-w-sm tw-gap-2 tw-text-sm md:tw-text-center">
            <p-icon name="warning-circle"></p-icon>
            <div>{{ 'signUp.signUpVerifiedAuthenticatorDisclaimer' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
