import * as moment from 'moment';

export class HistoryFilter {
  take: number;
  currentPage: number;
  transactionDateFrom: string;
  transactionDateTo: string;

  constructor() {
    this.currentPage = 1;
    this.take = 10;
    this.transactionDateFrom = moment().startOf('month').format();
    this.transactionDateTo = moment().format();
  }
}
