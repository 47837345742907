import { WalletStatus } from '../../enum/mysec/wallet-status.enum';
import { WalletModel } from './wallet.model';

export class Wallet {
  balance: number;
  pendingAmount: number;
  status: WalletStatus;
  ecfAccountNo: string;
  userId: string;

  constructor(model: WalletModel) {
    this.balance = model.balance;
    this.pendingAmount = model.pendingAmount;
    this.status = model.status;
    this.ecfAccountNo = model.ecfAccountNo;
    this.userId = model.userId;
  }
}
