import { Component, Input } from '@angular/core';
import { SocialLinkType } from '../../../core/enum/social-link-type.enum';
import { Campaign } from '../../../core/models/campaign/campaign';
import { DefaultLogo } from '../../../core/services/constant';

@Component({
  selector: 'campaign-business-overview',
  templateUrl: './campaign-business-overview.component.html',
  styleUrls: ['./campaign-business-overview.component.scss'],
})
export class CampaignBusinessOverviewComponent {
  @Input() campaign: Campaign;

  defaultLogo = DefaultLogo;

  SocialLinkType: typeof SocialLinkType = SocialLinkType;
}
