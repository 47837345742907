import { Attachment } from './../../../core/models/attachment/attachment';
import { CompanyService } from './../../../core/services/api/company.service';
import { BlockUiService } from '../../../shared/services/blockUi/block-ui.service';
import { ErrorService } from '../../../core/services/error.service';
import { Component } from '@angular/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-new-application',
  templateUrl: './new-application.component.html',
  styleUrls: ['./new-application.component.scss'],
})
export class NewApplicationComponent {
  step: number = 1;
  attachments: Attachment[];

  constructor(
    private errorService: ErrorService,
    private blockUiService: BlockUiService,
    private companyService: CompanyService,
  ) {}

  next() {
    this.step++;
    this.getCompanyDocuments();
  }

  getCompanyDocuments() {
    this.blockUiService.open();

    //will change to new api when api ready
    this.companyService
      .getCompanyAttachments()
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        (data) => {
          this.attachments = data;
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  submit() {}

  uploadCompanyDocument(document: Attachment) {
    //will change to new api when api ready
    this.companyService.uploadCompanyAttachment(document).subscribe(
      () => {
        this.getCompanyDocuments();
      },
      (err) => {
        this.errorService.showError(err);
        let uploadDocument = this.attachments.find(
          (doc) => doc.attachmentType.id == document.attachmentType.id,
        );
        uploadDocument.file = null;
      },
    );
  }

  deleteCompanyDocument(id: number) {
    //will change to new api when api ready
    this.companyService.deleteCompanyAttachment(id).subscribe(
      () => {
        this.getCompanyDocuments();
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }
}
