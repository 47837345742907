<div class="row justify-content-center mb-5">
  <div class="col-md-10 login-panel p-5">
    <div class="row justify-content-center">
      <div class="col-lg-7 col-md-9 text-center">
        <div
          *ngIf="title"
          class="mb-2 text-black tw-text-lg tw-font-bold"
          [innerHTML]="title | translate"
        ></div>
        <div class="mb-4 text-black" [innerHTML]="message | translate"></div>
        <ng-container *ngIf="isRequireLogin">
          <div
            class="btn btn-primary-border w-100 font-size-larger mb-3 py-3"
            routerLink="/login"
            (click)="handleLoginRedirection($event)"
          >
            {{ 'common.loginBtn' | translate }}
          </div>
          <div
            class="btn btn-primary w-100 font-size-larger py-3"
            routerLink="/sign-up"
            data-t="signUpClick"
            [attr.data-t-location]="placement"
          >
            {{ 'common.signUp' | translate }}
          </div>
        </ng-container>
        <a *ngIf="ctaUrl" [href]="ctaUrl" target="_blank" class="btn btn-primary-border">
          {{ ctaText | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
