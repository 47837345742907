import { Component, Input } from '@angular/core';
import { DefaultLogo } from '../../core/services/constant';

@Component({
  selector: 'app-thumbnail-group',
  templateUrl: './thumbnail-group.component.html',
  styleUrls: ['./thumbnail-group.component.scss'],
})
export class ThumbnailGroupComponent {
  @Input() size: 'lg' | 'sm' = 'sm';
  @Input() showImage: boolean = true;
  @Input() imageUrl: string;

  @Input() name: string;
  @Input() description: string;

  @Input() nameClass: string;
  @Input() descriptionClass: string;

  defaultLogo = DefaultLogo;
}
