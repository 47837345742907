import { TestimonialSliderContent } from '../../../core/models/mysec/for-founders-slider-logos';
import { Component, NgModule, ViewEncapsulation } from '@angular/core';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, SwiperOptions } from 'swiper';
import { ViewChild, NgZone } from '@angular/core';
import { SwiperComponent } from 'swiper/angular';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-for-founders',
  templateUrl: './for-founders.component.html',
  styleUrls: ['./for-founders.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ForFoundersComponent {
  constructor(private ngZone: NgZone) {}
  selectedIndex: number = 0;

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  config: SwiperOptions = {
    autoHeight: true,
  };

  setIndex(index: number) {
    this.selectedIndex = index;
  }

  isSelectedIndex(index: number) {
    return index === this.selectedIndex;
  }

  onSlideChange(swiperEl: any) {
    this.ngZone.run(() => {
      this.selectedIndex = swiperEl ? swiperEl?.activeIndex : 0;
    });
  }

  onButtonClick(index: number) {
    this.ngZone.run(() => {
      this.swiper.swiperRef.slideTo(index);
    });
  }

  testimonialSlider: TestimonialSliderContent[] = [
    {
      'backgroundColour': '#AB4499',
      'testimonial':
        'The team definitely is very helpful. As you may know, I have been an entrepreneur for the last 20 years, but even then I think the pitchIN team were still able to assist me in advising me on how best to do the pitch, and how to message the pitch best.',
      'authourAvatar': 'commerce-asia-founder.jpeg',
      'authourName': 'Ganesh Kumar Bangah',
      'authourRole': 'Founder & Executive Chairman',
      'companyName': 'Commerce.Asia',
      'caseStudyContent': ``,
      'caseStudyRedirectUrl': 'www.google.com',
      'companyLogoUrl': 'logo_rectangle_commerce_dot_asia.png',
      'backgroundImage': 'commerce-asia-banner-bg.png',
    },
    {
      'backgroundColour': '#45B0C8',
      'testimonial':
        'I, as a founder, have my personal network to raise funds. But the important part about working with pitchIN is their strong network of investors. When we combine the two communities together, it creates a synergy of fundraising for our case.',
      'authourAvatar': 'kakitangan-founder.jpeg',
      'authourName': 'Effon Khoo',
      'authourRole': 'Founder',
      'companyName': 'Kakitangan',
      'caseStudyContent': ``,
      'caseStudyRedirectUrl': 'www.google.com',
      'companyLogoUrl': 'logo_rectangle_kakitangan.png',
      'backgroundImage': 'kakitangan-banner-bg.png',
    },
    {
      'backgroundColour': '#5DAC46',
      'testimonial':
        'The pitchIN team is very friendly and helpful. They side with entrepreneurs and understand the challenges faced by an entrepreneur. But at the same time, they act as the bridge between investors and entrepreneurs.',
      'authourAvatar': 'signature-market-founder.jpeg',
      'authourName': 'Edwin Wang',
      'authourRole': 'Co-founder and CEO',
      'companyName': 'Signature Market',
      'caseStudyContent': ``,
      'caseStudyRedirectUrl': 'www.google.com',
      'companyLogoUrl': 'logo_rectangle_signature_market.png',
      'backgroundImage': 'signature-market-banner-bg.png',
    },
    {
      'backgroundColour': '#CEA02E',
      'testimonial':
        'Through my personal experience raising funds, recognition and know-how is very important. Here pitchIN is the market leader in the public eye. With pitchIN, you do half the work, but the trust level is much higher and support is more comprehensive. I think that is very important.',
      'authourAvatar': 'oxwhite-founder.jpeg',
      'authourName': 'CK Chang',
      'authourRole': 'Founder',
      'companyName': 'OXWHITE',
      'caseStudyContent': ``,
      'caseStudyRedirectUrl': 'www.google.com',
      'companyLogoUrl': 'logo_rectangle_oxwhite.png',
      'backgroundImage': 'oxwhite-banner-bg.png',
    },
  ];
}
