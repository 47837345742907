import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'trustSrc' })
export class TrustSrcPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(src: string): SafeHtml {
    if (!src) return null;

    return this.domSanitizer.bypassSecurityTrustHtml(src);
  }
}
