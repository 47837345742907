import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { CampaignStatus } from '../../../../core/enum/campaign-status.enum';
import { InvestmentStatus } from '../../../../core/enum/investment-status.enum';
import { RefundStatus } from '../../../../core/enum/refund-status.enum';
import { MyInvestment } from '../../../../core/models/investment/my-investment';
import { InvestmentService } from '../../../../core/services/api/investment.service';
import { ErrorService } from '../../../../core/services/error.service';
import { BlockUiService } from '../../../../shared/services/blockUi/block-ui.service';
import { PopupService } from '../../../../shared/services/popup/popup.service';

@Component({
  selector: 'app-investments',
  templateUrl: './investments.component.html',
  styleUrls: ['./investments.component.scss'],
})
export class InvestmentsComponent implements OnChanges {
  @Input() campaignStatus: CampaignStatus;
  @Input() myInvestment: MyInvestment;
  @Output() getMyInvestment = new EventEmitter();
  displayColumns: string[] = [
    'PledgedAmount',
    'RefundedAmount',
    'InvestmentStatus',
    'RefundStatus',
    'RequestForMoreInfo',
    'CreatedAt',
    'Action',
  ];

  InvestmentStatus: typeof InvestmentStatus = InvestmentStatus;
  RefundStatus: typeof RefundStatus = RefundStatus;
  CampaignStatus: typeof CampaignStatus = CampaignStatus;

  constructor(
    private popupService: PopupService,
    private blockUiService: BlockUiService,
    private errorService: ErrorService,
    private investmentService: InvestmentService,
  ) {}

  ngOnChanges() {
    if (
      [CampaignStatus.ShareAllotment, CampaignStatus.Success, CampaignStatus.Fail].includes(
        this.campaignStatus,
      )
    )
      this.displayColumns = this.displayColumns.filter((col) => col != 'Action');

    if (this.campaignStatus == CampaignStatus.ShareAllotment)
      this.displayColumns = this.displayColumns.filter(
        (col) => !['RefundedAmount', 'InvestmentStatus', 'RefundStatus'].includes(col),
      );

    if (this.myInvestment?.investments?.length > 0) {
      this.myInvestment.investments.sort((val1, val2) => {
        return <any>new Date(val2.createdAt) - <any>new Date(val1.createdAt);
      });
    }
  }

  cancelInvestment(investmentId: number) {
    this.popupService.confirm('investment.confirmCancelInvestment').then(
      () => {
        this.blockUiService.open();

        this.investmentService
          .cancelInvestment(investmentId)
          .pipe(finalize(() => this.blockUiService.close()))
          .subscribe(
            () => {
              this.popupService.alert('investment.requestRefundInProgress').then(() => {
                this.getMyInvestment.emit();
              });
            },
            (err) => {
              this.errorService.showError(err);
            },
          );
      },
      () => {},
    );
  }
}
