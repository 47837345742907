export enum ContainerType {
  Company = 1,
  CompanyLogo = 2,
  CompanyBanner = 3,
  IndividualInvestor = 4,
  ProfilePicture = 5,
  RichTextHtml = 6,
  Investment = 7,
  InvestmentReceipt = 8,
  InvestmentSourceOfFundDocument = 9,
  InvestmentSourceOfWealthDocument = 10,
  OneTimeBusinessFeeReceipt = 11,
  CTOSReport = 12,
  Banner = 13,
  CampaignEvent = 14,
  FundRaise = 15,
  RaiseFund = 16,
  SampleDocs = 17,
  Campaign = 18,
  MySec = 19,
  WalletReceipt = 20,
  MySecCompanyTeam = 21,
}
