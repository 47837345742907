import { TabMenuItem } from '../../core/models/menu/tab-menu-item';
import { StorageService } from '../../core/services/storage.service';
import { MenuService } from '../../core/services/menu.service';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  selectedTab: string[] = ['/profile', 'settings'];
  tabs: TabMenuItem[] = [];
  settingSelectedTab: number = 1;

  constructor(
    private menuService: MenuService,
    public storageService: StorageService,
    private router: Router,
  ) {}

  ngOnInit() {
    // Note: PLT-530 Remove IEO & PSTX related from the tabs
    this.tabs = this.menuService.menu.tabMenu.filter(
      (tabItem) => !tabItem.name.includes('settings.'),
    );
    this.selectTab(this.router.url);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.selectTab(this.router.url);
      }
    });
  }

  selectTab(url: string) {
    let currentUrls = url.split('/');
    let lastUrl = this.tabs.find((tab) => tab.route?.includes(currentUrls[currentUrls.length - 1]));

    if (lastUrl) this.selectedTab = lastUrl.route;
  }

  changeTab(route: string[]) {
    this.selectedTab = route;
  }
}
