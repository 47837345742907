<div class="tw-bg-warning-light tw-py-10">
  <div
    class="container-fluid tw-text-center md:tw-flex md:tw-gap-3 md:tw-text-left"
  >
    <div
      class="pitchin-icon pitchin-icon-warning_icon_red tw-inline-block tw-h-16 tw-w-16 tw-flex-shrink-0 tw-cursor-default"
    ></div>
    <div>
      <div class="tw-mb-1 tw-text-lg tw-font-bold tw-text-primary">
        {{ 'common.warning' | translate }}
      </div>
      <div>
        {{ 'landing.warningDesc' | translate }}
        <span
          class="font-weight-bold text-underline cursor-pointer"
          [routerLink]="['/risk-warning']"
        >
          {{ 'common.learnMore' | translate }}</span
        >
      </div>
    </div>
  </div>
</div>

<footer class="footer tw-text-white" [class.expand-footer]="isExpandFooter">
  <div class="container-fluid">
    <div
      class="tw-mb-5 tw-grid tw-gap-x-10 md:tw-grid-cols-3 lg:tw-grid-cols-4"
    >
      <div
        class="footer__item tw-mb-10 md:tw-col-span-3 lg:tw-col-span-1 lg:tw-mb-5"
      >
        <div
          class="pitchin-icon pitchin-icon-pitchin-logo-white tw-mb-3 tw-h-16 tw-w-20 tw-cursor-default"
        ></div>
        <p
          class="tw-mb-0 tw-text-base tw-leading-normal tw-text-white tw-text-opacity-50"
        >
          {{ 'footer.companyDesc' | translate }}
        </p>
        <br />
        <a
          class="cursor-pointer tw-mb-0 tw-text-xs tw-leading-normal tw-text-white tw-text-opacity-50"
          [href]="'/assets/CHANGELOG.md'"
          [hidden]="'PRODUCTION' === environment.environment"
        >
          {{ 'v' + Version }}
        </a>
      </div>

      <div class="footer__item tw-mb-5" *ngFor="let footerItem of footerItems">
        <div class="footer__heading">
          {{ 'footer.' + footerItem.colTitle | translate }}
        </div>
        <ul class="footer__navlist">
          <ng-container *ngFor="let item of footerItem.items">
            <li
              class="footer__navlist-item tw-flex tw-flex-wrap tw-items-center tw-pb-2"
              *ngIf="!item.show || item.show()"
            >
              <span
                *ngIf="item.linkType == LinkType.File"
                (click)="popupFile(item.url)"
              >
                {{ 'footer.' + item.title | translate }}
              </span>
              <a
                class="tw-mr-2"
                *ngIf="item.linkType == LinkType.URL"
                [href]="item.url"
                target="_blank"
              >
                {{ 'footer.' + item.title | translate }}
              </a>
              <a
                *ngIf="item.linkType == LinkType.RouterLink"
                [routerLink]="item.url"
                [fragment]="item?.fragment"
              >
                {{ 'footer.' + item.title | translate }}
              </a>
              <ng-container *ngIf="item.badge">
                <a
                  class="footer__badge"
                  *ngIf="item.badgeAction.linkType == LinkType.URL"
                  [href]="item.badgeAction.url"
                >
                  <i
                    *ngIf="item.badgeIcon"
                    class="tw-mr-0.5"
                    [class]="item.badgeIcon"
                  ></i>
                  {{ 'footer.' + item.badge | translate }}
                </a>
              </ng-container>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>

    <div class="footer__notes">
      <p
        class="tw-mb-8 tw-text-center tw-text-sm tw-text-white tw-text-opacity-50 md:tw-text-left"
      >
        <span class="tw-font-semibold"
          >{{ 'footer.companyRegisteredNumber' | translate }}</span
        >
        <br />
        {{ 'footer.companyFullAddress' | translate }}
      </p>
      <div
        class="tw-text-center tw-text-white tw-text-opacity-20 md:tw-flex md:tw-items-center"
      >
        <p class="tw-mb-5 tw-text-sm md:tw-mb-0">
          {{ 'common.copyright' | translate: { currentYear: currentYear } }}
        </p>

        <ul
          class="footer__socials tw-ml-auto tw-flex tw-justify-center tw-gap-x-4 md:tw-gap-x-2 md:tw-pl-5"
        >
          <!-- Facebook -->
          <li>
            <a
              href="https://www.facebook.com/pitchIN.my/"
              aria-label="pitchIN Facebook Page"
              target="_blank"
              class="tw-block"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 64 64"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="m60,12c0-4.42-3.58-8-8-8H12C7.58,4,4,7.58,4,12v40c0,4.42,3.58,8,8,8h15.98l4.18-2.42,3.84,2.42h15.99c4.42,0,8-3.58,8-8V12h0Z"
                ></path>
                <path
                  fill="#161d2d"
                  fill-rule="evenodd"
                  d="m28,60v-20h-6c-.26,0-.52-.1-.71-.29-.19-.19-.29-.44-.29-.71v-6c0-.27.11-.52.29-.71.19-.19.44-.29.71-.29h6v-8.68c0-2.21.88-4.32,2.44-5.89,1.56-1.56,3.68-2.44,5.89-2.44h6.68c.26,0,.52.11.71.29.19.19.29.44.29.71v5c0,.27-.11.52-.29.71-.19.19-.44.29-.71.29h-2.92c-2.26,0-4.08,1.83-4.08,4.08v5.92h6.87c.29,0,.56.12.75.34.19.22.28.5.24.79-.2,1.61-.57,4.54-.75,6-.06.5-.49.88-.99.88h-6.12v20h-8Z"
                ></path>
              </svg>
            </a>
          </li>
          <!-- Twitter -->
          <li>
            <a
              href="https://x.com/pitchIN_token"
              aria-label="pitchIN X account"
              target="_blank"
              class="tw-block"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="26"
                height="26"
                viewBox="0 0 64 64"
                version="1.1"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="m60,12c0-4.42-3.58-8-8-8H12C7.58,4,4,7.58,4,12v40c0,4.42,3.58,8,8,8h40c4.42,0,8-3.58,8-8V12h0Z"
                ></path>
                <path
                  fill="#161d2d"
                  d="m15.07,48.28h4l10.68-12.14,9.29,12.12h10.86l-14.25-18.84,12.06-13.7h-4l-9.91,11.26-8.5-11.25h-11.21l13.79,17.99-12.82,14.57Zm5.11-29.56h3.64l20.06,26.54h-3.35l-20.34-26.54Z"
                ></path>
              </svg>
            </a>
          </li>
          <li>
            <!-- Linkedin -->
            <a
              href="https://www.linkedin.com/company/pitchin"
              aria-label="pitchIN Linkedin page"
              target="_blank"
              class="tw-block"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 64 64"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="m60,12c0-4.42-3.58-8-8-8H12C7.58,4,4,7.58,4,12v40c0,4.42,3.58,8,8,8h40c4.42,0,8-3.58,8-8V12h0Z"
                ></path>
                <path
                  fill="#161d2d"
                  fill-rule="evenodd"
                  d="m34.38,28.27v-2c0-.55-.45-1-1-1h-6.5c-.27,0-.52.11-.71.29-.19.19-.29.44-.29.71v24.5c0,.27.1.52.29.71s.44.29.71.29h6.5c.26,0,.52-.1.71-.29s.29-.44.29-.71v-14c0-2.48,2.01-4.5,4.5-4.5s4.5,2.02,4.5,4.5v14c0,.27.1.52.29.71s.44.29.71.29h6.5c.55,0,1-.45,1-1v-15.5c0-6.85-3.2-9.85-8.25-10.5-6.06-.78-9.25,3.5-9.25,3.5h0Zm-13.25-2c0-.26-.11-.52-.29-.71-.19-.19-.44-.29-.71-.29h-6.5c-.27,0-.52.11-.71.29-.19.19-.29.44-.29.71v24.5c0,.27.1.52.29.71s.44.29.71.29h6.5c.26,0,.52-.1.71-.29s.29-.44.29-.71v-24.5h0Zm-4.25-14.05c2.62,0,4.75,2.13,4.75,4.75s-2.13,4.75-4.75,4.75-4.75-2.13-4.75-4.75,2.13-4.75,4.75-4.75h0Z"
                ></path>
              </svg>
            </a>
          </li>
          <li>
            <!-- Tiktok -->
            <a
              href="https://www.tiktok.com/@pitchin_my"
              aria-label="pitchIN TikTok page"
              target="_blank"
              class="tw-block"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 64 64"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="m60,12c0-4.42-3.58-8-8-8H12C7.58,4,4,7.58,4,12v40c0,4.42,3.58,8,8,8h40c4.42,0,8-3.58,8-8V12h0Z"
                ></path>
                <path
                  fill="#161d2d"
                  fill-rule="evenodd"
                  d="m47.64,27.65c0,.3-.13.58-.37.77-.23.19-.53.22-.83.21-1.86-.05-4.77-.69-6.14-2v12.54c0,5.78-4.68,10.46-10.46,10.46h-1.08c-2.42,0-4.72-.88-6.58-2.39,1.43.71,3,1.13,4.63,1.13h1.08c5.78,0,10.46-4.68,10.46-10.46v-12.54c1.37,1.3,4.28,1.95,6.14,2,.3,0,.6-.02.83-.21.23-.19.37-.47.37-.77,0-1.42,0-3.78,0-5.02.38.11.77.2,1.16.29.46.1.79.5.79.97,0,1.24,0,3.61,0,5.02Zm-19.58,4.43c-.19.19-.44.29-.71.29-2.76,0-5,2.24-5,5,0,2.17,1.39,4,3.33,4.69-.85-.9-1.38-2.1-1.38-3.43,0-2.76,2.24-5,5-5,.27,0,.52-.1.71-.29.19-.19.29-.44.29-.71v-3c0-.26-.11-.52-.29-.71s-.44-.29-.71-.29h-.54c-.14,0-.27.04-.41.04v2.7c0,.27-.11.52-.29.71Zm13.67-12.69c-.83-.75-1.53-1.74-2.03-3.09-.15-.39-.52-.67-.94-.67h-.74c.87,1.91,2.16,3.03,3.71,3.75Z"
                ></path>
                <path
                  fill="#3a4765"
                  fill-rule="evenodd"
                  d="m21.37,46.57c.25.25.55.45.82.67-1.01-.5-1.96-1.12-2.77-1.93-1.96-1.96-3.06-4.62-3.06-7.4v-.08c0-5.78,4.68-10.46,10.46-10.46h.54c.27,0,.52.11.71.29.19.19.29.44.29.71v.3c-5.58.22-10.05,4.78-10.05,10.42v.08c0,2.77,1.1,5.43,3.06,7.4Zm24.31-25.2h0c0-.47-.33-.88-.79-.97-1.12-.25-2.19-.55-3.17-1.01,1.11,1,2.47,1.57,3.95,1.98Zm-16.38,22.27c1.33,0,2.6-.53,3.54-1.46s1.46-2.21,1.46-3.54v-22c0-.55.45-1,1-1h2.72c-.09-.2-.19-.38-.27-.6-.15-.39-.52-.67-.94-.67h-3.46c-.55,0-1,.45-1,1v22c0,1.33-.53,2.6-1.46,3.54s-2.21,1.46-3.54,1.46c-.59,0-1.15-.12-1.67-.31.91.96,2.19,1.57,3.62,1.57Z"
                ></path>
                <path
                  fill="#161d2d"
                  fill-rule="evenodd"
                  d="m27.9,48.37c5.78,0,10.46-4.68,10.46-10.46v-12.54c1.37,1.3,4.28,1.95,6.14,2,.3,0,.6-.02.83-.21.23-.19.37-.47.37-.77,0-1.42,0-3.78,0-5.02-1.48-.41-2.84-.98-3.95-1.98-1.54-.72-2.84-1.85-3.71-3.75h-2.72c-.55,0-1,.45-1,1v22c0,1.33-.53,2.6-1.46,3.54s-2.21,1.46-3.54,1.46c-1.43,0-2.71-.61-3.62-1.57-1.93-.69-3.33-2.52-3.33-4.69,0-2.76,2.24-5,5-5,.27,0,.52-.1.71-.29.19-.19.29-.44.29-.71v-2.7c-5.58.22-10.05,4.78-10.05,10.42v.08c0,2.77,1.1,5.43,3.06,7.4.25.25.55.45.82.67,1.43.71,3,1.13,4.63,1.13h1.08Z"
                ></path>
              </svg>
            </a>
          </li>
          <li>
            <!-- Youtube -->
            <a
              href="https://www.youtube.com/@pitchIN_Equity"
              aria-label="pitchIN Youtube page"
              target="_blank"
              class="tw-block"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 64 64"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="m60,12c0-4.42-3.58-8-8-8H12C7.58,4,4,7.58,4,12v40c0,4.42,3.58,8,8,8h40c4.42,0,8-3.58,8-8V12h0Z"
                ></path>
                <path
                  fill="#161d2d"
                  fill-rule="evenodd"
                  d="m27.39,40.88c-.29.16-.64.16-.93,0-.29-.17-.46-.48-.46-.82v-16.11c0-.34.17-.65.46-.81.29-.17.64-.17.93,0,3.17,1.81,10.91,6.22,14.14,8.06.29.17.48.48.48.82s-.18.66-.48.82c-3.22,1.84-10.97,6.25-14.14,8.06h0Z"
                ></path>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
