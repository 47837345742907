import { CampaignMenuItem } from '../models/menu/tab-menu-item';
import { FileTypeMime } from '../models/attachment/file-type-mime';
import { Role } from '../enum/role.enum';
import { CampaignStatus } from '../enum/campaign-status.enum';

export const NumberPattern = /^[0-9]*$/;

export const DefaultPasswordPattern =
  /^(?=.*\d)(?=.*[#$^+=!*()@%&{}\[\].])(?=.*[a-z])(?=.*[A-Z]).{8,30}$/;

export const DefaultEmailPattern =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

export const DefaultURLPattern =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

export const DefaultVideoURLPattern =
  /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$|^(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*$/;

export const DoubleDecimalPattern = /^(?!$)\d{0,10}(?:\.\d{0,2})?$/;

export const DoubleDecimalPatternAllowNegative = /^-?(?!$)\d{0,10}(?:\.\d{0,2})?$/;

export const DigitOnlyPattern = /^\d*\.?\d*$/;

export const DigitOnlyPatternAllowNegative = /^-?\d*\.?\d*$/;

export const TenDigitOnlyPattern = /^(?!$)\d{0,10}?$/;

export const OneBillionWithTwoDecimalPattern = /^(?!$)\d{0,9}(?:\.\d{0,2})?$|^1000000000$/;

export const OneBillionWithTwoDecimalPatternAllowNegative =
  /^(?!$)\d{0,9}(?:\.\d{0,2})?$|^-?(?!$)\d{0,9}(?:\.\d{0,2})?$|^-?1000000000$|^1000000000$/;

export const OneBillionPattern = /^(?!$)\d{0,9}?$|^1000000000$/;

export const SingleDecimalPattern = /^\d+(\.\d{1})?$/;

export const YEAR_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const FULL_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const DATE_FORMATS = {
  defaultDate: 'D MMM YYYY',
  defaultTime: 'H:mm',
  defaultDateTime: 'DD MMM YYYY HH:mm',
  weekdayDateTime: 'ddd D MMMM h:mm A',
  weekdayDate: 'ddd, D MMM YYYY',
};

export const DefaultCountryCode: string = '60';

export const DefaultCountry: string = 'MY';

export const DefaultTimeZone = 'Asia/Kuala_Lumpur';

export const FileSizeLimit: number = 20971520;

export const VideoFileSizeLimit: number = 104857600;

export const FileNameLimit: number = 50;

export const PDFFileFormat: string[] = ['application/pdf'];

export const XLSXFileFormat: string[] = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const ExcelFileFormat: string[] = [...XLSXFileFormat, 'text/csv'];

export const WordFileFormat: string[] = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const PresentationFileFormat: string[] = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

export const AllFileFormat: string[] = [
  '.docx',
  '.csv',
  ...PDFFileFormat,
  ...ExcelFileFormat,
  ...WordFileFormat,
  ...PresentationFileFormat,
];

export const ImageFileFormat: string[] = ['image/png', 'image/jpeg'];

export const ImageAndPdfFileFormat: string[] = [...ImageFileFormat, ...PDFFileFormat];

export const PdfAndExcelFileFormat: string[] = [...PDFFileFormat, ...ExcelFileFormat];

export const PdfAndXlsxFileFormat: string[] = [...PDFFileFormat, ...XLSXFileFormat];

export const VideoFileFormat: string[] = ['video/mp4'];

export const DefaultProfileImage = 'assets/img/icons/common/profile_icon_red@2x.png';

export const DefaultProfileImageGrey = 'assets/img/icons/common/profile_icon_grey@2x.png';

export const DefaultBanner = 'assets/img/banner/company_banner_placeholder@2x.png';

export const DefaultLogo = 'assets/img/banner/company_profile_placeholder@2x.png';

export const ICMaskFormat = '000000-00-0000';

export const PassportFormat = /^[a-zA-Z0-9]{7,9}$/;

export const CampaignMenus: CampaignMenuItem[] = [
  { name: 'raise.summary', route: 'summary' },
  { name: 'raise.problem', route: 'problem' },
  { name: 'raise.solution', route: 'solution' },
  { name: 'raise.product', route: 'product' },
  { name: 'raise.traction', route: 'traction' },
  { name: 'raise.customers', route: 'customers' },
  { name: 'raise.businessModel', route: 'businessModel' },
  { name: 'raise.market', route: 'market' },
  { name: 'raise.competition', route: 'competition' },
  { name: 'raise.funding', route: 'funding' },
  { name: 'raise.vision', route: 'vision' },
  { name: 'raise.founders', route: 'founders' },
  { name: 'raise.team', route: 'team' },
  { name: 'raise.investmentTerms', route: 'investmentTerms' },
  { name: 'raise.disclosure', route: 'disclosure' },
];

export const FileExtension = /(?:\.([^.]+))?$/;

export const FileType: FileTypeMime[] = [
  {
    mime: 'application/pdf',
    ext: ['pdf'],
    sign: ['25504446'],
    rules: { 'start': 0, 'end': 4 },
  },
  {
    mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ext: ['xlsx'],
    sign: ['504b34', '504b0304'],
    rules: { 'start': 0, 'end': 4 },
  },
  {
    mime: 'text/csv',
    ext: ['csv'],
  },
  {
    mime: 'image/png',
    ext: ['png'],
    sign: ['89504e47'],
    rules: { 'start': 0, 'end': 4 },
  },
  {
    mime: 'image/jpeg',
    ext: ['jpeg', 'jpg'],
    sign: ['ffd8ff'],
    rules: { 'start': 0, 'end': 3 },
  },
  {
    mime: 'video/mp4',
    ext: ['mp4'],
    sign: ['6674797069736F6D'],
    rules: { 'start': 0, 'end': 8 },
  },
];

export const APP_VERSION = require('../../../../package.json').version;

export const signUpTypes = [
  {
    name: 'IndividualInvestor',
    id: Role.IndividualInvestor,
    type: 'signUp.individual',
    title: 'signUp.individualTitle',
    desc: 'signUp.individualDesc',
    image: '../../../assets/img/icons/common/signup-individual.png',
    backgroundImage: '../../../assets/img/banner/bg-signup-individual.png',
  },
  {
    name: 'CompanyRep',
    id: Role.CompanyRep,
    type: 'signUp.corporate',
    title: 'signUp.corporateTitle',
    desc: 'signUp.corporateDesc',
    image: '../../../assets/img/icons/common/signup-corporate.png',
    backgroundImage: '../../../assets/img/banner/bg-signup-corporate.png',
  },
  {
    name: 'IssuerRep',
    id: Role.IssuerRep,
    type: 'signUp.issuer',
    title: 'signUp.issuerTitle',
    desc: 'signUp.issuerDesc',
    image: '../../../assets/img/icons/common/signup-issuer.png',
    backgroundImage: '../../../assets/img/banner/bg-signup-issuer.png',
  },
];

export const DefaultURLOptionalProtocolPattern =
  /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&\\=]*)/;

export const MaxBonusLimit = 300;

export const MaxReferalCodeLength = 20;

export const OneBillionPatternAllowNegative =
  /^(?!$)\d{0,9}?$|^-?(?!$)\d{0,9}?$|^1000000000$|^-?1000000000$/;

export const TradingAmountClassLimit: number = 50000;

export const MinimumDepositAmount: number = 20;

export const MinimumWithdrawAmount: number = 1; // changed the amount to 1 as per PLT-442

export const OrderMaxQuantity = 100000;

export const OrderMaxPrice = 500000;

export const OrderMinPrice = 0.5;

export const OrderIncrementLessThanRM50 = 0.01;

export const OrderIncrementMoreThanRM50 = 0.5;

export const MinimumPriceChecking = 50;
