import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpResponseInterceptor } from './http-response.interceptor';
import { AccessTokenInterceptor } from './access-token.interceptor';
import { EncodeHttpParamsInterceptor } from './encode-http-params.interceptos';
import { CancelPreviousRequestInterceptor } from './cancel-previous-request.interceptor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AccessTokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CancelPreviousRequestInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: EncodeHttpParamsInterceptor, multi: true },
];
