<button
  class="pitchin-icon pitchin-icon-close tw-absolute -tw-right-2 -tw-top-2 tw-z-[5] tw-rounded-full"
  (click)="close()"
></button>

<div class="modal-body tw-max-h-[50vh] tw-overflow-auto tw-p-0" *ngIf="portfolioListModel">
  <div class="tw-p-6">
    <app-shares-pop-up
      [imageUrl]="portfolioListModel.company.companyLogoUrl"
      [companyName]="'Business' | translate"
      [companyDescription]="portfolioListModel?.company?.brandName || '-'"
      [counterName]="'Counter' | translate"
      [counterDescription]="portfolioListModel?.tickerCode?.toUpperCase() || '-'"
      [nameClass]="'!tw-text-body !tw-font-normal'"
      [descriptionClass]="'!tw-font-bold !tw-text-black'"
      [isMoratorium]="portfolioListModel?.isMoratorium"
    >
    </app-shares-pop-up>

    <hr class="tw-my-6" />

    <div class="tw-mt-6 tw-space-y-4">
      <ng-container
        *ngIf="portfolioListModel?.portfolio?.lastTradedSharePrice; else indicativeSharePrice"
      >
        <!-- Last traded share price -->
        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-5">
          <div class="tw-col-span-3">
            <div class="tw-font-bold tw-text-black">
              {{ 'mysec.lastTradedSharePrice' | translate }}
            </div>
            <div class="tw-text-xs tw-text-body tw-text-opacity-80 first-letter:tw-lowercase">
              {{ 'mysec.lastTradedSharePriceDescription' | translate }}
            </div>
          </div>
          <div class="tw-col-span-2 tw-tabular-nums md:tw-text-right">
            {{
              portfolioListModel?.portfolio.lastTradedSharePrice
                | numberSuffix: { numberFormat: '1.2-2' }
            }}
          </div>
        </div>
      </ng-container>

      <ng-template #indicativeSharePrice>
        <!-- Indicative share price -->
        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-5">
          <div class="tw-col-span-3">
            <div class="tw-font-bold tw-text-black">
              {{ 'mysec.indicativeSharePrice' | translate }}
            </div>
            <div class="tw-text-xs tw-text-body tw-text-opacity-80 first-letter:tw-lowercase">
              {{ 'mysec.indicativeSharePriceDescriptionForValuationBasisPopup' | translate }}
            </div>
          </div>
          <div class="tw-col-span-2 tw-tabular-nums md:tw-text-right">
            {{
              portfolioListModel?.portfolio.indicativeSharePrice
                | numberSuffix: { numberFormat: '1.2-2' }
            }}
          </div>
        </div>
      </ng-template>

      <!-- Valuation -->
      <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-5">
        <div class="tw-col-span-3">
          <div class="tw-font-bold tw-text-black">{{ 'campaign.valuation' | translate }}</div>
          <!-- PLT-1471 Hide updated at for now as this is lastTradedDate instead -->
          <!-- <div *ngIf="portfolioListModel?.portfolio?.lastPriceChangedAt" class="tw-text-xs tw-text-body tw-text-opacity-80">
                        {{ 'raise.updated' | translate }} {{ portfolioListModel?.portfolio?.lastPriceChangedAt | amDateFormat: dateFormat }}
                    </div> -->
        </div>
        <div class="tw-col-span-2 tw-tabular-nums md:tw-text-right">
          {{ portfolioListModel?.portfolio.valuation | numberSuffix: { numberFormat: '1.2-2' } }}
        </div>
      </div>

      <!-- Valuation basis -->
      <div class="">
        <div class="tw-font-bold tw-text-black">{{ 'mysec.valuationBasis' | translate }}</div>
        <div>{{ portfolioListModel.portfolio?.valuationBasis || '-' }}</div>
      </div>
    </div>
  </div>
</div>
