import { isPlatformBrowser } from '@angular/common';
import { Role } from './../../core/enum/role.enum';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './../../core/services/auth/auth.service';
import { signUpTypes } from 'src/app/core/services/constant';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition('step1 => step2', [
        style({ transform: 'translateX(0)', opacity: '1' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(-20px)', opacity: '0' })),
      ]),
    ]),
  ],
})
export class SignupComponent implements OnInit {
  currentStep: string = 'step1';

  signUpTypes = signUpTypes;
  isOAuth: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platform: Object,
  ) {}

  ngOnInit() {
    // save for future used, now support social sign up fo individual investor only
    // this.isOAuth = this.activatedRoute.snapshot.data.isOAuth;

    if (isPlatformBrowser(this.platform) && this.authService.isAuthenticated()) {
      this.router.navigate(['/profile/settings/profile-details'], {
        replaceUrl: true,
      });
    }
  }

  register(signUpType: Role) {
    this.currentStep = 'step2';

    setTimeout(() => {
      this.router.navigate(['/sign-up/register', signUpType]);
    }, 250);

    // save for future used, now support social sign up fo individual investor only
    // if (!this.isOAuth) {
    //     this.router.navigate(['/sign-up/register', signUpType]);
    //     return;
    // }

    // switch (signUpType) {
    //     case Role.IndividualInvestor:
    //         this.router.navigate(['sign-up/register-individual/1']);
    //         break;
    //     case Role.CompanyRep:
    //         this.router.navigate(['sign-up/register-corporate/1']);
    //         break;
    //     case Role.IssuerRep:
    //         this.router.navigate(['sign-up/register-issuer/1']);
    //         break;
    // }
  }
}
