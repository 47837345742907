import { Role } from '../../enum/role.enum';
import { LoginProfileModel } from './login-profile.model';

export class LoginProfile {
  accessTokenExpiredAt: string;
  email: string;
  fullName: string;
  id: string;
  role: Role;

  constructor(model: LoginProfileModel) {
    this.accessTokenExpiredAt = model.accessTokenExpiredAt;
    this.email = model.email;
    this.fullName = model.fullName;
    this.id = model.id;
    model.roles.forEach((r) => {
      if ([Role.IndividualInvestor, Role.CompanyRep, Role.IssuerRep].includes(Role[r])) {
        this.role = Role[r];
      }
    });
  }
}
