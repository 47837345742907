import { ErrorService } from '../../../../core/services/error.service';
import { CampaignService } from '../../../../core/services/api/campaign.service';
import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BlockUiService } from '../../../../shared/services/blockUi/block-ui.service';
import { DefaultBanner, DefaultURLPattern } from '../../../../core/services/constant';
import { RssFeedService } from '../../../..//core/services/api/rss-feed.service';
import { News } from '../../../../core/models/news/news';
import { FeedItem } from '../../../../core/models/news/feed-item';

@Component({
  selector: 'app-campaign-news',
  templateUrl: './campaign-news.component.html',
  styleUrls: ['./campaign-news.component.scss'],
})
export class CampaignNewsComponent implements OnInit {
  @Input() campaignId: number;
  @Input() isCreator: boolean;
  @Input() rssUrl: string;
  rssData: News;
  rssFeedItems: FeedItem[] = [];
  total: number = 0;
  params = {
    currentPage: 1,
    take: 3,
  };
  isLoading: boolean = false;
  isAddNews: boolean = false;
  showFormError: boolean = false;
  form: UntypedFormGroup;
  defaultBanner = DefaultBanner;

  constructor(
    private campaignService: CampaignService,
    private errorService: ErrorService,
    private formBuilder: UntypedFormBuilder,
    private blockUiService: BlockUiService,
    private rssFeedService: RssFeedService,
  ) {}

  ngOnInit() {
    if (this.rssUrl) this.getRssFeedData();
  }

  getRssFeedData() {
    this.isLoading = true;

    this.rssFeedService
      .getFeedContent(this.rssUrl)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.rssData = data;
          this.getRssItems();
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  getRssItems() {
    let startIndex = 0;
    let endIndex = this.params.currentPage * this.params.take;
    let items: FeedItem[] = this.rssData.items;
    this.total = items.length;
    this.rssFeedItems = items.slice(startIndex, endIndex);
  }

  addNews() {
    this.isAddNews = !this.isAddNews;

    if (this.isAddNews) this.buildForm();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      rssUrl: [this.rssUrl, Validators.pattern(DefaultURLPattern)],
    });
  }

  submit() {
    if (this.form.invalid) {
      this.showFormError = true;
      return;
    }

    this.blockUiService.open();

    this.campaignService
      .updateRssFeedUrl(this.campaignId, this.form.value.rssUrl)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        () => {
          this.rssUrl = this.form.value.rssUrl;
          this.isAddNews = !this.isAddNews;

          if (this.rssUrl) this.getRssFeedData();
          else {
            this.rssFeedItems = [];
            this.total = 0;
          }
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  loadMore() {
    this.params.currentPage++;
    this.getRssItems();
  }

  cancel() {
    this.isAddNews = !this.isAddNews;
  }

  openUrl(url: string) {
    window.open(url);
  }
}
