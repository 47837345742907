import { PopupService } from './../../../../shared/services/popup/popup.service';
import { BlockUiService } from './../../../../shared/services/blockUi/block-ui.service';
import { ErrorService } from './../../../../core/services/error.service';
import { CampaignService } from './../../../../core/services/api/campaign.service';
import { AccountService } from './../../../../core/services/api/account.service';
import { UserProfile } from './../../../../core/models/user/user-profile';
import { Question } from './../../../../core/models/campaign/question';
import { DateAgoPipe } from '../../../../shared/pipes/date-ago.pipe';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Moment } from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { PagedResult } from '../../../../shared/models/paged-result/paged-result';
import { StorageService } from '../../../../core/services/storage.service';
import { Role } from '../../../../core/enum/role.enum';
import { DefaultProfileImage } from '../../../../core/services/constant';
import { ActivatedRoute } from '@angular/router';
import { GtmDataLayerService } from 'src/app/core/services/gtm-data-layer.service';

@Component({
  selector: 'app-qa-list',
  templateUrl: './qa-list.component.html',
  styleUrls: ['./qa-list.component.scss'],
})
export class QaListComponent implements OnInit {
  @Input() companyName: string;
  @Input() campaignId: number;
  @Output() onUpdate = new EventEmitter();

  questions: Question[] = [];
  questionsTotal: number;
  profile: UserProfile;
  questionContent: string;
  replyContent: string;
  params = {
    currentPage: 1,
    take: 10,
  };
  isLoading: boolean = false;
  showFormError: boolean = false;
  showAnswerFormError: boolean = false;
  isIssuer: boolean = false;
  defaultProfileImage = DefaultProfileImage;
  questionId: number;
  parentQuestionId: number;

  @ViewChild('form', { read: NgForm }) form: NgForm;
  @ViewChild('answerForm', { read: NgForm }) answerForm: NgForm;

  constructor(
    private dateAgoPipe: DateAgoPipe,
    private translateService: TranslateService,
    private accountService: AccountService,
    private campaignService: CampaignService,
    private errorService: ErrorService,
    private blockUiService: BlockUiService,
    private popupService: PopupService,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private _gtmDataLayerService: GtmDataLayerService,
  ) {}

  ngOnInit() {
    this.isIssuer = this.storageService.role == Role.IssuerRep;
    this.questionId = this.activatedRoute.snapshot.queryParams.questionId;
    this.parentQuestionId = this.activatedRoute.snapshot.queryParams.parentQuestionId;

    this.accountService.profile(true).subscribe({
      next: (profile) => (this.profile = profile),
      error: (err) => this.errorService.showError(err),
    });

    this.getQuestionList(true);
  }

  getQuestionList(reload?: boolean) {
    if (reload) {
      this.questions = [];
      this.params.currentPage = 1;
    }

    this.isLoading = true;

    this.campaignService
      .getQuestions(
        this.campaignId,
        this.params.currentPage,
        this.params.take,
        this.questionId,
        this.parentQuestionId,
      )
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.questions = this.questions.concat(data.data);
          this.questionsTotal = data.total;
        },
        error: (err) => this.errorService.showError(err),
      });
  }

  getAnswerList(question: Question, isReply?: boolean, isDelete?: boolean) {
    if (!question.answers) {
      question.answers = { data: [] } as PagedResult<Question>;
    }

    let skip = question.answers.data.length;
    let take = this.params.take;

    if (isReply) {
      skip = 0;
      take = isDelete ? question.answers.data.length : question.answers.data.length + 1;
      question.answers = {
        data: [],
        total: 0,
      };
    }

    this.campaignService.getAnswers(this.campaignId, question.id, skip, take).subscribe({
      next: (data) => {
        question.answers.data = question.answers.data.concat(data.data);
        question.answers.total = data.total;
      },
      error: (err) => this.errorService.showError(err),
    });
  }

  loadMore() {
    this.params.currentPage++;
    this.getQuestionList();
  }

  transformDate(publishDate: Moment) {
    if (!publishDate) return;

    let dateAgo = this.dateAgoPipe.transform(publishDate);

    return dateAgo.counter > 0
      ? dateAgo.counter +
          ' ' +
          this.translateService.instant(dateAgo.interval) +
          this.translateService.instant(dateAgo.text)
      : this.translateService.instant(dateAgo.text);
  }

  replyQuestion(question: Question) {
    question.showReply = true;

    this.questions.forEach((q) => {
      if (q.id != question.id) q.showReply = false;

      let answers = q.answers;

      if (answers && answers.data && answers.data.length > 0) {
        answers.data.forEach((sa) => {
          if (sa.id != question.id) sa.showReply = false;
        });
      }
    });
  }

  submitQuestion() {
    if (this.form.invalid) {
      this.showFormError = true;
      return;
    }

    this.blockUiService.open();

    this.campaignService
      .addQuestion(this.campaignId, this.questionContent)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe({
        next: () => {
          this.popupService.success('campaign.questionAdded').then(() => {
            this.questionContent = null;
            this.getQuestionList(true);
          });
          this.getStatisticCount();
          this.onUpdate.emit();
        },
        error: (err) => this.errorService.showError(err),
      });
  }

  submitAnswer(question: Question) {
    if (this.answerForm.invalid) {
      this.showAnswerFormError = true;
      return;
    }

    this.blockUiService.open();

    this.campaignService
      .addAnswer(this.campaignId, question.id, this.replyContent)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe({
        next: () => {
          this.popupService.success('campaign.repliesAdded').then(() => {
            this.replyContent = null;
            question.showReply = false;
            this.getAnswerList(question, true);
          });
        },
        error: (err) => this.errorService.showError(err),
      });
  }

  deleteQuestion(questionId: number) {
    this.popupService.confirm('campaign.confirmDeleteQuestion').then(
      () => {
        this.blockUiService.open();

        this.campaignService
          .deleteQuestion(questionId, this.campaignId)
          .pipe(finalize(() => this.blockUiService.close()))
          .subscribe({
            next: () => this.getQuestionList(true),
            error: (err) => this.errorService.showError(err),
          });
      },
      () => {},
    );
  }

  deleteAnswer(question: Question, answerId: number) {
    this.popupService.confirm('campaign.confirmDeleteAnswer').then(
      () => {
        this.blockUiService.open();

        this.campaignService
          .deleteAnswer(answerId, question.id, this.campaignId)
          .pipe(finalize(() => this.blockUiService.close()))
          .subscribe({
            next: () => this.getAnswerList(question, true, true),
            error: (err) => this.errorService.showError(err),
          });
      },
      () => {},
    );
  }

  getStatisticCount() {
    this.campaignService.getStatisticCount(this.campaignId).subscribe({
      next: (data) => this.campaignService.statisticCount.next(data),
      error: (err) => this.errorService.showError(err),
    });
  }
}
