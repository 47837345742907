<article class="tw-flex tw-flex-col tw-px-12 tw-py-12 xl:tw-py-0" [ngClass]="wrapperClass">
  <h1 class="tw-text-2xl tw-font-bold" *ngIf="showTitle">
    {{ 'twoFactor.authAppverification' | translate }}
  </h1>
  <h2 *ngIf="h2" class="tw-font-body tw-text-xl tw-font-medium">
    {{ h2 | translate }}
  </h2>

  <p class="tw-text-base">
    {{ description | translate }}
  </p>

  <form
    (ngSubmit)="onSubmit()"
    [formGroup]="form"
    class="tw-mt-4 tw-flex tw-w-full tw-flex-col"
    [class.show-form-error]="showFormError"
  >
    <input type="text" formControlName="otp" class="form-control" />
    <app-validation-error-message
      [inputName]="'twoFactor.code' | translate"
      [showFormError]="showFormError"
      [messageForMinLength]="'Minimum 6 characters required.'"
      [control]="form.get('otp')"
    >
    </app-validation-error-message>

    <ng-content select="[before-cta]"></ng-content>

    <button
      class="tw-mt-4 tw-w-full tw-cursor-pointer tw-rounded tw-bg-primary tw-py-2 tw-font-semibold tw-text-white"
      type="submit"
    >
      {{ 'common.verify' | translate }}
    </button>
  </form>
</article>
