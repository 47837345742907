import * as Sentry from "@sentry/angular-ivy";
import { environment } from "./environments/environment";

Sentry.init({
    environment: environment.environment,
    dsn: environment.sentryDsn,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment.environment === "PRODUCTION" ? 1.0 : 0.5,
    release: "v2.2.19",
    // This sets the sample rate to be 30%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: environment.environment === "PRODUCTION" ? 0.5 : 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: environment.environment === "PRODUCTION" ? 1 : 0,
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: [ environment.apiServer ],
            routingInstrumentation: Sentry.routingInstrumentation
        }),
        new Sentry.Replay({
            // Prevent masking all text, by default inputs value will be masked
            maskAllText: false,
        })
    ],
    ignoreErrors: [
        /Non-Error exception captured with keys/
    ],
    denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
    ],
    enabled: environment.production ?? false
})
