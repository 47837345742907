import { Injectable } from '@angular/core';
import { Country } from 'country-state-city';
import { getCountryCodeForRegionCode } from 'awesome-phonenumber';
import { CountryCode } from '../models/country/country-code';
import { Timezones } from 'country-state-city/lib/interface';

@Injectable()
export class CountriesService {
  constructor() {}

  getCountryList(): CountryCode[] {
    let countries = Country.getAllCountries();

    return countries.map((country) => {
      return {
        name: country.name,
        isoCode: country.isoCode,
        phonecode: getCountryCodeForRegionCode(country.isoCode).toString(),
      };
    });
  }

  getSortedCountryList(countryCodes: string[]): CountryCode[] {
    const allCountries = this.getCountryList();

    const prioritizedCountries = allCountries.filter((country) =>
      countryCodes.includes(country.isoCode),
    );
    const otherCountries = allCountries
      .filter((country) => !countryCodes.includes(country.isoCode))
      .sort((a, b) => a.name.localeCompare(b.name));

    return [...prioritizedCountries, ...otherCountries];
  }

  getTimeZones(): Timezones[] {
    let countries = Country.getAllCountries();
    let timezones: Timezones[] = [];

    countries.forEach((country) => {
      if (country.timezones) timezones = timezones.concat(country.timezones);
    });

    return timezones.sort((a, b) => a.zoneName.localeCompare(b.zoneName));
  }
}
