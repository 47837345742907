import { PopupService } from '../../shared/services/popup/popup.service';
import { Injectable } from '@angular/core';
import { BlockUiService } from '../../shared/services/blockUi/block-ui.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from './toast.service';
// import { LoggingService } from './logging.service';

@Injectable()
export class ErrorService {
  isPopupShowing: boolean = false;

  constructor(
    private popupService: PopupService,
    private blockUiService: BlockUiService,
    private toastService: ToastService,
    private translateService: TranslateService,
    // private loggingService: LoggingService
  ) {}

  // Note: Add `useToastAsFallback` as parameter as not wanted to affect all components that using this function
  // Can remove in future if needed
  showError(error: any, closePopupCallback?: Function, useToastAsFallback: boolean = false) {
    if (this.isPopupShowing) return;

    this.blockUiService.close();
    let errorMessage: string;

    if (error && error.Error && error.Error.Message) errorMessage = error.Error.Message;
    else if (error && error.error && error.error.message) errorMessage = error.error.message;
    else if (error && error.ErrorMessage) errorMessage = error.ErrorMessage;

    if (errorMessage) {
      this.isPopupShowing = true;
      this.popupService.alert(errorMessage).finally(() => {
        this.isPopupShowing = false;
        if (closePopupCallback) closePopupCallback(error);
      });
    } else if (useToastAsFallback) {
      this.toastService.error(this.translateService.instant('error.general'));
    }
  }

  // handleError(error: Error) {
  //     this.loggingService.logException(error); // Manually log exception
  // }
}
