import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { ContainerType } from '../../../../core/enum/container-type.enum';
import { Attachment } from '../../../../core/models/attachment/attachment';
import { AttachmentInfo } from '../../../../core/models/attachment/attachment-info';
import { FileUploadOption } from '../../../../core/models/attachment/file-upload-option';
import { AccountService } from '../../../../core/services/api/account.service';
import { PDFFileFormat } from '../../../../core/services/constant';
import { ErrorService } from '../../../../core/services/error.service';
import { FileValidationService } from '../../../../core/services/file-validation.service';
import { PopupService } from '../../../../shared/services/popup/popup.service';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss'],
})
export class UploadDocumentComponent implements OnInit {
  document: Attachment;
  returnFile = false;
  showFormError: boolean = false;
  form: UntypedFormGroup;
  fileTypes: string[] = PDFFileFormat;
  isLoading = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private popupService: PopupService,
    private accountService: AccountService,
    private errorService: ErrorService,
    private fileValidationService: FileValidationService,
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  async selectFile(file: File) {
    const uploadOption: FileUploadOption = {
      fileType: this.fileTypes,
      errorMsg: this.fileValidationService.errorMessage(this.fileTypes),
    };

    if (await this.fileValidationService.validFile(file, uploadOption)) {
      this.document.fileName = file.name;
      this.document.file = file;
      this.form.controls.fileName.setValue(this.generateFileName(file.name));

      if (this.returnFile) return;

      this.isLoading = true;

      this.accountService
        .uploadAttachmentPresignedURL(file, ContainerType.Company)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          (data) => {
            this.document.attachmentInfo = new AttachmentInfo(data);
            this.document.url = data.url;
          },
          (err) => {
            this.errorService.showError(err);
            this.document.file = null;
          },
        );
    }
  }

  buildForm() {
    this.form = this.formBuilder.group({
      fileName: ['', Validators.required],
    });
  }

  confirm() {
    if (!this.document.file) {
      this.popupService.alert('company.pleaseUploadDocument');
      return;
    }

    if (this.form.invalid) {
      this.showFormError = true;
      return;
    }

    this.document.fileName = this.form.value.fileName;
    this.activeModal.close(this.document);
  }

  cancel() {
    this.activeModal.dismiss();
  }

  generateFileName(fileName: string) {
    if (!fileName) return '';

    return fileName.split('.').slice(0, -1).join('-');
  }
}
