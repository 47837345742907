import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { RaiseFundApplicationProcessStatusType } from '../../../core/enum/raise-fund-application-process-status-type.enum';
import { ShareType } from '../../../core/enum/share-type.enum';
import { OfferCreation } from '../../../core/models/raise/offer-creation';
import { RaiseService } from '../../../core/services/api/raise.service';
import { MaxBonusLimit, OneBillionWithTwoDecimalPattern } from '../../../core/services/constant';
import { ErrorService } from '../../../core/services/error.service';
import { BlockUiService } from '../../../shared/services/blockUi/block-ui.service';

@Component({
  selector: 'app-offer-creation',
  templateUrl: './offer-creation.component.html',
  styleUrls: ['./offer-creation.component.scss'],
})
export class OfferCreationComponent implements OnInit {
  fundRaiseApplicationId: number;
  fundRaiseApplicationName: string;
  offerCreation: OfferCreation;
  isViewBonus: boolean = true;
  showFormError: boolean = false;
  form: UntypedFormGroup;
  isLoading = false;

  ShareType: typeof ShareType = ShareType;
  RaiseFundApplicationProcessStatusType: typeof RaiseFundApplicationProcessStatusType =
    RaiseFundApplicationProcessStatusType;
  OneBillionWithTwoDecimalPattern = OneBillionWithTwoDecimalPattern;
  MaxBonusLimit = MaxBonusLimit;

  constructor(
    private blockUiService: BlockUiService,
    private raiseService: RaiseService,
    private errorService: ErrorService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.fundRaiseApplicationId = this.activatedRoute.snapshot.params.fundRaiseApplicationId;

    this.buildForm();
    this.getApplications();
    this.getOfferCreation();
  }

  buildForm() {
    this.showFormError = false;

    this.form = this.formBuilder.group({
      bonusPeriodStartDate: [''],
      bonusPeriodEndDate: [''],
      enableBonusShare: [false, Validators.required],
      bonusShares: this.formBuilder.array([]),
    });
  }

  getApplications() {
    this.isLoading = true;

    this.raiseService
      .getRaiseFundApplications()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          let fundRaiseApplications = data;
          let application = fundRaiseApplications.find(
            (application) => application.id == this.fundRaiseApplicationId,
          );
          this.fundRaiseApplicationName = application ? application.name : null;
        },
        error: (err) => this.errorService.showError(err),
      });
  }

  getOfferCreation() {
    this.isLoading = true;

    this.raiseService
      .getOfferCreation(this.fundRaiseApplicationId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.offerCreation = data;
          this.patchFormValue();
        },
        error: (err) => this.errorService.showError(err),
      });
  }

  patchFormValue() {
    this.form.patchValue(this.offerCreation);
    this.hideBonusShare();

    if (this.offerCreation.bonusShares && this.offerCreation.bonusShares.length > 0) {
      this.offerCreation.bonusShares.sort(function (a, b) {
        return a.investmentLots - b.investmentLots;
      });

      this.offerCreation.bonusShares.forEach((bonus) => {
        bonus.shares = Math.round(bonus.investmentLots / this.offerCreation.pricePerShare);
        bonus.bonusShares = Math.round((bonus.shares * bonus.bonusPercentage) / 100);
        bonus.totalShares = bonus.shares + bonus.bonusShares;
        let bonusForm = this.buildBonusForm();
        bonusForm.patchValue(bonus);
        this.bonusFormControl.push(bonusForm);
      });
    }
  }

  buildBonusForm() {
    return this.formBuilder.group({
      id: [null],
      investmentLots: [
        0,
        [Validators.required, Validators.pattern(/^(?=.*[1-9])[0-9]*[.]?[0-9]*$/)],
      ],
      bonusPercentage: [
        0,
        [
          Validators.required,
          Validators.pattern(/^(?=.*[1-9])[0-9]*[.]?[0-9]*$/),
          Validators.max(MaxBonusLimit),
        ],
      ],
      shares: [0],
      bonusShares: [0],
      totalShares: [0],
    });
  }

  get bonusFormControl() {
    return <UntypedFormArray>this.form.controls.bonusShares;
  }

  addBonus() {
    if (this.bonusFormControl.invalid) {
      this.showFormError = true;
      return;
    }

    this.bonusFormControl.push(this.buildBonusForm());
    this.showFormError = false;
  }

  removeBonus(index: number) {
    if (this.bonusFormControl.controls.length > 1) this.bonusFormControl.removeAt(index);
  }

  editBonusShare() {
    this.isViewBonus = false;

    if (!this.form.value.enableBonusShare) {
      this.addBonus();
    }
  }

  cancelEditBonus() {
    this.isViewBonus = true;
    this.buildForm();
    this.patchFormValue();
  }

  submit(approve: boolean, approveBonusShare: boolean) {
    if (!this.isViewBonus) {
      if (!this.form.value.enableBonusShare) {
        this.bonusFormControl.clear();
        this.bonusFormControl.updateValueAndValidity();
      }

      if (this.form.invalid) {
        this.showFormError = true;
        return;
      }
    }

    let offerCreation = this.form.value;

    this.blockUiService.open();

    this.raiseService
      .reviewOfferCreation(this.fundRaiseApplicationId, offerCreation, approve, approveBonusShare)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe({
        next: (data) => this.back(),
        error: (err) => this.errorService.showError(err),
      });
  }

  back() {
    this.location.back();
  }

  hideBonusShare() {
    if (this.form.value.enableBonusShare) {
      this.form.controls.bonusPeriodStartDate.setValidators(Validators.required);
      this.form.controls.bonusPeriodEndDate.setValidators(Validators.required);
    } else {
      this.form.controls.bonusPeriodStartDate.clearValidators();
      this.form.controls.bonusPeriodEndDate.clearValidators();
    }

    this.form.controls.bonusPeriodStartDate.updateValueAndValidity();
    this.form.controls.bonusPeriodEndDate.updateValueAndValidity();
    this.bonusFormControl.updateValueAndValidity();
  }

  getShare(item: UntypedFormGroup) {
    item.controls['shares'].setValue(
      Math.round(item.value.investmentLots / this.offerCreation.pricePerShare),
    );
    item.controls['bonusShares'].setValue(
      Math.round((item.value.shares * item.value.bonusPercentage) / 100),
    );
    item.controls['totalShares'].setValue(item.value.shares + item.value.bonusShares);
  }
}
