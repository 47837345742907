<main class="container-fluid tw-flex tw-flex-col tw-items-center tw-py-12">
  <a
    class="tw-flex tw-items-center tw-gap-4 tw-self-start tw-bg-transparent"
    routerLink="/profile/settings/security"
    *ngIf="isViewOnly"
  >
    <i class="fa fa-arrow-left" aria-hidden="true"></i>
    <p class="tw-m-0 tw-font-semibold tw-text-black hover:tw-underline">
      {{ 'common.back' | translate }}
    </p>
  </a>
  <section class="tw-flex tw-max-w-[35rem] tw-flex-col tw-items-center tw-gap-8 tw-py-4">
    <div>
      <h1 class="text-2xl tw-text-center tw-font-bold">
        {{ 'twoFactor.backupCodes' | translate }}
      </h1>
      <h2 class="tw-text-center tw-text-base">
        {{ 'twoFactor.backupCodesDesc' | translate }}
      </h2>
    </div>
    <article
      class="tw-grid tw-w-full tw-grid-cols-2 tw-gap-4 tw-rounded tw-border tw-border-primary tw-p-8"
    >
      <p
        *ngFor="let backupCode of backupCodes"
        class="rounded tw-m-0 tw-flex tw-items-center tw-justify-center tw-bg-primary tw-bg-opacity-10 tw-px-2 tw-py-4 tw-text-center tw-text-xl tw-font-semibold tw-proportional-nums tw-text-primary"
      >
        {{ backupCode }}
      </p>
    </article>
    <form
      [formGroup]="form"
      (ngSubmit)="handleContinue()"
      *ngIf="!isViewOnly"
      class="tw-flex tw-w-full tw-flex-col tw-gap-4"
      [class.show-form-error]="showFormError"
    >
      <label class="tw-m-0 tw-self-start tw-text-sm tw-text-black">
        <mat-checkbox formControlName="isAccept"></mat-checkbox>
        {{ 'twoFactor.safelyRecordedCode' | translate }}</label
      >
      <button
        class="tw-w-full tw-cursor-pointer tw-rounded tw-bg-primary tw-py-4 tw-text-center tw-font-semibold tw-text-white hover:tw-text-white"
      >
        {{ 'common.continue' | translate }}
      </button>
    </form>
  </section>
</main>
