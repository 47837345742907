import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Campaign } from '../../../core/models/campaign/campaign';
import { CampaignInfo } from '../../../core/models/campaign/campaign-info';
import { BusinessDirectoryInfo } from '../../../core/models/business-directory/business-directory-info';

@Component({
  selector: 'campaign-header',
  templateUrl: './campaign-header.component.html',
  styleUrls: ['./campaign-header.component.scss'],
})
export class CampaignHeaderComponent {
  @Input() campaign: Campaign;
  @Input() campaignInfo: CampaignInfo;
  @Input() businessDirectoryInfo: BusinessDirectoryInfo;
  @Input() isLogin: boolean = false;
  @Input() isInvestNowLoading: boolean;
  @Input() isCampaignEnded: boolean;

  @Output() saveCampaign = new EventEmitter();
  @Output() investNow = new EventEmitter();
  @Output() onInvestorCountLoaded = new EventEmitter();

  handleSaveCampaign() {
    this.saveCampaign.emit();
  }

  handleInvestNow() {
    this.investNow.emit();
  }

  handleInvestorCountLoaded(count) {
    this.onInvestorCountLoaded.emit(count);
  }
}
