import { FinancialSummaryModel } from './financial-summary.model';

export class FinancialSummary {
  previousClose: number;
  lastDoneTransactionPrice: number;
  startRange: number;
  endRange: number;
  volumeFirstTrade: number;
  volumeThisWeek: number;
  volumeLast30Days: number;
  indicativePrice: number;
  changesPercentage: number;
  changesValue: number;

  constructor(model: FinancialSummaryModel) {
    this.previousClose = model.previousClose;
    this.lastDoneTransactionPrice = model.lastDoneTransactionPrice;
    this.startRange = model.startRange;
    this.endRange = model.endRange;
    this.volumeFirstTrade = model.volumeFirstTrade;
    this.volumeThisWeek = model.volumeThisWeek;
    this.volumeLast30Days = model.volumeLast30Days;
    this.changesPercentage = model.changesPercentage;
    this.changesValue = model.changesValue;
    this.indicativePrice = model.indicativePrice;
  }
}
