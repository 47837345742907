<!-- General statistics for current campaign -->
<section>
  <h2 class="p-text-h3 tw-mb-5">{{ 'campaign.campaignDetails' | translate }}</h2>

  <div class="tw-rounded-10px tw-border tw-border-divider">
    <div
      class="tw-grid tw-divide-y tw-divide-divider md:tw-grid-cols-3 md:tw-divide-x md:tw-divide-y-0"
    >
      <div *ngFor="let col of campaignDetails">
        <ul class="tw-mb-0 tw-list-none tw-p-8" [class]="col.wrapperClass">
          <li *ngFor="let item of col.children" class="tw-text-black">
            <label class="p-text-label tw-mb-0">{{ item.name | translate }}</label>

            <div *ngIf="item.isCurrency; else itemValue">
              {{ item.value | numberSuffix }}
            </div>

            <ng-template #itemValue>
              <div>{{ item.value }}</div>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
