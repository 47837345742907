<div class="tw-mb-2.5">
  <app-date-range-picker
    (goSearch)="filterByDate($event)"
    [dateFrom]="params.transactionDateFrom"
    [dateTo]="params.transactionDateTo"
    [maxRange]="maxDateRange"
    [readonly]="true"
    [col]="3"
    [showClear]="false"
  >
  </app-date-range-picker>
</div>

<ng-container *ngIf="!isLoading">
  <div class="row" *ngIf="!isLoading && portfolioHistoryList && portfolioHistoryList.length > 0">
    <div class="col-12">
      <div class="p-table__wrapper">
        <div class="p-table__body">
          <mat-table class="responsive-table p-table" [dataSource]="portfolioHistoryList">
            <ng-container matColumnDef="OrderNo">
              <mat-header-cell *matHeaderCellDef>{{ 'mysec.orderNo' | translate }}</mat-header-cell>
              <mat-cell
                *matCellDef="let item"
                [ngClass]="'tw-break-all tw-text-xs tw-tabular-nums'"
              >
                <ng-container
                  *ngIf="
                    item.transactionHistory?.orderNo &&
                      item.shareMarketStatus !== ShareMarketStatus.Dehosting;
                    else orderNo
                  "
                >
                  <a
                    class="p-link-primary p-link-underline"
                    [routerLink]="['/dashboard', 'orders']"
                    [queryParams]="{ filterString: item.transactionHistory?.orderNo }"
                    target="_blank"
                  >
                    <ng-container *ngTemplateOutlet="orderNo"></ng-container>
                  </a>
                </ng-container>

                <ng-template #orderNo>{{ item.transactionHistory?.orderNo || '-' }}</ng-template>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Time">
              <mat-header-cell *matHeaderCellDef [ngClass]="'tw-justify-end'">{{
                'common.date' | translate
              }}</mat-header-cell>
              <mat-cell
                *matCellDef="let item"
                [ngClass]="'tw-justify-end tw-text-right tw-text-xs tw-tabular-nums'"
              >
                <div class="text-black">
                  {{ item.transactionHistory?.createdAt | amDateFormat: 'DD MMM yyyy,' }} <br />
                  {{ item.transactionHistory?.createdAt | amDateFormat: 'hh:mm a' }}
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Type">
              <mat-header-cell *matHeaderCellDef>{{ 'common.type' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let item">
                <div class="d-flex align-items-center">
                  <div
                    class="mr-2 pitchin-icon-md"
                    [class.pitchin-icon-withdraw]="
                      item.transactionHistory?.shareFlow == ShareFlow.Debit
                    "
                    [class.pitchin-icon-deposit]="
                      item.transactionHistory?.shareFlow == ShareFlow.Credit
                    "
                  ></div>
                  <div class="text-black">
                    {{ 'portfolio.' + ShareFlow[item.transactionHistory?.shareFlow] | translate }}
                  </div>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Event">
              <mat-header-cell *matHeaderCellDef>{{ 'common.event' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let item">
                <div class="text-black">
                  {{
                    'portfolio.' + ActivityStatus[item.transactionHistory?.activityStatus]
                      | translate
                  }}
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Company">
              <mat-header-cell *matHeaderCellDef
                >{{ 'mysec.companyAndCounter' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let item">
                <app-thumbnail-group
                  [name]="item?.company?.brandName"
                  [description]="item.tickerCode ? item?.tickerCode.toUpperCase() : null"
                  [imageUrl]="item?.company?.companyLogoUrl"
                >
                </app-thumbnail-group>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ShareQty">
              <mat-header-cell *matHeaderCellDef [ngClass]="'tw-justify-end'"
                >{{ 'portfolio.shareQty' | translate }}
              </mat-header-cell>
              <mat-cell
                *matCellDef="let item"
                [ngClass]="'tw-justify-end tw-text-xs tw-tabular-nums'"
              >
                <ng-container
                  *ngIf="
                    item.transactionHistory &&
                      (item.transactionHistory?.shareQuantity ||
                        item.transactionHistory?.shareQuantity == 0);
                    else empty
                  "
                >
                  <span
                    [ngClass]="
                      item.transactionHistory.shareQuantity > 0
                        ? 'text-success'
                        : item.transactionHistory.shareQuantity < 0
                          ? 'text-primary'
                          : 'text-light-grey'
                    "
                  >
                    {{
                      item.transactionHistory.shareQuantity > 0
                        ? '+'
                        : item.transactionHistory.shareQuantity < 0
                          ? '-'
                          : ''
                    }}
                    {{
                      item.transactionHistory.shareQuantity < 0
                        ? -1 * item.transactionHistory.shareQuantity
                        : item.transactionHistory.shareQuantity
                    }}
                  </span>
                </ng-container>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="PricePerShare">
              <mat-header-cell *matHeaderCellDef [ngClass]="'tw-justify-end'"
                >{{ 'offerCreation.pricePerShare' | translate }}
              </mat-header-cell>
              <mat-cell
                *matCellDef="let item"
                [ngClass]="'tw-justify-end tw-text-xs tw-tabular-nums'"
              >
                <div class="text-black">
                  {{
                    (item.transactionHistory?.pricePerShare
                      | numberSuffix: { numberFormat: '1.2-2' }) || '-'
                  }}
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Amount">
              <mat-header-cell *matHeaderCellDef [ngClass]="'tw-justify-end'"
                >{{ 'investment.investedAmount' | translate }}
              </mat-header-cell>
              <mat-cell
                *matCellDef="let item"
                [ngClass]="'tw-justify-end tw-text-xs tw-tabular-nums'"
              >
                <ng-container
                  *ngIf="
                    item.transactionHistory &&
                      (item.transactionHistory?.amount || item.transactionHistory?.amount == 0);
                    else empty
                  "
                >
                  <span
                    [ngClass]="
                      item.transactionHistory.amount > 0
                        ? 'text-success'
                        : item.transactionHistory.amount < 0
                          ? 'text-primary'
                          : 'text-light-grey'
                    "
                  >
                    {{
                      item.transactionHistory.amount > 0
                        ? '+'
                        : item.transactionHistory.amount < 0
                          ? '-'
                          : ''
                    }}
                    {{
                      (item.transactionHistory.amount < 0
                        ? -1 * item.transactionHistory.amount
                        : item.transactionHistory.amount
                      ) | numberSuffix: { numberFormat: '1.2-2' }
                    }}
                  </span>
                </ng-container>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayColumns"> </mat-header-row>
            <mat-row *matRowDef="let item; columns: displayColumns"> </mat-row>
          </mat-table>
        </div>
      </div>
      <div class="tw-mt-2.5 tw-flex tw-flex-row tw-justify-end">
        <button
          type="button"
          class="p-link-underline p-link-primary tw-flex tw-h-fit tw-items-center tw-font-medium hover:tw-no-underline"
          (click)="export()"
        >
          <img
            src="../../../../assets/img/icons/common/icon-download.svg"
            alt="download"
            class="tw-mr-1 tw-h-5"
          />
          <div>
            {{ 'common.exportPdf' | translate }}
          </div>
        </button>
      </div>
    </div>
    <!--Hide Blockchain, will use in phase 2-->
    <!-- <div class="mt-4 col-12 d-flex">
            <div class="mr-2 pitchin-icon-md pitchin-icon-blockchain"></div>
            {{ 'portfolio.blockchainDesc' | translate }}
        </div> -->
  </div>
  <div
    class="py-4 text-center col-12"
    *ngIf="!isLoading && (!portfolioHistoryList || portfolioHistoryList.length == 0)"
  >
    {{ 'common.noRecord' | translate }}
  </div>
  <div class="row" *ngIf="total > params.take">
    <div class="col-lg-12">
      <div class="mt-3 paging">
        <ngb-pagination
          [collectionSize]="total"
          [(pageSize)]="params.take"
          [(page)]="params.currentPage"
          (pageChange)="getPortfolioHistory()"
          [maxSize]="5"
          [rotate]="true"
        >
          <ng-template ngbPaginationPrevious><i class="fa fa-chevron-left"></i></ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
          <ng-template ngbPaginationNext><i class="fa fa-chevron-right"></i></ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</ng-container>

<agmo-loading *ngIf="isLoading"></agmo-loading>
<ng-template #empty>-</ng-template>
