import { Location } from '@angular/common';
import {
  Component,
  DEFAULT_CURRENCY_CODE,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EkycStatus } from '../../../../../core/enum/ekyc-status.enum';
import { GtmPaymentButton } from '../../../../../core/enum/gtm-payment-button.enum';
import { ReceiptType } from '../../../../../core/enum/receipt-type.enum';
import { PaymentType } from '../../../../../core/enum/payment-type.enum';
import { Role } from '../../../../../core/enum/role.enum';
import { AttachmentInfo } from '../../../../../core/models/attachment/attachment-info';
import { FileUploadOption } from '../../../../../core/models/attachment/file-upload-option';
import { Campaign } from '../../../../../core/models/campaign/campaign';
import { InvestmentModel } from '../../../../../core/models/investment/investment.model';
import { Payment } from '../../../../../core/models/payment/payment';
import { PaymentMethodInfo } from '../../../../../core/models/payment/payment-method-info';
import { SettingService } from '../../../../../core/services/api/setting.service';
import { ImageFileFormat, PDFFileFormat } from '../../../../../core/services/constant';
import { ErrorService } from '../../../../../core/services/error.service';
import { FileValidationService } from '../../../../../core/services/file-validation.service';
import { GtmDataLayerService } from '../../../../../core/services/gtm-data-layer.service';
import { StorageService } from '../../../../../core/services/storage.service';
import { PopupService } from '../../../../../shared/services/popup/popup.service';
import { PaymentOption } from '../../../../../core/models/payment/payment-option';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-process',
  templateUrl: './payment-process.component.html',
  styleUrls: ['./payment-process.component.scss'],
})
export class PaymentProcessComponent implements OnInit {
  @Input() paymentAmount: number;
  @Input() referenceNo: string;
  @Input() lotAmount: number;
  @Input() isLoading: boolean;
  @Input() continueLink: string;
  @Input() document: File;
  @Input() campaign: Campaign;
  @Input() investment: InvestmentModel;
  @Input() receiptType = ReceiptType.Investment;

  @Input() isDuitNowEnabled: boolean = false;
  @Input() isPSTX: boolean = false;
  @Output() choosePayment = new EventEmitter<Payment>();
  @Output() nextStep = new EventEmitter<{ isNext: boolean; paymentType: PaymentType }>();
  @Output() uploadReceipt = new EventEmitter<File>();

  isHelpCollapsed: boolean = true;
  paymentMethodInfo: PaymentMethodInfo;
  form: UntypedFormGroup;
  showFormError: boolean = false;
  accept: string = PDFFileFormat.concat(ImageFileFormat).toString();
  attachmentInfo: AttachmentInfo;
  role: Role;

  paymentOptions: PaymentOption[] = [
    {
      key: PaymentType.MaybankFPX,
      value: PaymentType[PaymentType.MaybankFPX],
      show: () => {
        // (legacy) during transition period, only some ECF campaigns will use this method
        return !this.isDuitNowEnabled;
      },
    },
    {
      key: PaymentType.DuitNow,
      value: PaymentType[PaymentType.DuitNow],
      show: () => {
        // Note (PLT-1338): only enable duitnow for pstx campaign,
        // for ECF campaign, only show in non-production environment
        return (
          this.isPSTX || (!this.isPSTX && this.isDuitNowEnabled && environment.flagToShowDuitNow)
        );
      },
    },
    {
      key: PaymentType.BankIn,
      value: PaymentType[PaymentType.BankIn],
    },
    {
      key: PaymentType.Cheque,
      value: PaymentType[PaymentType.Cheque],
    },
    {
      key: PaymentType.Swift,
      value: PaymentType[PaymentType.Swift],
    },
  ];

  ReceiptType = ReceiptType;
  PaymentType = PaymentType;
  EkycStatus = EkycStatus;
  Role = Role;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private settingService: SettingService,
    private storageService: StorageService,
    private fileValidationService: FileValidationService,
    private popupService: PopupService,
    private _gtmDataLayerService: GtmDataLayerService,
    @Inject(DEFAULT_CURRENCY_CODE) private defaultCurrencyCode: string,
    private errorService: ErrorService,
    private location: Location,
  ) {}

  ngOnInit() {
    this.role = this.storageService.role;
    this.buildForm();
    this.getPaymentMethodInfo();

    // Set the first available payments
    const firstAvailablePaymentMethod = this.paymentOptions.find(
      (option) => option.show?.() ?? true,
    );
    if (firstAvailablePaymentMethod) {
      this.selectPaymentType(PaymentType[firstAvailablePaymentMethod.value]);
    }

    if (
      (this.role == Role.IndividualInvestor && this.paymentAmount > 30000) ||
      (this.role == Role.CompanyRep && this.paymentAmount > 1000000)
    ) {
      this.form.controls.paymentType.setValue(PaymentType.Cheque);
    }

    if (this.role == Role.IssuerRep)
      this.paymentOptions = this.paymentOptions.filter(
        (type) => type.key != PaymentType.MaybankFPX,
      );
  }

  buildForm() {
    this.form = this.formBuilder.group({
      paymentType: [
        this.isDuitNowEnabled ? PaymentType.DuitNow : PaymentType.MaybankFPX,
        Validators.required,
      ],
    });
  }

  getPaymentMethodInfo() {
    let observable = this.isPSTX
      ? this.settingService.getPstxPaymentMethodInfo()
      : this.settingService.getPaymentMethodInfo(this.campaign.id);

    observable.subscribe({
      next: (data) => {
        this.paymentMethodInfo = data;
      },
      error: (err) => {
        this.errorService.showError(err);
      },
    });
  }

  selectPaymentType(paymentType: PaymentType) {
    if (this.disablePaymentType(paymentType)) return;

    this.form.controls.paymentType.setValue(paymentType);
  }

  async selectFile(file: File) {
    const uploadOption: FileUploadOption = {
      fileType: [...PDFFileFormat, ...ImageFileFormat],
      errorMsg: 'common.onlyImageAndPdfAccepted',
    };

    if (await this.fileValidationService.validFile(file, uploadOption)) {
      this.document = file;
      this.uploadReceipt.emit(this.document);
    }
  }

  removeDocument() {
    this.document = null;
    this.attachmentInfo = null;
  }

  submit() {
    if (this.form.invalid) {
      this.showFormError = true;
      return;
    }

    this.logAddPaymentInfo();

    // For FPX and Duitnow will go to next Step
    if ([PaymentType.MaybankFPX, PaymentType.DuitNow].includes(this.form.value.paymentType)) {
      this.nextStep.emit({
        isNext: true,
        paymentType: this.form.value.paymentType,
      });
      return;
    }

    // Other methods need to ensure the receipt is uploaded
    if (this.document == null && this.attachmentInfo == null) {
      this.showFormError = true;
      this.popupService.alert('common.pleaseUploadReceipt');
      return;
    }

    this.setInvestmentPayment();
  }

  setInvestmentPayment() {
    if (this.document != null) {
      let paymentModel = {
        paymentType: this.form.value.paymentType,
        attachmentInfo: this.attachmentInfo,
      } as Payment;

      this.choosePayment.emit(paymentModel);

      if (this.role == Role.IssuerRep) return;

      this._gtmDataLayerService.logPurchase({
        currency: this.defaultCurrencyCode.trim(),
        value: this.paymentAmount,
        transaction_id: this.referenceNo,
        items: [
          {
            item_name: this.campaign?.company?.brandName,
            item_id: this.campaign?.id,
            item_category: 'ecf',
            price: this.investment?.pledgedAmount
              ? this.investment?.pledgedAmount / this.investment?.lotQuantity
              : 0,
            quantity: this.investment?.lotQuantity,
          },
        ],
      });
    } else {
      this.popupService.alert('common.pleaseUploadReceipt');
    }
  }

  disablePaymentType(type: PaymentType): boolean {
    if (type == PaymentType.MaybankFPX) {
      return (
        (this.role == Role.IndividualInvestor && this.paymentAmount > 30000) ||
        (this.role == Role.CompanyRep && this.paymentAmount > 1000000)
      );
    } else if (type === PaymentType.DuitNow) {
      return this.paymentAmount > 50000;
    }
    return false;
  }

  logAddPaymentInfo(paymentMethod?: string) {
    this._gtmDataLayerService.logAddPaymentInfo({
      currency: this.defaultCurrencyCode.trim(),
      value: this.paymentAmount,
      payment_type: paymentMethod ?? PaymentType[this.form.value.paymentType],
      items: [
        {
          item_name: this.campaign?.company?.brandName,
          item_id: this.campaign?.id,
          item_category: 'ecf',
          price: this.investment?.pledgedAmount
            ? this.investment?.pledgedAmount / this.investment?.lotQuantity
            : 0,
          quantity: this.investment?.lotQuantity,
        },
      ],
    });
  }

  logPaylater() {
    this.logAddPaymentInfo('PayLater');
  }

  cancelPayment(): void {
    this.location.back();
  }
}
