import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NumberSuffixPipe } from '../../../shared/pipes/number-suffix.pipe';
import { FinancialOverview } from '../../../core/models/campaign/financial-overview';

@Component({
  selector: 'app-financial-list',
  templateUrl: './financial-list.component.html',
  styleUrls: ['./financial-list.component.scss'],
})
export class FinancialListComponent {
  @Input() financialOverviews: FinancialOverview[];
  @Input() params: any;
  @Input() total: number;
  @Input() isLoading: boolean;
  @Input() isPSTX: boolean = true;
  @Output() pageChange = new EventEmitter<any>();

  constructor(private numberSuffix: NumberSuffixPipe) {}

  changePage() {
    this.pageChange.emit();
  }

  getDisplayColumns(): string[] {
    const columns = ['Year', 'Revenue', 'GrossProfit', 'NetProfit', 'Note'];

    return columns;
  }
}