import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { EkycStatus } from '../../core/enum/ekyc-status.enum';
import { Role } from '../../core/enum/role.enum';
import { UserProfile } from '../../core/models/user/user-profile';
import { AccountService } from '../../core/services/api/account.service';
import { ErrorService } from '../../core/services/error.service';
import { GtmDataLayerService } from '../../core/services/gtm-data-layer.service';
import { StorageService } from '../../core/services/storage.service';
import { BlockUiService } from '../../shared/services/blockUi/block-ui.service';
import { PopupService } from '../../shared/services/popup/popup.service';
import { ForceUpdateMessageComponent } from '../login/force-update-message/force-update-message.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-login-behalf-redirection',
  templateUrl: './login-behalf-redirection.component.html',
  styleUrls: ['./login-behalf-redirection.component.scss'],
})
export class LoginBehalfRedirectionComponent implements OnInit {
  userId: string;
  token: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private errorService: ErrorService,
    private router: Router,
    private popupService: PopupService,
    private modalService: NgbModal,
    private blockUiService: BlockUiService,
    private storageService: StorageService,
    private _gtmDataLayerService: GtmDataLayerService,
    @Inject(PLATFORM_ID) private platform: Object,
  ) {}

  ngOnInit(): void {
    this.userId = this.activatedRoute.snapshot.queryParams.userId;
    this.token = this.activatedRoute.snapshot.queryParams.token;

    if (isPlatformBrowser(this.platform)) {
      this.accountService.loginOnBehalf(this.userId, this.token).subscribe({
        next: (data) => {
          let role = data.role;

          if (!role || role == Role.SuperAdmin || role == Role.Admin) {
            this.popupService.alert('common.unauthorisedAccess');
            this.accountService.logout().subscribe();
            return;
          }

          if (data.isForceUpdateUserProfile) {
            const popupOptions = {
              size: 'lg',
              centered: true,
              keyboard: false,
              windowClass: 'modal-full-screen',
            } as NgbModalOptions;

            const modalRef = this.modalService.open(ForceUpdateMessageComponent, popupOptions);
            modalRef.componentInstance.name = data.fullName;

            modalRef.result.then(
              () => {
                this.checkSignUpFlow(role, data);
              },
              () => {
                this.checkSignUpFlow(role, data);
              },
            );
          } else {
            this.checkSignUpFlow(role, data);
          }
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
    }
  }

  checkSignUpFlow(role: Role, user?: UserProfile) {
    this.accountService
      .getSignUpFlowStatus()
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe({
        next: (flowData) => {
          if (flowData && !flowData.isCompleted) {
            let roleText = Role[role].split(/(?=[A-Z])/);
            let roleRoute = roleText.join('-').toLowerCase();
            this.router.navigate(['/sign-up', roleRoute]);
            this.accountService.currentUser.next(null);
            return;
          }

          this.storageService.role = role;
          this.accountService.profile(true).subscribe({
            next: () => {
              this.router.navigate(['/']);
            },
            error: (err) => {
              this.errorService.showError(err);
            },
          });
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }
}
