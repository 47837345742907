import { OAuthType } from './../../../core/enum/oauth-type.enum';
import { PopupService } from './../../../shared/services/popup/popup.service';
import { AccountService } from './../../../core/services/api/account.service';
import { ErrorService } from './../../../core/services/error.service';
import { BlockUiService } from './../../../shared/services/blockUi/block-ui.service';
import {
  DefaultEmailPattern,
  DefaultPasswordPattern,
  signUpTypes,
} from '../../../core/services/constant';
import { Role } from '../../../core/enum/role.enum';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
// import { AngularFireAuth } from '@angular/fire/auth';
// import firebase from 'firebase/app';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TncModalComponent } from '../tnc-modal/tnc-modal.component';
import { GtmDataLayerService } from './../../../core/services/gtm-data-layer.service';
import { AuthService } from './../../../core/services/auth/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CorporateBusinessType } from './../../../core/enum/corporate-business-type.enum';
import { SignUpModel } from './../../../core/models/account/signup.model';
import { InvalidControlScrollService } from './../../../core/services/invalid-control-scroll.service';
import { WhitespaceValidator } from './../../../core/validator/whitespace.validators';

@Component({
  selector: 'app-register-email',
  templateUrl: './register-email.component.html',
  styleUrls: ['./register-email.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('void', style({ transform: 'translateX(20px)', opacity: '0' })),
      state('in', style({ transform: 'translateX(0)', opacity: '1' })),
      transition('void => *', animate('250ms ease-in')),
    ]),
  ],
})
export class RegisterEmailComponent implements OnInit {
  signUpTypesObj = signUpTypes.reduce((acc, item) => {
    acc[item.name] = item;
    return acc;
  }, {});
  currentSignUpType: {
    name: string;
    id: number;
    type: string;
    desc: string;
    image: string;
    backgroundImage: string;
  };

  showFormError: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  form: UntypedFormGroup;
  role: Role;
  showPasswordHint: boolean = false;
  atLeast8Characters: boolean = false;
  includesDigit: boolean = false;
  includesUpperAndLowercase: boolean = false;
  includesSymbol: boolean = false;
  isSent: boolean = false;

  registrationNumberPlaceholder: string = 'company.businessRegistrationNumberPlaceholder';
  corporateBusinessType = [
    CorporateBusinessType.SdnBhd,
    CorporateBusinessType.Berhad,
    CorporateBusinessType.LLP,
  ];
  issuerBusinessType = [
    CorporateBusinessType.SdnBhd,
    CorporateBusinessType.UnlistedBerhad,
    CorporateBusinessType.LLP,
  ];

  Role: typeof Role = Role;
  OAuthType: typeof OAuthType = OAuthType;
  CorporateBusinessType: typeof CorporateBusinessType = CorporateBusinessType;

  constructor(
    // public afAuth: AngularFireAuth,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private blockUiService: BlockUiService,
    private accountService: AccountService,
    private errorService: ErrorService,
    private popupService: PopupService,
    private router: Router,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private _gtmDataLayerService: GtmDataLayerService,
    private authService: AuthService,
    private invalidControlScrollService: InvalidControlScrollService,
    @Inject(PLATFORM_ID) private platform: Object,
  ) {}

  ngOnInit() {
    this.role = parseInt(this.activatedRoute.snapshot.params.role);
    this.currentSignUpType = this.signUpTypesObj[Role[this.role]];

    if (![Role.IndividualInvestor, Role.CompanyRep, Role.IssuerRep].includes(this.role)) {
      this.router.navigate(['/sign-up']);
    }

    if (this.role === Role.IssuerRep) {
      this.corporateBusinessType = this.issuerBusinessType;
    }

    this.buildForm();

    if (isPlatformBrowser(this.platform) && this.authService.isAuthenticated()) {
      this.router.navigate(['/profile/settings/profile-details'], {
        replaceUrl: true,
      });
    }
  }

  buildForm() {
    this.form = this.formBuilder.group({
      companyType: [null, this.role !== Role.IndividualInvestor ? Validators.required : null],
      companyName: [
        '',
        this.role !== Role.IndividualInvestor
          ? [Validators.required, Validators.maxLength(200), WhitespaceValidator()]
          : null,
      ],
      registrationNumber: [
        '',
        this.role !== Role.IndividualInvestor ? [Validators.required, WhitespaceValidator()] : null,
      ],
      brandName: [
        '',
        this.role === Role.IssuerRep
          ? [Validators.required, Validators.maxLength(100), WhitespaceValidator()]
          : null,
      ],
      fullname: ['', [Validators.required, Validators.maxLength(100), WhitespaceValidator()]],

      email: [
        '',
        Validators.compose([Validators.required, Validators.pattern(DefaultEmailPattern)]),
      ],
      password: [
        '',
        Validators.compose([Validators.required, Validators.pattern(DefaultPasswordPattern)]),
      ],
      confirmPassword: [
        '',
        Validators.compose([Validators.required, this.checkSamePassword.bind(this)]),
      ],
    });
  }

  checkSamePassword(control: UntypedFormControl): { [key: string]: boolean } {
    if (!this.form) return;

    const passwordControl = this.form.get('password');

    if (passwordControl.value != control.value) return { notMatch: true };
    else return null;
  }

  handleCompanyTypeChange() {
    this.logInputChange('companyType');

    if (this.form.value.companyType === CorporateBusinessType.LLP) {
      this.form.controls.registrationNumber.setValidators([
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(14),
        Validators.pattern(/^[a-zA-Z0-9-]+$/),
      ]);
      this.registrationNumberPlaceholder = 'company.businessRegistrationNumberPlaceholderAsLLP';
    } else {
      this.form.controls.registrationNumber.setValidators([
        Validators.required,
        Validators.minLength(12),
        Validators.maxLength(12),
        Validators.pattern(/^[a-zA-Z0-9-]+$/),
      ]);
      this.registrationNumberPlaceholder = 'company.businessRegistrationNumberPlaceholder';
    }

    this.form.controls.registrationNumber.updateValueAndValidity();
  }

  handleRegistrationNumberBlur(value) {
    this.form.controls.registrationNumber.setValue(value.toUpperCase());
  }

  logInputChange(inputName: string) {
    this._gtmDataLayerService.logFormBlur(inputName);
  }

  submit() {
    this.showFormError = false;

    if (this.form.invalid) {
      this.invalidControlScrollService.scrollToFirstInvalidControl();
      this.showFormError = true;
      return;
    }

    this.blockUiService.open();

    let formFields: SignUpModel = {
      Fullname: this.form.value.fullname,
      Email: this.form.value.email,
      Password: this.form.value.password,
      Role: this.role,
      CompanyType:
        this.role === Role.CompanyRep || this.role === Role.IssuerRep
          ? this.form.value.companyType
          : undefined,
      CompanyName:
        this.role === Role.CompanyRep || this.role === Role.IssuerRep
          ? this.form.value.companyName
          : undefined,
      RegistrationNumber:
        this.role === Role.CompanyRep || this.role === Role.IssuerRep
          ? this.form.value.registrationNumber
          : undefined,
      BrandName: this.role === Role.IssuerRep ? this.form.value.brandName : undefined,
    };

    this.accountService
      .registerUser(formFields)
      .pipe(
        finalize(() => {
          this.blockUiService.close();
        }),
      )
      .subscribe({
        next: (data) => {
          this.isSent = true;
          this._gtmDataLayerService.logSignUp(Role[this.role]);
        },
        error: (err) => {
          this.handleServerInputError(err);
        },
      });
  }

  handleServerInputError(err) {
    this.showFormError = true;

    if (err && err.error?.code === 'ERR_COMPANY_NAME_ALREADY_EXISTS') {
      this.form.get('companyName')?.setErrors({ alreadyExists: true });
      this.invalidControlScrollService.scrollToFirstInvalidControl();
      return;
    } else if (err && err.error?.code === 'ERR_COMPANY_REG_NUM_ALREADY_EXISTS') {
      this.form.get('registrationNumber')?.setErrors({ alreadyExists: true });
      this.invalidControlScrollService.scrollToFirstInvalidControl();
      return;
    } else if (err && err.error?.code === 'ERR_ISSUER_BRAND_NAME_ALREADY_EXISTS') {
      this.form.get('brandName')?.setErrors({ alreadyExists: true });
      this.invalidControlScrollService.scrollToFirstInvalidControl();
      return;
    }

    this.errorService.showError(err, null, true);
  }

  // signInOAuth(oAuthType: OAuthType) {
  //     let provider: firebase.auth.AuthProvider;

  //     switch (oAuthType) {
  //         case OAuthType.Facebook:
  //             provider = new firebase.auth.FacebookAuthProvider();
  //             break;
  //         case OAuthType.Google:
  //             provider = new firebase.auth.GoogleAuthProvider();
  //             break;
  //         case OAuthType.Twitter:
  //             provider = new firebase.auth.TwitterAuthProvider();
  //             break;
  //     }

  //     this.signInWithSocial(provider, oAuthType);
  // }

  // signInWithSocial(provider: firebase.auth.AuthProvider, oAuthType: OAuthType) {
  //     this.blockUiService.open();

  //     this.afAuth.signInWithPopup(provider)
  //         .then((result) => {
  //             let credential = result.credential as firebase.auth.OAuthCredential;

  //             if (oAuthType == OAuthType.Google) {
  //                 result.user.getIdToken().then(idToken => {
  //                     this.loginOAuth(oAuthType, idToken);
  //                 });
  //             }
  //             else {
  //                 this.loginOAuth(oAuthType, credential.accessToken, credential.secret);
  //             }
  //         }).catch((error) => {
  //             this.blockUiService.close();
  //         });
  // }

  // loginOAuth(oAuthType: OAuthType, accessToken: string, secret?: string) {
  //     this.accountService.loginOAuth(accessToken, oAuthType, secret)
  //         .pipe(finalize(() => this.blockUiService.close()))
  //         .subscribe(data => {
  //             let role = data.role;

  //             if (!role || role == Role.SuperAdmin || role == Role.Admin) {
  //                 this.popupService.alert('common.unauthorisedAccess');
  //                 this.accountService.logout().subscribe();
  //                 return;
  //             }

  //             this.checkSignUpFlow(role);
  //         }, err => {
  //             this.errorService.showError(err);
  //         });
  // }

  checkSignUpFlow(role: Role) {
    this.accountService
      .getSignUpFlowStatus()
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        (flowData) => {
          if (flowData && !flowData.isCompleted) {
            let nextStage = flowData.completedSignUpFlowStage + 1;
            let roleText = Role[role].split(/(?=[A-Z])/);
            let roleRoute = roleText.join('-').toLowerCase();
            this.router.navigate(['/sign-up', roleRoute, nextStage]);
            this.accountService.currentUser.next(null);
            return;
          }

          this.accountService.profile(true).subscribe();
          this.router.navigate(['/']);
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  onFocus() {
    this.showPasswordHint = true;
  }

  onBlur() {
    this.showPasswordHint = false;
  }

  checkPassword() {
    let password = this.form.value.password;
    password = password.replace(/\s/g, '');
    this.form.controls.password.setValue(password);
    this.atLeast8Characters = password.match(/^.{8,}$/) ? true : false;
    this.includesDigit = password.match(/\d/) ? true : false;
    this.includesUpperAndLowercase = password.match(/^(?=.*[a-z])(?=.*[A-Z]).*$/) ? true : false;
    this.includesSymbol = password.match(/^(?=.*[#$^+=!*()@%&{}\[\].]).*$/) ? true : false;

    if (this.form.value.confirmPassword && password)
      this.form.controls.confirmPassword.setErrors(
        this.checkSamePassword(this.form.controls.confirmPassword as UntypedFormControl),
      );
  }

  resendEmail() {
    this.blockUiService.open();

    this.accountService
      .resendVerifyEmail(this.form.value.email)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        () => {
          this.popupService.alert(
            this.translateService.instant('signUp.emailSent', { email: this.form.value.email }),
          );
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  popupFile(url: number) {
    const popupOptions = {
      size: 'lg',
      centered: true,
      keyboard: false,
      windowClass: 'modal-full-screen',
    } as NgbModalOptions;

    const modalRef = this.modalService.open(TncModalComponent, popupOptions);
    modalRef.componentInstance.url = url;
  }
}
