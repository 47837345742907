import { Component, Input } from '@angular/core';
import { CampaignStatus } from '../../../core/enum/campaign-status.enum';
import { Campaign } from '../../../core/models/campaign/campaign';

@Component({
  selector: 'campaign-footer',
  templateUrl: './campaign-footer.component.html',
  styleUrls: ['./campaign-footer.component.scss'],
})
export class CampaignFooterComponent {
  @Input() campaign: Campaign;
  CampaignStatus: typeof CampaignStatus = CampaignStatus;
}
