import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
class InternalGuardService {
  constructor(private router: Router) {}

  canActivate(): boolean {
    if (environment.environment !== 'PRODUCTION') {
      // Allow navigation to the route
      return true;
    } else {
      // Redirect to homepage as if page never exist
      this.router.navigate(['/']); // Replace '/' with the desired route
      return false;
    }
  }
}

export const InternalGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): boolean => {
  return inject(InternalGuardService).canActivate();
};
