import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { WalletTransactionFilter } from '../../../../pages/mysec/wallet/wallet-transaction-filter';
import { PagedResult } from '../../../../shared/models/paged-result/paged-result';
import { TransactionList } from '../../../models/mysec/transaction-list';
import { Wallet } from '../../../models/mysec/wallet';
import { Payment } from '../../../models/payment/payment';
import { PaymentMethod } from '../../../models/payment/payment-method';
import { PaymentMethodModel } from '../../../models/payment/payment-method.model';
import { PaymentModel } from '../../../models/payment/payment.model';

@Injectable()
export class WalletService {
  private route = environment.apiServer + 'Api/v1/MySec/{role}/wallets';

  constructor(private http: HttpClient) {}

  activateWallet(): Observable<any> {
    return this.http.post(this.route + '/activate', null);
  }

  getWallet(): Observable<Wallet> {
    return this.http.get(this.route).pipe(
      map((data: any) => {
        return new Wallet(data.result);
      }),
    );
  }

  depositWallet(amount: number, paymentModel: Payment): Observable<any> {
    let body = new PaymentModel(paymentModel);
    body.amount = +amount;

    return this.http.post(this.route + '/deposit', body).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  withdrawWallet(model: PaymentMethod): Observable<any> {
    const body = new PaymentMethodModel(model);

    return this.http.post(this.route + '/withdraw', body).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getWalletTransaction(filter: WalletTransactionFilter): Observable<PagedResult<TransactionList>> {
    const params: any = Object.assign({}, filter);

    return this.http.get(this.route + '/history', { params: params }).pipe(
      map((data: any) => {
        return {
          data:
            data.result && data.result.data
              ? data.result.data.map((item) => new TransactionList(item))
              : [],
          total: data.result.totalCount,
        };
      }),
    );
  }

  cancelTransaction(walletTransactionHistoryId: number): Observable<any> {
    return this.http.put(this.route + '/' + walletTransactionHistoryId + '/cancel', null);
  }
}
