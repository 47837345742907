<section class="tw-mt-16 tw-text-sm tw-leading-snug" *ngIf="list">
  <p
    *ngIf="
      !listByCategory[userType].pstx.article.isBroken ||
      !listByCategory[userType].ieo.article.isBroken
    "
    class="p-text-p tw-mb-2.5"
  >
    {{ 'learn.sidebar.readMore' | translate }}
  </p>

  <ul class="list--read-more tw-pl-5">
    <ng-template ngFor let-item [ngForOf]="list[userType]">
      <li *ngIf="item.category !== category && !item.article.isBroken">
        <a
          class="tw-inline-block tw-py-1 tw-underline hover:tw-underline"
          [routerLink]="item.article?.url"
        >
          {{ 'learn.sidebar.' + item.category | translate }}
        </a>
      </li>
    </ng-template>
  </ul>

  <p class="p-text-p">
    {{ 'learn.sidebar.not' + userType | translate }}?

    <a *ngIf="userType == 'issuer'" [routerLink]="listByCategory.investor[category].article?.url">
      {{ 'learn.sidebar.goToInvestor' | translate }}
    </a>
    <a *ngIf="userType == 'investor'" [routerLink]="listByCategory.issuer[category].article?.url">
      {{ 'learn.sidebar.goToIssuer' | translate }}
    </a>
  </p>
</section>
