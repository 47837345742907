<div
  class="floating-menu d-none d-md-flex align-items-center"
  [class.hide-navigation]="hideNavigationMenu"
>
  <div class="navigation-panel">
    <div class="border-bottom" *ngFor="let floatingMenu of floatingMenus">
      <div class="floating-menu-item" *ngFor="let menu of floatingMenu.menuItems">
        <span
          [ngClass]="menu.cssClass"
          [class.text-primary]="selectedMenu == menu.route"
          (click)="$event.stopPropagation(); selectMenu(menu.route, floatingMenu)"
        >
          {{ menu.name | translate }}
        </span>
      </div>
    </div>
  </div>
  <div
    class="toggle-panel"
    (click)="$event.stopPropagation(); hideNavigationMenu = !hideNavigationMenu"
  >
    <div class="gray-line"></div>
    <div class="gray-line ml-1"></div>
  </div>
</div>
