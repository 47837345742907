<section class="section section-shaped">
  <div class="container">
    <div class="text-primary text-underline cursor-pointer back-button h5" (click)="back()">
      {{ 'common.back' | translate }}
    </div>
    <div class="w-75 m-auto" *ngIf="!isLoading && campaignInfo">
      <div class="title text-center">{{ 'campaign.campaignDetails' | translate }}</div>
      <div class="box p-4 my-5">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <span>{{ 'common.status' | translate }}</span>
            <div class="status-badge red-background">{{ 'raise.Rejected' | translate }}</div>
          </div>
          <div class="col-lg-6 col-md-12">
            <span>{{ 'raise.lastUpdatedOn' | translate }}</span>
            <div class="input-label font-weight-bold">
              {{ (campaignInfo.reviewedAt | amDateFormat: 'DD MMM YYYY, HH:mm a') || '-' }}
            </div>
          </div>
          <div class="col-12 mt-3">
            <span>{{ 'common.remark' | translate }}</span>
            <div class="input-label font-weight-bold">{{ campaignInfo.remark || '-' }}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div
            class="btn btn-primary-border w-100 font-size-larger mb-3 py-3"
            [routerLink]="[route, fundRaiseApplicationId, 'campaign', campaignId]"
          >
            {{ 'investment.createNewDraft' | translate }}
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div
            class="btn btn-primary w-100 font-size-larger py-3"
            [routerLink]="[route, 'campaign', campaignId]"
          >
            {{ 'investment.editPreviousDraft' | translate }}
          </div>
        </div>
      </div>
    </div>
    <agmo-loading *ngIf="isLoading"></agmo-loading>
  </div>
</section>
