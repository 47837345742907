import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ChangeEvent, CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { Role } from '../../../core/enum/role.enum';
import { CampaignNews } from '../../../core/models/campaign/campaign-news';
import { CampaignNewsModel } from '../../../core/models/campaign/campaign-news.model';
import { EDITOR_CONFIG } from '../../../core/models/editor/editor-config';
import { StorageService } from '../../../core/services/storage.service';
import { UploadAdapter } from '../../../core/services/upload-adapter';
import { DateAgoPipe } from '../../pipes/date-ago.pipe';
import plainTextToHtml from '@ckeditor/ckeditor5-clipboard/src/utils/plaintexttohtml';
import { DefaultProfileImage } from '../../../core/services/constant';
import { CampaignStatus } from 'src/app/core/enum/campaign-status.enum';

@Component({
  selector: 'app-updates',
  templateUrl: './updates.component.html',
  styleUrls: ['./updates.component.scss'],
})
export class UpdatesComponent implements OnInit, OnChanges {
  @Input() updates: CampaignNews[] = [];
  @Input() total: number;
  @Input() params: any;
  @Input() isPublished: boolean;
  @Input() isCreator: boolean;
  @Input() isLoading: boolean = false;
  @Input() campaignStatus: number;
  @Output() deleteItem = new EventEmitter<any>();
  @Output() submitForm = new EventEmitter<any>();
  @Output() getList = new EventEmitter<any>();

  showFormError: boolean = false;
  form: UntypedFormGroup;
  showActions: boolean = this.storageService.role == Role.IssuerRep;
  Editor: any;
  ckEditorConfig = EDITOR_CONFIG;
  ckEditorLoaded = false;
  isEdit: boolean = false;
  CampaignStatus: typeof CampaignStatus = CampaignStatus;

  DefaultProfileImage = DefaultProfileImage;

  @ViewChild('editor') editorComponent: CKEditorComponent;

  constructor(
    private dateAgoPipe: DateAgoPipe,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    @Inject(PLATFORM_ID) private platform: Object,
    private storageService: StorageService,
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      const Editor = require('../../../../assets/scripts/ckeditor5/ckeditor');
      this.Editor = Editor.Editor;
      this.ckEditorLoaded = true;
      this.ckEditorConfig.extraPlugins = [
        function (editor: any) {
          editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new UploadAdapter(loader);
          };
        },
      ];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('updates' in changes) this.isEdit = false;
  }

  transformDate(publishDate: Moment) {
    if (!publishDate) return;

    let dateAgo = this.dateAgoPipe.transform(publishDate);

    return dateAgo.counter > 0
      ? dateAgo.counter +
          ' ' +
          this.translateService.instant(dateAgo.interval) +
          this.translateService.instant(dateAgo.text)
      : this.translateService.instant(dateAgo.text);
  }

  viewDraft() {
    this.isPublished = !this.isPublished;

    let body = {
      params: this.params,
      reload: true,
      isPublished: this.isPublished,
    };

    this.getList.emit(body);
  }

  addUpdate() {
    this.isEdit = !this.isEdit;

    if (this.isEdit) this.buildForm();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      id: [null],
      title: ['', Validators.required],
      content: [''],
    });
  }

  submit(isPublished: boolean) {
    if (this.form.invalid) {
      this.showFormError = true;
      return;
    }

    let campaignNew: CampaignNewsModel = this.form.value;
    campaignNew.isPublished = isPublished;

    this.submitForm.emit(campaignNew);
  }

  loadMore() {
    this.params.currentPage++;
    let body = {
      params: this.params,
      reload: false,
      isPublished: this.isPublished,
    };
    this.getList.emit(body);
  }

  cancel() {
    this.isEdit = !this.isEdit;
  }

  deleteUpdates(id: number) {
    this.deleteItem.emit(id);
  }

  editUpdates(news: CampaignNews) {
    this.isEdit = !this.isEdit;
    this.buildForm();
    this.form.patchValue(news);
  }

  removeRichText({ editor }: ChangeEvent) {
    const editingView = editor.editing.view;

    editingView.document.on('clipboardInput', (evt, data) => {
      if (editor.isReadOnly || data.method != 'paste') {
        return;
      }

      const dataTransfer = data.dataTransfer;
      let content = plainTextToHtml(dataTransfer.getData('text/plain'));
      data.content = editor.data.htmlProcessor.toView(content);

      editingView.scrollToTheSelection();
    });
  }
}
