import { FormPanel } from './form-panel';
import { FormTemplateModel } from './form-template-model';

export class FormTemplate {
  title: string;
  description: string;
  formPanels: FormPanel[];

  constructor(model: FormTemplateModel) {
    this.title = model.title;
    this.description = model.description;
    this.formPanels = model.formPanels
      ? model.formPanels.map((panel) => {
          return new FormPanel(panel);
        })
      : [];
  }
}
