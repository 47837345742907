import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidateParamsService {
  sanitize(schema, params: any, allowedParams?: string[]) {
    Object.keys(params).forEach((item) => {
      if (allowedParams && allowedParams.indexOf(item) === -1) {
        delete params[item];
      } else {
        params[item] = this.validate(schema, item, params[item]);
      }
    });

    return params;
  }

  private validate(schema, key, value) {
    if (!schema[key]) return value;

    let allowedParams: string[] = schema[key].allowed.map(String);

    if (Array.isArray(value)) {
      let defaultValue: number[] = [];

      value.forEach((item) => {
        if (!this.validateValue(allowedParams, item)) defaultValue.push(parseInt(item));
      });

      if (defaultValue.length == 0) return schema[key].defaultValue;
      else return defaultValue;
    }

    if (this.validateValue(allowedParams, value)) return schema[key].defaultValue;

    return value;
  }

  private validateValue(allowedParams: string[], value: string) {
    return allowedParams.indexOf(value) == -1;
  }
}
