<div class="tw-relative tw-flex">
  <div class="container-fluid">
    <div class="tw-left-0 tw-right-0 tw-top-0">
      <div class="tw-grid lg:tw-min-h-[65vh] lg:tw-grid-cols-5">
        <div
          class="tw-py-8 md:tw-p-8 xl:tw-p-12"
          [ngClass]="isFullWidth ? 'lg:tw-col-span-5' : 'lg:tw-col-span-3'"
        >
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="!isFullWidth"
    class="tw-absolute tw-bottom-0 tw-right-0 tw-top-0 tw-inline-block tw-w-full tw-max-w-[40%] tw-bg-gradient-to-br tw-text-center max-lg:tw-hidden"
    [ngClass]="backgroundClass"
  >
    <img
      [@fadeIn]
      [src]="backgroundImage"
      [alt]="backgroundImageAlt"
      class="tw-sticky tw-top-[20%] tw-m-auto tw-h-auto tw-max-w-full tw-transform"
      width="450"
      height="450"
    />
  </div>
</div>
