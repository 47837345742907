import * as moment from 'moment';
export class ShareMovementParams {
  take: number;
  currentPage: number;
  createdAtTo: string;
  createdAtFrom: string;
  filterString: string;
  mySecShareIds: string;
  filterBy: string;

  constructor() {
    this.currentPage = 1;
    this.take = 12;
    this.mySecShareIds = '';
    this.createdAtTo = moment().format();
    this.createdAtFrom = moment().subtract(30, 'days').format();
  }
}
