import { Component, Input } from '@angular/core';

@Component({
  selector: 'page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent {
  @Input() fullWidth: boolean = false;

  @Input() productBadge: ProductBadge;
  @Input() productBadges: ProductBadge[];

  @Input() name: string;
  @Input() description: string;
  @Input() column: number = 1;
  @Input() headerType: 'h1' | 'h2' = 'h1';
}

type ProductBadge = 'ecf' | 'pstx' | 'ieo';
