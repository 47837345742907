import { BusinessDirectory } from './../../models/business-directory/business-directory';
import { environment } from '../../../../environments/environment';
import { PagedResult } from '../../../shared/models/paged-result/paged-result';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { StorageService } from '../storage.service';
import { BusinessDirectoryInfo } from '../../models/business-directory/business-directory-info';
import { CampaignHistoryInfo } from '../../models/business-directory/campaign-history-info';
import { Role } from '../../enum/role.enum';

@Injectable()
export class BusinessDirectoryService {
  private route = environment.apiServer + 'Api/v1/ECF/{role}/businessDirectory';

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) {}

  getBusinessList(currentPage: number, take: number): Observable<PagedResult<BusinessDirectory>> {
    let params = {
      currentPage: currentPage.toString(),
      take: take.toString(),
    };

    if (
      !this.storageService.role ||
      ![Role.IndividualInvestor, Role.CompanyRep, Role.IssuerRep].includes(this.storageService.role)
    )
      params['noToken'] = 'noToken';

    return this.http.get(this.route, { params: params }).pipe(
      map((data: any) => {
        return {
          data: data.result.data ? data.result.data : [],
          total: data.result.totalCount,
        };
      }),
    );
  }

  getBusinessBySlug(slug: string): Observable<BusinessDirectoryInfo> {
    const params = !this.storageService.role ? { 'noToken': 'noToken' } : null;

    return this.http.get(this.route + '/' + slug, { params: params }).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getCampaignHistory(
    slug: string,
    currentPage: number,
    take: number,
  ): Observable<PagedResult<CampaignHistoryInfo>> {
    let params = {
      currentPage: currentPage.toString(),
      take: take.toString(),
    };

    if (
      !this.storageService.role ||
      ![Role.IndividualInvestor, Role.CompanyRep, Role.IssuerRep].includes(this.storageService.role)
    )
      params['noToken'] = 'noToken';

    return this.http.get(this.route + '/' + slug + '/campaignHistories', { params: params }).pipe(
      map((data: any) => {
        return {
          data: data.result.data ? data.result.data : [],
          total: data.result.totalCount,
        };
      }),
    );
  }
}
