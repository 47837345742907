<app-signup-layout
  [backgroundClass]="'tw-from-secondary tw-to-black'"
  [backgroundImage]="'../../../assets/img/banner/bg-signup.png'"
>
  <div class="row justify-content-center tw-mb-5" [@slideInOut]="currentStep">
    <div class="col-md-8 col-lg-12">
      <h1
        class="p-text-h2 tw-mb-8 tw-text-secondary max-md:!tw-text-2xl"
        [innerHTML]="'signUp.signUpTitle' | translate"
      ></h1>

      <div>
        <!-- For invest only -->
        <h2
          class="p-text-p !tw-text-xl tw-font-bold tw-text-primary"
          [innerHTML]="'signUp.signUpInvest' | translate"
        ></h2>

        <div class="tw-space-y-2.5">
          <ng-container *ngFor="let item of signUpTypes">
            <ng-container *ngIf="item.type !== 'signUp.issuer'">
              <ng-container *ngTemplateOutlet="button; context: { item: item }"></ng-container>
            </ng-container>
          </ng-container>
        </div>

        <!-- For raise funds only -->
        <h2
          class="p-text-p tw-mt-5 !tw-text-xl tw-font-bold tw-text-info-darkest"
          [innerHTML]="'signUp.signUpRaiseFunds' | translate"
        ></h2>

        <div *ngFor="let item of signUpTypes">
          <ng-container *ngIf="item.type === 'signUp.issuer'">
            <ng-container *ngTemplateOutlet="button; context: { item: item }"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</app-signup-layout>

<ng-template #button let-item="item">
  <a class="tw-inline-block tw-w-full tw-text-base" (click)="register(item.id)">
    <div
      class="tw-flex tw-cursor-pointer tw-select-none tw-gap-5 tw-rounded-lg tw-border tw-border-solid tw-border-divider tw-px-5 tw-py-3 hover:tw-border-secondary hover:tw-shadow-[0_0_0_2px_#006927]"
    >
      <div class="tw-flex-shrink-0">
        <img [alt]="'pitchIN ' + item.type" [src]="item.image" width="30" height="30" />
      </div>
      <div class="tw-flex-1">
        <div
          class="tw-mb-1 tw-text-base tw-font-medium tw-text-black"
          [innerHTML]="item.title | translate"
        ></div>
        <ul
          class="list--checkmark tw-mb-0 tw-space-y-0.5 tw-pl-0 tw-text-sm tw-text-black"
          [innerHTML]="item.desc | translate"
        ></ul>
      </div>
      <div class="tw-ml-auto tw-flex-shrink-0 tw-self-center">
        <p-icon name="chevron-right"></p-icon>
      </div>
    </div>
  </a>
</ng-template>
