<div class="row" [formGroup]="form">
  <div
    class="col-sm-12 d-flex mb-2"
    *ngIf="question.controlType != 'checkbox'"
    [attr.for]="question.key"
  >
    <div class="input-label mb-0" [innerHTML]="question.label"></div>
    <div
      class="ml-2 hover-dropdown"
      ngbDropdown
      placement="bottom-right"
      *ngIf="question.hoverText"
    >
      <div class="pitchin-icon pitchin-icon-info-icon-grey no-caret" ngbDropdownToggle></div>
      <div
        class="font-size-smaller hover-text-panel p-3"
        ngbDropdownMenu
        [innerHTML]="question.hoverText"
      ></div>
    </div>
  </div>
  <div
    class="col-sm-12 input-description"
    *ngIf="question.description"
    [innerHTML]="question.description"
  ></div>
  <div
    class="input-field"
    [class]="inputClass"
    [ngSwitch]="question.controlType"
    [class.date-input]="question.controlType == 'datepicker'"
  >
    <ng-container *ngSwitchCase="'textbox'">
      <input
        *ngIf="question.key == 'phoneNumber'"
        class="form-control"
        [formControlName]="question.key"
        [id]="question.key"
        type="text"
        [readonly]="question.readonly"
        [placeholder]="question.placeholder"
        digitOnly
        minlength="7"
        maxlength="15"
      />
      <input
        *ngIf="question.type == 'number'"
        class="form-control"
        [formControlName]="question.key"
        [id]="question.key"
        type="text"
        [readonly]="question.readonly"
        [placeholder]="question.placeholder"
        [pattern]="
          question.type == 'number'
            ? question.allowNegatives
              ? OneBillionPatternAllowNegative
              : OneBillionPattern
            : question.pattern
        "
        digitOnly
        decimal="true"
        [allowNegatives]="question.allowNegatives"
        allowPaste="false"
      />
      <input
        *ngIf="question.type != 'number' && question.key != 'phoneNumber'"
        class="form-control"
        [formControlName]="question.key"
        [id]="question.key"
        [type]="question.type"
        [readonly]="question.readonly"
        [pattern]="question.pattern"
        [placeholder]="question.placeholder"
      />
    </ng-container>

    <div *ngSwitchCase="'currency'" class="input-field position-relative">
      <div class="position-absolute text-black font-weight-bold currency">
        {{ 'investment.rm' | translate }}
      </div>
      <input
        class="form-control pl-5"
        [formControlName]="question.key"
        [id]="question.key"
        type="text"
        [readonly]="question.readonly"
        [placeholder]="question.placeholder"
        digitOnly
        decimal="true"
        [allowNegatives]="question.allowNegatives"
        allowPaste="false"
        [pattern]="
          question.allowNegatives
            ? OneBillionWithTwoDecimalPatternAllowNegative
            : OneBillionWithTwoDecimalPattern
        "
      />
    </div>

    <div *ngSwitchCase="'percentage'" class="input-field position-relative">
      <input
        class="form-control pr-5"
        [formControlName]="question.key"
        [id]="question.key"
        type="text"
        [readonly]="question.readonly"
        [placeholder]="question.placeholder"
        digitOnly
        decimal="true"
        [allowNegatives]="question.allowNegatives"
        allowPaste="false"
        [pattern]="
          question.allowNegatives
            ? OneBillionWithTwoDecimalPatternAllowNegative
            : OneBillionWithTwoDecimalPattern
        "
      />
      <div class="position-absolute text-black font-weight-bold percent">%</div>
    </div>

    <mat-select
      *ngSwitchCase="'dropdown'"
      [placeholder]="'common.selectPlaceholder' | translate"
      class="form-control"
      [id]="question.key"
      [formControlName]="question.key"
      [disabled]="question.readonly"
    >
      <mat-option *ngFor="let opt of question.options" [value]="opt.key">
        {{ opt.value }}
      </mat-option>
    </mat-select>

    <mat-radio-group
      *ngSwitchCase="'radio-boolean'"
      [id]="question.key"
      [formControlName]="question.key"
      [disabled]="question.readonly"
    >
      <mat-radio-button *ngFor="let opt of question.options" [value]="opt.key">
        {{ opt.value }}
      </mat-radio-button>
    </mat-radio-group>

    <textarea
      *ngSwitchCase="'textarea'"
      class="form-control"
      rows="3"
      [id]="question.key"
      [formControlName]="question.key"
      [readonly]="question.readonly"
      [placeholder]="question.placeholder"
    ></textarea>

    <mat-selection-list
      *ngSwitchCase="'selection-list'"
      [id]="question.key"
      [formControlName]="question.key"
      [disabled]="question.readonly"
    >
      <mat-list-option
        class="list-option"
        *ngFor="let opt of question.options"
        [value]="opt.key"
        checkboxPosition="before"
        [disableRipple]="true"
        >{{ opt.value }}
      </mat-list-option>
    </mat-selection-list>

    <ng-container *ngSwitchCase="'datepicker'">
      <input
        class="form-control"
        [matDatepicker]="datepicker"
        [formControlName]="question.key"
        [name]="question.key"
        placeholder="DD/MM/YYYY"
        [readonly]="question.readonly"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="datepicker"
        disableRipple
        [disabled]="question.readonly"
      >
        <div class="pitchin-icon pitchin-icon-calendar ml-3" matDatepickerToggleIcon></div>
      </mat-datepicker-toggle>
      <mat-datepicker #datepicker></mat-datepicker>
    </ng-container>

    <ng-container *ngSwitchCase="'checkbox'">
      <mat-checkbox
        [formControlName]="question.key"
        [id]="question.key"
        [disabled]="question.readonly"
      >
        <span class="input-label">
          {{ question.label }}
        </span>
      </mat-checkbox>
    </ng-container>
  </div>
</div>
