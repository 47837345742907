<button
  type="button"
  class="btn tw-relative"
  [class.tw-pointer-events-none]="loading"
  [class]="buttonClass"
  [disabled]="disabled"
>
  <agmo-loading
    *ngIf="loading"
    class="loader tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2 tw-transform"
    [loadingClass]="'tw-text-white'"
  ></agmo-loading>

  <p-icon
    *ngIf="buttonIcon"
    [className]="loading ? 'tw-invisible' : 'tw-mr-2'"
    [iconClass]="'tw-w-4 tw-h-4'"
    [name]="buttonIcon"
  >
  </p-icon>

  <span *ngIf="buttonLabel" [class]="loading ? 'tw-text-transparent' : ''">
    {{ buttonLabel | translate }}
  </span>

  <p-icon
    *ngIf="suffixButtonIcon"
    [className]="loading ? 'tw-invisible' : 'tw-ml-2'"
    [iconClass]="'tw-w-4 tw-h-4'"
    [name]="suffixButtonIcon"
  >
  </p-icon>
</button>
