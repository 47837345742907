<ng-container *ngIf="campaignInfo; else loading">
  <!-- Campaign Whats New -->
  <campaign-whats-new
    [campaignInfo]="campaignInfo"
    [campaignStatus]="campaignStatus"
    (changeTab)="handleChangeTab($event)"
  ></campaign-whats-new>

  <!-- Highlights -->
  <ng-container *ngTemplateOutlet="highlights"></ng-container>

  <section *ngIf="campaignInfo?.partners?.length" class="tw-mb-4">
    <h3 class="tw-mb-4 tw-text-base tw-font-bold tw-uppercase tw-text-primary">
      {{ 'partnership.inPartnershipWith' | translate }}
    </h3>
    <div class="tw-flex tw-flex-col tw-gap-4 tw-rounded-md">
      <article
        class="tw-flex tw-w-full tw-gap-4 tw-bg-gray-100 tw-p-6"
        *ngFor="let partner of campaignInfo.partners"
      >
        <img
          [src]="partner?.partnerLogoAttachment?.url ?? defaultLogo"
          [alt]="partner.partnerName"
          class="tw-h-24 tw-w-24 tw-min-w-24 tw-rounded-lg"
        />
        <div>
          <h5 class="tw-m-0 tw-text-lg tw-font-bold">{{ partner.partnerName }}</h5>
          <p class="ck-content tw-m-0 tw-line-clamp-2 tw-text-base tw-text-opacity-90">
            {{ partner.description }}
          </p>
          <a
            [routerLink]="['/partner', partner.micrositeSlug]"
            *ngIf="partner.micrositeSlug"
            class="tw-normal-case tw-text-link hover:tw-text-link hover:tw-underline"
            >{{ 'common.viewMore' | translate }} &#x2192;</a
          >
        </div>
      </article>
    </div>
  </section>

  <div class="tw-flex tw-flex-col tw-gap-10 lg:tw-flex-row lg:tw-flex-nowrap lg:tw-gap-0">
    <!-- (Mobile) Table of contents -->
    <div
      class="table-contents tw-sticky tw-top-[72px] tw-z-[5] tw-w-full tw-bg-white tw-pb-2.5 tw-pt-3 md:tw-top-[85px] lg:tw-hidden lg:tw-pt-2.5"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="campaignInfoTabCollapse"
    >
      <!-- Button -->
      <button
        type="button"
        class="btn btn-lightgrey tw-flex tw-w-full tw-items-center tw-justify-between tw-px-5 tw-py-2.5 tw-text-left tw-text-base lg:tw-hidden"
        (click)="collapse.toggle()"
      >
        <div class="tw-shrink-0">
          <span
            class="tw-mr-2.5 tw-inline-block tw-h-[24px] tw-w-[24px] tw-rounded-full tw-bg-white tw-text-center tw-leading-[24px]"
          >
            <i class="fa fa-list" aria-hidden="true"></i>
          </span>
          <span class="tw-mr-2.5 tw-hidden tw-font-bold sm:tw-inline-block"
            >{{ 'risk.tableOfContents' | translate }}:</span
          >
          <span class="tw-text-body tw-text-opacity-80">{{
            mapTableOfContents[selected] | translate
          }}</span>
        </div>
        <i
          class="fa tw-text-2xl"
          [class.fa-caret-up]="!isCollapsed"
          [class.fa-caret-down]="isCollapsed"
        ></i>
      </button>

      <!-- collapsable -->
      <div
        #collapse="ngbCollapse"
        [(ngbCollapse)]="isCollapsed"
        [animation]="false"
        class="tw-absolute tw-w-full tw-bg-white lg:tw-static"
      >
        <ng-container *ngTemplateOutlet="menuOfContents"></ng-container>
      </div>
    </div>

    <!-- (Desktop) Table of contents-->
    <div class="tw-hidden tw-pr-10 lg:tw-block">
      <ng-container *ngTemplateOutlet="menuOfContents"></ng-container>
    </div>

    <div class="tw-flex-auto">
      <!-- Body contents -->
      <ng-container *ngIf="!isLegacy; else legacyTemplate">
        <div
          scrollSpy
          [navbarHeight]="70"
          [spiedTags]="['DIV']"
          (sectionChange)="handleScrollChange($event)"
        >
          <ng-container *ngFor="let section of bodyContents">
            <div
              [id]="section.route"
              class="tw-scroll-m-44 tw-pb-20 sm:tw-scroll-m-[10.5rem] lg:tw-scroll-m-28"
            >
              <h3 class="p-text-title-separater tw-mb-10">{{ section.name | translate }}</h3>

              <div
                class="ck-content tw-text-lg"
                [innerHTML]="campaignInfo[section.route] | trustSrc"
              ></div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!-- Private campaign CTA -->
      <div
        *ngIf="campaign?.isPrivate && !campaign?.isInvited && !campaign?.isCreator"
        class="tw-bg-skeleton tw-bg-cover tw-bg-center tw-bg-no-repeat tw-pb-8"
      >
        <app-empty-state
          [title]="'common.inviteOnlyEmptyStateTitle'"
          [message]="'common.inviteOnlyEmptyStateDescription'"
          [ctaUrl]="!isIssuer ? campaign?.registrationLinkUrl : null"
          [ctaText]="!isIssuer ? 'common.registerInterest' : null"
        >
        </app-empty-state>
      </div>
    </div>
  </div>

  <ng-template #legacyTemplate>
    <div
      class="ck-content legacy-content"
      [innerHTML]="legacyBodyContent | legacyFormatter | trustSrc"
      scrollSpy
      [spiedTags]="['H5']"
      [navbarHeight]="70"
      (sectionChange)="handleScrollChange($event)"
    ></div>
  </ng-template>

  <ng-template #menuOfContents>
    <ul
      class="tw-mb-0 tw-max-h-[50vh] tw-list-none tw-space-y-2.5 tw-overflow-auto tw-border tw-border-divider tw-px-4 tw-py-4 tw-pl-5 tw-shadow-lg md:tw-text-right lg:tw-sticky lg:tw-top-[120px] lg:tw-max-h-full lg:tw-overflow-visible lg:tw-border-0 lg:tw-px-0 lg:tw-pb-20 lg:tw-pt-0 lg:tw-text-sm lg:tw-shadow-none"
    >
      <ng-container *ngFor="let section of tableOfContents">
        <li class="tab-item" [class.active]="section.route === selected">
          <a
            class="tw-block tw-w-full tw-cursor-pointer !tw-text-black !tw-text-opacity-40 hover:!tw-text-opacity-60"
            (click)="handleMenuClick(section.route)"
          >
            {{ section.name | translate }}
          </a>
        </li>
      </ng-container>
    </ul>
  </ng-template>

  <ng-template #highlights>
    <ng-container *ngIf="campaignInfo?.campaignHighlights?.length">
      <ul class="p-text-emphasis tw-mb-0 tw-space-y-2.5 tw-pb-5 tw-pl-5 md:tw-pb-10 lg:tw-pb-20">
        <li
          *ngFor="let highlight of campaignInfo.campaignHighlights"
          [innerHTML]="highlight?.content | trustSrc"
        ></li>
      </ul>
    </ng-container>
  </ng-template>
</ng-container>

<ng-template #loading>
  <app-skeleton [height]="2.5" [width]="'80%'" [marginBottom]="1.25"></app-skeleton>
  <app-skeleton></app-skeleton>
  <app-skeleton></app-skeleton>
  <app-skeleton [width]="'70%'"></app-skeleton>
</ng-template>
