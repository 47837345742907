<div class="row justify-content-center mb-5">
  <div class="col-12">
    <div class="row" *ngIf="showActions && !isAddEvent && isCreator">
      <div class="col-12 d-flex justify-content-end">
        <div class="btn btn-green" (click)="addEvent()">
          {{ 'campaign.addNewEvent' | translate }}
        </div>
      </div>
    </div>
    <ng-container *ngIf="!isAddEvent">
      <div class="row">
        <div class="col-12">
          <h4 class="font-weight-extrabold">{{ 'campaign.events' | translate }}</h4>
        </div>
      </div>
      <div class="tw-grid tw-gap-4 sm:tw-grid-cols-2">
        <a
          class="tw-relative tw-rounded tw-border tw-border-divider"
          *ngFor="let event of events"
          [href]="event.eventLink"
          target="_blank"
        >
          <div
            *ngIf="event?.isUpcoming || event?.isOngoing"
            class="tw-absolute tw-left-3 tw-top-1.5 tw-z-[2] tw-rounded-full tw-border-2 tw-border-white tw-px-2 tw-py-1 tw-text-xs tw-uppercase tw-text-white"
            [class.tw-bg-secondary-light]="event?.isOngoing"
            [class.tw-bg-warning]="event?.isUpcoming"
          >
            <i *ngIf="event.isOngoing" class="fa fa-check tw-mr-1" aria-hidden="true"></i>
            <span *ngIf="event?.isUpcoming">{{ 'common.upcoming' | translate }}</span>
            <span *ngIf="event?.isOngoing">{{ 'common.ongoing' | translate }}</span>
          </div>
          <div class="tw-aspect-h-9 tw-aspect-w-16">
            <img
              class="tw-object-contain"
              *ngIf="event.photoAttachmentUrl"
              [alt]="event.title"
              [src]="event.photoAttachmentUrl"
            />
          </div>

          <div class="tw-p-5">
            <div class="tw-text-lg tw-font-bold tw-text-black">
              {{ event.title }}
            </div>

            <div class="tw-text-sm tw-font-medium tw-text-secondary">
              {{ event.startDate | amDateFormat: 'ddd, DD MMM YYYY, hh:mm a' }}
            </div>

            <div class="tw-mt-3 tw-text-body">
              <app-tag-list [tags]="getTags(event.eventTagsArray)"></app-tag-list>
            </div>

            <div
              class="action-panel d-flex tw-mt-2.5 tw-justify-end"
              *ngIf="showActions && isCreator"
            >
              <div
                class="pitchin-icon pitchin-icon-edit-black mr-3"
                (click)="$event.stopPropagation(); $event.preventDefault(); editEvent(event)"
              ></div>
              <div
                class="pitchin-icon pitchin-icon-delete"
                (click)="$event.stopPropagation(); $event.preventDefault(); deleteEvent(event.id)"
              ></div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-12 d-flex my-3 p-0" *ngIf="total - events.length > 0">
        <div class="view-more" (click)="loadMore()">
          {{ 'common.loadMore' | translate }}
        </div>
      </div>
      <div class="col-lg-8 col-md-10 col-md-12" *ngIf="isLoading">
        <agmo-loading></agmo-loading>
      </div>
      <div class="col-12 pb-5 text-center" *ngIf="!isLoading && (!events || events.length == 0)">
        <app-empty-state></app-empty-state>
      </div>
    </ng-container>
    <ng-container *ngIf="showActions && isAddEvent">
      <div class="row mb-4">
        <div class="col-12">
          <h4 class="font-weight-extrabold">{{ 'campaign.addNewEvent' | translate }}</h4>
        </div>
      </div>
      <form
        (ngSubmit)="submit()"
        [formGroup]="form"
        [class.show-form-error]="showFormError"
        *ngIf="form"
      >
        <div class="row">
          <div class="col-lg-12 input-panel">
            <div class="input-label">{{ 'campaign.eventTitle' | translate }}*</div>
            <div class="input-field">
              <input
                class="form-control"
                formControlName="title"
                type="text"
                [placeholder]="'campaign.eventTitlePlaceholder' | translate"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6 input-panel">
            <div class="input-label">{{ 'campaign.eventStartDate' | translate }}*</div>
            <div class="input-field date-input">
              <input
                class="form-control"
                [matDatepicker]="startDate"
                formControlName="startDate"
                name="startDate"
                placeholder="DD/MM/YYYY"
                [min]="minStartDate"
                [max]="form.value.endDate"
              />
              <mat-datepicker-toggle matSuffix [for]="startDate" disableRipple>
                <div class="pitchin-icon pitchin-icon-calendar" matDatepickerToggleIcon></div>
              </mat-datepicker-toggle>
              <mat-datepicker #startDate></mat-datepicker>
            </div>
            <div class="error" *ngIf="form.controls.startDate.errors && showFormError">
              {{ 'common.incorrectMinYear' | translate: { 'year': 1990 } }}
            </div>
          </div>
          <div class="col-lg-4 col-md-6 input-panel">
            <div class="input-label">{{ 'campaign.eventStartTime' | translate }}*</div>
            <div class="input-field date-input">
              <input
                [ngxMatTimepicker]="startTime"
                placeholder="HH:MM"
                class="form-control"
                formControlName="startTime"
                readonly
              />
              <span class="owl-date-time-toggle">
                <div class="pitchin-icon pitchin-icon-clock" (click)="startTime.open()"></div>
              </span>
              <ngx-mat-timepicker #startTime></ngx-mat-timepicker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6 input-panel">
            <div class="input-label">{{ 'campaign.eventEndDate' | translate }}*</div>
            <div class="input-field date-input">
              <input
                class="form-control"
                [matDatepicker]="endDate"
                formControlName="endDate"
                name="endDate"
                placeholder="DD/MM/YYYY"
                [min]="form.value.startDate"
              />
              <mat-datepicker-toggle matSuffix [for]="endDate" disableRipple>
                <div class="pitchin-icon pitchin-icon-calendar" matDatepickerToggleIcon></div>
              </mat-datepicker-toggle>
              <mat-datepicker #endDate></mat-datepicker>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 input-panel">
            <div class="input-label">{{ 'campaign.eventEndTime' | translate }}*</div>
            <div class="input-field date-input">
              <input
                [ngxMatTimepicker]="endTime"
                placeholder="HH:MM"
                class="form-control"
                formControlName="endTime"
                readonly
              />
              <span class="owl-date-time-toggle">
                <div class="pitchin-icon pitchin-icon-clock" (click)="endTime.open()"></div>
              </span>
              <ngx-mat-timepicker #endTime></ngx-mat-timepicker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 input-panel">
            <div class="input-label mb-0">{{ 'campaign.eventTags' | translate }}</div>
            <div class="input-desc">{{ 'campaign.eventTagsDescription' | translate }}</div>
            <div class="input-field">
              <mat-chip-list #tagList aria-label="EventTags selection" class="form-control">
                <mat-chip
                  *ngFor="let tag of eventTags"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(tag)"
                >
                  {{ tag }}
                  <i class="fa fa-times font-size-regular" *ngIf="removable" matChipRemove></i>
                </mat-chip>
                <input
                  [placeholder]="'campaign.eventTagsPlaceholder' | translate"
                  [matChipInputFor]="tagList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="add($event)"
                />
              </mat-chip-list>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-5 col-md-6 input-panel">
            <div class="input-label">{{ 'campaign.eventImage' | translate }}*</div>
            <div class="input-field">
              <div
                class="banner-upload"
                agmoUploader
                [accept]="accept"
                (selectFiles)="selectFile($event)"
                *ngIf="!isImageLoading && !imageUrl"
              >
                <div class="upload-container upload-field d-flex">
                  <div class="align-self-center px-4">
                    <div class="pitchin-icon pitchin-icon-upload"></div>
                    <div class="drop-document">
                      {{ 'company.uploadImage' | translate }}
                    </div>
                    <div class="upload-document-instruction">
                      {{ 'campaign.uploadImageInstruction' | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="banner-upload" *ngIf="!isImageLoading && imageUrl">
                <div class="upload-container">
                  <img
                    class="banner-image"
                    [alt]="form.value.title"
                    [src]="imageUrl"
                    *ngIf="imageUrl"
                  />
                  <div
                    class="pitchin-icon pitchin-icon-add"
                    (click)="removeImage()"
                    *ngIf="imageUrl"
                  ></div>
                </div>
              </div>
              <agmo-loading *ngIf="isImageLoading"></agmo-loading>
              <div class="text-danger" *ngIf="showFormError && !imageUrl">
                {{ 'common.pleaseUploadDocument' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 input-panel">
            <div class="input-label">{{ 'campaign.eventUrl' | translate }}*</div>
            <div class="input-field">
              <input
                class="form-control"
                formControlName="eventLink"
                type="text"
                [placeholder]="'campaign.eventUrlPlaceholder' | translate"
              />
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6 input-panel d-flex">
            <div class="btn-cancel" (click)="cancel()">{{ 'common.cancel' | translate }}</div>
          </div>
          <div class="col-6 input-panel text-right">
            <button class="btn btn-green" type="submit">{{ 'common.submit' | translate }}</button>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</div>
