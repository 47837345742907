<section class="section">
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12">
        <h1 class="title font-weight-bold text-dark text-center">Nominee Agreement</h1>
      </div>
    </div>

    <div class="row">
      <div class="mx-auto col-11 col-md-8 offset-md-2">
        <p>Last updated: 01 January 2023 (v1.3)</p>
        <p><strong>THIS NOMINEE AGREEMENT</strong> is made on [ ]</p>
        <p class="font-weight-bold">PARTIES:</p>
        <p class="font-weight-bold">BETWEEN</p>

        <ol>
          <li>
            <p>
              <strong>THE PERSON</strong> referred to in
              <strong>Schedule 1 (“Crowdfunder”)</strong>;
            </p>
          </li>
          <li>
            <p>
              <strong>PITCH NOMINEES SDN. BHD.</strong>
              (Registration No.: 201601017095 (1188026-P)), a company incorporated in Malaysia with
              its registered address at 22-1, Jalan Equine 1D, Taman Equine, 43300 Seri Kembangan,
              Selangor (<strong>“Nominee”</strong>);
            </p>
          </li>
        </ol>

        <p class="font-weight-bold">AND</p>

        <ol start="3">
          <li>
            <p>
              <strong>THE COMPANY</strong> whose corporate details are set out in
              <strong>Schedule 2 (“Company”)</strong>.
            </p>
          </li>
        </ol>

        <p class="font-weight-bold">RECITALS</p>

        <ol type="A">
          <li>
            <p>
              The Company is seeking to raise funds from Crowdfunders (as defined below) via the
              Hosting (as defined below) in consideration for such new Shares to be issued and
              allotted by the Company for the purpose of the Crowdfunding Exercise.
            </p>
          </li>
          <li>
            <p>
              At the request of the Crowdfunder, the Nominee has agreed to hold such number of
              Shares issued and allotted by the Company as set out in Schedule 1 upon the Company
              manages to raise the Targeted Amount from Crowdfunders via a Hosting in accordance
              with the terms and conditions of this Agreement.
            </p>
          </li>
          <li>
            <p>
              The Nominee, Company and other shareholders of the Company entered into a Shareholders
              Agreement, Share Subscription Agreement and/or Term Sheet (as the case may be), on or
              prior to the date of this Agreement.
            </p>
          </li>
        </ol>

        <p><strong>NOW IT IS HEREBY AGREED</strong> as follows:</p>

        <div class="legal-list">
          <ol>
            <li>
              <!-- 1 -->
              <h2>DEFINITIONS AND INTERPRETATION</h2>

              <ol>
                <!-- 1.1 -->
                <li>
                  <p>
                    Unless the context otherwise requires, the following words shall have the
                    following meanings assigned to it:
                  </p>

                  <dl class="legal-description-list">
                    <dt>“Agreement”</dt>
                    <dd>means this Nominee Agreement;</dd>

                    <dt>“Business Day”</dt>
                    <dd>
                      means a day (other than a Saturday or Sunday or a public holiday in Kuala
                      Lumpur and Selangor) on which banks are generally open in Kuala Lumpur and
                      Selangor and “Business Days” shall be construed accordingly;
                    </dd>

                    <dt>“Cooling-Off Period”</dt>
                    <dd>
                      means a period of six (6) Business Days after the expiry of the Offer Period;
                    </dd>

                    <dt>“Crowdfunders”</dt>
                    <dd>means a person who invests in any Hosting on the ECF Platform;</dd>

                    <dt>“Crowdfunding Exercise”</dt>
                    <dd>means an exercise on the ECF Platform to raise funds via the Hosting;</dd>

                    <dt>“ECF”</dt>
                    <dd>means Equity Crowdfunding;</dd>

                    <dt>“ECF Funds”</dt>
                    <dd>
                      means the funds raised by the Company from the Crowdfunders via the ECF
                      Platform;
                    </dd>

                    <dt>“ECF Operator”</dt>
                    <dd>
                      means Pitch Platforms Sdn. Bhd. (Registration No.: 201501033144 (1158464-T)),
                      a company licensed by Securities Commission of Malaysia to operate an ECF
                      Platform;
                    </dd>

                    <dt>“ECF Platform”</dt>
                    <dd>
                      means the equity crowdfunding platform at www.equity.pitchin.my and any
                      subdomain thereof operated by the ECF Operator, approved by and registered
                      with the Securities Commission of Malaysia;
                    </dd>

                    <dt>“ECF Platform Agreements”</dt>
                    <dd>
                      means the Terms and Conditions (User), Terms and Conditions (Issuer &
                      Crowdfunders), Privacy Policy, and/or any other additional terms made
                      available on the ECF Platform, which may be varied by the ECF Operator from
                      time to time;
                    </dd>

                    <dt>“Exit Transaction”</dt>
                    <dd>
                      means any exit transaction which the Company may undertake, including but not
                      limited to initial public offerings (IPO), strategic merger and acquisitions
                      or management buyouts;
                    </dd>

                    <dt>“Hosting”</dt>
                    <dd>
                      means the offer of the Shares by the Company on the ECF Platform for
                      subscription by the Crowdfunders in consideration of the ECF Funds;
                    </dd>

                    <dt>“Investment Amount”</dt>
                    <dd>
                      means the amount invested by the Crowdfunder into the Company pursuant to the
                      Crowdfunding Exercise;
                    </dd>

                    <dt>“Offer Period”</dt>
                    <dd>
                      means the period during which the Crowdfunders may deposit the ECF Funds into
                      the Trust Account pending for the completion of the Crowdfunding Exercise;
                    </dd>

                    <dt>“Shareholders Agreement”</dt>
                    <dd>
                      means the agreement entered into between the Nominee, Company and other
                      shareholders of the Company on or about the date of this Agreement. For the
                      avoidance of doubt, the Shareholders Agreement may include any or all
                      agreements entered between the shareholders of the Company to govern its
                      relationship and may include shareholders agreement, share subscription
                      agreement, term sheet (as the case may be);
                    </dd>

                    <dt>“Shares”</dt>
                    <dd>
                      means the shares offered by the Company to Crowdfunders for the purpose of the
                      Crowdfunding Exercise via the Hosting;
                    </dd>

                    <dt>“Targeted Amount”</dt>
                    <dd>
                      means the targeted amount sought to be raised by the Company in the
                      Crowdfunding Exercise via the Hosting;
                    </dd>

                    <dt>“Transferee”</dt>
                    <dd>
                      means a third party to which the Transferor purports or intends to transfer
                      the beneficial ownership and interests of the Shares held by the Nominee;
                    </dd>

                    <dt>“Transferor”</dt>
                    <dd>
                      means a beneficial owner of the Shares who purports or intends to transfer the
                      beneficial ownership and interest of the Shares held by the Nominee to a third
                      party; and
                    </dd>

                    <dt>“Trust Account”</dt>
                    <dd>
                      means the account opened by a trustee appointed by the ECF Operator in the
                      name of such trustee, in which the Investment Amount is held during the Offer
                      Period pending the completion of the Crowdfunding Exercise.
                    </dd>
                  </dl>
                </li>

                <!-- 1.2 -->
                <li>
                  <p>
                    Any reference to a statutory provision shall include such provision and any
                    regulations made in pursuance thereof as from time to time modified, amended,
                    replaced or re-enacted whether before, on or after the date of this Agreement.
                    Any reference to any “law” shall include common law applicable in Malaysia and
                    the provisions of statutes, regulations, orders and other subsidiary legislation
                    issued pursuant to such statute, as well as directions, guidelines and circulars
                    issued by any regulatory authority pursuant to any authority granted by any such
                    statute.
                  </p>
                </li>

                <!-- 1.3 -->
                <li>
                  <p>
                    Unless otherwise provided, references to Recitals, Clauses and the Schedules are
                    in respect of recitals, clauses and the schedules in this Agreement.
                  </p>
                </li>

                <!-- 1.4 -->
                <li>
                  <p>
                    The Schedules shall be taken, read and construed as essential parts of this
                    Agreement. The headings in this Agreement are inserted for convenience only and
                    shall not affect the interpretation of this Agreement.
                  </p>
                </li>

                <!-- 1.5 -->
                <li>
                  <p>
                    Unless the context otherwise requires or permits, references to the singular
                    number shall include references to the plural and vice versa, references to a
                    particular gender shall include all genders, and references to natural persons
                    shall include bodies corporate and vice versa.
                  </p>
                </li>

                <!-- 1.6 -->
                <li>
                  <p>
                    Any reference to a
                    <strong>"day"</strong>, <strong>"week"</strong>, <strong>"month"</strong> or
                    <strong>"year"</strong> is to that day, week, month or year in accordance with
                    the Gregorian calendar.
                  </p>
                </li>

                <!-- 1.7 -->
                <li>
                  <p>
                    1.7 The expression
                    <strong>“Crowdfunder”</strong>, <strong>“Nominee”</strong> and the
                    <strong>“Company”</strong> shall, where the context permits, include their
                    respective successors, personal representatives and permitted assigns.
                  </p>
                </li>

                <!-- 1.8 -->
                <li>
                  <p>
                    Where a word or phrase is given a defined meaning in this Agreement any other
                    part of speech or other grammatical form in respect of such word or phrase has a
                    corresponding meaning.
                  </p>
                </li>

                <!-- 1.9 -->
                <li>
                  <p>
                    Any reference to this
                    <strong>“Agreement”</strong> is a reference to this Nominee Agreement (including
                    the schedules) and any written amendments, supplementals or novations of this
                    Deed from time to time, and includes a reference to any document which amends,
                    waives, is supplemental to or novates the terms of this Agreement.
                  </p>
                </li>

                <!-- 1.10 -->
                <li>
                  <p>
                    Any reference to
                    <strong>“writing”</strong>, or cognate expressions, includes any communication
                    effected via electronic mail, telex, cable, facsimile transmission or other
                    comparable means but shall not include short messaging services, instant
                    messaging services or internet chat.
                  </p>
                </li>

                <!-- 1.11 -->
                <li>
                  <p>
                    If any period of time is specified from a given day, or the day of a given act
                    or event, it is to be calculated exclusive of that day and if any period of time
                    falls on a day which is not a Business Day, then that period is to be deemed to
                    only expire on the next Business Day.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 2 -->
              <h2>AGREEMENT TO INVEST</h2>

              <ol>
                <!-- 2.1 -->
                <li>
                  <p>
                    The Crowdfunder hereby agrees to subscribe the Shares on the terms and subject
                    to the conditions set forth in this Agreement and the ECF Platform Agreements
                    and all documents and/or agreements made available on the ECF Platform.
                  </p>
                </li>

                <!-- 2.2 -->
                <li>
                  <p>
                    In making the subscription of Shares as mentioned in Clause 2.1 above, the
                    Crowdfunder acknowledges that:
                  </p>

                  <ol>
                    <!-- 2.2.1 -->
                    <li>
                      <p>
                        he or she has done so based solely on the information contained in the
                        Crowdfunding Exercise as provided and published by the Company, including
                        but not limited to the Shareholders Agreement and such independent knowledge
                        as the Crowdfunder may have in relation to such investment made without any
                        reliance on the representations made (whether express or implied) by the
                        Company and/or the ECF Operator (if any). For the avoidance of doubt, the
                        Crowdfunder acknowledges that no information and/or documents about the
                        Company other than what is set forth in the Crowdfunding Exercise has been
                        reviewed or approved by the Nominee (including any documents or information
                        prepared and provided by the Company directly to the Crowdfunder); and
                      </p>
                    </li>

                    <!-- 2.2.2 -->
                    <li>
                      <p>
                        he or she has read and understood the ECF Platform Agreements and he or she
                        fully accepts the risks described therein and any other risks inherent in
                        investing in businesses like the Company.
                      </p>
                    </li>
                  </ol>
                </li>

                <!-- 2.3 -->
                <li>
                  <p>
                    Upon a successful Crowdfunding Exercise, the Crowdfunder wishes for the
                    Crowdfunder's Shares to be registered in the name of and held by the Nominee on
                    the Crowdfunder’s behalf based on the Crowdfunder’s request.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 3 -->
              <h2>APPOINTMENT OF NOMINEE</h2>

              <ol>
                <!-- 3.1 -->
                <li>
                  <p>
                    Subject to the compliance to Section 56 of the Companies Act 2016, the
                    Crowdfunder hereby appoints the Nominee as the Crowdfunder's nominee with
                    respect to the Crowdfunder's Shares, and the Nominee hereby accepts such
                    appointment, in each case on the terms and subject to the conditions set forth
                    in this Agreement.
                  </p>
                </li>

                <!-- 3.2 -->
                <li>
                  <p>The Crowdfunder hereby acknowledges and agrees that the Nominee shall:</p>

                  <ol>
                    <!-- 3.2.1 -->
                    <li>
                      <p>
                        hold the Crowdfunder's Shares (together with all dividends, rights, bonuses,
                        interest and other rights as conferred under the Companies Act 2016,
                        constitution of the Company or the Shareholders Agreement to a shareholder
                        of the Company) as nominee of the Crowdfunder; and
                      </p>
                    </li>
                    <!-- 3.2.2 -->
                    <li>
                      <p>
                        have no beneficial interest in the Crowdfunder's Shares except in its
                        capacity as nominee of the Crowdfunder,
                      </p>
                    </li>
                  </ol>

                  <p>
                    in each case subject to the terms and conditions of this Agreement. The
                    beneficial interest to the Crowdfunder’s Shares shall be held by the
                    Crowdfunder.
                  </p>
                </li>

                <!-- 3.3 -->
                <li>
                  <p>
                    The Crowdfunder and the Nominee each agree that the relationship between them
                    shall be that of principal and nominee only, that there is no intention to
                    create a relationship of partnership between the Crowdfunder and the Nominee,
                    and that this Agreement should not be construed to create any partnership or
                    other form of joint venture between the Crowdfunder and the Nominee.
                  </p>
                </li>

                <!-- 3.4 -->
                <li>
                  <p>
                    For all intents and purposes, although the Nominee shall be the nominee of the
                    Crowdfunder in relation to the Crowdfunder's Shares, the Crowdfunder irrevocably
                    and unequivocally agrees that he or she shall not be entitled to direct the
                    actions of the Nominee in relation to the Crowdfunder's Shares or to call for
                    the transfer of the Crowdfunder's Shares to the Crowdfunder or any other person
                    or to otherwise exercise any rights which the Crowdfunder may have as beneficial
                    owner of the Crowdfunder's Shares, except as expressly provided for in this
                    Agreement.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 4 -->
              <h2>CANCELLATION RIGHTS</h2>

              <ol>
                <!-- 4.1 -->
                <li>
                  <p>
                    The Crowdfunder may exercise the right to cancel his or her investment as set
                    out on the ECF Platform at any time during or before the expiration of the
                    Cooling-Off Period (“Cancellation Right”).
                  </p>
                </li>
                <!-- 4.2 -->
                <li>
                  <p>
                    If the Crowdfunder exercises his or her Cancellation Right, this Agreement and
                    the nominee arrangement contemplated hereby shall terminate in accordance with
                    Clause 13. The Nominee shall bear no responsibility whatsoever in respect of the
                    refund of the Investment Amount and the Crowdfunder hereby releases and
                    discharges the Nominee in entirety from all claims, damages and causes of action
                    of any kind which may arise as a result of such payment being made to the
                    Company.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 5 -->
              <h2>AUTHORITY OF THE NOMINEE IN RESPECT OF THE CROWDFUNDER’S SHARES</h2>

              <ol>
                <!-- 5.1 -->
                <li>
                  <p>
                    Upon the issuance of the Crowdfunder’s Shares by the Company in the name of the
                    Nominee, the Nominee shall hold on the Crowdfunder’s behalf the Crowdfunder's
                    Shares and act on matters it believes to be in the best interests of the
                    Crowdfunder in consolidation with the other Crowdfunders, including but not
                    limited to:
                  </p>

                  <!-- 5.1.x -->
                  <ol>
                    <li>
                      <p>
                        casting votes in Annual General Meeting or Extraordinary General Meeting of
                        the Company;
                      </p>
                    </li>
                    <li>
                      <p>
                        executing or refusing to execute such written resolutions as proposed by the
                        Company;
                      </p>
                    </li>
                    <li>
                      <p>
                        issuing or refusing to issue consents or approvals, including but not
                        limited to consents or approvals for any future fundraising exercise(s) that
                        the Company may conduct
                      </p>
                    </li>
                    <li>
                      <p>approving or declining to approve any Exit Transaction;</p>
                    </li>
                    <li>
                      <p>
                        taking or not taking any other actions to which the Nominee is entitled by
                        virtue of being the legal shareholder of the Crowdfunder's Shares;
                      </p>
                    </li>
                    <li>
                      <p>
                        such other authorities as conferred to the Nominee by virtue of the
                        Companies Act 2016, the Shareholders’ Agreement or the constitution of the
                        Company being the legal shareholder of the Crowdfunder’s Shares.
                      </p>
                    </li>
                  </ol>
                </li>

                <!-- 5.2 -->
                <li>
                  <p>
                    For the avoidance of doubt, in taking actions including but not limited to
                    matters as stipulated in Clause 5.1 above, the Crowdfunders hereby irrevocably
                    and unequivocally agree and authorise (in the form of blanket consent) the
                    Nominee to execute any agreements on behalf of the Crowdfunders as the legal
                    shareholder of the Company, if the Nominee believes to be in best interest of
                    the Crowdfunders as a group in entirety in the opinion of the Nominee.
                  </p>
                </li>

                <!-- 5.3 -->
                <li>
                  <p>
                    For such authorities as granted to the Nominee by virtue of this Clause 5
                    herein, the Nominee may but not obligated to inform and consult the Crowdfunders
                    as a group if the Nominee opines that such decision to be made by the Nominee
                    might affect the Crowdfunder’s beneficial interest of the Crowdfunder’s Shares
                    or implicates the Crowdfunders (whether directly or indirectly) and that the
                    Nominee shall adopt the best mode deemed convenient and appropriate by the
                    Nominee in the best interest of the Crowdfunders and that such ultimate decision
                    made by the Nominee shall be final and conclusive in exercising the irrevocable
                    authorities granted to the Nominee by the Crowdfunders.
                  </p>
                </li>

                <!-- 5.4 -->
                <li>
                  <p>
                    The Crowdfunder agrees and acknowledges that the Nominee is not obligated to
                    direct, advise or monitor the Company and its business.
                  </p>
                </li>

                <!-- 5.5 -->
                <li>
                  <p>
                    In the event of an occurrence of an Exit Transaction, Nominee will facilitate
                    the Exit Transaction until such point where the Shares under the name of the
                    Nominee are deregistered from the register of members with Companies Commission
                    of Malaysia (CCM).
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 6 -->
              <h2>RIGHT TO NOTICES AND VOTES</h2>

              <ol>
                <!-- 6.1 -->
                <li>
                  <p>
                    The Nominee shall within reasonable time arrange for the Crowdfunder to receive
                    details of any meetings of the shareholders of the Company, and any other
                    information issued to shareholders of the Company, if the Crowdfunder at any
                    time in writing requests such details and information.
                  </p>
                </li>
                <!-- 6.2 -->
                <li>
                  <p>
                    In the event that the Crowdfunder has requested details of meetings of the
                    shareholders of the Company pursuant to Clause 6.1, the Crowdfunder may request
                    the Nominee to vote on the Crowdfunder’s Shares in such meeting according to the
                    Crowdfunder’s request, subject always to Clause 5.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 7 -->
              <h2>TRANSFER OF CROWDFUNDER’S SHARES</h2>

              <ol>
                <!-- 7.1 -->
                <li>
                  <p>
                    Subject to the written consent of the Nominee which such consent shall not be
                    unreasonably withheld, the Crowdfunder may request to transfer the beneficial
                    ownership and interest of the Crowdfunder’s Shares (‘Transferor’) to a third
                    party (‘Transferee’). The transfer shall only be valid and effective upon the
                    execution of a share transfer form prescribed by the Nominee and the submission
                    of all such documents and relevant administration fees (if applicable) as may be
                    requested by the Nominee.
                  </p>
                </li>
                <!-- 7.2 -->
                <li>
                  <p>
                    Upon signing of the share transfer form, the Transferor will no longer be a
                    party to this Agreement and will no longer hold beneficial ownership and
                    interest to the Shares. The Transferee will acquire beneficial ownership and
                    interest to the Shares and automatically deemed to be bound by the Terms and
                    Conditions set forth in this Agreement.
                  </p>
                </li>
                <!-- 7.3 -->
                <li>
                  <p>
                    Transfer of beneficial ownership and interest in the Shares in a Secondary
                    Market shall be subject to procedures as may be specified by the Nominee and/or
                    the ECF Operator from time to time.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 8 -->
              <h2>DISTRIBUTIONS FROM THE COMPANY</h2>

              <ol>
                <!-- 8.1 -->
                <li>
                  <p>
                    If the Company declares a cash dividend or makes a cash distribution to the
                    holders of its shares, the Parties agree that the Company shall pay the
                    dividends directly to the Crowdfunder (without the Nominee receiving the
                    dividends on behalf of the Crowdfunder) based on the bank information provided
                    by the Crowdfunder. The Nominee shall not be responsible to see to the correct
                    distribution of the dividends. If requested by the Company, the Nominee shall
                    furnish the bank account details of the Crowdfunder based on the information
                    provided to the Nominee (unless updated by the Crowdfunder in writing to the
                    Nominee).
                  </p>
                </li>
                <!-- 8.2 -->
                <li>
                  <p>
                    In the event the dividends are paid to the Nominee for its distribution, the
                    Nominee shall, as soon as practicable after receiving such dividend or
                    distribution and without obligation to verify the accuracy of the computation of
                    the dividend, distribute it to the Crowdfunder. In such event, the Company shall
                    bear all bank and administration charges (if any) and taxes in respect of the
                    payment of dividends.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 9 -->
              <h2>MANAGEMENT FEE</h2>

              <ol>
                <!-- 9.1 -->
                <li>
                  <p>
                    Unless otherwise stated in the Crowdfunding Exercise, as consideration for its
                    services as Nominee of the Crowdfunder in relation to the Crowdfunder's Shares
                    and all other shares held for other Crowdfunders pursuant to the Crowdfunding
                    Exercise, the Nominee shall be entitled to a fee equivalent to RM3,888 per annum
                    (inclusive of Sales and Services Tax, if applicable) ("Management Fee"), unless
                    waived in writing by the ECF Operator. The Company shall be responsible for
                    paying the ECF Operator the Management Fee within seven (7) Business Days of
                    written request by the ECF Operator. The Company shall reimburse the ECF
                    Operator for any disbursements incurred in the performance of its obligations
                    under this Agreement. The Company acknowledges that the ECF Operator may have an
                    upward adjustment to the Management Fee and shall engage in good faith
                    discussions with the Company to agree on such upward adjustment.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 10 -->
              <h2>OBLIGATIONS OF NOMINEE AND THE CROWDFUNDER</h2>

              <ol>
                <!-- 10.1 -->
                <li>
                  <p>
                    The Crowdfunder acknowledges and expressly agrees that in certain circumstances
                    it may be necessary for the Nominee not to take an action or fulfil an
                    obligation set forth in this Agreement if precluded by a contractual arrangement
                    with the Company which the Nominee has entered into in the belief that such
                    contractual arrangement is in the best interests of the Crowdfunder. The
                    Crowdfunder also acknowledges that the Nominee has like duties to the other
                    Crowdfunders on whose behalf the Nominee acquires and holds shares of the
                    Company and that in determining whether its actions are in the best interests of
                    the Crowdfunder:
                  </p>

                  <!-- 10.1.x -->
                  <ol>
                    <li>
                      <p>
                        the Nominee shall be entitled to regard an action as in the best interests
                        of the Crowdfunder if it regards it as being in the best interests of all
                        the Crowdfunders as a group; and
                      </p>
                    </li>
                    <li>
                      <p>
                        the Nominee shall be entitled to have regard only to the interests of the
                        Crowdfunder and other Crowdfunders in relation to the shares of the Company
                        held by the Nominee on their behalf, and not in relation to shares in any
                        other company hosted on the ECF Platform that the Crowdfunder may have
                        invested in.
                      </p>
                    </li>
                  </ol>
                </li>

                <!-- 10.2 -->
                <li>
                  <p>
                    Within six (6) weeks from the issuance of shares by the Company to the Nominee,
                    the Nominee shall issue a certificate to the Crowdfunder setting out the
                    following information:
                  </p>

                  <div class="alpha-list">
                    <ol>
                      <!-- 10.2(a) -->
                      <li>
                        <p>
                          the number of Shares held by the Nominee on behalf of the Crowdfunder in
                          the Company; and
                        </p>
                      </li>
                      <!-- 10.2(b) -->
                      <li>
                        <p>
                          the corresponding share certificate numbers corresponding to the Shares
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>

                <!-- 10.3 -->
                <li>
                  <p>
                    For the avoidance of doubt, the certificate shall be delivered to the
                    Crowdfunder only upon receipt of Crowdfunder’s identity documents and any other
                    document as required under the Nominee and/or ECF Operator’s Know-Your-Customer
                    and Anti-Money Laundering policies.
                  </p>
                </li>

                <!-- 10.4 -->
                <li>
                  <p>
                    The Nominee shall not be liable to the Crowdfunder, and hereby disclaims to the
                    fullest extent permissible by law all liability, for:
                  </p>

                  <ol>
                    <!-- 10.4.1 -->
                    <li>
                      <p>
                        ny losses or damages resulting from or related to actions taken or omitted
                        to be taken by the Nominee in connection with matters contemplated by this
                        Agreement except to the extent that such losses are the direct result of
                        fraud on the part of the Nominee; and
                      </p>
                    </li>
                    <!-- 10.4.2 -->
                    <li>
                      <p>
                        any direct, indirect, consequential, special or punitive loss, damage, cost
                        or expense, unforeseeable losses or damages, loss of profit, loss of
                        business, lost or wasted management time or time of other employees, loss of
                        reputation, depletion of goodwill or loss, damage or corruption of data.
                      </p>
                    </li>
                  </ol>
                </li>

                <!-- 10.5 -->
                <li>
                  <p>
                    Without prejudice to paragraph 10.3, in no event shall the Nominee be liable to
                    the Crowdfunder for more than the total amount invested by the Crowdfunder in
                    the shares of the Company under whatsoever circumstances pursuant to this
                    Agreement.
                  </p>
                </li>

                <!-- 10.6 -->
                <li>
                  <p>
                    The Crowdfunder may be liable to pay taxes on any dividends or other returns
                    received in respect of the Crowdfunder’s Shares, which may vary depending on
                    where the Company is incorporated and has its place of business. The Crowdfunder
                    is entirely responsible for paying any such taxes and the Nominee shall bear no
                    responsibility whatsoever in respect of them (save making any deductions or
                    withholdings which the Nominees is required by the law to make), including,
                    without limitation, notifying the Crowdfunder of any obligations that have or
                    may have arisen.
                  </p>
                </li>

                <!-- 10.7 -->
                <li>
                  <p>
                    The Company shall indemnify and keep the Nominee fully indemnified against all
                    claims, demands, liabilities, costs, expenses, damages, fines, penalties and
                    losses of whatsoever nature (including but not limited to any direct, indirect
                    or consequential losses, loss of profit, loss of reputation and all interest,
                    penalties and legal costs and all other professional costs and expenses)
                    suffered or incurred by, arising out of or in connection with the performance of
                    any act or omission of the Nominee under this Agreement.
                  </p>
                </li>

                <!-- 10.8 -->
                <li>
                  <p>
                    The Crowdfunder hereby warrants that the information set out in Schedule 1 are
                    true, accurate and complete and that there are no material omissions. In the
                    event of a change of such information, the Crowdfunder shall send the Nominee
                    written notification of such change within three (3) Business Days of such
                    change. The Crowdfunder acknowledges and accepts that the Nominee shall not be
                    able to perform its obligations without the updated information and the
                    Crowdfunder shall release the Nominee from all liabilities in the event the
                    Nominee performs its obligations based on the outdated information.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 11 -->
              <h2>REPLACEMENT OF NOMINEE</h2>

              <ol>
                <!-- 11.1 -->
                <li>
                  <p>
                    The Nominee may, at any time and entirely at its discretion, appoint any other
                    person, corporate entity, body or organisation as a replacement Nominee ("New
                    Nominee") provided that the Nominee is satisfied that such New Nominee is
                    competent to perform the obligations of the Nominee under this Agreement, and
                    that the New Nominee agrees to be bound by such obligations, before the
                    departing Nominee can be released from such obligations.
                  </p>
                </li>

                <!-- 11.2 -->
                <li>
                  <p>
                    Upon a New Nominee's acceptance of an appointment pursuant to paragraph 11.1:
                  </p>

                  <ol>
                    <!-- 11.2.1 -->
                    <li>
                      <p>
                        the New Nominee shall be deemed the Nominee for all purposes of this
                        Agreement, including, without limitation, for receiving any Management Fee
                        and for appointing a further New Nominee, provided, that such New Nominee
                        may choose to make communications and distributions pursuant to methods
                        other than those set forth in this Agreement; and
                      </p>
                    </li>
                    <!-- 11.2.2 -->
                    <li>
                      <p>
                        the departing Nominee shall cease to be the Nominee and shall no longer be
                        bound by this Agreement.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <!-- 12 -->
              <h2>RELEASE OF CROWDFUNDER’S SHARES TO THE CROWDFUNDER</h2>

              <ol>
                <!-- 12.1 -->
                <li>
                  <p>
                    Provided that the Crowdfunder shall bear for the costs and expenses (including
                    the stamp duty) payable for the purported transfer, the Nominee may transfer
                    legal ownership of some or all of the Crowdfunder's Shares to the Crowdfunder
                    or, where relevant, his or her estate at any time if either:
                  </p>
                  <!-- 12.1.x -->
                  <ol>
                    <li>
                      <p>
                        upon request by the Crowdfunder and subject to the written approval by the
                        Company;
                      </p>
                    </li>
                    <li>
                      <p>
                        the Company fails to settle any outstanding sums of the Management Fee or
                        disbursements payable to the Nominee;
                      </p>
                    </li>
                    <li>
                      <p>
                        the Nominee concludes that it is no longer in a position to hold and manage
                        such Crowdfunder's Shares in the best interests of the Crowdfunder and in
                        compliance with all applicable laws and regulations, and Nominee has not
                        appointed a New Nominee pursuant to Clause 11; or
                      </p>
                    </li>
                    <li>
                      <p>
                        it reasonably appears to the Nominee that the Company is likely to be
                        stagnant for the foreseeable future, meaning that it is unlikely to produce
                        significant returns for the Crowdfunder and its other Crowdfunders but does
                        not intend to wind up or its business or otherwise cease to exist.
                      </p>
                    </li>
                  </ol>
                </li>

                <!-- 12.2 -->
                <li>
                  <p>
                    Any transfer of legal ownership of the Crowdfunder's Shares pursuant to Clause
                    12.1 will, if the Nominee or the Company requires, be subject to the Crowdfunder
                    or his or her estate agreeing to be bound by the terms of any shareholders'
                    agreement or similar document in place in relation to the Company at the time of
                    transfer.
                  </p>
                </li>

                <!-- 12.3 -->
                <li>
                  <p>
                    The Crowdfunder expressly agrees and acknowledges that, in the event that the
                    Nominee exercises its right under Clause 12.1, the Crowdfunder will become the
                    legal, in addition to beneficial, owner of such Crowdfunder's Shares and the
                    Nominee’s obligations as nominee under this Agreement will terminate, and the
                    Crowdfunder recognises that, among other things, this means that he or she would
                    need to incur the administrative and other burdens of managing such
                    Crowdfunder's Shares.
                  </p>
                </li>

                <!-- 12.4 -->
                <li>
                  <p>
                    On the initial public offering of the share capital of the Company on a
                    recognised stock exchange, all the Crowdfunder’s Shares shall be listed in the
                    name of the Crowdfunder.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 13 -->
              <h2>TERMINATION</h2>

              <ol>
                <!-- 13.1 -->
                <li>
                  <p>
                    The Nominee may terminate this Agreement by giving not less than three (3)
                    months’ written notice to the Company and Crowdfunder. The Company shall before
                    the expiry of such three (3) months’ written notice appoint a new nominee to
                    perform the obligations of the Nominee as contemplated under this Agreement. In
                    the event a new nominee shall not have been appointed within such three months
                    period, the Nominee shall be permitted to transfer the Crowdfunder’s Shares to
                    the Crowdfunder whereby such costs and expenses incurred (including the stamp
                    duty) for the transfer shall be borne by the Company.
                  </p>
                </li>
                <!-- 13.2 -->
                <li>
                  <p>
                    This Agreement shall be terminated upon completion of an Exit Transaction, when
                    the shares in the name of the Nominee are deregistered.
                  </p>
                </li>
                <!-- 13.3 -->
                <li>
                  <p>
                    The Crowdfunder shall no longer be bound by the terms of this Agreement upon
                    completion of a transfer of the Crowdfunder’s beneficial interest in the Shares,
                    pursuant to Clause 7 above.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 14 -->
              <h2>DATA PROTECTION</h2>

              <ol>
                <!-- 14.1 -->
                <li>
                  <p>
                    For the purposes of this Agreement and in connection with the Crowdfunder’s use
                    of the Platform and/or the Nominee's acquisition, holding and disposal of the
                    Crowdfunder's Shares as the Crowdfunder's nominee, the Nominee may disclose
                    certain of the Crowdfunder's Personal Data to any if its affiliates or partners
                    which it contracts with or employs in connection with the Nominee’s provision of
                    services (including those based in other jurisdictions), the Company and any
                    person acting on behalf of the Company, to any other Platform Members for whom
                    the Nominee holds shares of the Company, to any tax, statutory or regulatory
                    authority as required by such authority, to any New Nominee or prospective New
                    Nominee, or to any prospective purchaser of the Crowdfunder's Shares, and each
                    of their respective officers, employees and professional advisers. The
                    Crowdfunder must ensure that the Personal Data set out in the Crowdfunder’s
                    profile is correct and up to date.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 15 -->
              <h2>NO PARTNERSHIP OR AGENCY</h2>

              <ol>
                <!-- 15.1 -->
                <li>
                  <p>
                    This Agreement shall not be construed so as to create a partnership or joint
                    venture between the Parties.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 16 -->
              <h2>NO WAIVER</h2>

              <ol>
                <!-- 16.1 -->
                <li>
                  <p>
                    No failure or delay by any Party in exercising any of its rights under this
                    Agreement shall be deemed to be a waiver of that right, and no waiver by any
                    Party of a breach of any provision of this Agreement shall be deemed to be a
                    waiver of any subsequent breach of the same or any other provision.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 17 -->
              <h2>SEVERABILITY</h2>

              <ol>
                <!-- 17.1 -->
                <li>
                  <p>
                    If any provision of this Agreement or part thereof is rendered void, illegal or
                    unenforceable by any legislation to which it is subject, it shall be rendered
                    void, illegal or unenforceable to that extent and it shall in no way affect or
                    prejudice the enforceability of the remainder of such provision or the other
                    provisions of this Agreement and the Parties shall mutually execute such
                    additional documents as may reasonably be required in order to rectify the same.
                    If any provision shall be void, illegal or unenforceable but would be valid and
                    enforceable if a minimum number of amendments are made, then that provision
                    shall be minimally amended to the extent necessary to render the provision valid
                    and enforceable and which would give effect to the intention of the Parties to
                    the maximum extent possible.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 18 -->
              <h2>ENTIRE AGREEMENT</h2>

              <ol>
                <!-- 18.1 -->
                <li>
                  <p>
                    This Agreement, together with the information about the terms of the investment
                    set forth in the Crowdfunding Exercise, contains the entire agreement between
                    the Parties, and supersedes and replaces all previous agreements and
                    understandings between the Parties, with respect to the matters set forth
                    herein. Each Party acknowledges that, in entering into this Agreement, it is not
                    relying on any representation, warranty, pre-contractual statement or other
                    provision except as expressly provided in this Agreement.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 19 -->
              <h2>COSTS</h2>

              <ol>
                <!-- 19.1 -->
                <li>
                  <p>
                    Each Party's costs and expenses (including professional, legal and accountancy
                    expenses) of the preparation, negotiation and execution of this Agreement and
                    any associated documentation shall be borne by such Party.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 20 -->
              <h2>FORCE MAJEURE</h2>

              <ol>
                <!-- 20.1 -->
                <li>
                  <p>
                    The Nominee shall not be liable for any delay in performing its obligations, or
                    failure to perform any such obligations under this Agreement if the delay or
                    failure results from circumstances beyond its reasonable control, including but
                    not limited to, electrical failure on the national grid affecting geographical
                    regions or locations in which the Nominee operates, any other banking payment,
                    communication or information systems, technological failure of any electronic
                    trading platform of an industry wide nature, an Act of God, governmental act,
                    war, armed conflict or civil commotion. Upon the occurrence of any force majeure
                    event, the Nominee shall promptly give written notice of such force majeure
                    event to the Crowdfunder and thereafter the performance of the Crowdfunder’s
                    obligations under this Agreement shall be suspended during that period of force
                    majeure.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 21 -->
              <h2>CONFLICTS</h2>

              <ol>
                <!-- 21.1 -->
                <li>
                  <p>
                    This Agreement does not amend or replace the ECF Platform Agreements, the
                    Shareholders’ Agreement, Term Sheet and/ or the Share Subscription Agreement,
                    but to the extent that there is any conflict between this Agreement and a ECF
                    Platform Agreement, this Agreement shall prevail.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 22 -->
              <h2>GOVERNING LAW</h2>

              <ol>
                <!-- 22.1 -->
                <li>
                  <p>
                    This Agreement shall be governed by, and construed in accordance with, the laws
                    of Malaysia.
                  </p>
                </li>
                <!-- 22.2 -->
                <li>
                  <p>
                    22.2 It is hereby agreed that any dispute, controversy or claim whatsoever
                    arising out of, in relation to or in connection with this Agreement shall at any
                    time be first settled amicably between the Crowdfunder, senior management of the
                    Company, the Nominee and the ECF Operator by way of negotiation in good faith. A
                    Party requiring a dispute, controversy or claim to be resolved amicably shall
                    notify the other Party in writing (<strong>“Dispute Notice”</strong>).
                  </p>
                </li>
                <!-- 22.3 -->
                <li>
                  <p>
                    In the event that no settlement is reached by the Parties within twenty one (21)
                    days from the date of the notice in Clause 13.1, such dispute, controversy or
                    claim may be referred by either Party to mediation comprising the board of
                    Directors of the Company.
                  </p>
                </li>
                <!-- 22.4 -->
                <li>
                  <p>
                    Either party may give notice to the other party of his intention to refer the
                    matter to mediation (“Request for Mediation”). Upon the service of a Request for
                    Mediation on Parties, the dispute or difference shall be subjected to mediation
                    between the Parties as mediated by the board of Directors of theC Company.
                  </p>
                </li>
                <!-- 22.5 -->
                <li>
                  <p>
                    If Parties fail to achieve any settlement during mediation, then in the event
                    any dispute or difference shall arise amongst any of the Parties in this
                    Agreement as to the construction of this Agreement or as to any matter or thing
                    of whatsoever nature arising thereunder or in connection therewith, including
                    any question regarding its existence, validity or termination, such dispute or
                    difference shall be submitted to a single arbitrator to be appointed by the
                    parties in dispute or, failing agreement within 14 days after either party in
                    dispute has given to the other party in dispute a written request to concur in
                    the appointment of an arbitrator, a single arbitrator to be appointed by the
                    Chairman for the time being of the Asian International Arbitration Centre
                    ("AIAC") and such submission shall be a submission to arbitration in accordance
                    with the Rules of the AIAC as presently in force by which the Parties in dispute
                    agree to be so bound. The place of arbitration shall be Malaysia and the
                    arbitration shall be conducted wholly in the English language.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 23 -->
              <h2>EXECUTION</h2>

              <ol>
                <!-- 23.1 -->
                <li>
                  <p>
                    This Agreement shall be deemed duly executed and shall become effective and
                    binding upon the Parties once the Crowdfunder has indicated his or her assent
                    hereto via the means provided on the ECF Platform. Further, this Agreement may
                    be signed in any number of counterparts and by the Parties on separate
                    counterparts, each of which, when so executed, shall be an original, but all
                    counterparts shall together constitute one and the same document. In this
                    regard, the Parties agree that:
                  </p>

                  <div class="alpha-list">
                    <!-- 23.1.x -->
                    <ol>
                      <li>
                        <p>
                          This Agreement has been made available on the ECF Platform throughout the
                          Crowdfunding Exercise.
                        </p>
                      </li>
                      <li>
                        <p>
                          By proceeding with the investment and not exercising the Crowdfunders’
                          Cancellation Rights, the Crowdfunder is deemed to have accepted the terms
                          of this Agreement; and
                        </p>
                      </li>
                      <li>
                        <p>
                          For the avoidance of doubt, the Parties agree that no signature is
                          necessary for this Agreement to be deemed duly executed by conduct and
                          binding upon the Parties.
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>
              </ol>
            </li>

            <li>
              <!-- 24 -->
              <h2>NOTICES</h2>

              <ol>
                <!-- 24.1 -->
                <li>
                  <p>
                    Any notice from the Crowdfunder to the Nominee in respect of this Agreement
                    shall be given by email to nominees&#64;pitchin.my, except where this Agreement
                    or another ECF Platform Agreement that the Crowdfunder executes sets forth
                    alternate means by which the Crowdfunder must give the Nominee notice.
                  </p>
                </li>
                <!-- 24.2 -->
                <li>
                  <p>
                    Any notice from the Nominee to the Crowdfunder in respect of this Agreement may
                    be given either through the Platform, by email to the address set forth in the
                    Crowdfunder's profile or by post or courier to the physical address set forth in
                    the Crowdfunder's profile.
                  </p>
                </li>
                <!-- 24.3 -->
                <li>
                  <p>
                    Notices given pursuant to this Clause 24 through the Platform or by email shall
                    be deemed received by the recipient upon despatch.
                  </p>
                </li>
                <!-- 24.4 -->
                <li>
                  <p>All notices given under this Agreement shall be in the English language.</p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 25 -->
              <h2>VARIATION OF TERMS</h2>

              <p>
                The terms of this Agreement may be amended from time to time as the Parties deem
                necessary, provided that such amendments are communicated to the Company and
                Crowdfunder by way of notice through the Platform or by email, whichever is deemed
                suitable by the Nominee. The amendments are deemed to take effect on the date of
                posting of such notice or email by the Nominee.
              </p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</section>
