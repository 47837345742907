import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TabMenuItem } from '../../../../core/models/menu/tab-menu-item';
import { CampaignInfo } from '../../../../core/models/campaign/campaign-info';

@Component({
  selector: 'app-campaign-info',
  templateUrl: './campaign-info.component.html',
  styleUrls: ['./campaign-info.component.scss'],
})
export class CampaignInfoComponent {
  @Input() campaignInfo: CampaignInfo;
  @Input() tabs: TabMenuItem[];
  @Output() scrollChange = new EventEmitter();
  @Output() changeTab = new EventEmitter();

  constructor() {}

  onSectionChange(route: string) {
    if (!route) return;

    this.scrollChange.emit(route);
  }

  handleTabChange(tab: number) {
    this.changeTab.emit(tab);
  }
}
