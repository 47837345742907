import { Injectable } from '@angular/core';
import { CampaignSectionFilter } from './campaign-section-filter';

@Injectable({
  providedIn: 'root',
})
export class CampaignSectionFilterService {
  landingFilter: CampaignSectionFilter;
  listingFilter: CampaignSectionFilter;

  clear() {
    this.landingFilter = new CampaignSectionFilter();
    this.listingFilter = new CampaignSectionFilter();
  }
}
