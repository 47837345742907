<agmo-loading *ngIf="isLoading" class="tw-mt-8 tw-block"></agmo-loading>

<ng-container *ngIf="!isLoading">
  <div class="tw-py-10">
    <div class="tw-mx-auto tw-max-w-6xl">
      <div class="tw-flex tw-flex-wrap tw-items-center tw-px-6 md:tw-flex-nowrap md:tw-gap-4">
        <div class="tw-flex-auto">
          <div class="p-text-preheader">{{ 'learn.homepage.learningPortal' | translate }}</div>
          <h1 class="p-text-h1">
            {{ 'learn.homepage.pageTitle' | translate }}
          </h1>
        </div>
        <div class="tw-max-w-full md:tw-min-h-[375px] md:tw-flex-[0_0_460px]">
          <img
            class="tw-max-w-full"
            src="/assets/img/banner/learn-hero-image.svg"
            alt="Learning portal image"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid tw-pb-20">
    <div class="tw-grid tw-gap-2.5 md:tw-grid-cols-2">
      <div class="card--portal">
        <h2 class="p-text-h3">
          {{ 'learn.for.investor' | translate }}
        </h2>
        <p>{{ 'learn.homepage.investorDescription' | translate }}</p>

        <div class="tw-mt-10 tw-flex tw-flex-col tw-gap-5">
          <a *ngFor="let item of investorList" [routerLink]="item.article.url">
            <div class="card--learn">
              <h3>{{ item.title }}</h3>

              <p>{{ item.description }}</p>
            </div>
          </a>
        </div>
      </div>

      <div class="card--portal">
        <h2 class="p-text-h3">
          {{ 'learn.for.issuer' | translate }}
        </h2>
        <p>{{ 'learn.homepage.issuerDescription' | translate }}</p>

        <div class="tw-mt-10 tw-flex tw-flex-col tw-gap-5">
          <a *ngFor="let item of issuerList" [routerLink]="item.article.url">
            <div class="card--learn">
              <h3>{{ item.title }}</h3>

              <p>{{ item.description }}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
