import { Component } from '@angular/core';
import { ToastClasses } from '../../core/models/toast/toast-classes';
import { ToastService } from '../../core/services/toast.service';

@Component({
  selector: 'app-style-guide',
  templateUrl: './style-guide.component.html',
  styleUrls: ['./style-guide.component.scss'],
})
export class StyleGuideComponent {
  constructor(private toastService: ToastService) {}

  openToast(type: keyof ToastClasses, showCloseBtn: boolean = false) {
    this.toastService[type]('Hello world!');
  }
}
