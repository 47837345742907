<app-not-found-page *ngIf="notFoundPage"></app-not-found-page>

<ng-container *ngIf="!notFoundPage">
  <div class="container-fluid p-layout--sidebar">
    <div class="p-layout__sidebar">
      <!-- Sidebar: Back button, Sidemenu -->
      <ng-container *ngIf="!category">
        <app-skeleton [width]="'80%'" [marginBottom]="2.5"></app-skeleton>
        <app-skeleton></app-skeleton>
        <app-skeleton></app-skeleton>
      </ng-container>

      <ng-container *ngIf="category">
        <a
          class="tw-inline-block tw-text-sm tw-text-body hover:tw-text-body hover:tw-text-opacity-60"
          [routerLink]="['/learn']"
          >← {{ 'learn.sidebar.back' | translate }}</a
        >
        <app-learn-article-submenu
          [articles]="subMenus"
          [category]="category"
          [currentArticle]="slug"
        ></app-learn-article-submenu>

        <!-- Sidebar: Read more -->
        <app-learn-article-readmore
          *ngIf="category"
          [category]="category"
          [userType]="userType"
          [list]="tableOfContent"
          [listByCategory]="tableOfContentByCategory"
        ></app-learn-article-readmore>
      </ng-container>
    </div>
    <div class="p-layout__body">
      <!-- Breadcrumb -->
      <div class="p-layout__breadcrumb">
        <ng-container *ngIf="userType">
          <section class="tw-flex tw-flex-wrap">
            <div class="tw-w-full tw-pt-4 tw-text-body tw-text-opacity-70 sm:tw-w-auto sm:tw-py-4">
              <span>
                {{ 'learn.for.' + userType | translate }}
              </span>
              <i class="fa fa-angle-right tw-mx-3" aria-hidden="true"></i>
            </div>
            <ul class="list--top-menu">
              <li
                *ngIf="getCurrentCategoryDetails() && getCurrentCategoryDetails().landing_page_url"
              >
                <a
                  class="list--top-menu-item"
                  [routerLink]="[getCurrentCategoryDetails().landing_page_url]"
                >
                  {{ getCurrentCategoryDetails().landing_page_title }}
                </a>
              </li>
              <li class="active">
                <span class="list--top-menu-item">{{ 'common.learnMore' | translate }}</span>
              </li>
              <li *ngIf="getCurrentCategoryDetails() && getCurrentCategoryDetails().faq_url.url">
                <a
                  class="list--top-menu-item"
                  [href]="getCurrentCategoryDetails().faq_url.url"
                  target="_blank"
                >
                  {{ 'campaign.faq' | translate }}
                </a>
              </li>
            </ul>
          </section>
        </ng-container>

        <ng-container *ngIf="!userType">
          <div class="tw-py-4">
            <app-skeleton [width]="'25%'"></app-skeleton>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="isLoading">
        <app-skeleton [height]="2.5" [width]="'80%'" [marginBottom]="1.25"></app-skeleton>
        <app-skeleton></app-skeleton>
        <app-skeleton></app-skeleton>
        <app-skeleton [width]="'70%'"></app-skeleton>
      </ng-container>

      <ng-container *ngIf="!isLoading">
        <div
          class="p-layout__content tw-prose tw-max-w-none tw-text-lg tw-text-body prose-headings:tw-text-black prose-p:tw-font-normal prose-a:tw-text-primary prose-a:tw-no-underline prose-ul:tw-mb-2.5 prose-ul:tw-mt-0 prose-img:tw-my-2.5 prose-img:tw-max-w-full prose-hr:tw-my-8"
          #bodyContent
        >
          <h1 class="p-text-h2 tw-mb-5">{{ title }}</h1>
          <div class="tw-aspect-h-9 tw-aspect-w-16 tw-hidden"></div>
          <div [innerHtml]="content"></div>
          <app-learn-article-footer [articles]="subMenus" [currentArticle]="slug">
          </app-learn-article-footer>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
