import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { unitOfTime } from 'moment';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit {
  @Input() dateFrom: string = null;
  @Input() dateTo: string = null;
  @Input() maxRange: { amount: number; unitOfTime: unitOfTime.Base };
  @Input() readonly: boolean = false;
  @Input() showClear: boolean = true;
  @Input() showLabel: boolean = true;
  @Input() parentClass: string;
  @Input() col: string = '2';
  @Output() goSearch = new EventEmitter<any>();

  today: string = moment().format('YYYY-MM-DD');
  minFrom: string = null;
  maxTo: string = this.today;
  maxDateFrom: moment.Moment;
  minDateTo: moment.Moment;

  constructor() {}

  ngOnInit() {
    this.setMinMax();
  }

  setMinMax() {
    if (this.maxRange) {
      this.maxDateFrom = moment(this.dateFrom).add(this.maxRange.amount, this.maxRange.unitOfTime);
      this.minDateTo = moment(this.dateTo).subtract(this.maxRange.amount, this.maxRange.unitOfTime);

      // not needed for now
      // this.dateFrom
      //     ? this.maxTo =  this.maxDateFrom.isAfter()
      //         ? moment().format('YYYY-MM-DD')
      //         : this.maxDateFrom.format('YYYY-MM-DD')
      //     : this.maxTo = moment().format('YYYY-MM-DD');

      // not needed for now
      // this.dateTo
      //     ? this.minFrom =  this.minDateTo.format('YYYY-MM-DD')
      //     : this.minFrom = null;
    }
  }

  updateValue(id: number) {
    if (id === 1 && this.dateTo && this.maxDateFrom?.isBefore(moment(this.dateTo))) {
      this.dateTo = this.maxDateFrom.format('YYYY-MM-DD');
    }

    if (id === 2 && this.dateFrom && this.minDateTo?.isAfter(moment(this.dateFrom))) {
      this.dateFrom = this.minDateTo.format('YYYY-MM-DD');
    }

    if (id === 1 && moment(this.dateFrom)?.isAfter(moment(this.dateTo))) {
      this.dateTo = this.dateFrom;
    }

    if (id === 2 && moment(this.dateTo)?.isBefore(moment(this.dateFrom))) {
      this.dateFrom = this.dateTo;
    }
  }

  filterDate(id: number) {
    this.setMinMax();
    this.updateValue(id);
    this.goSearch.emit({ dateFrom: this.dateFrom || null, dateTo: this.dateTo || null });
  }

  clearDates() {
    this.dateFrom = null;
    this.dateTo = null;
  }
}
