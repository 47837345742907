import {
  Component,
  DEFAULT_CURRENCY_CODE,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Business } from '../../../core/models/mysec/business';
import { MarketSignalModel } from '../../../core/models/mysec/market-signal.model';
import { BusinessesService } from '../../../core/services/api/mysec/businesses.service';
import { DefaultLogo } from '../../../core/services/constant';
import { ErrorService } from '../../../core/services/error.service';
import { BusinessesFilter } from './businesses-filter';
import * as signalR from '@microsoft/signalr';
import { environment } from '../../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { QueryParamsService } from '../../../core/services/query-params.service';
import { ValidateParamsService } from '../../../core/services/validate-params.service';
import { InvestmentSortType } from '../../../core/enum/investment-sort-type.enum';
import { AuthService } from '../../../core/services/auth/auth.service';
import { InvestorShareMarketStatusFilter } from '../../../core/enum/investor-share-market-status.enum';
import { businessesList } from '../../../../assets/static/placeholder';

@Component({
  selector: 'app-businesses',
  templateUrl: './businesses.component.html',
  styleUrls: ['./businesses.component.scss'],
})
export class BusinessesComponent implements OnInit, OnDestroy {
  @ViewChild('top') top: ElementRef;

  @Input() isPage: boolean = true;
  @Input() take: number;
  @Input() showPagination: boolean = true;

  private hubConnections: any = {};

  displayColumns: string[] = [
    'Businesses',
    'Counter',
    'CompanyAndCounter',
    'LastTrade',
    'BestBid',
    'BidQty',
    'BestAsk',
    'AskQty',
    'PriceChg',
  ];
  total: number;
  isLoading = true;

  params = new BusinessesFilter();
  businessesList: Business[] = [];
  sampleBusinessesList: Business[] = businessesList;
  isWatchList: boolean = false;

  tickerCodes: string[];
  defaultLogo = DefaultLogo;
  allowedParams: string[] = [...Object.keys(new BusinessesFilter()), 'sectorIds'];
  validationSchema: any = {
    investorShareMarketStatus: {
      allowed: Object.values(InvestorShareMarketStatusFilter),
      defaultValue: [],
    },
    orderBy: {
      allowed: Object.values(InvestmentSortType),
      defaultValue: 0,
    },
  };

  InvestorShareMarketStatusFilter = InvestorShareMarketStatusFilter;

  constructor(
    private errorService: ErrorService,
    private businessesService: BusinessesService,
    @Inject(PLATFORM_ID) private platform: Object,
    @Inject(DEFAULT_CURRENCY_CODE) public defaultCurrencyCode: string,
    private queryParamsService: QueryParamsService,
    private validateParamsService: ValidateParamsService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    const defaultParams = this.take
      ? { ...new BusinessesFilter(), take: this.take }
      : new BusinessesFilter();

    let queryParams = this.queryParamsService.getParams();

    this.allowedParams = this.authService.isAuthenticated()
      ? [...Object.keys(new BusinessesFilter()), 'sectorIds']
      : ['currentPage', 'take', 'selectedSectors', 'sectorIds', 'orderBy', 'filterString'];

    // Set the params directly and the `campaign-filters` will return the `selectedSectors[]`
    // otherwise whenever clicking the status tabs will get reset the sectors
    this.params = this.validateParamsService.sanitize(
      this.validationSchema,
      {
        ...defaultParams,
        ...queryParams,
      },
      this.allowedParams,
    );

    if (isPlatformBrowser(this.platform) && location.pathname == '/dashboard/saved-watchlist') {
      this.params = { ...this.params, isWatchList: true };
      this.isWatchList = true;
    } else {
      this.params = { ...this.params, isWatchList: false };
    }

    if (!this.isPage) this.getBusinessesList(this.params);
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platform)) this.stopConnection();
  }

  absValue(item): number {
    if (item < 0) {
      return Math.abs(item);
    }
    return item;
  }

  getBusinessesList(params: BusinessesFilter) {
    this.isLoading = true;
    this.params = params;

    this.businessesService
      .getBusinessesList(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          delete this.params.sectorIds;
        }),
      )
      .subscribe(
        (data) => {
          this.businessesList = data.data;
          this.total = data.total;

          if (
            this.businessesList.find(
              (item) => item.investorShareMarketStatus && item.investorShareMarketStatus.length > 0,
            )
          )
            this.displayColumns = [
              'Businesses',
              'Counter',
              'CompanyAndCounter',
              'Status',
              'LastTrade',
              'BestBid',
              'BidQty',
              'BestAsk',
              'AskQty',
              'PriceChg',
            ];
          else
            this.displayColumns = [
              'Businesses',
              'Counter',
              'CompanyAndCounter',
              'LastTrade',
              'BestBid',
              'BidQty',
              'BestAsk',
              'AskQty',
              'PriceChg',
            ];

          this.tickerCodes = this.businessesList.map((item) => item.tickerCode);
          this.buildConnection();
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  buildConnection() {
    if (isPlatformBrowser(this.platform)) {
      this.tickerCodes.forEach((tickerCode) => {
        this.hubConnections[tickerCode] = new signalR.HubConnectionBuilder()
          .configureLogging(signalR.LogLevel.Debug)
          .withUrl(environment.signalRMarketUrl, {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets,
          })
          .build();

        this.hubConnections[tickerCode]
          .start()
          .then((res) => {
            this.hubConnections[tickerCode]
              .invoke('joinTickerCodeGroup', tickerCode)
              .then(() => console.log('group Connected!', tickerCode))
              .catch((err) => {
                console.log('Error while group connection: ' + err, tickerCode);
              });
          })
          .catch((err) => console.log('Error while starting connection: ' + err, tickerCode));

        this.hubConnections[tickerCode].on(tickerCode, (data: MarketSignalModel) => {
          console.log('Data received: ', tickerCode, data);

          const index = this.businessesList.findIndex((market) => market.tickerCode === tickerCode);
          this.businessesList[index].lastTrade = data.lastDoneTransactionPrice;
          this.businessesList[index].bestBid = data.bestBidPrice;
          this.businessesList[index].bidQty = data.bestBidQuantity;
          this.businessesList[index].bestOffer = data.bestAskPrice;
          this.businessesList[index].offerQty = data.bestAskQuantity;
          this.businessesList[index].priceChg = data.changesPercentage;
        });
      });
    }
  }

  stopConnection() {
    if (this.tickerCodes && this.tickerCodes.length > 0) {
      this.tickerCodes.forEach((tickerCode) => {
        if (this.hubConnections[tickerCode].state === signalR.HubConnectionState.Connected) {
          this.hubConnections[tickerCode].invoke('LeaveTickerCodeGroup', tickerCode);
        }
      });
    }
  }

  checkItemPrice(item: Business): string {
    if (!item.priceChg || item.priceChg == 0) return 'flat';
    else if (item.priceChg > 0) return 'increase';
    else return 'decrease';
  }

  handlePageChange() {
    if (this.top) {
      this.top.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    this.updateUrl(this.params);
  }

  updateUrl(params: any, resetPagination: boolean = false) {
    if (resetPagination) {
      params.currentPage = 1;
    }

    if (params.selectedSectors) {
      params['sectorIds'] = params.selectedSectors.map((sector) => sector.id || sector.key);
    }

    this.queryParamsService.updateUrl(params, ['take', 'selectedSectors']);
    this.getBusinessesList(params);
  }
}
