import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-force-update-message',
  templateUrl: './force-update-message.component.html',
  styleUrls: ['./force-update-message.component.scss'],
})
export class ForceUpdateMessageComponent {
  name: string;

  constructor(private modal: NgbActiveModal) {}

  close() {
    this.modal.dismiss();
  }
}
