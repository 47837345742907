import { Injectable, TemplateRef } from '@angular/core';
import { ToastInfo } from '../models/toast/toast-info';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: ToastInfo[] = [];

  show(body: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ body, ...options });
  }

  success(body: string | TemplateRef<any>, options: any = {}) {
    this.show(body, { classname: 'toast-success', ...options });
  }

  error(body: string | TemplateRef<any>, options: any = {}) {
    this.show(body, { classname: 'toast-error', ...options });
  }

  info(body: string | TemplateRef<any>, options: any = {}) {
    this.show(body, { classname: 'toast-info', ...options });
  }

  warning(body: string | TemplateRef<any>, options: any = {}) {
    this.show(body, { classname: 'toast-warning', ...options });
  }

  remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter((t) => t != toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }
}
