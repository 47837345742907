import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FundRaiseApplication } from '../../models/raise/fund-raise-application';
import { OfferCreation } from '../../models/raise/offer-creation';
import { map, mergeMap } from 'rxjs/operators';
import { FormTemplate } from '../../../shared/models/question/form-template';
import { ApplicationForm } from '../../models/raise/application-form';
import { FundRaiseProcess } from '../../models/raise/fund-raise-process';
import { AccountService } from './account.service';
import { CreateCampaign } from '../../models/campaign/create-campaign';
import { OfferCreationModel } from '../../models/raise/offer-creation.model';
import { CampaignInfo } from '../../models/campaign/campaign-info';
import { CampaignInfoModel } from '../../models/campaign/campaign-info.model';
import { Payment } from '../../models/payment/payment';
import { PaymentModel } from '../../models/payment/payment.model';

@Injectable()
export class RaiseService {
  private route = environment.apiServer + 'Api/v1/users/{userId}/{role}/raiseFund';

  constructor(
    private http: HttpClient,
    private accountService: AccountService,
  ) {}

  getRaiseFundApplications(): Observable<FundRaiseApplication[]> {
    return this.http.get(this.route + '/applications').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getRaiseFundForm(): Observable<ApplicationForm> {
    return this.http.get(this.route + '/applicationForm').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getApplicationForm(url: string): Observable<FormTemplate> {
    return this.http.get(url).pipe(
      map((data: any) => {
        let form = new FormTemplate(data);
        return form;
      }),
    );
  }

  submitApplicationForm(form: FormTemplate): Observable<any> {
    let body = {
      content: JSON.stringify(form),
    };

    return this.http.post(this.route + '/applicationForm', body);
  }

  getApplicationProgress(
    fundRaiseApplicationId: number,
    stepId: number,
  ): Observable<FundRaiseProcess> {
    return this.http.get(this.route + '/' + fundRaiseApplicationId + '/module/' + stepId).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getSubmisionForm(
    fundRaiseApplicationId: number,
    stepId: number,
    submissionId: number,
  ): Observable<ApplicationForm> {
    return this.http
      .get(
        this.route +
          '/' +
          fundRaiseApplicationId +
          '/steps/' +
          stepId +
          '/submission/' +
          submissionId,
      )
      .pipe(
        map((data: any) => {
          return data.result;
        }),
      );
  }

  getDynamicForm(fundRaiseApplicationId: number, stepId: number): Observable<ApplicationForm> {
    return this.http.get(this.route + '/' + fundRaiseApplicationId + '/steps/' + stepId).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  submitDynamicForm(
    fundRaiseApplicationId: number,
    stepId: number,
    form: FormTemplate,
  ): Observable<any> {
    let body = {
      stepId: stepId.toString(),
      content: JSON.stringify(form),
    };

    return this.http.post(this.route + '/' + fundRaiseApplicationId + '/steps', body);
  }

  getOfferCreation(fundRaiseApplicationId: number): Observable<OfferCreation> {
    return this.http.get(this.route + '/' + fundRaiseApplicationId + '/offerCreation').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  reviewOfferCreation(
    fundRaiseApplicationId: number,
    offerCreation: OfferCreation,
    approve: boolean,
    approveBonusShare: boolean,
  ) {
    let body = new OfferCreationModel(offerCreation, approve, approveBonusShare);
    return this.http.put(this.route + '/' + fundRaiseApplicationId + '/offerCreation', body);
  }

  uploadAttachment(
    file: File,
    fundRaiseApplicationId: number,
    stepId: number,
    documentName: string,
  ): Observable<any> {
    let body = {
      FileName: file.name,
      DocumentName: documentName,
    };

    return this.http
      .post(this.route + '/' + fundRaiseApplicationId + '/steps/' + stepId + '/attachment', body)
      .pipe(
        mergeMap((data: any) => {
          if (data && data.result) {
            if (documentName)
              return this.accountService.uploadDocWithStepId(
                data.result,
                file,
                stepId,
                fundRaiseApplicationId,
              );
            else return this.accountService.uploadImage(data.result, file);
          } else return data.result;
        }),
      );
  }

  createCampaign(campaign: CampaignInfo, fundRaiseApplicationId: number): Observable<any> {
    let body = new CampaignInfoModel(campaign);

    return this.http.post(this.route + '/' + fundRaiseApplicationId + '/campaigns', body);
  }

  getCampaignDetails(
    campaignId: number,
    fundRaiseApplicationId: number,
  ): Observable<CreateCampaign> {
    return this.http
      .get(this.route + '/' + fundRaiseApplicationId + '/campaigns/' + campaignId)
      .pipe(
        map((data: any) => {
          return data.result;
        }),
      );
  }

  updateCampaign(campaign: CampaignInfo, fundRaiseApplicationId: number): Observable<any> {
    let body = new CampaignInfoModel(campaign);

    return this.http.put(this.route + '/' + fundRaiseApplicationId + '/campaigns', body);
  }

  getCommonKeys(fundRaiseApplicationId: number): Observable<any> {
    return this.http.get(this.route + '/' + fundRaiseApplicationId + '/dynamicForm/keys').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  setPaymentMethod(fundRaiseApplicationId: number, paymentModel: Payment): Observable<any> {
    const body = new PaymentModel(paymentModel);

    return this.http.post(this.route + '/' + fundRaiseApplicationId + '/payment', body).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  deleteAttachmentByAttachmentId(
    fundRaiseApplicationid: number,
    stepId: number,
    attachmentId: number,
  ): Observable<any> {
    return this.http.delete(
      this.route +
        '/' +
        fundRaiseApplicationid +
        '/steps/' +
        stepId +
        '/attachment/' +
        attachmentId,
    );
  }

  deleteAttachment(fundRaiseApplicationid: number, stepId: number): Observable<any> {
    return this.http.delete(
      this.route + '/' + fundRaiseApplicationid + '/steps/' + stepId + '/attachment',
    );
  }
}
