import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TeamMember } from '../../../models/mysec/team-member';
import { TeamMemberModel } from '../../../models/mysec/team-member.model';
import { StorageService } from '../../storage.service';

@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  private route = environment.apiServer + 'api/v1/MySec/{role}';

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) {}

  getTeams(): Observable<TeamMember[]> {
    return this.http.get(this.route + '/companies/teams').pipe(
      map((data: any) => {
        return data.result ? data.result.map((item) => new TeamMember(item)) : [];
      }),
    );
  }

  getCompanyTeams(tickerCode: string): Observable<TeamMember[]> {
    let params = {};

    if (!this.storageService.role) params['noToken'] = 'noToken';

    return this.http.get(`${this.route}/markets/${tickerCode}/teams`, { params: params }).pipe(
      map((data: any) => {
        return data.result ? data.result.map((item) => new TeamMember(item)) : [];
      }),
    );
  }

  getTeamMember(mySecCompanyTeamId: number): Observable<TeamMember> {
    return this.http.get(`${this.route}/companies/teams/${mySecCompanyTeamId}`).pipe(
      map((data: any) => {
        return new TeamMember(data.result);
      }),
    );
  }

  addTeamMember(teamMember: TeamMember): Observable<any> {
    let body = new TeamMemberModel(teamMember);

    return this.http.post(`${this.route}/companies/teams`, body);
  }

  updateTeamMember(mySecCompanyTeamId: number, teamMember: TeamMember): Observable<any> {
    let body = new TeamMemberModel(teamMember);

    return this.http.put(`${this.route}/companies/teams/${mySecCompanyTeamId}`, body);
  }

  deleteTeamMember(mySecCompanyTeamId: number): Observable<any> {
    return this.http.delete(`${this.route}/companies/teams/${mySecCompanyTeamId}`);
  }
}
