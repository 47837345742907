<section class="section section-shaped">
  <div class="tw-mx-auto tw-w-full tw-max-w-[996px] tw-px-4">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-2 progress-step" [class.active]="step == 1">
            <div
              class="pitchin-icon pitchin-icon-profile"
              [class.pitchin-icon-check-green]="step > 1"
            ></div>
            <div class="progress-title" [class.text-primary]="step >= 1">
              {{ 'registerInvestor.profile' | translate }}
            </div>
          </div>
          <div class="col-lg-1 p-0 d-none d-lg-flex">
            <div class="progress-line"></div>
          </div>
          <div class="col-lg-3 progress-step" [class.active]="step == 2">
            <div class="pitchin-icon pitchin-icon-coins"></div>
            <div class="progress-title" [class.text-primary]="step >= 2">
              {{ 'registerInvestor.investorType' | translate }}
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-5" *ngIf="step == 1">
          <div class="col-lg-8">
            <h2 class="text-center font-weight-extrabold">
              {{ 'registerInvestor.aboutYou' | translate }}
            </h2>
            <div class="text-center mb-4">{{ 'registerInvestor.subAboutYou' | translate }}</div>
            <form [formGroup]="form" [class.show-form-error]="showFormError">
              <div class="mb-3">
                <div class="input-field" *ngIf="!isLoading">
                  <div
                    class="profile-upload"
                    agmoUploader
                    [accept]="accept"
                    (selectFiles)="selectFile($event)"
                  >
                    <div class="profile-image pitchin-icon-profile" *ngIf="!profileImage"></div>
                    <img
                      class="profile-image"
                      alt="pitchIN User"
                      [src]="profileImage"
                      *ngIf="profileImage"
                    />
                    <div class="pitchin-icon pitchin-icon-add"></div>
                  </div>
                </div>
                <div class="text-primary text-center pt-3" *ngIf="!profileImage">
                  {{ 'common.dragAndDropImage' | translate }}
                </div>
                <div class="text-primary text-center pt-3" *ngIf="profileImage">
                  {{ 'common.lookingGood' | translate }}
                </div>
                <agmo-loading *ngIf="isLoading"></agmo-loading>
              </div>
              <div class="form-group">
                <label class="input-label">{{ 'signUp.identificationType' | translate }}*</label>
                <mat-select
                  [placeholder]="'common.selectPlaceholder' | translate"
                  class="form-control"
                  formControlName="userIdentityNoType"
                  (selectionChange)="changeIdentificationType()"
                >
                  <mat-option
                    *ngFor="let type of IdentificationType | enumToArray"
                    [value]="type.key"
                  >
                    {{ 'signUp.' + type.value | translate }}
                  </mat-option>
                </mat-select>
              </div>
              <div class="form-group">
                <label class="input-label">
                  {{ 'signUp.fullNameAsPer' | translate }}
                  {{ 'signUp.' + IdentificationType[form.value.userIdentityNoType] | translate }}*
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{ 'signUp.fullNamePlaceholder' | translate }}"
                  formControlName="fullName"
                />
              </div>
              <div class="form-group">
                <label class="input-label">
                  {{ 'signUp.' + IdentificationType[form.value.userIdentityNoType] | translate }}
                  {{ 'signUp.number' | translate }}*
                </label>
                <input
                  class="form-control"
                  *ngIf="form.value.userIdentityNoType != IdentificationType.Passport"
                  placeholder="{{ 'signUp.icNumberPlaceholder' | translate }}"
                  formControlName="identityNo"
                  [mask]="icMaskFormat"
                  type="text"
                />
                <input
                  class="form-control"
                  *ngIf="form.value.userIdentityNoType == IdentificationType.Passport"
                  placeholder="{{ 'signUp.passportPlaceholder' | translate }}"
                  formControlName="identityNo"
                  type="text"
                  alphanumeric
                />
                <div
                  class="error"
                  *ngIf="
                    showFormError &&
                    form.controls.identityNo.errors &&
                    (form.controls.identityNo.errors.pattern ||
                      form.controls.identityNo.errors.mask)
                  "
                >
                  {{ 'signUp.' + IdentificationType[form.value.userIdentityNoType] | translate }}
                  {{ 'signUp.number' | translate }}
                  {{ 'signUp.incorrectFormat' | translate }}
                </div>
              </div>
              <div class="form-group">
                <label class="input-label">{{ 'registerInvestor.aboutMe' | translate }}</label>
                <textarea
                  class="form-control"
                  cols="30"
                  rows="5"
                  formControlName="aboutMe"
                  placeholder="{{ 'registerInvestor.aboutMePlaceHolder' | translate }}"
                ></textarea>
              </div>
              <div class="d-flex justify-content-center mt-5">
                <button class="btn btn-primary" (click)="next()">
                  {{ 'registerInvestor.next' | translate }}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="row justify-content-center mt-5" *ngIf="step == 2">
          <div class="col-lg-12">
            <h2 class="text-center font-weight-extrabold">
              {{ 'registerInvestor.investorType' | translate }}
            </h2>
            <div class="text-center mb-5">
              {{ 'registerInvestor.selectInvestmentProfile' | translate }}
            </div>
            <app-investor-profile
              [investorProfile]="investorProfile"
              [isSettings]="false"
              (submitForm)="submit($event)"
            ></app-investor-profile>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
