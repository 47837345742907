<section class="section section-shaped">
  <div class="container">
    <div class="text-primary text-underline cursor-pointer back-button h5" (click)="back()">
      {{ 'common.back' | translate }}
    </div>
    <h6 class="mb-5" *ngIf="campaignInfo?.remark">
      {{ 'common.remark' | translate }} : {{ campaignInfo?.remark }}
    </h6>
    <div class="title text-center">{{ 'campaign.campaignDetails' | translate }}</div>
    <div class="text-center mb-5" id="campaignDesc">
      {{ 'campaign.campaignDetailsDesc' | translate }}
    </div>
    <div class="row">
      <!-- <div class="col-lg-3">
                <nav class="navbar navbar-expand-md navbar-light d-none d-lg-block headroom--pinned" id="tabList">
                    <ul class="nav flex-column">
                        <li *ngFor="let tab of tabs;" class="nav-item" [class.active]="selectedTab === tab.route">
                            <a class="nav-link" (click)="scrollTo(tab.route)">{{ tab.name | translate }}</a>
                        </li>
                    </ul>
                </nav>
            </div> -->
      <div class="col-12">
        <form
          (ngSubmit)="submit(true)"
          [formGroup]="form"
          [class.show-form-error]="showFormError"
          *ngIf="ckEditorLoaded && !isLoading"
        >
          <div class="row">
            <div class="col-lg-12" id="title" *ngIf="!isEditOngoingCampaign">
              <div class="input-panel">
                <label class="input-label">{{ 'raise.title' | translate }}*</label>
                <div class="input-field">
                  <input
                    type="text"
                    class="form-control"
                    [placeholder]="'raise.titlePlaceholder' | translate"
                    formControlName="name"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-12" id="campaignVideo">
              <div class="input-panel">
                <label class="input-label">{{ 'raise.campaignVideo' | translate }}*</label>
                <div class="input-field">
                  <input
                    type="text"
                    class="form-control"
                    [placeholder]="'raise.campaignVideoPlaceholder' | translate"
                    formControlName="videoUrl"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-12 input-panel" id="summary">
              <div class="input-label">{{ 'raise.summary' | translate }}*</div>
              <div class="input-field">
                <ckeditor
                  class="ck-editor"
                  formControlName="summary"
                  (focus)="removeRichText($event)"
                  [editor]="Editor"
                  [config]="getConfig('summary')"
                ></ckeditor>
              </div>
            </div>
            <div class="col-lg-12 input-panel" id="problem">
              <div class="input-label">{{ 'raise.problem' | translate }}</div>
              <div class="input-field">
                <ckeditor
                  class="ck-editor"
                  formControlName="problem"
                  (focus)="removeRichText($event)"
                  [editor]="Editor"
                  [config]="getConfig('problem')"
                ></ckeditor>
              </div>
            </div>
            <div class="col-lg-12 input-panel" id="solution">
              <div class="input-label">{{ 'raise.solution' | translate }}</div>
              <div class="input-field">
                <ckeditor
                  class="ck-editor"
                  formControlName="solution"
                  (focus)="removeRichText($event)"
                  [editor]="Editor"
                  [config]="getConfig('solution')"
                ></ckeditor>
              </div>
            </div>
            <div class="col-lg-12 input-panel" id="product">
              <div class="input-label">{{ 'raise.product' | translate }}</div>
              <div class="input-field">
                <ckeditor
                  class="ck-editor"
                  formControlName="product"
                  (focus)="removeRichText($event)"
                  [editor]="Editor"
                  [config]="getConfig('product')"
                ></ckeditor>
              </div>
            </div>
            <div class="col-lg-12 input-panel" id="traction">
              <div class="input-label">{{ 'raise.traction' | translate }}</div>
              <div class="input-field">
                <ckeditor
                  class="ck-editor"
                  formControlName="traction"
                  (focus)="removeRichText($event)"
                  [editor]="Editor"
                  [config]="getConfig('traction')"
                ></ckeditor>
              </div>
            </div>
            <div class="col-lg-12 input-panel" id="customers">
              <div class="input-label">{{ 'raise.customers' | translate }}</div>
              <div class="input-field">
                <ckeditor
                  class="ck-editor"
                  formControlName="customers"
                  (focus)="removeRichText($event)"
                  [editor]="Editor"
                  [config]="getConfig('customers')"
                >
                </ckeditor>
              </div>
            </div>
            <div class="col-lg-12 input-panel" id="businessModel">
              <div class="input-label">{{ 'raise.businessModel' | translate }}</div>
              <div class="input-field">
                <ckeditor
                  class="ck-editor"
                  formControlName="businessModel"
                  (focus)="removeRichText($event)"
                  [editor]="Editor"
                  [config]="getConfig('businessModel')"
                >
                </ckeditor>
              </div>
            </div>
            <div class="col-lg-12 input-panel" id="market">
              <div class="input-label">{{ 'raise.market' | translate }}</div>
              <div class="input-field">
                <ckeditor
                  class="ck-editor"
                  formControlName="market"
                  (focus)="removeRichText($event)"
                  [editor]="Editor"
                  [config]="getConfig('market')"
                ></ckeditor>
              </div>
            </div>
            <div class="col-lg-12 input-panel" id="competition">
              <div class="input-label">{{ 'raise.competition' | translate }}</div>
              <div class="input-field">
                <ckeditor
                  class="ck-editor"
                  formControlName="competition"
                  (focus)="removeRichText($event)"
                  [editor]="Editor"
                  [config]="getConfig('competition')"
                >
                </ckeditor>
              </div>
            </div>
            <div class="col-lg-12 input-panel" id="funding">
              <div class="input-label">{{ 'raise.funding' | translate }}</div>
              <div class="input-field">
                <ckeditor
                  class="ck-editor"
                  formControlName="funding"
                  (focus)="removeRichText($event)"
                  [editor]="Editor"
                  [config]="getConfig('funding')"
                ></ckeditor>
              </div>
            </div>
            <div class="col-lg-12 input-panel" id="vision">
              <div class="input-label">{{ 'raise.vision' | translate }}</div>
              <div class="input-field">
                <ckeditor
                  class="ck-editor"
                  formControlName="vision"
                  (focus)="removeRichText($event)"
                  [editor]="Editor"
                  [config]="getConfig('vision')"
                ></ckeditor>
              </div>
            </div>
            <div class="col-lg-12 input-panel" id="founders">
              <div class="input-label">{{ 'raise.founders' | translate }}</div>
              <div class="input-field">
                <ckeditor
                  class="ck-editor"
                  formControlName="founders"
                  (focus)="removeRichText($event)"
                  [editor]="Editor"
                  [config]="getConfig('founders')"
                ></ckeditor>
              </div>
            </div>
            <div class="col-lg-12 input-panel" id="team">
              <div class="input-label">{{ 'raise.team' | translate }}</div>
              <div class="input-field">
                <ckeditor
                  class="ck-editor"
                  formControlName="team"
                  (focus)="removeRichText($event)"
                  [editor]="Editor"
                  [config]="getConfig('team')"
                ></ckeditor>
              </div>
            </div>
            <div class="col-lg-12 input-panel" id="investmentTerms">
              <div class="input-label">{{ 'raise.investmentTerms' | translate }}</div>
              <div class="input-field">
                <ckeditor
                  class="ck-editor"
                  formControlName="investmentTerms"
                  (focus)="removeRichText($event)"
                  [editor]="Editor"
                  [config]="getConfig('investmentTerms')"
                >
                </ckeditor>
              </div>
            </div>
            <div class="col-lg-12 input-panel" id="disclosure">
              <div class="input-label">{{ 'raise.disclosure' | translate }}*</div>
              <div class="input-field">
                <ckeditor
                  class="ck-editor"
                  formControlName="disclosure"
                  (focus)="removeRichText($event)"
                  [editor]="Editor"
                  [config]="ckEditorConfig"
                >
                </ckeditor>
              </div>
            </div>
          </div>
          <div class="row border-top" *ngIf="!isEditOngoingCampaign">
            <div class="col-lg-12">
              <div class="text-black font-weight-extrabold">{{ 'campaign.faq' | translate }}</div>
              <div class="description">{{ 'raise.faqPlaceholder' | translate }}</div>
              <ng-container formArrayName="campaignFAQs">
                <div
                  class="faq-panel mt-3"
                  *ngFor="let faq of campaignFAQsFormControl.controls; let i = index"
                  formGroupName="{{ i }}"
                >
                  <div class="d-flex panel-padding cursor-pointer" (click)="showMore(faq)">
                    <div class="text-black font-weight-bold w-100">
                      {{ faq.value.title || '-' }}
                    </div>
                    <div
                      class="pitchin-icon pitchin-icon-delete align-self-end"
                      (click)="$event.stopPropagation(); removeArrayForm(i, 'campaignFAQs')"
                    ></div>
                  </div>
                  <div class="faq-form panel-padding" *ngIf="faq.value.showMore">
                    <div class="pb-3">
                      <div class="input-label">
                        {{ 'campaign.question' | translate }}
                      </div>
                      <div class="input-field">
                        <input type="text" class="form-control" formControlName="title" />
                      </div>
                    </div>
                    <div>
                      <div class="input-label">
                        {{ 'campaign.answer' | translate }}
                      </div>
                      <div class="input-field">
                        <ckeditor
                          class="ck-editor"
                          formControlName="description"
                          (focus)="removeRichText($event)"
                          [editor]="Editor"
                          [config]="ckLinkEditorConfig"
                        >
                        </ckeditor>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="btn btn-primary btn-add mt-3" (click)="addArrayForm('campaignFAQs')">
                <i class="fa fa-plus mr-2"></i>
                {{ 'common.add' | translate }}
              </div>
            </div>
          </div>
          <div class="row border-top" *ngIf="!isEditOngoingCampaign">
            <div class="col-lg-12">
              <div class="text-black font-weight-extrabold">{{ 'campaign.risks' | translate }}</div>
              <div class="description">{{ 'raise.riskPlaceholder' | translate }}</div>
              <ng-container formArrayName="campaignRisks">
                <div
                  class="faq-panel mt-3"
                  *ngFor="let risk of campaignRisksFormControl.controls; let i = index"
                  formGroupName="{{ i }}"
                >
                  <div class="d-flex panel-padding cursor-pointer" (click)="showMore(risk)">
                    <div class="text-black font-weight-bold w-100">
                      {{ risk.value.title || '-' }}
                    </div>
                    <div
                      class="pitchin-icon pitchin-icon-delete align-self-end"
                      (click)="$event.stopPropagation(); removeArrayForm(i, 'campaignRisks')"
                    ></div>
                  </div>
                  <div class="faq-form panel-padding" *ngIf="risk.value.showMore">
                    <div class="pb-3">
                      <div class="input-label">
                        {{ 'common.title' | translate }}
                      </div>
                      <div class="input-field">
                        <input type="text" class="form-control" formControlName="title" />
                      </div>
                    </div>
                    <div>
                      <div class="input-label">
                        {{ 'campaign.description' | translate }}
                      </div>
                      <div class="input-field">
                        <ckeditor
                          class="ck-editor"
                          formControlName="description"
                          (focus)="removeRichText($event)"
                          [editor]="Editor"
                          [config]="ckLinkEditorConfig"
                        >
                        </ckeditor>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="btn btn-primary btn-add mt-3" (click)="addArrayForm('campaignRisks')">
                <i class="fa fa-plus mr-2"></i>
                {{ 'common.add' | translate }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12" *ngIf="!isEditOngoingCampaign">
              <div class="text-center pt-3">
                <button type="submit" class="btn btn-primary btn-submit">
                  {{ 'common.submit' | translate }}
                </button>
              </div>
            </div>
            <div class="col-lg-12" *ngIf="isEditOngoingCampaign">
              <div class="pt-3">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  (click)="submitCampaignDraft(false)"
                >
                  {{ 'campaign.saveAsDraft' | translate }}
                </button>
                <button type="button" class="btn btn-primary" (click)="submitCampaignDraft(true)">
                  {{ 'campaign.submitForReview' | translate }}
                </button>
                <button
                  type="button"
                  class="btn btn-secondary float-right"
                  (click)="deleteCampaignDraft()"
                  *ngIf="
                    campaignInfo.campaignDetailApprovalStatus == CampaignDetailApprovalStatus.Draft
                  "
                >
                  {{ 'campaign.deleteDraft' | translate }}
                </button>
              </div>
            </div>
          </div>
        </form>
        <agmo-loading *ngIf="isLoading || !ckEditorLoaded"></agmo-loading>
      </div>
    </div>
  </div>
</section>
