import { Component } from '@angular/core';

@Component({
  selector: 'agmo-block-ui-modal',
  templateUrl: './block-ui-modal.component.html',
  styleUrls: ['./block-ui-modal.component.scss'],
})
export class BlockUiModalComponent {
  block = false;
  show = false;
}
