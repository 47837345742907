<ng-container *ngIf="!isLoading; else loading">
  <div *ngIf="videoUrl; else heroImage">
    <div
      class="embed-responsive embed-responsive-16by9 tw-overflow-hidden tw-rounded-[10px]"
      id="campaignVideo"
    >
      <iframe
        class="embed-responsive-item"
        title="campaign?.brandName"
        [src]="videoUrl | formatVideoURL"
        allowfullscreen
      ></iframe>
    </div>
  </div>

  <ng-template #heroImage>
    <div class="tw-aspect-h-9 tw-aspect-w-16">
      <img
        class="tw-w-full tw-rounded-[10px] tw-object-cover tw-object-center"
        [src]="campaign.company?.companyBannerUrl || defaultBanner"
        [alt]="campaign.company?.brandName"
      />
    </div>
  </ng-template>
</ng-container>

<ng-template #loading>
  <div class="tw-aspect-h-9 tw-aspect-w-16 tw-relative">
    <app-skeleton [className]="'tw-absolute tw-top-0 !tw-h-full'"></app-skeleton>
  </div>
</ng-template>
