import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CampaignStatus } from '../../../core/enum/campaign-status.enum';

@Component({
  selector: 'campaign-badge',
  templateUrl: './campaign-badge.component.html',
  styleUrls: ['./campaign-badge.component.scss'],
})
export class CampaignBadgeComponent implements OnChanges {
  @Input() status: CampaignStatus;

  CampaignStatus: typeof CampaignStatus = CampaignStatus;

  badgeColor: string;
  badgeLabel: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['status']) {
      this.initBadge();
    }
  }

  initBadge() {
    this.badgeLabel = this.getStatusLabel(this.status);
    this.badgeColor = this.status ? 'badge--' + CampaignStatus[this.status].toLowerCase() : '';
  }

  getStatusLabel(status: CampaignStatus) {
    switch (status) {
      case CampaignStatus.Success:
        return 'campaign.FullyFunded';

      case CampaignStatus.Fail:
        return 'campaign.Ended';

      case CampaignStatus.Rejected:
        return 'campaign.Ended';

      default:
        return 'campaign.' + CampaignStatus[status];
    }
  }
}
