import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { FpxBank } from '../../models/payment/fpx-bank';
import { Receipt } from '../../models/payment/receipt';
import { DuitNowBank } from '../../models/payment/duit-now-bank';

@Injectable()
export class PaymentService {
  private route = environment.apiServer + 'Api/v1/ECF/{role}/fpx';

  constructor(private http: HttpClient) {}

  getBankList(accountType: string): Observable<FpxBank[]> {
    return this.http.get(this.route + '/banklist/' + accountType).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getDuitNowBankList(accountType: string): Observable<DuitNowBank[]> {
    return this.http
      .get(environment.apiServer + 'Api/v1/ECF/{role}/payment/banklist/' + accountType)
      .pipe(
        map((data: any) => {
          return data.result;
        }),
      );
  }

  getTransactionDetail(transactionId: string): Observable<Receipt> {
    return this.http.get(this.route + '/investment/' + transactionId).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getRaiseFundTransactionDetail(transactionId: string): Observable<Receipt> {
    return this.http.get(this.route + '/raiseFund/' + transactionId).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getTransactionWalletDetails(transactionId: string): Observable<Receipt> {
    return this.http.get(this.route + '/wallet/' + transactionId).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }
}
