import { ErrorService } from '../../../../core/services/error.service';
import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessDirectoryService } from '../../../../core/services/api/business-directory.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { BlockUiService } from '../../../../shared/services/blockUi/block-ui.service';
import { AccountService } from '../../../../core/services/api/account.service';
import { catchError, finalize } from 'rxjs/operators';
import { BusinessDirectoryInfo } from '../../../../core/models/business-directory/business-directory-info';
import { DefaultLogo } from '../../../../core/services/constant';
import { TabItem } from '../../../../core/models/menu/tab-item';
import { FloatingMenu } from '../../../../shared/models/floating-menu/floating-menu';
import { FloatingMenuItem } from '../../../../shared/models/floating-menu/floating-menu-item';
import { HeaderService } from '../../../../shared/services/header/header.service';
import { isPlatformBrowser } from '@angular/common';
import { SettingService } from '../../../../core/services/api/setting.service';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { StatusCodeResponseService } from 'src/app/core/services/status-code-response.service';
import { GtmDataLayerService } from 'src/app/core/services/gtm-data-layer.service';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  slug: string;
  businessDirectory: BusinessDirectoryInfo;
  isLogin: boolean = false;
  defaultLogo = DefaultLogo;
  selectedTab: number = 1;
  tabs: TabItem[] = [
    {
      id: 1,
      name: 'campaign.businessInfo',
    },
  ];
  companyMenus: FloatingMenuItem[] = [
    { name: 'company.summary', route: 'summary' },
    { name: 'company.about', route: 'about' },
  ];
  selectedMenu: string;
  floatingMenus: FloatingMenu[];
  navbar: any;
  sticky: number;
  notFoundPage = false;

  constructor(
    private businessDirectoryService: BusinessDirectoryService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private errorService: ErrorService,
    private authService: AuthService,
    private blockUiService: BlockUiService,
    private accountService: AccountService,
    private headerService: HeaderService,
    @Inject(PLATFORM_ID) private platform: Object,
    private settingService: SettingService,
    private statusCodeResponseService: StatusCodeResponseService,
    private _gtmDataLayerService: GtmDataLayerService,
  ) {}

  ngOnInit() {
    if (this.authService.isAuthenticated()) this.isLogin = true;

    this.slug = this.activatedRoute.snapshot.params.companySlug;
    this.getBusiness();
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platform)) {
      this.settingService.notFoundPage.subscribe((notFoundPage) => {
        this.notFoundPage = notFoundPage;
      });
    }
  }

  getBusiness() {
    this.businessDirectoryService.getBusinessBySlug(this.slug).subscribe(
      (data) => {
        this.businessDirectory = data;
        this.setHeaderTag();

        if (!this.businessDirectory.companyDetail) return;

        if (!this.businessDirectory.companyDetail.description)
          this.companyMenus = this.companyMenus.filter((menu) => menu.route != 'summary');

        if (!this.businessDirectory.companyDetail.info)
          this.companyMenus = this.companyMenus.filter((menu) => menu.route != 'about');

        if (this.companyMenus.length > 0) {
          this.floatingMenus = [
            {
              name: 'companyMenu',
              scrollBuffer: 150,
              menuItems: this.companyMenus,
            },
          ];

          this.selectedMenu = this.companyMenus[0].route;
        }
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  setHeaderTag() {
    let pageTitle = `About ${this.businessDirectory.brandName} | pitchIN`;

    this.headerService.setHeaderTag(pageTitle);
    this.logPageView(pageTitle);
  }

  logPageView(title: string) {
    this._gtmDataLayerService.logPageView(this.router.url, title, 'Details');
  }

  notifyMe() {
    this.blockUiService.open();

    this.accountService
      .notifyBusiness(this.businessDirectory.id)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        () => {
          this.businessDirectory.isSubscribed = !this.businessDirectory.isSubscribed;
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (!this.navbar) this.navbar = document.getElementById('campaign-navbar');

    if (this.navbar && !this.sticky) this.sticky = this.navbar.offsetTop;

    if (!this.navbar || !this.sticky) return;

    if (window.pageYOffset >= this.sticky) this.navbar.classList.add('sticky');
    else this.navbar.classList.remove('sticky');
  }

  scrollChange(route: string) {
    if (route) this.selectedMenu = route;
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.notFoundPage = false;
  }

  changeTab(id: number, event?: PointerEvent) {}

  ngOnDestroy() {
    this.settingService.notFoundPage.next(false);
  }
}
