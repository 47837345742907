import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PortfolioListModel } from '../../../core/models/portfolio/portfolio-list.model';
import { DATE_FORMATS } from '../../../core/services/constant';

@Component({
  selector: 'app-valuation-basis-popup',
  templateUrl: './valuation-basis-popup.component.html',
  styleUrls: ['./valuation-basis-popup.component.scss'],
})
export class ValuationBasisPopupComponent {
  portfolioListModel: PortfolioListModel;

  dateFormat = DATE_FORMATS.defaultDate;

  constructor(private modal: NgbActiveModal) {}

  close() {
    this.modal.dismiss();
  }
}
