import { AccountService } from '../../../core/services/api/account.service';
import { ErrorService } from '../../../core/services/error.service';
import { BlockUiService } from '../../../shared/services/blockUi/block-ui.service';
import { Role } from '../../../core/enum/role.enum';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
// import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {
  role: Role;
  token: string;
  email: string;
  isLoading: boolean = true;

  Role: typeof Role = Role;

  constructor(
    // public afAuth: AngularFireAuth,
    private activatedRoute: ActivatedRoute,
    private blockUiService: BlockUiService,
    private accountService: AccountService,
    private errorService: ErrorService,
    private router: Router,
    @Inject(PLATFORM_ID) private _platformId: Object,
  ) {}

  ngOnInit() {
    this.role = parseInt(this.activatedRoute.snapshot.params.role);
    this.token = this.activatedRoute.snapshot.params.token;
    this.email = this.activatedRoute.snapshot.params.email;

    if (isPlatformBrowser(this._platformId)) this.verifyEmail();
  }

  verifyEmail() {
    this.isLoading = true;

    this.accountService
      .verifyEmail(this.token, this.email)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          let role = Role[this.role];
          let roleText = role.split(/(?=[A-Z])/);
          let roleRoute = roleText.join('-').toLowerCase();
          this.router.navigate(['/sign-up', roleRoute]);
          this.accountService.currentUser.next(null);
        },
        (err) => {
          if (err.Error && err.Error.Code && err.Error.Code == 'TOKEN_EXPIRED_ERROR') {
            this.isLoading = false;
            return;
          }

          this.errorService.showError(err);
        },
      );
  }

  resendEmail() {
    this.blockUiService.open();

    this.accountService
      .resendVerifyEmail(this.email)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        () => {},
        (err) => {
          this.errorService.showError(err);
        },
      );
  }
}
