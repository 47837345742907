<section>
  <h4 class="tw-mt-8 tw-font-body tw-text-base tw-font-semibold">
    {{ 'learn.sidebar.' + category | translate }}
  </h4>

  <ul class="list--article">
    <li *ngFor="let item of articles" [class.active]="item.uid == currentArticle">
      <a [routerLink]="[item.url]">{{ item.data?.menu_title || item.data?.title }}</a>
    </li>
  </ul>
</section>
