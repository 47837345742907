import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'formatVideoURL',
})
export class FormatVideoURLPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(url: string): SafeUrl {
    let videoUrl = '';

    let newURL: URL;

    try {
      newURL = new URL(url);
      videoUrl = url;
    } catch (_) {}

    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    const vimeoRegExp =
      /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
    const matchVimeo = url.match(vimeoRegExp);

    if (match && match[2].length === 11)
      videoUrl = 'https://www.youtube.com/embed/' + match[2] + '?rel=0&modestbranding=1';
    else if (matchVimeo && matchVimeo[4])
      videoUrl = 'https://player.vimeo.com/video/' + matchVimeo[4];

    return this.domSanitizer.bypassSecurityTrustResourceUrl(videoUrl);
  }
}
