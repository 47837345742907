<section class="section">
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12">
        <h1 class="title font-weight-bold text-dark text-center">Privacy Policy</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-8 offset-2">
        <p>
          Pitch Platforms Sdn. Bhd. (Company No. 1158464-T) ("pitchIN") is committed to protecting
          the privacy of our platform users with regards to their personal data. pitchIN’s Privacy
          Policy set out below is put together in accordance with the Personal Data Protection Act
          2010 ("Act", "PDPA").
        </p>
        <p>
          Save as otherwise provided, capitalised terms used herein shall have the same meanings
          assigned to them in the pitchIN Terms and Conditions.
        </p>

        <div class="legal-list">
          <ol>
            <li>
              <!-- 1 -->
              <h2>Information collected by pitchIN</h2>

              <ol>
                <!-- 1.1 -->
                <li>
                  <p>
                    The Platforms it operates does not automatically pick up any personal
                    information about individual users. In addition, our servers do not
                    automatically record information regarding a user's e- mail address unless the
                    user supplies it. Like all other websites, our servers may automatically collect
                    internet information, including your device information, log information,
                    browsing history, IP address, cookies and location information. We collect,
                    record, hold, store and/or process personal information when users register with
                    us. Registration is compulsory for anyone wanting to use the portion of our
                    Platforms which is accessible for registered Users. Your registration with us
                    signifies your consent to us processing your Personal Data in accordance with
                    the following terms and conditions.
                  </p>
                </li>

                <!-- 1.2 -->
                <li>
                  <p>
                    Personal information includes name, address, copies and details of
                    identification documents, contact details, gender, details of bank account,
                    employment history, credit and references check, marital status, commission or
                    alleged commission of offence, personal financial and social security detail.
                  </p>
                </li>

                <!-- 1.3 -->
                <li>
                  <p>
                    We may also collect, record, hold, store and/or process your information from
                    other sources, including but not limited to third party background checks, any
                    registered credit reporting agency, any regulatory authorities and any
                    authority, central depository or depository agent.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 2 -->
              <h2>Purpose of Personal Data collected</h2>

              <ol>
                <!-- 2.1 -->
                <li class="alpha-list">
                  <p>
                    You hereby acknowledge, confirm and consent that we may collect, record, hold,
                    store and/or process your personal data for the following purposes:
                  </p>

                  <!-- 2.1 (a) starts -->
                  <ol>
                    <li>
                      <p>
                        to verify your identity as required by the Securities Commission of Malaysia
                        and any applicable law;
                      </p>
                    </li>
                    <li>
                      <p>to send you our services and other investment related information;</p>
                    </li>
                    <li>
                      <p>
                        to understand your needs and preferences, and to provide you with our
                        services;
                      </p>
                    </li>
                    <li>
                      <p>
                        to develop, enhance, market and provide products and services to meet your
                        needs;
                      </p>
                    </li>
                    <li>
                      <p>to enable you to participate in investment and fund raising activities;</p>
                    </li>
                    <li>
                      <p>
                        in the case of investors looking to allocate capital, to confirm that you
                        are eligible to make investments through the website in accordance with
                        relevant legislation;
                      </p>
                    </li>
                    <li>
                      <p>to improve our services;</p>
                    </li>
                    <li>
                      <p>to respond or process your requests or complaints;</p>
                    </li>
                    <li>
                      <p>
                        to comply with any compliance and disclosure requirements of any and all
                        governmental and/or quasi government departments, agencies, regulatory
                        and/or statutory bodies;
                      </p>
                    </li>
                    <li>
                      <p>
                        to comply with any legal obligation binding on us under any law, rule,
                        regulation, by-law, order, guideline, directive, policy and such other
                        requirements in force and as amended from time to time;
                      </p>
                    </li>
                    <li>
                      <p>
                        for the purposes of record-keeping in the ordinary course of our business;
                        and
                      </p>
                    </li>
                    <li>
                      <p>for any purposes relating to the activities on the Platform.</p>
                    </li>
                  </ol>
                </li>

                <!-- 2.2 -->
                <li>
                  <p>
                    You hereby acknowledge that in the event that we do not receive the necessary
                    information from you, or if we are not satisfied with the information provided,
                    your application to become a registered User on the Platform will not be
                    accepted and you will not be able to access or use the portion of the Platform
                    accessible to registered Users.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 3 -->
              <h2>Use and Disclosure</h2>

              <ol>
                <!-- 3.1 -->
                <li>
                  <p>
                    We may disclose your personal data to our trustee, banks, Issuers, agent,
                    contractor, supplier, professional adviser, affiliates and associated companies,
                    any court, for the purpose set out in paragraph 2 above. These associates will
                    treat your personal data as confidential, in accordance with this Privacy Policy
                    and with all applicable data protection principles and will process such
                    personal data only for the purposes and within the terms set out herein.
                  </p>
                </li>

                <!-- 3.2 -->
                <li>
                  <p>
                    We are responsible for the personal data under our control. We take every
                    measure we can to provide a comparable level of protection for personal data
                    passed to our associates in relation to the services we provide. We will also
                    compile, use and disclose aggregated non-personally identifying information
                    collected through the Platform to improve our services and/or business.
                  </p>
                </li>

                <!-- 3.3 -->
                <li>
                  <p>
                    If you give us personal data or information about another person, you confirm
                    that he/she has appointed you to act for him/her, to consent to the processing
                    of his/her personal data and to receive on his/her behalf any data protection
                    notices.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 4 -->
              <h2>Storage and Retention of Personal Data</h2>

              <ol>
                <!-- 4.1 -->
                <li>
                  <p>
                    Your personal data shall be stored either in hard copies in our offices, our
                    associates offices or stored in servers located in Malaysia and operated by us
                    or our service providers. Any personal data supplied by you will be retained by
                    us as long as necessary for the fulfilment of the purposes stated in paragraph 2
                    above or is required to satisfy any legal, regulatory, accounting requirements
                    or to protect our interests.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 5 -->
              <h2>Communication Data</h2>

              <ol>
                <!-- 5.1 -->
                <li>
                  <p>
                    Our Platform will collect communications data such as your IP address and so on.
                    Any personal data that may end up being collected as part of such activities
                    will also be treated as personal data specifically collected by us in the course
                    of providing our services and running the online platform and subject to our
                    privacy protection policies.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 6 -->
              <h2>Automatic Collection of Non-Personal Data</h2>

              <ol>
                <!-- 6.1 -->
                <li>
                  <p>
                    We may automatically collect non-personal data (e.g. type of Internet browser
                    and operating system used, domain name of the website from which you came,
                    number of visits, average time spent on the site, pages viewed). We may use this
                    data to improve our services, website and our online platform, monitor the
                    attractiveness of the Platform and improve their performance or content.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 7 -->
              <h2>Changes of Business Ownership and Control</h2>

              <ol>
                <!-- 7.1 -->
                <li>
                  <p>
                    We may, from time to time, expand, reduce or sell our business, and this may
                    involve the transfer of certain divisions or the whole business to other
                    parties. Your personal data will, where it is relevant to any division so
                    transferred, be transferred along with that division and the new owner or newly
                    controlling party will, under the terms of this Privacy Policy, be permitted to
                    use your personal data for the purposes for which it was supplied by you.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 8 -->
              <h2>Cookies</h2>

              <ol>
                <!-- 8.1 -->
                <li>
                  <p>
                    When you view the Platform, we may store some data on your computer in the form
                    of a "cookie" to automatically recognise your personal computer next time you
                    visit. Cookies will be used for example to help us provide you tailored
                    information that match your investment profile and interests. You can block
                    cookies by configuring your Internet browser. Do note that an integral portion
                    of the services on our platform will be enhanced through cookies.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 9 -->
              <h2>Public Forums</h2>

              <ol>
                <li>
                  <p>
                    The platforms operated by pitchIN enable users to communicate with other users
                    of the platforms. You are advised to exercise caution when deciding to disclose
                    your personal data on such channels.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 10 -->
              <h2>Security</h2>

              <ol>
                <!-- 10.1 -->
                <li>
                  <p>
                    We will strive to protect and keep confidential all personal data collected by
                    us. The Platform has security measures in place to protect personal data and any
                    other sensitive information that may be held by us. For example, we utilise
                    industry standard SSL or SET protocols. Unfortunately however, no data
                    transmission over the internet can be guaranteed as completely secure. We cannot
                    therefore warrant the security of any personal data transmitted to us and
                    individuals do so at their own risk.
                  </p>
                </li>

                <!-- 10.2 -->
                <li>
                  <p>
                    Users are also reminded that they will need to keep their log in details such as
                    usernames and passwords confidential.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 11 -->
              <h2>Links</h2>

              <ol>
                <!-- 11.1 -->
                <li>
                  <p>
                    Our Platforms may contain links to other sites that we believe may be of
                    interest to you. Those sites are fully responsible for the information collected
                    and the services offered by them. In no event can pitchIN be responsible for the
                    information contained on any Linked Site or your use of or inability to use any
                    Linked Site. Access and use of Linked Sites, including the information,
                    material, products, and services on Linked Sites or available through Linked
                    Sites, is solely at your own risk. When you use the Linked Sites, you are
                    governed by the terms of use and privacy policies of such Linked Sites.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 12 -->
              <h2>Data Integrity</h2>

              <ol>
                <!-- 12.1 -->
                <li>
                  <p>
                    You are responsible for ensuring that the data that you provide to us is
                    accurate, complete and not misleading and that such data is kept up-to-date, and
                    you shall promptly keep us informed in the event the data is inaccurate,
                    incomplete, misleading or not up-to-date and you shall provide us the true,
                    accurate, complete, and updated data.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 13 -->
              <h2>Right of Access to Personal Data</h2>

              <ol>
                <!-- 13.1 -->
                <li>
                  <p>
                    The Act gives you the right of access to your personal data held by us on
                    payment of a prescribed fee and to request correction of the personal data that
                    is inaccurate, incomplete, misleading or not up-to-date.
                  </p>
                </li>

                <!-- 13.2 -->
                <li>
                  <p>
                    If you have any questions regarding this Privacy Policy or if you wish to
                    request access to your personal data or if you wish to correct your personal
                    data or if you wish to withdraw your consent to us for the processing of your
                    personal data for the purposes as set out in paragraph 2 above or for the
                    transfer of your personal data to the parties stated in paragraph 3 above, you
                    may send your request in writing to the following address:
                  </p>
                  <div class="card mb-3">
                    <address class="card-body mb-0">
                      PITCHIN,<br />
                      E-12-08, Menara Suezcap 2, KL Gateway,<br />
                      No. 2, Jalan Kerinchi,<br />
                      59200 Kuala Lumpur
                    </address>
                  </div>
                  <p>
                    Or by email to
                    <a href="mailto:cs@pitchin.my">cs&#64;pitchin.my</a>
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 14 -->
              <h2>Severability</h2>

              <ol>
                <!-- 14.1 -->
                <li>
                  <p>
                    We have made every effort to ensure that this Privacy Policy adheres strictly
                    with the relevant provisions of the Act and other applicable laws. However, in
                    the event that any of these provisions are found to be unlawful, invalid or
                    otherwise unenforceable, that provision is to be deemed severed from this
                    Privacy Policy and shall not affect the validity and enforceability of the rest
                    of the Privacy Policy. This clause shall apply only within jurisdictions where a
                    particular term is illegal.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 15 -->
              <h2>No Waiver</h2>

              <ol>
                <!-- 15.1-->
                <li>
                  <p>
                    In the event that either you or we fail to exercise any right or remedy
                    contained in this Privacy Policy, that does not mean you or we (as applicable)
                    have waived that right or remedy and so shall not be construed as a waiver.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 16 -->
              <h2>Changes to Privacy Policy</h2>

              <ol>
                <!-- 16.1 -->
                <li>
                  <p>
                    We reserve the right to amend this Privacy Policy at any time and will ensure
                    that a notice of such amendments will be made available to you through the
                    Platform or through any other mode we view appropriate and suitable.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </div>

        <p>Last updated: 01 January 2023</p>
      </div>
    </div>
  </div>
</section>
