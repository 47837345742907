import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Campaign } from '../../core/models/campaign/campaign';
import { CampaignService } from '../../core/services/api/campaign.service';
import { ErrorService } from '../../core/services/error.service';
import { AuthService } from '../../core/services/auth/auth.service';
import { CampaignStatus } from '../../core/enum/campaign-status.enum';
import { BlockUiService } from '../../shared/services/blockUi/block-ui.service';
import { AccountService } from '../../core/services/api/account.service';
import { BusinessDirectoryService } from '../../core/services/api/business-directory.service';
import { BusinessDirectoryInfo } from '../../core/models/business-directory/business-directory-info';
import { TabItem } from '../../core/models/menu/tab-item';
import { MyInvestment } from '../../core/models/investment/my-investment';
import { Role } from '../../core/enum/role.enum';
import { StorageService } from '../../core/services/storage.service';
import { CampaignInfo } from '../../core/models/campaign/campaign-info';
import { GtmDataLayerService } from 'src/app/core/services/gtm-data-layer.service';
import { PopupService } from './../../shared/services/popup/popup.service';
import { InvestmentService } from '../../core/services/api/investment.service';
import { finalize } from 'rxjs';
import { SettingService } from '../../core/services/api/setting.service';
import { HeaderService } from '../../shared/services/header/header.service';
import { Attachment } from '../../core/models/attachment/attachment';
import { CampaignNews } from '../../core/models/campaign/campaign-news';
import { FinancialOverview } from '../../core/models/campaign/financial-overview';

@Component({
  selector: 'equity-campaign',
  templateUrl: './equity-campaign.component.html',
  styleUrls: ['./equity-campaign.component.scss'],
})
export class EquityCampaignComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('tabContent') tabContent: ElementRef;

  isIssuer: boolean = false;
  isLogin: boolean = false;
  isCampaignEnded: boolean;
  isEligible: boolean = false;
  isInsiderView: boolean = false; // this is reserved for internal staff to view more info by accessing the queryString

  isLoading: boolean = true;
  isInvestNowLoading: boolean = false;
  isMyInvestmentLoading: boolean = true;

  // For Private campaigns
  isPrivateCampaign: boolean;
  isInvitedToInvest: boolean;

  notFoundPage: boolean = false;
  slug: string;
  campaign: Campaign;
  campaignInfo: CampaignInfo;
  businessDirectoryInfo: BusinessDirectoryInfo;
  myInvestment: MyInvestment;

  // For Documents
  isDocumentLoading: boolean = true;
  documents: Attachment[] = [];
  documentsTotal: number;
  documentsParams = {
    currentPage: 1,
    take: 12,
  };

  // For Financial
  isFinancialOverviewsLoading: boolean = true;
  financialOverviews: FinancialOverview[];
  financialOverviewsTotal: number;
  params = {
    currentPage: 0,
    take: 10,
  };

  // For News updates
  isUpdatesLoading = false;
  updatesParams = {
    currentPage: 0,
    take: 10,
  };
  updates: CampaignNews[];
  totalNewsUpdates: number;
  isUpdatesPublished: boolean = true;

  selectedTab: number = 1;
  tabs: TabItem[] = [
    {
      // only when the user has invested on this campaign
      id: 8,
      name: 'campaign.myInvestment',
      show: () => false,
    },
    {
      id: 12,
      name: 'campaign.campaignInfo',
      show: () => this.isCampaignEnded,
    },
    {
      id: 1,
      name: 'campaign.campaignInfo',
      show: () => !this.isCampaignEnded || this.isInsiderView,
    },
    {
      id: 2,
      name: 'campaign.financials&documents',
      show: () => !this.isCampaignEnded || this.isInsiderView,
    },
    {
      id: 3,
      name: 'campaign.updates',
      show: () => true,
    },
    {
      id: 4,
      name: 'campaign.q&a',
      show: () => !this.isCampaignEnded,
    },
    {
      id: 5,
      name: 'campaign.events',
      show: () => !this.isCampaignEnded,
    },
    {
      // (not longer using, remain here incase breaking) merge with `id: 2` tab, only show if the user has invested
      id: 6,
      name: 'campaign.documents&reports',
      show: () => false,
    },
    {
      // when campaign is completed, but no longer necessary after v2 template
      id: 9,
      name: 'campaign.businessInfo',
      show: () => false,
    },
    {
      // when campaign is completed, but no longer necessary after v2 template
      id: 10,
      name: 'campaign.campaignHistory',
      show: () => false,
    },
    {
      id: 11,
      name: 'campaign.investors',
      show: () =>
        this.campaign.campaignStatus !== CampaignStatus.PreLive &&
        this.campaign.campaignStatus !== CampaignStatus.Rejected &&
        !this.isCampaignEnded,
    },
  ];

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private campaignService: CampaignService,
    private activatedRoute: ActivatedRoute,
    private errorService: ErrorService,
    private blockUiService: BlockUiService,
    private businessDirectoryService: BusinessDirectoryService,
    private storageService: StorageService,
    private router: Router,
    private popupService: PopupService,
    private investmentService: InvestmentService,
    private settingService: SettingService,
    private headerService: HeaderService,
    private _gtmDataLayerService: GtmDataLayerService,
  ) {}

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.notFoundPage = false;
  }

  ngOnInit() {
    this.isLogin = this.authService.isAuthenticated();
    this.isIssuer = this.storageService.role == Role.IssuerRep;
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.insiderView === 'true') {
        this.isInsiderView = true;
      }
    });

    // For campaign histories to navigate to same page but different slugs
    this.activatedRoute.paramMap.subscribe((params: any) => {
      this.slug = params.get('slug');

      this.getCampaign();
      this.updateTabStatistics();

      this.isCampaignEnded =
        [CampaignStatus.Success, CampaignStatus.Fail, CampaignStatus.Rejected].includes(
          this.campaign?.campaignStatus,
        ) ?? false;
    });
  }

  ngAfterViewInit() {
    this.settingService.notFoundPage.subscribe(
      (notFoundPage) => (this.notFoundPage = notFoundPage),
    );
  }

  ngOnDestroy() {
    this.settingService.notFoundPage.next(false);
  }

  getTabVisibility(tabId: number) {
    const tab = this.tabs.find((tab) => tab.id === tabId);

    if (tab) {
      return tab.show();
    } else return false;
  }

  getCampaign() {
    this.campaignService
      .getCampaign(this.slug)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.campaign = data;

          if (this.campaign.company) this.setHeaderTag();

          this.isPrivateCampaign = this.campaign?.isPrivate;
          this.isInvitedToInvest = this.campaign?.isPrivate ? this.campaign?.isInvited : true;

          this.isCampaignEnded =
            [CampaignStatus.Success, CampaignStatus.Fail, CampaignStatus.Rejected].includes(
              data?.campaignStatus,
            ) ?? false;

          if (this.isCampaignEnded) this.selectedTab = 12;

          // Get business directory info
          this.getBusinessDirectoryInfo(this.campaign?.company?.slug);

          this.getCampaignInfo();

          this.getTabStatistics();

          this.getMyInvestment();

          this.selectDefaultTab();

          this.getCampaignDocuments();

          this.getInvestorCount();
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }

  getMyInvestment() {
    // If issuer, skip this entirely
    if (![Role.IndividualInvestor, Role.CompanyRep].includes(this.storageService.role)) {
      this.isMyInvestmentLoading = false;
      return;
    }

    this.campaignService
      .getMyInvestment(this.slug)
      .pipe(finalize(() => (this.isMyInvestmentLoading = false)))
      .subscribe({
        next: (data) => {
          this.myInvestment = data;

          if (data.investments && data.investments.length > 0) {
            this.tabs[0].show = () => true;

            // Select the myInvestment tab for campaign ended
            if (this.isCampaignEnded) {
              this.selectedTab = 8;
            }
          }
        },
        error: (err) => console.error('fail to load my investment tab', err),
      });
  }

  getBusinessDirectoryInfo(companySlug: string) {
    if (!companySlug) return;

    this.businessDirectoryService.getBusinessBySlug(companySlug).subscribe({
      next: (data) => {
        this.businessDirectoryInfo = data;
      },
      error: (err) => {
        this.errorService.showError(err);
      },
    });
  }

  getCampaignInfo() {
    this.campaignService.getCampaignInfo(this.campaign.id).subscribe({
      next: (data) => {
        this.campaignInfo = data;
      },
      error: (err) => this.errorService.showError(err),
    });
  }

  getFinancialOverview() {
    if (!this.isLogin) return;

    this.campaignService
      .getFinancialOverview(this.campaign.id, this.params.currentPage, this.params.take)
      .pipe(finalize(() => (this.isFinancialOverviewsLoading = false)))
      .subscribe({
        next: (data) => {
          this.financialOverviews = data.data;
          this.financialOverviewsTotal = data.total;
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }

  getCampaignDocuments() {
    if (this.isCampaignEnded || !this.isLogin) {
      this.isDocumentLoading = false;
      return;
    }

    this.accountService
      .getCampaignDocuments(
        this.campaign.id,
        this.documentsParams.currentPage,
        this.documentsParams.take,
      )
      .pipe(finalize(() => (this.isDocumentLoading = false)))
      .subscribe({
        next: (data) => {
          this.documents = this.documents.concat(data.data);
          this.documentsTotal = data.total;
        },
        error: (err) => console.error('fail to load documents', err),
      });
  }

  triggerNewUpdates(event: any) {
    this.updatesParams = event.params;
    this.isUpdatesPublished = event.isPublished;
    this.getUpdates(event.reload);
  }
  getUpdates(reload?: boolean) {
    if (reload) {
      this.updatesParams.currentPage = 1;
      this.updates = [];
    }
    this.isUpdatesLoading = true;
    this.campaignService
      .getCampaignNews(
        this.campaign.id,
        this.updatesParams.currentPage,
        this.updatesParams.take,
        this.isUpdatesPublished,
      )
      .pipe(finalize(() => (this.isUpdatesLoading = false)))
      .subscribe((data) => {
        this.updates = this.updates.concat(data.data);
        this.totalNewsUpdates = data.total;
        this.getTabStatistics();
      });
  }
  submitUpdates(event: any) {
    this.blockUiService.open();
    let observable = event.id
      ? this.campaignService.updateCampaignNews(this.campaign.id, event.id, event)
      : this.campaignService.createCampaignNews(this.campaign.id, event);
    observable.pipe(finalize(() => this.blockUiService.close())).subscribe((data) => {
      this.popupService.alert('campaign.newsAdded').then(
        () => {
          this.isUpdatesPublished = data.result.isPublished;
          this.getUpdates(true);
          this.getCampaignInfo();
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
    });
  }
  deleteUpdates(id: number) {
    this.popupService.confirm('campaign.confirmDeleteNews').then(
      () => {
        this.blockUiService.open();
        this.campaignService
          .deleteCampaignNews(this.campaign.id, id)
          .pipe(finalize(() => this.blockUiService.close()))
          .subscribe({
            next: () => {
              this.getUpdates(true);
            },
            error: (err) => {
              this.errorService.showError(err);
            },
          });
      },
      () => {},
    );
  }

  handleLoadMoreDocuments() {
    this.isDocumentLoading = true;

    this.documentsParams.currentPage++;
    this.getCampaignDocuments();
  }

  handleSaveCampaign() {
    if (!this.campaign) return;

    this.blockUiService.open();

    this.accountService.saveCampaign(this.campaign.id).subscribe({
      next: () => {
        this.getCampaign();
      },
      error: (err) => {
        this.errorService.showError(err);
      },
      complete: () => {
        this.blockUiService.close();
      },
    });
  }

  handleInvestorCount(count) {
    this.tabs.forEach((tab) => {
      if (tab.id == 11) tab['val'] = count;
    });
  }

  investNow() {
    if (this.isInvestNowLoading) return;

    if (!this.isLogin) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url },
        relativeTo: this.activatedRoute,
      });
      return;
    }

    if (this.isIssuer) {
      this.popupService.alert('common.pleaseLoginAsInvestor');
      return;
    }

    this.isInvestNowLoading = true;
    this.investmentService.getInvestmentEligibility().subscribe({
      next: (data) => {
        this.isInvestNowLoading = false;
        this.isEligible = data;

        if (this.isEligible) {
          this._gtmDataLayerService.logAddToCart({
            items: [
              {
                item_name: this.campaign.company.brandName,
                item_id: this.campaign.id,
                item_category: 'ecf',
              },
            ],
          });

          this.router.navigate(['invest-now'], {
            relativeTo: this.activatedRoute,
          });
        } else {
          this.popupService
            .alert('campaign.notEligibleToInvest', {
              titleLabel: 'campaign.completeProfile',
              okLabel: 'campaign.completeMyProfile',
              cancelLabel: '',
              backdrop: true,
            })
            .then(() => {
              this.accountService.profile(true, true).subscribe({
                error: (err) => this.errorService.showError(err),
              });
            })
            .catch(() => {});
        }
      },
      error: (err) => {
        this.isInvestNowLoading = false;
        this.errorService.showError(err);
      },
    });
  }

  getInvestorCount() {
    this.campaignService
      .getCampaignInvestorCount(this.campaign.id)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.campaign = {
            ...this.campaign,
            investorCount: data.count,
          };

          this.handleInvestorCount(data.count);
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }

  private selectDefaultTab() {
    let selectedTab = this.activatedRoute.snapshot.queryParams.selectedTab
      ? parseInt(this.activatedRoute.snapshot.queryParams.selectedTab)
      : null;

    let findTab = selectedTab
      ? this.tabs.find((tab) => tab.id == selectedTab)
      : this.tabs.find((tab) => tab.id == this.selectedTab);
    this.selectedTab = findTab ? findTab.id : this.tabs[1].id;

    if (this.selectedTab === 3) this.getUpdates(true);

    if (this.selectedTab === 2) this.getFinancialOverview();
  }

  private setHeaderTag() {
    let pageTitle = `Invest in ${this.campaign.company.brandName} | pitchIN`;

    if (this.campaign.campaignStatus === CampaignStatus.Success) {
      pageTitle = `Invest in ${this.campaign.company.brandName} (Successfully funded) | pitchIN`;
    }

    this.logPageView(pageTitle);
    this.logViewItem();
    this.headerService.setHeaderTag(pageTitle);
  }

  private logViewItem() {
    this._gtmDataLayerService.logViewItem({
      items: [
        {
          item_id: this.campaign.id,
          item_name: this.campaign.company.brandName,
          item_category: 'ecf',
        },
      ],
    });
  }

  private logPageView(title: string) {
    this._gtmDataLayerService.logPageView(
      this.router.url,
      title,
      this.getCampaignStatus(this.campaign.campaignStatus),
    );
  }

  private getCampaignStatus(status: CampaignStatus) {
    switch (status) {
      case CampaignStatus.Live:
        return 'Live';

      case CampaignStatus.PreLive:
        return 'Pre-Live';

      case CampaignStatus.Success:
        return 'Completed';
    }
  }

  private handleWhatsNewClick(tabEvent) {
    const { tabIndex, event } = tabEvent;
    this.changeTab(tabIndex, event);
  }

  private changeTab(id: number, event?: PointerEvent) {
    this.selectedTab = id;
    this.tabContent?.nativeElement?.scrollIntoView({
      behavior: 'smooth',
    });

    if (this.selectedTab == 3) this.getUpdates(true);
    if (this.selectedTab == 2) this.getFinancialOverview();
  }

  private getTabStatistics() {
    if (!this.campaign) return;

    this.campaignService.getStatisticCount(this.campaign.id).subscribe({
      next: (data) => this.campaignService.statisticCount.next(data),
      error: (err) => console.error('fail to load tab stats count', err),
    });
  }

  private updateTabStatistics() {
    this.campaignService.statisticCount.subscribe({
      next: (statisticCount) => {
        if (statisticCount) {
          this.tabs.forEach((tab) => {
            if (tab.id == 3) tab['val'] = statisticCount.newsCount;
            else if (tab.id == 4) tab['val'] = statisticCount.questionCount;
            else if (tab.id == 5) tab['val'] = statisticCount.eventCount;
          });
        }
      },
      error: (err) => console.error('fail to update tab stats count', err),
    });
  }
}
