import { Component } from '@angular/core';

@Component({
  selector: 'app-raya-with-pitchin',
  templateUrl: './raya-with-pitchin.component.html',
  styleUrls: ['./raya-with-pitchin.component.scss'],
})
export class RayaWithPitchinComponent {
  constructor() {}
}
