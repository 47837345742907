import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-risk-warning',
  templateUrl: './risk-warning.component.html',
  styleUrls: ['./risk-warning.component.scss'],
})
export class RiskWarningComponent implements AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platform: Object,
  ) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.route.fragment.pipe(first()).subscribe((fragment) => {
        if (fragment) {
          setTimeout(() => {
            document.getElementById(fragment).scrollIntoView();
          }, 200);
        }
      });
    }
  }
}
