import { PopupService } from '../../shared/services/popup/popup.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorService } from './../../core/services/error.service';
import { AccountService } from './../../core/services/api/account.service';
import { BlockUiService } from './../../shared/services/blockUi/block-ui.service';
import { DefaultPasswordPattern } from '../../core/services/constant';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  showFormError: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  form: UntypedFormGroup;
  showPasswordHint: boolean = false;
  atLeast8Characters: boolean = false;
  includesDigit: boolean = false;
  includesUpperAndLowercase: boolean = false;
  includesSymbol: boolean = false;
  email: string;
  token: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private blockUiService: BlockUiService,
    private accountService: AccountService,
    private errorService: ErrorService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private popupService: PopupService,
  ) {}

  ngOnInit() {
    this.email = this.activatedRoute.snapshot.params.email;
    this.token = this.activatedRoute.snapshot.params.token;

    this.form = this.formBuilder.group({
      newPassword: [
        '',
        Validators.compose([Validators.required, Validators.pattern(DefaultPasswordPattern)]),
      ],
      confirmPassword: [
        '',
        Validators.compose([Validators.required, this.checkSamePassword.bind(this)]),
      ],
    });
  }

  submit() {
    if (this.form.invalid) {
      this.showFormError = true;
      return;
    }

    this.blockUiService.open();

    this.accountService
      .resetPassword(this.token, this.email, this.form.value.newPassword)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        () => {
          this.popupService.success('resetPassword.resetSuccess').then(() => {
            this.router.navigate(['/login']);
          });
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  checkSamePassword(control: UntypedFormControl): { [key: string]: boolean } {
    if (!this.form) return;

    const passwordControl = this.form.get('newPassword');

    if (passwordControl.value != control.value) return { notMatch: true };
    else return null;
  }

  onFocus() {
    this.showPasswordHint = true;
  }

  onBlur() {
    this.showPasswordHint = false;
  }

  checkPassword() {
    let newPassword = this.form.value.newPassword;
    newPassword = newPassword.replace(/\s/g, '');
    this.form.controls.newPassword.setValue(newPassword);
    this.atLeast8Characters = newPassword.match(/^.{8,}$/) ? true : false;
    this.includesDigit = newPassword.match(/\d/) ? true : false;
    this.includesUpperAndLowercase = newPassword.match(/^(?=.*[a-z])(?=.*[A-Z]).*$/) ? true : false;
    this.includesSymbol = newPassword.match(/^(?=.*[#$^+=!*()@%&{}\[\].]).*$/) ? true : false;

    if (this.form.value.confirmPassword && newPassword)
      this.form.controls.confirmPassword.setErrors(
        this.checkSamePassword(this.form.controls.confirmPassword as UntypedFormControl),
      );
  }
}
