<div class="tw-flex tw-items-center" [ngClass]="size === 'sm' ? 'tw-gap-2.5' : 'tw-gap-5'">
  <div
    *ngIf="showImage"
    class="p-skeleton-image tw-flex-shrink-0"
    [ngClass]="size === 'sm' ? 'tw-h-10 tw-w-10' : 'tw-h-20 tw-w-20'"
  >
    <img
      class="logo-image tw-flex-shrink-0 !tw-object-cover"
      [ngClass]="
        size === 'sm'
          ? 'tw-h-10 tw-w-10 tw-border tw-border-solid tw-border-divider !tw-shadow-none'
          : ''
      "
      [alt]="name"
      [src]="imageUrl || defaultLogo"
    />
  </div>

  <div class="tw-w-full">
    <!-- Defaults -->
    <!-- Page header -->
    <ng-container *ngIf="size === 'lg'">
      <h1 class="p-skeleton-bar p-text-h2 tw-break-words" [ngClass]="nameClass">
        <span>{{ name }}</span>
        <small
          *ngIf="description"
          class="p-skeleton-bar p-text-p tw-mt-2.5 tw-block"
          [ngClass]="descriptionClass"
        >
          {{ description }}
        </small>
      </h1>
    </ng-container>

    <!-- Normal thumbnail -->
    <ng-container *ngIf="size === 'sm'">
      <div
        *ngIf="name"
        class="p-skeleton-bar company-name tw-line-clamp-1 tw-font-bold tw-text-black"
        [ngClass]="nameClass"
      >
        {{ name }}
      </div>
      <div *ngIf="description">
        <div
          class="p-skeleton-bar tw-line-clamp-1 tw-text-body tw-text-opacity-70"
          [ngClass]="descriptionClass"
        >
          {{ description }}
        </div>
      </div>
    </ng-container>
  </div>
</div>
