import { PopupService } from './../../shared/services/popup/popup.service';
import { StorageService } from './../../core/services/storage.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from './../../core/enum/role.enum';
import { RaiseService } from './../../core/services/api/raise.service';
import { FundRaiseApplication } from './../../core/models/raise/fund-raise-application';
import { ErrorService } from './../../core/services/error.service';
import { AccountService } from '../../core/services/api/account.service';
import { statistics, featuredCompanies } from '../../../assets/static/stats';
import { finalize } from 'rxjs/operators';
import { CompanyStats } from '../../core/models/company/company-stats';
import { howToRaiseFaqLd, ldToList } from '../../../assets/static/json-ld';
import { FaqAccordion } from '../../core/models/learn/faq-accordion';
import { MenuService } from 'src/app/core/services/menu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-raise',
  templateUrl: './raise.component.html',
  styleUrls: ['./raise.component.scss'],
})
export class RaiseComponent implements OnInit {
  fundRaiseApplications: FundRaiseApplication[];
  isIssuer: boolean;
  registerInProgress = false;
  isCheckFlowLoading = false;
  isLoading = false;
  stats = statistics;
  featuredCompanies: CompanyStats = featuredCompanies;

  howToRaiseFaqLd = howToRaiseFaqLd;
  pageFaqs: FaqAccordion[] = ldToList(howToRaiseFaqLd);
  faqList: Array<{ question: string; answer: string }> = [];
  ieoBaseUrl: string = environment.ieoBaseUrl;

  investorLogo = [
    { logo: '../../../assets/img/campaign/spurr.jpg', name: 'Spurr' },
    { logo: '../../../assets/img/campaign/iconstruct.jpg', name: 'Iconstruct' },
    { logo: '../../../assets/img/campaign/coffeeprint.jpg', name: 'Coffeeprint' },
    { logo: '../../../assets/img/campaign/apex.jpg', name: 'Apex' },
    { logo: '../../../assets/img/campaign/alpha_red.jpg', name: 'Alpha-Red' },
    { logo: '../../../assets/img/campaign/govicle.jpg', name: 'Govicle' },
    { logo: '../../../assets/img/campaign/kosumy.jpg', name: 'Kosumy' },
    { logo: '../../../assets/img/campaign/babydash.jpg', name: 'Babydash' },
  ];

  // mainQuestions = [
  //     {
  //         question: "Which businesses can raise money through equity crowdfunding?",
  //         answer: "Private limited companies (Sdn Bhd),  Limited Liability Partnerships (LLPs) and Berhad companies are allowed to seek funds through equity fundraising."
  //     },
  //     {
  //         question: "How much can companies raise?",
  //         answer: "The maximum amount per company that can be raised through equity crowdfunding in the company’s lifetime is RM20 million. (This excludes the company’s own contribution or any funding obtained through a private placement exercise)"
  //     },
  //     {
  //         question: "How long does it take to raise money?",
  //         answer: "Companies have to apply and go through a due diligence process. If accepted for equity crowdfunding, they can launch their campaign that will generally run between 30 to 60 days. If the funds are successfully raised, agreements are executed between all the shareholders and the funds are then transferred over to the company."
  //     }
  // ]

  // steps = [
  //     { stepName: 'applyToRaise', stepDesc: '3 - 7 working days', size: 15 },
  //     { stepName: 'dueDiligenceProcess', stepDesc: '3 - 6 weeks onboarding', size: 20 },
  //     { stepName: 'campaignLaunches', stepDesc: '2 - 3 months campaign is live', size: 40 },
  //     { stepName: 'nominationDistribution', stepDesc: '', size: 25 },
  // ];

  slider = [
    {
      'src': 'logo_rectangle_commerce_dot_asia.png',
      'name': 'commerce-dot-asia',
    },
    {
      'src': 'logo_rectangle_kakitangan.png',
      'name': 'kakitangan',
    },
    {
      'src': 'logo_square_oxwhite.png',
      'name': 'oxwhite',
    },
    {
      'src': 'logo_square_the_lorry.png',
      'name': 'the lorry',
    },
    {
      'src': 'logo_rectangle_signature_market.png',
      'name': 'signature market',
    },
    {
      'src': 'logo_rectangle_fundaztic.png',
      'name': 'fundaztic',
    },
    {
      'src': 'logo_rectangle_policy_street.png',
      'name': 'policy street',
    },
    {
      'src': 'logo_rectangle_slurp.png',
      'name': 'slurp',
    },
  ];

  raiseAdvantages = [
    {
      titleKey: 'largestCrowdfundingPlatform',
      descriptionKey: 'largestCrowdfundingPlatformDesc',
    },
    {
      titleKey: 'activeSupportiveCommunity',
      descriptionKey: 'activeSupportiveCommunityDesc',
    },
    {
      titleKey: 'weAreFellowFounders',
      descriptionKey: 'weAreFellowFoundersDesc',
    },
    {
      titleKey: 'VCFriendly',
      descriptionKey: 'VCFriendlyDesc',
    },
  ];

  comparisonData = [
    {
      category: 'Businesses',
      equity: '&#x2022; Any businesses looking to grow or expand<br>&#x2022; Seed to Pre-IPO',
      token:
        '&#x2022; High tech and Web3 companies<br>&#x2022; Established companies looking to finance a project',
    },
    {
      category: 'Investment offering',
      equity: 'Equity',
      token: 'Digital token (utility, asset-backed or security)',
    },
    {
      category: 'Fundraising timeline',
      equity: '3-7 months',
      token: '3-8 months',
    },
    {
      category: 'Fundraising limit',
      equity: 'RM20 million (lifetime)',
      token: 'RM100 million (annually)',
    },
    {
      category: 'Potential exits for investors',
      equity:
        '&#x2022; pitchIN Secondary Trading Market (PSTX)<br>&#x2022; Initial Public Offering (IPO)',
      token: 'Domestic and foreign Digital Asset Exchange (DAX)<br> <i>Coming soon</i>',
    },
  ];

  sector = [
    {
      title: 'Food & beverage',
      desc: 'Launch new locations and digitise operations. Gain new loyal customers that will advocate for your brand.',
      company: [
        {
          logo: 'logo_square_yins_sourdough.png',
          name: 'Yins sourdough',
        },
        {
          logo: 'logo_rectangle_signature_market.png',
          name: 'Signature Market',
        },
        {
          logo: 'logo_rectangle_hometaste.png',
          name: 'Hometaste',
        },
      ],
    },
    {
      title: 'Consumer & services',
      desc: 'Rapidly scale out marketing and scale up operations. Launch new products and grow a community that’ll love your brand.',
      company: [
        {
          logo: 'logo_rectangle_speedhome.png',
          name: 'SpeedHome',
        },
        {
          logo: 'logo_rectangle_jobbie.png',
          name: 'Jobbie',
        },
        {
          logo: 'logo_babydash.png',
          name: 'Babydash',
        },
      ],
    },
    {
      title: 'Manufacturing',
      desc: 'Unlock growth by investing in new equipment and facilities. Make capital investments to introduce new product lines and improve efficiency.',
      company: [
        {
          logo: 'logo_rectangle_nuvending.png',
          name: 'NU Vending',
        },
        {
          logo: 'logo_rectangle_robopreneur.png',
          name: 'Robopreneur',
        },
        {
          logo: 'logo_rectangle_df_automations.png',
          name: 'DF automation & Robotics',
        },
        {
          logo: 'logo_rectangle_xts.png',
          name: 'XTS Technologies',
        },
      ],
    },
    {
      title: 'Energy & environment',
      desc: 'Go-to-market faster and secure a foothold in a rapidly growing industry. Fuel innovation and scale operations.',
      company: [
        {
          logo: 'logo_rectangle_charge_sini.png',
          name: 'Chargesini',
        },
        {
          logo: 'logo_rectangle_miw.png',
          name: 'Materials in Works',
        },
        {
          logo: 'logo_rectangle_origo.png',
          name: 'Origo Eco',
        },
        {
          logo: 'logo_rectangle_ihandal.png',
          name: 'Ihandal Energy Solutions',
        },
      ],
    },
    {
      title: 'E-commerce & tech',
      desc: 'Grow your customer base by scaling marketing and expanding access to new regions or domains.',
      company: [
        {
          logo: 'logo_rectangle_commerce_dot_asia.png',
          name: 'Commerce.asia',
        },
        {
          logo: 'logo_rectangle_govicle.png',
          name: 'Govicle',
        },
        {
          logo: 'logo_rectangle_slurp.png',
          name: 'Slurp',
        },
        {
          logo: 'logo_rectangle_afa.png',
          name: 'Atheles for Athletes',
        },
      ],
    },
    {
      title: 'Agriculture',
      desc: 'Invest in new technology and research. Scale your operations, secure access to market and deliver a high-quality product.',
      company: [
        {
          logo: 'logo_rectangle_newleaf.png',
          name: 'Newleaf Agro',
        },
        {
          logo: 'logo_rectangle_agroz.png',
          name: 'Agroz Vertical Farm',
        },
        {
          logo: 'logo_rectangle_sfa.png',
          name: 'Smart Farm Agritech',
        },
      ],
    },
    {
      title: 'Health & education',
      desc: 'Open access to new markets by rapidly strengthening human capital investment, scaling marketing, digitising operations and increasing the number of services.',
      company: [
        {
          logo: 'logo_rectangle_goobat.png',
          name: 'Goobat',
        },
        {
          logo: 'logo_rectangle_mttc.png',
          name: 'MTTC',
        },
        {
          logo: 'logo_rectangle_eduk8u.png',
          name: 'eduk8u',
        },
      ],
    },
  ];

  funded = [
    {
      title: 'Early stage growth',
      desc: 'Bootstrapped through crowdfunding, then scaled with institutional capital. Leverage the power of crowdfunding to validate the product and attract institutional attention.',
      company: [
        {
          logo: 'logo_rectangle_policy_street.png',
          name: 'policystreet',
        },
        {
          logo: 'logo_rectangle_kakitangan.png',
          name: 'Kakitangan',
        },
        {
          logo: 'logo_rectangle_lapasar.png',
          name: 'Lapasar',
          mobileHide: true,
        },
      ],
    },
    {
      title: 'Previously fundraised',
      desc: 'Shorten the timeline to raise your next round while retaining greater control by leveraging your initial success. Tap into your existing customer base and amplify your fundraising efforts via crowdfunding.',
      company: [
        {
          logo: 'logo_rectangle_thelorry.png',
          name: 'The Lorry',
        },
        {
          logo: 'logo_rectangle_recommendgroup.jpeg',
          name: 'Recommend Group',
        },
        {
          logo: 'logo_rectangle_moneymatch.png',
          name: 'Money Match',
        },
      ],
    },
    {
      title: 'Small & medium enterprise (SME)',
      desc: 'Fuel market expansion and reach sustainable growth quicker. Raise capital from a crowd of future loyal customers, scale out marketing and invest in operational efficiency.',
      company: [
        {
          logo: 'logo_rectangle_globalpsytech.png',
          name: 'Global Psytech',
        },
        {
          logo: 'logo_rectangle_regov.png',
          name: 'Regov',
        },
        {
          logo: 'logo_rectangle_oxwhite.png',
          name: 'Oxwhite',
        },
      ],
    },
  ];

  constructor(
    private storageService: StorageService,
    private router: Router,
    private popupService: PopupService,
    private activatedRoute: ActivatedRoute,
    private raiseService: RaiseService,
    private errorService: ErrorService,
    private accountService: AccountService,
    private menuService: MenuService,
  ) {}

  ngOnInit() {
    this.isIssuer = this.storageService.role == Role.IssuerRep;

    if (this.isIssuer) {
      this.checkFlow();
    }
  }

  getApplications() {
    this.isLoading = true;

    this.raiseService
      .getRaiseFundApplications()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.fundRaiseApplications = data;
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }

  applyRaise() {
    if (this.isIssuer) {
      if (!this.registerInProgress)
        this.router.navigate(['raise-fund-form'], { relativeTo: this.activatedRoute });
      else
        this.popupService.alert('campaign.issuerCompleteProfile').then(() => {
          this.router.navigate(['/sign-up', 'issuer-rep']);
        });
    } else {
      this.popupService.alert('raise.signUpToContinue');
    }
  }

  checkFlow() {
    this.isCheckFlowLoading = true;

    this.accountService
      .getSignUpFlowStatus()
      .pipe(finalize(() => (this.isCheckFlowLoading = false)))
      .subscribe({
        next: (flowData) => {
          this.registerInProgress = flowData && !flowData.isCompleted;

          if (!this.registerInProgress) this.getApplications();
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }
  loadFaq() {
    this.faqList = howToRaiseFaqLd.mainEntity.map((item) => {
      return {
        question: item.name,
        answer: item.acceptedAnswer.text,
      };
    });
  }
}
