import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { finalize } from 'rxjs/operators';
import { Business } from '../../../../../core/models/mysec/business';
import { BusinessesService } from '../../../../../core/services/api/mysec/businesses.service';
import { DefaultLogo } from '../../../../../core/services/constant';
import { ErrorService } from '../../../../../core/services/error.service';
import { BusinessesFilter } from '../../../businesses/businesses-filter';
import { ShareMarketStatus } from '../../../../../core/enum/mysec/share-market-status.enum';
import { ShareStatus } from '../../../../../core/enum/mysec/share-status.enum';

@Component({
  selector: 'app-ticker-code',
  templateUrl: './ticker-code.component.html',
  styleUrls: ['./ticker-code.component.scss'],
})
export class TickerCodeComponent implements OnInit {
  displayColumns: string[] = [
    'Code',
    'DirectShare',
    'NomineeShare',
    'LastTrade',
    'BestBid',
    'BestAsk',
    'OrderQty',
    'PriceChg',
  ];
  total: number;
  isLoading = false;
  params = {
    ...new BusinessesFilter(),
    // Notes: set this `take` to bigger value to `disable` pagination
    take: 500,
  };
  businessesList: Business[];
  defaultLogo = DefaultLogo;

  totalShares: number;
  totalNomineeShares: number;
  nomineeSharesPercentage: string;

  ShareMarketStatus: typeof ShareMarketStatus = ShareMarketStatus;
  ShareStatus: typeof ShareStatus = ShareStatus;

  constructor(
    private errorService: ErrorService,
    private businessesService: BusinessesService,
  ) {}

  ngOnInit() {
    this.getTickerCodeList();
  }

  getTickerCodeList(reload?: boolean) {
    if (reload) {
      this.params.currentPage = 1;
    }

    this.isLoading = true;

    this.businessesService
      .getTickerCodeList(this.params)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.businessesList = data.data;
          this.total = data.total;
          this.totalShares = data?.totalShares;
          this.totalNomineeShares = data?.totalNomineeShares;
          this.nomineeSharesPercentage = (
            (data.totalNomineeShares / data.totalShares) *
            100
          ).toFixed(2);
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }

  changePage(event?: PageEvent) {
    this.getTickerCodeList();
  }

  checkItemPrice(item: Business): string {
    if (!item.priceChg || item.priceChg == 0) return 'flat';
    else if (item.priceChg > 0) return 'increase';
    else return 'decrease';
  }

  absValue(item): number {
    if (item < 0) {
      return Math.abs(item);
    }
    return item;
  }
}
