import { CheckboxQuestion } from './checkbox-question';
import { CurrencyQuestion } from './currency-question';
import { DatepickerQuestion } from './datepicker-question';
import { DescriptionQuestion } from './description-question';
import { DropdownQuestion } from './dropdown-question';
import { FormPanelModel } from './form-panel-model';
import { PercentQuestion } from './percent-question';
import { QuestionBase } from './question-base';
import { RadioBooleanQuestion } from './radio-boolean-question';
import { SelectionListQuestion } from './selection-list-question';
import { TextareaQuestion } from './textarea-question';
import { TextboxQuestion } from './textbox-question';
import { TitleQuestion } from './title-question';

export class FormPanel {
  items: QuestionBase<any>[];

  constructor(model: FormPanelModel) {
    this.items = [];

    if (model && model.items && model.items.length > 0) {
      model.items.forEach((item) => {
        switch (item.controlType) {
          case 'currency':
            this.items.push(new CurrencyQuestion(item));
            break;
          case 'radio-boolean':
            this.items.push(new RadioBooleanQuestion(item));
            break;
          case 'selection-list':
            this.items.push(new SelectionListQuestion(item));
            break;
          case 'textarea':
            this.items.push(new TextareaQuestion(item));
            break;
          case 'percentage':
            this.items.push(new PercentQuestion(item));
            break;
          case 'textbox':
            this.items.push(new TextboxQuestion(item));
            break;
          case 'dropdown':
            this.items.push(new DropdownQuestion(item));
            break;
          case 'datepicker':
            this.items.push(new DatepickerQuestion(item));
            break;
          case 'title':
            this.items.push(new TitleQuestion(item));
            break;
          case 'description':
            this.items.push(new DescriptionQuestion(item));
            break;
          case 'checkbox':
            this.items.push(new CheckboxQuestion(item));
            break;
        }
      });
    }
  }
}
