import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DefaultLogo } from '../../../../../core/services/constant';

@Component({
  selector: 'app-confirm-invest',
  templateUrl: './confirm-invest.component.html',
  styleUrls: ['./confirm-invest.component.scss'],
})
export class ConfirmInvestComponent {
  campaignImage: string;
  campaignTitle: string;
  investmentAmount: number;
  lotAmount: number;

  defaultLogo = DefaultLogo;

  constructor(public activeModal: NgbActiveModal) {}

  confirm() {
    this.activeModal.close();
  }

  cancel() {
    this.activeModal.dismiss();
  }
}
