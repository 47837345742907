import * as moment from 'moment';
import { OperatingHour } from '../market/operating-hour';
import { OrderEntrySession } from './order-entry-session';
import { TradingSessionModel } from './trading-session.model';
import { TradingFee } from './trading-fee';
import { PublicHoliday } from '../market/public-holiday';
import { OrderBookReset } from './order-book-reset';

export class TradingSession {
  tradingSessions: OperatingHour[];
  publicHolidays: string[];
  publicHolidaysList: PublicHoliday[];
  orderEntrySession: OrderEntrySession;
  orderBookReset: OrderBookReset;
  tradingFees: TradingFee[];
  currentPublicHolidaysYear: number;

  constructor(model: TradingSessionModel) {
    this.tradingSessions = model.tradingSessions;
    this.publicHolidays = model.publicHolidays
      ? model.publicHolidays.map((holiday) =>
          moment(moment.utc(holiday.date).toDate()).format('M/D/YYYY'),
        )
      : null;

    this.publicHolidaysList = model.publicHolidays
      ? model.publicHolidays.map((holiday) => {
          return {
            date: moment(moment.utc(holiday.date).toDate()).format('M/D/YYYY'),
            id: holiday.id,
            name: holiday.name,
          };
        })
      : null;

    this.orderEntrySession = model.orderEntrySession;
    this.orderBookReset = model.orderBookReset;
    this.tradingFees = model.tradingFees;
  }
}
