<div>
  <div class="tw-mx-auto tw-max-w-2xl lg:tw-grid lg:tw-max-w-none lg:tw-grid-cols-2 lg:tw-gap-5">
    <div class="tw-flex tw-flex-col tw-gap-[10px]">
      <div
        *ngFor="let step of tutorialSteps.steps; let i = index"
        (click)="setIndex(i)"
        [class.active]="selectedIndex === i"
        [class.active-border]="selectedIndex === i"
        class="list--reset tw-group tw-flex tw-rounded-md tw-border tw-border-gray-200 tw-p-2 tw-text-gray-400 hover:tw-cursor-pointer hover:tw-border-black hover:tw-text-black hover:tw-shadow-lg"
      >
        <div class="tw-pr-[8px]">
          <span
            class="tw-inline-block tw-h-5 tw-w-5 tw-rounded-full tw-bg-gray-400 tw-text-center tw-leading-5 tw-text-white group-hover:tw-bg-black"
            >{{ i + 1 }}</span
          >
        </div>
        <div [innerHTML]="step.description | trustSrc"></div>
      </div>
    </div>
    <div>
      <app-inner-tab-content
        [content]="tutorialSteps.steps[selectedIndex].content"
      ></app-inner-tab-content>
    </div>
  </div>
</div>
