<div class="tw-relative tw-w-full">
  <input
    #input
    class="form-control tw-pl-12"
    placeholder="{{ placeholder | translate }}"
    [ngModel]="inputValue"
    (keydown.enter)="submit()"
    name="search"
    required
  />
  <div
    class="pitchin-icon pitchin-icon-search tw-absolute tw-left-3 tw-top-3"
    (click)="submit()"
  ></div>
</div>
