import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-json-ld',
  templateUrl: './json-ld.component.html',
  styleUrls: [],
})
export class JsonLdComponent implements OnInit {
  @Input() itemLd: { [key: string]: any };
  html: SafeHtml;

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.html = this.getSafeHTML(this.itemLd);
  }

  getSafeHTML(jsonLD: { [key: string]: any }): SafeHtml {
    const json = jsonLD
      ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>')
      : ''; // escape / to prevent script tag in JSON
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }
}
