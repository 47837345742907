import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'p-icon',
  templateUrl: './icon.component.html',
})
export class IconComponent implements OnInit {
  @Input() iconClass: string;
  @Input() name: string;
  @Input() size: string; // for large, use `lg`

  iconSize: string;

  ngOnInit() {
    this.iconSize =
      this.size == 'lg' ? 'tw-w-[2.5rem] tw-h-[2.5rem]' : 'tw-w-[1.5rem] tw-h-[1.5rem]';
  }
}
