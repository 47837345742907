<main class="container-fluid tw-py-12">
  <a
    class="tw-flex tw-items-center tw-gap-4 tw-bg-transparent"
    [routerLink]="['/profile/settings/security']"
  >
    <i class="fa fa-arrow-left" aria-hidden="true"></i>
    <p class="tw-m-0 tw-font-semibold tw-text-black hover:tw-underline">
      {{ 'common.back' | translate }}
    </p>
  </a>

  <section class="tw-flex tw-flex-col tw-items-center tw-text-black">
    <img
      src="../../../../../assets/img/icons/common/shield-check-red.png"
      alt="check mark"
      class="tw-w-20"
    />
    <h1 class="tw-mb-0 tw-text-center tw-font-heading tw-text-3xl tw-font-bold">
      {{ 'twoFactor.enableTwoFactor' | translate }}
    </h1>
    <p class="tw-text-center tw-text-black">
      {{ 'twoFactor.enableTwoFactorDesc' | translate }}
    </p>

    <div class="tw-flex tw-flex-col tw-py-4 xl:tw-grid xl:tw-grid-cols-3">
      <article
        class="tw-flex tw-flex-col tw-items-center tw-border-b tw-border-primary tw-px-12 tw-py-12 xl:tw-border-b-0 xl:tw-border-r xl:tw-py-0"
      >
        <h2 class="tw-text-xl">{{ 'common.step' | translate: { stepNo: 1 } }}</h2>
        <h3 class="tw-text-center tw-text-xl tw-font-semibold tw-text-primary">
          {{ 'twoFactor.dowloadApp' | translate }}
        </h3>
        <p class="tw-text-center tw-text-black">
          {{ 'twoFactor.dowloadAppDesc' | translate }}
        </p>
        <ul
          class="tw-flex tw-w-full tw-list-none tw-flex-col tw-gap-2 tw-self-start tw-p-0 tw-text-black"
        >
          <li class="tw-flex tw-gap-4">
            <img
              src="../../../../../assets/img/logo/google-2fa.png"
              alt="Google Authenticator"
              class="tw-h-auto tw-min-w-6"
            />
            <p class="tw-m-0">{{ 'twoFactor.google' | translate }}</p>
          </li>
          <li class="tw-flex tw-gap-4">
            <img
              src="../../../../../assets/img/logo/authenticator-2fa.png"
              alt="2FAS"
              class="tw-h-auto tw-min-w-6"
            />
            <p class="tw-m-0">{{ 'twoFactor.2faApp' | translate }}</p>
          </li>
          <li class="tw-flex tw-gap-4">
            <img
              src="../../../../../assets/img/logo/microsoft-2fa.png"
              alt="Microsoft Authenticator"
              class="tw-h-auto tw-min-w-6"
            />
            <p class="tw-m-0">{{ 'twoFactor.microsoft' | translate }}</p>
          </li>
          <li class="tw-flex tw-gap-4">
            <img
              src="../../../../../assets/img/logo/authy-2fa.png"
              alt="Authy Authenticator"
              class="tw-h-auto tw-min-w-6"
            />
            <p class="tw-m-0">{{ 'twoFactor.authy' | translate }}</p>
          </li>
        </ul>
      </article>
      <article
        class="tw-flex tw-flex-col tw-items-center tw-border-b tw-border-primary tw-px-12 tw-py-12 xl:tw-border-b-0 xl:tw-border-r xl:tw-py-0"
      >
        <h2 class="tw-text-xl">{{ 'common.step' | translate: { stepNo: 2 } }}</h2>
        <h3 class="tw-text-center tw-text-xl tw-font-semibold tw-text-primary">
          {{ 'twoFactor.scanrQr' | translate }}
        </h3>
        <p class="tw-text-center">
          {{ 'twoFactor.qrCodeDesc' | translate }}
        </p>
        <img [src]="QrCodeSrc" alt="QR Code" />
        <div
          class="tw-flex tw-items-center tw-gap-2 tw-rounded tw-border tw-border-primary tw-px-4 tw-py-2"
        >
          <p class="tw-m-0 tw-text-sm tw-text-primary">{{ secretKey }}</p>
          <button (click)="handleCopySecretKey()" class="tw-cursor-pointer tw-bg-transparent">
            <img
              src="../../../../../assets/img/icons/common/clipboard_icon_red@2x.png"
              alt="copy"
              class="tw-w-6"
            />
          </button>
        </div>
      </article>
      <article class="tw-flex tw-flex-col tw-items-center tw-px-12 tw-py-12 xl:tw-py-0">
        <h2 class="tw-text-xl">{{ 'common.step' | translate: { stepNo: 3 } }}</h2>
        <h3 class="tw-text-center tw-text-xl tw-font-semibold tw-text-primary">
          {{ 'twoFactor.verifyAuthApp' | translate }}
        </h3>
        <p class="tw-text-center">{{ 'twoFactor.enterSixDigit' | translate }}</p>
        <form
          (ngSubmit)="onSubmit()"
          [formGroup]="form"
          class="tw-flex tw-flex-col tw-gap-4"
          [class.show-form-error]="showFormError"
        >
          <input
            type="text"
            formControlName="otp"
            class="tw-rounded tw-border tw-border-gray-500 tw-px-4 tw-py-2"
            placeholder="Enter security code"
          />

          <button
            class="tw-w-full tw-cursor-pointer tw-rounded tw-bg-primary tw-py-2 tw-font-semibold tw-text-white"
            type="submit"
          >
            {{ 'common.submit' | translate }}
          </button>
        </form>
      </article>
    </div>
  </section>
</main>
