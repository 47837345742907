import { ShareMarketStatus } from '../../enum/mysec/share-market-status.enum';
import { OrderSummaryModel } from './order-summary.model';

export class OrderSummary {
  id: number;
  mySecOrderId: number;
  tickerCode: string;
  tickerStatus: ShareMarketStatus;
  orderDetail: OrderSummaryOrderDetail[];
  tradingFee: number;
  totalAmount: number;
  createdAt: string;

  constructor(model: OrderSummaryModel) {
    this.id = model.id;
    this.mySecOrderId = model.mySecOrderId;
    this.tickerCode = model.tickerCode;
    this.tickerStatus = model.tickerStatus;
    this.orderDetail = model.orderDetail;
    this.tradingFee = model.tradingFee;
    this.totalAmount = model.totalAmount;
    this.createdAt = model.createdAt;
  }
}

export class OrderSummaryOrderDetail {
  quantity: number;
  pricePerShare: number;
  amount: number;
}
