<div class="container-fluid">
  <ng-container *ngIf="step == 1">
    <div class="row justify-content-center mt-5 mb-5">
      <div class="col-md-10 col-lg-8">
        <h3 class="font-weight-extrabold text-center text-black">
          {{ 'raise.fundRaisePayment' | translate }}
        </h3>
        <div class="text-center">{{ 'raise.oneTimeFeeNeeded' | translate }}</div>
        <div class="fee-amount-panel">
          <div class="text-black w-50">{{ 'raise.feeAmount' | translate }}</div>
          <div class="text-primary font-size-large font-weight-extrabold w-50 text-right">
            {{ onboardingFees | numberSuffix }}
          </div>
        </div>
      </div>
    </div>
    <app-payment-process
      [isLoading]="isLoading"
      [receiptType]="receiptType"
      [paymentAmount]="onboardingFees"
      [document]="document"
      [referenceNo]="fundRaiseApplicationId"
      [continueLink]="'/raise/my-application'"
      (choosePayment)="setInvestmentPayment($event)"
      (nextStep)="nextStep($event)"
      (uploadReceipt)="uploadReceipt($event)"
    ></app-payment-process>
  </ng-container>
  <app-fpx-payment
    *ngIf="step == 2"
    [paymentAmount]="onboardingFees"
    (choosePayment)="setInvestmentPayment($event)"
  >
  </app-fpx-payment>
</div>
