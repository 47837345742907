import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PagedResult } from '../../../shared/models/paged-result/paged-result';
import { IncomingNotification } from '../../models/notification/incoming-notification';
import { AccountService } from './account.service';

@Injectable()
export class NotificationsService {
  private route = environment.apiServer + 'Api/v1/notifications/{userId}/{role}';

  constructor(
    private http: HttpClient,
    private accountService: AccountService,
  ) {}

  getNotifications(take: number, skip: number): Observable<IncomingNotification[]> {
    const params = {
      skip: skip.toString(),
      take: take.toString(),
    };

    return this.http.get(this.route, { params: params }).pipe(
      map((data: any) => {
        this.accountService.notifications.next(data.result);
        return data.result;
      }),
    );
  }

  deleteNotification(id: number): Observable<any> {
    return this.http.delete(this.route + '/' + id);
  }

  markNotificationIsRead(id: number): Observable<any> {
    return this.http.get(this.route + '/' + id + '/Read');
  }

  markAllNotificationIsRead(): Observable<any> {
    return this.http.get(this.route + '/ReadAll');
  }
}
