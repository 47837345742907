<section class="section section-shaped">
  <div class="container">
    <div class="text-primary text-underline cursor-pointer back-button h5" (click)="back()">
      {{ 'common.back' | translate }}
    </div>
    <div class="title text-center">{{ 'campaign.campaignDetails' | translate }}</div>
    <app-campaign-info [campaignInfo]="campaignInfo" [tabs]="campaignMenus" *ngIf="!isLoading">
    </app-campaign-info>
    <agmo-loading *ngIf="isLoading"></agmo-loading>
  </div>
</section>
