import { Role } from '../../../core/enum/role.enum';
import { StorageService } from './../../../core/services/storage.service';
import { CompanyService } from './../../../core/services/api/company.service';
import { PopupService } from './../../../shared/services/popup/popup.service';
import { ErrorService } from './../../../core/services/error.service';
import { AccountService } from './../../../core/services/api/account.service';
import { BlockUiService } from './../../../shared/services/blockUi/block-ui.service';
import { Attachment } from './../../../core/models/attachment/attachment';
import { Representative } from '../../../core/models/user/representative';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CompanyModel } from '../../../core/models/company/company.model';
import { isPlatformBrowser } from '@angular/common';
import { CompanyLiteModel } from '../../../core/models/company/company-lite.model';

@Component({
  selector: 'app-register-issuer',
  templateUrl: './register-issuer.component.html',
  styleUrls: ['./register-issuer.component.scss'],
})
export class RegisterIssuerComponent implements OnInit {
  step: number = 1;
  attachments: Attachment[] = [];

  company: CompanyLiteModel;

  Role: typeof Role = Role;

  constructor(
    private router: Router,
    private blockUiService: BlockUiService,
    private accountService: AccountService,
    private errorService: ErrorService,
    private popupService: PopupService,
    private companyService: CompanyService,
    private storageService: StorageService,
    @Inject(PLATFORM_ID) private platform: Object,
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      this.checkSignUpFlow();
    }
  }

  checkSignUpFlow() {
    this.accountService
      .getSignUpFlowStatus()
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        (flowData) => {
          if (flowData) {
            if (!flowData.isCompleted) {
              this.step = flowData.completedSignUpFlowStage + 1;
              this.getIssuerBasicInfo();

              if (this.step == 3) this.getCompanyDocuments();
            } else this.router.navigate(['/']);
          } else {
            this.popupService.alert('common.userNotExists').then(() => {
              this.router.navigate(['/profile/settings/profile-details']);
            });
          }
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  getIssuerBasicInfo() {
    this.accountService.profile(true).subscribe((data) => {
      this.company = {
        name: data.companyName,
        registrationNumber: data.registrationNumber,
        companyId: data.companyId,
        brandName: data.brandName,
        companyType: data.companyType,
      };
    });
  }

  submitCompany(company: CompanyModel) {
    this.blockUiService.open();

    this.accountService
      .registerIssuerCompany(company)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        () => {
          this.step++;
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  registerRep(event: Representative) {
    this.blockUiService.open();

    this.accountService
      .registerIssuerRep(event)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        () => {
          // this.step++;
          // this.getCompanyDocuments();
          this.storageService.role = Role.IssuerRep;
          this.router.navigate(['/sign-up/proceed-e-kyc']);
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  submit() {
    this.blockUiService.open();

    this.accountService
      .registerIssuerDocuments()
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        (data) => {
          if (!!data.result) {
            this.storageService.role = Role.IssuerRep;
            this.router.navigate(['/sign-up/proceed-e-kyc']);
          } else this.popupService.alert('company.pleaseUploadDocuments');
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  uploadCompanyDocument(document: Attachment) {
    this.companyService.uploadCompanyAttachment(document).subscribe(
      () => {
        this.getCompanyDocuments();
      },
      (err) => {
        this.errorService.showError(err);
        document.file = null;
      },
    );
  }

  deleteCompanyDocument(id: number) {
    this.companyService.deleteCompanyAttachment(id).subscribe(
      () => {
        this.getCompanyDocuments();
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  getCompanyDocuments() {
    this.blockUiService.open();

    this.companyService
      .getCompanyAttachments()
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        (data) => {
          this.attachments = data;
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  uploadDocument(document: Attachment) {
    this.blockUiService.open();

    this.accountService.uploadDocument(document).subscribe(
      () => {
        this.getCompanyDocuments();
      },
      (err) => {
        this.blockUiService.close();
        this.errorService.showError(err);
        document.file = null;
      },
    );
  }
}
