import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PopupService } from '../../../shared/services/popup/popup.service';
import { PopupOptions } from '../../../shared/services/popup/popup-options';
import { ActivatedRoute, Router } from '@angular/router';
import { FormTemplate } from '../../../shared/models/question/form-template';
import { RaiseService } from '../../../core/services/api/raise.service';
import { ErrorService } from '../../../core/services/error.service';
import { BlockUiService } from '../../../shared/services/blockUi/block-ui.service';
import { finalize } from 'rxjs/operators';
import { FormType } from '../../../core/enum/form-type.enum';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { MyApplicationFilterService } from '../my-application/my-application-filter.service';
import { MyApplicationFilter } from '../my-application/my-application-filter';

@Component({
  selector: 'app-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.scss'],
})
export class ApplicationFormComponent implements OnInit {
  formType: FormType;
  form: FormTemplate;
  applicationForm$: Observable<FormTemplate>;
  submissionId: number;
  stepId: number;
  fundRaiseApplicationId: number;
  submitText: string;

  FormType: typeof FormType = FormType;

  constructor(
    private popupService: PopupService,
    private router: Router,
    private raiseService: RaiseService,
    private errorService: ErrorService,
    private blockUiService: BlockUiService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private myApplicationFilterService: MyApplicationFilterService,
  ) {}

  ngOnInit() {
    this.formType = this.activatedRoute.snapshot.data.type;
    this.submitText = this.formType == FormType.Fixed ? 'raise.submitApplication' : 'common.submit';

    if (this.formType != FormType.Fixed) {
      this.fundRaiseApplicationId = this.activatedRoute.snapshot.params.fundRaiseApplicationId;
      this.stepId = this.activatedRoute.snapshot.params.stepId;
    }

    if (this.formType == FormType.Submission || this.formType == FormType.Rejected) {
      this.submissionId = this.activatedRoute.snapshot.params.submissionId;
    }

    this.getForm();
  }

  getForm() {
    let observable =
      this.formType == FormType.Fixed
        ? this.raiseService.getRaiseFundForm()
        : this.formType == FormType.Submission || this.formType == FormType.Rejected
          ? this.raiseService.getSubmisionForm(
              this.fundRaiseApplicationId,
              this.stepId,
              this.submissionId,
            )
          : this.raiseService.getDynamicForm(this.fundRaiseApplicationId, this.stepId);

    observable.subscribe(
      (data) => {
        let form = data;

        if (form.url) {
          this.raiseService.getApplicationForm(form.url).subscribe(
            (form) => {
              this.form = form;

              this.form.formPanels.forEach((formPanel) => {
                if (formPanel.items && formPanel.items.length > 0) {
                  formPanel.items.forEach((item) => {
                    if (this.formType == FormType.Submission) {
                      item.readonly = true;
                    }

                    if (
                      this.formType == FormType.Rejected &&
                      item.controlType == 'datepicker' &&
                      item.isToday
                    ) {
                      item.value = null;
                    }
                  });
                }
              });

              if (this.formType != FormType.Fixed) {
                this.checkCommonKeys();
              } else {
                this.applicationForm$ = new Observable((observer) => {
                  observer.next(this.form);
                  observer.complete();
                });
              }
            },
            (err) => {
              this.errorService.showError(err);
              this.back();
            },
          );
        }
      },
      (err) => {
        this.errorService.showError(err);
        this.back();
      },
    );
  }

  checkCommonKeys() {
    this.raiseService.getCommonKeys(this.fundRaiseApplicationId).subscribe(
      (keys) => {
        let commonkeys = keys && keys.values && keys.values.length > 0 ? keys.values : [];

        commonkeys.forEach((key) => {
          this.form.title = this.form.title
            ? this.form.title.replace('{' + key.dynamicFormKey + '}', key.value)
            : null;

          this.form.description = this.form.description
            ? this.form.description.replace('{' + key.dynamicFormKey + '}', key.value)
            : null;

          if (this.form.formPanels && this.form.formPanels.length > 0) {
            this.form.formPanels.forEach((formPanel) => {
              if (formPanel.items && formPanel.items.length > 0) {
                formPanel.items.forEach((item) => {
                  item.label = item.label
                    ? item.label.replace('{' + key.dynamicFormKey + '}', key.value)
                    : null;

                  item.description = item.description
                    ? item.description.replace('{' + key.dynamicFormKey + '}', key.value)
                    : null;

                  if (item.type === 'string') {
                    item.value = item.value
                      ? item.value.replace('{' + key.dynamicFormKey + '}', key.value)
                      : null;
                  }

                  if (item.key === key.dynamicFormKey) {
                    item.value = key.value;
                    item.readonly = true;
                  }
                });
              }
            });
          }
        });

        this.applicationForm$ = new Observable((observer) => {
          observer.next(this.form);
          observer.complete();
        });
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  submit(event: any) {
    this.form.formPanels.forEach((formPanel) => {
      if (formPanel.items && formPanel.items.length > 0) {
        formPanel.items.forEach((item) => {
          if (item.key && item.key in event) {
            if (event[item.key] != null) {
              if (item.controlType === 'datepicker') {
                item.value = moment(event[item.key]).toISOString();
              } else {
                item.value =
                  item.type === 'array'
                    ? JSON.stringify(event[item.key])
                    : event[item.key].toString();
              }
            } else {
              item.value = null;
            }
          }
        });
      }
    });

    let message =
      this.formType == FormType.Fixed
        ? 'raise.confirmApplicationMessage'
        : 'raise.confirmSubmitFormMessage';
    let titleLabel =
      this.formType == FormType.Fixed ? 'raise.confirmApplication' : 'common.confirmation';

    this.popupService
      .confirm(message, {
        titleLabel: titleLabel,
        okLabel: 'raise.yesSubmit',
        cancelLabel: 'raise.noBackToEdit',
      } as PopupOptions)
      .then(
        () => {
          this.blockUiService.open();

          let observable =
            this.formType == FormType.Fixed
              ? this.raiseService.submitApplicationForm(this.form)
              : this.raiseService.submitDynamicForm(
                  this.fundRaiseApplicationId,
                  this.stepId,
                  this.form,
                );

          observable.pipe(finalize(() => this.blockUiService.close())).subscribe(
            () => {
              this.popupService.success('raise.successSubmitApplication').then(() => {
                if (this.formType == FormType.Fixed) {
                  this.myApplicationFilterService.filter = new MyApplicationFilter();
                  this.router.navigate(['/raise/my-application']);
                } else {
                  this.back();
                }
              });
            },
            (err) => {
              this.errorService.showError(err);
            },
          );
        },
        () => {},
      );
  }

  confirmBack() {
    if (this.formType == FormType.Fixed || this.formType == FormType.Dynamic) {
      this.popupService.confirm('raise.confirmBack').then(
        () => {
          this.back();
        },
        () => {},
      );
    } else {
      this.back();
    }
  }

  back() {
    this.location.back();
  }

  print() {
    window.print();
  }
}
