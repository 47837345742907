<div class="row mb-5" *ngIf="!isLoading && financialOverviews">
  <div class="col-12">
    <div class="p-table__wrapper">
      <div class="p-table__body">
        <mat-table class="p-table" [dataSource]="financialOverviews">
          <ng-container matColumnDef="Year">
            <mat-header-cell *matHeaderCellDef>{{ 'campaign.year' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let item">
              {{ item.year }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Period" *ngIf="isPSTX">
            <mat-header-cell *matHeaderCellDef>{{ 'campaign.period' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let item">
              {{ item.period || '-' }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Revenue">
            <mat-header-cell *matHeaderCellDef>
              <div class="tw-w-full tw-text-right">
                {{ (isPSTX ? 'pstx.RevenueRM' : 'campaign.revenue') | translate }}
              </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let item" class="tw-justify-end">
              <div class="tw-tabular-nums">
                {{ item.revenue === '0' ? '-' : item.revenue || '-' }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="GrossProfit">
            <mat-header-cell *matHeaderCellDef>
              <div class="tw-w-full tw-text-right">
                {{ (isPSTX ? 'pstx.ProfitLossBeforeTaxRM' : 'campaign.grossProfit') | translate }}
              </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let item" class="tw-justify-end">
              <div class="tw-tabular-nums">
                {{ item.grossProfit === '0' ? '-' : item.grossProfit || '-' }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="NetProfit">
            <mat-header-cell *matHeaderCellDef>
              <div class="tw-w-full tw-text-right">
                {{ (isPSTX ? 'pstx.ProfitLossAfterTaxRM' : 'campaign.netProfit') | translate }}
              </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let item" class="tw-justify-end">
              <div class="tw-tabular-nums">
                {{ item.netProfit === '0' ? '-' : item.netProfit || '-' }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Note">
            <mat-header-cell *matHeaderCellDef>
              <div class="tw-pl-4">{{ 'campaign.note' | translate }}</div>
            </mat-header-cell>
            <mat-cell *matCellDef="let item">
              <div
                matTooltip="{{ item.note }}"
                matTooltipPosition="after"
                class="tw-break-words tw-pl-4"
              >
                {{ item.note ?? '-' }}
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="getDisplayColumns()"> </mat-header-row>
          <mat-row *matRowDef="let item; columns: getDisplayColumns()"> </mat-row>
        </mat-table>
      </div>

      <div class="py-4 text-center" *ngIf="!financialOverviews || financialOverviews.length === 0">
        {{ 'common.noRecord' | translate }}
      </div>
    </div>
  </div>
</div>
<agmo-loading *ngIf="isLoading"></agmo-loading>
<div class="row" *ngIf="total > params.take">
  <div class="col-lg-12">
    <div class="paging mt-3">
      <ngb-pagination
        [collectionSize]="total"
        [(pageSize)]="params.take"
        [(page)]="params.currentPage"
        (pageChange)="changePage($event)"
        [maxSize]="5"
        [rotate]="true"
      >
        <ng-template ngbPaginationPrevious><i class="fa fa-chevron-left"></i></ng-template>
        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        <ng-template ngbPaginationNext><i class="fa fa-chevron-right"></i></ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>

<!-- Disclosure -->
<div class="tw-mt-2.5">
  <strong class="tw-mb-1.5 tw-block tw-text-base tw-text-black">{{
    'raise.disclosure' | translate
  }}</strong>
  <p class="tw-text-sm">{{ 'mysec.financialsDisclosure' | translate }}</p>
</div>
