<section class="section section-shaped">
  <div class="container">
    <div class="row justify-content-center" *ngIf="!isSent">
      <div class="col-lg-6">
        <h2 class="text-center font-weight-extrabold mb-4">
          {{ 'login.forgotPassword' | translate }}
        </h2>
        <form (ngSubmit)="submit()" #form [class.show-form-error]="showFormError">
          <div class="mb-3">
            <input
              class="form-control"
              placeholder="{{ 'forgotPassword.email' | translate }}"
              [(ngModel)]="email"
              name="email"
              type="text"
              required
            />
          </div>

          <div class="btn-panel">
            <button type="submit" class="btn btn-primary">
              {{ 'forgotPassword.send' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="row justify-content-center my-5" *ngIf="isSent">
      <div class="col-lg-6 text-center">
        <img
          class="email-img"
          src="../../../../assets/img/icons/common/email_open_icon@2x.png"
          alt="Email"
        />
        <h2 class="font-weight-extrabold mb-4 mt-5">
          {{ 'forgotPassword.requestResetPassword' | translate }}
        </h2>
        <div>{{ 'forgotPassword.emailSent' | translate }}</div>
        <div class="text-primary font-weight-bold mt-4 cursor-pointer" (click)="forgotPassword()">
          {{ 'forgotPassword.resendResetPasswordEmail' | translate }}
        </div>
      </div>
    </div>
  </div>
</section>
