import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PortfolioListModel } from '../../../core/models/portfolio/portfolio-list.model';
import { ShareInfo } from '../../../core/models/portfolio/share-info';

@Component({
  selector: 'app-portfolio-share-popup',
  templateUrl: './portfolio-share-popup.component.html',
  styleUrls: ['./portfolio-share-popup.component.scss'],
})
export class PortfolioSharePopupComponent implements OnInit {
  portfolioListModel: PortfolioListModel;

  directShare: number = 0;
  nomineeShare: number = 0;

  tradableShare: number;
  untradableShare: number;

  constructor(private modal: NgbActiveModal) {}

  ngOnInit() {
    this.directShare = this.getShareQty(this.portfolioListModel.shareInfos, false);
    this.nomineeShare = this.getShareQty(this.portfolioListModel.shareInfos, true);
    this.untradableShare = this.getShareOnHold(this.portfolioListModel.shareInfos);

    this.getTradableShares();
  }

  close() {
    this.modal.dismiss();
  }

  getShareQty(shareInfos: ShareInfo[], isNominee: boolean): number {
    let share = shareInfos.find((i) => i.isNominee === isNominee);
    return share ? share.shareQuantity : 0;
  }

  getShareOnHold(shareInfos: ShareInfo[]): number {
    let share = shareInfos.find((i) => !!i.isNominee);
    return share?.lockedShareQuantity ?? 0;
  }

  getTradableShares(): void {
    this.tradableShare = this.directShare + this.nomineeShare;
  }
}
