<!-- PSTX takeover (for limited time only) -->
<div class="container-fluid tw-hidden tw-pt-5 lg:tw-py-5" #pstxBanner>
  <a routerLink="/pstx">
    <div class="tw-max-w-full tw-rounded-10px">
      <picture>
        <source media="(min-width: 768px)" srcset="./assets/img/banner/pstx-launch-banner.jpg" />
        <source srcset="./assets/img/banner/pstx-launch-banner-mobile.jpg" />
        <img src="./assets/img/banner/pstx-launch-banner.jpg" class="tw-h-auto tw-max-w-full tw-rounded-10px"
          alt="pitchIN PSTX Banner" width="1280" height="200" />
      </picture>
    </div>
  </a>
</div>
<div class="container-fluid tw-hidden tw-pt-5 lg:tw-py-5" #pstxBannerLive>
  <a routerLink="/pstx">
    <div class="tw-max-w-full tw-rounded-10px">
      <picture>
        <source media="(min-width: 768px)" srcset="./assets/img/banner/pstx-live-banner.jpg" />
        <source srcset="./assets/img/banner/pstx-live-banner-mobile.jpg" />
        <img src="./assets/img/banner/pstx-live-banner.jpg" class="tw-h-auto tw-max-w-full tw-rounded-10px"
          alt="pitchIN PSTX Banner" width="1280" height="200" />
      </picture>
    </div>
  </a>
</div>

<div class="container-fluid tw-pb-10 tw-pt-8 sm:tw-py-10">
  <div class="tw-relative">
    <div class="tw-relative tw-z-[3] md:tw-max-w-[50%] lg:tw-max-w-2xl lg:tw-pt-10">
      <h1 class="p-text-h1 hero-title tw-mb-5" [innerHTML]="'landing.heroTitle' | translate"></h1>
      <p class="p-text-p !tw-text-xl">{{ 'landing.heroDescription' | translate }}</p>

      <div class="tw-mt-5">
        <a class="btn btn-primary !tw-mr-5" [routerLink]="['/equity', 'invest']">
          {{ 'whyInvest.whyInvest' | translate }} <span class="tw-ml-1">→</span>
        </a>
        <a class="p-link-primary p-link-underline" [routerLink]="['/sign-up']" data-t="signUpClick"
          data-t-location="page">{{ 'login.signUpNow' | translate }}</a>
      </div>
    </div>

    <div class="hero-image tw-z-[2] tw-text-right sm:tw-absolute">
      <!-- Quick hack to avoid bandwidth waste to load image in tablet/mobile -->
      <picture>
        <source media="(min-width: 768px)" srcset="./assets/img/banner/landing-hero.png" />
        <source srcset="
            data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+P///38ACfsD/QVDRcoAAAAASUVORK5CYII=
          " />
        <img src="./assets/img/banner/landing-hero.png" class="tw-h-auto tw-max-w-full max-md:tw-hidden"
          alt="pitchIN PSTX Banner" width="600" height="405" />
      </picture>
    </div>
  </div>
</div>

<!-- <div class="container-fluid">
    <div class="section-1 position-relative">
        <swiper class="swiper-navigation m-fadeOut" [config]="bannerConfig" [(index)]="bannerIndex"
            [class.m-fadeIn]="loadBannerSwiper" *ngIf="banners && banners.length > 0">
            <ng-template swiperSlide *ngFor="let slide of banners; let i = index;">
                <div class="landing-banner">
                    <a class="upload-container" *ngIf="slide.urlLink" [href]="slide.urlLink">
                        <img class="d-none d-md-block banner-image" [src]="slide.bannerImageAttachment?.url"
                            [alt]="slide.title" />
                        <img class="d-md-none banner-image" [src]="slide.bannerMobileImageAttachment?.url"
                            [alt]="slide.title" />
                    </a>
                    <div class="upload-container" *ngIf="!slide.urlLink">
                        <img class="d-none d-md-block banner-image" [src]="slide.bannerImageAttachment?.url"
                            [alt]="slide.title" />
                        <img class="d-md-none banner-image" [src]="slide.bannerMobileImageAttachment?.url"
                            [alt]="slide.title" />
                    </div>
                </div>
            </ng-template>
        </swiper>
        <div class="landing-banner" *ngIf="!banners">
            <div class="upload-container loading-container">
                <agmo-loading></agmo-loading>
            </div>
        </div>
    </div>
</div> -->

<div class="lg:tw-mt-6">
  <app-service-section></app-service-section>
</div>

<div class="tw-mt-8">
  <app-stats-section></app-stats-section>
</div>

<div class="tw-mt-20">
  <!-- ECF campaigns -->
  <div class="container-fluid">
    <div
      class="tw-mb-5 tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2 lg:tw-flex-nowrap lg:tw-gap-10">
      <div class="tw-mr-auto tw-max-w-xl">
        <product-badge></product-badge>
        <h2 class="p-text-h2">{{ 'landing.ecfSectionTitle' | translate }}</h2>
        <p>
          {{ 'landing.ecfSectionDescription' | translate }}
          <a class="p-link-underline tw-inline-block !tw-text-body" routerLink="/equity/invest">
            {{ 'whyInvest.whyInvest' | translate }} →
          </a>
        </p>
      </div>

      <nav class="navbar navbar-light navbar-content tw-border-b-0">
        <ul class="navbar-nav tw-flex-row">
          <li class="nav-item" [class.active]="selectedCampaignStatus === CampaignStatus.Live">
            <a role="button" class="nav-link" (click)="selectedCampaignStatus = CampaignStatus.Live">
              {{ 'common.liveCampaigns' | translate }}
            </a>
          </li>
          <li class="nav-item" [class.active]="selectedCampaignStatus === CampaignStatus.PreLive">
            <a role="button" class="nav-link" (click)="
                selectedCampaignStatus = CampaignStatus.PreLive;
                handleLoadCampaigns(CampaignStatus.PreLive)
              ">
              {{ 'common.preLiveCampaigns' | translate }}
            </a>
          </li>
        </ul>
      </nav>
    </div>

    <ng-container *ngIf="selectedCampaignStatus === CampaignStatus.Live">
      <app-campaign-list [campaigns]="campaigns[CampaignStatus.Live].data"
        [total]="campaigns[CampaignStatus.Live].total" [isLoading]="campaigns[CampaignStatus.Live].isLoading"
        [displayPagination]="false" [noRecordText]="'common.noLiveCampaign'"
        (getCampaignList)="getCampaigns(CampaignStatus.Live)"></app-campaign-list>
    </ng-container>

    <ng-container *ngIf="selectedCampaignStatus === CampaignStatus.PreLive">
      <app-campaign-list [campaigns]="campaigns[CampaignStatus.PreLive].data"
        [total]="campaigns[CampaignStatus.PreLive].total" [isLoading]="campaigns[CampaignStatus.PreLive].isLoading"
        [displayPagination]="false" (getCampaignList)="getCampaigns(CampaignStatus.PreLive)"></app-campaign-list>
    </ng-container>
  </div>

  <!-- Who has invested? -->
  <div class="container-fluid tw-mt-8" *ngIf="selectedCampaignStatus === CampaignStatus.Live">
    <div class="section-2 m-fadeOut" *ngIf="investedData && investedData.length > 0" [class.m-fadeIn]="loadSwiper">
      <swiper class="swiper-navigation" [config]="investedConfig">
        <ng-template swiperSlide let-slide *ngFor="let data of investedData; let i = index; last as isLast">
          <a class="wrapper router-link" [routerLink]="'/equity/' + data.campaignSlug"
            [class.border-right]="checkIsActive(slide.isActive, i)">
            <img width="50" height="50" [src]="data.logoUrl || defaultLogo" class="logo" [alt]="data.campaignSlug" />
            <div class="invested-details">
              <div class="text-header line-clamp">{{ data.investmentText }}</div>
              <div class="hour">{{ data.investedTimeAgo }}</div>
            </div>
          </a>
        </ng-template>
      </swiper>
    </div>
  </div>

  <!-- View all button -->
  <div class="tw-mx-auto tw-mt-8 tw-max-w-xs" *ngIf="!campaigns[CampaignStatus.Live].isLoading">
    <a class="btn btn-primary-border view-btn tw-w-full" [routerLink]="['/equity']">
      {{ 'common.viewAllCampaigns' | translate }}
    </a>
  </div>
</div>

<!-- TCF campaigns -->
<div class="tw-mt-20">
  <div class="container-fluid">
    <div
      class="tw-mb-5 tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2 lg:tw-flex-nowrap lg:tw-gap-10">
      <div class="tw-mr-auto tw-max-w-xl">
        <product-badge name="ieo"></product-badge>
        <h2 class="p-text-h2">{{ 'landing.tcfSectionTitle' | translate }}</h2>
        <p>
          {{ 'landing.tcfSectionDescription' | translate }}
        </p>
      </div>
    </div>

    <div *ngIf="loadingTCFCampaigns" class="tw-mt-6">
      <agmo-loading></agmo-loading>
    </div>

    <ng-container *ngIf="!loadingTCFCampaigns">
      <div class="tw-mt-6 tw-grid tw-gap-[1.875rem]">
        <!-- Loop through live campaigns -->
        <ng-container *ngFor="let campaign of liveOfferTcfCampaigns.slice(0, 2)">
          <app-tcf-campaign-card [campaign]="campaign"></app-tcf-campaign-card>
        </ng-container>

        <!-- Loop through prelive campaigns -->
        <ng-container *ngFor="let campaign of preLiveTcfCampaigns.slice(0, 2)">
          <app-tcf-campaign-card [campaign]="campaign"></app-tcf-campaign-card>
        </ng-container>

        <!-- Loop through cooling off campaigns -->
        <ng-container *ngFor="let campaign of coolingOffTcfCampaigns.slice(0, 2)">
          <app-tcf-campaign-card [campaign]="campaign"></app-tcf-campaign-card>
        </ng-container>
      </div>

      <!-- If no campaign, show empty state -->
      <ng-container *ngIf="
          liveOfferTcfCampaigns.length === 0 && preLiveTcfCampaigns.length === 0 && coolingOffTcfCampaigns.length === 0;
          else viewAllTcfCampaign
        ">
        <div class="tw-py-5 tw-text-center">
          {{ 'common.noTcfCampaign' | translate }}
        </div>
      </ng-container>

      <!-- View all button -->
      <ng-template #viewAllTcfCampaign>
        <div class="tw-mx-auto tw-mt-8 tw-max-w-xs" *ngIf="!campaigns[CampaignStatus.Live].isLoading">
          <a class="btn btn-primary-border view-btn tw-w-full" [href]="tcfBaseUrl">
            {{ 'common.viewAllCampaigns' | translate }}
          </a>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>

<!-- PSTX listing -->
<div class="tw-mt-20" *ngIf="environment.flagToShowPstx">
  <div class="container-fluid">
    <div
      class="tw-mb-3 tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2 lg:tw-flex-nowrap lg:tw-gap-10">
      <div class="tw-mr-auto tw-max-w-xl">
        <product-badge name="pstx"></product-badge>
        <h2 class="p-text-h2">{{ 'landing.pstxSectionTitle' | translate }}</h2>
        <p>
          {{ 'landing.pstxSectionDescription' | translate }}
          <a class="p-link-underline tw-inline-block !tw-text-body" routerLink="/pstx/how-it-works">
            {{ 'raise.howItWorks' | translate }} →
          </a>
        </p>
      </div>
    </div>

    <app-businesses [isPage]="false" [take]="20" [showPagination]="false"></app-businesses>
  </div>
</div>

<app-partnership></app-partnership>
<app-for-investors></app-for-investors>
<app-for-founders></app-for-founders>
<app-academy></app-academy>
<app-ecosystem></app-ecosystem>

<div class="section-9 p-5" *ngIf="!isLogin">
  <div class="container-fluid">
    <div class="row text-center py-4">
      <div class="col-lg-12">
        <h4 class="text-white font-weight-extrabold">{{ 'common.registerWithUs' | translate }}</h4>
      </div>
      <div class="col-lg-12 pt-2">
        <a class="btn btn-red font-weight-extrabold router-link" routerLink="/sign-up" data-t="signUpClick"
          data-t-location="page">
          {{ 'common.registerNow' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>