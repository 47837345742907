import { Attachment } from './../../models/attachment/attachment';
import { environment } from './../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CompanyService {
  private route = environment.apiServer + 'Api/v1/Companies';

  constructor(private http: HttpClient) {}

  uploadCompanyAttachment(document: Attachment): Observable<any> {
    const params = { 'noToken': 'noToken' };
    let formData = new FormData();
    formData.append(
      'AttachmentInfo.EncryptedAttachmentInfo',
      document.attachmentInfo.encryptedAttachmentInfo.toString(),
    );
    formData.append('AttachmentInfo.Token', document.attachmentInfo.token.toString());
    formData.append('type', document.attachmentType.id.toString());

    return this.http.post(this.route + '/attachment', formData, { params: params }).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getCompanyAttachments(): Observable<Attachment[]> {
    return this.http.get(this.route + '/attachments').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  deleteCompanyAttachment(id: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: {
        Id: id,
      },
    };

    return this.http.delete(this.route + '/attachment', options);
  }
}
