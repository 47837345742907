import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs';

@Component({
  selector: 'app-fee-structure',
  templateUrl: './fee-structure.component.html',
  styleUrls: ['./fee-structure.component.scss'],
})
export class FeeStructureComponent implements AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platform: Object,
  ) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.route.fragment.pipe(first()).subscribe((fragment) => {
        if (fragment) {
          setTimeout(() => {
            document.getElementById(fragment).scrollIntoView();
          }, 200);
        }
      });
    }
  }
}
