<div class="col-12" *ngIf="isLoading">
  <agmo-loading></agmo-loading>
</div>

<ng-container *ngIf="!isLoading">
  <div *ngIf="profile" class="row justify-content-center mb-5">
    <div class="col-12">
      <div class="d-flex mb-4">
        <img
          class="profile-image"
          alt="pitchIN User"
          [src]="profile.photoAttachmentUrl || defaultProfileImage"
        />
        <div class="w-100 text-right">
          <form (ngSubmit)="submitQuestion()" #form [class.show-form-error]="showFormError">
            <div class="input-field mb-3">
              <textarea
                class="form-control"
                rows="4"
                [(ngModel)]="questionContent"
                name="questionContent"
                [placeholder]="'campaign.gotQuestionFor' | translate: { company: companyName }"
                required
              ></textarea>
            </div>
            <button type="submit" class="btn btn-primary">
              {{ 'campaign.post' | translate }}
            </button>
          </form>
        </div>
      </div>
    </div>
    <div class="col-12 d-flex mb-3">
      <div class="text-green font-weight-extrabold title mr-2">
        {{ 'campaign.q&a' | translate }}
      </div>
      <div class="light-grey font-size-larger align-self-center">({{ questionsTotal }})</div>
    </div>
    <div class="col-12">
      <div
        class="d-flex mb-4"
        [class.deleted-question]="question.isDeleted"
        *ngFor="let question of questions; let i = index"
      >
        <img
          class="profile-image"
          [alt]="question.creator?.fullName"
          [src]="question.creator?.photoAttachmentUrl || defaultProfileImage"
        />
        <div class="question-content">
          <div class="d-flex mb-3">
            <div class="name-panel d-flex flex-wrap">
              <div class="text-black font-weight-extrabold mr-2 mb-2">
                {{ question.creator?.fullName }}
              </div>
              <div class="role" *ngIf="question.isCreator">
                {{ 'campaign.creator' | translate }}
              </div>
            </div>
            <div class="date-panel text-right light-grey" *ngIf="!question.isDeleted">
              {{ transformDate(question.createdAt) }}
            </div>
          </div>
          <div class="mb-3 font-size-smaller" *ngIf="!question.isDeleted">
            {{ question.text }}
          </div>
          <div class="mb-3 font-size-smaller" *ngIf="question.isDeleted">
            {{ 'campaign.questionDeleted' | translate }}
          </div>
          <div class="d-flex" *ngIf="!question.isDeleted">
            <div class="font-weight-bold text-primary w-100">
              <span class="cursor-pointer font-size-smaller" (click)="replyQuestion(question)">
                {{ 'campaign.reply' | translate }}</span
              >
            </div>
            <!-- <div class="pitchin-icon pitchin-icon-delete" *ngIf="isIssuer || question.isPostOwner"
                            (click)="deleteQuestion(question.id)"></div> -->
          </div>
          <div class="d-flex my-3" *ngIf="question.showReply">
            <img
              class="profile-image"
              alt="pitchIN User"
              [src]="profile.photoAttachmentUrl || defaultProfileImage"
            />
            <div class="w-100 text-right">
              <form
                (ngSubmit)="submitAnswer(question)"
                #answerForm
                [class.show-form-error]="showAnswerFormError"
              >
                <div class="input-field mb-3">
                  <textarea
                    class="form-control"
                    rows="4"
                    [(ngModel)]="replyContent"
                    name="replyContent"
                    required
                  ></textarea>
                </div>
                <button type="submit" class="btn btn-primary">
                  {{ 'campaign.post' | translate }}
                </button>
              </form>
            </div>
          </div>
          <div
            class="d-flex reply-item"
            *ngFor="let reply of question.answers?.data; let i = index"
          >
            <img
              class="profile-image"
              [alt]="reply.creator?.fullName"
              [src]="reply.creator?.photoAttachmentUrl || defaultProfileImage"
            />
            <div class="question-content">
              <div class="d-flex mb-3">
                <div class="name-panel d-flex flex-wrap">
                  <div class="text-black font-weight-extrabold mr-2 mb-2">
                    {{ reply.creator?.fullName }}
                  </div>
                  <div class="role" *ngIf="reply.isCreator">
                    {{ 'campaign.creator' | translate }}
                  </div>
                </div>
                <div class="date-panel w-50 text-right light-grey">
                  {{ transformDate(reply.createdAt) }}
                </div>
              </div>
              <div class="mb-3 font-size-smaller">
                {{ reply.text }}
              </div>
              <div class="d-flex">
                <div class="font-weight-bold text-primary w-100">
                  <span class="cursor-pointer font-size-smaller" (click)="replyQuestion(reply)">
                    {{ 'campaign.reply' | translate }}</span
                  >
                </div>
                <!-- <div class="pitchin-icon pitchin-icon-delete" *ngIf="isIssuer || reply.isPostOwner"
                                    (click)="deleteAnswer(question, reply.id)"></div> -->
              </div>
              <div class="d-flex my-3" *ngIf="reply.showReply">
                <img
                  class="profile-image"
                  alt="pitchIN User"
                  [src]="profile.photoAttachmentUrl || defaultProfileImage"
                />
                <div class="w-100 text-right">
                  <form
                    (ngSubmit)="submitAnswer(reply)"
                    #answerForm
                    [class.show-form-error]="showAnswerFormError"
                  >
                    <div class="input-field mb-3">
                      <textarea
                        class="form-control"
                        rows="4"
                        [(ngModel)]="replyContent"
                        name="replyContent"
                        required
                      ></textarea>
                    </div>
                    <button type="submit" class="btn btn-primary">
                      {{ 'campaign.post' | translate }}
                    </button>
                  </form>
                </div>
              </div>
              <div
                class="d-flex mt-4 subreply-item"
                *ngFor="let subreply of reply.answers?.data; let i = index"
              >
                <img
                  class="profile-image"
                  [alt]="subreply.creator?.fullName"
                  [src]="subreply.creator?.photoAttachmentUrl || defaultProfileImage"
                />
                <div class="question-content">
                  <div class="d-flex mb-3">
                    <div class="name-panel d-flex flex-wrap">
                      <div class="text-black font-weight-extrabold mr-2 mb-2">
                        {{ subreply.creator?.fullName }}
                      </div>
                      <div class="role" *ngIf="subreply.isCreator">
                        {{ 'campaign.creator' | translate }}
                      </div>
                    </div>
                    <div class="date-panel w-50 text-right light-grey">
                      {{ transformDate(subreply.createdAt) }}
                    </div>
                  </div>
                  <div class="mb-3 font-size-smaller">
                    {{ subreply.text }}
                  </div>
                  <!-- <div class="d-flex justify-content-end">
                                        <div class="pitchin-icon pitchin-icon-delete" *ngIf="isIssuer || subreply.isPostOwner"
                                            (click)="deleteAnswer(reply, subreply.id)"></div>
                                    </div> -->
                </div>
              </div>
              <div
                class="view-more reply-view-more"
                (click)="getAnswerList(reply)"
                *ngIf="
                  reply.answers &&
                  reply.answers.data &&
                  reply.answers.total - reply.answers.data.length > 0
                "
              >
                {{
                  'campaign.viewMoreReplies'
                    | translate: { total: reply.answers.total - reply.answers.data.length }
                }}
              </div>
            </div>
          </div>
          <div
            class="view-more reply-view-more"
            (click)="getAnswerList(question)"
            *ngIf="
              question.answers &&
              question.answers.data &&
              question.answers.total - question.answers.data.length > 0
            "
          >
            {{
              'campaign.viewMoreReplies'
                | translate: { total: question.answers.total - question.answers.data.length }
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 d-flex mb-3" *ngIf="questionsTotal > questions.length">
      <div class="view-more" (click)="loadMore()">
        {{ 'campaign.viewMoreQuestions' | translate: { total: questionsTotal - questions.length } }}
      </div>
    </div>
    <div class="col-12 pb-5 text-center" *ngIf="!questions || questions.length == 0">
      <app-empty-state></app-empty-state>
    </div>
  </div>
</ng-container>
