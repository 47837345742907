<section class="section section-shaped">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="row justify-content-center">
          <div class="col-lg-3 progress-step" [class.active]="step == 1">
            <div
              class="pitchin-icon pitchin-icon-details"
              [class.pitchin-icon-check-green]="step > 1"
            ></div>
            <div class="progress-title" [class.text-primary]="step >= 1">
              {{ 'raise.details' | translate }}
            </div>
          </div>
          <div class="col-lg-1 d-flex p-0 m-3">
            <div class="progress-line"></div>
          </div>
          <div class="col-lg-3 progress-step" [class.active]="step == 2">
            <div class="pitchin-icon pitchin-icon-documents"></div>
            <div class="progress-title" [class.text-primary]="step >= 2">
              {{ 'company.documents' | translate }}
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-5" *ngIf="step == 1">
          <div class="col-lg-12">
            <h2 class="text-center font-weight-extrabold">
              {{ 'raise.shareholders&DirectorsDetails' | translate }}
            </h2>
            <div class="text-center mb-4">{{ 'raise.pleaseProvideDetails' | translate }}</div>
            <app-shareholders-and-directors
              (submitForm)="next($event)"
            ></app-shareholders-and-directors>
          </div>
        </div>
        <div class="row justify-content-center mt-5" *ngIf="step == 2">
          <div class="col-lg-12">
            <h2 class="text-center font-weight-extrabold">
              {{ 'signUp.uploadDocuments' | translate }}
            </h2>
            <div class="text-center mb-5">{{ 'signUp.uploadDocumentDesc' | translate }}</div>
            <app-documents
              [attachments]="attachments"
              (uploadDocument)="uploadCompanyDocument($event)"
              (deleteDocument)="deleteCompanyDocument($event)"
            >
            </app-documents>
            <div class="text-center pt-5">
              <div (click)="submit()" class="btn btn-primary">
                {{ 'common.submit' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
