import { Component, OnInit } from '@angular/core';
import { FaqAccordion } from '../../core/models/learn/faq-accordion';
import { shariahComplianceFaqLd, ldToList } from '../../../assets/static/json-ld.js';
import { StorageService } from '../../core/services/storage.service';
import { Role } from '../../core/enum/role.enum';

@Component({
  selector: 'app-shariah-compliant-investment',
  templateUrl: './shariah-compliant-investment.component.html',
  styleUrls: ['./shariah-compliant-investment.component.scss'],
})
export class ShariahCompliantInvestmentComponent implements OnInit {
  pageFaqs: FaqAccordion[] = ldToList(shariahComplianceFaqLd);
  role: Role;
  Role = Role;

  constructor(private storageService: StorageService) {}

  ngOnInit() {
    this.role = this.storageService.role;
  }

  handleStartApplication() {
    if (!this.role) {
      return '/sign-up/register/4';
    }

    if (this.role === Role.IssuerRep) {
      return '/raise/raise-fund-form';
    }
  }

  principles = [
    {
      title: 'Justice (Adl)',
      description:
        'The rights of all parties in a transaction are upheld, ensuring that no one is wronged or taken advantage of.',
      positives: ['Fairness', 'Transparency', 'Honesty'],
      negatives: ['Unjust', 'Exploitation', 'Deceit'],
      imgSrc: 'shariah-justice.svg',
    },
    {
      title: 'Mutual Consent (Rida)',
      description:
        'Transactions are based on voluntary, and informed terms, respecting the autonomy of all parties.',
      positives: ['Free will', 'Mutual agreement'],
      negatives: ['Manipulation', 'Coercion'],
      imgSrc: 'shariah-consent.svg',
    },
    {
      title: 'Avoidance of Harm (Darar)',
      description: 'Transactions are conducted ethically, ensuring no harm to any party.',
      positives: ['Fair distributions', 'Transparency', 'Ethical activities'],
      negatives: ['Interest', 'Gambling', 'Harmful activities'],
      imgSrc: 'shariah-harm.svg',
    },
    {
      title: 'Transparency (Wadihah)',
      description: 'Transactions are performed with transparency, fostering trust with investors.',
      positives: ['Clear terms'],
      negatives: ['Hidden information'],
      imgSrc: 'shariah-transparency.svg',
    },
    {
      title: 'Respect for Rights (Huqūq)',
      description: 'The rights of all parties involved are upheld in every transaction.',
      positives: ['Due diligence', 'Respect ownership', 'Clear records'],
      negatives: ['Uncertainty', 'Taking without consent'],
      imgSrc: 'shariah-rights.svg',
    },
  ];

  businessScreening = [
    {
      percentage: '5%',
      activities: [
        'Conventional banking and lending',
        'Conventional insurance',
        'Gambling',
        'Non-halal food and beverages',
        'Other activities deemed non-compliant according to Shariah principles as determined by the Shariah Advisor',
      ],
    },
    {
      percentage: '20%',
      activities: [
        'Share trading',
        'Stockbroking business',
        'Rental received from Shariah non-compliant activities',
        'Other activities deemed non-compliant according to Shariah principles as determined by the Shariah Advisor',
      ],
    },
  ];

  investmentGuidelines = [
    {
      question: 'Are you looking for investments that promote positive social impact?',
      description:
        'Shariah-compliant investing is ideal as it encourages investments that contribute positively to society, supporting industries that create value in a socially responsible way.',
      category: 'Social Impacts',
      impacts: ['Education', 'Healthcare advancement', 'Renewable energy', 'Technology'],
      isPositive: true,
    },
    {
      question:
        'Do you prefer to invest in industries that align with your values and ethical standards?',
      description:
        'Under Shariah principles, industries that could negatively impact individuals, society, or the environment are deemed forbidden.',
      category: 'What should I avoid?',
      impacts: ['Exploitation', 'Any unethical practices', 'Harmful activities', 'Uncertainty'],
      isPositive: false,
    },
  ];

  benefits = [
    {
      title: 'Ethical and social responsibility',
      description:
        'Shariah-compliant investing aligns with Islamic values and avoids prohibited industries, while promoting social good through sectors like healthcare and renewable energy.',
      imgSrc: 'shariah-consent.svg',
    },
    {
      title: 'Risk-sharing and fairness',
      description:
        'The profit and loss are equally shared, where both investor and business owner share risks and rewards, unlike traditional fixed-return finance.',
      imgSrc: 'shariah-risk.svg',
    },
    {
      title: 'Diversification of investment options',
      description:
        'Offers a range of products, including Shariah ECF, allowing investors to diversify portfolios while following Islamic principles.',
      imgSrc: 'shariah-diverse.svg',
    },
    {
      title: 'Increased transparency and accountability',
      description:
        'Investments involve clear contracts to avoid ambiguity and include oversight to maintain ethical standards and reduce fraud.',
      imgSrc: 'shariah-accountability.svg',
    },
  ];

  founderBenefits = [
    {
      title: 'Alignment with a growing market',
      description:
        'The rise in demand for ethical and socially responsible investing aligns with Shariah-compliant investing, offering a way to contribute positively to society while achieving financial returns.',
      imgSrc: 'shariah-alignment.svg',
    },
    {
      title: 'Market differentiation',
      description:
        'Adhering to Shariah principles gives businesses a competitive advantage by attracting investors, enhancing reputation, and expanding access to a broader, socially-driven investment base.',
      imgSrc: 'shariah-market.svg',
    },
  ];

  assurances = [
    {
      title: 'Oversight by Shariah Advisors',
      description:
        "Advice and consultation provided through pitchIN's engagements with issuers from start till end of the process.",
      imgSrc: 'shariah-oversight.svg',
    },
    {
      title: 'Recognized Market Operator',
      description:
        'Licensed by the Securities Commission (SC) to facilitate Shariah-compliant Equity Crowdfunding (ECF) offerings.',
      imgSrc: 'shariah-recognized.svg',
    },
    {
      title: 'Thorough screening process',
      description:
        'At pitchIN, Shariah screening is mandatory for issuers offering Islamic deals, based on the Securities Commission’s Toolkit.',
      imgSrc: 'shariah-screening.svg',
    },
  ];
}
