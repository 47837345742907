<div>
  <div class="container-fluid tw-mx-auto tw-text-center">
    <h2
      class="p-text-h2 tw-mb-[25px] !tw-font-normal tw-text-[#1a1847]"
      [innerHTML]="content.title"
    ></h2>
  </div>
  <div class="tw-mb-[30px] tw-overflow-x-auto">
    <div
      class="nav nav-pills tw-mx-[15px] tw-min-w-[614px] tw-max-w-full !tw-flex-nowrap tw-justify-center"
    >
      <button
        *ngFor="let tab of content.tabs; let i = index"
        (click)="setIndex(i)"
        [class.!tw-text-white]="selectedIndex == i"
        [class.!tw-bg-primary]="selectedIndex == i"
        class="nav-item nav-link tw-select-none tw-whitespace-nowrap"
        type="button"
      >
        {{ tab.title }}
      </button>
    </div>
  </div>
  <div class="container-fluid">
    <app-inner-tab [tutorialSteps]="content.tabs[selectedIndex]"></app-inner-tab>
    <button
      (click)="resetContent()"
      class="tw-mx-auto tw-mb-[14px] tw-mt-[30px] tw-flex tw-items-center tw-rounded-full !tw-bg-[#1a1847] tw-px-4 tw-text-white hover:tw-cursor-pointer"
    >
      <img
        src="assets/img/icons/common/rotate-right.svg"
        alt="back button icon"
        class="tw-mr-2 tw-w-max"
      />Back
    </button>
  </div>
</div>
