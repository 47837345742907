import { IdentificationType } from '../../enum/identification-type.enum';
import { AttachmentInfo } from '../attachment/attachment-info';
import { Representative } from './representative';
import { SubRepresentativeModel } from './sub-representative.model';

export class RepresentativeModel {
  UserIdentityNoType: IdentificationType;
  FullName: string;
  IdentityNo: string;
  AttachmentInfo: AttachmentInfo;
  Representatives: SubRepresentativeModel[];

  constructor(model: Representative) {
    this.UserIdentityNoType = model.userIdentityNoType;
    this.FullName = model.fullName;
    this.IdentityNo = model.identityNo ? model.identityNo.replace(/-/g, '') : null;
    this.AttachmentInfo = model.attachmentInfo;
    this.Representatives = model.representatives
      ? model.representatives.map((representative) => new SubRepresentativeModel(representative))
      : [];
  }
}
