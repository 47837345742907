import { Component } from '@angular/core';

@Component({
  selector: 'app-service-terms-conditions',
  templateUrl: './service-terms-conditions.component.html',
  styleUrls: ['./service-terms-conditions.component.scss'],
})
export class ServiceTermsConditionsComponent {
  constructor() {}
}
