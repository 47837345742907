import { IdentificationType } from '../../enum/identification-type.enum';
import { AttachmentInfo } from '../attachment/attachment-info';
import { UserProfile } from './user-profile';

export class UserProfileModel {
  FullName: string;
  UserIdentityNoType: IdentificationType;
  IdentityNo: string;
  AboutMe: string;
  AttachmentInfo: AttachmentInfo;

  constructor(model: UserProfile) {
    this.FullName = model.fullName;
    this.UserIdentityNoType = model.userIdentityNoType;
    this.IdentityNo = model.identityNo ? model.identityNo.replace(/-/g, '') : null;
    this.AboutMe = model.aboutMe;
    this.AttachmentInfo = model.attachmentInfo;
  }
}
