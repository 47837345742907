export const statistics = {
    raised: 337400000,
    investors: 18000,
    campaigns: 181,
};

export const featuredCompanies = [
    {
        brandName: "TheLorry",
        companyLogoUrl:
            "../../assets/img/logo/logo_square_the_lorry.png",
        id: 1056,
        slug: "thelorry",
        sectors: [
            {
                id: 85,
                name: "E-Commerce",
                topSectors: true,
                createdAt: "2022-09-06T12:13:41.564314Z",
                createdBy: "00000000-0000-0000-0000-000000000000",
            },
        ],
        stats: {
            raised: 4140000,
            investors: 171,
        },
    },
    {
        brandName: "Commerce.Asia",
        companyLogoUrl:
            "../../assets/img/logo/logo_square_commerce_asia.jpg",
        id: 116,
        slug: "commerce-dotasia-ventures-sdn-bhd-2",
        sectors: [
            {
                id: 85,
                name: "E-Commerce",
                topSectors: true,
                createdAt: "2022-09-06T12:13:41.564314Z",
                createdBy: "00000000-0000-0000-0000-000000000000",
            },
        ],
        stats: {
            raised: 20000000,
            investors: 170,
        },
    },
    {
        brandName: "MoneyMatch",
        companyLogoUrl:
            "../../assets/img/logo/logo_square_money_match.png",
        id: 5,
        slug: "moneymatch-sdn-bhd",
        sectors: [
            {
                id: 116,
                name: "Finance, Insurance",
                topSectors: true,
                createdAt: "2022-09-06T12:13:41.564314Z",
                createdBy: "00000000-0000-0000-0000-000000000000",
            },
        ],
        stats: {
            raised: 3267000,
            investors: 124,
        },
    },
    {
        brandName: "Fundaztic",
        companyLogoUrl:
            "../../assets/img/logo/logo_square_fundaztic.png",
        id: 1086,
        slug: "peoplender-sdn-bhd-2",
        sectors: [
            {
                id: 298,
                name: "Technology",
                topSectors: false,
                createdAt: "2022-09-06T12:13:41.564314Z",
                createdBy: "00000000-0000-0000-0000-000000000000",
            },
        ],
        stats: {
            raised: 19641600,
            investors: 115,
        },
    },
    {
        brandName: "Kakitangan",
        companyLogoUrl:
            "../../assets/img/logo/logo_square_kakitangan.jpg",
        id: 1057,
        slug: "kakitangancom",
        sectors: [
            {
                id: 298,
                name: "Technology",
                topSectors: false,
                createdAt: "2022-09-06T12:13:41.564314Z",
                createdBy: "00000000-0000-0000-0000-000000000000",
            },
        ],
        stats: {
            raised: 1551174,
            investors: 63,
        },
    },
    {
        brandName: "Union Chain Group Sdn Bhd",
        companyLogoUrl:
            "../../assets/img/logo/logo_square_union_chain.png",
        id: 1130,
        slug: "union-chain-group-sdn-bhd",
        sectors: [
            {
                id: 122,
                name: "Food And Beverage",
                topSectors: false,
                createdAt: "2022-09-06T12:13:41.564314Z",
                createdBy: "00000000-0000-0000-0000-000000000000",
            },
        ],
        stats: {
            raised: 2238700,
            investors: 59,
        },
    },
    {
        brandName: "Speedhome",
        companyLogoUrl:
            "../../assets/img/logo/logo_square_speedhome.jpg",
        id: 159,
        slug: "speedrent-technology-sdn-bhd-3",
        sectors: [
            {
                id: 248,
                name: "Prop Tech",
                topSectors: false,
                createdAt: "2022-09-06T12:13:41.564314Z",
                createdBy: "00000000-0000-0000-0000-000000000000",
            },
        ],
        stats: {
            raised: 7126762,
            investors: 409,
        },
    },
    {
        brandName: "Newleaf Agro",
        companyLogoUrl:
            "../../assets/img/logo/logo_square_newleaf_agro.jpg",
        id: 138,
        slug: "newleaf-agro-sdn-bhd-",
        sectors: [
            {
                id: 8,
                name: "Agriculture",
                topSectors: false,
                createdAt: "2022-09-06T12:13:41.564314Z",
                createdBy: "00000000-0000-0000-0000-000000000000",
            },
        ],
        stats: {
            raised: 2400000,
            investors: 99,
        },
    },
    {
        brandName: "OXWHITE",
        companyLogoUrl:
            "../../assets/img/logo/logo_square_oxwhite.webp",
        id: 1128,
        slug: "oxwhite",
        sectors: [
            {
                id: 85,
                name: "E-Commerce",
                topSectors: true,
                createdAt: "2022-09-06T12:13:41.564314Z",
                createdBy: "00000000-0000-0000-0000-000000000000",
            },
        ],
        stats: {
            raised: 5019400,
            investors: 485,
        },
    },
    {
        brandName: "Signature Market",
        companyLogoUrl:
            "../../assets/img/logo/logo_square_signature_market.jpeg",
        id: 1066,
        slug: "signature-snack-sdn-bhd",
        sectors: [
            {
                id: 122,
                name: "Food & Beverage",
                topSectors: true,
                createdAt: "2022-09-06T12:13:41.564314Z",
                createdBy: "00000000-0000-0000-0000-000000000000",
            },
        ],
        stats: {
            raised: 2400000,
            investors: 186,
        },
    },
    {
        brandName: "PolicyStreet",
        companyLogoUrl:
            "../../assets/img/logo/logo_square_policy_street.png",
        id: 1140,
        slug: "policystreet",
        sectors: [
            {
                id: 116,
                name: "Financial Services",
                topSectors: true,
                createdAt: "2022-09-06T12:13:41.564314Z",
                createdBy: "00000000-0000-0000-0000-000000000000",
            },
        ],
        stats: {
            raised: 5280657,
            investors: 274,
        },
    },
    {
        brandName: "Slurp!",
        companyLogoUrl:
            "../../assets/img/logo/logo_square_slurp.png",
        id: 13,
        slug: "silentmode-sdn-bhd",
        sectors: [
            {
                id: 298,
                name: "Technology",
                topSectors: true,
                createdAt: "2022-09-06T12:13:41.564314Z",
                createdBy: "00000000-0000-0000-0000-000000000000",
            },
        ],
        stats: {
            raised: 2880000,
            investors: 112,
        },
    },
    {
        brandName: "Chargehere EV Solution",
        companyLogoUrl:
            "../../assets/img/logo/logo_square_chargehere.jpg",
        id: 160,
        slug: "chargehere-ev-solution-sdn-bhd",
        sectors: [
            {
                id: 97,
                name: "Energy",
                topSectors: true,
                createdAt: "2022-09-06T12:13:41.564314Z",
                createdBy: "00000000-0000-0000-0000-000000000000",
            },
        ],
        stats: {
            raised: 5551938,
            investors: 74,
        },
    },
    {
        brandName: "Lapasar",
        companyLogoUrl:
            "../../assets/img/logo/logo_square_lapasar.png",
        id: 53,
        slug: "tenderin-sdn-bhd",
        sectors: [
            {
                id: 85,
                name: "E-Commerce",
                topSectors: false,
                createdAt: "2022-09-06T12:13:41.564314Z",
                createdBy: "00000000-0000-0000-0000-000000000000",
            },
        ],
        stats: {
            raised: 7558760,
            investors: 18,
        },
    },
    {
        brandName: "NU Vending",
        companyLogoUrl:
            "../../assets/img/logo/logo_square_nu_vending.jpg",
        id: 1274,
        slug: "nu-vending-sdn-bhd3",
        sectors: [
            {
                id: 193,
                name: "Manufacturing",
                topSectors: false,
                createdAt: "2022-09-06T12:13:41.564314Z",
                createdBy: "00000000-0000-0000-0000-000000000000",
            },
        ],
        stats: {
            raised: 3802200,
            investors: 125,
        },
    },
    {
        brandName: "Superceed",
        companyLogoUrl:
            "../../assets/img/logo/logo_square_superceed.jpg",
        id: 8,
        slug: "superceed-m-sdn-bhd2",
        sectors: [
            {
                id: 283,
                name: "Software & IT Services",
                topSectors: true,
                createdAt: "2022-09-06T12:13:41.564314Z",
                createdBy: "00000000-0000-0000-0000-000000000000",
            },
        ],
        stats: {
            raised: 3448926,
            investors: 49,
        },
    },
];
