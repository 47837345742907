import { PopupService } from '../../../shared/services/popup/popup.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { InvestorType } from '../../../core/enum/investor-type.enum';
import { InvestorProfile } from '../../../core/models/user/investor-profile';
import { InvalidControlScrollService } from '../../../core/services/invalid-control-scroll.service';

@Component({
  selector: 'app-investor-profile',
  templateUrl: './investor-profile.component.html',
  styleUrls: ['./investor-profile.component.scss'],
})
export class InvestorProfileComponent implements OnInit, OnChanges {
  @Input() investorProfile: InvestorProfile;
  @Input() isSettings: boolean;
  @Input() isCompanyRep: boolean;
  @Output() submitForm = new EventEmitter();

  InvestorType: typeof InvestorType = InvestorType;

  form: UntypedFormGroup;
  showFormError: boolean = false;
  selectedInvestorType: any;
  investorTypes = [
    {
      id: InvestorType.SophisticatedInvestor,
      tier: 'registerInvestor.highestTier',
      name: 'registerInvestor.sophisticatedInvestor',
      limit: 'registerInvestor.unlimited',
      condition: {
        cond1: 'registerInvestor.sophisticatedCond1',
        cond2: 'registerInvestor.sophisticatedCond2',
        cond3: 'registerInvestor.sophisticatedCond3',
      },
      subCondition: true,
      subConditionPosition: 2,
      subCond: {
        cond1: 'registerInvestor.sophisticatedCond2Sub1',
        cond2: 'registerInvestor.sophisticatedCond2Sub2',
      },
    },
    {
      id: InvestorType.AngelInvestor,
      tier: 'registerInvestor.midTier',
      name: 'registerInvestor.angelInvestor',
      limit: {
        ecf: { total: 'registerInvestor.angelECF&PSTXtotal' },
        tcf: { total: 'registerInvestor.angelTCFtotal' },
      },
      condition: {
        cond1: 'registerInvestor.angelCond1',
        cond2: 'registerInvestor.angelCond2',
        cond3: 'registerInvestor.angelCond3',
      },
    },
    {
      id: InvestorType.RetailInvestor,
      tier: 'registerInvestor.lowestTier',
      name: 'registerInvestor.retailInvestor',
      limit: {
        ecf: {
          perIssuer: 'registerInvestor.retailECF&PSTXperIssuer',
          total: 'registerInvestor.retailECF&PSTXtotal',
        },
        tcf: {
          perIssuer: 'registerInvestor.retailTCFperIssuer',
          total: 'registerInvestor.retailTCFtotal',
        },
      },
      condition: {
        cond1: 'registerInvestor.retailCond1',
        cond2: 'registerInvestor.retailCond2',
      },
    },
  ];

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  corporateInvestorTypes = [
    {
      id: InvestorType.SophisticatedInvestor,
      tier: 'registerInvestor.highestTier',
      name: 'registerInvestor.sophisticatedInvestor',
      limit: 'registerInvestor.unlimited',
      condition: {
        cond1: 'registerInvestor.sophisticatedCond1Corp',
        cond2: 'registerInvestor.sophisticatedCond2Corp',
      },
      subCondition: true,
      subConditionPosition: 2,
      subCond: {
        cond1: 'registerInvestor.sophisticatedCond2Sub1Corp',
        cond2: 'registerInvestor.sophisticatedCond2Sub2Corp',
      },
    },
    {
      id: InvestorType.RetailInvestor,
      tier: 'registerInvestor.lowestTier',
      name: 'registerInvestor.retailInvestor',
      limit: {
        ecf: {
          perIssuer: 'registerInvestor.retailECF&PSTXperIssuer',
          total: 'registerInvestor.retailECF&PSTXtotal',
        },
        tcf: {
          perIssuer: 'registerInvestor.retailTCFperIssuer',
          total: 'registerInvestor.retailTCFtotal',
        },
      },
      condition: {
        cond1: 'registerInvestor.retailCondCorp',
      },
    },
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private popupService: PopupService,
    private invalidControlScrollService: InvalidControlScrollService,
  ) {}

  ngOnInit(): void {
    this.buildForm();

    this.isCompanyRep
      ? (this.selectedInvestorType = this.corporateInvestorTypes)
      : (this.selectedInvestorType = this.investorTypes);
  }

  ngOnChanges() {
    this.buildForm();

    if (this.investorProfile) {
      this.form.patchValue(this.investorProfile);
    }
  }

  buildForm() {
    this.form = this.formBuilder.group({
      investorType: ['', Validators.required],
      isAnonymous: [false],
      isAccept: [false, Validators.required],
    });

    if (this.investorProfile) {
      this.form.patchValue(this.investorProfile);
    }
  }

  submit() {
    if (this.form.value.investorType === '') {
      this.popupService.alert('registerInvestor.selectInvestorTypeAlert').then(() => {
        this.invalidControlScrollService.scrollToFirstInvalidControl();
      });
      return;
    }

    if (this.form.invalid) {
      this.popupService.alert('common.missingFieldsAlert');
      this.showFormError = true;
      return;
    }

    if (!this.form.value.isAccept) {
      this.popupService.alert('registerInvestor.acceptDeclarationAlert');
      return;
    }

    this.submitForm.emit(this.form.value);
  }

  selectInvestor(investorType: InvestorType) {
    this.form.controls['investorType'].setValue(investorType);
    const confirmButton = document.getElementById('confirmButton');
    if (confirmButton) {
      const yOffset = -600;
      const elementPosition = confirmButton.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: elementPosition + yOffset, behavior: 'smooth' });
    }
  }
}
