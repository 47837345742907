import { Component, Input } from '@angular/core';
import { ArticleTableContent } from '../../../../core/models/learn/article-table-content';

@Component({
  selector: 'app-learn-article-readmore',
  templateUrl: './learn-article-readmore.component.html',
  styleUrls: ['./learn-article-readmore.component.scss'],
})
export class LearnArticleReadmoreComponent {
  @Input() category: string;
  @Input() userType: string;
  @Input() list: ArticleTableContent;
  @Input() listByCategory: object = {};

  constructor() {}
}
