import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PopupType } from './popup-type.enum';
import { PopupModalComponent } from './popup-modal.component';
import { PopupOptions } from './popup-options';
import { SharedModule } from '../../shared.module';
import { PlatformLocation } from '@angular/common';

@Injectable({
  providedIn: SharedModule,
})
export class PopupService {
  defaultOptions: any = {
    centered: true,
    keyboard: false,
    backdropClass: 'backdrop-popup',
  };

  constructor(
    private modalService: NgbModal,
    private location: PlatformLocation,
  ) {
    this.location.onPopState(() => this.modalService.dismissAll());
  }

  alert(message: string, options?: PopupOptions): Promise<any> {
    return this.open(message, PopupType.alert, options);
  }

  confirm(message: string, options?: PopupOptions): Promise<any> {
    return this.open(message, PopupType.confirm, options);
  }

  success(message: string, options?: PopupOptions): Promise<any> {
    return this.open(message, PopupType.success, options);
  }

  private open(message: string, popupType: PopupType, options: PopupOptions): Promise<any> {
    this.defaultOptions.backdrop = options && options.backdrop ? options.backdrop : 'static';
    const modalRef = this.modalService.open(PopupModalComponent, this.defaultOptions);
    modalRef.componentInstance.type = popupType;
    modalRef.componentInstance.message = message;

    if (options) {
      modalRef.componentInstance.titleLabel = options.titleLabel;
      modalRef.componentInstance.okLabel = options.okLabel;
      modalRef.componentInstance.cancelLabel = options.cancelLabel;
      modalRef.componentInstance.okClass = options.okClass;
      modalRef.componentInstance.cancelClass = options.cancelClass;
      modalRef.componentInstance.titleClass = options.titleClass;
      modalRef.componentInstance.showTitle = options.showTitle == null ? true : options.showTitle;
    }

    return modalRef.result;
  }
}
