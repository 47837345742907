export const businessesList = [
    {
        name: undefined,
        address: undefined,
        bestBid: undefined,
        bestOffer: undefined,
        bidQty: undefined,
        brandName: "&nbsp;&nbsp;&nbsp;",
        changesValue: 0,
        companyAnnouncements: undefined,
        companyBannerUrl: undefined,
        companyId: 0,
        companyLogo: undefined,
        companyLogoUrl: undefined,
        companyName: "&nbsp;&nbsp;",
        companySectors: undefined,
        companySocialLinks: undefined,
        investorShareMarketStatus: [],
        isWatchList: undefined,
        lastTrade: 1,
        offerQty: undefined,
        orderQuantity: "0",
        priceChg: 9,
        registrationNo: "&nbsp;&nbsp;&nbsp;",
        registrationNumber: undefined,
        relatedShares: [],
        slug: undefined,
        tagline: undefined,
        tickerCode: undefined,
        valuation: undefined,
        valuationBasis: undefined,
        websiteUrl: undefined,
    },
    {
        name: undefined,
        address: undefined,
        bestBid: undefined,
        bestOffer: undefined,
        bidQty: undefined,
        brandName: "&nbsp;&nbsp;&nbsp;",
        changesValue: 0,
        companyAnnouncements: undefined,
        companyBannerUrl: undefined,
        companyId: 0,
        companyLogo: undefined,
        companyLogoUrl: undefined,
        companyName: "&nbsp;",
        companySectors: undefined,
        companySocialLinks: undefined,
        investorShareMarketStatus: [],
        isWatchList: undefined,
        lastTrade: 1,
        offerQty: undefined,
        orderQuantity: "0",
        priceChg: 9,
        registrationNo: "&nbsp;&nbsp;&nbsp;",
        registrationNumber: undefined,
        relatedShares: [],
        slug: undefined,
        tagline: undefined,
        tickerCode: undefined,
        valuation: undefined,
        valuationBasis: undefined,
        websiteUrl: undefined,
    },
    {
        name: undefined,
        address: undefined,
        bestBid: undefined,
        bestOffer: undefined,
        bidQty: undefined,
        brandName: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;",
        changesValue: 0,
        companyAnnouncements: undefined,
        companyBannerUrl: undefined,
        companyId: 0,
        companyLogo: undefined,
        companyLogoUrl: undefined,
        companyName: "&nbsp;&nbsp;",
        companySectors: undefined,
        companySocialLinks: undefined,
        investorShareMarketStatus: [],
        isWatchList: undefined,
        lastTrade: 1,
        offerQty: undefined,
        orderQuantity: "0",
        priceChg: 9,
        registrationNo: "&nbsp;&nbsp;&nbsp;",
        registrationNumber: undefined,
        relatedShares: [],
        slug: undefined,
        tagline: undefined,
        tickerCode: undefined,
        valuation: undefined,
        valuationBasis: undefined,
        websiteUrl: undefined,
    },
];
