import { Component } from '@angular/core';
import { statistics } from '../../../../assets/static/stats';

@Component({
  selector: 'app-stats-section',
  templateUrl: './stats-section.component.html',
  styleUrls: ['./stats-section.component.scss'],
})
export class StatsSectionComponent {
  stats = statistics;
}
