import * as prismic from '@prismicio/client';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const accessToken = environment.prismicAcessToken;
const routes = [
  {
    type: 'article',
    resolvers: {
      group: '_group',
      handle: '_group._handle',
    },
    path: '/learn/:handle/:uid',
  },
];
@Component({
  selector: 'app-learn-preview',
  templateUrl: './learn-preview.component.html',
  styleUrls: ['./learn-preview.component.scss'],
})
export class LearnPreviewComponent implements OnInit {
  client: prismic.Client = prismic.createClient('pitchin-my', {
    accessToken,
    routes,
  });

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.initRedirection();
  }

  async initRedirection() {
    const redirectUrl = await this.client.resolvePreviewURL({
      defaultURL: '/',
    });

    this.router.navigate([redirectUrl], { replaceUrl: true });
  }
}
