import { Directive, Input, EventEmitter, Output, ElementRef, HostListener } from '@angular/core';

var lastScrollTop = 0;

@Directive({
  selector: '[scrollSpy]',
})
export class ScrollSpyDirective {
  @Input() public spiedTags = [];
  @Input() navbarHeight: number = 0;
  @Output() public sectionChange = new EventEmitter<string>();
  private currentSection: string;

  constructor(private _el: ElementRef) {}

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    let currentSection: string;
    const children = this._el.nativeElement.children;
    const scrollTop = window.scrollY;

    let navbar = document.getElementById('tabList');
    let navbarOffsetHeight = navbar?.offsetHeight ?? 0;
    let navbarHeight = navbarOffsetHeight + this.navbarHeight;

    let footer = document.getElementsByTagName('footer')[0];
    let footerTop = footer.offsetTop - footer.offsetHeight;

    let closestTopPos = Number.MAX_VALUE;

    for (let i = 0; i < children.length; i++) {
      const element = children[i];

      if (this.spiedTags.some((spiedTag) => spiedTag === element.tagName)) {
        const distanceToTop = Math.abs(element.getBoundingClientRect().top);

        // Get the closest top position section
        if (distanceToTop - navbarHeight < closestTopPos) {
          closestTopPos = distanceToTop;
          currentSection = element.id;
        }
      }
    }

    if (currentSection !== this.currentSection) {
      this.currentSection = currentSection;
      this.sectionChange.emit(this.currentSection);
    }

    if (scrollTop > footerTop && !navbar.classList.contains('headroom-bottom-padding'))
      navbar.classList.add('headroom-bottom-padding');
    else if (scrollTop <= footerTop && navbar.classList.contains('headroom-bottom-padding'))
      navbar.classList.remove('headroom-bottom-padding');

    lastScrollTop = scrollTop;
  }
}
