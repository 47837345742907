import { YEAR_FORMATS } from '../../core/services/constant';
import { InvestmentType } from './../../core/enum/investment-type.enum';
import { Component, OnInit } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { PortfolioService } from '../../core/services/api/portfolio.service';
import { ErrorService } from '../../core/services/error.service';
import { PortfolioSummary } from '../../core/models/portfolio/portfolio-summary';
import { finalize } from 'rxjs/operators';
import { PortfolioListModel } from '../../core/models/portfolio/portfolio-list.model';
import { ActivatedRoute } from '@angular/router';
import { portfolioFaqLd, ldToList } from '../../../assets/static/json-ld';
import { FaqAccordion } from '../../core/models/learn/faq-accordion';
@Component({
  selector: 'app-my-portfolio',
  templateUrl: './my-portfolio.component.html',
  styleUrls: ['./my-portfolio.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: YEAR_FORMATS }],
})
export class MyPortfolioComponent implements OnInit {
  selectedTab: string = 'portfolio';

  portfolioListModel: PortfolioListModel[];
  portfolioSummary: PortfolioSummary;
  total: number = 0;
  isLoading: boolean = false;
  params = {
    currentPage: 1,
    take: 20,
  };
  selectedYear: Moment = moment();

  InvestmentType: typeof InvestmentType = InvestmentType;

  faqList: Array<{ question: string; answer: string }> = [];

  portfolioFaqLd = portfolioFaqLd;
  pageFaqs: FaqAccordion[] = ldToList(portfolioFaqLd);

  // lineChartData: ChartDataSets[];
  // lineChartLabels: Label[];
  // lineChartOptions: (ChartOptions & { annotation: any });
  // lineChartColors: Color[];

  // pieChartOptions: ChartOptions;
  // pieChartLabels: Label[];
  // pieChartData: SingleDataSet;
  // pieChartColors: Color[];

  constructor(
    private portfolioService: PortfolioService,
    private errorService: ErrorService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    // this.initLineChart();
    // this.initPieChart();

    this.getPortfolio();
    this.loadFaq();

    this.route.queryParams.subscribe((params) => {
      this.selectedTab = params.selectedTab == 'history' ? 'history' : 'portfolio';
    });
  }

  loadFaq() {
    this.faqList = portfolioFaqLd.mainEntity.map((item) => {
      return {
        question: item.name,
        answer: item.acceptedAnswer.text,
      };
    });
  }

  // initLineChart() {
  //     this.lineChartData = [
  //         { data: [6500, 5900, 8000, 8100, 5600, 5500, 4000] },
  //     ];
  //     this.lineChartLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  //     this.lineChartOptions = {
  //         responsive: true,
  //         annotation: {},
  //     };
  //     this.lineChartColors = [
  //         {
  //             borderWidth: 2,
  //             borderColor: '#D8D8D8',
  //             backgroundColor: 'transparent',
  //             pointBackgroundColor: '#0E2B43',
  //             pointBorderColor: '#0E2B43',
  //             pointBorderWidth: 6,
  //             pointHoverBackgroundColor: '#C00000',
  //             pointHoverBorderColor: '#C00000',
  //             pointHoverBorderWidth: 6
  //         },
  //     ];
  // }

  // initPieChart() {
  //     this.pieChartOptions = {
  //         responsive: true,
  //         legend: {
  //             position: 'left',
  //         },
  //     };
  //     this.pieChartLabels = ['Mobile App', 'Education ', 'Technology'];
  //     this.pieChartData = [300, 500, 100];
  //     this.pieChartColors = [
  //         {
  //             backgroundColor: ['#DF7F7F', '#C00000', '#F3CCCC'],
  //         },
  //     ];
  // }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment(this.selectedYear);
    ctrlValue.year(moment(normalizedYear).year());
    this.selectedYear = ctrlValue;
    datepicker.close();
  }

  getPortfolio() {
    this.isLoading = true;

    this.portfolioService
      .getPortfolio(this.params.currentPage, this.params.take)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          let portfolioList = data.data;

          portfolioList.map(({ portfolio }) => {
            portfolio.unrealisedGainLossPercentage = Number(
              ((portfolio?.unrealisedGainLoss / portfolio?.purchasedAmount) * 100).toFixed(2),
            );
          });

          this.portfolioListModel = portfolioList;
          this.portfolioSummary = data.summary;
          this.total = data.total;
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }
}
