import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-tnc-modal',
  templateUrl: './tnc-modal.component.html',
  styleUrls: ['./tnc-modal.component.scss'],
})
export class TncModalComponent implements OnInit {
  url: string;
  safeUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}
