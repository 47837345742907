import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvestmentLimit } from '../../../core/models/investment/investment-limit';
import { InvestorType } from '../../../core/enum/investor-type.enum';

@Component({
  selector: 'app-investment-limit-popup',
  templateUrl: './investment-limit-popup.component.html',
  styleUrls: ['./investment-limit-popup.component.scss'],
})
export class InvestmentLimitPopupComponent implements OnInit {
  exceedLimitType: 'campaign' | 'interval' = 'campaign';

  currentInvestorType: InvestorType;
  recommendedInvestorType: InvestorType;

  investmentLimitConfig: InvestmentLimit;

  InvestorType: typeof InvestorType = InvestorType;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.currentInvestorType = this.investmentLimitConfig?.CurrentInvestorType;
    this.recommendedInvestorType = this.investmentLimitConfig?.RecommendedInvestorType;
  }

  close() {
    this.activeModal.dismiss();
  }

  confirm(isProceedToUpgradeInvestorType: boolean) {
    this.activeModal.close(isProceedToUpgradeInvestorType);
  }
}
