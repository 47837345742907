import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CampaignFiltersMenu } from '../../../../../../core/models/campaign/campaign-filters-menu';
import { CampaignFiltersTag } from '../../../../../../core/models/campaign/campaign-filters-tag';
import { QueryParamsService } from '../../../../../../core/services/query-params.service';

@Component({
  selector: 'app-campaign-filters-item-label',
  templateUrl: './campaign-filters-item-label.component.html',
  styleUrls: ['./campaign-filters-item-label.component.scss'],
})
export class CampaignFiltersItemLabelComponent {
  @Input() menu: CampaignFiltersMenu;
  @Input() parentMenu: CampaignFiltersMenu;
  @Input() isActive: boolean = false;
  @Output() onChange = new EventEmitter<CampaignFiltersTag>();

  constructor(private queryParamsService: QueryParamsService) {}

  handleClick() {
    this.isActive = !this.isActive;

    this.onChange.emit({
      parentKey: this.parentMenu.key,
      isActive: this.isActive,
      isArray: this.parentMenu.isArray ?? false,
      isMultiSelect: this.parentMenu.isMultiSelect ?? true,
      ...this.menu,
    });
  }
}
