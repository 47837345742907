import { Component, Input } from '@angular/core';
import { BuyerSellerTabs } from 'src/app/core/models/mysec/buy-sell-content';

@Component({
  selector: 'app-inner-tab',
  templateUrl: './inner-tab.component.html',
  styleUrls: ['./inner-tab.component.scss'],
})
export class InnerTabComponent {
  selectedIndex: number = 0;

  @Input() tutorialSteps: BuyerSellerTabs;

  setIndex(index: number) {
    this.selectedIndex = index;
  }
}
