<div class="modal-body text-center p-5">
  <h5 class="font-weight-extrabold text-primary">
    {{ 'company.uploadDocumentTitle' | translate }}
  </h5>
  <form [formGroup]="form" [class.show-form-error]="showFormError" *ngIf="form">
    <div class="input-panel mt-3">
      <div class="input-label text-left">{{ 'company.uploadDocumentTitle' | translate }}*</div>
      <div class="input-field" *ngIf="!isLoading">
        <div
          class="banner-upload"
          agmoUploader
          [accept]="fileTypes.toString()"
          (selectFiles)="selectFile($event)"
        >
          <div class="upload-container upload-field d-flex">
            <div class="align-self-center px-4 m-auto">
              <div class="pitchin-icon pitchin-icon-upload"></div>
              <ng-container *ngIf="!document.file">
                <div class="drop-document">
                  {{ 'company.uploadDocument' | translate }}
                </div>
                <div class="upload-document-instruction">
                  {{ 'company.pleaseProvideDocument' | translate }}
                </div>
              </ng-container>
              <ng-container *ngIf="document.file">
                <div class="upload-instruction mt-3 text-center">
                  {{ document.name ? document.name : document.fileName }}
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <agmo-loading *ngIf="isLoading"></agmo-loading>
    </div>
    <div class="input-panel">
      <div class="input-label text-left">{{ 'company.documentName' | translate }}</div>
      <div class="input-field">
        <input class="form-control" formControlName="fileName" type="text" maxlength="200" />
      </div>
    </div>
  </form>
  <div class="d-flex flex-column">
    <button
      class="btn btn-primary font-weight-extrabold"
      (click)="confirm()"
      [disabled]="isLoading"
    >
      {{ 'common.confirm' | translate }}
    </button>
    <div class="btn btn-grey font-weight-extrabold" (click)="cancel()">
      {{ 'common.no' | translate }}
    </div>
  </div>
</div>
