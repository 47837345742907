<div class="tw-mt-4 tw-text-center lg:tw-mt-0">
  <p class="p-text-p tw-mb-[10px] tw-text-left tw-text-black">
    <strong>Order Book</strong> list of all the shares up for trade
  </p>
  <div
    class="tw-m-auto tw-mb-[10px] tw-max-w-2xl tw-border-collapse tw-overflow-auto tw-rounded-lg tw-border-2 tw-border-black"
  >
    <table class="table--buysell tw-w-full">
      <tr class="tw-border-b-2 tw-text-[#1a1847]">
        <th class="tw-text-right">Volume</th>
        <th class="tw-border-r-[6px] tw-border-black tw-pr-4 tw-text-right">Buy price (RM)</th>
        <th class="tw-pl-4 tw-text-left">Sell price (RM)</th>
        <th class="tw-text-left">Volume</th>
      </tr>

      <tr *ngFor="let order of orderBooks; let i = index">
        <td
          [ngClass]="[
            order.buyOrder?.isHighlighted
              ? order.buyOrder?.isHighlighted == 'gray'
                ? 'tw-bg-[#DDDBE6]'
                : 'tw-bg-[#FFF3D9]'
              : '',
            order.buyOrder?.isBold ? 'tw-font-bold' : '',
          ]"
          class="tw-w-[25%] tw-text-right"
        >
          {{ order.buyOrder?.volume || '&nbsp;' }}
        </td>
        <td
          [ngClass]="[
            order.buyOrder?.isHighlighted
              ? order.buyOrder?.isHighlighted == 'gray'
                ? 'tw-bg-[#DDDBE6]'
                : 'tw-bg-[#FFF3D9]'
              : '',
            order.buyOrder?.isBold ? 'tw-font-bold' : '',
          ]"
          class="tw-w-[25%] tw-border-r-[6px] tw-border-black tw-pr-4 tw-text-right"
        >
          {{ (order.buyOrder?.price | number: '1.2-2') || '&nbsp;' }}
        </td>
        <td
          [ngClass]="[
            order.sellOrder?.isHighlighted
              ? order.sellOrder?.isHighlighted == 'gray'
                ? 'tw-bg-[#DDDBE6]'
                : 'tw-bg-[#FFF3D9]'
              : '',
            order.sellOrder?.isBold ? 'tw-font-bold' : '',
          ]"
          class="tw-w-[25%] tw-pl-4 tw-text-left"
        >
          {{ (order.sellOrder?.price | number: '1.2-2') || '&nbsp;' }}
        </td>
        <td
          [ngClass]="[
            order.sellOrder?.isHighlighted
              ? order.sellOrder?.isHighlighted == 'gray'
                ? 'tw-bg-[#DDDBE6]'
                : 'tw-bg-[#FFF3D9]'
              : '',
            order.sellOrder?.isBold ? 'tw-font-bold' : '',
          ]"
          class="tw-w-[25%] tw-text-left"
        >
          {{ order.sellOrder?.volume || '&nbsp;' }}
        </td>
      </tr>
    </table>
  </div>
  <p class="p-text-p tw-mb-[10px] tw-text-left tw-text-black">
    <strong>Portfolio</strong> list of all the shares you own
  </p>
  <div
    class="tw-m-auto tw-mb-0 tw-max-w-2xl tw-overflow-auto tw-rounded-lg tw-border-2 tw-border-black"
  >
    <table class="table--portfolio tw-w-full tw-text-left tw-text-black">
      <tr>
        <th class="tw-w-[152px]">Business</th>
        <th class="tw-text-right">Qty</th>
        <th class="tw-text-right">Inv. Amount</th>
        <th class="tw-text-right">Ave. Price per share</th>
      </tr>
      <tr
        [ngClass]="
          content.portfolio.isHighlighted
            ? content.portfolio.isHighlighted == 'green'
              ? 'tw-bg-[#CFEBDC]'
              : 'tw-bg-[#FFF3D9]'
            : ''
        "
      >
        <td>{{ content.portfolio.business || '&nbsp;' }}</td>
        <td class="tw-text-right tw-tabular-nums">{{ content.portfolio.quantity || '&nbsp;' }}</td>
        <td class="tw-text-right tw-tabular-nums">
          {{ content.portfolio.investmentAmount || '&nbsp;' }}
        </td>
        <td class="tw-text-right tw-tabular-nums">
          {{ content.portfolio.shareAveragePrice || '&nbsp;' }}
        </td>
      </tr>
    </table>
  </div>
</div>
