import {
  Component,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
import { Campaign } from '../../../core/models/campaign/campaign';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../core/services/auth/auth.service';
import { AccountService } from '../../../core/services/api/account.service';
import { CampaignStatus } from '../../../core/enum/campaign-status.enum';
import { isPlatformBrowser } from '@angular/common';
import { SocialLinkType } from '../../../core/enum/social-link-type.enum';
import { ShareType } from '../../../core/enum/share-type.enum';
import { CampaignInfo } from '../../../core/models/campaign/campaign-info';
import { Router } from '@angular/router';

@Component({
  selector: 'campaign-investment-details',
  templateUrl: './campaign-investment-details.component.html',
  styleUrls: ['./campaign-investment-details.component.scss'],
})
export class CampaignInvestmentDetailsComponent implements OnInit, OnChanges {
  @Input() campaign: Campaign;
  @Input() campaignInfo: CampaignInfo;
  @Input() isMobile: boolean = false;

  SocialLinkType: typeof SocialLinkType = SocialLinkType;

  isLogin: boolean = false;
  isCampaignEnded: boolean = false;

  isConnectWithFoundersCollapsed: boolean = false;
  isInvestSummaryCollapsed: boolean = false;
  isBusinessOverviewCollapsed: boolean = false;

  investmentStats: {
    name: string;
    value: string | number;
    isCurrency?: boolean;
    show?: (campaign: Campaign) => boolean;
  }[];

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private accountService: AccountService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth > 767) {
      this.isInvestSummaryCollapsed = false;
      this.isBusinessOverviewCollapsed = false;
      this.isConnectWithFoundersCollapsed = false;
    } else {
      this.isInvestSummaryCollapsed = true;
      this.isBusinessOverviewCollapsed = true;
      this.isConnectWithFoundersCollapsed = true;
    }
  }

  ngOnInit() {
    this.isLogin = this.authService.isAuthenticated();
    this.isCampaignEnded =
      [CampaignStatus.Success, CampaignStatus.Fail, CampaignStatus.Rejected].includes(
        this.campaign?.campaignStatus,
      ) ?? false;

    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth <= 767) {
        this.isInvestSummaryCollapsed = true;
        this.isBusinessOverviewCollapsed = true;
        this.isConnectWithFoundersCollapsed = true;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['campaign']) {
      this.updateStatistics();
    }
  }

  updateStatistics() {
    this.investmentStats = [
      {
        name: 'campaign.type',
        value:
          this.campaign?.shareType === ShareType.Ulp
            ? `${this.translateService.instant('mysec.' + ShareType[this.campaign?.shareType])}`
            : `${this.campaign?.campaignType} - ${this.translateService.instant(
                'mysec.' + ShareType[this.campaign?.shareType],
              )}`,
      },
      {
        name: 'campaign.equityOffered',
        value: `${this.translateService.instant(
          'campaign.upToPercent',
        )} ${this.campaign?.equityOffered?.toFixed(2)}%`,
        show: () => {
          return this.campaign?.shareType !== ShareType.Ulp;
        },
      },
      {
        name: 'campaign.valuation',
        value: this.campaign?.valuationAmount,
        isCurrency: true,
        show: () => {
          return this.campaign.shareType !== ShareType.Ulp;
        },
      },
      {
        name: 'campaign.minimumFundingTarget',
        value: this.campaign?.minimumTargetAmount,
        isCurrency: true,
      },
      {
        name: 'campaign.totalTargetAmount',
        value: this.campaign?.maximumTargetAmount,
        isCurrency: true,
      },
      {
        name: 'campaign.taxRelief',
        value: this.campaign?.isAngelTaxIncentive ? 'ATI' : '-',
      },
    ];
  }

  handleLoginToProceed() {
    this.router.navigate(['/login'], {
      queryParams: { returnUrl: this.router.url },
    });
  }
}
