<div class="container-fluid">
  <div
    class="tw-grid tw-grid-cols-3 tw-items-center tw-divide-x tw-divide-y-0 tw-divide-divider lg:tw-grid-cols-5"
  >
    <div class="col--custom">
      <span class="text--highlight">
        {{ stats.raised | numberSuffix: { isSuffix: true, numberFormat: '1.0-0' } }}+
      </span>
      <span>{{ 'landing.raisedInTotal' | translate }}</span>
    </div>
    <div class="col--custom">
      <span class="text--highlight"> {{ stats.investors | number }}+ </span>
      <span>{{ 'landing.ofInvestors' | translate }}</span>
    </div>
    <div class="col--custom">
      <span class="text--highlight"> {{ stats.campaigns | number }}+ </span>
      <span>{{ 'landing.ofCampaigns' | translate }}</span>
    </div>
    <div class="col--custom tw-col-span-3 !tw-border-l-0 lg:tw-col-span-2 lg:!tw-border-l">
      <span
        class="text--highlight tw-border-t tw-border-divider tw-pt-5 lg:tw-border-t-0 lg:tw-pt-0 lg:!tw-text-2xl"
      >
        {{ 'whyInvest.rmo' | translate }}
      </span>
    </div>
  </div>
</div>
