export enum Countries {
  'UnitedStates',
  'Afghanistan',
  'AlandIslands',
  'Albania',
  'Algeria',
  'AmericanSamoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'AntiguaAndBarbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'BonaireSintEustatiusSaba',
  'BosniaAndHerzegovina',
  'Botswana',
  'BouvetIsland',
  'Brazil',
  'BritishIndianOceanTerritory',
  'BruneiDarussalam',
  'Bulgaria',
  'BurkinaFaso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'CapeVerde',
  'CaymanIslands',
  'CentralAfricanRepublic',
  'Chad',
  'Chile',
  'China',
  'ChristmasIsland',
  'CocosKeelingIslands',
  'Colombia',
  'Comoros',
  'Congo',
  'CongoDemocraticRepublic',
  'CookIslands',
  'CostaRica',
  'CoteDIvoire',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'CzechRepublic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'DominicanRepublic',
  'Ecuador',
  'Egypt',
  'ElSalvador',
  'EquatorialGuinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'FalklandIslands',
  'FaroeIslands',
  'Fiji',
  'Finland',
  'France',
  'FrenchGuiana',
  'FrenchPolynesia',
  'FrenchSouthernTerritories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'GuineaBissau',
  'Guyana',
  'Haiti',
  'HeardIslandMcdonaldIslands',
  'HolySeeVaticanCityState',
  'Honduras',
  'HongKong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'IsleOfMan',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea',
  'KoreaDemocraticPeoplesRepublic',
  'Kuwait',
  'Kyrgyzstan',
  'LaoPeoplesDemocraticRepublic',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'LibyanArabJamahiriya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'MarshallIslands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'NewCaledonia',
  'NewZealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'NorfolkIsland',
  'NorthernMarianaIslands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'PalestinianTerritory',
  'Panama',
  'PapuaNewGuinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'PuertoRico',
  'Qatar',
  'Reunion',
  'Romania',
  'RussianFederation',
  'Rwanda',
  'SaintBarthelemy',
  'SaintHelena',
  'SaintKittsAndNevis',
  'SaintLucia',
  'SaintMartin',
  'SaintPierreAndMiquelon',
  'SaintVincentAndGrenadines',
  'Samoa',
  'SanMarino',
  'SaoTomeAndPrincipe',
  'SaudiArabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'SierraLeone',
  'Singapore',
  'SintMaarten',
  'Slovakia',
  'Slovenia',
  'SolomonIslands',
  'Somalia',
  'SouthAfrica',
  'SouthGeorgiaAndSandwichIsl',
  'SouthSudan',
  'Spain',
  'SriLanka',
  'Sudan',
  'Suriname',
  'SvalbardAndJanMayen',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'SyrianArabRepublic',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'TimorLeste',
  'Togo',
  'Tokelau',
  'Tonga',
  'TrinidadAndTobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'TurksAndCaicosIslands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'UnitedArabEmirates',
  'UnitedKingdom',
  'UnitedStatesOutlyingIslands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'VietNam',
  'VirginIslandsBritish',
  'VirginIslandsUS',
  'WallisAndFutuna',
  'WesternSahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
}
