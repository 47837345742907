<div *ngIf="!isLoading; else loading">
  <ng-container *ngIf="announcements?.length > 0; else emptyState">
    <!-- Desktop table -->
    <div class="tw-grid tw-gap-4 max-md:tw-hidden xl:tw-grid-cols-2">
      <a
        *ngFor="let announcement of announcements"
        [routerLink]="['/pstx', announcement.tickerCode]"
        [queryParams]="{ selectedTab: 6, id: announcement.id }"
        target="_blank"
        class="tw-rounded-lg tw-border tw-border-divider tw-p-4 hover:tw-border-divider-dark"
      >
        <ng-container
          *ngTemplateOutlet="announcementHeader; context: { announcement: announcement }"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="announcementBody; context: { announcement: announcement }"
        ></ng-container>
      </a>
    </div>

    <!-- Mobile table -->
    <div class="tw-space-y-4 md:tw-hidden">
      <div
        *ngFor="let announcement of announcements"
        class="tw-rounded-lg tw-border tw-border-divider"
      >
        <button
          class="tw-w-full tw-bg-transparent tw-px-4 tw-py-3 tw-text-left"
          type="button"
          (click)="announcement.isCollapsed = !announcement.isCollapsed"
        >
          <div class="tw-flex tw-items-center tw-justify-between tw-gap-2.5">
            <div class="tw-flex-auto">
              <ng-container
                *ngTemplateOutlet="announcementHeader; context: { announcement: announcement }"
              ></ng-container>
            </div>

            <span
              class="tw-inline-block tw-origin-center tw-transform tw-transition-transform"
              [class.tw-rotate-180]="!announcement.isCollapsed"
            >
              <p-icon name="chevron-down"></p-icon>
            </span>
          </div>
        </button>

        <div class="tw-px-4 tw-py-3" [ngbCollapse]="announcement.isCollapsed">
          <ng-container
            *ngTemplateOutlet="announcementBody; context: { announcement: announcement }"
          ></ng-container>
        </div>
      </div>
    </div>

    <!-- Pagination -->
    <div class="tw-my-8 tw-flex tw-justify-center" *ngIf="total > params.take">
      <ngb-pagination
        [collectionSize]="total"
        [maxSize]="5"
        [rotate]="true"
        [pageSize]="params.take"
        [(page)]="params.currentPage"
        (pageChange)="getAnnouncements()"
      >
        <ng-template ngbPaginationPrevious><i class="fa fa-chevron-left"></i></ng-template>
        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        <ng-template ngbPaginationNext><i class="fa fa-chevron-right"></i></ng-template>
      </ngb-pagination>
    </div>
  </ng-container>

  <ng-template #emptyState>
    <div class="text-center tw-py-20">
      {{ 'announcementsEmptyState' | translate }}
    </div>
  </ng-template>
</div>

<ng-template #loading>
  <agmo-loading></agmo-loading>
</ng-template>

<ng-template #announcementHeader let-announcement="announcement">
  <div class="tw-flex tw-justify-between tw-gap-2.5 max-lg:tw-items-center max-sm:tw-flex-wrap">
    <div class="tw-min-w-[0] tw-flex-auto max-sm:tw-flex-[0_0_100%]">
      <div class="tw-flex tw-items-start sm:tw-items-center">
        <app-thumbnail-group [imageUrl]="announcement.company?.companyLogoUrl">
        </app-thumbnail-group>

        <div class="tw-flex-1 tw-pr-1">
          <div class="tw-flex tw-flex-col tw-justify-between sm:tw-flex-row">
            <div class="tw-leading-none">
              <span class="tw-text-xs tw-font-bold tw-text-primary">
                {{ announcement.company?.name }}
              </span>
              <!-- Show highlight only for news that is within last 7 days -->
              <span *ngIf="announcement.timeDifference <= 7" class="dot--glowing tw-ml-1.5"></span>
            </div>
            <div class="tw-line-clamp-1 tw-font-semibold tw-text-black sm:tw-hidden">
              <span>{{ announcement.title }}</span>
            </div>
            <ul
              class="list--dot tw-mb-0 tw-flex tw-flex-col tw-pl-0 tw-pt-1 tw-text-xs tw-text-body tw-text-opacity-70 sm:tw-flex-row sm:!tw-flex-nowrap sm:tw-whitespace-nowrap"
            >
              <li>
                {{
                  'mysec.changesInCategory'
                    | translate
                      : {
                          category:
                            (AnnouncementCategory[announcement.category]
                              ? 'mysec.' + AnnouncementCategory[announcement.category]
                              : 'common.others'
                            ) | translate,
                        }
                }}
              </li>

              <li *ngIf="announcement?.timeDifference <= 7; else normalTimeDifference">
                <a
                  #announcementDate="matTooltip"
                  (click)="announcementDate.toggle()"
                  class="btn-transparent hover:tw-text-body-dark"
                  matTooltip="{{ announcement.publishedAt | amDateFormat: 'DD MMM YYYY' }}"
                >
                  <span>{{ announcement?.formattedTimeDifference }}</span>
                </a>
              </li>

              <ng-template #normalTimeDifference>
                <li>{{ announcement?.formattedTimeDifference }}</li>
              </ng-template>
            </ul>
          </div>
          <div class="tw-hidden tw-font-semibold tw-text-black sm:tw-line-clamp-1">
            <span>{{ announcement.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #announcementBody let-announcement="announcement">
  <p
    class="tw-mb-0 tw-mt-4 tw-line-clamp-2 tw-text-base tw-text-body tw-text-opacity-70 max-md:tw-mt-0"
  >
    <span [innerHTML]="renderContent(announcement.body)"></span>
  </p>
  <a
    [routerLink]="['/pstx', announcement.tickerCode]"
    [queryParams]="{ selectedTab: 6, id: announcement.id }"
    target="_blank"
    class="p-link-secondary tw-mt-2.5 tw-inline-block tw-text-sm md:tw-hidden"
  >
    {{ 'common.readMore' | translate }} →
  </a>
</ng-template>
