<main
  class="container-fluid tw-flex tw-h-full tw-min-h-[35rem] tw-flex-col tw-items-center tw-gap-8 tw-py-12"
>
  <a
    class="tw-flex tw-items-center tw-gap-4 tw-self-start tw-bg-transparent"
    routerLink="/profile/settings/security"
  >
    <i class="fa fa-arrow-left" aria-hidden="true"></i>
    <p class="tw-m-0 tw-font-semibold tw-text-black hover:tw-underline">
      {{ 'common.back' | translate }}
    </p>
  </a>
  <div class="tw-flex tw-flex-1 tw-items-center tw-justify-center">
    <app-two-factor-auth-input (otpChange)="handleRemove($event)"></app-two-factor-auth-input>
  </div>
</main>
