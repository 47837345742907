import { Component } from '@angular/core';

@Component({
  selector: 'app-e-kyc-in-progress',
  templateUrl: './e-kyc-in-progress.component.html',
  styleUrls: ['./e-kyc-in-progress.component.scss'],
})
export class EKycInProgressComponent {
  constructor() {}
}
