import { ClosingPriceModel } from './closing-price.model';

export class ClosingPrice {
  price: number;
  date: string;

  constructor(model: ClosingPriceModel) {
    this.price = model.closingPrice;
    this.date = model.date;
  }
}
