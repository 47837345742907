import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CampaignInfo } from '../../../core/models/campaign/campaign-info';
import { CampaignStatus } from '../../../core/enum/campaign-status.enum';

@Component({
  selector: 'campaign-whats-new',
  templateUrl: './campaign-whats-new.component.html',
  styleUrls: ['./campaign-whats-new.component.scss'],
})
export class CampaignWhatsNewComponent implements OnChanges {
  @Input() campaignInfo: CampaignInfo;
  @Input() campaignStatus: CampaignStatus;

  @Output() changeTab: EventEmitter<{
    tabIndex: number;
    event?: PointerEvent;
  }> = new EventEmitter();

  isVisible: boolean = false;

  CampaignStatus: typeof CampaignStatus = CampaignStatus;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['campaignInfo']) {
      const whatsNew = this.campaignInfo?.campaignWhatsNew;

      this.isVisible =
        (whatsNew?.isNewLargestInvestment && this.getInvestmentPledgedCondition()) ||
        whatsNew?.newEventCount > 0 ||
        whatsNew?.questionCount > 0 ||
        whatsNew?.upcomingEventCount > 0 ||
        whatsNew?.updateCount > 0;
    }
  }

  handleChangeTab(tabIndex, event?: PointerEvent) {
    this.changeTab.emit({ tabIndex, event });
  }

  getInvestmentPledgedCondition() {
    return (
      this.campaignStatus !== CampaignStatus.PreLive &&
      this.campaignStatus !== CampaignStatus.Rejected
    );
  }
}
