import { Component, DEFAULT_CURRENCY_CODE, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ReceiptType } from '../../../../../core/enum/receipt-type.enum';
import { Receipt } from '../../../../../core/models/payment/receipt';
import { PaymentService } from '../../../../../core/services/api/payment.service';
import { ErrorService } from '../../../../../core/services/error.service';
import { InvestmentService } from '../../../../../core/services/api/investment.service';
import { GtmDataLayerService } from '../../../../../core/services/gtm-data-layer.service';

@Component({
  selector: 'app-payment-receipt',
  templateUrl: './payment-receipt.component.html',
  styleUrls: ['./payment-receipt.component.scss'],
})
export class PaymentReceiptComponent implements OnInit {
  @Input() paymentGatewayName: 'FPX' | 'DuitNow' = 'FPX';

  transactionId: string;
  receipt: Receipt;
  isLoading = false;
  receiptType: ReceiptType;

  ReceiptType = ReceiptType;

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorService: ErrorService,
    private paymentService: PaymentService,
    private investmentService: InvestmentService,
    private _gtmDataLayerService: GtmDataLayerService,
    @Inject(DEFAULT_CURRENCY_CODE) private defaultCurrencyCode: string,
  ) {}

  ngOnInit() {
    this.transactionId = this.activatedRoute.snapshot.params.transactionId;
    this.receiptType = this.activatedRoute.snapshot.params.receiptType;
    this.paymentGatewayName = this.activatedRoute.snapshot?.url[0]?.path.includes('duitnow')
      ? 'DuitNow'
      : 'FPX';

    this.getTransactionDetail();
  }

  getTransactionDetail() {
    this.isLoading = true;
    let observable =
      this.receiptType == ReceiptType.Investment
        ? this.paymentService.getTransactionDetail(this.transactionId)
        : this.paymentService.getTransactionWalletDetails(this.transactionId);

    observable.pipe(finalize(() => (this.isLoading = false))).subscribe({
      next: (success) => {
        this.receipt = success;
        this.getInvestmentDetail(success.refNo);
      },
      error: (err) => {
        this.errorService.showError(err);
      },
    });
  }

  getInvestmentDetail(sellerOrderNo) {
    this.investmentService.getInvestmentDetail(sellerOrderNo).subscribe(
      (data) => {
        const { company, campaign, investment } = data;

        if (!campaign || !investment) return;

        this._gtmDataLayerService.logPurchase({
          currency: this.defaultCurrencyCode.trim(),
          value: investment?.pledgedAmount,
          transaction_id: investment?.referenceNumber,
          items: [
            {
              item_name: company?.brandName,
              item_id: campaign?.id,
              item_category: 'ecf',
              price: investment?.pledgedAmount
                ? investment?.pledgedAmount / investment?.lotQuantity
                : 0,
              quantity: investment?.lotQuantity,
            },
          ],
        });
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  print() {
    window.print();
  }
}
