import { Component } from '@angular/core';

@Component({
  selector: 'app-partnership',
  templateUrl: './partnership.component.html',
  styleUrls: ['./partnership.component.scss'],
})
export class PartnershipComponent {
  partners = [
    {
      name: 'Women in Tech',
      src: 'women-in-tech-card.png',
    },
    {
      name: 'Watch Tower & Friends',
      src: 'wtf-card.png',
    },
    {
      name: 'MRANTI',
      src: 'mranti-card.png',
    },
    {
      name: 'Beyond4',
      src: 'beyond4-card.png',
    },
    {
      name: 'Broku',
      src: 'broku-card.png',
    },
  ];

  doublePartners = [...this.partners, ...this.partners];
}
