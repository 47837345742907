import { Component, Input } from '@angular/core';

@Component({
  selector: 'agmo-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() buttonClass: string = 'btn-primary';
  @Input() buttonLabel: string;
  @Input() buttonIcon: string;
  @Input() suffixButtonIcon: string;
  @Input() disabled: boolean = false;
  @Input() loading: boolean = true;

  constructor() {}
}
