import { PaymentMethod } from './payment-method';

export class PaymentMethodModel {
  bankId: number;
  bankName: string;
  accountNumber: string;
  accountHolder: string;
  amount: number;

  constructor(model: PaymentMethod) {
    this.bankId = model.bankId;
    this.accountNumber = model.accountNumber;
    this.accountHolder = model.accountHolder;
    this.bankName = model.bankName;
    this.amount = model.amount;
  }
}
