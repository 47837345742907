import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BusinessDirectoryInfo } from '../../../../../core/models/business-directory/business-directory-info';

@Component({
  selector: 'app-business-info',
  templateUrl: './business-info.component.html',
  styleUrls: ['./business-info.component.scss'],
})
export class BusinessInfoComponent {
  @Input() businessDirectory: BusinessDirectoryInfo;
  @Output() scrollChange = new EventEmitter();

  constructor() {}

  onSectionChange(route: string) {
    if (!route) return;

    this.scrollChange.emit(route);
  }
}
