import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { DefaultBanner, DefaultLogo } from '../../../../core/services/constant';
import * as moment from 'moment';

@Component({
  selector: 'app-tcf-campaign-card',
  templateUrl: './tcf-campaign-card.component.html',
  styleUrls: ['./tcf-campaign-card.component.css'],
})
export class TcfCampaignCardComponent implements OnInit {
  tcfBaseUrl: string = environment.ieoBaseUrl;

  defaultLogo = DefaultLogo;
  defaultBanner = DefaultBanner;

  @Input() campaign: any;
  @Input() campaignStatus: string;

  accumulatedInvestmentFiat: number;
  minFundingTarget: number;

  ngOnInit() {
    this.accumulatedInvestmentFiat = this.campaign.progress?.fiatAmount / 100 || 0;
    this.minFundingTarget =
      (Number(this.campaign.offer.fundingGoal) * this.campaign.offer.pricePerToken) / 100 || 0;
  }

  calculateProgress(percentage: number): number {
    return percentage > 100 ? 100 : percentage;
  }

  calculateDayLeft(selectedDateTime: Date) {
    const now = moment(new Date());
    const end = moment(selectedDateTime);
    return end.diff(now, 'days');
  }

  addDecimalPlace(num: number) {
    if (num >= 100) {
      return '1.0-0';
    }
    return '1.2-2';
  }

  getPercentage() {
    const percentage = (this.accumulatedInvestmentFiat / this.minFundingTarget) * 100 || 0;

    if (percentage > 0 && percentage < 1) {
      return 1;
    }

    if (percentage < 99) {
      return Math.round(percentage);
    }

    if (percentage < 100) {
      return 99;
    }

    return percentage;
  }
}
