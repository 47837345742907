import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { getRegionCodeForCountryCode, parsePhoneNumber } from 'awesome-phonenumber';
import { Observable, of } from 'rxjs';

export class FormValidators {
  // ? Pass country code in implemented class to support multiple region phone numbers.
  static countryCode(
    controlName: string,
    countryCodeControlName: string,
    skip?: string,
  ): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      const control = group.get(controlName);
      if (!control) {
        return { controlNotFound: true };
      }

      const countryCodeControl = group.get(countryCodeControlName);
      if (!countryCodeControl) {
        return { controlNotFound: true };
      }

      if (control?.errors?.required) {
        return control.errors;
      }

      const region = getRegionCodeForCountryCode(countryCodeControl.value);
      let validNumber = true;

      if (control.value) {
        try {
          const parsedPhoneUtil = parsePhoneNumber(control.value.toString(), {
            regionCode: region,
          });
          validNumber = parsedPhoneUtil.valid;
        } catch (e) {}
      }
      const error = validNumber ? null : { phoneInvalidFormat: true };
      control.setErrors(error);
      return error;
    };
  }

  static minYearValidator(minYear: number): ValidatorFn {
    return (group: AbstractControl): Observable<ValidationErrors | null> => {
      const selectedDate: Date = group.value;
      const minDate: Date = new Date(minYear - 1, 11, 31);

      if (selectedDate < minDate) {
        group.setErrors({ minYear: true });
        return of({ minYear: true });
      }

      return of(null);
    };
  }
}
