import { WalletTransactionModel } from './wallet-transaction.model';

export class WalletTransaction {
  amount: number;
  createdAt: string;
  creditFlow: number;
  event: number;
  id: number;
  mySecOrderContractId: number;
  orderNo: string;
  referenceNumber: string;
  status: number;
  walletId: number;

  constructor(model: WalletTransactionModel) {
    this.amount = model.amount;
    this.createdAt = model.createdAt;
    this.creditFlow = model.creditFlow;
    this.event = model.event;
    this.id = model.id;
    this.mySecOrderContractId = model.mySecOrderContractId;
    this.orderNo = model.orderNo;
    this.referenceNumber = model.referenceNumber;
    this.status = model.status;
    this.walletId = model.walletId;
  }
}
