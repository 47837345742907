<div class="container-fluid">
  <ng-container *ngIf="step === 1">
    <div class="row justify-content-center mt-5 mb-5">
      <div class="col-md-10 col-lg-6">
        <h3 class="font-weight-extrabold text-center text-black">
          {{
            (receiptType == ReceiptType.Investment
              ? 'investment.choosePayment'
              : 'mysec.chooseTopUp'
            ) | translate
          }}:
        </h3>
      </div>
    </div>
    <app-payment-process
      *ngIf="!isLoadingInvestmentInfo"
      [isLoading]="isLoading"
      [isPSTX]="isPSTX"
      [isDuitNowEnabled]="isDuitNowEnabled"
      [receiptType]="receiptType"
      [campaign]="campaign"
      [investment]="investment"
      [document]="document"
      [paymentAmount]="amount"
      [referenceNo]="refNo"
      [lotAmount]="investment?.lotQuantity"
      [continueLink]="continueLink"
      (choosePayment)="setPayment($event)"
      (nextStep)="nextStep($event)"
      (uploadReceipt)="uploadReceipt($event)"
    ></app-payment-process>
  </ng-container>

  <ng-container *ngIf="step === 2">
    <app-fpx-payment
      *ngIf="selectedPaymentType === PaymentType.MaybankFPX"
      [paymentAmount]="amount"
      (choosePayment)="setPayment($event)"
    >
    </app-fpx-payment>

    <app-duitnow-payment
      *ngIf="selectedPaymentType === PaymentType.DuitNow"
      [paymentAmount]="amount"
      (choosePayment)="setPayment($event)"
    >
    </app-duitnow-payment>
  </ng-container>

  <agmo-loading *ngIf="isLoadingInvestmentInfo"></agmo-loading>
</div>
