<section class="section tw-pb-0">
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-lg-12">
        <h1 class="title font-weight-bold text-dark text-center">
          {{ 'pitchin.aboutPitchin' | translate }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 offset-md-2 tw-text-center">
        <p class="desc mb-5">{{ 'pitchin.aboutPitchinDesc' | translate }}</p>
      </div>
    </div>
    <img
      src="assets/img/banner/about-pitchin.jpg"
      class="tw-mx-auto tw-block tw-w-[800px] tw-max-w-full tw-rounded-lg"
      alt="pitchIN co-founders"
    />
  </div>
</section>

<section class="-tw-mt-[20%]">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path
      fill="#C00000"
      fill-opacity="1"
      d="M0,224L60,202.7C120,181,240,139,360,138.7C480,139,600,181,720,181.3C840,181,960,139,1080,122.7C1200,107,1320,117,1380,122.7L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
    ></path>
  </svg>
  <div class="-tw-mt-1 tw-bg-[#C00000] tw-pb-40 tw-pt-20">
    <div class="container-fluid tw-text-white">
      <div class="tw-grid tw-gap-10 lg:tw-grid-cols-2 lg:tw-items-center">
        <div>
          <h2 class="tw-font-bold tw-text-white">{{ 'pitchin.aboutPitchinStory' | translate }}</h2>

          <p>{{ 'pitchin.aboutPitchinDesc2' | translate }}</p>

          <ul class="timeline tw-mb-20">
            <li class="timeline__item">
              <span class="timeline__heading">2012</span>
              <div>{{ 'pitchin.aboutPitchinStory2012' | translate }}</div>
            </li>
            <li class="timeline__item">
              <span class="timeline__heading">2015</span>
              <div>{{ 'pitchin.aboutPitchinStory2015' | translate }}</div>
            </li>
            <li class="timeline__item">
              <span class="timeline__heading">2021</span>
              <div>{{ 'pitchin.aboutPitchinStory2021' | translate }}</div>
            </li>
            <li class="timeline__item">
              <span class="timeline__heading">2022</span>
              <ul
                class="tw-flex tw-flex-col tw-gap-2 tw-pl-5"
                [innerHTML]="'pitchin.aboutPitchinStory2022' | translate"
              ></ul>
            </li>
          </ul>
        </div>

        <div class="tw-relative">
          <img
            alt="dots"
            src="assets/img/banner/dots.svg"
            class="tw-absolute -tw-right-4 -tw-top-12 tw-w-16 lg:-tw-right-12 lg:tw-w-24"
          />
          <img
            src="assets/img/banner/about-entrepreneurs.jpg"
            class="tw-block tw-w-full tw-rounded-lg"
            alt="pitchin story"
          />
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section tw-bg-slate-50 tw-pt-10">
  <div class="container -tw-mt-[250px] lg:-tw-mt-[200px]">
    <div class="tw-relative tw-rounded-lg tw-bg-white tw-p-8 tw-shadow-lg lg:tw-p-14">
      <div class="text-center">
        <h3 class="mb-3 tw-text-xl tw-font-bold tw-uppercase tw-text-primary">
          {{ 'pitchin.ourMission' | translate }}
        </h3>
        <p class="lg:tw-text-3xl" [innerHTML]="'pitchin.ourMissionDesc' | translate"></p>
      </div>
    </div>
  </div>

  <div class="container tw-pb-10 tw-pt-20">
    <div class="row">
      <div class="col-lg-10">
        <h2 class="tw-mb-8 tw-font-bold">{{ 'pitchin.empowerEntrepreneurs' | translate }}</h2>

        <p>{{ 'pitchin.empowerEntrepreneursDesc1' | translate }}</p>

        <p>{{ 'pitchin.empowerEntrepreneursDesc2' | translate }}</p>
      </div>
    </div>
  </div>
</section>

<section class="section tw-bg-slate-200 tw-py-20">
  <div class="container text-center">
    <h2 class="tw-mb-10 tw-font-bold">{{ 'pitchin.ourInvestors' | translate }}</h2>
    <div class="tw-grid sm:tw-grid-cols-3 md:tw-gap-10">
      <div>
        <img
          src="assets/img/banner/investor-322-ecf.png"
          alt="322 ECF Investors"
          class="tw-max-w-[90%]"
        />
      </div>

      <div><img src="assets/img/banner/investor-mtdc.png" alt="MTDC" class="tw-max-w-[90%]" /></div>

      <div>
        <img
          src="assets/img/banner/investor-gobi-partners.png"
          alt="Gobi Partners"
          class="tw-max-w-[90%]"
        />
      </div>
    </div>
  </div>
</section>

<section class="section tw-pt-16 lg:tw-py-20">
  <div class="container-fluid">
    <div class="row text-center">
      <div class="col-md-10 offset-md-1">
        <h2 class="tw-mb-8 tw-font-bold">{{ 'pitchin.ourLeadership' | translate }}</h2>

        <p>{{ 'pitchin.ourLeadershipDesc' | translate }}</p>
      </div>

      <div class="col-md-10 offset-md-1 tw-flex tw-flex-wrap tw-justify-center">
        <div
          class="person tw-flex-[0_0_33.33%] tw-px-2 md:tw-flex-[0_0_33.33%] md:tw-px-5"
          *ngFor="let person of team; index as i"
          (click)="openModal(carousel, i)"
          [class.active]="activeSelection == person"
        >
          <div class="team-icon" [style.background-image]="'url(' + person.imageUrl + ')'"></div>
          <div class="text-center mt-3 name">
            <span class="tw-font-semibold">{{ person.name }}</span>
            <div class="tw-mt-1 tw-text-[10px] tw-uppercase tw-text-body tw-text-opacity-70">
              {{ person.position }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #carousel let-modal>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill="#444"
        d="M32 2.8L29.1 0 16 13.2 2.8 0 0 2.8 13.2 16 0 29.1 2.8 32 16 18.8 29.1 32l2.9-2.9L18.8 16"
      ></path>
    </svg>
  </button>
  <ngb-carousel class="team-carousel" [activeId]="activeSelection">
    <ng-template ngbSlide *ngFor="let person of team; index as i" [id]="i + 1">
      <div class="tw-max-h-[65vh] tw-overflow-auto tw-px-20 tw-py-8 lg:tw-max-h-fit">
        <div class="row">
          <div class="col-lg-3">
            <div class="person-photo tw-w-28 lg:tw-w-full">
              <span class="frame" [style.background-image]="'url(' + person.imageUrl + ')'"></span>
            </div>
          </div>
          <div class="col-lg-9">
            <h3 class="mt-3 mb-0">{{ person.name }}</h3>
            <h5 class="text-black-50">{{ person?.position }}</h5>
            <p class="mt-4" [innerHTML]="person.bioDetail"></p>
          </div>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
</ng-template>

<section class="section">
  <div class="container-fluid">
    <div class="row tw-items-center">
      <div class="col-md-5">
        <h3 class="tw-mb-8 tw-font-bold lg:tw-text-4xl">{{ 'pitchin.journey' | translate }}</h3>
        <p>{{ 'pitchin.journeyDesc1' | translate }}</p>
        <p>{{ 'pitchin.journeyDesc2' | translate }}</p>
      </div>
      <div class="col-md-7 tw-relative">
        <img
          alt="dots"
          src="assets/img/banner/dots.svg"
          class="tw-absolute -tw-bottom-8 -tw-left-8 tw-w-16"
        />
        <img
          src="assets/img/banner/about-journey.jpg"
          class="tw-max-w-full tw-rounded-lg"
          alt="pitchin workshop"
        />
      </div>
    </div>
  </div>
</section>

<section class="section tw-pb-40 lg:tw-pt-32">
  <div class="container text-center">
    <p>{{ 'pitchin.thankYou' | translate }}</p>
    <h3 class="tw-font-bold tw-text-black lg:tw-text-4xl">
      {{ 'pitchin.makeThingsHappen' | translate }}
    </h3>
  </div>
</section>
