<ng-container *ngIf="profile && profile.isMYsecAvailable; else noRegistry">
  <page name="raise.shareholdersDashboard">
    <app-ticker-code></app-ticker-code>
    <app-shareholder-information [tickerCodes]="tickerCodes"></app-shareholder-information>
    <app-share-movement-tracker [tickerCodes]="tickerCodes"></app-share-movement-tracker>
  </page>
</ng-container>

<ng-template #noRegistry>
  <agmo-loading *ngIf="isLoading"></agmo-loading>

  <div *ngIf="!isLoading" class="container-fluid">
    <div class="tw-p-5 tw-pt-10 tw-text-center">
      <ng-container *ngIf="isIssuerHasCampaign === true; else noCampaignCreated">
        <span [innerHTML]="'mysec.noDigitalRegistry' | translate"></span>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #noCampaignCreated>
  <span [innerHTML]="'mysec.noCampaignCreated' | translate"></span>
</ng-template>
