<section class="container-fluid">
  <div class="tab-content">
    <div class="row mb-5">
      <div class="col-md-8">
        <div class="d-flex">
          <div
            *ngFor="let type of InvestmentType | enumToArray"
            class="type-select"
            [class.active]="type.key == selectedType"
            (click)="changeTab(type.key)"
          >
            {{ type.value }}
          </div>
        </div>
      </div>
      <div class="col-md-4 text-primary my-application font-weight-bold d-flex align-items-center">
        <span class="cursor-pointer" routerLink="/raise/my-application">{{
          'raise.myApplications' | translate
        }}</span>
        <div
          class="pitchin-icon pitchin-icon-next-arrow ml-3"
          routerLink="/raise/my-application"
        ></div>
      </div>
    </div>
    <ng-container *ngIf="selectedType == InvestmentType.ECF">
      <agmo-loading *ngIf="isLoading"></agmo-loading>
      <div
        class="row justify-content-center no-campaign"
        *ngIf="!isLoading && campaigns.length == 0"
      >
        <div class="col-lg-4 col-md-6 text-center">
          <img
            class="w-50"
            alt="Empty Placeholder"
            src="../../../assets/img/brand/mycampaign_ecf_placeholder_1@2x.png"
          />
          <div
            class="my-3 font-size-larger"
            [innerHTML]="'raise.raiseBusinessAndGrow' | translate"
          ></div>
          <div class="btn btn-primary-border btn-learn-more" routerLink="/raise">
            {{ 'common.learnMore' | translate }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6" *ngFor="let campaign of campaigns">
          <div class="campaign">
            <div class="action-panel">
              <div
                class="campaign-status"
                *ngIf="campaign.campaignStatus"
                [class.success]="campaign.campaignStatus == CampaignStatus.Success"
                [class.live]="
                  [CampaignStatus.PreLive, CampaignStatus.Live].includes(campaign.campaignStatus)
                "
                [class.cooling-off]="campaign.campaignStatus == CampaignStatus.Cooling"
                [class.fail]="
                  [
                    CampaignStatus.Fail,
                    CampaignStatus.Rejected,
                    CampaignStatus.NotApplicable,
                  ].includes(campaign.campaignStatus)
                "
                [class.in-progress]="
                  [CampaignStatus.InProcess, CampaignStatus.New].includes(campaign.campaignStatus)
                "
                [class.share-allotment]="
                  [CampaignStatus.ShareAllotment].includes(campaign.campaignStatus)
                "
              >
                {{ 'campaign.' + CampaignStatus[campaign.campaignStatus] | translate }}
              </div>
              <div class="action" ngbDropdown placement="bottom-right">
                <div
                  class="pitchin-icon pitchin-icon-more no-caret"
                  ngbDropdownToggle
                  *ngIf="
                    [
                      CampaignStatus.PreLive,
                      CampaignStatus.Live,
                      CampaignStatus.Success,
                      CampaignStatus.Fail,
                      CampaignStatus.ShareAllotment,
                    ].includes(campaign.campaignStatus)
                  "
                ></div>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <div
                    class="dropdown-item font-size-regular font-weight-bold"
                    [routerLink]="['/equity', campaign.slug]"
                  >
                    {{ 'investment.campaignPage' | translate }}
                  </div>
                  <div
                    class="dropdown-item font-size-regular font-weight-bold"
                    [routerLink]="['investor-list', campaign.slug]"
                  >
                    {{ 'investment.investorList' | translate }}
                  </div>
                  <ng-container
                    *ngIf="
                      [CampaignStatus.PreLive, CampaignStatus.Live].includes(
                        campaign.campaignStatus
                      )
                    "
                  >
                    <div
                      class="dropdown-item font-size-regular font-weight-bold d-flex"
                      [routerLink]="['edit-campaign/campaign', campaign.id]"
                      *ngIf="
                        !campaign.campaignDetailApprovalStatus ||
                        ![
                          CampaignDetailApprovalStatus.Pending,
                          CampaignDetailApprovalStatus.Rejected,
                        ].includes(campaign.campaignDetailApprovalStatus)
                      "
                    >
                      {{ 'investment.editCampaign' | translate }}
                      <div
                        class="custom-badge blue-background ml-2"
                        *ngIf="
                          campaign.campaignDetailApprovalStatus ==
                          CampaignDetailApprovalStatus.Draft
                        "
                      >
                        {{ 'raise.Draft' | translate }}
                      </div>
                    </div>
                    <div
                      class="dropdown-item font-size-regular font-weight-bold d-flex"
                      [routerLink]="'campaign-draft/' + campaign.id"
                      *ngIf="
                        campaign.campaignDetailApprovalStatus ==
                        CampaignDetailApprovalStatus.Pending
                      "
                    >
                      {{ 'investment.viewDraft' | translate }}
                      <div class="custom-badge yellow-background ml-2">
                        {{ 'ekyc.Pending' | translate }}
                      </div>
                    </div>
                    <div
                      class="dropdown-item font-size-regular font-weight-bold d-flex"
                      [routerLink]="[
                        'campaign-draft-reject',
                        campaign.fundRaiseApplicationId,
                        campaign.id,
                      ]"
                      *ngIf="
                        campaign.campaignDetailApprovalStatus ==
                        CampaignDetailApprovalStatus.Rejected
                      "
                    >
                      {{ 'investment.editCampaign' | translate }}
                      <div class="custom-badge red-background ml-2">
                        {{ 'raise.Rejected' | translate }}
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="d-flex mb-3">
              <img
                class="logo-image"
                [alt]="campaign.name"
                [src]="campaign.company?.companyLogoUrl || defaultLogo"
              />
            </div>
            <div class="d-flex mb-3">
              <div class="campaign-title text-break">{{ campaign.name }}</div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="campaign-label">
                  {{ 'campaign.investmentAmount' | translate }}
                </div>
                <div class="campaign-amount larger-text text-green">
                  {{ (campaign.bankedInAmount | numberSuffix) || '-' }}
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="campaign-label">
                  {{ 'campaign.investorsNo' | translate }}
                </div>
                <div class="campaign-amount larger-text text-green">
                  {{ campaign.investorCount }}
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="campaign-label">
                  {{ 'campaign.awaitingPayment' | translate }}
                </div>
                <div class="campaign-amount larger-text text-green">
                  {{ (campaign.fundedAmount | numberSuffix) || '-' }}
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="campaign-label">
                  {{ 'campaign.investorsNo' | translate }}
                </div>
                <div class="campaign-amount larger-text text-green">
                  {{ campaign.pledgedInvestorCount }}
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="campaign-label">
                  {{ 'campaign.largestInvestmentAmount' | translate }}
                </div>
                <div class="campaign-amount text-green">
                  {{ (campaign.largestInvestmentAmount | numberSuffix) || '-' }}
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="campaign-label">
                  {{ 'campaign.lastInvestmentAmount' | translate }}
                </div>
                <div class="campaign-amount text-green">
                  {{ (campaign.lastPaidAmount | numberSuffix) || '-' }}
                </div>
              </div>
              <div class="col-12">
                <div class="campaign-progress">
                  {{ campaign.raisedPercentage | number: '1.2-2' }}% -
                  {{
                    ((campaign.endAt | amDifference: today : 'days') > 0
                      ? (campaign.endAt | amDifference: today : 'days' : true)
                      : 0
                    ) | number: '1.0-0'
                  }}
                  {{ 'campaign.daysLeft' | translate }}
                </div>
                <div class="campaign-progress-bar">
                  <mat-progress-bar
                    mode="determinate"
                    height="10"
                    [value]="campaign.raisedPercentage"
                    [color]="campaign.raisedPercentage > 100 ? 'success' : ''"
                  >
                  </mat-progress-bar>
                </div>
              </div>
            </div>
            <div class="row border-top pt-4" *ngIf="campaign.showDetails">
              <div class="col-md-6 mb-3">
                <div class="campaign-label">
                  {{ 'campaign.minTargetAmount' | translate }}
                </div>
                <div class="campaign-amount text-black">
                  {{ (campaign.minimumTargetAmount | numberSuffix) || '-' }}
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="campaign-label">
                  {{ 'campaign.maxTargetAmount' | translate }}
                </div>
                <div class="campaign-amount text-black">
                  {{ (campaign.maximumTargetAmount | numberSuffix) || '-' }}
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="campaign-label">
                  {{ 'campaign.overSubscriptionAmount' | translate }}
                </div>
                <div class="campaign-amount text-black">
                  {{ (campaign.overSubscriptionAmount | numberSuffix) || '-' }}
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="campaign-label">
                  {{ 'campaign.minAmountForAPledge' | translate }}
                </div>
                <div class="campaign-amount text-black">
                  {{
                    (campaign.minimumLots * campaign.sharePerLot * campaign.pricePerShare
                      | numberSuffix) || '-'
                  }}
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="campaign-label">
                  {{ 'campaign.eachInvestmentLot' | translate }}
                </div>
                <div class="campaign-amount text-black">
                  {{ (campaign.sharePerLot * campaign.pricePerShare | numberSuffix) || '-' }}
                </div>
              </div>
            </div>
            <div class="more-details" (click)="showDetails(campaign)">
              {{ 'common.moreDetails' | translate }}
              <i
                class="fa ml-3"
                [class.fa-chevron-up]="campaign.showDetails"
                [class.fa-chevron-down]="!campaign.showDetails"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="total > params.take">
        <div class="col-lg-12">
          <div class="paging mt-3">
            <ngb-pagination
              [collectionSize]="total"
              [(pageSize)]="params.take"
              [(page)]="params.currentPage"
              (pageChange)="getList()"
              [maxSize]="5"
              [rotate]="true"
            >
              <ng-template ngbPaginationPrevious><i class="fa fa-chevron-left"></i></ng-template>
              <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
              <ng-template ngbPaginationNext><i class="fa fa-chevron-right"></i></ng-template>
            </ngb-pagination>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</section>
