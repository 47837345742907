<div class="tab-content">
  <div class="row tw-gap-y-6" *ngIf="!isLoading">
    <div class="col-xl-4 col-lg-6 col-md-6" *ngFor="let campaign of campaigns">
      <app-campaign-card [campaign]="campaign"></app-campaign-card>
    </div>
    <div class="col-12 pt-5 pb-5 text-center" *ngIf="!campaigns || campaigns.length === 0">
      <span [innerHTML]="noRecordText | translate" (click)="processLinks($event)"></span>
    </div>
  </div>
  <ng-container *ngIf="isLoading">
    <div class="tw-py-10">
      <agmo-loading></agmo-loading>
    </div>
  </ng-container>
  <div class="row" *ngIf="displayPagination && total > params.take">
    <div class="col-lg-12">
      <div class="paging mt-3">
        <ngb-pagination
          [collectionSize]="total"
          [maxSize]="5"
          [rotate]="true"
          [pageSize]="params.take"
          [(page)]="params.currentPage"
          (pageChange)="getList()"
        >
          <ng-template ngbPaginationPrevious><i class="fa fa-chevron-left"></i></ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
          <ng-template ngbPaginationNext><i class="fa fa-chevron-right"></i></ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>
