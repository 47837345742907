import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-validation-error-message',
  templateUrl: './validation-error-message.component.html',
  styleUrls: ['./validation-error-message.component.scss'],
})
export class ValidationErrorMessageComponent {
  @Input() control: FormControl = new FormControl();
  @Input() showFormError = false;
  @Input() inputName: string;
  @Input() messageForRequired: string = 'common.thisIsRequired';
  @Input() messageForInvalidFormat: string = 'common.invalidFormat';
  @Input() messageForNotMatch: string = 'common.inputNotMatch';
  @Input() messageForMinLength: string = 'common.inputInvalidMinLength';
  @Input() messageForMaxLength: string = 'common.inputInvalidMaxLength';
  @Input() messageForInputExists: string = 'common.inputExists';

  constructor() {}
}
