import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { CampaignStatus } from '../../../../core/enum/campaign-status.enum';
import { Campaign } from '../../../../core/models/campaign/campaign';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import { StorageService } from '../../../../core/services/storage.service';
import { Role } from '../../../../core/enum/role.enum';

@Component({
  selector: 'campaign-statistics',
  templateUrl: './campaign-statistics.component.html',
  styleUrls: ['./campaign-statistics.component.scss'],
})
export class CampaignStatisticsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('footerCta') footerCta: ElementRef;

  @Input() isLogin: boolean;
  @Input() campaign: Campaign;
  @Input() isInvestNowLoading: boolean;

  @Output() investNow = new EventEmitter();
  @Output() onInvestorCountLoaded = new EventEmitter();

  isIssuer: boolean = false;
  isLoading: boolean = true;
  isFooterSticky: boolean = false;
  isCtaDisabled: boolean = false;

  daysLeft: number = 0;
  daysLeftLabel: string;

  intervalId: any;

  CampaignStatus: typeof CampaignStatus = CampaignStatus;

  stats: {
    name: string;
    value: string | number;
    isCurrency?: boolean;
    show?: (campaign: Campaign) => boolean;
  }[];

  constructor(
    private translate: TranslateService,
    private storageService: StorageService,
    @Inject(PLATFORM_ID) private platform: Object,
  ) {}

  ngOnInit() {
    this.isIssuer = this.storageService.role == Role.IssuerRep;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['campaign']) {
      this.updateStats();

      // For live campaigns, will trigger countdown
      if (
        isPlatformBrowser(this.platform) &&
        this.campaign.campaignStatus === CampaignStatus.Live
      ) {
        this.triggerUpdateStats();
      }

      this.isCtaDisabled =
        ![CampaignStatus.PreLive, CampaignStatus.Live].includes(this.campaign?.campaignStatus) ||
        this.campaign?.isEnded ||
        (this.campaign?.isPrivate && !this.campaign?.isInvited);
    }
  }

  ngOnDestroy() {
    this.clearIntervalIfExist();
  }

  clearIntervalIfExist() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  handleInvestNow() {
    if (
      ![CampaignStatus.PreLive, CampaignStatus.Live].includes(this.campaign.campaignStatus) ||
      this.campaign.isEnded
    )
      return;

    this.investNow.emit();
  }

  // To write a scroll listener, if the window scroll beyond the element,
  // then the listener will be triggered
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (
      [
        CampaignStatus.Cooling,
        CampaignStatus.ShareAllotment,
        CampaignStatus.Success,
        CampaignStatus.Fail,
        CampaignStatus.Rejected,
      ].includes(this.campaign.campaignStatus)
    )
      return;

    const contentWrapper = document.getElementById('campaignContent');
    const footerCta = this.footerCta?.nativeElement;

    if (!contentWrapper || !footerCta) return;

    const isSticky = footerCta.classList.contains('is-sticky');
    const shouldBeSticky = window.scrollY >= contentWrapper.offsetTop;

    if (shouldBeSticky && !isSticky) {
      footerCta.classList.add('is-sticky');
    } else if (!shouldBeSticky && isSticky) {
      footerCta.classList.remove('is-sticky');
    }
  }

  triggerUpdateStats() {
    if (!this.intervalId) {
      this.intervalId = setInterval(() => {
        this.updateStats();
      }, 30000); // update 0.5 minute
    }
  }

  updateStats() {
    const endAt = moment(this.campaign?.endAt);
    const today = moment();
    const daysLeftInSeconds = endAt.diff(today, 'seconds');

    // To take into account of the time difference, and round up
    this.daysLeft = Number(daysLeftInSeconds / 86400);

    // > 1 day: use "x days"
    // = 1 day: use "1 day"
    // < 1 day : use "x hours"
    // < 1 hour: use "x mins"
    let daysLeftLabel = () => {
      if (daysLeftInSeconds >= 172800) {
        // >= 2days
        return `${this.daysLeft.toFixed(0)} ${this.translate.instant('campaign.days')}`;
      } else if (daysLeftInSeconds >= 86400) {
        // 1 day
        return `1 ${this.translate.instant('common.day')}`;
      } else if (daysLeftInSeconds >= 3600) {
        // 1 hour
        return `${endAt.diff(today, 'hours')} ${this.translate.instant('common.hours')}`;
      } else if (daysLeftInSeconds > 120) {
        return `${endAt.diff(today, 'minutes')} ${this.translate.instant('common.mins')}`;
      } else if (daysLeftInSeconds > 1) {
        // less than 1 minute left
        return `1 ${this.translate.instant('common.min')}`;
      } else {
        return `0 ${this.translate.instant('common.min')}`;
      }
    };

    this.daysLeftLabel = daysLeftLabel();

    this.stats = [
      {
        name: 'campaign.currentRaisedAmount',
        value: this.campaign.bankedInAmount ?? '0',
        isCurrency: true,
        show: (campaign: Campaign) => {
          return (
            campaign.campaignStatus !== CampaignStatus.PreLive &&
            campaign.campaignStatus !== CampaignStatus.Rejected
          );
        },
      },
      {
        name: 'campaign.minTarget',
        value: this.campaign.minimumTargetAmount ?? '-',
        isCurrency: true,
        show: (campaign: Campaign) => {
          return (
            campaign.campaignStatus === CampaignStatus.PreLive ||
            campaign.campaignStatus === CampaignStatus.Rejected
          );
        },
      },
      {
        name: 'campaign.investors',
        value: this.campaign.investorCount ?? '-',
        show: (campaign: Campaign) => {
          return (
            campaign.campaignStatus !== CampaignStatus.PreLive &&
            campaign.campaignStatus !== CampaignStatus.Rejected
          );
        },
      },
      {
        name: 'campaign.largestInvestment',
        value: this.campaign.largestInvestmentAmount ?? '-',
        isCurrency: true,
        show: (campaign: Campaign) => {
          return (
            campaign.campaignStatus !== CampaignStatus.PreLive &&
            campaign.campaignStatus !== CampaignStatus.Rejected
          );
        },
      },
      {
        name: 'campaign.leftToGo',
        value: this.campaign?.endAt ? daysLeftLabel() : '-',
        show: (campaign: Campaign) => {
          return campaign.campaignStatus === CampaignStatus.Live;
        },
      },
      {
        name: 'campaign.coolingOffPeriodEndDate',
        value: this.campaign?.coolingPeriodEndAt
          ? moment(this.campaign?.coolingPeriodEndAt).format('D MMM YYYY')
          : '-',
        show: (campaign: Campaign) => {
          return (
            campaign.campaignStatus === CampaignStatus.Cooling ||
            campaign.campaignStatus === CampaignStatus.ShareAllotment
          );
        },
      },
    ];

    this.isLoading = false;
  }
}
