import { Order } from './order';
import { OrderBookModel } from './order-book.model';

export class OrderBook {
  bidOrderList: Order[];
  offerOrderList: Order[];

  constructor(order: OrderBookModel) {
    this.bidOrderList =
      order.buyOrderList && order.buyOrderList.length > 0
        ? order.buyOrderList.map((bidOrder) => new Order(bidOrder))
        : [];
    this.offerOrderList =
      order.sellOrderList && order.sellOrderList.length > 0
        ? order.sellOrderList.map((offerOrder) => new Order(offerOrder))
        : [];
  }
}
