import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ShareType } from '../../../core/enum/share-type.enum';
import { Campaign } from '../../../core/models/campaign/campaign';

@Component({
  selector: 'campaign-performance',
  templateUrl: './campaign-performance.component.html',
  styleUrls: ['./campaign-performance.component.scss'],
})
export class CampaignPerformanceComponent implements OnChanges {
  @Input() campaign: Campaign;

  campaignDetails: {
    wrapperClass: string;
    children: {
      name: string;
      value: string | number;
      isCurrency?: boolean;
    }[];
  }[];

  constructor(private translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['campaign']) this.updateCampaignPerformance();
  }

  updateCampaignPerformance() {
    this.campaignDetails = [
      {
        wrapperClass: 'tw-grid tw-space-y-2.5 lg:tw-grid-cols-2 lg:tw-space-y-0 lg:tw-gap-2',
        children: [
          {
            name: 'campaign.type',
            value: this.campaign?.campaignType ?? '-',
          },
          {
            name: 'campaign.equityOffered',
            value:
              `${this.translateService.instant(
                'campaign.upToPercent',
              )} ${this.campaign?.equityOffered?.toFixed(2)}%` ?? '-',
          },
          {
            name: 'investment.shareType',
            value: this.campaign?.shareType
              ? `${this.translateService.instant('mysec.' + ShareType[this.campaign?.shareType])}`
              : '-',
          },
          {
            name: 'campaign.taxRelief',
            value: this.campaign?.isAngelTaxIncentive ? 'ATI' : '-',
          },
        ],
      },
      {
        wrapperClass: 'tw-space-y-2.5',
        children: [
          {
            name: 'campaign.valuation',
            value: this.campaign?.valuationAmount,
            isCurrency: true,
          },
          {
            name: 'campaign.minimumFundingTarget',
            value: this.campaign?.minimumTargetAmount,
            isCurrency: true,
          },
          {
            name: 'campaign.totalTargetAmount',
            value: this.campaign?.maximumTargetAmount,
            isCurrency: true,
          },
        ],
      },
      {
        wrapperClass: 'tw-space-y-2.5',
        children: [
          {
            name: 'campaign.campaignStartDate',
            value: this.campaign?.startAt
              ? moment(this.campaign?.startAt).format('D MMM YYYY')
              : '-',
          },
          {
            name: 'campaign.campaignEndDate',
            value: this.campaign?.endAt ? moment(this.campaign?.endAt).format('D MMM YYYY') : '-',
          },
          {
            name: 'campaign.coolingOffPeriodEndDate',
            value: this.campaign?.coolingPeriodEndAt
              ? moment(this.campaign?.coolingPeriodEndAt).format('D MMM YYYY')
              : '-',
          },
        ],
      },
    ];
  }
}
