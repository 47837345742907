import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { PaymentType } from '../../../../core/enum/payment-type.enum';
import { AttachmentInfo } from '../../../../core/models/attachment/attachment-info';
import { FileUploadOption } from '../../../../core/models/attachment/file-upload-option';
import { Campaign } from '../../../../core/models/campaign/campaign';
import { CountryCode } from '../../../../core/models/country/country-code';
import { Edd } from '../../../../core/models/investment/edd';
import { InvestmentModel } from '../../../../core/models/investment/investment.model';
import { Item } from '../../../../core/models/share/item';
import { CampaignService } from '../../../../core/services/api/campaign.service';
import { SettingService } from '../../../../core/services/api/setting.service';
import { DefaultCountryCode, PDFFileFormat } from '../../../../core/services/constant';
import { CountriesService } from '../../../../core/services/countries.service';
import { ErrorService } from '../../../../core/services/error.service';
import { FileValidationService } from '../../../../core/services/file-validation.service';
import { FormValidators } from '../../../../core/validator/form.validators';
import { BlockUiService } from '../../../../shared/services/blockUi/block-ui.service';
import { PopupService } from '../../../../shared/services/popup/popup.service';

const othersId: number = 255;

@Component({
  selector: 'app-edd-form',
  templateUrl: './edd-form.component.html',
  styleUrls: ['./edd-form.component.scss'],
})
export class EddFormComponent implements OnInit {
  showFormError: boolean = false;
  form: UntypedFormGroup;
  isLoading: boolean = false;
  isLoadingWealth: boolean = false;
  isLoadingFund: boolean = false;
  accept: string = PDFFileFormat.toString();
  sourceOfWealthDoc: File;
  sourceOfWealthAttachmentInfo: AttachmentInfo = new AttachmentInfo();
  sourceOfFundsDoc: File;
  sourceOfFundAttachmentInfo: AttachmentInfo = new AttachmentInfo();
  countries: CountryCode[];
  slug: string;
  investmentId: number;
  investmentEddId: number;
  investmentEddFormId: number;
  campaign: Campaign;
  investment: InvestmentModel;
  edd: Edd;
  relationshipOfInvestorToTheIssuerType: Item[];
  sourceOfWealths: Item[];
  sourceOfFunds: Item[];
  eddNatureOfRelationshipWithThirdParty: Item[];
  eddReasonForUsingThirdPartyAccount: Item[];
  othersId: number = othersId;

  PaymentType: typeof PaymentType = PaymentType;

  constructor(
    private popupService: PopupService,
    private formBuilder: UntypedFormBuilder,
    private blockUiService: BlockUiService,
    private errorService: ErrorService,
    private campaignService: CampaignService,
    private activatedRoute: ActivatedRoute,
    private settingService: SettingService,
    private router: Router,
    private fileValidationService: FileValidationService,
    private countriesService: CountriesService,
  ) {}

  ngOnInit(): void {
    this.slug = this.activatedRoute.snapshot.params.slug;
    this.investmentId = this.activatedRoute.snapshot.params.investmentId;
    this.investmentEddId = this.activatedRoute.snapshot.params.investmentEddId;

    this.buildForm();
    this.countries = this.countriesService.getCountryList();
    this.isLoading = true;
    this.getEddDropdown();
    this.getCampaign();
  }

  buildForm() {
    this.form = this.formBuilder.group(
      {
        sourceOfWealths: [[], Validators.required],
        othersSourceOfWealths: [null],
        sourceOfFunds: [[], Validators.required],
        othersSourceOfFunds: [null],
        relationshipOfInvestorToTheIssuerType: ['', Validators.required],
        isThirdPartyAccount: [true, Validators.required],
        name: [''],
        phoneCountryCode: [DefaultCountryCode],
        phoneNumber: [''],
        identityNo: [''],
        address: [''],
        eddNatureOfRelationshipWithThirdParty: [null],
        eddNatureOfRelationshipWithThirdPartyOthers: [null],
        eddReasonForUsingThirdPartyAccount: [null],
        eddReasonForUsingThirdPartyAccountOthers: [null],
      },
      {
        validators: [FormValidators.countryCode('phoneNumber', 'phoneCountryCode')],
      },
    );

    this.updateDepositFormValidation(this.form.controls.isThirdPartyAccount.value);
  }

  getEddDropdown() {
    this.settingService.getEddDropdown().subscribe(
      (data) => {
        this.sourceOfWealths = data.sourceOfWealths;
        this.sourceOfFunds = data.sourceOfFunds;
        this.relationshipOfInvestorToTheIssuerType = data.relationshipOfInvestorToTheIssuerType;
        this.eddNatureOfRelationshipWithThirdParty = data.eddNatureOfRelationshipWithThirdParty;
        this.eddReasonForUsingThirdPartyAccount = data.eddReasonForUsingThirdPartyAccount;
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  getCampaign() {
    this.campaignService.getCampaign(this.slug).subscribe(
      (data) => {
        this.campaign = data;
        this.getInvestment();
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  getInvestment() {
    this.campaignService.getInvestment(this.campaign.id, this.investmentId).subscribe(
      (data) => {
        this.investment = data;
        this.investmentEddFormId = this.investment.investmentEddFormId;

        this.investmentEddFormId ? this.getEddForm() : (this.isLoading = false);

        if (this.investment) {
          this.changeValidity(true, 'eddNatureOfRelationshipWithThirdParty');
          this.changeValidity(true, 'eddReasonForUsingThirdPartyAccount');
        }
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  getEddForm() {
    this.campaignService
      .getEddForm(this.slug, this.investmentId, this.investmentEddId, this.investmentEddFormId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.edd = data;
          this.form.patchValue(this.edd);
          this.form.disable();
          this.updateDepositFormValidation(this.edd.isThirdPartyAccount);
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  onCheck(field: string, id: number, isChecked: boolean) {
    let data = this.form.get(field).value;

    isChecked ? data.push(id) : data.splice(data.indexOf(id), 1);

    this.form.controls[field].setValue(data);
  }

  async selectFile(type: number, file: File) {
    const uploadOption: FileUploadOption = {
      fileType: PDFFileFormat,
      errorMsg: 'common.onlyPdfAccepted',
    };

    if (await this.fileValidationService.validFile(file, uploadOption)) {
      if (type == 1) {
        this.isLoadingWealth = true;

        this.campaignService
          .uploadSourceOfWealthDocument(this.slug, this.investmentId, this.investmentEddId, file)
          .pipe(finalize(() => (this.isLoadingWealth = false)))
          .subscribe(
            (data) => {
              this.sourceOfWealthDoc = file;
              this.sourceOfWealthAttachmentInfo.attachmentInfo = new AttachmentInfo(data);
            },
            (err) => {
              this.errorService.showError(err);
              this.removeDocument(type);
            },
          );
      } else {
        this.isLoadingFund = true;

        this.campaignService
          .uploadSourceOfFundsDocument(this.slug, this.investmentId, this.investmentEddId, file)
          .pipe(finalize(() => (this.isLoadingFund = false)))
          .subscribe(
            (data) => {
              this.sourceOfFundsDoc = file;
              this.sourceOfFundAttachmentInfo.attachmentInfo = new AttachmentInfo(data);
            },
            (err) => {
              this.errorService.showError(err);
              this.removeDocument(type);
            },
          );
      }
    }
  }

  removeDocument(type: number) {
    if (type == 1) {
      this.sourceOfWealthDoc = null;
      this.sourceOfWealthAttachmentInfo = new AttachmentInfo();
    } else {
      this.sourceOfFundsDoc = null;
      this.sourceOfFundAttachmentInfo = new AttachmentInfo();
    }
  }

  submit() {
    if (
      this.form.invalid ||
      !this.sourceOfWealthAttachmentInfo.attachmentInfo ||
      !this.sourceOfFundAttachmentInfo.attachmentInfo
    ) {
      this.showFormError = true;
      return;
    }

    this.edd = this.form.value;
    this.edd.sourceOfWealthAttachmentInfo = this.sourceOfWealthAttachmentInfo;
    this.edd.sourceOfFundAttachmentInfo = this.sourceOfFundAttachmentInfo;

    this.blockUiService.open();

    this.campaignService
      .addEdd(this.slug, this.investmentId, this.investmentEddId, this.edd)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        () => {
          this.redirect();
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  changeValidity(isOther: boolean, otherControl: string) {
    if (isOther) {
      this.form.controls[otherControl].setValidators([Validators.required]);
    } else {
      this.form.controls[otherControl].setValue(null);
      this.form.controls[otherControl].clearValidators();
    }

    this.form.controls[otherControl].updateValueAndValidity();
  }

  confirmCancel() {
    this.popupService.confirm('raise.confirmCancel').then(
      () => {
        this.redirect();
      },
      () => {},
    );
  }

  redirect() {
    this.router.navigate(['/equity', this.slug], { queryParams: { selectedTab: 8 } });
  }

  viewDocument(url: string) {
    window.open(url);
  }

  updateDepositFormValidation(isThirdPartyAccount: boolean) {
    const formControlNames = [
      'name',
      'phoneCountryCode',
      'phoneNumber',
      'identityNo',
      'address',
      'eddNatureOfRelationshipWithThirdParty',
      'eddNatureOfRelationshipWithThirdPartyOthers',
      'eddReasonForUsingThirdPartyAccount',
      'eddReasonForUsingThirdPartyAccountOthers',
    ];

    formControlNames.forEach((control) => {
      this.setValidator(control, isThirdPartyAccount ? [Validators.required] : null);
    });

    if (isThirdPartyAccount) {
      this.form.controls['phoneCountryCode'].setValue(DefaultCountryCode);

      if (!this.investmentEddFormId) {
        this.setValidator('eddNatureOfRelationshipWithThirdPartyOthers', null);
        this.setValidator('eddReasonForUsingThirdPartyAccountOthers', null);
      }
    }
  }

  setValidator(field: string, validators?: ValidatorFn[]) {
    if (validators) {
      this.form.controls[field].setValidators(validators);
    } else {
      this.form.controls[field].setValue(null);
      this.form.controls[field].clearValidators();
    }
    this.form.controls[field].updateValueAndValidity();
  }
}
