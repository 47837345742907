<div class="tw-mt-10 tw-rounded-10px tw-border tw-border-divider tw-p-5 tw-text-center lg:tw-p-10">
  <div class="-tw-mt-20">
    <img
      class="logo-image tw-mb-4"
      [alt]="campaign.company?.brandName"
      [src]="campaign.company?.companyLogoUrl || defaultLogo"
    />

    <span class="p-text-h2 tw-mb-5 tw-block tw-text-black">
      {{ campaign.company?.brandName }}
    </span>

    <!-- Company Name -->
    <p *ngIf="campaign.company?.name" class="tw-font-semibold tw-text-black tw-opacity-95">
      {{ campaign.company?.name }}
    </p>

    <!-- Address -->
    <p>{{ campaign.company?.address || '-' }}</p>

    <!-- Registration Number (optional) -->
    <p *ngIf="campaign.company?.registrationNumber" class="tw-opacity-75">
      Reg No: {{ campaign.company?.registrationNumber }}
    </p>

    <!-- Website Url (optional) -->
    <p *ngIf="campaign.company?.websiteUrl">
      <a
        class="tw-font-bold tw-underline"
        [href]="campaign.company?.websiteUrl | weblink"
        target="_blank"
      >
        {{ campaign.company?.websiteUrl | weblink: 'true' }}
      </a>
    </p>

    <!-- Social Links (optional) -->
    <div
      class="tw-flex tw-justify-center tw-gap-2.5"
      *ngIf="campaign.company?.socialLinks.length > 0"
    >
      <ng-container *ngFor="let social of campaign.company?.socialLinks">
        <a [href]="social.url | weblink" target="_blank" *ngIf="social.url">
          <div
            class="tw-h-8 tw-w-8"
            [class.pitchin-icon-facebook]="social.type == SocialLinkType.Facebook"
            [class.pitchin-icon-twitter]="social.type == SocialLinkType.Twitter"
            [class.pitchin-icon-instagram]="social.type == SocialLinkType.Instagram"
            [class.pitchin-icon-linkedin]="social.type == SocialLinkType.LinkedIn"
            [class.pitchin-icon-tiktok]="social.type == SocialLinkType.TikTok"
          ></div>
        </a>
      </ng-container>
    </div>
  </div>
</div>
