import { ErrorService } from './../../core/services/error.service';
import { AccountService } from './../../core/services/api/account.service';
import { BlockUiService } from './../../shared/services/blockUi/block-ui.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  showFormError: boolean = false;
  email: string = null;
  isSent: boolean = false;

  @ViewChild('form', { read: NgForm }) form: NgForm;

  constructor(
    private blockUiService: BlockUiService,
    private accountService: AccountService,
    private errorService: ErrorService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((param) => {
      if (param.isSent) {
        this.isSent = true;
      }
      if (param.email) {
        this.email = param.email;
      }
    });
  }

  submit() {
    if (this.form.invalid) {
      this.showFormError = true;
      return;
    }

    this.forgotPassword();
  }

  forgotPassword() {
    this.blockUiService.open();

    this.accountService
      .forgotPassword(this.email)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        () => {
          this.isSent = true;
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }
}
