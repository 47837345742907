<div class="toast-container toast-top-right">
  <ngb-toast
    *ngFor="let toast of toastService.toasts"
    class="ngx-toastr"
    [class]="toast.classname"
    [autohide]="true"
    [delay]="toast.delay || 5000"
    (hidden)="toastService.remove(toast)"
  >
    <div class="toast-message">
      <span [innerHTML]="toast.body | trustSrc"></span>
    </div>
  </ngb-toast>
</div>
