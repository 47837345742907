<section class="section section-shaped">
  <div class="container">
    <div class="row mb-3">
      <div class="col-6" *ngIf="formType == FormType.Submission">
        <div class="btn btn-primary-border float-right font-size-larger" (click)="print()">
          {{ 'common.print' | translate }} <i class="fa fa-print ml-3"></i>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mb-5 print-include">
      <div class="col-lg-10" *ngIf="applicationForm$">
        <app-dynamic-form
          [dynamicForm]="applicationForm$ | async"
          [submitText]="submitText | translate"
          (submitForm)="submit($event)"
          [disableSubmit]="formType == FormType.Submission"
        >
        </app-dynamic-form>
        <div
          class="text-primary text-underline cursor-pointer back-button h5 tw-mt-5 tw-text-center"
          (click)="confirmBack()"
        >
          {{ 'common.back' | translate }}
        </div>
      </div>
      <div class="col-lg-10" *ngIf="!applicationForm$">
        <agmo-loading></agmo-loading>
      </div>
    </div>
  </div>
</section>
