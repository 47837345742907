<div
  *ngIf="campaign.campaignStatus != CampaignStatus.Success"
  [ngClass]="{
    'tw-border-t tw-border-divider':
      campaign.rssUrl ||
      campaign.isCreator ||
      (campaign.campaignFAQs && campaign.campaignFAQs.length > 0) ||
      (campaign.campaignRisks && campaign.campaignRisks.length > 0),
  }"
>
  <div class="section" *ngIf="campaign.rssUrl || campaign.isCreator" id="news">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <h3 class="p-text-h3 tw-mb-5">
            {{ 'landing.news' | translate }}
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-campaign-news
            [campaignId]="campaign.id"
            [isCreator]="campaign.isCreator"
            [rssUrl]="campaign.rssUrl"
          >
          </app-campaign-news>
        </div>
      </div>
    </div>
  </div>
  <div class="section" *ngIf="campaign.campaignFAQs && campaign.campaignFAQs.length > 0" id="faq">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <h3 class="p-text-h3 tw-mb-5">
            {{ 'campaign.faq' | translate }}
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-faqs [faqs]="campaign.campaignFAQs"></app-faqs>
        </div>
      </div>
    </div>
  </div>
  <div
    class="section"
    *ngIf="campaign.campaignRisks && campaign.campaignRisks.length > 0"
    id="risks"
  >
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <h3 class="p-text-h3 tw-mb-5">
            {{ 'campaign.risks' | translate }}
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-risks [risks]="campaign.campaignRisks"></app-risks>
        </div>
      </div>
    </div>
  </div>
</div>
