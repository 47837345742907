export enum RaiseFundApplicationActionType {
  None = 0,
  DynamicForm = 1,
  UploadDocument = 2,
  Payment = 3,
  EKYC = 4,
  UploadVideo = 5,
  CreateCampaign = 6,
  OfferCreation = 7,
  BonusShare = 8,
  UploadCampaignVideo = 9,
  FPXPayment = 10,
  UploadReceipt = 11,
  ApprovalRequired = 12,
  UploadMultipleFile = 13,
  Attachment = 14,
  NextScreen = 99,
}
