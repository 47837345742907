<div class="bg-network tw-text-white">
  <div class="container-fluid tw-relative tw-py-24">
    <div class="tw-mb-14 tw-text-center">
      <h2 class="tw-text-[2rem] tw-font-bold tw-leading-9 tw-text-white md:tw-text-5xl">
        {{ 'landing.ecosystem' | translate }}
      </h2>
    </div>

    <div class="tw-grid tw-gap-10 md:tw-grid-cols-3 md:tw-gap-20">
      <div class="tw-text-2xl">
        <div class="p-text-preheader tw-mb-2 tw-text-lg !tw-font-extralight tw-opacity-70">
          {{ 'pitchin.ourMission' | translate }}
        </div>
        <div class="tw-mb-5 tw-font-bold">{{ 'landing.ecosystemSubtitle' | translate }}</div>
        <p>{{ 'landing.ecosystemMainDesc' | translate }}</p>
        <div class="tw-text-lg">
          <a
            [routerLink]="['/about-us']"
            class="tw-pr-1 tw-text-inherit tw-underline hover:tw-text-inherit"
            >{{ 'footer.aboutUs' | translate }}</a
          >
          <span>→</span>
        </div>
      </div>

      <div class="ecosystem tw-col-span-2 tw-grid tw-gap-10 tw-text-white">
        <ul class="ecosystem-list">
          <li class="ecosystem-item">
            <a [routerLink]="['/']">
              <div class="tw-flex tw-justify-between">
                <h3 class="ecosystem-item__title">{{ 'landing.ecosystemPitchIN' | translate }}</h3>
                <span class="ecosystem-item__arrow">→</span>
              </div>
              <p class="p-text-p">{{ 'landing.ecosystemPitchINDesc' | translate }}</p>
            </a>
          </li>
          <li class="ecosystem-item">
            <a href="https://reward.pitchin.my/" target="_blank">
              <div class="tw-flex tw-justify-between">
                <h3 class="ecosystem-item__title">{{ 'landing.ecosystemRewards' | translate }}</h3>
                <span class="ecosystem-item__arrow">→</span>
              </div>
              <p class="p-text-p">{{ 'landing.ecosystemRewardsDesc' | translate }}</p>
            </a>
          </li>
          <li class="ecosystem-item">
            <a href="http://academy.pitchin.my/" target="_blank">
              <div class="tw-flex tw-justify-between">
                <h3 class="ecosystem-item__title">{{ 'landing.ecosystemAcademy' | translate }}</h3>
                <span class="ecosystem-item__arrow">→</span>
              </div>
              <p class="p-text-p">{{ 'landing.ecosystemAcademyDesc' | translate }}</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
