import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { StatusCodeResponseService } from 'src/app/core/services/status-code-response.service';
import { environment } from '../../../environments/environment';
import { AccountService } from '../../core/services/api/account.service';
import { SettingService } from '../../core/services/api/setting.service';
import { StorageService } from '../../core/services/storage.service';
import { PagedResult } from '../models/paged-result/paged-result';
import { HeaderService } from '../services/header/header.service';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private router: Router,
    private accountService: AccountService,
    private storageService: StorageService,
    private settingService: SettingService,
    private headerService: HeaderService,
    private statusCodeResponseService: StatusCodeResponseService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let params = new HttpParams({ fromString: req.params.toString() });

    if (req.url.indexOf(environment.apiServer) != -1 && !params.get('noToken')) {
      if (this.isRefreshing) {
        return this.returnRefreshTokenSubject(next, req);
      }

      if (!this.storageService.tokenExpiry || this.storageService.tokenExpiry.isBefore(moment())) {
        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);

        return this.returnRefreshTokenObservable(next, req);
      }
    }

    if (params.get('noToken')) {
      params = params.delete('noToken');

      req.clone({
        params: params,
        headers: req.headers,
        withCredentials: req.withCredentials,
      });
    }

    return next.handle(req).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          if (event.headers.get('x-total-count')) {
            return event.clone({
              body: new PagedResult(event.body, parseInt(event.headers.get('x-total-count'))),
            });
          }

          return event;
        }

        return event;
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            if (err.headers.get('token-expired')) {
              return this.handle401Error(req, next);
            } else if (
              err.error &&
              err.error.Error &&
              err.error.Error.Code &&
              err.error.Error.Code == 'TOKEN_EXPIRED_ERROR'
            ) {
              throw err.error;
            } else {
              this.clearTokenAndRedirectToLogin();

              if (err.error) throw err.error;
            }
          } else if (err.status === 403) {
            this.clearTokenAndRedirectToLogin();
          } else if (err.status === 404 && req.url.indexOf(environment.apiServer) == 0) {
            if (err.error?.error?.code.includes('OPEN404PAGE')) {
              this.headerService.setHeaderTag('pitchIN | 404 Page Not Found');
              this.settingService.notFoundPage.next(true);
              this.statusCodeResponseService.setNotFound();
              return throwError({});
            }
          }

          if (err.error) {
            throw err.error;
          }

          return throwError({});
        }

        return throwError({});
      }),
    );
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      return this.returnRefreshTokenObservable(next, req);
    } else {
      return this.returnRefreshTokenSubject(next, req);
    }
  }

  private clearTokenAndRedirectToLogin() {
    this.storageService.clear();
    this.accountService.currentUser.next(null);

    const returnUrl = window?.location?.pathname;
    const queryParams = returnUrl ? { returnUrl } : null;

    this.router.navigate(['/login'], { queryParams }).catch((err) => {
      console.error('fail to navigate to login: ', err);
    });
  }

  returnRefreshTokenSubject(next: HttpHandler, req: HttpRequest<any>) {
    return this.refreshTokenSubject.pipe(
      filter((token) => token != null),
      take(1),
      switchMap(() => {
        return next.handle(req);
      }),
    );
  }

  returnRefreshTokenObservable(next: HttpHandler, req: HttpRequest<any>) {
    return this.accountService.refreshToken().pipe(
      switchMap((token: any) => {
        this.isRefreshing = false;
        this.refreshTokenSubject.next(token);

        return next.handle(req);
      }),
      catchError((err) => {
        this.isRefreshing = false;
        this.clearTokenAndRedirectToLogin();

        if (err.error) throw err.error;
        else return throwError({});
      }),
    );
  }
}
