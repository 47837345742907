<div class="tw-flex-auto">
  <ng-container *ngIf="!isLoading; else loading">
    <div class="tw-mb-4">
      <campaign-badge [status]="campaign?.campaignStatus"></campaign-badge>
    </div>

    <ul class="tw-mb-0 tw-list-none tw-space-y-4 tw-pl-0" id="campaignStats">
      <ng-container *ngFor="let item of stats">
        <li *ngIf="!item.show || item.show(campaign)">
          <!-- Number -->
          <div class="p-text-h3 tw-text-black">
            <span *ngIf="item.isCurrency; else statsNumber">
              {{ item.value | numberSuffix: { isSuffix: false, numberFormat: '1.0-1' } }}
            </span>

            <ng-template #statsNumber>
              <span>{{ item.value }}</span>

              <!-- Add tooltip to show ended date -->
              <ng-container *ngIf="item.name == 'campaign.leftToGo'">
                <button
                  type="button"
                  class="btn-transparent tw-align-middle"
                  [ngbPopover]="fundingPeriodTooltip"
                  container=".tooltip-container"
                  triggers="click"
                >
                  <div class="pitchin-icon pitchin-icon-help-circle p-help-icon--lg"></div>
                </button>
                <ng-template #fundingPeriodTooltip>
                  <div class="tooltip-container tw-max-w-[200px] md:tw-max-w-[250px]">
                    <div class="tw-font-bold tw-text-black">
                      {{ 'campaign.fundingPeriod' | translate }}
                    </div>
                    {{ campaign.startAt | amDateFormat: 'D MMM YYYY' }} -
                    {{ campaign.endAt | amDateFormat: 'D MMM YYYY' }}
                  </div>
                </ng-template>
              </ng-container>
            </ng-template>
          </div>

          <!-- Progress bar -->
          <ng-container *ngIf="item.name == 'campaign.currentRaisedAmount'; else statsLabel">
            <div class="p-text-tag">
              {{ campaign.raisedPercentage | number: '1.0-0' }}%
              {{ 'campaign.ofTheMinTarget' | translate }} ({{
                campaign.minimumTargetAmount
                  | numberSuffix: { isSuffix: false, numberFormat: '1.0-1' }
              }})
            </div>

            <mat-progress-bar
              aria-label="progressbar"
              mode="determinate"
              height="10"
              class="tw-mt-1.5"
              [color]="campaign.raisedPercentage >= 100 ? 'success' : ''"
              [value]="campaign.raisedPercentage"
            >
            </mat-progress-bar>
          </ng-container>

          <!-- Label -->
          <ng-template #statsLabel>
            <div class="p-text-tag">{{ item.name | translate }}</div>
          </ng-template>
        </li>
      </ng-container>
    </ul>

    <hr
      class="tw-my-6"
      *ngIf="
        campaign?.campaignStatus !== CampaignStatus.Live &&
        campaign?.campaignStatus !== CampaignStatus.Cooling
      "
    />

    <div
      class="p-text-body"
      *ngIf="campaign?.campaignStatus == CampaignStatus.PreLive"
      [innerHTML]="
        'campaign.preliveFooterNote' | translate: { campaignName: campaign?.company?.brandName }
      "
    ></div>

    <p
      class="p-text-body !tw-text-secondary-light"
      *ngIf="
        [CampaignStatus.Success, CampaignStatus.ShareAllotment].includes(campaign?.campaignStatus)
      "
    >
      {{ 'campaign.successfulFooterNote' | translate }}
      {{ campaign.endAt | amDateFormat: 'D MMM YYYY' }}.
    </p>

    <p
      class="p-text-body"
      *ngIf="
        campaign?.campaignStatus == CampaignStatus.Fail ||
        campaign?.campaignStatus == CampaignStatus.Rejected
      "
    >
      {{ 'campaign.failedFooterNote' | translate }}.
    </p>
  </ng-container>
</div>

<!-- Normal cta button on top -->
<div class="tw-mt-10">
  <p
    class="p-text-body tw-text-center !tw-text-xs tw-text-body tw-text-opacity-80"
    *ngIf="
      campaign?.isPrivate &&
      !campaign?.isInvited &&
      !isIssuer &&
      [CampaignStatus.PreLive, CampaignStatus.Live].includes(campaign?.campaignStatus)
    "
  >
    <span class="tw-mb-0.5 tw-block" [innerHTML]="'common.inviteOnlyBlurp' | translate"></span>
    <a
      *ngIf="campaign?.registrationLinkUrl"
      [href]="campaign?.registrationLinkUrl"
      target="_blank"
      class="p-link-secondary p-link-underline tw-text-base tw-font-bold"
    >
      {{ 'common.registerInterest' | translate }}
    </a>
  </p>

  <ng-container *ngTemplateOutlet="ctaBtn"></ng-container>
</div>

<!-- Sticky footer cta -->
<div class="sticky-cta" #footerCta>
  <div class="container-fluid cta-footer-wrapper">
    <ng-container *ngTemplateOutlet="ctaBtn"></ng-container>
  </div>
</div>

<ng-template #loading>
  <app-skeleton [width]="'80%'" [marginBottom]="2.5"></app-skeleton>
  <app-skeleton></app-skeleton>
  <app-skeleton></app-skeleton>
</ng-template>

<ng-template #ctaBtn>
  <span
    class="cta-footer-countdown tw-hidden tw-font-bold tw-lowercase tw-text-body tw-text-opacity-70"
    *ngIf="
      daysLeft > 0 &&
      (!campaign?.isPrivate || (campaign?.isPrivate && campaign?.isInvited) || isIssuer)
    "
  >
    {{ daysLeftLabel }} {{ 'campaign.leftToInvest' | translate }}
  </span>
  <span
    class="cta-footer-countdown tw-hidden !tw-text-xs"
    *ngIf="
      campaign?.isPrivate &&
      !campaign?.isInvited &&
      !isIssuer &&
      [CampaignStatus.PreLive, CampaignStatus.Live].includes(campaign?.campaignStatus)
    "
  >
    <span [innerHTML]="'common.inviteOnlyBlurp' | translate" class="lg:tw-pr-2"></span>
    <a
      *ngIf="campaign?.registrationLinkUrl"
      [href]="campaign?.registrationLinkUrl"
      target="_blank"
      class="p-link-secondary p-link-underline tw-font-bold lg:tw-text-base"
    >
      {{ 'common.registerInterest' | translate }}
    </a>
  </span>
  <agmo-button
    *ngIf="[CampaignStatus.PreLive, CampaignStatus.Live].includes(campaign?.campaignStatus)"
    [loading]="isInvestNowLoading"
    [disabled]="isCtaDisabled"
    [buttonClass]="
      'btn-green tw-w-full md:tw-w-72 font-size-regular tw-break-words tw-whitespace-normal'
    "
    [buttonLabel]="
      campaign.campaignStatus == CampaignStatus.PreLive
        ? 'campaign.reserveYourInvestment'
        : 'campaign.investNow'
    "
    [buttonIcon]="!isCtaDisabled ? null : 'lock'"
    (click)="handleInvestNow()"
  >
  </agmo-button>
</ng-template>
