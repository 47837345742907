<div class="c-filters-toggle">
  <button
    class="c-filters-toggle__btn"
    *ngFor="let menu of filterMenus"
    (click)="tabClick(menu.key)"
    [class.active]="selectedMenu == menu.key"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="collapseFilter"
  >
    {{ menu.label }}

    <i class="fa fa-caret-down tw-ml-2 tw-opacity-50"></i>
  </button>

  <app-campaign-filters-portal [portalName]="portalName">
    <div
      #collapse="ngbCollapse"
      [(ngbCollapse)]="isCollapsed"
      class="c-filter__dropdown-collapsable"
    >
      <ng-container *ngIf="selectedMenuIndex >= 0">
        <ng-container
          *ngIf="filterMenus[selectedMenuIndex].children; then defaultMenuList; else menuSections"
        >
        </ng-container>
      </ng-container>
    </div>
  </app-campaign-filters-portal>
</div>

<!-- Default case -->
<ng-template #defaultMenuList>
  <div class="c-filter__dropdown-section">
    <ul class="c-filter__dropdown-grid tw-mb-0 tw-grid tw-gap-2">
      <li *ngFor="let childMenu of filterMenus[selectedMenuIndex].children">
        <app-campaign-filters-item-label
          [menu]="childMenu"
          [parentMenu]="filterMenus[selectedMenuIndex]"
          [isActive]="isActive(childMenu)"
          (onChange)="handleChange($event)"
        >
        </app-campaign-filters-item-label>
      </li>
    </ul>
  </div>
</ng-template>

<!-- With multiple section groups -->
<ng-template #menuSections>
  <div
    *ngFor="let sectionMenu of filterMenus[selectedMenuIndex].childrenGroups"
    class="c-filter__dropdown-section"
  >
    <h5 class="tw-mb-2.5 tw-py-1 tw-text-base tw-font-medium">{{ sectionMenu.label }}</h5>
    <ul class="c-filter__dropdown-grid c-filter__dropdown-grid--two tw-mb-0 tw-grid tw-gap-2">
      <li *ngFor="let childMenu of sectionMenu.children">
        <app-campaign-filters-item-label
          [menu]="childMenu"
          [parentMenu]="sectionMenu"
          [isActive]="isActive(childMenu)"
          (onChange)="handleChange($event)"
        >
        </app-campaign-filters-item-label>
      </li>
    </ul>
  </div>
</ng-template>
