// reference: https://prismic.io/docs/rest-api-technical-reference
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { LearnGroup } from '../../enum/learn-group.enum';
import { Article } from '../../models/learn/article';
@Injectable()
export class PrismicService {
  private articles = Object.keys(LearnGroup).reduce((acc, key) => ({ ...acc, [key]: null }), {});
  private subMenus = Object.keys(LearnGroup).reduce((acc, key) => ({ ...acc, [key]: null }), {});
  private tableOfContents: object;

  private route = environment.apiServer + 'Api/v1/StaticUI/public';

  constructor(private http: HttpClient) {}

  private getEnumKey(enumValue) {
    return Object.keys(LearnGroup).find((k) => LearnGroup[k] == enumValue);
  }

  getHomepage(): Observable<object> {
    const params = { noToken: 'noToken' };

    if (this.tableOfContents) {
      return of(this.tableOfContents);
    }

    return this.http.get(this.route + '/homepage', { params: params }).pipe(
      map((data: any) => {
        this.tableOfContents = data.result;

        return data.result;
      }),
    );
  }

  getSubmenu(group: LearnGroup): Observable<Article[]> {
    const params = { noToken: 'noToken', group: group };
    const key = this.getEnumKey(group);

    if (key && this.subMenus[key]) return of(this.subMenus[key]);

    return this.http.get(this.route + '/submenu', { params: params }).pipe(
      map((data: any) => {
        this.subMenus[key] = data.result;

        return data.result;
      }),
    );
  }

  getArticle(slug: string, group?: LearnGroup): Observable<Article> {
    const params = { noToken: 'noToken', group: group };

    return this.http.get(this.route + '/article/' + slug, { params: params }).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  sortArticle(list: Article[]) {
    const customSort = (a, b) => {
      if (a.data.position == null) return 1;
      if (b.data.position == null) return -1;
      return a.data.position - b.data.position;
    };

    return list.sort(customSort);
  }
}
