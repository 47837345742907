<div
  [class.is-loading]="isLoading"
  *ngIf="totalShares || totalNomineeShares || businessesList.length"
>
  <div class="tw-mb-10 tw-max-w-2xl">
    <div class="tw-grid tw-gap-5 sm:tw-grid-cols-2">
      <div class="tw-rounded tw-border tw-border-divider tw-p-5">
        <div class="p-skeleton-bar tw-mb-2.5 !tw-block tw-text-lg">
          {{ 'offerCreation.totalShares' | translate }}
        </div>
        <div class="p-skeleton-bar tw-text-2xl tw-font-medium tw-text-black">
          {{ totalShares | number }}
        </div>
      </div>

      <div class="tw-rounded tw-border tw-border-divider tw-p-5">
        <div class="p-skeleton-bar tw-mb-2.5 !tw-block tw-text-lg">
          {{ 'offerCreation.totalNomineeShares' | translate }}
        </div>
        <div class="p-skeleton-bar tw-text-2xl tw-font-medium tw-text-black">
          {{ totalNomineeShares | number }}
        </div>
        <small class="p-skeleton-hidden"
          >({{ nomineeSharesPercentage | number }}% of Total Shares)</small
        >
      </div>
    </div>
  </div>

  <div class="tw-mb-5 tw-text-xl tw-font-bold tw-text-black">
    {{ 'raise.marketInformation' | translate }}
  </div>

  <div class="mysec-mobile-table mobile-view row tw-mb-8 tw-rounded" *ngIf="businessesList">
    <div class="col-12">
      <table class="tw-w-full">
        <thead>
          <th class="tw-text-xs tw-font-medium">{{ 'mysec.businesses' | translate }}</th>
        </thead>
        <tbody>
          <tr *ngFor="let item of businessesList">
            <td>
              <ng-container
                *ngIf="
                  item?.tickerCode &&
                    ![ShareMarketStatus.Dehosting, ShareMarketStatus.Inactive].includes(
                      item?.status
                    );
                  else noLink
                "
              >
                <a
                  class="text-unset tw-flex tw-items-start"
                  [routerLink]="['/pstx', item.tickerCode]"
                >
                  <ng-container
                    *ngTemplateOutlet="mobileRow; context: { item: item }"
                  ></ng-container>
                </a>
              </ng-container>
              <ng-template #noLink>
                <ng-container *ngTemplateOutlet="mobileRow; context: { item: item }"></ng-container>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row desktop-view tw-mb-8" *ngIf="businessesList">
    <div class="col-12">
      <div class="p-table__wrapper">
        <div class="p-table__body">
          <mat-table class="responsive-table p-table" [dataSource]="businessesList">
            <ng-container matColumnDef="Code">
              <mat-header-cell *matHeaderCellDef>
                {{ 'Counter' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let item" class="tw-flex-col tw-items-start">
                <ng-container
                  *ngIf="
                    item?.tickerCode &&
                      ![ShareMarketStatus.Dehosting, ShareMarketStatus.Inactive].includes(
                        item?.status
                      );
                    else normalTickerCode
                  "
                >
                  <a
                    [routerLink]="['/pstx', item.tickerCode]"
                    class="p-link-underline p-link-primary p-skeleton-bar tw-uppercase"
                    >{{ item.tickerCode }}</a
                  >
                </ng-container>
                <ng-template #normalTickerCode>
                  <div class="p-skeleton-bar tw-uppercase">{{ item.tickerCode }}</div>
                </ng-template>
                <div
                  class="p-skeleton-hidden tw-text-sm tw-font-medium tw-text-body/50"
                  [class.!tw-text-status-success]="item.status === ShareMarketStatus.Active"
                  [class.!tw-text-status-fail]="item.status === ShareMarketStatus.Inactive"
                >
                  {{ ShareMarketStatus[item.status] }}
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="DirectShare">
              <mat-header-cell *matHeaderCellDef>
                {{ 'mysec.directShare' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let item" class="tw-flex-col tw-items-start">
                <div
                  class="p-skeleton-bar"
                  *ngIf="item.shareHolder?.directShares !== 0; else empty"
                >
                  {{ item.shareHolder?.directShares | number }}
                  {{
                    'raise.share_plural' | translate: { 'count': item.shareHolder?.directShares }
                  }}
                </div>
                <div class="p-skeleton-hidden" *ngIf="item.shareHolder?.directShareHolders !== 0">
                  ({{ item.shareHolder?.directShareHolders | number }}
                  {{
                    'raise.shareholder_plural'
                      | translate: { 'count': item.shareHolder?.directShareHolders }
                  }})
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="NomineeShare">
              <mat-header-cell *matHeaderCellDef>
                {{ 'mysec.nomineeShare' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let item" class="tw-flex-col tw-items-start">
                <div
                  class="p-skeleton-bar"
                  *ngIf="item.shareHolder?.nomineeShares !== 0; else empty"
                >
                  {{ item.shareHolder?.nomineeShares | number }}
                  {{
                    'raise.share_plural' | translate: { 'count': item.shareHolder?.nomineeShares }
                  }}
                </div>
                <div class="p-skeleton-hidden" *ngIf="item.shareHolder?.nomineeShareHolders !== 0">
                  ({{ item.shareHolder?.nomineeShareHolders | number }}
                  {{
                    'raise.shareholder_plural'
                      | translate: { 'count': item.shareHolder?.nomineeShareHolders }
                  }})
                </div>
              </mat-cell>
            </ng-container>
            <!-- <ng-container matColumnDef="Status">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                <ng-container
                                    *ngTemplateOutlet="status; context: {item:item, class: 'tw-justify-start', childClass: 'last:tw-mr-0'}"></ng-container>
                            </mat-cell>
                        </ng-container> -->
            <ng-container matColumnDef="LastTrade">
              <mat-header-cell *matHeaderCellDef>
                {{ 'mysec.lastTrade' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let item">
                <div class="p-skeleton-bar">
                  {{
                    item.lastTrade
                      ? (item.lastTrade | numberSuffix: { numberFormat: '1.2-2' })
                      : '-'
                  }}
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="BestBid">
              <mat-header-cell *matHeaderCellDef>
                <div class="tw-flex tw-items-center">
                  <div class="tw-mr-1">{{ 'mysec.bestBid' | translate }}</div>
                  <div
                    class="pitchin-icon pitchin-icon-help-circle p-help-icon"
                    #tooltip="matTooltip"
                    matTooltip="{{
                      'The highest price that a buyer is currently willing to pay for a particular share'
                        | translate
                    }}"
                  ></div>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let item">
                <div class="p-skeleton-bar">
                  {{
                    item.bestBid ? (item.bestBid | numberSuffix: { numberFormat: '1.2-2' }) : '-'
                  }}
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="BestAsk">
              <mat-header-cell *matHeaderCellDef>
                <div class="tw-flex tw-items-center">
                  <div class="tw-mr-1">{{ 'mysec.bestAsk' | translate }}</div>
                  <div
                    class="pitchin-icon pitchin-icon-help-circle p-help-icon"
                    #tooltip="matTooltip"
                    matTooltip="{{
                      'The lowest price at which a seller is currently willing to sell a particular share'
                        | translate
                    }}"
                  ></div>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let item">
                <div class="p-skeleton-bar">
                  {{
                    item.bestOffer
                      ? (item.bestOffer | numberSuffix: { numberFormat: '1.2-2' })
                      : '-'
                  }}
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="OrderQty">
              <mat-header-cell *matHeaderCellDef>
                <div class="tw-flex tw-items-center">
                  <div class="tw-mr-1">{{ 'mysec.orderQty' | translate }}</div>
                  <div
                    class="pitchin-icon pitchin-icon-help-circle p-help-icon"
                    #tooltip="matTooltip"
                    matTooltip="{{ 'Total number of shares available for trade' | translate }}"
                  ></div>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let item">
                <div class="p-skeleton-bar">
                  {{ item.orderQuantity ? (item.orderQuantity | number) : '-' }}
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="PriceChg">
              <mat-header-cell *matHeaderCellDef>
                <div class="d-flex align-items-center">
                  <div class="mr-1">{{ 'mysec.priceChg' | translate }}</div>
                  <div
                    class="pitchin-icon pitchin-icon-help-circle p-help-icon"
                    #tooltip="matTooltip"
                    matTooltip="{{ 'mysec.changePercentDescription' | translate }}"
                  ></div>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let item">
                <ng-container
                  *ngTemplateOutlet="chgPrice; context: { item: item, class: 'tw-justify-start' }"
                ></ng-container>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayColumns"> </mat-header-row>
            <mat-row *matRowDef="let item; columns: displayColumns"> </mat-row>
          </mat-table>

          <div
            class="tw-w-full tw-py-4 tw-text-center"
            *ngIf="!businessesList || businessesList.length == 0"
          >
            {{ 'common.noRecord' | translate }}
          </div>

          <div class="p-table__footer" *ngIf="total > params.take">
            <div class="p-table__pagination p-skeleton-pagination">
              <ngb-pagination
                [collectionSize]="total"
                [(pageSize)]="params.take"
                [(page)]="params.currentPage"
                (pageChange)="changePage($event)"
                [maxSize]="5"
                [rotate]="true"
              >
                <ng-template ngbPaginationPrevious>
                  <span class="p-table__pagination-icon">←</span>
                  <div class="p-table__pagination-label tw-pl-2">
                    {{ 'common.prev' | translate }}
                  </div>
                </ng-template>
                <ng-template class="page-no" ngbPaginationNumber let-page>
                  <div class="page-no">{{ page }}</div>
                </ng-template>
                <ng-template ngbPaginationNext>
                  <div class="p-table__pagination-label tw-pr-2">
                    {{ 'common.next' | translate }}
                  </div>
                  <span class="p-table__pagination-icon">→</span>
                </ng-template>
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #chgPrice let-item="item" let-class="class">
  <div class="p-skeleton-hidden tw-flex tw-items-center" [class]="class">
    <div class="p-skeleton-bar tw-whitespace-nowrap">
      {{ item.changesValue || 0 | numberSuffix: { numberFormat: '1.2-2' } }}
    </div>
    <div
      class="p-skeleton-hidden tw-ml-2 tw-flex tw-items-center tw-rounded-full tw-px-1 tw-py-1 tw-text-xs"
      [class]="checkItemPrice(item)"
    >
      <div
        class="pitchin-icon-xs pitchin-icon-percentage tw-mr-1"
        [class]="'pitchin-icon-' + checkItemPrice(item) + '-arrow'"
      ></div>
      <div class="tw-font-medium">{{ absValue(item.priceChg || 0) | number }}%</div>
    </div>
  </div>
</ng-template>

<ng-template #status let-item="item" let-class="class" let-childClass="childClass">
  <div class="tw-flex tw-flex-wrap" [class]="class">
    <div
      *ngFor="let status of item.investorShareMarketStatus"
      class="tw-flex tw-items-center tw-rounded-full tw-px-2 tw-text-xs tw-py-1 tw-whitespace-nowrap tw-ml-2 tw-mb-1 tw-font-medium {{
        InvestorShareMarketStatusFilter[status]
      }} {{ childClass }}"
    >
      {{ InvestorShareMarketStatusFilter[status] | translate }}
    </div>
  </div>
</ng-template>

<ng-template #empty>
  <span class="p-skeleton-bar">-</span>
</ng-template>

<ng-template #mobileRow let-item="item">
  <div class="tw-flex tw-w-full tw-justify-between tw-pb-1 max-sm:tw-flex-wrap">
    <div class="tw-mr-auto">
      <div
        class="p-skeleton-bar !tw-block tw-font-medium tw-uppercase tw-text-black"
        [class.tw-underline]="
          item?.tickerCode &&
          ![ShareMarketStatus.Dehosting, ShareMarketStatus.Inactive].includes(item?.status)
        "
      >
        {{ item.tickerCode }}
      </div>
      <div
        class="p-skeleton-bar tw-text-sm tw-font-medium tw-text-body/50"
        [class.!tw-text-status-success]="item.status === ShareMarketStatus.Active"
        [class.!tw-text-status-fail]="item.status === ShareMarketStatus.Inactive"
      >
        {{ ShareMarketStatus[item.status] }}
      </div>
    </div>
    <div class="tw-text-right max-sm:tw-flex-[0_0_100%]">
      <ng-container
        *ngTemplateOutlet="chgPrice; context: { item: item, class: 'tw-justify-end' }"
      ></ng-container>
      <div class="p-skeleton-hidden">
        {{ 'Bid' | translate }}:
        {{ item.bestBid ? (item.bestBid | numberSuffix: { numberFormat: '1.2-2' }) : '-' }}
      </div>
      <div class="p-skeleton-hidden">
        {{ 'Ask' | translate }}:
        {{ item.bestOffer ? (item.bestOffer | numberSuffix: { numberFormat: '1.2-2' }) : '-' }}
      </div>
      <div class="p-skeleton-hidden">
        {{ 'mysec.orderQty' | translate }}: {{ item.orderQty ? (item.orderQty | number) : '-' }}
      </div>
      <div class="p-skeleton-hidden">
        {{ 'mysec.directShare' | translate }}:
        <span *ngIf="item.shareHolder?.directShares !== 0; else empty"
          >{{ item.shareHolder?.directShares | number }}
          {{ 'raise.share_plural' | translate: { 'count': item.shareHolder?.directShares } }}</span
        >
        <span *ngIf="item.shareHolder?.directShareHolders !== 0">
          ({{ item.shareHolder?.directShareHolders | number }}
          {{
            'raise.shareholder_plural'
              | translate: { 'count': item.shareHolder?.directShareHolders }
          }})</span
        >
      </div>
      <div class="p-skeleton-hidden">
        {{ 'mysec.nomineeShare' | translate }}:
        <span *ngIf="item.shareHolder?.nomineeShares !== 0; else empty"
          >{{ item.shareHolder?.nomineeShares | number }}
          {{ 'raise.share_plural' | translate: { 'count': item.shareHolder?.nomineeShares } }}</span
        >
        <span *ngIf="item.shareHolder?.nomineeShareHolders !== 0">
          ({{ item.shareHolder?.nomineeShareHolders | number }}
          {{
            'raise.shareholder_plural'
              | translate: { 'count': item.shareHolder?.directShareHolders }
          }})</span
        >
      </div>
    </div>
  </div>
</ng-template>
