import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormTemplate } from '../../models/question/form-template';
import { QuestionBase } from '../../models/question/question-base';
import { QuestionControlService } from '../dynamic-form-question/question-control.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit {
  @Input() dynamicForm: FormTemplate;
  @Input() submitText: string;
  @Input() disableSubmit: boolean;
  @Output() submitForm = new EventEmitter();

  form: UntypedFormGroup;
  showFormError: boolean = false;
  questions: QuestionBase<any>[] = [];

  constructor(private questionControlService: QuestionControlService) {}

  ngOnInit() {
    this.dynamicForm.formPanels.forEach((formPanel) => {
      if (formPanel.items && formPanel.items.length > 0) {
        let formItems = formPanel.items.filter(
          (item) => item.controlType != 'title' && item.controlType != 'description',
        );
        this.questions.push(...formItems);
      }
    });

    this.form = this.questionControlService.toFormGroup(this.questions);
  }

  onSubmit() {
    this.questions.forEach((question) => {
      if (question.controlType == 'checkbox' && question.required) {
        if (this.form.controls[question.key].value == false)
          this.form.controls[question.key].setErrors({ incorrect: true });
      }

      if (question.type == 'number' && !question.required) {
        if (this.form.controls[question.key].value == '')
          this.form.controls[question.key].setValue(null);
      }
    });

    if (this.form.invalid) {
      this.showFormError = true;
      return;
    }

    this.submitForm.emit(this.form.value);
  }
}
