import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'legacyFormatter',
})
export class LegacyFormatterPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    value = value.replace(/(\\t)*/gi, '');
    value = value.replace(/(<br\s*\/?>){1,}\s*/gi, '<br>'); // reduce multiple br to single
    value = value.replace(
      /(<br\s*\/?>){1,}\s*(<\/?(p|h|div|ul|ol|li|table|td|tr|tbody))/gi,
      '<!-- replaced br -->$2',
    ); // remove br if appear before tag
    value = value.replace(/(<br\s*\/?>){1,}\s*([^<\\])/gi, '<br class="before-text">$2'); // replace multiple br with single br if appear before non-tag

    value = this.removeEmptyParagraph(value);

    return value;
  }

  removeEmptyParagraph(text: string) {
    text = text.replace(/([^\w])(&nbsp;)([^\w])/gi, '$1$3');
    text = text.replace(/<p>(\s*<br\s*([\w="-]+)?\/?>*\s*)*<\/p>/gi, '');

    return text;
  }
}
