<div class="notification-wrapper tw-relative tw-text-base" #announcement>
  <span
    class="tw-inline-block tw-w-full tw-px-10 tw-leading-normal md:tw-px-8"
    [innerHTML]="content | trustSrc"
  ></span>

  <button
    type="button"
    class="tw-absolute tw-right-0 tw-top-1/2 -tw-translate-y-1/2 tw-cursor-pointer tw-border-none tw-bg-transparent tw-text-black tw-text-opacity-30 hover:tw-text-opacity-50"
    (click)="closeAnnouncementBar()"
  >
    <p-icon name="close"></p-icon>
  </button>
</div>
