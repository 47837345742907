<div class="section container-fluid">
  <div class="tw-text-white md:tw-grid md:tw-grid-cols-3">
    <div class="bg--workshop tw-min-h-[220px] md:tw-min-h-max"></div>

    <div
      class="tw-rounded-b-10px tw-bg-gradient-to-br tw-from-[#EC474D] tw-to-[#F9767B] tw-p-10 md:tw-col-span-2 md:tw-rounded-l-none md:tw-rounded-r-10px"
    >
      <div class="p-text-preheader tw-text-lg !tw-font-extralight">pitchIN Academy</div>
      <h2 class="p-text-h2 tw-mb-10 !tw-text-3xl tw-text-white">
        In person workshops for Investors & Entrepreneurs
      </h2>

      <ul
        class="tw-mb-0 tw-list-none tw-divide-y tw-divide-white tw-divide-opacity-50 tw-border-b tw-border-t tw-border-white tw-border-opacity-50 tw-pl-0"
      >
        <li>
          <a
            href="https://academy.pitchin.my/fundraising-accelerator/"
            target="_blank"
            class="tw-flex tw-items-center tw-py-5 tw-text-white tw-text-opacity-80 hover:tw-text-white"
          >
            <div>
              <h3 class="tw-mb-1 tw-text-white">Fundraising Accelerator</h3>
              <p class="tw-mb-0">
                Multi-day programme for entrepreneurs to get their company into the best possible
                fundraising shape
              </p>
            </div>
            <span class="tw-pl-4 tw-text-2xl">
              <i class="fa fa-caret-right" aria-hidden="true"></i>
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://academy.pitchin.my/investment-workshop/"
            target="_blank"
            class="tw-flex tw-items-center tw-py-5 tw-text-white tw-text-opacity-80 hover:tw-text-white"
          >
            <div>
              <h3 class="tw-mb-1 tw-text-white">Investment Workshop</h3>
              <p class="tw-mb-0">
                Workshop to help first-time or beginner investors to make informed decisions before
                investing
              </p>
            </div>
            <span class="tw-pl-4 tw-text-2xl">
              <i class="fa fa-caret-right" aria-hidden="true"></i>
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
