<div class="hero__bg"></div>

<section class="tw-bg-gray-100 tw-py-8">
  <div class="container">
    <div class="md:tw-grid md:tw-grid-cols-5 md:tw-items-center md:tw-gap-2">
      <div class="md:tw-col-span-2">
        <picture>
          <source
            media="(max-width: 767px)"
            srcset="./assets/img/hero/beyond4-pitchin-logos-horizontal.png"
          />
          <source media="(min-width: 768px)" srcset="./assets/img/hero/beyond4-pitchin-logos.png" />
          <img
            src="./assets/img/hero/beyond4-pitchin-logos.png"
            alt="BEYOND4 - pitchIN"
            class="tw-mx-auto tw-block tw-w-full sm:tw-max-w-[90%] lg:tw-max-w-[80%]"
          />
        </picture>
      </div>

      <p class="tw-mb-0 md:tw-col-span-3">
        BEYOND4 and pitchIN are proud to announce a new partnership aimed at empowering and
        accelerating early and mid-stage startups in Malaysia. BEYOND4, a Malaysia-based startup
        ecosystem, will now be supporting its startups to raise funds via equity crowdfunding on
        pitchIN. This partnership will provide investors with a unique opportunity to support and
        invest in innovative and exciting startups in the region.
      </p>
    </div>
  </div>
</section>

<!-- Initial loading -->
<section class="section" *ngIf="totalCampaigns == null">
  <div class="container-fluid">
    <agmo-loading></agmo-loading>
  </div>
</section>

<!-- Section: Coming soon (when no campaign exist) -->
<section class="section" *ngIf="totalCampaigns != null && totalCampaigns == 0">
  <div class="container-fluid">
    <h2 class="font-weight-extrabold tw-mb-5">
      {{ 'common.liveCampaigns' | translate }}
    </h2>
    <div class="tw-text-center">
      <div class="tw-my-10">{{ 'common.comingSoon' | translate }}</div>
      <img
        src="/assets/img/hero/beyond4-potential-campaigns.jpg"
        class="tw-w-full tw-max-w-[1000px]"
        alt="coming-soon"
      />
    </div>
  </div>
</section>

<!-- Section: Pre-Live -->
<ng-container *ngIf="list[CampaignStatus.PreLive].total > 0">
  <hr class="tw-my-0" />

  <section class="section">
    <div class="container-fluid">
      <h2 class="font-weight-extrabold tw-mb-5">
        {{ 'common.preLiveCampaigns' | translate }}
      </h2>
      <app-campaign-list
        [campaigns]="list[CampaignStatus.PreLive].campaigns"
        [params]="list[CampaignStatus.PreLive].params"
        [total]="list[CampaignStatus.PreLive].total"
        [isLoading]="list[CampaignStatus.PreLive].isLoading"
        [noRecordText]="'common.comingSoon'"
        (getCampaignList)="getList($event, CampaignStatus.PreLive)"
      ></app-campaign-list>
    </div>
  </section>
</ng-container>

<!-- Section: Live -->
<section class="section" *ngIf="list[CampaignStatus.Live].total > 0">
  <div class="container-fluid">
    <h2 class="font-weight-extrabold tw-mb-5">
      {{ 'common.liveCampaigns' | translate }}
    </h2>
    <app-campaign-list
      [campaigns]="list[CampaignStatus.Live].campaigns"
      [params]="list[CampaignStatus.Live].params"
      [total]="list[CampaignStatus.Live].total"
      [isLoading]="list[CampaignStatus.Live].isLoading"
      [noRecordText]="'common.comingSoon'"
      (getCampaignList)="getList($event, CampaignStatus.Live)"
    ></app-campaign-list>
  </div>
</section>

<!-- Section: Success -->
<ng-container *ngIf="list[CampaignStatus.Success].total > 0">
  <hr class="tw-my-0" />

  <section class="section">
    <div class="container-fluid">
      <h2 class="font-weight-extrabold tw-mb-5">
        {{ 'common.successCampaigns' | translate }}
      </h2>
      <app-campaign-list
        [campaigns]="list[CampaignStatus.Success].campaigns"
        [params]="list[CampaignStatus.Success].params"
        [total]="list[CampaignStatus.Success].total"
        [isLoading]="list[CampaignStatus.Success].isLoading"
        [noRecordText]="'common.comingSoon'"
        (getCampaignList)="getList($event, CampaignStatus.Success)"
      ></app-campaign-list>
    </div>
  </section>
</ng-container>

<div class="section--partner tw-bg-[#263E7E] tw-py-14 tw-text-white tw-text-opacity-80">
  <div class="container tw-max-w-3xl">
    <h3 class="tw-mb-4 tw-text-center tw-font-bold tw-text-[#FDD302]">The BEYOND4 Ecosystem</h3>

    <p>
      An end-to-end solutions ecosystem comprising of 5 accelerators (Education, Skills, Talent,
      Innovation & Startups) established with the purpose of identifying, nurturing and accelerating
      talent.
    </p>

    <p>
      With Talent at the core of the BEYOND4 Ecosystem we aim to develop a pool of top tech talent,
      promote ground breaking innovation and accelerate sustainable startups.
    </p>

    <p>
      With a philosophy of supporting and empowering startup founders, BEYOND4 provides a curated
      programme for startups to raise funds and scale exponentially with a 3-year roadmap including
      shared services to accelerate their growth.
    </p>

    <p>
      These shared services are the cornerstone of the programme, providing startups with key
      services such as strategy consulting, financial expertise, human capital, mentoring, legal aid
      and ecosystem support.
    </p>

    <p>
      Our use of the Orchestration Method to harmoniously bring together various stakeholders with
      each performing their respective roles is pivotal, creating a cumulative higher value as an
      ecosystem.
    </p>

    <div class="tw-mt-7 tw-text-center">
      <h5 class="tw-mb-3 tw-font-medium tw-text-white">Connect with us</h5>
      <a
        href="https://beyond4.tech/"
        target="_blank"
        class="btn tw-rounded-full tw-bg-[#FDD302] tw-text-[#263E7E] hover:tw-text-[#263E7E]"
      >
        More on BEYOND4
      </a>
    </div>
  </div>
</div>
