<section [class.tw-py-20]="showTitle">
  <h2 *ngIf="showTitle" class="p-text-h3 tw-mb-8 tw-text-center">
    {{ 'common.understandingThisPage' | translate }}
  </h2>
  <div class="accordion-container tw-mx-auto">
    <div class="accordion" *ngFor="let item of accordionItems" [class.active]="item.open">
      <div
        class="accordion-header"
        (click)="handleClick(item)"
        data-t="faq"
        [class.is-opened]="item.open"
        [class]="headerClass ?? 'tw-bg-divider tw-bg-opacity-40'"
      >
        <span class="question">{{ item.question }}</span>
        <p-icon name="chevron-down" class="accordion-icon"></p-icon>
      </div>
      <div class="accordion-body">
        <div class="answer" [innerHTML]="item.answer | trustSrc"></div>
      </div>
    </div>
  </div>
</section>
