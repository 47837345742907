<div class="mb-3 row">
  <div class="col-12">
    <h1 class="mb-4 font-weight-extrabold d-none" id="title">
      {{ campaignInfo.name }}
    </h1>
    <div class="mb-5" *ngIf="campaignInfo.videoUrl" id="campaignVideo">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          class="embed-responsive-item"
          [src]="campaignInfo.videoUrl | formatVideoURL"
          allowfullscreen
          title=""
        ></iframe>
      </div>
    </div>

    <!-- Campaign What's New -->
    <ng-container *ngIf="campaignInfo.campaignWhatsNew?.whatsNewInDays">
      <div class="campaign-title text-break text-primary tw-pb-5">
        {{ 'common.whatsNew' | translate }}
      </div>
      <div class="tw-grid tw-grid-cols-2 tw-gap-3 md:tw-grid-cols-5">
        <div
          class="tw-rounded-xl tw-bg-[#D9B019] tw-bg-opacity-10 tw-p-5 tw-text-[#D9B019] hover:tw-bg-opacity-20"
          *ngIf="campaignInfo.campaignWhatsNew.upcomingEventCount > 0"
          (click)="handleTabChange(5)"
        >
          <div class="tw-text-left tw-align-top"><p-icon name="events" size="lg"></p-icon></div>
          <div class="tw-text-right tw-text-3xl tw-font-bold">
            {{ campaignInfo.campaignWhatsNew.upcomingEventCount }}
          </div>
          <div
            class="tw-text-right tw-align-bottom tw-text-xl tw-font-bold tw-leading-none"
            [innerHTML]="
              'common.upcomingEvent'
                | translate: { 'count': campaignInfo.campaignWhatsNew.upcomingEventCount }
            "
          ></div>
        </div>
        <div
          class="tw-rounded-xl tw-bg-[#5D873B] tw-bg-opacity-10 tw-p-5 tw-text-[#5D873B] hover:tw-bg-opacity-20"
          *ngIf="campaignInfo.campaignWhatsNew.isNewLargestInvestment"
        >
          <div class="tw-text-left tw-align-top"><p-icon name="invest" size="lg"></p-icon></div>
          <div class="tw-text-3xl"><br /></div>
          <div class="tw-text-right tw-align-bottom tw-text-xl tw-font-bold tw-leading-none">
            {{ 'common.largestInvestment' | translate }}
          </div>
        </div>
        <div
          class="tw-rounded-xl tw-bg-[#B26B53] tw-bg-opacity-10 tw-p-5 tw-text-[#B26B53] hover:tw-bg-opacity-20"
          *ngIf="campaignInfo.campaignWhatsNew.updateCount > 0"
          (click)="handleTabChange(3)"
        >
          <div class="tw-text-left tw-align-top"><p-icon name="news" size="lg"></p-icon></div>
          <div class="tw-text-right tw-text-3xl tw-font-bold">
            {{ campaignInfo.campaignWhatsNew.updateCount }}
          </div>
          <div
            class="tw-text-right tw-align-bottom tw-text-xl tw-font-bold tw-leading-none"
            [innerHTML]="
              'common.updateAdded'
                | translate: { 'count': campaignInfo.campaignWhatsNew.updateCount }
            "
          ></div>
        </div>
        <div
          class="tw-rounded-xl tw-bg-[#21B3F5] tw-bg-opacity-10 tw-p-5 tw-text-[#21B3F5] hover:tw-bg-opacity-20"
          *ngIf="campaignInfo.campaignWhatsNew.questionCount > 0"
          (click)="handleTabChange(4)"
        >
          <div class="tw-text-left tw-align-top">
            <p-icon name="announcement" size="lg"></p-icon>
          </div>
          <div class="tw-text-right tw-text-3xl tw-font-bold">
            {{ campaignInfo.campaignWhatsNew.questionCount }}
          </div>
          <div class="tw-text-right tw-align-bottom tw-text-xl tw-font-bold tw-leading-none">
            {{ 'common.questionPosted' | translate }}
          </div>
        </div>
        <div
          class="tw-rounded-xl tw-bg-[#E88945] tw-bg-opacity-10 tw-p-5 tw-text-[#E88945] hover:tw-bg-opacity-20"
          *ngIf="campaignInfo.campaignWhatsNew.newEventCount > 0"
          (click)="handleTabChange(5)"
        >
          <div class="tw-text-left tw-align-top"><p-icon name="calendar" size="lg"></p-icon></div>
          <div class="tw-text-right tw-text-3xl tw-font-bold">
            {{ campaignInfo.campaignWhatsNew.newEventCount }}
          </div>
          <div
            class="tw-text-right tw-align-bottom tw-text-xl tw-font-bold tw-leading-none"
            [innerHTML]="
              'common.eventAdded'
                | translate: { 'count': campaignInfo.campaignWhatsNew.newEventCount }
            "
          ></div>
        </div>
      </div>
      <hr class="tw-my-12" />
    </ng-container>

    <!-- Campaign Highlights -->
    <ng-container *ngIf="campaignInfo.campaignHighlights?.length">
      <ul class="p-text-emphasis tw-mb-0 tw-space-y-2.5 tw-pl-5">
        <li *ngFor="let highlight of campaignInfo.campaignHighlights">
          <div class="font-weight-bolder" [innerHTML]="highlight?.content | trustSrc"></div>
        </li>
      </ul>
      <hr class="tw-my-12" />
    </ng-container>

    <ng-container *ngFor="let tab of tabs">
      <div
        class="mb-5 tab-content tw-clear-both"
        [id]="tab.route"
        *ngIf="!['title', 'campaignVideo'].includes(tab.route)"
      >
        <div class="campaign-title text-break text-primary">
          {{ tab.name | translate }}
        </div>
        <div class="mt-3 ck-content" [innerHTML]="campaignInfo[tab.route] | trustSrc"></div>
      </div>
    </ng-container>
  </div>
</div>
