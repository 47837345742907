<div class="container-fluid">
  <div class="article-container row no-gutters">
    <div id="sidebar" class="col-md-4 d-none d-md-block position-relative">
      <div class="sticky-sidebar-container" id="tabList">
        <div class="sidebar__inner">
          <a
            [routerLink]="'.'"
            [class.active]="'equity-crowdfunding' == visibleSection"
            fragment="equity-crowdfunding"
            >Equity Crowdfunding</a
          >
          <a
            [routerLink]="'.'"
            [class.active]="'why-is-equity-crowdfunding-so-exciting' == visibleSection"
            fragment="why-is-equity-crowdfunding-so-exciting"
            >Why Is Equity Crowdfunding So Exciting?</a
          >
          <a
            [routerLink]="'.'"
            [class.active]="'is-equity-crowdfunding-legal-and-safe' == visibleSection"
            fragment="is-equity-crowdfunding-legal-and-safe"
            >Is Equity Crowdfunding Legal And Safe?</a
          >
          <a
            [routerLink]="'.'"
            [class.active]="'angel-tax-incentive' == visibleSection"
            fragment="angel-tax-incentive"
            >ECF & The Angel Tax Incentive (ATI)</a
          >
          <a
            [routerLink]="'.'"
            [class.active]="'investor-funds' == visibleSection"
            fragment="investor-funds"
            >What is CFVC?</a
          >
        </div>
      </div>
    </div>
    <div
      id="content"
      class="col-md-8"
      scrollSpy
      [spiedTags]="['SECTION']"
      (sectionChange)="onSectionChange($event)"
      [navbarHeight]="-300"
    >
      <section class="article-section" id="equity-crowdfunding">
        <h2 class="title font-weight-bold text-dark">Equity Crowdfunding</h2>
        <p>
          Equity crowdfunding innovated on the first rewards-based crowdfunding model by offering
          individuals the opportunity to invest in companies in return for a direct equity stake in
          that company.
        </p>

        <p>
          Entrepreneurs offer a slice of their company's equity for a set period of time. Registered
          investors can take up the investment block they are comfortable with. Once the exercise is
          completed, the investor becomes a shareholder of the company with all the associated
          rights and benefits.
        </p>
      </section>

      <section class="article-section" id="why-is-equity-crowdfunding-so-exciting">
        <h2 class="title font-weight-bold text-dark">Why Is Equity Crowdfunding So Exciting?</h2>
        <p>
          The most common kind of crowdfunding is reward-based crowdfunding. Popular rewards-based
          crowdfunding sites such as Kickstarter and Indiegogo in US list many projects where
          backers pledge money in return for 'rewards' from the project founders. pitchIN operates a
          Rewards-based platform at reward.pitchin.my.
        </p>

        <p>
          The popularity of rewards-based crowdfunding led to innovation in the crowdfunding
          industry. Today there are platforms that have taken different approaches to crowdfunding
          like equity crowdfunding, property and debt crowdfunding.
        </p>
      </section>

      <section class="article-section" id="is-equity-crowdfunding-legal-and-safe">
        <h2 class="title font-weight-bold text-dark">Is Equity Crowdfunding Legal And Safe?</h2>
        <p>
          All investments carry risks and equity crowdfunding is no exception. The typical company
          raising capital of an equity crowdfunding platform is an early stage company with all the
          inherent risks a new business goes through. Companies can fail or perform below
          expectations. Fraud may be a risk too but this is where regulations come into play.
        </p>

        <p>
          All over the world, equity crowdfunding is regulated by securities regulators. Locally,
          the Securities Commission of Malaysia only allows approved platforms to offer equity
          crowdfunding. In addition to that, the Securities Commission has set rules and guidelines
          that must be followed by all approved platforms. pitchIN is registered with the Securities
          Commission of Malaysia as a Recognized Market Operator (RMO) for the purpose of offering
          Equity Crowdfunding related services. You can also download the rules and guidelines in
          relation to Equity Crowdfunding by the Securities Commission of Malaysia here
        </p>
      </section>

      <section class="article-section" id="angel-tax-incentive">
        <h2 class="title font-weight-bold text-dark">ECF &amp; The Angel Tax Incentive (ATI)</h2>
        <p>
          Equity Crowdfunding is an eligible avenue of investment for the Angel Tax Incentive (ATI).
          Both the investee and the investor must be registered with and accredited by the Malaysian
          Business Angel Network (MBAN).
        </p>

        <p>
          The minimum amount per investment is RM5,000.00 and up to a maximum of RM500,000.00
          investment per annum in total.
        </p>

        <p>The eligibility criteria consists of the following:</p>

        <ul>
          <li>Must be a tax resident in Malaysia;</li>
          <li>
            Either considered a High Net Worth Individual or High Income Earner
            <ul>
              <li>
                High Net Worth Individual — Total wealth or net personal assets of RM3 million and
                above or its equivalent in foreign currencies; OR
              </li>
              <li>
                High Income Earner — The gross annual income of not less than RM180,000.00 in the
                preceding twelve (12) months; OR
              </li>
              <li>
                High Income Earner — Jointly with one’s spouse, a gross amount total annual income
                of RM250,000.00 in the preceding twelve (12) months.
              </li>
            </ul>
          </li>
        </ul>

        <p>
          For more info, visit
          <a href="https://www.mban.com.my/angel-tax-incentive" target="_blank" rel="noopener"
            >https://www.mban.com.my/angel-tax-incentive</a
          >
        </p>
      </section>

      <section class="article-section" id="investor-funds">
        <h2 class="title font-weight-bold text-dark">What Is CFVC?</h2>
        <p>
          Crowd-Funded Venture Capital (CFVC) are Microfunds that will be set up on pitchIN. Only
          registered venture capital funds are allowed to raise MicroFunds on the platform. These
          CFVCs can raise money for investments on pitchIN only from sophisticated investors.
        </p>

        <p>The eligibility criteria consists of the following:</p>

        <ul>
          <li>VCs must be registered with the Securities Commission.</li>
          <li>Only sophisticated investors can join a CFVC.</li>
          <li>Funds raised by a CFVC must only be used to invest in Issues listed on pitchIN.</li>
        </ul>
      </section>
    </div>
  </div>
</div>
