<!-- conditionally render based on whether it is ECF / PSTX -->
<ng-container>
  <div class="row justify-content-center mb-5">
    <div class="col-md-10 col-lg-8">
      <form [formGroup]="form" [class.show-form-error]="showFormError" (ngSubmit)="submit()">
        <div class="row">
          <div class="col-md-12">
            <mat-radio-group name="paymentType" formControlName="paymentType">
              <ng-container *ngFor="let type of paymentOptions">
                <div
                  *ngIf="!type.show || type.show()"
                  class="custom-control custom-radio"
                  [class.active]="form.controls.paymentType?.value === type.key"
                  [class.disabled]="disablePaymentType(type.key)"
                  (click)="
                    !isPSTX || type.key !== PaymentType.MaybankFPX
                      ? selectPaymentType(type.key)
                      : null
                  "
                >
                  <mat-radio-button
                    class="type-radio"
                    [value]="type.key"
                    [disabled]="isPSTX && type.key === PaymentType.MaybankFPX"
                  >
                  </mat-radio-button>

                  <div
                    class="d-flex w-100 option-panel"
                    [class.grayscale]="isPSTX && type.key === PaymentType.MaybankFPX"
                  >
                    <div class="text-panel w-100">
                      <div class="type-label font-weight-extrabold text-black">
                        {{ 'investment.' + type.value | translate }}
                      </div>
                      <div
                        *ngIf="
                          (!isPSTX && type.key === PaymentType.MaybankFPX) ||
                          type.key === PaymentType.DuitNow
                        "
                        class="font-size-larger mt-3"
                      >
                        *{{ 'investment.recommended' | translate }}
                      </div>
                      <div
                        *ngIf="isPSTX && type.key === PaymentType.MaybankFPX"
                        class="font-size-larger mt-3"
                      >
                        {{ 'investment.comingSoon' | translate }}
                      </div>
                    </div>
                    <div
                      *ngIf="type.key === PaymentType.MaybankFPX"
                      class="fpx-img pitchin-icon-fpx tw-cursor-default"
                    ></div>
                    <div
                      *ngIf="type.key === PaymentType.DuitNow"
                      class="fpx-img pitchin-icon-duitnow tw-cursor-default"
                    ></div>
                  </div>
                </div>

                <ng-container
                  *ngIf="
                    form.value.paymentType === type.key &&
                    (type.key === PaymentType.Cheque ||
                      type.key === PaymentType.Swift ||
                      type.key === PaymentType.BankIn)
                  "
                >
                  <ng-template *ngTemplateOutlet="trustAccount; context: { type: type }">
                  </ng-template>
                </ng-container>
              </ng-container>
            </mat-radio-group>
          </div>
        </div>
        <div class="row justify-content-center mt-5">
          <div class="col-sm-7">
            <button class="btn btn-primary btn-continue" type="submit">
              {{ 'investment.continue' | translate }}
            </button>
            <div
              class="pay-later"
              *ngIf="
                receiptType === ReceiptType.Investment || receiptType === ReceiptType.OnboardingFee
              "
            >
              <span class="cursor-pointer" [routerLink]="continueLink" (click)="logPaylater()">
                {{ 'investment.continuePayLater' | translate }}
              </span>
            </div>
            <div class="pay-later" *ngIf="receiptType === ReceiptType.Wallet">
              <span class="cursor-pointer" (click)="cancelPayment()">
                {{ 'mysec.cancelTopUp' | translate }}
              </span>
            </div>
          </div>
        </div>
        <div class="tw-mt-5 tw-text-center">
          <button
            type="button"
            (click)="isHelpCollapsed = false"
            [attr.aria-expanded]="!isHelpCollapsed"
            aria-controls="helpCollapse"
            class="p-link-secondary p-link-underline tw-mb-2.5 tw-bg-transparent tw-p-0"
          >
            Need help?
          </button>

          <div
            #collapse="ngbCollapse"
            [(ngbCollapse)]="isHelpCollapsed"
            aria-controls="helpCollapse"
          >
            <app-alert-bar
              [title]="'investment.contactUsForHelpTitle' | translate"
              [type]="'warning'"
            >
              <p
                *ngIf="!referenceNo; else generalHelpText"
                [innerHTML]="'investment.contactUsForHelpDescription' | translate"
              ></p>

              <ng-template #generalHelpText>
                <p
                  [innerHTML]="
                    'investment.contactUsForHelpDescriptionWithRef'
                      | translate: { ref: referenceNo }
                  "
                ></p>
              </ng-template>
            </app-alert-bar>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
<ng-template #trustAccount let-type="type">
  <div
    class="row account-row"
    *ngIf="!isPSTX && (type.key == PaymentType.Cheque || type.key == PaymentType.BankIn)"
  >
    <div class="col-md-6 col-sm-6">
      {{
        (role == Role.IssuerRep ? 'pitchIN Account Details' : 'investment.pitchINEcfTrustAccount')
          | translate
      }}
    </div>
    <div class="col-md 6 col-sm-6 description-panel">
      <div *ngIf="role != Role.IssuerRep">{{ 'investment.ecfTrustAccount' | translate }}</div>
      <div class="bank-account-no font-weight-bold">{{ paymentMethodInfo?.accountName }}</div>
      <div class="font-size-large font-weight-extrabold">{{ paymentMethodInfo?.bankName }}</div>
      <div class="bank-account-no">{{ paymentMethodInfo?.accountNumber }}</div>
    </div>
  </div>
  <div
    class="row account-row"
    *ngIf="isPSTX && (type.key == PaymentType.Cheque || type.key == PaymentType.BankIn)"
  >
    <div class="col-md-6 col-sm-6">
      {{
        (role == Role.IssuerRep ? 'pitchIN Account Details' : 'investment.pitchINPstxTrustAccount')
          | translate
      }}
    </div>
    <div class="col-md-6 col-sm-6 description-panel">
      <div class="bank-account-no font-weight-bold">{{ paymentMethodInfo?.accountName }}</div>
      <div class="font-size-large font-weight-extrabold">{{ paymentMethodInfo?.bankName }}</div>
      <div class="bank-account-no">{{ paymentMethodInfo?.accountNumber }}</div>
    </div>
  </div>
  <div class="row account-row" *ngIf="type.key == PaymentType.Swift">
    <div class="col-md-6">
      <div class="tw-font-bold">{{ 'common.pitchPlatformsSdnBhd' | translate }}</div>
      <div
        [innerHTML]="
          'E-12-08, Menara Suezcap 2, <br/> KL Gateway, 2, Jalan Kerinchi, <br/> 59200 Kuala Lumpur, <br/> Malaysia'
        "
      ></div>
    </div>
    <div class="col-md-6 description-panel">
      <div class="tw-font-bold">{{ paymentMethodInfo?.accountName }}</div>
      <div class="tw-mb-1.5">{{ paymentMethodInfo?.accountNumber }}</div>
      <div class="tw-font-bold">{{ paymentMethodInfo?.bankName }}</div>
      <div [innerHTML]="paymentMethodInfo?.address"></div>
    </div>
  </div>
  <div class="row account-row" *ngIf="type.key == PaymentType.Swift">
    <div class="col-md-8 col-sm-7">{{ 'investment.swiftCode' | translate }}</div>
    <div class="col-md 4 col-sm-5 description-panel">
      <div class="font-size-large font-weight-bold">{{ paymentMethodInfo?.swiftCode }}</div>
    </div>
  </div>
  <div class="row account-row">
    <div class="col-md-8 col-sm-7">
      {{
        (receiptType == ReceiptType.Wallet ? 'mysec.topUpAmount' : 'investment.paymentAmount')
          | translate
      }}

      <span
        class="tw-block tw-text-sm tw-italic tw-text-black tw-text-opacity-70"
        *ngIf="type.key === PaymentType.Swift && role !== Role.IssuerRep"
      >
        {{ 'mysec.topUpDescription' | translate }}
      </span>
    </div>
    <div class="col-md 4 col-sm-5 description-panel">
      <div class="account font-size-large">
        <span class="text-primary font-weight-extrabold">{{ paymentAmount | numberSuffix }}</span>
        <span *ngIf="receiptType == ReceiptType.Investment"
          >({{ lotAmount }} {{ 'investment.lots_plural' | translate }})</span
        >
      </div>
    </div>
    <div class="col-md-12 pt-3" *ngIf="receiptType !== ReceiptType.Wallet">
      <div class="row note-row">
        <div class="col-md-9 col-sm-7 note">{{ 'investment.noteForReferenceNo' | translate }}</div>
        <div class="col-md 3 col-sm-5 description-panel">
          <div class="ref-no">{{ 'investment.refNo' | translate }}</div>
          <div class="font-size-large font-weight-bold">{{ referenceNo }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="row account-row border-bottom-none">
    <div class="col-md-8 col-sm-7 font-italic">
      {{
        (type.key == PaymentType.Cheque
          ? 'investment.uploadChequeReceiptOrEmail'
          : 'investment.uploadReceiptOrEmail'
        ) | translate
      }}
    </div>
    <div class="col-md 4 col-sm-5 description-panel">
      <div class="input-field" *ngIf="!isLoading">
        <div
          class="document-upload cursor-pointer"
          agmoUploader
          [accept]="accept"
          (selectFiles)="selectFile($event)"
          *ngIf="!document"
        >
          <div class="document-text text-left">{{ 'investment.uploadReceipt' | translate }}</div>
          <div class="pitchin-icon pitchin-icon-upload"></div>
        </div>
        <div class="document-upload uploaded" *ngIf="document">
          <div class="document-text text-left">{{ document.name }}</div>
          <div class="pitchin-icon pitchin-icon-delete" (click)="removeDocument(document)"></div>
        </div>
      </div>
      <agmo-loading *ngIf="isLoading"></agmo-loading>
    </div>
  </div>
</ng-template>
