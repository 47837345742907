<section class="section section-shaped">
  <div class="container">
    <div class="tw-flex tw-justify-center">
      <div class="tw-w-full tw-max-w-[500px] tw-overflow-hidden">
        <h2 class="text-center font-weight-extrabold mb-4">{{ 'common.login' | translate }}</h2>

        <div
          class="login-container tw-flex tw-transition-transform tw-duration-300"
          [class.active]="step === 2"
        >
          <!-- Step 1 -->
          <div class="tw-flex-[0_0_100%]">
            <form
              (ngSubmit)="submit()"
              [formGroup]="form"
              class="tw-overflow-hidden"
              [class.show-form-error]="showFormError"
            >
              <div class="mb-3">
                <input
                  class="form-control"
                  placeholder="{{ 'common.email' | translate }}"
                  formControlName="email"
                  type="text"
                  id="user_email"
                />
              </div>

              <div class="mb-3">
                <div class="password-panel">
                  <input
                    class="form-control"
                    [attr.type]="showPassword ? 'text' : 'password'"
                    placeholder="{{ 'login.password' | translate }}"
                    formControlName="password"
                    id="user_password"
                    maxlength="30"
                  />
                  <button type="button" class="show-password tw-bg-transparent" (click)="toggle()">
                    <i
                      class="fa"
                      [class.fa-eye-slash]="!showPassword"
                      [class.fa-eye]="showPassword"
                    ></i>
                  </button>
                </div>
              </div>

              <div class="btn-panel">
                <button type="submit" class="btn btn-primary tw-w-full">
                  {{ 'common.login' | translate }}
                </button>
              </div>

              <div class="row mt-3 forgot-password-panel">
                <div class="col-6">
                  <mat-checkbox formControlName="isRememberMe">
                    {{ 'login.rememberMe' | translate }}
                  </mat-checkbox>
                </div>
                <div class="col-6 text-right tw-text-sm">
                  <a routerLink="/forgot-password">{{ 'login.forgotPassword' | translate }}</a>
                </div>
              </div>
            </form>

            <hr />

            <h5 class="font-weight-bold text-center mt-3">
              {{ 'login.dontHaveAccount' | translate }}
              <a routerLink="/sign-up" class="font-weight-extrabold">{{
                'login.signUpNow' | translate
              }}</a>
            </h5>
          </div>
          <!-- Step 2 -->
          <div class="tw-flex-[0_0_100%]">
            <app-two-factor-auth-input
              *ngIf="step === 2"
              [showTitle]="false"
              [h2]="'twoFactor.enterCode'"
              [wrapperClass]="'tw-items-start !tw-p-0'"
              [description]="'twoFactor.enterSixDigitForLogin'"
              (otpChange)="submitWithOtp($event)"
            >
              <div before-cta class="tw-flex tw-justify-between tw-pb-2 tw-pt-2 tw-text-sm">
                <a (click)="handleBack()" class="tw-cursor-pointer tw-text-body"
                  >← {{ 'common.back' | translate }}</a
                >

                <a
                  href="https://support.pitchin.my/hc/en-us/articles/22249917767325"
                  class="p-link-secondary p-link-underline"
                  target="_blank"
                  >{{ 'common.havingTrouble' | translate }}</a
                >
              </div>
            </app-two-factor-auth-input>
          </div>
        </div>

        <!-- <div class="login-with mb-4 mt-5"><span>{{ 'login.orLoginWith' | translate }}</span></div>
                <div class="d-flex justify-content-center">
                    <div class="social-media">
                        <img alt="pitchIN Facebook Login" src="../../../assets/img/icons/common/facebook_icon_circle@2x.png"
                            (click)="signInOAuth(OAuthType.Facebook)" />
                    </div>
                    <div class="social-media">
                        <img alt="pitchIN Twitter Login" src="../../../assets/img/icons/common/twitter_icon_circle@2x.png"
                            (click)="signInOAuth(OAuthType.Twitter)" />
                    </div>
                    <div class="social-media">
                        <img alt="pitchIN Google Login" src="../../../assets/img/icons/common/google_icon_circle@2x.png"
                            (click)="signInOAuth(OAuthType.Google)" />
                    </div>
                </div> -->
      </div>
    </div>
  </div>
</section>
