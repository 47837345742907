import { AnnouncementCategory } from '../../enum/mysec/announcement-category.enum';
import { AnnouncementModel } from './announcements.model';

export class Announcement {
  body: string;
  category: AnnouncementCategory;
  companyId: number;
  description: string;
  id: number;
  publishedAt: string;
  referenceNo: string;
  title: string;

  constructor(model: AnnouncementModel) {
    this.body = model.body;
    this.category = model.category;
    this.companyId = model.companyId;
    this.description = model.description;
    this.id = model.id;
    this.publishedAt = model.publishedAt;
    this.referenceNo = model.referenceNo;
    this.title = model.title;
  }
}
