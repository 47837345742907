import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SharedModule } from '../../shared.module';

@Injectable({
  providedIn: SharedModule,
})
export class HeaderService {
  constructor(
    private titleService: Title,
    private metaService: Meta,
  ) {}

  setHeaderTag(name: string) {
    this.titleService.setTitle(name);
    //this.updateNameTag('description', tagline);
    //this.updateNameTag('image', logoUrl);
    this.updatePropertyTag('og:title', name);
    //this.updatePropertyTag('og:description', tagline);
    //this.updatePropertyTag('og:image', logoUrl);
    this.updateNameTag('twitter:site_name', name);
    //this.updateNameTag('twitter:description', tagline);
    //this.updateNameTag('twitter:image', logoUrl);
  }

  updateNameTag(name: string, content: string) {
    this.metaService.updateTag({
      name: name,
      content: content,
    });
  }

  updatePropertyTag(name: string, content: string) {
    this.metaService.updateTag({
      property: name,
      content: content,
    });
  }

  addTag(name: string, content: string) {
    this.metaService.addTag({
      name: name,
      content: content,
    });
  }

  getTag(name: string) {
    return this.metaService.getTag(name);
  }

  removeTag(name: string) {
    return this.metaService.removeTag(name);
  }
}
