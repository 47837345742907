import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DuitNowBank } from '../../../../../core/models/payment/duit-now-bank';
import { ErrorService } from '../../../../..//core/services/error.service';
import { PaymentService } from '../../../../../core/services/api/payment.service';
import { StorageService } from '../../../../../core/services/storage.service';
import { PaymentType } from '../../../../../core/enum/payment-type.enum';
import { Payment } from '../../../../../core/models/payment/payment';
import { AccountType } from '../../../../../core/enum/account-type.enum';
import { Role } from '../../../../../core/enum/role.enum';

@Component({
  selector: 'app-duitnow-payment',
  templateUrl: './duitnow-payment.component.html',
  styleUrls: ['./duitnow-payment.component.scss'],
})
export class DuitnowPaymentComponent implements OnInit {
  @Input() paymentAmount: number;
  @Output() choosePayment = new EventEmitter<any>();

  form: UntypedFormGroup;
  showFormError: boolean = false;
  accountTypes: string[] = [];
  banks: DuitNowBank[];

  AccountType: typeof AccountType = AccountType;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private errorService: ErrorService,
    private paymentService: PaymentService,
    private storageService: StorageService,
  ) {}

  ngOnInit() {
    this.accountTypes =
      this.storageService.role == Role.IndividualInvestor
        ? [AccountType[AccountType.B2C]]
        : [AccountType[AccountType.B2B1]];
    this.buildForm();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      businessModel: [this.accountTypes[0], Validators.required],
      bankID: [null, Validators.required],
    });

    this.getBankList();
  }

  getBankList() {
    this.paymentService.getDuitNowBankList(this.form.value.businessModel).subscribe({
      next: (data) => {
        this.banks = data;
      },
      error: (err) => {
        this.errorService.showError(err);
      },
    });
  }

  submit() {
    if (this.form.invalid) {
      this.showFormError = true;
      return;
    }

    let paymentModel = {
      paymentType: PaymentType.DuitNow,
      businessModel: this.form.value.businessModel,
      bankID: this.form.value.bankID,
    } as Payment;

    this.choosePayment.emit(paymentModel);
  }
}
