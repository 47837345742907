import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PagedResult } from '../../../shared/models/paged-result/paged-result';
import { Attachment } from '../../models/attachment/attachment';

@Injectable()
export class InvestmentService {
  private route = environment.apiServer + 'Api/v1/users/{userId}/{role}';

  constructor(public http: HttpClient) {}

  getInvestmentEligibility(): Observable<any> {
    return this.http.get(this.route + '/investment/eligibility').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  cancelInvestment(investmentId: number): Observable<any> {
    return this.http.post(this.route + '/investment/' + investmentId + '/refund', null);
  }

  getInvestmentDocuments(
    campaignId: number,
    currentPage: number,
    take: number,
  ): Observable<PagedResult<Attachment>> {
    let params = {
      currentPage: currentPage.toString(),
      take: take.toString(),
    };

    return this.http
      .get(this.route + '/campaign/' + campaignId + '/investment/documents', { params: params })
      .pipe(
        map((data: any) => {
          return {
            data: data.result.data || [],
            total: data.result.totalCount,
          };
        }),
      );
  }

  getInvestmentDetail(sellerOrderNo: string): Observable<any> {
    return this.http.get(this.route + '/investment/refno/' + sellerOrderNo).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }
}
