import { Location, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CKEditorComponent, ChangeEvent } from '@ckeditor/ckeditor5-angular';
import plainTextToHtml from '@ckeditor/ckeditor5-clipboard/src/utils/plaintexttohtml';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { CampaignDetailApprovalStatus } from '../../../core/enum/campaign-detail-approval-status';
import { CampaignFormComponentType } from '../../../core/enum/device-type.enum copy';
import { RaiseFundApplicationProcessModule } from '../../../core/enum/raise-fund-application-process-module.enum';
import { CampaignInfo } from '../../../core/models/campaign/campaign-info';
import { CreateCampaign } from '../../../core/models/campaign/create-campaign';
import { EDITOR_CONFIG, LINK_EDITOR_CONFIG } from '../../../core/models/editor/editor-config';
import { CampaignMenuItem } from '../../../core/models/menu/tab-menu-item';
import { Term } from '../../../core/models/share/term';
import { CampaignService } from '../../../core/services/api/campaign.service';
import { RaiseService } from '../../../core/services/api/raise.service';
import { DefaultVideoURLPattern } from '../../../core/services/constant';
import { ErrorService } from '../../../core/services/error.service';
import { UploadAdapter } from '../../../core/services/upload-adapter';
import { WhitespaceValidator } from '../../../core/validator/whitespace.validators';
import { BlockUiService } from '../../../shared/services/blockUi/block-ui.service';
import { MyApplicationFilterService } from '../my-application/my-application-filter.service';

@Component({
  selector: 'app-campaign-form',
  templateUrl: './campaign-form.component.html',
  styleUrls: ['./campaign-form.component.scss'],
})
export class CampaignFormComponent implements OnInit {
  campaignFormComponentType: CampaignFormComponentType = CampaignFormComponentType.New;
  showFormError: boolean = false;
  form: UntypedFormGroup;
  campaign: CreateCampaign;
  campaignInfo: CampaignInfo;
  fundRaiseApplicationId: number;
  campaignId: number;
  selectedTab: string = 'title';
  isEditOngoingCampaign: boolean = false;

  CampaignDetailApprovalStatus: typeof CampaignDetailApprovalStatus = CampaignDetailApprovalStatus;

  tabs: CampaignMenuItem[] = [
    { name: 'raise.summary', route: 'summary' },
    { name: 'raise.problem', route: 'problem' },
    { name: 'raise.solution', route: 'solution' },
    { name: 'raise.product', route: 'product' },
    { name: 'raise.traction', route: 'traction' },
    { name: 'raise.customers', route: 'customers' },
    { name: 'raise.businessModel', route: 'businessModel' },
    { name: 'raise.market', route: 'market' },
    { name: 'raise.competition', route: 'competition' },
    { name: 'raise.funding', route: 'funding' },
    { name: 'raise.vision', route: 'vision' },
    { name: 'raise.founders', route: 'founders' },
    { name: 'raise.team', route: 'team' },
    { name: 'raise.investmentTerms', route: 'investmentTerms' },
    { name: 'raise.disclosure', route: 'disclosure' },
  ];

  Editor: any;
  ckEditorConfig = EDITOR_CONFIG;
  ckLinkEditorConfig = LINK_EDITOR_CONFIG;
  ckEditorLoaded = false;
  isLoading = false;
  mode: string;

  @ViewChild('editor') editorComponent: CKEditorComponent;

  constructor(
    private errorService: ErrorService,
    private formBuilder: UntypedFormBuilder,
    private blockUiService: BlockUiService,
    private raiseService: RaiseService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platform: Object,
    private translateService: TranslateService,
    private campaignService: CampaignService,
    private myApplicationFilterService: MyApplicationFilterService,
  ) {}

  ngOnInit() {
    this.campaignFormComponentType = this.activatedRoute.snapshot.data.type;
    this.fundRaiseApplicationId = this.activatedRoute.snapshot.params.fundRaiseApplicationId;
    this.campaignId = this.activatedRoute.snapshot.params.campaignId;
    this.isEditOngoingCampaign = [
      CampaignFormComponentType.NewDraft,
      CampaignFormComponentType.EditDraft,
    ].includes(this.campaignFormComponentType);

    this.buildForm();

    if (
      [CampaignFormComponentType.Edit, CampaignFormComponentType.NewDraft].includes(
        this.campaignFormComponentType,
      )
    ) {
      this.getCampaign();
    }

    if ([CampaignFormComponentType.EditDraft].includes(this.campaignFormComponentType)) {
      this.getCampaignDraft();
    }

    if (isPlatformBrowser(this.platform)) {
      const Editor = require('../../../../assets/scripts/ckeditor5/ckeditor');
      this.Editor = Editor.Editor;
      this.ckEditorLoaded = true;
      this.ckEditorConfig.extraPlugins = [
        function (editor: any) {
          editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new UploadAdapter(loader);
          };
        },
      ];
    }
  }

  buildForm() {
    this.form = this.formBuilder.group({
      name: ['', !this.isEditOngoingCampaign ? Validators.required : ''],
      videoUrl: [
        '',
        Validators.compose([Validators.required, Validators.pattern(DefaultVideoURLPattern)]),
      ],
      summary: ['', Validators.required],
      problem: [''],
      solution: [''],
      product: [''],
      traction: [''],
      customers: [''],
      businessModel: [''],
      market: [''],
      competition: [''],
      funding: [''],
      vision: [''],
      founders: [''],
      team: [''],
      investmentTerms: [''],
      disclosure: [
        this.translateService.instant('raise.disclosurePlaceholder'),
        Validators.required,
      ],
      campaignFAQs: this.formBuilder.array([]),
      campaignRisks: this.formBuilder.array([]),
    });
  }

  buildArrayForm(term?: Term) {
    return this.formBuilder.group({
      id: [term ? term.id : null],
      title: [term ? term.title : '', [Validators.required, WhitespaceValidator()]],
      description: [term ? term.description : '', [Validators.required, WhitespaceValidator()]],
      showMore: [true],
    });
  }

  get campaignFAQsFormControl() {
    return <UntypedFormArray>this.form.controls.campaignFAQs;
  }

  get campaignRisksFormControl() {
    return <UntypedFormArray>this.form.controls.campaignRisks;
  }

  showMore(form: UntypedFormGroup) {
    form.controls.showMore.setValue(!form.value.showMore);
  }

  removeArrayForm(index: number, name: string) {
    if (this[`${name}` + `FormControl`].controls.length > 0)
      this[`${name}` + `FormControl`].removeAt(index);
  }

  addArrayForm(name: string) {
    if (this.form.controls[name].invalid) {
      this.showFormError = true;
      return;
    }

    if (this[`${name}` + `FormControl`].value.length > 0) {
      this[`${name}` + `FormControl`].controls.forEach((form: any) => {
        form.controls.showMore.setValue(false);
      });
    }

    this[`${name}` + `FormControl`].push(this.buildArrayForm());
    this.showFormError = false;
  }

  getCampaign() {
    this.isLoading = true;

    this.raiseService
      .getCampaignDetails(this.campaignId, this.fundRaiseApplicationId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.campaign = data;
          this.campaignInfo = this.campaign.campaignInfo;
          this.form.patchValue({
            name: this.campaign.name,
            videoUrl: this.campaign.campaignInfo.videoUrl,
            summary: this.campaign.campaignInfo.summary || '',
            problem: this.campaign.campaignInfo.problem || '',
            solution: this.campaign.campaignInfo.solution || '',
            product: this.campaign.campaignInfo.product || '',
            traction: this.campaign.campaignInfo.traction || '',
            customers: this.campaign.campaignInfo.customers || '',
            businessModel: this.campaign.campaignInfo.businessModel || '',
            market: this.campaign.campaignInfo.market || '',
            competition: this.campaign.campaignInfo.competition || '',
            funding: this.campaign.campaignInfo.funding || '',
            vision: this.campaign.campaignInfo.vision || '',
            founders: this.campaign.campaignInfo.founders || '',
            team: this.campaign.campaignInfo.team || '',
            investmentTerms: this.campaign.campaignInfo.investmentTerms || '',
            disclosure: this.campaign.campaignInfo.disclosure || '',
          });

          if (this.campaign.campaignFAQs && this.campaign.campaignFAQs.length > 0) {
            this.campaign.campaignFAQs.forEach((faq) => {
              this.campaignFAQsFormControl.push(this.buildArrayForm(faq));
            });
          }

          if (this.campaign.campaignRisks && this.campaign.campaignRisks.length > 0) {
            this.campaign.campaignRisks.forEach((risk) => {
              this.campaignRisksFormControl.push(this.buildArrayForm(risk));
            });
          }
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  getCampaignDraft() {
    this.isLoading = true;

    this.campaignService
      .getCampaignDraft(this.campaignId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.campaignInfo = data;
          this.form.patchValue({
            videoUrl: this.campaignInfo.videoUrl,
            summary: this.campaignInfo.summary || '',
            problem: this.campaignInfo.problem || '',
            solution: this.campaignInfo.solution || '',
            product: this.campaignInfo.product || '',
            traction: this.campaignInfo.traction || '',
            customers: this.campaignInfo.customers || '',
            businessModel: this.campaignInfo.businessModel || '',
            market: this.campaignInfo.market || '',
            competition: this.campaignInfo.competition || '',
            funding: this.campaignInfo.funding || '',
            vision: this.campaignInfo.vision || '',
            founders: this.campaignInfo.founders || '',
            team: this.campaignInfo.team || '',
            investmentTerms: this.campaignInfo.investmentTerms || '',
            disclosure: this.campaignInfo.disclosure || '',
          });
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  submit() {
    if (this.form.invalid) {
      this.showFormError = true;
      return;
    }

    let campaign = this.form.value;
    campaign.campaignId = this.campaignId;

    this.blockUiService.open();

    let observable = this.campaignId
      ? this.raiseService.updateCampaign(campaign, this.fundRaiseApplicationId)
      : this.raiseService.createCampaign(campaign, this.fundRaiseApplicationId);

    observable.pipe(finalize(() => this.blockUiService.close())).subscribe(
      (data) => {
        this.myApplicationFilterService.filter.selectedTab =
          RaiseFundApplicationProcessModule.CreateCampaign;
        this.back();
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  submitCampaignDraft(isDraft: boolean) {
    if (this.form.invalid) {
      this.showFormError = true;
      return;
    }

    let campaignInfo = this.form.value;
    campaignInfo.submitDraft = isDraft;

    this.blockUiService.open();

    this.campaignService
      .submitCampaignDraft(this.campaignId, campaignInfo)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        (data) => {
          this.back();
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  deleteCampaignDraft() {
    this.blockUiService.open();

    this.campaignService
      .deleteCampaignDraft(this.campaignId)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        (data) => {
          this.back();
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  back() {
    this.location.back();
  }

  scrollTo(route: string) {
    this.selectedTab = route;
    let el = document.getElementById(route);
    el.scrollIntoView({ behavior: 'smooth' });
  }

  onSectionChange(route: string) {
    this.selectedTab = route;
  }

  removeRichText({ editor }: ChangeEvent) {
    let editingView = editor.editing.view;

    editingView.document.on('clipboardInput', (evt, data) => {
      if (editor.isReadOnly || data.method != 'paste') {
        return;
      }

      const dataTransfer = data.dataTransfer;
      let content = plainTextToHtml(dataTransfer.getData('text/plain'));
      data.content = editor.data.htmlProcessor.toView(content);

      editingView.scrollToTheSelection();
    });
  }

  getConfig(controlField: string) {
    let config = {
      ...this.ckEditorConfig,
      placeholder: this.translateService.instant('raise.' + controlField + 'Placeholder'),
    };

    return config;
  }
}
