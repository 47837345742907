import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { TCFCampaign } from '../../models/campaign/tcf-campaign.model';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TCFCampaignService {
  private apiUrl = environment.ieoApiServer;

  constructor(private http: HttpClient) {}

  getCampaignsFrontPage(): Observable<{
    liveOffer: TCFCampaign[];
    preLive: TCFCampaign[];
    coolingDown: TCFCampaign[];
  }> {
    const params = {
      noToken: 'noToken',
    };

    return this.http.get<{
      liveOffer: TCFCampaign[];
      preLive: TCFCampaign[];
      coolingDown: TCFCampaign[];
    }>(`${this.apiUrl}api/campaigns/front-page`, { params: params });
  }

  getTotalInvestments(): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}api/investor/investments/total`).pipe(
      map((data: any) => {
        // The value is in cents, so we divide by 100 to get the actual value
        return Number(data) / 100;
      }),
    );
  }
}
