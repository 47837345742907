<div role="status" class="tw-inline-block tw-w-full tw-animate-pulse">
  <div
    class="tw-rounded tw-bg-gray-200"
    [ngClass]="className"
    [style]="{
      width: computedWidth,
      height: computedHeight,
      'margin-bottom': computedMarginBottom,
    }"
  ></div>
</div>
