import { Bonus } from './bonus';

export class BonusModel {
  InvestmentLots: number;
  BonusPercentage: number;

  constructor(model: Bonus) {
    this.InvestmentLots = model.investmentLots;
    this.BonusPercentage = model.bonusPercentage;
  }
}
