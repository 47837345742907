import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Item } from '../../models/share/item';
import { Country } from '../../models/share/country';
import { PaymentMethodInfo } from '../../models/payment/payment-method-info';
import { SectorList } from '../../models/company/sector-list';
import { Sector } from '../../models/company/sector';
import { Announcement } from '../../models/setting/announcement';

@Injectable()
export class SettingService {
  private route = environment.apiServer + 'Api/v1/Settings';
  notFoundPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(public http: HttpClient) {}

  getSectors(keywords: string): Observable<SectorList> {
    const params = {
      noToken: 'noToken',
      name: keywords,
    };

    return this.http.get(this.route + '/sector/search', { params: params }).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getSectorsByIds(sectorIds?: Array<number>): Observable<Sector[]> {
    const params = {
      noToken: 'noToken',
      sectorIds: sectorIds,
    };

    return this.http.get(this.route + '/sector/ids', { params: params }).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getBankList(): Observable<Item[]> {
    return this.http.get(this.route + '/banks').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getCountries(): Observable<Country[]> {
    return this.http.get(this.route + '/countries').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getStates(stateId: number): Observable<Item[]> {
    return this.http.get(this.route + '/countries/' + stateId + '/states').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getPaymentMethodInfo(campaignId: number): Observable<PaymentMethodInfo> {
    return this.http.get(this.route + '/paymentMethodInfos/campaign/' + campaignId).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getPstxPaymentMethodInfo(): Observable<PaymentMethodInfo> {
    return this.http.get(this.route + '/paymentMethodInfos/PSTX').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getEddDropdown(): Observable<any> {
    return this.http.get(this.route + '/dropdowns/edd').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getProfileDropdown(): Observable<any> {
    return this.http.get(this.route + '/dropdowns/investorProfile').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getCorporateRepDropdown(): Observable<any> {
    return this.http.get(this.route + '/dropdowns/companyRepProfile').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getCompanyDropdown(): Observable<any> {
    return this.http.get(this.route + '/dropdowns/companyProfile').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getRssUrl(): Observable<string> {
    const params = {
      noToken: 'noToken',
    };

    return this.http.get(this.route + '/newsfeed', { params: params }).pipe(
      map((data: any) => {
        return data.result.url;
      }),
    );
  }

  getUiSettings(): Observable<Announcement> {
    const params = {
      noToken: 'noToken',
    };

    return this.http.get(this.route + '/ui/settings', { params: params }).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }
}
