import { Component } from '@angular/core';

@Component({
  selector: 'app-website-terms-conditions',
  templateUrl: './website-terms-conditions.component.html',
  styleUrls: ['./website-terms-conditions.component.scss'],
})
export class WebsiteTermsConditionsComponent {
  constructor() {}
}
