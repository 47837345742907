<div *ngIf="!isLoading">
  <ng-container *ngIf="portfolioListModel && portfolioListModel.length > 0">
    <!-- Desktop table -->
    <div class="p-table__wrapper max-md:tw-hidden">
      <div class="p-table__body">
        <mat-table class="responsive-table p-table" [dataSource]="portfolioListModel">
          <ng-container matColumnDef="Company">
            <mat-header-cell *matHeaderCellDef>{{
              'mysec.companyAndCounter' | translate
            }}</mat-header-cell>
            <mat-cell *matCellDef="let item">
              <ng-container
                *ngIf="item.tickerCode && item.status !== ShareMarketStatus.Dehosting; else noRoute"
              >
                <div class="cursor-pointer" [routerLink]="['/pstx', item.tickerCode]">
                  <ng-template
                    *ngTemplateOutlet="companyName; context: { item: item }"
                  ></ng-template>
                </div>
              </ng-container>
              <ng-template #noRoute>
                <div>
                  <ng-template
                    *ngTemplateOutlet="companyName; context: { item: item }"
                  ></ng-template>
                </div>
              </ng-template>
              <ng-template #companyName let-item="item">
                <app-thumbnail-group
                  [name]="item?.company?.brandName"
                  [description]="item.tickerCode ? item?.tickerCode.toUpperCase() : null"
                  [imageUrl]="item?.company?.companyLogoUrl"
                >
                </app-thumbnail-group>

                <div class="tw-mt-1 tw-pl-[50px]">
                  <a
                    [routerLink]="['/pstx', item.tickerCode]"
                    *ngIf="
                      item.tickerCode &&
                      item.status !== ShareMarketStatus.Dehosting &&
                      item.status !== ShareMarketStatus.Inactive
                    "
                    class="p-link-secondary tw-text-xs max-md:tw-hidden"
                  >
                    <span>{{ 'mysec.buyMore' | translate }}</span> →
                  </a>
                </div>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="TickerCode">
            <mat-header-cell *matHeaderCellDef>
              <span class="d-flex align-items-center">
                {{ 'Counter' | translate }}
                <button
                  type="button"
                  #tooltip="matTooltip"
                  (click)="tooltip.toggle()"
                  class="btn-transparent"
                  matTooltip="{{ 'portfolio.tickerCodeDesc' | translate }}"
                >
                  <div class="pitchin-icon pitchin-icon-help-circle p-help-icon"></div>
                </button>
              </span>
            </mat-header-cell>

            <mat-cell *matCellDef="let item">
              <ng-container
                *ngIf="
                  item.tickerCode &&
                  item.status !== ShareMarketStatus.Dehosting &&
                  item.status !== ShareMarketStatus.Inactive
                "
              >
                <a
                  [routerLink]="['/pstx', item.tickerCode]"
                  class="p-link-underline p-link-primary tw-uppercase"
                  >{{ item.tickerCode }}</a
                >
              </ng-container>
              <ng-container
                *ngIf="
                  item.tickerCode &&
                  (item.status === ShareMarketStatus.Dehosting ||
                    item.status === ShareMarketStatus.Inactive)
                "
              >
                <div class="tw-uppercase">{{ item.tickerCode }}</div>
              </ng-container>
              <ng-container *ngIf="!item.tickerCode">
                <div class="p-text-tag--round p-text-tag--dot">
                  {{ 'common.pending' | translate }}
                </div>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ShareQty">
            <mat-header-cell *matHeaderCellDef>{{
              'portfolio.shareQty' | translate
            }}</mat-header-cell>
            <mat-cell
              *matCellDef="let item"
              [ngClass]="'tw-flex tw-justify-between tw-gap-2 tw-tabular-nums'"
            >
              <ng-container *ngTemplateOutlet="shareQty; context: { item: item }"></ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Ownership">
            <mat-header-cell *matHeaderCellDef>
              {{ 'portfolio.ownership' | translate }}
              <button
                type="button"
                #tooltip="matTooltip"
                (click)="tooltip.toggle()"
                class="btn-transparent"
                matTooltip="{{ 'portfolio.ownershipTooltip' | translate }}"
              >
                <div class="pitchin-icon pitchin-icon-help-circle p-help-icon"></div>
              </button>
            </mat-header-cell>
            <mat-cell *matCellDef="let item">
              <div class="tw-flex tw-flex-wrap tw-gap-1">
                <div
                  class="p-text-tag--round p-tag--info"
                  *ngIf="
                    [ShareOwnership.Direct, ShareOwnership.Both].includes(item.portfolio?.ownership)
                  "
                >
                  {{ 'portfolio.Direct' | translate }}
                </div>
                <div
                  class="p-text-tag--round p-tag--warning"
                  *ngIf="
                    [ShareOwnership.Nominee, ShareOwnership.Both].includes(
                      item.portfolio?.ownership
                    )
                  "
                >
                  {{ 'portfolio.Nominee' | translate }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="PurchasedPrice">
            <mat-header-cell *matHeaderCellDef [ngClass]="'tw-justify-end'">
              {{ 'portfolio.investmentAmount' | translate }}
              <button
                type="button"
                #tooltipInvest="matTooltip"
                (click)="tooltipInvest.toggle()"
                class="btn-transparent !tw-pr-0"
                matTooltip="{{ 'portfolio.investedAmountTooltip' | translate }}"
              >
                <div class="pitchin-icon pitchin-icon-help-circle p-help-icon"></div>
              </button>
            </mat-header-cell>
            <mat-cell
              *matCellDef="let item"
              [ngClass]="'tw-justify-end tw-text-right tw-tabular-nums'"
            >
              <ng-container
                *ngTemplateOutlet="investmentAmount; context: { item: item }"
              ></ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="MarketPrice">
            <mat-header-cell *matHeaderCellDef [ngClass]="'tw-justify-end'">
              {{ 'portfolio.marketValue' | translate }}
              <button
                type="button"
                #tooltipMarket="matTooltip"
                (click)="tooltipMarket.toggle()"
                class="btn-transparent !tw-pr-0"
                matTooltip="{{ 'portfolio.totalMarketValueTooltip' | translate }}"
              >
                <div class="pitchin-icon pitchin-icon-help-circle p-help-icon"></div>
              </button>
            </mat-header-cell>
            <mat-cell
              *matCellDef="let item"
              [ngClass]="'tw-justify-end tw-text-right tw-tabular-nums'"
            >
              <ng-container *ngTemplateOutlet="marketPrice; context: { item: item }"></ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Profit">
            <mat-header-cell *matHeaderCellDef [ngClass]="'tw-justify-end'">
              {{ 'portfolio.unrealisedGainLoss' | translate }}
              <button
                type="button"
                #tooltipGain="matTooltip"
                (click)="tooltipGain.toggle()"
                class="btn-transparent !tw-pr-0"
                matTooltip="{{ 'portfolio.unrealisedGainLossTooltip' | translate }}"
              >
                <div class="pitchin-icon pitchin-icon-help-circle p-help-icon"></div>
              </button>
            </mat-header-cell>
            <mat-cell
              *matCellDef="let item"
              [ngClass]="'tw-flex-col tw-items-end tw-justify-center tw-gap-1 tw-tabular-nums'"
            >
              <ng-container *ngTemplateOutlet="profit; context: { item: item }"></ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell *matHeaderCellDef>
              {{ 'common.actions' | translate }}
            </mat-header-cell>
            <mat-cell
              *matCellDef="let item"
              [ngClass]="'tw-flex-col tw-items-start tw-justify-center tw-gap-2.5'"
            >
              <!-- Show announcement in past 7 days -->
              <ng-container *ngIf="item.tickerCode && item?.totalUpdatesInLast7Days > 0">
                <div>
                  <span class="dot--glowing tw-mr-1"></span>
                  <a
                    [routerLink]="['/pstx', item.tickerCode]"
                    [queryParams]="{ selectedTab: 6 }"
                    class="btn-transparent p-link-secondary tw-text-xs"
                  >
                    {{
                      'portfolio.viewNoAnnouncementsInPast7d'
                        | translate: { count: item.totalUpdatesInLast7Days }
                    }}
                    →
                  </a>
                </div>
              </ng-container>

              <!-- Show all announcements, prevent showing two links, so only show either one -->
              <ng-container
                *ngIf="item.tickerCode && item?.hasUpdates && item?.totalUpdatesInLast7Days === 0"
              >
                <a
                  [routerLink]="['/pstx', item.tickerCode]"
                  [queryParams]="{ selectedTab: 6 }"
                  class="btn-transparent p-link-secondary tw-text-xs"
                >
                  {{ 'portfolio.viewAllAnnouncements' | translate }} →
                </a>
              </ng-container>

              <!-- Download digital cert -->
              <ng-container *ngIf="item.digitalCert && item.digitalCert?.url">
                <button
                  type="button"
                  class="btn-transparent p-link-secondary tw-p-0 tw-text-xs"
                  (click)="openUrl(item.digitalCert?.url)"
                >
                  {{ 'portfolio.downloadDigitalCert' | translate }} →
                </button>
              </ng-container>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayColumns"> </mat-header-row>
          <mat-row *matRowDef="let item; columns: displayColumns"> </mat-row>
        </mat-table>
      </div>
    </div>

    <!-- Mobile table -->
    <div class="tw-space-y-4 md:tw-hidden">
      <div
        *ngFor="let item of portfolioListModel"
        class="tw-rounded-lg tw-border tw-border-divider"
      >
        <!-- Header -->
        <button
          type="button"
          class="tw-flex tw-w-full tw-items-center tw-justify-between tw-bg-transparent tw-px-4 tw-py-3 tw-text-left"
          (click)="item.isCollapsed = !item.isCollapsed"
        >
          <!-- Logo -->
          <div
            class="tw-flex-1 tw-shrink-0"
            [class.tw-max-w-[55%]]="
              item.tickerCode &&
              item.status !== ShareMarketStatus.Dehosting &&
              item.status !== ShareMarketStatus.Inactive
            "
          >
            <app-thumbnail-group
              [name]="item?.company?.brandName"
              [description]="item?.company?.name"
              [imageUrl]="item?.company?.companyLogoUrl"
            >
            </app-thumbnail-group>
          </div>

          <!-- Indicative amount -->
          <div class="tw-relative tw-flex tw-items-center tw-gap-2.5">
            <div
              class="tw-transform tw-transition-transform tw-duration-100 max-md:tw-text-right max-sm:tw-flex max-sm:tw-flex-col max-sm:tw-flex-wrap max-sm:tw-items-end max-sm:tw-justify-end"
              [class.tw-invisible]="!item.isCollapsed"
              [class.tw-translate-y-0]="item.isCollapsed"
              [class.-tw-translate-y-2]="!item.isCollapsed"
            >
              <div>
                <span
                  class="p-text-tag--round md:tw-hidden"
                  *ngIf="
                    item.portfolio.unrealisedGainLossPercentage &&
                    item.portfolio.unrealisedGainLossPercentage !== 0
                  "
                  [class.p-tag--error]="item?.portfolio?.unrealisedGainLossPercentage < 0"
                  [class.p-tag--success]="item?.portfolio?.unrealisedGainLossPercentage > 0"
                >
                  <span
                    class="fa tw-mr-1"
                    [class.fa-arrow-up]="item?.portfolio?.unrealisedGainLossPercentage > 0"
                    [class.fa-arrow-down]="item?.portfolio?.unrealisedGainLossPercentage < 0"
                  >
                  </span>
                  {{
                    (item.portfolio.unrealisedGainLossPercentage < 0
                      ? item.portfolio.unrealisedGainLossPercentage * -1
                      : item.portfolio.unrealisedGainLossPercentage
                    ) | number: '1.0-0'
                  }}%
                </span>

                <ng-container
                  *ngTemplateOutlet="marketPrice; context: { item: item }"
                ></ng-container>
              </div>
            </div>

            <div
              class="tw-absolute tw-right-10 tw-transform tw-transition-transform tw-duration-300"
              [class.tw-invisible]="item.isCollapsed"
              [class.tw-translate-y-0]="!item.isCollapsed"
              [class.tw-translate-y-2]="item.isCollapsed"
            >
              <a
                [routerLink]="['/pstx', item.tickerCode]"
                *ngIf="
                  item.tickerCode &&
                  item.status !== ShareMarketStatus.Dehosting &&
                  item.status !== ShareMarketStatus.Inactive
                "
                class="btn btn-black-border btn-md"
              >
                {{ 'mysec.buyMore' | translate }}
              </a>
            </div>

            <span
              class="tw-inline-block tw-origin-center tw-transform tw-transition-transform"
              [class.tw-rotate-180]="!item.isCollapsed"
            >
              <p-icon name="chevron-down"></p-icon>
            </span>
          </div>
        </button>

        <!-- Body -->
        <div [ngbCollapse]="item.isCollapsed">
          <div>
            <hr class="tw-mx-4 tw-my-0" />

            <div class="tw-px-4 tw-py-3">
              <div class="tw-grid tw-grid-cols-3 tw-gap-2.5 max-sm:tw-grid-cols-2">
                <!-- Share quantity -->
                <div>
                  <div class="overview-label">
                    {{ 'portfolio.shareQty' | translate }}
                  </div>
                  <div class="overview-value !tw-font-normal">
                    <ng-container
                      *ngTemplateOutlet="shareQty; context: { item: item }"
                    ></ng-container>
                  </div>
                </div>

                <!-- Investment amount -->
                <div>
                  <div class="overview-label !tw-flex tw-items-center">
                    {{ 'portfolio.invAmount' | translate }}

                    <button
                      type="button"
                      #tooltipInvest="matTooltip"
                      (click)="tooltipInvest.toggle()"
                      class="btn-transparent !tw-pr-0"
                      matTooltip="{{ 'portfolio.investedAmountTooltip' | translate }}"
                    >
                      <div class="pitchin-icon pitchin-icon-help-circle p-help-icon"></div>
                    </button>
                  </div>
                  <div class="overview-value !tw-font-normal">
                    <ng-container
                      *ngTemplateOutlet="investmentAmount; context: { item: item }"
                    ></ng-container>
                  </div>
                </div>

                <!-- Indicative amount -->
                <div>
                  <div class="overview-label !tw-flex tw-items-center">
                    {{ 'portfolio.marketValue' | translate }}

                    <button
                      type="button"
                      #tooltipMarket="matTooltip"
                      (click)="tooltipMarket.toggle()"
                      class="btn-transparent !tw-pr-0"
                      matTooltip="{{ 'portfolio.totalMarketValueTooltip' | translate }}"
                    >
                      <div class="pitchin-icon pitchin-icon-help-circle p-help-icon"></div>
                    </button>
                  </div>
                  <div class="overview-value !tw-font-normal">
                    <div
                      class="tw-flex tw-flex-row-reverse tw-justify-end tw-gap-1 max-sm:tw-flex-wrap-reverse"
                    >
                      <ng-container
                        *ngTemplateOutlet="marketPrice; context: { item: item }"
                      ></ng-container>
                    </div>
                  </div>
                </div>

                <!-- Unrealised gain -->
                <div>
                  <div class="overview-label !tw-flex tw-items-center">
                    {{ 'portfolio.unrealisedGainLoss' | translate }}

                    <button
                      type="button"
                      #tooltipGain="matTooltip"
                      (click)="tooltipGain.toggle()"
                      class="btn-transparent !tw-pr-0"
                      matTooltip="{{ 'portfolio.unrealisedGainLossTooltip' | translate }}"
                    >
                      <div class="pitchin-icon pitchin-icon-help-circle p-help-icon"></div>
                    </button>
                  </div>
                  <div class="overview-value !tw-font-normal">
                    <div class="tw-flex tw-flex-row tw-flex-wrap tw-gap-1">
                      <ng-container
                        *ngTemplateOutlet="profit; context: { item: item }"
                      ></ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Footer -->
            <ng-container *ngIf="(item.tickerCode && item?.hasUpdates) || item.digitalCert?.url">
              <hr class="tw-mx-4 tw-my-0" />

              <div class="tw-flex tw-gap-3 tw-px-4 tw-py-3 max-sm:tw-flex-wrap">
                <div class="tw-flex-1" *ngIf="item.tickerCode && item?.hasUpdates">
                  <a
                    [routerLink]="['/pstx', item.tickerCode]"
                    [queryParams]="{ selectedTab: 6 }"
                    class="btn btn-black-border btn-md tw-w-full"
                  >
                    <span
                      *ngIf="item?.totalUpdatesInLast7Days > 0"
                      class="dot--glowing tw-mr-1"
                    ></span>
                    {{ 'portfolio.viewPastAnnouncements' | translate }}
                  </a>
                </div>

                <div class="tw-flex-1" *ngIf="item.digitalCert && item.digitalCert?.url">
                  <button
                    type="button"
                    class="btn btn-black-border btn-md tw-w-full"
                    (click)="openUrl(item.digitalCert?.url)"
                  >
                    {{ 'portfolio.downloadDigitalCert' | translate }}
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- Pagination -->
    <div class="tw-my-8 tw-flex tw-justify-center" *ngIf="total > params.take">
      <div class="paging">
        <ngb-pagination
          [collectionSize]="total"
          [(pageSize)]="params.take"
          [(page)]="params.currentPage"
          (pageChange)="getPortfolio()"
          [maxSize]="5"
          [rotate]="true"
        >
          <ng-template ngbPaginationPrevious><i class="fa fa-chevron-left"></i></ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
          <ng-template ngbPaginationNext><i class="fa fa-chevron-right"></i></ng-template>
        </ngb-pagination>
      </div>
    </div>
  </ng-container>
  <div
    class="col-12 text-center tw-py-20"
    *ngIf="!portfolioListModel || portfolioListModel.length == 0"
  >
    {{ 'common.noRecord' | translate }}
  </div>
</div>
<agmo-loading *ngIf="isLoading"></agmo-loading>

<ng-template #empty>-</ng-template>

<ng-template #shareQty let-item="item">
  <button class="p-link-secondary tw-p-0 tw-font-medium" (click)="openShare(item)">
    {{ item.portfolio?.shareQuantity | number }}
  </button>
</ng-template>

<ng-template #marketPrice let-item="item">
  <div class="text-black">
    {{ item.portfolio?.marketPrice | numberSuffix: { numberFormat: '1.2-2' } || '-' }}
  </div>
</ng-template>

<ng-template #investmentAmount let-item="item">
  <div class="text-black">
    {{ item.portfolio?.purchasedAmount | numberSuffix: { numberFormat: '1.2-2' } }}
  </div>
</ng-template>

<ng-template #profit let-item="item">
  <ng-container
    *ngIf="
      item?.portfolio &&
        (item?.portfolio?.unrealisedGainLoss || item?.portfolio?.unrealisedGainLoss == 0);
      else empty
    "
  >
    <span
      class="p-text-tag--round max-md:tw-order-2"
      *ngIf="
        item.portfolio.unrealisedGainLossPercentage &&
        item.portfolio.unrealisedGainLossPercentage !== 0
      "
      [class.p-tag--error]="item?.portfolio?.unrealisedGainLossPercentage < 0"
      [class.p-tag--success]="item?.portfolio?.unrealisedGainLossPercentage > 0"
    >
      <span
        class="fa tw-mr-1"
        [class.fa-arrow-up]="item?.portfolio?.unrealisedGainLossPercentage > 0"
        [class.fa-arrow-down]="item?.portfolio?.unrealisedGainLossPercentage < 0"
      >
      </span>
      {{
        (item.portfolio.unrealisedGainLossPercentage < 0
          ? item.portfolio.unrealisedGainLossPercentage * -1
          : item.portfolio.unrealisedGainLossPercentage
        ) | number: '1.0-0'
      }}%
    </span>
    <span
      class="max-md:tw-order-1 max-md:!tw-text-black"
      [ngClass]="
        item.portfolio.unrealisedGainLoss > 0
          ? 'text-success'
          : item.portfolio.unrealisedGainLoss < 0
            ? 'text-primary'
            : 'text-light-grey'
      "
    >
      {{
        item.portfolio.unrealisedGainLoss > 0
          ? '+'
          : item.portfolio.unrealisedGainLoss < 0
            ? '-'
            : ''
      }}
      {{
        (item.portfolio.unrealisedGainLoss < 0
          ? -1 * item.portfolio.unrealisedGainLoss
          : item.portfolio.unrealisedGainLoss
        )
          | numberSuffix
            : {
                numberFormat: '1.2-2',
              }
      }}
    </span>
    <!-- PLT-1394 hide valuation basis when counter is pending or dehosted, also if no new updatedPrice, don't show -->
    <div
      *ngIf="
        item.tickerCode &&
        item.status !== ShareMarketStatus.Dehosting &&
        item.portfolio?.indicativeSharePrice !== 0 &&
        item.portfolio?.valuationBasis !== undefined
      "
      class="max-md:tw-order-3 max-md:tw-flex-[1_0_100%]"
    >
      <button
        type="button"
        class="p-link-secondary tw-text-xs"
        (click)="openValuationBasisPopup(item)"
      >
        {{ 'mysec.valuationBasis' | translate }} →
      </button>
    </div>
  </ng-container>
</ng-template>
