export class DeviceModel {
  DeviceType: number;
  DeviceNo: string;
  RegistrationId: string;
  GuiVersion: string;

  constructor(id: string, registrationId?: string) {
    this.DeviceType = 3;
    this.DeviceNo = id;
    this.RegistrationId = registrationId;
    this.GuiVersion = '1';
  }
}
