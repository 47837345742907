import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TwoFactorservice } from '../../../../core/services/auth/two-factor.service';
import { QueryParamsService } from '../../../../core/services/query-params.service';
import { Router } from '@angular/router';
import { ToastService } from '../../../../core/services/toast.service';
import { BlockUiService } from '../../../../shared/services/blockUi/block-ui.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-backup-codes',
  templateUrl: './backup-codes.component.html',
})
export class BackupCodesComponent implements OnInit {
  backupCodes: string[];
  isViewOnly = true;

  form: UntypedFormGroup;
  showFormError: boolean = false;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly twoFactorService: TwoFactorservice,
    private readonly queryParamsService: QueryParamsService,
    private readonly router: Router,
    private readonly blockUiService: BlockUiService,
  ) {}

  ngOnInit(): void {
    this.blockUiService.open();
    this.buildForm();
    this.isViewOnly = this.queryParamsService.getParam('viewOnly') === 'true';
    this.twoFactorService
      .getBackupCodes()
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe({
        next: (data) => {
          const backupCodes = data.backupCodes.map((code) => code.backupCode);
          this.backupCodes = backupCodes;
        },
        error: (err) => {
          console.error('failed to retreive backup codes', err);
        },
      });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      isAccept: [false, Validators.requiredTrue],
    });
  }

  handleContinue() {
    if (!this.form.value.isAccept) {
      this.showFormError = true;
      return;
    }
    this.router.navigate(['/profile/settings/security']);
  }
}
