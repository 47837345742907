import { Component, OnInit } from '@angular/core';
import { ErrorService } from '../../../core/services/error.service';
import { finalize } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CampaignInfo } from '../../../core/models/campaign/campaign-info';
import { CampaignService } from '../../../core/services/api/campaign.service';
import { FloatingMenuItem } from '../../../shared/models/floating-menu/floating-menu-item';
import { CampaignMenus } from '../../../core/services/constant';

@Component({
  selector: 'app-campaign-draft',
  templateUrl: './campaign-draft.component.html',
  styleUrls: ['./campaign-draft.component.scss'],
})
export class CampaignDraftComponent implements OnInit {
  campaignInfo: CampaignInfo;
  fundRaiseApplicationId: number;
  campaignId: number;
  campaignMenus: FloatingMenuItem[];
  isLoading = false;

  constructor(
    private errorService: ErrorService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private campaignService: CampaignService,
  ) {}

  ngOnInit() {
    this.fundRaiseApplicationId = this.activatedRoute.snapshot.params.fundRaiseApplicationId;
    this.campaignId = this.activatedRoute.snapshot.params.campaignId;
    this.getCampaignDraft();
  }

  getCampaignDraft() {
    this.isLoading = true;

    this.campaignService
      .getCampaignDraft(this.campaignId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.campaignInfo = data;
          this.campaignMenus = [];

          if (this.campaignInfo) {
            CampaignMenus.forEach((menu) => {
              if (this.campaignInfo[menu.route]) this.campaignMenus.push(menu);
            });
          }
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  back() {
    this.location.back();
  }
}
