import { Component } from '@angular/core';

@Component({
  selector: 'app-personal-data-protection',
  templateUrl: './personal-data-protection.component.html',
  styleUrls: ['./personal-data-protection.component.scss'],
})
export class PersonalDataProtectionComponent {
  constructor() {}
}
