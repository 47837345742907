<div #top class="tw-scroll-mt-40"></div>

<app-businesses-filters
  *ngIf="isPage"
  class="tw-mb-1 tw-inline-block tw-w-full"
  [params]="params"
  (paramsChange)="updateUrl($event, true)"
>
</app-businesses-filters>

<div class="guide1-step-2" [class.is-watchlist]="isWatchList">
  <div class="mysec-mobile-table mobile-view mt-2 tw-rounded">
    <div class="tw-border tw-border-divider" [class.is-loading]="isLoading">
      <table class="tw-w-full">
        <thead>
          <th class="tw-text-xs tw-font-medium">{{ 'mysec.businesses' | translate }}</th>
        </thead>
        <tbody>
          <ng-container
            *ngTemplateOutlet="
              mobileList;
              context: {
                list:
                  businessesList.length == 0 && isLoading ? sampleBusinessesList : businessesList,
              }
            "
          >
          </ng-container>

          <ng-template #mobileList let-list="list">
            <tr *ngFor="let item of list">
              <td>
                <a
                  class="text-unset tw-flex tw-items-start"
                  [routerLink]="['/pstx', item.tickerCode]"
                >
                  <app-thumbnail-group [imageUrl]="item.companyLogo"> </app-thumbnail-group>
                  <div class="company-panel tw-flex tw-flex-col">
                    <div class="tw-flex tw-justify-between tw-pb-1">
                      <div class="tw-w-1/2">
                        <app-thumbnail-group
                          [showImage]="false"
                          [name]="item.brandName"
                          [description]="item.companyName"
                        >
                        </app-thumbnail-group>
                        <div class="p-skeleton-bar tw-uppercase">{{ item.tickerCode }}</div>
                      </div>
                      <div class="tw-w-1/2 tw-text-right">
                        <div class="p-skeleton-bar tw-font-bold">
                          <ng-container
                            *ngIf="item?.tradeVolumeSinceFirstTraded > 0; else dashedPrice"
                          >
                            {{
                              item.lastTrade
                                ? (item.lastTrade | numberSuffix: { numberFormat: '1.2-2' })
                                : '-'
                            }}
                          </ng-container>
                        </div>
                        <ng-container
                          *ngTemplateOutlet="
                            chgPrice;
                            context: { item: item, class: 'tw-justify-end', isMobile: true }
                          "
                        >
                        </ng-container>
                        <div class="p-skeleton-hidden tw-tabular-nums">
                          {{ 'mysec.Bid' | translate }}/{{ 'common.qty' | translate }}:
                          <span class="tw-whitespace-nowrap">
                            {{
                              item.bestBid
                                ? (item.bestBid | numberSuffix: { numberFormat: '1.2-2' })
                                : '-'
                            }}&nbsp;/&nbsp;{{ item.bidQty ? (item.bidQty | number: '1.0-0') : '-' }}
                          </span>
                        </div>
                        <div class="p-skeleton-hidden tw-tabular-nums">
                          {{ 'mysec.Sell' | translate }}/{{ 'common.qty' | translate }}:
                          <span class="tw-whitespace-nowrap">
                            {{
                              item.bestOffer
                                ? (item.bestOffer | numberSuffix: { numberFormat: '1.2-2' })
                                : '-'
                            }}&nbsp;/&nbsp;{{
                              item.offerQty ? (item.offerQty | number: '1.0-0') : '-'
                            }}
                          </span>
                        </div>
                        <!-- <div class="p-skeleton-hidden">{{ 'mysec.orderQty' | translate}}:
                                                    {{ item.orderQuantity ? (item.orderQuantity | number) : '-' }} </div> -->
                      </div>
                    </div>
                    <ng-container
                      *ngTemplateOutlet="
                        status;
                        context: {
                          item: item,
                          class: 'tw-justify-end',
                          childClass: 'first:tw-ml-0',
                        }
                      "
                    ></ng-container>
                  </div>
                </a>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>

      <div
        class="tw-w-full tw-py-4 tw-text-center"
        *ngIf="!isLoading && (!businessesList || businessesList.length == 0)"
      >
        {{ 'common.noRecord' | translate }}
      </div>
    </div>

    <div class="tw-w-full" *ngIf="showPagination && total > params.take">
      <ngb-pagination
        class="mobile-pagination"
        [collectionSize]="total"
        [(pageSize)]="params.take"
        [(page)]="params.currentPage"
        (pageChange)="handlePageChange()"
        [boundaryLinks]="false"
      >
        <ng-template ngbPaginationPrevious>
          <div class="pitchin-icon-arrow-left tw-h-5 tw-w-5"></div>
        </ng-template>
        <ng-template ngbPaginationPages let-page let-pages="pages">
          <div class="ngb-custom-pages-item" *ngIf="pages.length > 0">
            <div class="col-form-label tw-flex tw-flex-nowrap tw-whitespace-nowrap tw-px-2">
              <span id="paginationInputLabel" for="paginationInput" class="tw-pr-2 tw-font-normal"
                >Page</span
              >
              <span class="tw-pr-2">{{ page }}</span>
              <span class="tw-font-normal">of</span>
              <span id="paginationDescription" class="tw-pl-2">{{ pages.length }}</span>
            </div>
          </div>
        </ng-template>
        <ng-template ngbPaginationNext>
          <div class="pitchin-icon-arrow-right tw-h-5 tw-w-5"></div>
        </ng-template>
      </ngb-pagination>
    </div>
  </div>

  <div class="row desktop-view" *ngIf="businessesList">
    <div class="col-12">
      <div class="p-table__wrapper tw-mt-4">
        <div class="p-table__body" [class.is-loading]="isLoading">
          <mat-table
            class="responsive-table p-table"
            [dataSource]="
              businessesList.length == 0 && isLoading ? sampleBusinessesList : businessesList
            "
          >
            <ng-container matColumnDef="Businesses">
              <mat-header-cell *matHeaderCellDef>
                {{ 'mysec.businesses' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let item">
                <div class="tw-flex tw-w-full tw-items-start">
                  <a class="company-panel text-unset" [routerLink]="['/pstx', item.tickerCode]">
                    <app-thumbnail-group
                      [imageUrl]="item.companyLogo"
                      [name]="item.brandName"
                      [description]="item.companyName"
                    >
                    </app-thumbnail-group>
                  </a>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="CompanyAndCounter">
              <mat-header-cell *matHeaderCellDef>
                {{ 'mysec.companyAndCounter' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let item">
                <div class="tw-flex tw-w-full tw-items-start">
                  <a class="company-panel text-unset" [routerLink]="['/pstx', item.tickerCode]">
                    <app-thumbnail-group
                      [name]="item?.brandName"
                      [description]="item.tickerCode ? item?.tickerCode.toUpperCase() : null"
                      [imageUrl]="item?.companyLogo"
                    >
                    </app-thumbnail-group>

                    <div class="tw-pl-10 tw-pt-1">
                      <ng-container
                        *ngTemplateOutlet="
                          status;
                          context: {
                            item: item,
                            class: 'tw-justify-start',
                            childClass: 'last:tw-mr-0',
                          }
                        "
                      ></ng-container>
                    </div>
                  </a>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Counter">
              <mat-header-cell *matHeaderCellDef>
                {{ 'Counter' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let item">
                <a
                  class="p-skeleton-bar text-unset tw-uppercase"
                  [routerLink]="['/pstx', item.tickerCode]"
                >
                  {{ item.tickerCode }}
                </a>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Status">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let item">
                <ng-container
                  *ngTemplateOutlet="
                    status;
                    context: { item: item, class: 'tw-justify-start', childClass: 'last:tw-mr-0' }
                  "
                ></ng-container>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="LastTrade">
              <mat-header-cell *matHeaderCellDef class="tw-justify-end">
                {{ 'mysec.lastTrade' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let item" class="tw-justify-end">
                <span class="p-skeleton-bar tw-tabular-nums">
                  <ng-container *ngIf="item?.tradeVolumeSinceFirstTraded > 0; else dashedPrice">
                    {{
                      item.lastTrade
                        ? (item.lastTrade | numberSuffix: { numberFormat: '1.2-2' })
                        : '-'
                    }}
                  </ng-container>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="BestBid">
              <mat-header-cell *matHeaderCellDef class="tw-justify-end">
                <div class="tw-flex tw-items-center">
                  <div class="tw-mr-1">{{ 'mysec.bestBid' | translate }}</div>
                  <div
                    class="pitchin-icon pitchin-icon-help-circle p-help-icon"
                    #tooltip="matTooltip"
                    matTooltip="{{
                      'The highest price that a buyer is currently willing to pay for a particular share'
                        | translate
                    }}"
                  ></div>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let item" class="tw-justify-end">
                <span class="p-skeleton-bar tw-tabular-nums">{{
                  item.bestBid ? (item.bestBid | numberSuffix: { numberFormat: '1.2-2' }) : '-'
                }}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="BidQty">
              <mat-header-cell *matHeaderCellDef class="tw-justify-end">
                <div class="tw-flex tw-items-center">
                  <div>{{ 'mysec.bidQty' | translate }}</div>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let item" class="tw-justify-end">
                <span class="p-skeleton-bar tw-tabular-nums">{{
                  item.bidQty ? (item.bidQty | number: '1.0-0') : '-'
                }}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="BestAsk">
              <mat-header-cell *matHeaderCellDef class="tw-justify-end">
                <div class="tw-flex tw-items-center">
                  <div class="tw-mr-1">{{ 'mysec.bestAsk' | translate }}</div>
                  <div
                    class="pitchin-icon pitchin-icon-help-circle p-help-icon"
                    #tooltip="matTooltip"
                    matTooltip="{{
                      'The lowest price at which a seller is currently willing to sell a particular share'
                        | translate
                    }}"
                  ></div>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let item" class="tw-justify-end">
                <span class="p-skeleton-bar tw-tabular-nums">{{
                  item.bestOffer ? (item.bestOffer | numberSuffix: { numberFormat: '1.2-2' }) : '-'
                }}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="AskQty">
              <mat-header-cell *matHeaderCellDef class="tw-justify-end">
                <div class="tw-flex tw-items-center">
                  <div>{{ 'mysec.askQty' | translate }}</div>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let item" class="tw-justify-end">
                <span class="p-skeleton-bar tw-tabular-nums">{{
                  item.offerQty ? (item.offerQty | number: '1.0-0') : '-'
                }}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="OrderQty">
              <mat-header-cell *matHeaderCellDef>
                <div class="tw-flex tw-items-center">
                  <div class="tw-mr-1">{{ 'mysec.orderQty' | translate }}</div>
                  <div
                    class="pitchin-icon pitchin-icon-help-circle p-help-icon"
                    #tooltip="matTooltip"
                    matTooltip="{{ 'Total number of shares available for trade' | translate }}"
                  ></div>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let item">
                <ng-container
                  *ngTemplateOutlet="orderQuantity; context: { item: item }"
                ></ng-container>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="PriceChg">
              <mat-header-cell *matHeaderCellDef>
                <div class="d-flex align-items-center">
                  <div class="mr-1">{{ 'mysec.priceChg' | translate }}</div>
                  <div
                    class="pitchin-icon pitchin-icon-help-circle p-help-icon"
                    #tooltip="matTooltip"
                    matTooltip="{{ 'mysec.changePercentDescription' | translate }}"
                  ></div>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let item">
                <ng-container
                  *ngTemplateOutlet="
                    chgPrice;
                    context: { item: item, class: 'tw-justify-start', isMobile: false }
                  "
                ></ng-container>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayColumns"> </mat-header-row>
            <mat-row
              *matRowDef="let item; columns: displayColumns"
              class="cursor-pointer"
              [class.tw-pointer-events-none]="!item.tickerCode"
              [routerLink]="['/pstx', item.tickerCode]"
            >
            </mat-row>
          </mat-table>

          <div
            class="tw-w-full tw-py-4 tw-text-center"
            *ngIf="!isLoading && (!businessesList || businessesList.length == 0)"
          >
            {{ 'common.noRecord' | translate }}
          </div>

          <div class="p-table__footer" *ngIf="showPagination && total > params.take">
            <div class="p-table__pagination p-skeleton-pagination">
              <ngb-pagination
                [collectionSize]="total"
                [(pageSize)]="params.take"
                [(page)]="params.currentPage"
                (pageChange)="handlePageChange()"
                [maxSize]="5"
                [rotate]="true"
              >
                <ng-template ngbPaginationPrevious>
                  <span class="p-table__pagination-icon">←</span>
                  <div class="p-table__pagination-label tw-pl-2">
                    {{ 'common.prev' | translate }}
                  </div>
                </ng-template>
                <ng-template class="page-no" ngbPaginationNumber let-page>
                  <div class="page-no">{{ page }}</div>
                </ng-template>
                <ng-template ngbPaginationNext>
                  <div class="p-table__pagination-label tw-pr-2">
                    {{ 'common.next' | translate }}
                  </div>
                  <span class="p-table__pagination-icon">→</span>
                </ng-template>
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!showPagination && !isLoading" class="tw-mx-auto tw-mt-8 tw-max-w-xs">
    <a class="btn btn-primary-border tw-w-full" [routerLink]="['/pstx']">
      {{ 'common.viewAll' | translate }}
    </a>
  </div>
</div>

<ng-template #chgPrice let-item="item" let-class="class" let-isMobile="isMobile">
  <div class="tw-flex tw-items-center" [class]="class" [class.p-skeleton-hidden]="isMobile">
    <div class="tw-whitespace-nowrap" [class.p-skeleton-bar]="!isMobile">
      {{ item.changesValue >= 0 || item.changesValue == null ? '+' : ''
      }}<span *ngIf="isMobile">{{ item.changesValue || 0 | number: '1.2-2' }}</span
      ><span *ngIf="!isMobile">{{
        item.changesValue || 0 | numberSuffix: { numberFormat: '1.2-2' }
      }}</span>
    </div>
    <div
      class="p-skeleton-hidden tw-ml-2 tw-flex tw-items-center tw-rounded-full tw-px-1 tw-py-1 tw-text-xs"
      [class]="checkItemPrice(item)"
    >
      <div
        class="pitchin-icon-xs pitchin-icon-percentage tw-mr-1"
        [class]="'pitchin-icon-' + checkItemPrice(item) + '-arrow'"
      ></div>
      <div class="tw-font-medium">{{ absValue(item.priceChg || 0) | number: '1.0-0' }}%</div>
    </div>
  </div>
</ng-template>

<ng-template #status let-item="item" let-class="class" let-childClass="childClass">
  <div class="p-skeleton-hidden tw-flex tw-flex-wrap" [class]="class">
    <div
      *ngFor="let status of item.investorShareMarketStatus"
      class="tw-flex tw-items-center tw-rounded-full tw-px-2 tw-text-xs tw-py-1 tw-whitespace-nowrap tw-ml-2 tw-mb-1 tw-font-medium {{
        InvestorShareMarketStatusFilter[status]
      }} {{ childClass }}"
    >
      {{ InvestorShareMarketStatusFilter[status] | translate }}
    </div>
  </div>
</ng-template>

<ng-template #logoImage let-item="item">
  <img
    class="tw-h-10 tw-w-10 tw-rounded tw-border tw-border-solid tw-border-[#D0D5DD] tw-object-cover"
    [alt]="item.companyName"
    [src]="item.companyLogo || defaultLogo"
  />
</ng-template>

<ng-template #empty>
  <span class="p-skeleton-bar">-</span>
</ng-template>

<ng-template #orderQuantity let-item="item">
  <span class="p-skeleton-bar">{{ item.orderQuantity ? (item.orderQuantity | number) : '-' }}</span>
</ng-template>

<ng-template #dashedPrice> {{ defaultCurrencyCode }} - </ng-template>
