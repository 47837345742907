import { NotificationEvent } from './../../core/models/notification/notification-event';
import { PushNotificationService } from './../../core/services/api/push-notification.service';
import { MenuService } from './../../core/services/menu.service';
import { Menu } from './../../core/models/menu/menu';
import { StorageService } from './../../core/services/storage.service';
import { AuthService } from './../../core/services/auth/auth.service';
import { Role } from '../../core/enum/role.enum';
import { UserProfile } from './../../core/models/user/user-profile';
import { AccountService } from './../../core/services/api/account.service';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router, NavigationEnd, NavigationStart, UrlSerializer } from '@angular/router';
import {
  DOCUMENT,
  isPlatformBrowser,
  isPlatformServer,
  Location,
  PopStateEvent,
} from '@angular/common';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { BlockUiService } from './../../shared/services/blockUi/block-ui.service';
import { MyApplicationFilterService } from './../../pages/raise/my-application/my-application-filter.service';
import { CampaignSectionFilterService } from './../../pages/landing/campaign-section/campaign-section-filter.service';
import { PopupService } from './../../shared/services/popup/popup.service';
import { NotificationsService } from '../../core/services/api/notifications';
import { finalize } from 'rxjs/operators';
import { ErrorService } from '../../core/services/error.service';
import { IncomingNotification } from '../../core/models/notification/incoming-notification';
import { DefaultProfileImage } from '../../core/services/constant';
import { GtmDataLayerService } from '../../core/services/gtm-data-layer.service';
import { EkycStatus } from '../../core/enum/ekyc-status.enum';
import { MenuItem } from '../../core/models/menu/menu-item';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { WalletService } from '../../core/services/api/mysec/wallet.service';
import { Wallet } from '../../core/models/mysec/wallet';
import { PstxAccountService } from '../../core/services/pstx-account.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @ViewChild('profileDropdown', { static: false, read: NgbDropdown }) profileDropdown: NgbDropdown;
  @ViewChild('notificationDropdown', { static: false, read: NgbDropdown })
  notificationDropdown: NgbDropdown;
  @ViewChild('managementDropdown', { static: false, read: NgbDropdown })
  managementDropdown: NgbDropdown;
  @ViewChild('dropdownRef', { static: false, read: NgbDropdown }) navDropdown: NgbDropdown;
  public isCollapsed = true;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  private notificationSubscription: Subscription;

  profile: UserProfile;
  menu: Menu;
  role: Role;
  incomingNotification: boolean = false;
  notifications: IncomingNotification[];
  notificationLoadMore = true;
  params = {
    skip: 0,
    take: 10,
  };
  isLoading = false;
  registerInProgress = false;
  defaultProfileImage = DefaultProfileImage;
  signUpLink: string;

  Role: typeof Role = Role;
  EkycStatus: typeof EkycStatus = EkycStatus;
  showNotificationBar: boolean = false;

  isDevelopmentMode: boolean = environment.environment !== 'PRODUCTION';
  environment = environment.environment;

  wallet: Wallet;
  isWalletFound: boolean = true;

  constructor(
    public location: Location,
    private router: Router,
    private accountService: AccountService,
    private authService: AuthService,
    public storageService: StorageService,
    private menuService: MenuService,
    private pushNotificationService: PushNotificationService,
    @Inject(PLATFORM_ID) private platform: Object,
    @Inject(DOCUMENT) private document: Document,
    private blockUiService: BlockUiService,
    private myApplicationFilterService: MyApplicationFilterService,
    private campaignSectionFilterService: CampaignSectionFilterService,
    private popupService: PopupService,
    private notificationsService: NotificationsService,
    private errorService: ErrorService,
    private _gtmDataLayerService: GtmDataLayerService,
    private renderer: Renderer2,
    private walletService: WalletService,
    private pstxAccountService: PstxAccountService,
  ) {}

  async ngOnInit() {
    this.menu = this.menuService.menu;

    if (isPlatformServer(this.platform)) return;

    if (location.pathname == '/redirect-login') return;

    this.accountService.currentUser.subscribe((profile) => {
      this.profile = profile;

      if (this.authService.isAuthenticated()) {
        if (!this.profile) {
          this.accountService.profile(true).subscribe(
            () => {},
            () => this.checkFlow(),
          );
        } else {
          this.checkFlow();
        }
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url != this.lastPoppedUrl) this.yScrollStack.push(window.scrollY);
      } else if (event instanceof NavigationEnd) {
        if (event.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;

          if (isPlatformBrowser(this.platform)) window.scrollTo(0, this.yScrollStack.pop());
        } else if (isPlatformBrowser(this.platform)) {
          window.scrollTo(0, 0);

          var navbar = document.getElementsByTagName('nav')[0];

          if (navbar?.classList.contains('headroom--unpinned')) {
            navbar.classList.remove('headroom--unpinned');
            navbar.classList.add('headroom--pinned');
          }
        }

        if (this.profile) {
          // Push the user_id variable to gtm only when profile exists / (is authenticated)
          this._gtmDataLayerService.logUser(
            this.profile.id,
            this.role,
            this.profile.ekycStatus == EkycStatus.Verified,
            this.profile.hasTransaction,
            this.profile.email,
          );
        }
      }
    });

    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });

    this.accountService.notifications.subscribe((notifications) => {
      if (!notifications) {
        this.notifications = [];
        return;
      }

      if (notifications.length == 0) {
        this.notificationLoadMore = false;
        return;
      }

      this.notifications = this.notifications.concat(notifications);
      this.incomingNotification = !!this.notifications.find(
        (notificatiion) => !notificatiion.isRead,
      );
    });

    this.notificationSubscription = this.pushNotificationService.event.subscribe(
      (event: NotificationEvent) => {
        this.incomingNotification = true;
        this.getNotifications(true);
      },
    );

    this.pstxAccountService.getWallet().subscribe((wallet: Wallet) => {
      this.wallet = wallet;
    });

    this.pstxAccountService.getIsWalletFound().subscribe((isFound: boolean) => {
      this.isWalletFound = isFound;
    });

    this.initNotificationBar();
  }

  initNotificationBar() {
    // Will check the device date time if is > Malaysia Time 1am will be hidden;
    const dateTimeToRemove = new Date('2023-06-01T01:00:00.000+08:00');
    const isShow = moment().isBefore(dateTimeToRemove);

    this.showNotificationBar = isShow;

    if (isShow) {
      this.renderer.addClass(this.document.body, 'open-notification-bar');
    }
  }

  checkFlow() {
    this.accountService.getSignUpFlowStatus().subscribe(
      (flowData) => {
        this.registerInProgress = flowData && !flowData.isCompleted;
        this.role = this.storageService.role;

        if (this.profile && !this.registerInProgress) {
          if (this.profile.completeEkyc) {
            this.router.navigate(['/profile/settings/profile-details']);
          }

          this.getNotifications(true);

          this.getWalletBalance();
        } else {
          let role =
            this.role == Role.CompanyRep
              ? 'company-rep'
              : this.role == Role.IndividualInvestor
                ? 'individual-investor'
                : 'issuer-rep';

          this.signUpLink = '/sign-up/' + role;
          if (this.router.url !== this.signUpLink) this.router.navigate([this.signUpLink]);
        }
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  getNotifications(reload?: boolean) {
    if (reload) {
      this.params.skip = 0;
      this.notifications = [];
    }

    this.isLoading = true;

    this.notificationsService
      .getNotifications(this.params.take, this.params.skip)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {},
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  getWalletBalance(forceRefresh: boolean = false) {
    if (this.role === Role.IssuerRep) return;

    if (!forceRefresh && (this.wallet || !this.isWalletFound)) return;

    this.pstxAccountService.getWallet();
  }

  resetWallet() {
    this.pstxAccountService.resetState();
  }

  isActiveMenu(url: string, isExactMatch: boolean = false) {
    const path = window?.location?.pathname;

    if (!path) {
      return false;
    }

    if (isExactMatch) {
      return path === url;
    }

    return path.startsWith(url);
  }

  isActiveSubmenu(menu: MenuItem[]) {
    if (!menu) return false;

    const isActive = menu.some((m) => {
      return this.isActiveMenu(m.url, true);
    });

    return isActive;
  }

  handleLoginRedirection(event: MouseEvent) {
    const currentUrl = this.router.url;

    // Retain the `href` from <a> for SEO purpose, but avoid it navigate itself
    event.preventDefault();

    // Avoid doing any redirection when in /login
    if (currentUrl.includes('/login')) return;

    const excludedUrls = ['/sign-up'];
    const returnUrl = excludedUrls.some((url) => currentUrl.includes(url)) ? null : currentUrl;
    const queryParams = returnUrl ? { returnUrl } : null;

    this.router.navigate(['/login'], { queryParams }).catch((err) => {
      console.error('fail to navigate to login: ', err);
    });
  }

  logout() {
    this.isCollapsed = true;

    this.popupService.confirm('common.confirmLogout').then(
      () => {
        this.blockUiService.open();

        this.accountService
          .logout()
          .subscribe(() => {
            this.router.navigate(['/']);
            this.registerInProgress = false;
            this._gtmDataLayerService.logUser();
            this.resetWallet();
          })
          .add(() => {
            this.blockUiService.close();
          });
      },
      () => {},
    );
  }

  clearServiceFilter(isParent: boolean) {
    if (!isParent) {
      this.isCollapsed = true;
    }

    this.myApplicationFilterService.clear();
    this.campaignSectionFilterService.clear();
  }

  viewDocument(url: string) {
    window.open(url);
  }

  deleteNotification(id: number) {
    this.popupService.confirm('common.confirmDeleteNotification').then(
      () => {
        this.blockUiService.open();

        this.notificationsService
          .deleteNotification(id)
          .pipe(finalize(() => this.blockUiService.close()))
          .subscribe({
            next: () => {
              this.getNotifications(true);
            },
            error: (err) => {
              this.errorService.showError(err);
            },
          });
      },
      () => {},
    );
  }

  markAllIsRead() {
    this.blockUiService.open();

    this.notificationsService
      .markAllNotificationIsRead()
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe({
        next: () => {
          this.getNotifications(true);
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }

  markIsRead(notification: IncomingNotification) {
    this.blockUiService.open();

    this.notificationsService
      .markNotificationIsRead(notification.id)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe({
        next: () => {
          this.getNotifications(true);

          if (notification.notification.deepLink)
            window.open(notification.notification.deepLink, '_self');
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }

  onNotificationScroll() {
    if (this.notificationLoadMore == false) return;

    this.params.skip += this.params.take;
    this.getNotifications();
  }

  closeDropdown() {
    if (isPlatformBrowser(this.platform)) {
      if (this.navDropdown) this.navDropdown.close();

      if (this.profileDropdown) this.profileDropdown.close();

      if (this.notificationDropdown) this.notificationDropdown.close();

      if (this.managementDropdown) this.managementDropdown.close();
    }
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    this.closeDropdown();
  }

  disableSlide(event: TouchEvent): void {
    event.preventDefault();
  }
}
