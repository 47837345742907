import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { finalize } from 'rxjs';
import { AnnouncementCategory } from 'src/app/core/enum/mysec/announcement-category.enum';
import { Announcement } from 'src/app/core/models/announcement/announcement';
import { PortfolioService } from 'src/app/core/services/api/portfolio.service';
import { DATE_FORMATS } from 'src/app/core/services/constant';
import { ErrorService } from 'src/app/core/services/error.service';

@Component({
  selector: 'app-my-announcements',
  templateUrl: './my-announcements.component.html',
  styleUrls: ['./my-announcements.component.scss'],
})
export class MyAnnouncementsComponent implements OnInit {
  isLoading: boolean = true;
  announcements: Announcement[] = [];

  total = 0;
  params = {
    currentPage: 1,
    take: 10,
  };

  AnnouncementCategory: typeof AnnouncementCategory = AnnouncementCategory;

  constructor(
    private portfolioService: PortfolioService,
    private errorService: ErrorService,
  ) {}

  ngOnInit() {
    this.getAnnouncements();
  }

  getAnnouncements() {
    this.portfolioService
      .getAnnouncements(this.params.currentPage, this.params.take)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (res) => {
          this.announcements = this.renderData(res.data);
          this.total = res.total;
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }

  renderData(announcements: Announcement[]): Announcement[] {
    return announcements.map((announcement) => {
      return {
        ...announcement,
        timeDifference: this.getTimeDifference(announcement.publishedAt),
        formattedTimeDifference: this.getFormattedTimeDifference(announcement.publishedAt),
        tickerCode: announcement.investedInTickerCodes?.find((tickerCode) => tickerCode !== null),
        isCollapsed: true,
      };
    });
  }

  getTimeDifference(newDate: string): number {
    const publishedAt = moment(newDate);
    const currentDate = moment();
    const timeDifference = currentDate.diff(publishedAt, 'seconds');

    // Get in days
    return Number(timeDifference / 86400);
  }

  getFormattedTimeDifference(newDate: string): string {
    const publishedAt = moment(newDate);
    const currentDate = moment();
    const timeDifference = currentDate.diff(publishedAt, 'seconds');

    if (timeDifference <= 86400) {
      // 1 day
      const hours = Math.floor(timeDifference / 3600);
      return `${hours}h ago`;
    } else if (timeDifference <= 604800) {
      // 7 days
      const days = Math.floor(timeDifference / 86400);
      return `${days}d ago`;
    } else {
      return publishedAt.format('DD MMM YYYY');
    }
  }

  renderContent(html: string): string {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  }
}
