<ng-container *ngIf="!notFoundPage && businessDirectory">
  <div class="container-fluid">
    <div class="row position-relative pt-4">
      <div class="col-md-10 d-flex title-panel">
        <div class="mr-4 pb-3">
          <img
            class="logo-image"
            [alt]="businessDirectory.brandName"
            [src]="businessDirectory.companyLogoUrl || defaultLogo"
          />
        </div>
        <div class="d-flex campaign-name-panel pb-3">
          <h1 class="campaign-title text-break">{{ businessDirectory.brandName }}</h1>
        </div>
      </div>
      <div class="col-md-2 d-flex justify-content-end align-items-center action-panel">
        <div
          class="pitchin-icon mr-3"
          [ngClass]="
            businessDirectory.isSubscribed
              ? 'pitchin-icon-bell-icon-blue'
              : 'pitchin-icon-notification'
          "
          (click)="notifyMe()"
          *ngIf="isLogin"
          [title]="
            (businessDirectory.isSubscribed ? 'landing.unsubscribed' : 'landing.notifyMe')
              | translate
          "
        ></div>
        <div ngbDropdown placement="bottom-right">
          <div class="pitchin-icon pitchin-icon-share no-caret" ngbDropdownToggle></div>
          <div class="share-dropdown" ngbDropdownMenu>
            <div class="d-flex flex-row">
              <share-button class="col-auto btn-share-ws px-1" button="whatsapp"></share-button>
              <share-button class="col-auto px-1" button="facebook"></share-button>
              <share-button class="col-auto px-1" button="twitter"></share-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        {{ businessDirectory.tagline }}
      </div>
    </div>
    <div class="row d-md-none mt-4">
      <div class="col-12">
        <app-business-overview [businessDirectory]="businessDirectory"></app-business-overview>
      </div>
    </div>
  </div>
  <div class="border-bottom mb-4" id="campaign-navbar">
    <div class="container-fluid mt-4">
      <nav class="navbar navbar-expand-md navbar-light navbar-content" id="tabList">
        <ul class="navbar-nav mr-auto">
          <li
            *ngFor="let tab of tabs"
            class="nav-item d-flex"
            [class.active]="selectedTab === tab.id"
          >
            <a class="nav-link" (click)="changeTab(tab.id, $event)" data-t="tabClick">{{
              tab.name | translate
            }}</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <app-floating-menu
    *ngIf="floatingMenus && floatingMenus.length > 0"
    [floatingMenus]="floatingMenus"
    [selectedMenu]="selectedMenu"
  ></app-floating-menu>
  <div class="container-fluid content">
    <div class="row">
      <div class="col-md-9">
        <div class="navbar-content">
          <app-business-info
            [businessDirectory]="businessDirectory"
            (scrollChange)="scrollChange($event)"
            *ngIf="selectedTab == 1 && businessDirectory"
          >
          </app-business-info>
        </div>
      </div>
      <div class="col-md-3 d-none d-md-block">
        <app-business-overview [businessDirectory]="businessDirectory"></app-business-overview>
      </div>
    </div>
  </div>
</ng-container>
<agmo-loading *ngIf="!notFoundPage && !businessDirectory"></agmo-loading>
<app-not-found-page *ngIf="notFoundPage"></app-not-found-page>
