import { Component, OnInit } from '@angular/core';
import { PopupService } from '../../../shared/services/popup/popup.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorService } from '../../../core/services/error.service';
import { BlockUiService } from '../../../shared/services/blockUi/block-ui.service';
import { Payment } from '../../../core/models/payment/payment';
import { RaiseService } from '../../../core/services/api/raise.service';
import { finalize } from 'rxjs/operators';
import { HomeService } from '../../../core/services/api/home.service';
import { ReceiptType } from '../../../core/enum/receipt-type.enum';
import { PaymentType } from '../../../core/enum/payment-type.enum';
import { AttachmentInfo } from '../../../core/models/attachment/attachment-info';

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss'],
})
export class MakePaymentComponent implements OnInit {
  fundRaiseApplicationId: number;
  step: number = 1;
  onboardingFees: number;
  isLoading: boolean = false;
  attachmentInfo: AttachmentInfo;
  document: File;

  readonly receiptType = ReceiptType.OnboardingFee;

  constructor(
    private activatedRoute: ActivatedRoute,
    private popupService: PopupService,
    private errorService: ErrorService,
    private router: Router,
    private blockUiService: BlockUiService,
    private raiseService: RaiseService,
    private homeService: HomeService,
  ) {}

  ngOnInit() {
    this.fundRaiseApplicationId = this.activatedRoute.snapshot.params.fundRaiseApplicationId;
    this.checkCommonKeys();
  }

  setInvestmentPayment(paymentModel: Payment) {
    if (paymentModel.paymentType != PaymentType.MaybankFPX && !this.attachmentInfo) {
      this.popupService.alert('common.pleaseUploadReceipt');
      return;
    }

    this.blockUiService.open();
    paymentModel.attachmentInfo = this.attachmentInfo;

    this.raiseService
      .setPaymentMethod(this.fundRaiseApplicationId, paymentModel)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        (data) => {
          if (data && data.fpxPaymentGatewayUrl) window.location.href = data.fpxPaymentGatewayUrl;
          else {
            this.popupService
              .success('raise.paymentWillBeConfirmed', {
                titleLabel: 'campaign.paymentSubmitted',
                okLabel: 'common.ok',
                cancelLabel: 'common.cancel',
              })
              .then(() => {
                this.router.navigate(['/raise/my-application']);
              })
              .catch(() => {
                // modal closed
              });
          }
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  nextStep(response: { isNext: boolean; paymentType: PaymentType }) {
    if (response.isNext) this.step = 2;
  }

  checkCommonKeys() {
    this.raiseService.getCommonKeys(this.fundRaiseApplicationId).subscribe(
      (keys) => {
        let dynamicKeys = keys.values || [];

        if (dynamicKeys.length > 0) {
          let findKey = dynamicKeys.find((key) => key.dynamicFormKey == 'onboardingFees');
          this.onboardingFees = findKey ? parseInt(findKey.value.replace('RM', '')) : 0;
        }
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  uploadReceipt(file: File) {
    this.isLoading = true;

    this.homeService
      .uploadBusinessFeePresignedURL(this.fundRaiseApplicationId, file)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.attachmentInfo = new AttachmentInfo(data);
          this.document = file;
        },
        (err) => {
          this.errorService.showError(err);
          this.attachmentInfo = null;
          this.document = null;
        },
      );
  }
}
