import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PagedResult } from '../../../shared/models/paged-result/paged-result';
import { PortfolioHistoryList } from '../../models/portfolio/portfolio-history-list';
import { HistoryFilter } from '../../../pages/my-portfolio/history/history-filter';
import * as moment from 'moment';

@Injectable()
export class PortfolioService {
  private route = environment.apiServer + 'Api/v1/Users/{userId}/{role}/portfolio';

  constructor(private http: HttpClient) {}

  getPortfolio(currentPage: number, take: number, isDigitalCert?: boolean): Observable<any> {
    type GetPortfolioParams = {
      currentPage: string;
      take: string;
      isDigitalCert?: boolean;
    };

    let params: GetPortfolioParams = {
      currentPage: currentPage.toString(),
      take: take.toString(),
    };

    if (isDigitalCert) {
      params.isDigitalCert = true;
    }

    return this.http.get(this.route + '/details', { params: params }).pipe(
      map((data: any) => {
        return {
          data: data.result.data || [],
          summary: data.result.summary,
          total: data.result.totalCount,
        };
      }),
    );
  }

  getPortfolioHistory(filter: HistoryFilter): Observable<PagedResult<PortfolioHistoryList>> {
    const params: any = Object.assign({}, filter);
    if (params.transactionDateFrom)
      params.transactionDateFrom = moment(params.transactionDateFrom).startOf('day').utc().format();
    if (params.transactionDateTo)
      params.transactionDateTo = moment(params.transactionDateTo).startOf('day').utc().format();

    return this.http.get(this.route + '/history', { params: params }).pipe(
      map((data: any) => {
        return {
          data: data.result.data || [],
          total: data.result.totalCount,
        };
      }),
    );
  }

  generateHistoryReportPdf(filter: HistoryFilter): Observable<any> {
    const params: any = Object.assign({}, filter);
    if (params.transactionDateFrom)
      params.transactionDateFrom = moment(params.transactionDateFrom).startOf('day').utc().format();
    if (params.transactionDateTo)
      params.transactionDateTo = moment(params.transactionDateTo).startOf('day').utc().format();

    return this.http.get(this.route + '/history/export', { params: params }).pipe(
      map((response: any) => {
        return {
          data: response.result,
        };
      }),
    );
  }

  getAnnouncements(currentPage: number, take: number): Observable<any> {
    let params = {
      currentPage: currentPage.toString(),
      take: take.toString(),
    };

    return this.http.get(this.route + '/updates', { params: params }).pipe(
      map((data: any) => {
        return {
          data: data.result.data || [],
          total: data.result.totalCount,
        };
      }),
    );
  }

  getReports(): Observable<any> {
    return this.http.get(this.route + '/reports').pipe(
      map((data: any) => {
        return {
          data: data.result || [],
        };
      }),
    );
  }
}
