import { OrderPaymentDetailModel } from './order-payment-detail.model';
import { Wallet } from './wallet';

export class OrderPaymentDetail {
  wallet: Wallet;
  amount: number;
  tradingFee: number;
  totalPayable: number;
  isShareHolder: boolean;
  availableShareQuantity: number;

  constructor(model: OrderPaymentDetailModel) {
    this.wallet = model.wallet ? new Wallet(model.wallet) : null;
    this.amount = model.sharePriceAmount;
    this.tradingFee = model.tradingPayableAmount;
    this.totalPayable = model.totalAmount;
    this.isShareHolder = model.isShareHolder;
    this.availableShareQuantity = model.availableShareQuantity;
  }
}
