import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs';
import { UserProfile } from '../../../core/models/user/user-profile';
import { AccountService } from '../../../core/services/api/account.service';
import { TickerCode } from '../../../core/models/mysec/ticker-code';
import { BusinessesService } from 'src/app/core/services/api/mysec/businesses.service';

@Component({
  selector: 'app-mysec-settings',
  templateUrl: './mysec-settings.component.html',
  styleUrls: ['./mysec-settings.component.scss'],
})
export class MysecSettingsComponent implements OnInit {
  profile: UserProfile;
  isLoading = true;
  isIssuerHasCampaign: boolean;
  tickerCodes: TickerCode[];

  constructor(
    private accountService: AccountService,
    private businessesService: BusinessesService,
  ) {}

  ngOnInit() {
    this.accountService.profile(false).subscribe((data) => {
      this.profile = data;
    });

    this.getIssuerCampaign();
    this.getTickerCodes();
  }

  getIssuerCampaign() {
    this.isLoading = true;

    // PLT-1198 to check if issuer has campaign
    // if exists, then it could be no digital registry registerd
    this.accountService
      .getMyCampaigns(1, 1)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((data) => {
        this.isIssuerHasCampaign = data.total > 0;
      });
  }

  getTickerCodes() {
    this.isLoading = true;
    this.businessesService
      .getTickerCodes()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((data) => {
        this.tickerCodes = data;
      });
  }
}
