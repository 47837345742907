import * as moment from 'moment';
import { EkycStatus } from '../../enum/ekyc-status.enum';
import { IdentificationType } from '../../enum/identification-type.enum';
import { AttachmentInfo } from '../attachment/attachment-info';
import { UserProfile } from './user-profile';

export class UpdateProfileModel {
  Id: string;
  PhoneNumber: string;
  PhoneCountryCode: string;
  FullName: string;
  Email: string;
  Birthday: string;
  AboutMe: string;
  Address: string;
  Postcode: string;
  City: string;
  StateId: string;
  CountryId: string;
  PhotoAttachmentUrl: string;
  EkycStatus: EkycStatus;
  TimeZone: string;
  IdentityNo: string;
  UserIdentityNoType: IdentificationType;
  OccupationType: number;
  EmploymentSector: number;
  OthersOccupationType: string;
  GrossAnnualIncomeType: number;
  SourceOfWealth: number[];
  OthersSourceOfWealth: string;
  SourceOfFunds: number[];
  TransactionPurpose: number;
  OthersSourceOfFunds: string;
  isPEPDeclaration: boolean;
  CompanyRepPositionType: number;
  OthersCompanyRepPositionType: string;
  attachmentInfo: AttachmentInfo;

  constructor(model: UserProfile) {
    this.Id = model.id;
    this.PhoneNumber = model.phoneNumber;
    this.PhoneCountryCode = model.phoneCountryCode;
    this.FullName = model.fullName;
    this.Email = model.email;
    this.Birthday = moment(model.birthday).format('YYYY-MM-DD');
    this.AboutMe = model.aboutMe;
    this.Address = model.address;
    this.Postcode = model.postcode;
    this.City = model.city;
    this.StateId = model.stateId;
    this.CountryId = model.countryId;
    this.PhotoAttachmentUrl = model.photoAttachmentUrl;
    this.EkycStatus = model.ekycStatus;
    this.TimeZone = model.timeZone;
    this.IdentityNo = model.identityNo ? model.identityNo.replace(/-/g, '') : null;
    this.UserIdentityNoType = model.userIdentityNoType;
    this.OccupationType = model.occupationType;
    this.EmploymentSector = model.employmentSector;
    this.OthersOccupationType = model.othersOccupationType;
    this.SourceOfWealth = model.sourceOfWealth;
    this.TransactionPurpose = model.transactionPurpose;
    this.OthersSourceOfWealth = model.othersSourceOfWealth;
    this.SourceOfFunds = model.sourceOfFunds;
    this.OthersSourceOfFunds = model.othersSourceOfFunds;
    this.GrossAnnualIncomeType = model.grossAnnualIncomeType;
    this.isPEPDeclaration = model.isPEPDeclaration;
    this.CompanyRepPositionType = model.companyRepPositionType;
    this.OthersCompanyRepPositionType = model.othersCompanyRepPositionType;
    this.attachmentInfo = model.attachmentInfo;
  }
}
