import { CommonModule, CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TransferState } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { MomentModule } from 'ngx-moment';
import { FinancialListComponent } from '../shared/page/financial-list/financial-list.component';
import { NewsComponent } from '../shared/page/news/news.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ButtonComponent } from './components/button/button.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { FloatingMenuComponent } from './components/floating-menu/floating-menu.component';
import { JsonLdComponent } from './components/json-ld/json-ld.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LoginOrSignupComponent } from './components/login-or-sign-up/login-or-sign-up.component';
import { SelectSearchFilterComponent } from './components/select-search-filter/select-search-filter.component';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { TagListComponent } from './components/tag-list/tag-list.component';
import { UploaderDirective } from './components/uploader/uploader.directive';
import { AlphanumericDirective } from './directive/alphanumeric.directive';
import { ImageAspectRatioDirective } from './directive/image-aspect-ratio.directive';
import { LazyImgDirective } from './directive/lazy-img.directive';
import { NoScrollInputDirective } from './directive/no-scroll-input.directive';
import { NoSpaceDirective } from './directive/no-space-directive';
import { ScrollSpyDirective } from './directive/scroll-spy.directive';
import { TwoDigitDecimaNumberDirective } from './directive/two-digits-decimal-number.directive';
import { translateBrowserLoaderFactory } from './loader/translate-browser.loader.service';
import { UpdatesComponent } from './page/updates/updates.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { FormatVideoURLPipe } from './pipes/format-video-url.pipe';
import { LegacyFormatterPipe } from './pipes/legacy-formatter.pipe';
import { NumberSuffixPipe } from './pipes/number-suffix.pipe';
import { PluralizePipe } from './pipes/pluralize.pipe';
import { TrustSrcPipe } from './pipes/trust-src.pipe';
import { WeblinkPipe } from './pipes/weblink.pipe';
import { DatetimeMomentDirective } from './plugin-configs/ngb-datetime-moment/ngb-datetime-moment.directive';
import { BlockUiModalComponent } from './services/blockUi/block-ui-modal.component';
import { DynamicFormQuestionComponent } from './services/dynamic-form-question/dynamic-form-question.component';
import { QuestionControlService } from './services/dynamic-form-question/question-control.service';
import { DynamicFormComponent } from './services/dynamic-form/dynamic-form.component';
import { LoaderComponent } from './services/loader/loader.component';
import { PopupModalComponent } from './services/popup/popup-modal.component';
import { PreviewModalComponent } from './services/preview/preview-modal.component';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { FULL_FORMATS } from '../core/services/constant';
import { IconComponent } from '../components/icon/icon.component';
import { EmptyStateComponent } from '../components/empty-state/empty-state.component';
import { ThumbnailGroupComponent } from '../components/thumbnail-group/thumbnail-group.component';
import { FaqAccordionComponent } from '../components/faq-accordion/faq-accordion.component';
import { SearchInputComponent } from '../components/search-input/search-input.component';
import { PageComponent } from '../components/page/page.component';
import { ProductBadgeComponent } from '../components/product-badge/product-badge.component';
import { TabComponent } from '../components/tab/tab.component';
import { OrderSummaryComponent } from '../pages/mysec/wallet/order-summary/order-summary.component';
import { ToastsComponent } from '../components/toasts/toasts.component';
import { AlertBarComponent } from '../components/alert-bar/alert-bar.component';
import { ValidationErrorMessageComponent } from './components/validation-error-message/validation-error-message.component';
import { ValidationErrorSummaryComponent } from './components/validation-error-summary/validation-error-summary.component';
import { ProfileOnboardingChecklistComponent } from '../pages/settings/profile-onboarding-checklist/profile-onboarding-checklist.component';
import { GtmInputBlurDirective } from './directive/gtm-input-blur.directive';
import { InvestmentLimitPopupComponent } from './services/investment-limit-popup/investment-limit-popup.component';

@NgModule({
  declarations: [
    PopupModalComponent,
    LoadingComponent,
    IconComponent,
    BlockUiModalComponent,
    UploaderDirective,
    TrustSrcPipe,
    LegacyFormatterPipe,
    PreviewModalComponent,
    BreadcrumbComponent,
    TagListComponent,
    JsonLdComponent,
    LoaderComponent,
    ButtonComponent,
    SkeletonComponent,
    AlertBarComponent,
    DatetimeMomentDirective,
    ImageAspectRatioDirective,
    PluralizePipe,
    EnumToArrayPipe,
    NumberSuffixPipe,
    DateAgoPipe,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    ScrollSpyDirective,
    SelectSearchFilterComponent,
    NoScrollInputDirective,
    WeblinkPipe,
    FloatingMenuComponent,
    LazyImgDirective,
    GtmInputBlurDirective,
    FormatVideoURLPipe,
    AlphanumericDirective,
    DateRangePickerComponent,
    NoSpaceDirective,
    AlphanumericDirective,
    TwoDigitDecimaNumberDirective,
    FinancialListComponent,
    UpdatesComponent,
    NewsComponent,
    LoginOrSignupComponent,
    EmptyStateComponent,
    ThumbnailGroupComponent,
    FaqAccordionComponent,
    SearchInputComponent,
    PageComponent,
    ProductBadgeComponent,
    TabComponent,
    OrderSummaryComponent,
    ToastsComponent,
    ValidationErrorMessageComponent,
    ValidationErrorSummaryComponent,
    ProfileOnboardingChecklistComponent,
    InvestmentLimitPopupComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    MatRadioModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatTooltipModule,
    DigitOnlyModule,
    MatTableModule,
    MomentModule,
    CKEditorModule,
  ],
  exports: [
    PopupModalComponent,
    LoadingComponent,
    IconComponent,
    BlockUiModalComponent,
    UploaderDirective,
    TrustSrcPipe,
    LegacyFormatterPipe,
    BreadcrumbComponent,
    TagListComponent,
    ButtonComponent,
    JsonLdComponent,
    SkeletonComponent,
    AlertBarComponent,
    DatetimeMomentDirective,
    LoaderComponent,
    ImageAspectRatioDirective,
    PluralizePipe,
    TranslateModule,
    EnumToArrayPipe,
    NumberSuffixPipe,
    DateAgoPipe,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    ScrollSpyDirective,
    SelectSearchFilterComponent,
    NoScrollInputDirective,
    WeblinkPipe,
    FloatingMenuComponent,
    LazyImgDirective,
    GtmInputBlurDirective,
    FormatVideoURLPipe,
    AlphanumericDirective,
    DateRangePickerComponent,
    NoSpaceDirective,
    AlphanumericDirective,
    TwoDigitDecimaNumberDirective,
    FinancialListComponent,
    UpdatesComponent,
    NewsComponent,
    LoginOrSignupComponent,
    EmptyStateComponent,
    ThumbnailGroupComponent,
    FaqAccordionComponent,
    SearchInputComponent,
    PageComponent,
    ProductBadgeComponent,
    TabComponent,
    OrderSummaryComponent,
    ToastsComponent,
    ValidationErrorMessageComponent,
    ValidationErrorSummaryComponent,
    ProfileOnboardingChecklistComponent,
    InvestmentLimitPopupComponent,
  ],
  providers: [
    CurrencyPipe,
    DateAgoPipe,
    PluralizePipe,
    QuestionControlService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: FULL_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
})
export class SharedModule {}
