<div class="row justify-content-center mb-5">
  <div class="col-12">
    <ng-container *ngIf="myInvestment">
      <div class="row">
        <div class="col-lg-4 col-md-6 mt-3">
          <div class="analytic-box">
            <div class="analytic-title">{{ 'investment.investedAmount' | translate }}</div>
            <div class="analytic-amount text-danger font-weight-extrabold">
              {{ myInvestment.investmentAmount | numberSuffix }}
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mt-3">
          <div class="analytic-box">
            <div class="analytic-title">{{ 'campaign.shareOwned' | translate }}</div>
            <div class="analytic-amount text-danger font-weight-extrabold">
              {{ myInvestment.shareQuantity | number }}
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-4 pb-2">
        <div class="col-12">
          <h4 class="font-weight-bold">{{ 'investment.myInvestment' | translate }}</h4>
        </div>
      </div>
      <div class="row d-md-none investment-panel">
        <div class="col-12 investment-list">
          <div
            class="row content-row position-relative"
            *ngFor="let investment of myInvestment.investments"
          >
            <div class="col-12 text-black content-col">
              <span class="font-weight-bold pr-2">{{ 'investment.amount' | translate }}: </span
              >{{ investment.pledgedAmount | numberSuffix }}
            </div>
            <ng-container *ngIf="campaignStatus != CampaignStatus.ShareAllotment">
              <div class="col-12 text-black content-col">
                <span class="font-weight-bold pr-2"
                  >{{ 'investment.refundAmount' | translate }}: </span
                >{{
                  investment.refundedAmount > 0 ? (investment.refundedAmount | numberSuffix) : '-'
                }}
              </div>
              <div
                class="col-12 font-weight-bold content-col {{
                  InvestmentStatus[investment.investmentStatus]
                }}"
              >
                <span class="text-black font-weight-bold pr-2">
                  {{ 'investment.investmentStatus' | translate }}:
                </span>
                <i class="fa fa-circle mr-2"></i
                >{{ 'investment.' + InvestmentStatus[investment.investmentStatus] | translate }}
              </div>
              <div
                class="col-12 font-weight-bold content-col {{
                  RefundStatus[investment.refundStatus]
                }}"
              >
                <span class="text-black font-weight-bold pr-2">
                  {{ 'investment.refundStatus' | translate }}:
                </span>
                <ng-container *ngIf="investment.refundStatus != 99">
                  <i class="fa fa-circle mr-2"></i
                  >{{ 'investment.' + RefundStatus[investment.refundStatus] | translate }}
                </ng-container>
                <span *ngIf="investment.refundStatus == 99">-</span>
              </div>
            </ng-container>
            <div class="col-12 content-col">
              <span class="text-black font-weight-bold pr-2">
                {{ 'investment.requestForMoreInfo' | translate }}:
              </span>
              <span
                class="btn btn-primary-border"
                [routerLink]="'edd/' + investment.id + '/' + investment.investmentEddId"
                *ngIf="investment.isNeedFillUpEdd"
              >
                {{ 'investment.fillForm' | translate }}</span
              >
              <span
                class="link"
                [routerLink]="'edd/' + investment.id + '/' + investment.investmentEddId"
                *ngIf="investment.isEddFilled"
              >
                {{ 'investment.viewSubmittedInfo' | translate }}</span
              >
              <span *ngIf="!investment.isNeedFillUpEdd && !investment.isEddFilled">-</span>
            </div>
            <div class="col-12 text-black content-col">
              {{ investment.createdAt | amDateFormat: 'D MMM YYYY, hh:mm A' }}
            </div>
            <div
              class="col-12 action-col"
              *ngIf="
                [
                  CampaignStatus.ShareAllotment,
                  CampaignStatus.Success,
                  CampaignStatus.Fail,
                ].includes(campaignStatus)
              "
            >
              <div
                class="action"
                ngbDropdown
                placement="bottom-right"
                *ngIf="
                  [InvestmentStatus.PendingPayment, InvestmentStatus.Paid].includes(
                    investment.investmentStatus
                  )
                "
              >
                <div
                  class="pitchin-icon pitchin-icon-more-horizontal no-caret"
                  ngbDropdownToggle
                ></div>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <div
                    class="dropdown-item font-size-regular font-weight-bold cursor-pointer"
                    (click)="cancelInvestment(investment.id)"
                  >
                    {{ 'investment.cancelInvestment' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 pb-5 text-center"
          *ngIf="!myInvestment.investments || myInvestment.investments.length == 0"
        >
          <app-empty-state></app-empty-state>
        </div>
      </div>
      <div class="row d-none d-md-block">
        <div class="col-12">
          <mat-table class="responsive-table" [dataSource]="myInvestment.investments">
            <ng-container matColumnDef="PledgedAmount">
              <mat-header-cell *matHeaderCellDef>{{
                'investment.amount' | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let item">
                {{ item.pledgedAmount | numberSuffix }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="RefundedAmount">
              <mat-header-cell *matHeaderCellDef
                >{{ 'investment.refundAmount' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let item">
                {{ item.refundedAmount > 0 ? (item.refundedAmount | numberSuffix) : '-' }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="InvestmentStatus">
              <mat-header-cell *matHeaderCellDef
                >{{ 'investment.investmentStatus' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let item">
                <div
                  class="font-weight-bold content-col {{ InvestmentStatus[item.investmentStatus] }}"
                >
                  <i class="fa fa-circle mr-2"></i
                  >{{ 'investment.' + InvestmentStatus[item.investmentStatus] | translate }}
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="RefundStatus">
              <mat-header-cell *matHeaderCellDef
                >{{ 'investment.refundStatus' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let item">
                <div
                  class="font-weight-bold content-col {{ RefundStatus[item.refundStatus] }}"
                  *ngIf="item.refundStatus != 99"
                >
                  <i class="fa fa-circle mr-2"></i
                  >{{ 'investment.' + RefundStatus[item.refundStatus] | translate }}
                </div>
                <div *ngIf="item.refundStatus == 99">-</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="RequestForMoreInfo">
              <mat-header-cell *matHeaderCellDef
                >{{ 'investment.requestForMoreInfo' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let item">
                <span class="d-md-none text-black font-weight-bold pr-2">
                  {{ 'investment.requestForMoreInfo' | translate }}:
                </span>
                <span
                  class="btn btn-primary-border"
                  [routerLink]="'edd/' + item.id + '/' + item.investmentEddId"
                  *ngIf="item.isNeedFillUpEdd"
                >
                  {{ 'investment.fillForm' | translate }}</span
                >
                <span
                  class="link"
                  [routerLink]="'edd/' + item.id + '/' + item.investmentEddId"
                  *ngIf="item.isEddFilled"
                >
                  {{ 'investment.viewSubmittedInfo' | translate }}</span
                >
                <span *ngIf="!item.isNeedFillUpEdd && !item.isEddFilled">-</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="CreatedAt">
              <mat-header-cell *matHeaderCellDef
                >{{ 'investment.transactionDate' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let item">
                {{ item.createdAt | amDateFormat: 'D MMM YYYY, hh:mm A' }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Action">
              <mat-header-cell *matHeaderCellDef
                >{{ 'investment.actions' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let item">
                <div
                  class="action"
                  ngbDropdown
                  container="body"
                  *ngIf="
                    [InvestmentStatus.PendingPayment, InvestmentStatus.Paid].includes(
                      item.investmentStatus
                    )
                  "
                >
                  <div
                    class="pitchin-icon pitchin-icon-more-horizontal no-caret"
                    ngbDropdownToggle
                  ></div>
                  <div ngbDropdownMenu>
                    <div
                      class="font-weight-bold cursor-pointer"
                      ngbDropdownItem
                      (click)="cancelInvestment(item.id)"
                    >
                      {{ 'investment.cancelInvestment' | translate }}
                    </div>
                  </div>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayColumns"> </mat-header-row>
            <mat-row *matRowDef="let item; columns: displayColumns"> </mat-row>
          </mat-table>
        </div>
        <div
          class="col-12 py-4 text-center"
          *ngIf="!myInvestment.investments || myInvestment.investments.length == 0"
        >
          <app-empty-state></app-empty-state>
        </div>
      </div>
    </ng-container>
    <agmo-loading *ngIf="!myInvestment"></agmo-loading>
  </div>
</div>
