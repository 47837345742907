import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { News } from '../../models/news/news';

@Injectable()
export class RssFeedService {
  private rssToJsonServiceBaseUrl: string = 'https://rss2json.com/api.json?rss_url=';

  constructor(private http: HttpClient) {}

  getFeedContent(url: string): Observable<News> {
    let encodedUrl = encodeURIComponent(url);

    return this.http.get(this.rssToJsonServiceBaseUrl + encodedUrl).pipe(
      map((data: any) => {
        return data;
      }),
    );
  }
}
