import { Component, Input, OnInit } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { ShareOwnership } from '../../../core/enum/share-ownership.enum';
import { PortfolioList } from '../../../core/models/portfolio/portfolio-list';
import { PortfolioListModel } from '../../../core/models/portfolio/portfolio-list.model';
import { PortfolioService } from '../../../core/services/api/portfolio.service';
import { ErrorService } from '../../../core/services/error.service';
import { PortfolioSharePopupComponent } from '../portfolio-share-popup/portfolio-share-popup.component';
import { ShareMarketStatus } from '../../../core/enum/mysec/share-market-status.enum';
import { ValuationBasisPopupComponent } from '../valuation-basis-popup/valuation-basis-popup.component';

@Component({
  selector: 'app-my-shares',
  templateUrl: './my-shares.component.html',
  styleUrls: ['./my-shares.component.scss'],
})
export class MySharesComponent implements OnInit {
  @Input() portfolioListModel: PortfolioListModel[];
  @Input() total: number;

  portfolioList: PortfolioList[] = [];
  displayColumns: string[] = [
    'Company',
    'ShareQty',
    'PurchasedPrice',
    'MarketPrice',
    'Profit',
    'Action',
  ];
  params = {
    currentPage: 1,
    take: 20,
  };
  isLoading: boolean = false;

  ShareOwnership = ShareOwnership;
  ShareMarketStatus = ShareMarketStatus;

  constructor(
    private errorService: ErrorService,
    private portfolioService: PortfolioService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    if (!this.portfolioListModel) {
      this.getPortfolio();
    }
  }

  getPortfolio() {
    this.isLoading = true;

    this.portfolioService
      .getPortfolio(this.params.currentPage, this.params.take)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          let portfolioList = data.data;

          portfolioList.map((item: PortfolioListModel) => {
            item.portfolio.unrealisedGainLossPercentage = Number(
              (
                (item.portfolio?.unrealisedGainLoss / item.portfolio?.purchasedAmount) *
                100
              ).toFixed(2),
            );
            item.isCollapsed = true;
          });

          this.portfolioListModel = portfolioList;
          this.total = data.total;
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }

  changePage(event?: PageEvent) {
    this.params.currentPage = event.pageIndex + 1;
    this.params.take = event.pageSize;
    this.getPortfolio();
  }

  openShare(portfolioListModel: PortfolioListModel) {
    const popupOptions = {
      size: 'md',
      centered: true,
      keyboard: false,
    } as NgbModalOptions;

    const modalRef = this.modalService.open(PortfolioSharePopupComponent, popupOptions);
    modalRef.componentInstance.portfolioListModel = portfolioListModel;
    modalRef.result.then(
      () => {},
      () => {},
    );
  }

  openUrl(url: string) {
    window.open(url);
  }

  openValuationBasisPopup(portfolioListModel: PortfolioListModel) {
    const popupOptions = {
      size: 'md',
      centered: true,
      keyboard: false,
    } as NgbModalOptions;

    const modalRef = this.modalService.open(ValuationBasisPopupComponent, popupOptions);
    modalRef.componentInstance.portfolioListModel = portfolioListModel;
    modalRef.result.then(
      () => {},
      () => {},
    );
  }
}
