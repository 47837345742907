import { Component, Input } from '@angular/core';
import { CampaignStatus } from '../../../core/enum/campaign-status.enum';
import * as moment from 'moment';
import { Campaign } from '../../../core/models/campaign/campaign';

@Component({
  selector: 'app-partner-campaign-card',
  templateUrl: './partner-campaign-card.component.html',
})
export class PartnerCampaignCardComponent {
  @Input() campaign: Campaign;

  CampaignStatus: typeof CampaignStatus = CampaignStatus;

  calculateProgress(percentage: number): number {
    return percentage > 100 ? 100 : percentage;
  }

  calculateDayLeft(selectedDateTime: Date) {
    const now = moment(new Date());
    const end = moment(selectedDateTime);
    return end.diff(now, 'days');
  }

  calculateRaisedPercentange() {
    return this.campaign.raisedPercentage > 0 && this.campaign.raisedPercentage < 1
      ? 1
      : Math.round(this.campaign.raisedPercentage);
  }
}
