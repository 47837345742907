import { CompanyLiteModel } from './company-lite.model';

export class CompanyLite {
  brandName: string;
  companyId: number;
  companyLogoUrl?: string;
  companyType?: number;
  name: string;
  registrationNumber: string;
  slug?: string;
  tickerCode?: string;

  constructor(model: CompanyLiteModel) {
    this.brandName = model.brandName;
    this.companyId = model.companyId;
    this.companyLogoUrl = model.companyLogoUrl;
    this.companyType = model.companyType;
    this.name = model.name;
    this.registrationNumber = model.registrationNumber;
    this.slug = model.slug;
    this.tickerCode = model.tickerCode;
  }
}
