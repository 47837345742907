<section class="section">
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12">
        <h1 class="title font-weight-bold text-dark text-center">
          {{ 'Fee Structure' | translate }}
        </h1>
      </div>
    </div>
  </div>

  <div class="container-fluid tw-pt-[30px]">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <h2 class="p-text-h3 tw-mb-5">Fees for investors</h2>

        <h3 class="p-text-p tw-font-body tw-font-semibold">Equity Crowdfunding (ECF)</h3>
        <ul class="tw-pl-5">
          <li>Investors are not charged any fee to invest in crowdfunding campaigns.</li>
        </ul>

        <h3 class="p-text-p tw-mt-8 tw-font-body tw-font-semibold">Token Crowdfunding (TCF)</h3>
        <ul class="tw-pl-5">
          <li>Investors are not charged any fee to invest in crowdfunding campaigns.</li>
        </ul>

        <h3 class="p-text-p tw-mt-8 tw-font-body tw-font-semibold">
          pitchIN Secondary Trading Market (PSTX)
        </h3>
        <ul class="tw-pl-5">
          <li>
            <a [routerLink]="['/learn/trade/pstx-fee-structure']"
              >View fees when trading on the Secondary Market →</a
            >
          </li>
        </ul>

        <hr />

        <h2 class="p-text-h3 tw-mb-5">Fees for issuers</h2>
        <p>Click on the links below to view fees associated with fundraising on pitchIN.</p>
        <ul class="tw-space-y-2.5 tw-pl-5">
          <li>
            <a [routerLink]="['/learn/raise/types-of-fees-for-issuers']"
              >Equity Crowdfunding (ECF) fundraising fees →</a
            >
          </li>
          <li>
            <a [routerLink]="['/learn/token-issue/tcf-fee-structure']"
              >Token Crowdfunding (TCF) fundraising fees →</a
            >
          </li>
          <li>
            <a [routerLink]="['/learn/trade/pstx-fee-structure']"
              >pitchIN Secondary Trading Market (PSTX) trading fees →</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
