<div [navbarHeight]="80" scrollSpy [spiedTags]="['DIV']" (sectionChange)="onSectionChange($event)">
  <div class="row mb-5" *ngIf="businessDirectory.videoUrl">
    <div class="col-12">
      <div class="video-panel">
        <div class="video-container">
          <iframe
            class="embed-responsive-item"
            title="business video"
            [src]="businessDirectory.videoUrl | formatVideoURL"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-5" *ngIf="businessDirectory.companyDetail?.description" id="summary">
    <div class="col-12">
      <div class="campaign-subtitle text-primary">
        {{ 'company.summary' | translate }}
      </div>
      <div class="mt-3">
        {{ businessDirectory.companyDetail.description }}
      </div>
    </div>
  </div>
  <div class="row mb-5" *ngIf="businessDirectory.companyDetail?.info" id="about">
    <div class="col-12">
      <div class="campaign-subtitle text-primary">
        {{ 'company.about' | translate }}
      </div>
      <div
        class="mt-3 ck-content"
        [innerHTML]="businessDirectory.companyDetail.info | trustSrc"
      ></div>
    </div>
  </div>
</div>
