<div class="modal-body">
  <div class="text-center mb-5">
    <img class="icon" alt="Popup Icon" [src]="iconUrl" />
  </div>
  <div *ngIf="showTitle" class="text-primary font-weight-extrabold title" [ngClass]="titleClass">
    {{
      titleLabel || (type == PopupType.success ? 'common.done' : 'common.information') | translate
    }}
  </div>
  <div class="message" [innerHTML]="message | translate"></div>
  <div class="buttons">
    <button
      type="button"
      class="btn btn-primary ok"
      aria-label="OK"
      (click)="ok()"
      [ngClass]="okClass"
    >
      {{ okLabel || 'common.ok' | translate }}
    </button>
    <button
      type="button"
      class="btn btn cancel"
      aria-label="Cancel"
      (click)="cancel()"
      [ngClass]="cancelClass"
      *ngIf="type == PopupType.confirm || (type == PopupType.success && cancelLabel)"
    >
      {{ cancelLabel || 'common.cancel' | translate }}
    </button>
  </div>
</div>
