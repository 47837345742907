import { StorageService } from './../storage.service';
import { environment } from './../../../../environments/environment';
import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NotificationEvent } from '../../models/notification/notification-event';
import { getMessaging, getToken, NotificationPayload, onMessage } from 'firebase/messaging';
import { NotificationItem } from '../../models/notification/notification-item';

@Injectable()
export class PushNotificationService {
  event: Observable<NotificationEvent>;
  private eventSubject: Subject<NotificationEvent> = new Subject<NotificationEvent>();

  constructor(
    private storageService: StorageService,
    private ngZone: NgZone,
  ) {
    this.event = this.eventSubject.asObservable();
    this.event.subscribe((event: NotificationEvent) => this.handleEvent(event));
  }

  requestPermission() {
    const messaging = getMessaging();

    getToken(messaging, { vapidKey: environment.publicAPIKey })
      .then((currentToken) => {
        if (currentToken) {
          console.log('registrationId:' + currentToken);
          this.storageService.registrationId = currentToken;
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      this.showNotification(payload.notification, payload.fcmOptions.link);
    });
  }

  showNotification(data: NotificationPayload, link: string): void {
    const notificationTitle = data.title;
    const notificationOptions = {
      body: data.body,
      data: data,
      icon: '/assets/img/brand/pitchIN_logo.png',
    };
    const eventBody: NotificationItem = {
      title: data.title,
      body: data.body,
      clickLink: link,
    };

    const notification = new Notification(notificationTitle, notificationOptions);
    notification.onclick = () => {
      this.ngZone.run(() => {
        this.eventSubject.next({ type: 'click', data: eventBody });
        window.focus();
      });

      notification.close();
    };

    this.eventSubject.next({ type: 'receive', data: eventBody });
  }

  handleEvent(event: NotificationEvent) {
    if (event.type == 'click') this.handleClick(event.data);
  }

  handleClick(data: NotificationItem): void {
    window.location.replace(data.clickLink);
  }
}
