<ng-container *ngIf="fundRaiseProcess">
  <div class="table-container table-responsive">
    <table class="table">
      <thead>
        <tr class="title">
          <th id="title" class="text-black-50" width="50%">
            {{ 'common.item' | translate }}
          </th>
          <th id="lastUpdatedDate" class="text-black-50" width="30%">
            {{ 'raise.lastUpdatedOn' | translate }}
          </th>
          <th id="raiseFundApplicationProcessStatusType" class="text-black-50" width="20%">
            {{ 'common.status' | translate }}
          </th>
        </tr>
        <tr class="header">
          <th id="title">
            <div class="d-flex flex-row align-items-center">
              <div
                class="pitchin-icon-md pitchin-icon-check-grey mr-4"
                [class.pitchin-icon-check-green]="
                  fundRaiseProcess.raiseFundApplicationProcessStatusType ==
                  RaiseFundApplicationProcessStatusType.Approved
                "
              ></div>
              <span>{{ tabContent.title | translate }}</span>
            </div>
          </th>
          <th id="lastUpdatedDate">
            <div class="font-weight-normal">
              {{ (fundRaiseProcess.lastUpdatedDate | amDateFormat: 'DD MMM YYYY, HH:mm a') || '-' }}
            </div>
          </th>
          <th id="raiseFundApplicationProcessStatusType">
            <ng-container
              *ngIf="
                fundRaiseProcess.raiseFundApplicationProcessStatusType ==
                RaiseFundApplicationProcessStatusType.Approved
              "
            >
              <ng-container [ngSwitch]="tabContent.tab">
                <ng-container
                  *ngSwitchCase="RaiseFundApplicationProcessModule.FundRaiseApplication"
                >
                  <button type="btn" class="btn btn-tertiary btn-lg" (click)="nextStep()">
                    {{ tabContent.btnText | translate }}
                  </button>
                </ng-container>
                <ng-container *ngSwitchCase="RaiseFundApplicationProcessModule.DueDiligence">
                  <button type="btn" class="btn btn-tertiary btn-lg" (click)="nextStep()">
                    {{ tabContent.btnText | translate }}
                  </button>
                </ng-container>
                <ng-container *ngSwitchCase="RaiseFundApplicationProcessModule.OfferCreation">
                  <button
                    type="btn"
                    class="btn btn-tertiary btn-lg"
                    *ngIf="!campaignCreated"
                    [routerLink]="['/raise/new-campaign', fundRaiseApplicationId]"
                  >
                    {{ tabContent.btnText | translate }}
                  </button>
                  <button
                    type="btn"
                    class="btn btn-tertiary btn-lg"
                    *ngIf="campaignCreated"
                    (click)="nextStep()"
                  >
                    {{ 'raise.proceedToCampaignCreation' | translate }}
                  </button>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="
                tabContent.tab == RaiseFundApplicationProcessModule.CreateCampaign &&
                fundRaiseProcess?.items[0]?.campaignId
              "
            >
              <button
                type="btn"
                class="btn btn-tertiary btn-lg"
                [routerLink]="[
                  '/raise/campaign-details',
                  fundRaiseApplicationId,
                  fundRaiseProcess.items[0].campaignId,
                ]"
              >
                {{ tabContent.btnText | translate }}
              </button>
            </ng-container>
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- Required for Pre-live -->
        <ng-container *ngIf="requiredForPreLiveItems.length > 0">
          <tr>
            <td colspan="3" class="group-header-td !tw-p-0">
              <div class="group-header">Required for Pre-Live</div>
            </td>
          </tr>
          <ng-container *ngFor="let item of requiredForPreLiveItems">
            <ng-template *ngTemplateOutlet="desktopList; context: { item: item }"></ng-template>
          </ng-container>
        </ng-container>

        <!-- Required for Live -->
        <ng-container *ngIf="requiredForLiveItems.length > 0">
          <tr>
            <td colspan="3" class="group-header-td !tw-p-0">
              <div class="group-header">Required for Live</div>
            </td>
          </tr>
          <ng-container *ngFor="let item of requiredForLiveItems">
            <ng-template *ngTemplateOutlet="desktopList; context: { item: item }"></ng-template>
          </ng-container>
        </ng-container>

        <!-- Other without requirements (mainly for non-DD tab) -->
        <ng-container *ngIf="noRequirementItems.length > 0">
          <ng-container *ngFor="let item of noRequirementItems">
            <ng-template *ngTemplateOutlet="desktopList; context: { item: item }"></ng-template>
          </ng-container>
        </ng-container>

        <!-- Optional -->
        <ng-container *ngIf="optionalItems.length > 0">
          <tr>
            <td colspan="3" class="group-header-td !tw-p-0">
              <div class="group-header">Optional</div>
            </td>
          </tr>
          <ng-container *ngFor="let item of optionalItems">
            <ng-template *ngTemplateOutlet="desktopList; context: { item: item }"></ng-template>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="list-container">
    <div class="list-header">
      <div class="d-flex flex-row">
        <div
          class="pitchin-icon-md pitchin-icon-check-grey mr-4"
          [class.pitchin-icon-check-green]="fundRaiseProcess.isCompleted"
        ></div>
        <div>
          <div class="font-weight-bold">{{ tabContent.title | translate }}</div>
          <div *ngIf="fundRaiseProcess.lastUpdatedDate">
            {{ fundRaiseProcess.lastUpdatedDate | amDateFormat: 'DD MMM YYYY, HH:mm a' }}
          </div>
          <button
            type="btn"
            class="btn btn-tertiary btn-lg mt-2"
            *ngIf="fundRaiseProcess.isCompleted"
            (click)="nextStep()"
          >
            {{ tabContent.btnText | translate }}
          </button>
        </div>
      </div>
    </div>

    <!-- Required for Pre-Live -->
    <ng-container *ngIf="requiredForPreLiveItems.length > 0">
      <div class="group-header--mobile">Required for Pre-Live</div>
      <div class="list-body" *ngFor="let item of requiredForPreLiveItems">
        <ng-template *ngTemplateOutlet="mobileList; context: { item: item }"></ng-template>
      </div>
    </ng-container>

    <!-- Required for Live -->
    <ng-container *ngIf="requiredForLiveItems.length > 0">
      <div class="group-header--mobile">Required for Live</div>
      <div class="list-body" *ngFor="let item of requiredForLiveItems">
        <ng-template *ngTemplateOutlet="mobileList; context: { item: item }"></ng-template>
      </div>
    </ng-container>

    <!-- Required for Optional -->
    <ng-container *ngIf="optionalItems.length > 0">
      <div class="group-header--mobile">Optional</div>
      <div class="list-body" *ngFor="let item of optionalItems">
        <ng-template *ngTemplateOutlet="mobileList; context: { item: item }"></ng-template>
      </div>
    </ng-container>

    <!-- No requirement -->
    <ng-container *ngIf="noRequirementItems.length > 0">
      <div class="list-body" *ngFor="let item of noRequirementItems">
        <ng-template *ngTemplateOutlet="mobileList; context: { item: item }"></ng-template>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #desktopList let-item="item">
  <ng-template *ngTemplateOutlet="row; context: { item: item }"></ng-template>
  <ng-container *ngIf="tabContent.tab != RaiseFundApplicationProcessModule.OfferCreation">
    <ng-container *ngFor="let subItem of item.subItems">
      <ng-template
        *ngTemplateOutlet="row; context: { item: subItem, padding: 'pl-5', parent: item }"
      >
      </ng-template>
      <ng-container *ngIf="subItem.subItems?.length > 0">
        <ng-container *ngFor="let sub of subItem.subItems">
          <ng-template
            *ngTemplateOutlet="row; context: { item: sub, padding: 'pl-6', parent: subItem }"
          >
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #mobileList let-item="item">
  <ng-template *ngTemplateOutlet="mobilerow; context: { item: item }"></ng-template>
  <ng-container *ngIf="tabContent.tab != RaiseFundApplicationProcessModule.OfferCreation">
    <ng-container *ngFor="let subItem of item.subItems">
      <ng-template
        *ngTemplateOutlet="mobilerow; context: { item: subItem, padding: 'pl-5', parent: item }"
      >
      </ng-template>
      <ng-container *ngFor="let sub of subItem.subItems">
        <ng-template
          *ngTemplateOutlet="mobilerow; context: { item: sub, padding: 'pl-5', parent: subItem }"
        >
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #row let-item="item" let-padding="padding" let-parent="parent">
  <tr>
    <td scope="row">
      <div class="row {{ padding }}">
        <div class="col-1">
          <div
            class="pitchin-icon-md pitchin-icon-check-grey mr-4"
            [class.pitchin-icon-check-green]="
              [
                RaiseFundApplicationProcessStatusType.Approved,
                RaiseFundApplicationProcessStatusType.Paid,
              ].includes(item.raiseFundApplicationProcessStatusType)
            "
            [class.pitchin-icon-rejected-icon-red]="
              item.raiseFundApplicationProcessStatusType ==
              RaiseFundApplicationProcessStatusType.Rejected
            "
          ></div>
        </div>
        <div class="col-11 align-self-center">
          <div class="d-flex flex-column">
            <div [innerHTML]="item.title"></div>
            <ng-template *ngTemplateOutlet="detail; context: { item: item }"></ng-template>
            <div
              class="link"
              *ngIf="
                item.raiseFundApplicationActionType !=
                  RaiseFundApplicationActionType.UploadReceipt &&
                item.sampleAttachmentUrl &&
                !item.attachment
              "
              (click)="viewDetails(item.sampleAttachmentUrl)"
            >
              {{ 'raise.downloadTemplate' | translate }}
            </div>
          </div>
        </div>
      </div>
    </td>
    <td>
      <div class="font-weight-normal">
        {{ (item.lastUpdatedDate | amDateFormat: 'DD MMM YYYY, HH:mm a') || '-' }}
      </div>
    </td>
    <td>
      <ng-template
        *ngTemplateOutlet="action; context: { item: item, parent: parent }"
      ></ng-template>
    </td>
  </tr>
</ng-template>

<ng-template #detail let-item="item">
  <ng-container
    *ngIf="item.raiseFundApplicationProcessStatusType != RaiseFundApplicationActionType.None"
  >
    <ng-container [ngSwitch]="item.raiseFundApplicationActionType">
      <ng-container *ngSwitchCase="RaiseFundApplicationActionType.DynamicForm">
        <div
          class="link"
          *ngIf="
            [
              RaiseFundApplicationProcessModule.FundRaiseApplication,
              RaiseFundApplicationProcessModule.DueDiligence,
            ].includes(tabContent.tab)
          "
          [routerLink]="
            'detail/' +
            fundRaiseApplicationId +
            '/steps/' +
            item.id +
            '/submission/' +
            item.dynamicFormSubmissionId
          "
        >
          {{ 'common.viewDetails' | translate }}
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="RaiseFundApplicationActionType.OfferCreation">
        <div class="link" [routerLink]="['/raise/offer-creation', fundRaiseApplicationId]">
          {{ 'common.viewDetails' | translate }}
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="RaiseFundApplicationActionType.CreateCampaign">
        <div
          class="link"
          *ngIf="tabContent.tab == RaiseFundApplicationProcessModule.CreateCampaign"
          [routerLink]="['/raise/campaign-details', fundRaiseApplicationId, item.campaignId]"
        >
          {{ 'common.viewDetails' | translate }}
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="RaiseFundApplicationActionType.Ekyc">
        <div class="link" [routerLink]="['/ekyc/detail']">
          {{ 'common.viewDetails' | translate }}
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="RaiseFundApplicationActionType.UploadDocument">
        <div class="link" *ngIf="item.attachment" (click)="viewDetails(item.attachment.url)">
          {{ 'common.viewDetails' | translate }}
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="RaiseFundApplicationActionType.Attachment">
        <div class="link" *ngIf="item.attachment" (click)="viewDetails(item.attachment.url)">
          {{ 'common.viewDetails' | translate }}
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="RaiseFundApplicationActionType.UploadCampaignVideo">
        <div class="link" *ngIf="item.attachment" (click)="viewDetails(item.attachment.url)">
          {{ 'common.viewVideo' | translate }}
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="RaiseFundApplicationActionType.UploadReceipt">
        <div class="link" *ngIf="item.attachment" (click)="viewDetails(item.attachment.url)">
          {{ 'common.viewReceipt' | translate }}
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="RaiseFundApplicationActionType.FPXPayment">
        <div
          class="link"
          *ngIf="item.paymentGatewayReferenceNumber"
          [routerLink]="'/raise-fund-receipt/' + item.paymentGatewayReferenceNumber"
        >
          {{ 'common.viewReceipt' | translate }}
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #action let-item="item" let-parent="parent">
  <ng-container
    *ngIf="
      item.raiseFundApplicationProcessStatusType != RaiseFundApplicationProcessStatusType.None &&
      ![
        RaiseFundApplicationActionType.UploadReceipt,
        RaiseFundApplicationActionType.Attachment,
      ].includes(item.raiseFundApplicationActionType)
    "
  >
    <div
      class="d-flex flex-row align-items-center"
      [class.approved]="
        [
          RaiseFundApplicationProcessStatusType.Approved,
          RaiseFundApplicationProcessStatusType.Paid,
          RaiseFundApplicationProcessStatusType.BypassPayment,
        ].includes(item.raiseFundApplicationProcessStatusType)
      "
      [class.pending]="
        [
          RaiseFundApplicationProcessStatusType.Pending,
          RaiseFundApplicationProcessStatusType.PendingIssuerApproval,
          RaiseFundApplicationProcessStatusType.PendingPayment,
          RaiseFundApplicationProcessStatusType.PendingFPXApproval,
        ].includes(item.raiseFundApplicationProcessStatusType)
      "
      [class.rejected]="
        [
          RaiseFundApplicationProcessStatusType.Rejected,
          RaiseFundApplicationProcessStatusType.RejectedByIssuer,
        ].includes(item.raiseFundApplicationProcessStatusType)
      "
    >
      <i class="fa fa-circle mr-3"></i>
      <div>
        {{
          'raise.' +
            RaiseFundApplicationProcessStatusType[item.raiseFundApplicationProcessStatusType]
            | translate
        }}
      </div>
    </div>
    <div
      class="mb-2 lastReviewedDate"
      *ngIf="
        item.raiseFundApplicationProcessStatusType !=
          RaiseFundApplicationProcessStatusType.Pending &&
        item.raiseFundApplicationProcessStatusType !=
          RaiseFundApplicationProcessStatusType.PendingIssuerApproval
      "
    >
      {{ item.lastReviewedDate | amDateFormat: 'DD MMM YYYY, HH:mm a' }}
    </div>
    <div
      class="text-black-50"
      *ngIf="
        item.raiseFundApplicationProcessStatusType == RaiseFundApplicationProcessStatusType.Paid &&
        fundRaiseProcess.paymentRef
      "
    >
      #{{ fundRaiseProcess.paymentRef }}
    </div>
    <ng-container
      *ngIf="
        [
          RaiseFundApplicationProcessStatusType.Rejected,
          RaiseFundApplicationProcessStatusType.RejectedByIssuer,
        ].includes(item.raiseFundApplicationProcessStatusType)
      "
    >
      <ng-container *ngIf="item.remark">
        <div class="text-black-50">{{ 'raise.remark' | translate }} :</div>
        <div class="mb-3">{{ item.remark }}</div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container
    *ngIf="
      item.raiseFundApplicationProcessStatusType == RaiseFundApplicationProcessStatusType.None ||
      item.raiseFundApplicationProcessStatusType == RaiseFundApplicationProcessStatusType.Rejected
    "
  >
    <button
      type="btn"
      class="btn btn-outline-primary"
      [routerLink]="
        item.raiseFundApplicationProcessStatusType == RaiseFundApplicationProcessStatusType.Rejected
          ? 'form/' +
            fundRaiseApplicationId +
            '/steps/' +
            item.id +
            '/submission/' +
            item.dynamicFormSubmissionId
          : 'form/' + fundRaiseApplicationId + '/steps/' + item.id
      "
      *ngIf="item.raiseFundApplicationActionType == RaiseFundApplicationActionType.DynamicForm"
    >
      {{ 'raise.fillAndSubmit' | translate }}
    </button>
    <div
      class="d-flex flex-row align-items-center pending"
      *ngIf="item.raiseFundApplicationActionType == RaiseFundApplicationActionType.EKYC"
    >
      <i class="fa fa-circle mr-3"></i> {{ 'raise.pendingAdminVerification' | translate }}
    </div>
    <a
      class="btn btn-outline-primary"
      *ngIf="
        item.campaignId &&
        item.raiseFundApplicationActionType == RaiseFundApplicationActionType.CreateCampaign
      "
      [routerLink]="['/raise/edit-campaign', fundRaiseApplicationId, item.campaignId]"
    >
      {{ 'raise.fillAndSubmit' | translate }}
    </a>
  </ng-container>
  <ng-container
    *ngIf="
      item.raiseFundApplicationProcessStatusType == RaiseFundApplicationProcessStatusType.None &&
      item.raiseFundApplicationActionType == RaiseFundApplicationActionType.OfferCreation
    "
  >
    <div class="d-flex flex-row align-items-center pending">
      <i class="fa fa-circle mr-3"></i>
      <div>{{ 'raise.PendingOfferCreation' | translate }}</div>
    </div>
  </ng-container>
  <div
    class="d-flex align-items-center"
    *ngIf="
      item.raiseFundApplicationActionType == RaiseFundApplicationActionType.FPXPayment &&
      [
        RaiseFundApplicationProcessStatusType.None,
        RaiseFundApplicationProcessStatusType.Rejected,
      ].includes(item.raiseFundApplicationProcessStatusType)
    "
  >
    <button type="btn" class="btn btn-outline-primary" (click)="makePayment()">
      {{ 'raise.makePayment' | translate }}
    </button>
  </div>
  <ng-container
    *ngIf="
      [
        RaiseFundApplicationActionType.UploadDocument,
        RaiseFundApplicationActionType.UploadVideo,
        RaiseFundApplicationActionType.UploadCampaignVideo,
      ].includes(item.raiseFundApplicationActionType) &&
      (item.raiseFundApplicationProcessStatusType == RaiseFundApplicationProcessStatusType.None ||
        item.raiseFundApplicationProcessStatusType ==
          RaiseFundApplicationProcessStatusType.Rejected)
    "
  >
    <div
      class="link mb-3 d-md-none"
      *ngIf="
        item.raiseFundApplicationProcessStatusType == RaiseFundApplicationActionType.None &&
        item.sampleAttachmentUrl
      "
      (click)="viewDetails(item.sampleAttachmentUrl)"
    >
      {{ 'raise.downloadTemplate' | translate }}
    </div>
    <div class="input-field">
      <div
        class="document-upload cursor-pointer"
        agmoUploader
        [accept]="getAcceptType(item.raiseFundApplicationActionType)"
        (selectFiles)="selectFile($event, item)"
        *ngIf="!item.attachment || !item.attachmentUrl"
      >
        <div class="document-text tw-text-center">
          {{
            (item.raiseFundApplicationActionType == RaiseFundApplicationActionType.UploadDocument
              ? 'common.upload'
              : 'common.upload'
            ) | translate
          }}
        </div>
        <!-- <div class="pitchin-icon pitchin-icon-upload"></div> -->
      </div>
      <div class="document-upload uploaded" *ngIf="item.attachment && item.attachmentUrl">
        <div class="document-text">{{ item.attachment.name }}</div>
      </div>
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      item.raiseFundApplicationActionType == RaiseFundApplicationActionType.UploadMultipleFile &&
      item.raiseFundApplicationProcessStatusType != RaiseFundApplicationProcessStatusType.Approved
    "
  >
    <div
      class="document-upload cursor-pointer mt-2 tw-text-center"
      (click)="uploadAdditionalDocument(item)"
    >
      <div class="document-text">
        {{ 'common.upload' | translate }}
      </div>
      <!-- <div class="pitchin-icon pitchin-icon-upload"></div> -->
    </div>
  </ng-container>
  <!-- PLT-1195 Remove ability for issuer to delete the files -->
  <!-- <ng-container
        *ngIf="item.raiseFundApplicationActionType == RaiseFundApplicationActionType.Attachment && item.raiseFundApplicationProcessStatusType != RaiseFundApplicationProcessStatusType.Approved">
        <button class="btn btn-outline-primary" (click)="removeAttachmentByAttachmentId(item.id, parent.id)">
            {{ 'company.deleteDocument' | translate }}
        </button>
    </ng-container> -->
</ng-template>

<ng-template #mobilerow let-item="item" let-padding="padding" let-parent="parent">
  <ng-container>
    <div class="d-flex flex-row list-content padding-1 {{ padding }}">
      <div
        class="pitchin-icon-md pitchin-icon-check-grey mr-4"
        [class.pitchin-icon-check-green]="
          [
            RaiseFundApplicationProcessStatusType.Approved,
            RaiseFundApplicationProcessStatusType.Paid,
          ].includes(item.raiseFundApplicationProcessStatusType)
        "
      ></div>
      <div class="w-100">
        <div [innerHTML]="item.title"></div>
        <div class="text-black-50">
          {{ item.lastUpdatedDate | amDateFormat: 'DD MMM YYYY, HH:mm a' }}
        </div>
        <div class="mt-2">
          <ng-template *ngTemplateOutlet="detail; context: { item: item }"></ng-template>
        </div>
        <div class="mt-2">
          <ng-template
            *ngTemplateOutlet="action; context: { item: item, parent: parent }"
          ></ng-template>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<agmo-loading *ngIf="!fundRaiseProcess"></agmo-loading>
