import { Component, OnInit } from '@angular/core';
import { RaiseService } from '../../../core/services/api/raise.service';
import { finalize } from 'rxjs/operators';
import { ErrorService } from '../../../core/services/error.service';
import { FundRaiseApplication } from '../../../core/models/raise/fund-raise-application';
import { TabContent } from '../../../core/models/raise/tab-content';
import { RaiseFundApplicationProcessModule } from '../../../core/enum/raise-fund-application-process-module.enum';
import { FundRaiseProcess } from '../../../core/models/raise/fund-raise-process';
import { MyApplicationFilterService } from './my-application-filter.service';
import { MyApplicationFilter } from './my-application-filter';
import { RaiseFundApplicationProcessStatusType } from '../../../core/enum/raise-fund-application-process-status-type.enum';
import { PopupService } from '../../../shared/services/popup/popup.service';
import { Router } from '@angular/router';
import { CampaignStatus } from '../../../core/enum/campaign-status.enum';
import { FundRaiseItem } from '../../../core/models/raise/fund-raise-item';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-my-application',
  templateUrl: './my-application.component.html',
  styleUrls: ['./my-application.component.scss'],
})
export class MyApplicationComponent implements OnInit {
  public ieoApplicationUrl = environment.ieoBaseUrl + '/issuer/applications';

  isVersion2: boolean = false;

  applicationId: number;
  selectedApplication: FundRaiseApplication;
  fundRaiseApplications: FundRaiseApplication[];
  filter = this.myApplicationFilterService.filter || new MyApplicationFilter();
  campaignCreated: Boolean = false;
  isLoading: boolean = false;

  RaiseFundApplicationProcessModule: typeof RaiseFundApplicationProcessModule =
    RaiseFundApplicationProcessModule;
  CampaignStatus: typeof CampaignStatus = CampaignStatus;

  selectedFundRaiseProcess: FundRaiseProcess;
  selectedTab: RaiseFundApplicationProcessModule;
  selectedTabContent: TabContent;

  fundRaiseProcesses: { [key: number]: FundRaiseProcess } = {};
  tabContents: TabContent[] = [
    {
      tab: RaiseFundApplicationProcessModule.FundRaiseApplication,
      name: 'raise.fundRaiseApplication',
      title: 'raise.fundRaiseApplicationProcess',
      btnText: 'raise.proceedToDueDiligence',
    },
    {
      tab: RaiseFundApplicationProcessModule.DueDiligence,
      name: 'raise.dueDiligence',
      title: 'raise.dueDiligenceProcess',
      btnText: 'raise.proceedToOfferCreation',
      hoverText: 'raise.dueDiligenceDisclaimer',
      isEnabled: () => {
        // Refer to PLT-1159
        return (
          this.fundRaiseProcesses[RaiseFundApplicationProcessModule.FundRaiseApplication]?.items[0]
            ?.raiseFundApplicationProcessStatusType ===
          RaiseFundApplicationProcessStatusType.Approved
        );
      },
    },
    {
      tab: RaiseFundApplicationProcessModule.OfferCreation,
      name: 'raise.offerCreation',
      title: 'raise.offerCreationProcess',
      btnText: 'raise.createCampaign',
    },
    {
      tab: RaiseFundApplicationProcessModule.CreateCampaign,
      name: 'raise.createCampaign',
      title: 'raise.createCampaignProcess',
      btnText: 'raise.viewCampaignPage',
    },
  ];

  constructor(
    private raiseService: RaiseService,
    private errorService: ErrorService,
    private myApplicationFilterService: MyApplicationFilterService,
    private popupService: PopupService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.applicationId = this.filter.applicationId;
    this.selectedTab = this.filter.selectedTab;
    this.selectedTabContent = this.tabContents.find(
      (tabContent) => tabContent.tab === this.selectedTab,
    );
    this.getApplications();
  }

  getApplications() {
    this.raiseService.getRaiseFundApplications().subscribe({
      next: (data) => {
        this.fundRaiseApplications = data;

        this.fundRaiseApplications.sort(function (a, b) {
          return b.id - a.id;
        });

        if (this.fundRaiseApplications && this.fundRaiseApplications.length > 0) {
          if (!this.applicationId) {
            this.applicationId = this.fundRaiseApplications[0].id;
          }

          this.getApplicationProgress();
        }
      },
      error: (err) => this.errorService.showError(err),
    });
  }

  getApplicationProgress(reload?: boolean) {
    this.isLoading = true;

    this.selectedApplication = this.fundRaiseApplications.find(
      (application) => application.id == this.applicationId,
    );

    if (reload) {
      this.selectedTab = this.tabContents[0].tab;
      this.selectedTabContent = this.tabContents[0];
    }

    this.myApplicationFilterService.filter = {
      applicationId: this.applicationId,
      selectedTab: this.selectedTab,
    };

    this.raiseService
      .getApplicationProgress(this.applicationId, this.selectedTab)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.isVersion2 = data?.title?.includes('V2');

          this.selectedFundRaiseProcess = data;
          this.setFundRaiseProcess(this.selectedTab, data);
          this.checkEcfStatus();
          this.checkCampaign();
        },
        error: (err) => this.errorService.showError(err),
      });
  }

  changeTab(tabContent: TabContent) {
    tabContent.tab > this.selectedTab
      ? this.checkPreviousStatus(RaiseFundApplicationProcessModule.FundRaiseApplication, tabContent)
      : this.setSelectedTab(tabContent);
  }

  setSelectedTab(tabContent: TabContent) {
    this.selectedTab = tabContent.tab;
    this.selectedTabContent = tabContent;
    this.getApplicationProgress();
  }

  // Due to some gatekeeping logic relies on the previous tab status
  // this method is used to store the fund raise process for each tab
  // in future, get rid of `selectedFundRaiseProcess` and use `fundRaiseProcesses` instead
  setFundRaiseProcess(selectedTab: number, fundRaiseProcess: FundRaiseProcess) {
    this.fundRaiseProcesses[selectedTab] = fundRaiseProcess;
  }

  checkPreviousStatus(tab: RaiseFundApplicationProcessModule, tabContent: TabContent) {
    this.isLoading = true;

    this.raiseService.getApplicationProgress(this.applicationId, tab).subscribe({
      next: (data) => {
        // Temporary fix for PLT-1159
        // Utilise the `isEnabled` function without additional tabName condition in future
        if (this.isVersion2 && tabContent.tab == RaiseFundApplicationProcessModule.DueDiligence) {
          if (!tabContent?.isEnabled || tabContent?.isEnabled()) {
            this.setSelectedTab(tabContent);
            return;
          }
        }

        if (
          data.raiseFundApplicationProcessStatusType !==
            RaiseFundApplicationProcessStatusType.Approved &&
          !data.isEcfAppFormApproved
        ) {
          this.popupService.alert('raise.invalidStep');
          this.isLoading = false;
          return;
        }

        if (
          tab != RaiseFundApplicationProcessModule.OfferCreation &&
          tabContent.tab == RaiseFundApplicationProcessModule.CreateCampaign
        ) {
          this.checkPreviousStatus(RaiseFundApplicationProcessModule.OfferCreation, tabContent);
          return;
        }

        if (
          tabContent.tab != RaiseFundApplicationProcessModule.CreateCampaign ||
          (tabContent.tab == RaiseFundApplicationProcessModule.CreateCampaign &&
            this.campaignCreated)
        ) {
          this.setSelectedTab(tabContent);
          return;
        }

        this.router.navigate(['/raise/new-campaign/' + this.applicationId]);
      },
      error: (err) => this.errorService.showError(err),
    });
  }

  checkCampaign() {
    this.campaignCreated = false;

    this.raiseService
      .getApplicationProgress(this.applicationId, RaiseFundApplicationProcessModule.CreateCampaign)
      .subscribe({
        next: (data) => {
          if (data.items[0]?.campaignId) {
            this.campaignCreated = true;
          }
        },
        error: (err) => this.errorService.showError(err),
      });
  }

  checkEcfStatus() {
    if (this.selectedTabContent.tab === RaiseFundApplicationProcessModule.FundRaiseApplication) {
      const ecfItem: FundRaiseItem = this.selectedFundRaiseProcess.items.find(
        (item) => item.isItemRequiredApproval === true,
      );
      if (
        ecfItem.raiseFundApplicationProcessStatusType !==
        RaiseFundApplicationProcessStatusType.Approved
      ) {
        this.selectedFundRaiseProcess.items = this.selectedFundRaiseProcess.items.filter(
          (item) => item.isItemRequiredApproval === true,
        );
      }
    }
  }

  next(tab: RaiseFundApplicationProcessModule) {
    const currentTabIndex = this.tabContents.findIndex((tabContent) => tabContent.tab === tab);
    this.selectedTabContent = this.tabContents[currentTabIndex + 1];
    this.selectedTab = this.selectedTabContent.tab;
    this.getApplicationProgress();
  }
}
