import { Inject, Injectable, Optional } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';

@Injectable()
export class StatusCodeResponseService {
  private response: Response;

  constructor(
    @Optional()
    @Inject(RESPONSE)
    response: any,
  ) {
    this.response = response;
  }

  setStatus(code: number, message?: string) {
    if (!this.response) {
      return;
    }

    this.response.statusCode = code;

    if (message) {
      this.response.statusMessage = message;
    }
  }

  setNotFound(message = 'Not Found') {
    if (!this.response) {
      return;
    }

    this.response.statusCode = 404;
    this.response.statusMessage = message;
  }

  setRedirect(newUrl: string) {
    if (!this.response) {
      return;
    }

    this.response.statusCode = 301;
    this.response.location(newUrl);
  }

  setPermanentRedirect(newUrl: string) {
    if (!this.response) {
      return;
    }

    this.response.redirect(301, newUrl);
    this.response.finished = true;
    this.response.end();
  }

  setInternalServerError(message = 'Internal Server Error') {
    if (!this.response) {
      return;
    }

    this.response.statusCode = 500;
    this.response.statusMessage = message;
  }
}
