import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-partner-campaign-card',
  templateUrl: './empty-partner-campaign-card.component.html',
})
export class EmptyPartnerCampaignCardComponent {
  @Input() slug: string;
  @Input() partnerName: string;
}
