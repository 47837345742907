<div class="tw-py-8" *ngIf="campaign">
  <campaign-heading
    [campaign]="campaign"
    [isLogin]="isLogin"
    (saveCampaign)="handleSaveCampaign()"
  ></campaign-heading>

  <div class="md:tw-flex md:tw-gap-6 lg:tw-gap-12">
    <div class="tw-flex-1 tw-flex-shrink-0 tw-pb-5 md:tw-pb-0">
      <campaign-video
        [campaign]="campaign"
        [campaignInfo]="campaignInfo"
        [isCampaignEnded]="isCampaignEnded"
        [businessDirectoryInfo]="businessDirectoryInfo"
      ></campaign-video>
    </div>

    <div class="md:tw-w-72">
      <campaign-statistics
        class="tw-flex tw-h-full tw-w-full tw-flex-col"
        [isLogin]="isLogin"
        [campaign]="campaign"
        [isInvestNowLoading]="isInvestNowLoading"
        (investNow)="handleInvestNow()"
        (onInvestorCountLoaded)="handleInvestorCountLoaded($event)"
      ></campaign-statistics>
    </div>
  </div>
</div>
