import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ArticleTableContent } from '../../core/models/learn/article-table-content';
import { PrismicService } from '../../core/services/api/prismic.service';
import { ErrorService } from '../../core/services/error.service';

@Component({
  selector: 'app-learn',
  templateUrl: './learn.component.html',
  styleUrls: ['./learn.component.scss'],
})
export class LearnComponent implements OnInit {
  isLoading: boolean = true;
  investorList: ArticleTableContent[] = [];
  issuerList: ArticleTableContent[] = [];

  constructor(
    private prismicService: PrismicService,
    private errorService: ErrorService,
  ) {}

  ngOnInit(): void {
    this.getContents();
  }

  getContents() {
    this.prismicService
      .getHomepage()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          this.investorList = res.data.investor.filter((item) => !item.article.isBroken);
          this.issuerList = res.data.issuer.filter((item) => !item.article.isBroken);
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }
}
