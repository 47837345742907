<a
  tabindex="0"
  class="c-filter-item-label"
  [class.active]="isActive"
  (click)="handleClick()"
  data-t="filterClick"
>
  {{ menu.label | translate }}

  <button class="c-filter-item-label__cancel">
    <i class="fa fa-times"></i>
  </button>
</a>
