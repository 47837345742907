import { Component, Input } from '@angular/core';
import { SocialLinkType } from '../../../../../core/enum/social-link-type.enum';
import { BusinessDirectoryInfo } from '../../../../../core/models/business-directory/business-directory-info';

@Component({
  selector: 'app-business-overview',
  templateUrl: './business-overview.component.html',
  styleUrls: ['./business-overview.component.scss'],
})
export class BusinessOverviewComponent {
  @Input() businessDirectory: BusinessDirectoryInfo;
  isCollapsed = false;

  SocialLinkType: typeof SocialLinkType = SocialLinkType;

  constructor() {}
}
