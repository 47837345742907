import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Campaign } from '../../../../core/models/campaign/campaign';
import { DefaultLogo } from '../../../../core/services/constant';
import { CampaignInfo } from '../../../../core/models/campaign/campaign-info';

@Component({
  selector: 'campaign-heading',
  templateUrl: './campaign-heading.component.html',
  styleUrls: ['./campaign-heading.component.scss'],
})
export class CampaignHeadingComponent {
  defaultLogo = DefaultLogo;

  @Input() campaign: Campaign;
  @Input() campaignInfo: CampaignInfo;
  @Input() isLogin: boolean;
  @Output() saveCampaign = new EventEmitter();

  handleSaveCampaign() {
    this.saveCampaign.emit();
  }
}
