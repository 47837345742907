<div class="row text-black">
  <div class="col-lg-3 col-md-4" *ngIf="selectedQuestion">
    <div
      class="py-3 font-size-smaller"
      [class.text-primary]="selectedQuestion.id == question.id"
      *ngFor="let question of faqs"
    >
      <span class="cursor-pointer" (click)="selectQuestion(question)">
        {{ question.title }}
        <i *ngIf="selectedQuestion.id == question.id" class="pl-3 fa fa-angle-right"></i>
      </span>
    </div>
  </div>
  <div class="col-lg-9 col-md-8">
    <div class="answer">
      <div class="font-weight-extrabold question pb-3">
        {{ selectedQuestion.title }}
      </div>
      <div
        class="ck-content"
        *ngIf="selectedQuestion"
        [innerHTML]="selectedQuestion.description | trustSrc"
      ></div>
    </div>
  </div>
</div>
