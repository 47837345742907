import { Component, Input, OnInit } from '@angular/core';
import { Term } from '../../../../core/models/share/term';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss'],
})
export class FaqsComponent implements OnInit {
  @Input() faqs: Term[];
  selectedQuestion: Term;

  constructor() {}

  ngOnInit() {
    this.selectedQuestion = this.faqs[0];
  }

  selectQuestion(faq: Term) {
    this.selectedQuestion = faq;
  }
}
