import { Component } from '@angular/core';
import { NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-about-pitchin',
  templateUrl: './about-pitchin.component.html',
  styleUrls: ['./about-pitchin.component.scss'],
  providers: [
    {
      provide: NgbCarouselConfig,
      useValue: {
        interval: 0,
        showNavigationArrows: true,
      },
    },
  ],
})
export class AboutPitchinComponent {
  activeSelection: any;
  partners = [
    { imgId: '1', logoUrl: 'assets/img/brand/logo_mdec@2x.png' },
    { imgId: '2', logoUrl: 'assets/img/brand/logo_cradle@2x.png' },
    { imgId: '3', logoUrl: 'assets/img/brand/logo_mban@2x.png' },
    { imgId: '4', logoUrl: 'assets/img/brand/logo_cyberview@2x.png' },
    { imgId: '5', logoUrl: 'assets/img/brand/logo_magic@2x.png' },
    { imgId: '6', logoUrl: 'assets/img/brand/logo_mtdc@2x.png' },
    { imgId: '7', logoUrl: 'assets/img/brand/logo_mansec@2x.png' },
    { imgId: '8', logoUrl: 'assets/img/brand/logo_mahwengkwai@2x.png' },
    { imgId: '9', logoUrl: 'assets/img/brand/logo_limjoyan@2x.png' },
    { imgId: '10', logoUrl: 'assets/img/brand/logo_startuptoolkit@2x.png' },
    { imgId: '11', logoUrl: 'assets/img/brand/logo_workana@2x.png' },
  ];

  team = [
    {
      name: 'Sam Shafie',
      position: 'CEO and Co-Founder',
      imageUrl: 'assets/img/team/sam.png',
      bioDetail:
        'Sam co-founded pitchIN and is legally trained. He has served as a Deputy Public Prosecutor in the Attorney-General Chambers of Malaysia as well as in the Securities Commission of Malaysia (SC) and the Malaysian Communications and Multimedia Commission (MCMC). Sam is also the Founder of WatchTower and Friends (WTF), a tech startup accelerator. He has been named by Digital News Asia in their 2016-2017 Digerati 50.',
    },
    {
      name: 'Kashminder Singh',
      position: 'CSO and Co-Founder',
      imageUrl: 'assets/img/team/kash.png',
      bioDetail:
        'Kash is the co-founder and Chief Strategy Officer at pitchIN. He is also the co-founder of WatchTower and Friends, a startups accelerator. A life-long entrepreneur, he has built multiple successful businesses. Kash was previously the founder and Managing Editor of MOBILE WORLD, a leading tech publication that ran for 11 years. He also co-created GoMobile, a groundbreaking event that incorporated a large consumer expo, conference and an awards night. He is passionate about entrepreneur development and loves working on innovative financing options for startups and small businesses.',
    },
    // { name: "Kelly Lee", position: "Head of Partnerships", imageUrl: "assets/img/team/kelly.png" },
    // { name: "Cynthia Chiam", position: "Manager, pitchIN Academy", imageUrl: "assets/img/team/cynthia.png" },
    // { name: 'Chan Wei Chi', position: "COO", imageUrl: "assets/img/team/wei-chi.png", bioDetail: "" },
    // { name: 'Ken Lau', position: "Growth Lead", imageUrl: "assets/img/team/ken.png", bioDetail: "Ken's experience covers Corporate, Management Consultancy (Accenture), Startups (Zalora, MYSALE, MaGIC). <br><br>His experience in the startup industry includes setting up companies from ground up and leading them to tens of millions of revenue, managing operations of up to 100 staff members across multiple business units. At MaGIC, he was the Acceleration Director, focusing on developing program content and mechanics for their award winning MaGIC/Global Accelerator Program. He focuses on early stage companies, bringing them from MVP to PMF with emphasis on unit economics. <br><br>Ken has also founded a seed stage adventure travel platform Adventoro. At the moment, he focuses on growth and unit economics for pitchIN." },
    // { name: "Rene", position: 'CTO', imageUrl: "assets/img/team/rene.png" },
    // { name: 'Saifuddin', position: 'Back-End Lead Developer', imageUrl: "assets/img/team/saifuddin.png", bioDetail: "Saifuddin specialises in iOS development, server side scripting (Ruby on Rails) and database management.<br><br>Right after graduation, he joined Brainstorm Technologies Sdn Bhd as an intern. A self-taught developer, he helped developed software solutions on numerous fields such as legal, media, academic and the services industries. At Brainstorm Technologies, he was also a trainer for their iOS Development Program, having trained multiple companies and universities in Malaysia and Indonesia.<br><br>Recently, his interest shifted to Crowdfunding in Malaysia and he joined Pitch Platforms Sdn Bhd, the leading Crowdfunding platform in Malaysia. At pitchIN, Saifuddin works on the development of pitchIN-reward, pitchIN-equity and assisting startups under the pitchIN umbrella.<br><br>Saifuddin is currently accompanying his wife for her PhD in Nashville, Tennessee." },
    // { name: "Jourdein", position: 'Front-End Developer', imageUrl: "assets/img/team/jourdein.png" },
    // { name: "Leesa Jaib", position: "Senior Marketing Executive", imageUrl: "assets/img/team/leesa.png" },
    // { name: "Brenda Hor", position: "Marketing Executive", imageUrl: "assets/img/team/brenda.png" },
    // { name: "Matt Lee", position: "ECF Head", imageUrl: "assets/img/team/matt.png" },
    // { name: "Nathan Chuah", position: "ECF Investment Lead", imageUrl: "assets/img/team/nathan.png" },
    // { name: "Chong Hon Mun", position: "ECF Investment Lead", imageUrl: "assets/img/team/hon-mun.png" },
    // { name: "Foo Xin Yi", position: "ECF Investment Lead", imageUrl: "assets/img/team/xin-yi.png" },
    // { name: "Syed Adam Al-Jufri", position: "ECF Operation Executive", imageUrl: "assets/img/team/adam.png" },
    // { name: "Aina Farzana", position: "Compliance Head", imageUrl: "assets/img/team/aina.png" },
    // { name: "I Vy Quah", position: "Legal and Compliance Executive", imageUrl: "assets/img/team/i-vy.png" },
    // { name: "Nurul Ain Syazwi", position: "Legal and Compliance Executive", imageUrl: "assets/img/team/ain.png "},
    // { name: 'Harvey Foo', position: "Business Development Associate", imageUrl: "assets/img/team/harvey.png", bioDetail: "Harvey is the business plan and pitch specialist of the pitchIN. He started his career in a cross-border private equity firm and worked in a public-listed company. Harvey served as the mentor in the SITEC Accelerator Program (SAP). He also regularly conducts workshops at UTAR on Entrepreneurship topics." },
    // { name: 'Chee Giap Huan', position: "Business Development Associate", imageUrl: "assets/img/team/chee-giap.png", bioDetail: "Chee Giap started his career as a computer scientist. After completing his computer science bachelor's degree at University of Science Malaysia in 2005 and working for Motorola, he decided to head home to join Sterling Circle Sdn Bhd in 2008. As the executive director, Chee Giap is instrumental in bringing up the company from its roots as a water filter trading business. Just by recognizing that healthcare is becoming more and more significant, Chee Giap developed innovative healthcare products that really launched the company to the next level and paved the way for a new cycle of corporate growth. <br><br>Under his leadership, Sterling Circle won the Golden Bull Award in 2010 and 2011. Asia Circle was also awarded the Asia Top Excellent Health Product in 2011. <br><br>In 2016, Chee Giap decided to use his experience and ventured into the corporate business world and founded Wealcaps End Bhd. Out of a passion for helping entrepreneurs that has potential to grow, Wealcaps has invested in or incubated 20 startups within the first year. A new found focus has helped Chee Giap aspire to help more entrepreneurs by nurturing them and helping them grow healthy startups." },
    // { name: 'Siti Afiqah', position: "ECF Investment Analyst", imageUrl: "assets/img/team/siti.png" },
    // { name: 'Raj Kissu', position: "Product Head", imageUrl: "assets/img/team/raj.png" },
    {
      name: 'Steven Wong',
      position: 'CTO',
      imageUrl: 'assets/img/team/steven-wong.png',
      bioDetail: '',
    },
    {
      name: 'Nisa Zulkifli',
      position: 'Head, ECF',
      imageUrl: 'assets/img/team/nisa-zulkifli.png',
      bioDetail: '',
    },
    {
      name: 'Faten Nabila',
      position: 'Head, Legal and Compliance',
      imageUrl: 'assets/img/team/faten.png',
      bioDetail:
        'Faten was admitted to the Malaysian Bar in 2013 as Advocate and Solicitor of Malaya. She has more than 10 years of experience working as in-house counsel in a variety of sectors, including the financial sector, solid waste management, education, and public transportation.',
    },
    {
      name: 'Hanif Mohamed Tamin',
      position: 'Head of Academy',
      imageUrl: 'assets/img/team/hanif.png',
      bioDetail:
        'University of East London, BA Hon(s) International Business; University of Waikato New Zealand, MBM; Nottingham Business School; PhD of Business studies (ECF research candidate) <br/><br/> Hanif is the Head of the Academy at pitchIN. His extensive involvement in government policy planning, SME development and Industry linkages spans over 15 years of experience serving SME Corp. Malaysia under the purview of MITI and KUSKOP. His portfolio includes being part of developing the SME Masterplan 2012 -2020, National SME Development Council (NSDC) and supporting the SME High Impact Programme (HIP). Areas of specialty include Access to financing, inclusive innovation, and the Programme for Enhancement of Strategic Industry & High Growth Enterprises (PRESTIGE) for (Drone Tech/ Rail/ Oil & Gas) sectors to enhance high-growth SMEs into home-grown champions. His last stint was monitoring and managing the Alternative Financing Unit, one of the first public-private partnership investment programs known as “SME Investment Partner” (SIP). In pitchIN, he is tasked to develop a strategic plan for pitchIN Academy, aiming to create a thriving business unit within the pitchIN Group that delivers excellent content for fundraising capacity-building programmes to users, issuers and investors. He aspires to expand the role of the Academy by liaising with other government agencies, state-level and new organisations as well as stakeholders to build effective collaboration initiatives that advance the vision of pitchIN in becoming a premier fintech, entrepreneurship and business academy that democratised financing.',
    },
  ];

  constructor(private modalService: NgbModal) {}

  openModal(content: any, index: number) {
    this.activeSelection = index + 1;
    const modalRef = this.modalService.open(content, {
      size: '80',
      centered: true,
      windowClass: 'window-modal',
    });
    modalRef.result.then(
      (result) => {},
      (reason) => (this.activeSelection = null),
    );
  }
}
