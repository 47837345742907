export class ShareMovement {
  investorName: string;
  investorEmail: string;
  investorContactNumber?: string;
  isCorpRep: boolean;
  tickerCode: string;
  netShareMovement: number;
  totalSharesBought: number;
  totalSharesSold: number;
  averagePriceBought: number;
  averagePriceSold: number;
  totalPriceBought: number;
  totalPriceSold: number;
  recentBalance: number;
  oldestBalance: number;
  shareholderType: ShareholderType;
  recentCreatedAt: string;
  oldestCreatedAt: string;
}

export enum ShareholderType {
  Existing,
  New,
  Exited,
  Undetermined,
}
