import { Component, OnInit } from '@angular/core';
import { whyInvestFaqLd, ldToList } from '../../../assets/static/json-ld';
import { featuredCompanies, statistics } from '../../../assets/static/stats';
import { CompanyStats } from '../../core/models/company/company-stats';
import { AuthService } from '../../core/services/auth/auth.service';
import { FaqAccordion } from '../../core/models/learn/faq-accordion';

@Component({
  selector: 'app-why-invest',
  templateUrl: './why-invest.component.html',
  styleUrls: ['./why-invest.component.scss'],
})
export class WhyInvestComponent implements OnInit {
  isLogin = false;

  faqLd = whyInvestFaqLd;
  pageFaqs: FaqAccordion[] = ldToList(this.faqLd);
  featuredCompanies: CompanyStats = featuredCompanies;
  faqList: Array<{ question: string; answer: string }>;
  stats = statistics;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.isLogin = this.authService.isAuthenticated();

    this.faqList = whyInvestFaqLd.mainEntity.map((item) => {
      return {
        question: item.name,
        answer: item.acceptedAnswer.text,
      };
    });
  }
}
