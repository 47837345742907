import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TwoFactorservice } from '../../../../core/services/auth/two-factor.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastService } from '../../../../core/services/toast.service';
import { Router } from '@angular/router';
import { AccountService } from '../../../../core/services/api/account.service';
import { BlockUiService } from '../../../../shared/services/blockUi/block-ui.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-enable-two-factor-auth',
  templateUrl: './enable-two-factor-auth.component.html',
})
export class EnableTwoFactorAuthComponent implements OnInit {
  secretKey: string;
  QrCodeSrc: string;
  form: UntypedFormGroup;
  showFormError: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly twoFactorService: TwoFactorservice,
    private readonly clipboard: Clipboard,
    private readonly toastService: ToastService,
    private readonly accountService: AccountService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly blockUiService: BlockUiService,
  ) {}

  ngOnInit(): void {
    this.blockUiService.open();
    this.buildForm();
    this.twoFactorService
      .getTwoFactorSecret()
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe({
        next: (data) => {
          this.secretKey = data.secret;
          this.QrCodeSrc = data.qrCode.image;
        },
        error: (err) => {
          if (err.error.code === 'ERR_2FA_ALREADY_SETUP') {
            this.router.navigate(['/profile/settings/security']);
          }
          console.error('failed to load QR code', err);
        },
      });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(8)]],
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      this.showFormError = true;
      return;
    }

    this.twoFactorService.verifyTwoFactor(this.form.value.otp).subscribe({
      next: (data) => {
        this.accountService.profile(true).subscribe();
        this.router.navigate(['/profile/settings/security/backup-codes'], {
          queryParams: {
            viewOnly: false,
          },
        });
      },
      error: (err) => {
        this.toastService.error('Failed to verify code');
        console.error('failed to verify code', err);
      },
    });
  }

  handleCopySecretKey() {
    this.clipboard.copy(this.secretKey);
    this.toastService.success('Copied to clipboard');
  }
}
