import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorService } from '../../../../../core/services/error.service';
import { PaymentType } from '../../../../../core/enum/payment-type.enum';
import { Payment } from '../../../../../core/models/payment/payment';
import { PaymentService } from '../../../../../core/services/api/payment.service';
import { AccountType } from '../../../../../core/enum/account-type.enum';
import { FpxBank } from '../../../../../core/models/payment/fpx-bank';
import { StorageService } from '../../../../../core/services/storage.service';
import { Role } from 'src/app/core/enum/role.enum';
import { DefaultEmailPattern } from '../../../../../core/services/constant';

@Component({
  selector: 'app-fpx-payment',
  templateUrl: './fpx-payment.component.html',
  styleUrls: ['./fpx-payment.component.scss'],
})
export class FpxPaymentComponent implements OnInit {
  @Input() paymentAmount: number;
  @Output() choosePayment = new EventEmitter<any>();

  form: UntypedFormGroup;
  showFormError: boolean = false;
  accountTypes: string[] = [];
  banks: FpxBank[];

  AccountType: typeof AccountType = AccountType;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private errorService: ErrorService,
    private paymentService: PaymentService,
    private storageService: StorageService,
  ) {}

  ngOnInit() {
    this.accountTypes =
      this.storageService.role == Role.IndividualInvestor
        ? [AccountType[AccountType.B2C]]
        : [AccountType[AccountType.B2B1]];
    this.buildForm();
  }

  getBankList() {
    this.paymentService.getBankList(this.form.value.businessModel).subscribe(
      (data) => {
        this.banks = data;
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  buildForm() {
    this.form = this.formBuilder.group({
      businessModel: [this.accountTypes[0], Validators.required],
      bankID: [null, Validators.required],
      emailAddress: [null, Validators.pattern(DefaultEmailPattern)],
    });

    this.getBankList();
  }

  submit() {
    if (this.form.value.isAcceptTerm == false)
      this.form.controls.isAcceptTerm.setErrors({ incorrect: true });

    if (this.form.invalid) {
      this.showFormError = true;
      return;
    }

    let paymentModel = {
      paymentType: PaymentType.MaybankFPX,
      emailAddress: this.form.value.emailAddress,
      businessModel: this.form.value.businessModel,
      bankID: this.form.value.bankID,
    } as Payment;

    this.choosePayment.emit(paymentModel);
  }
}
