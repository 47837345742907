import { Component, Input, OnInit } from '@angular/core';
import { BusinessesService } from 'src/app/core/services/api/mysec/businesses.service';
import { DATE_FORMATS } from 'src/app/core/services/constant';
import { finalize } from 'rxjs';
import { ShareMovement, ShareholderType } from '../../../../core/models/mysec/share-movement';
import { ShareMovementParams } from './share-movement-params';
import * as moment from 'moment';
import { TickerCode } from '../../../../core/models/mysec/ticker-code';

@Component({
  selector: 'app-share-movement-tracker',
  templateUrl: './share-movement-tracker.component.html',
})
export class ShareMovementTrackerComponent implements OnInit {
  @Input() tickerCodes: TickerCode[];
  displayColumns: string[] = [
    'InvestorName',
    'DateRange',
    'TickerCode',
    'PreviousHolding',
    'Movement',
    'CurrentBalance',
    'AveragePricePerShare',
    'ShareholderType',
  ];

  isLoading = true;
  total: number;
  totalShareholders: number;
  totalShares: number;

  params = new ShareMovementParams();

  tickerCodeList: { key: string; value: string }[] = [];
  selectedTickerCode = 'All';

  filterBy = [
    { value: 'Name', key: 'InvestorName' },
    { value: 'Email', key: 'Email' },
  ];
  selectedFilterBy = this.filterBy[0];

  dateFormat = DATE_FORMATS.defaultDate;
  maxDateRange = { amount: 30, unitOfTime: 'day' };

  shareMovement: ShareMovement[] = [];

  ShareholderType: typeof ShareholderType = ShareholderType;

  constructor(private readonly businessesService: BusinessesService) {}

  ngOnInit() {
    this.getShareMovement();
  }

  getShareMovement() {
    this.isLoading = true;

    this.businessesService
      .getShareMovement(this.params)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((data) => {
        this.shareMovement = data.data;
        this.total = data.total;
        this.initTickerCodes();
      });
  }

  handlePageChange() {
    this.getShareMovement();
  }

  filterByDate(dateRange: { dateFrom: string; dateTo: string }) {
    dateRange.dateFrom
      ? (this.params.createdAtFrom = moment(dateRange.dateFrom).format())
      : delete this.params.createdAtFrom;

    dateRange.dateTo
      ? (this.params.createdAtTo = moment(dateRange.dateTo).format())
      : delete this.params.createdAtTo;

    if (this.params.createdAtFrom && this.params.createdAtTo) {
      this.params.currentPage = 1;
      this.getShareMovement();
    }
  }

  initTickerCodes() {
    const tickerCodeList = this.tickerCodes.map((item) => {
      return {
        key: item.id.toString(),
        value: item.tickerCode.toUpperCase(),
      };
    });

    this.tickerCodeList = [
      {
        key: '',
        value: 'All',
      },
      ...tickerCodeList,
    ];
  }

  handleFilterTickerCode(tickerCode: string) {
    this.selectedTickerCode = tickerCode;

    const getTickerCode = this.tickerCodeList.find((t) => t.value === tickerCode);

    if (getTickerCode) {
      this.handleFilter('mySecShareIds', getTickerCode.key);
    }
  }

  handleFilterBy(filterBy: { key: string; value: string }) {
    this.selectedFilterBy = filterBy;
  }

  handleFilter(paramKey: string, value: string) {
    this.params[paramKey] = value;
    this.params.currentPage = 1;
    this.getShareMovement();
  }

  handleSearch(search: string) {
    this.params.filterBy = this.selectedFilterBy.key;
    this.params.filterString = search;
    this.getShareMovement();
  }

  getShareholderTypeStyle(type: ShareholderType) {
    switch (type) {
      case ShareholderType.Undetermined:
        return;
      case ShareholderType.Existing:
        return 'tw-text-[#0059FF]';
      case ShareholderType.Exited:
        return 'tw-text-[#FF0000]';
      case ShareholderType.New:
        return 'tw-text-[#18B666]';
      default:
        break;
    }
  }

  handleReset() {
    this.selectedTickerCode = 'All';
    this.handleFilter('mySecShareIds', '');
    this.selectedFilterBy = this.filterBy[0];
    this.params.filterString = '';

    this.getShareMovement();
  }
}
