import { Component, OnInit } from '@angular/core';
import { ErrorService } from '../../../core/services/error.service';
import { RaiseService } from '../../../core/services/api/raise.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CreateCampaign } from '../../../core/models/campaign/create-campaign';
import { FloatingMenuItem } from '../../../shared/models/floating-menu/floating-menu-item';
import { CampaignMenus } from '../../../core/services/constant';

@Component({
  selector: 'app-create-campaign-details',
  templateUrl: './create-campaign-details.component.html',
  styleUrls: ['./create-campaign-details.component.scss'],
})
export class CreateCampaignDetailsComponent implements OnInit {
  campaign: CreateCampaign;
  fundRaiseApplicationId: number;
  campaignId: number;
  campaignMenus: FloatingMenuItem[] = [];

  constructor(
    private errorService: ErrorService,
    private raiseService: RaiseService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.fundRaiseApplicationId = this.activatedRoute.snapshot.params.fundRaiseApplicationId;
    this.campaignId = this.activatedRoute.snapshot.params.campaignId;

    if (this.campaignId) this.getCampaign();
  }

  getCampaign() {
    this.raiseService.getCampaignDetails(this.campaignId, this.fundRaiseApplicationId).subscribe(
      (data) => {
        this.campaign = data;
        if (this.campaign.campaignInfo) {
          CampaignMenus.forEach((menu) => {
            if (this.campaign.campaignInfo[menu.route]) this.campaignMenus.push(menu);
          });
        }
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  back() {
    this.location.back();
  }
}
