import { Injectable } from '@angular/core';
import { Wallet } from '../models/mysec/wallet';
import { WalletService } from './api/mysec/wallet.service';
import { BehaviorSubject, Observable, finalize } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PstxAccountService {
  isFetchingWallet: boolean = false;
  isWalletFound: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  wallet: BehaviorSubject<Wallet> = new BehaviorSubject<Wallet>(null);

  constructor(private walletService: WalletService) {
    this.getWalletBalance();
  }

  resetState() {
    this.setIsWalletFound(true);
    this.setWallet(null);
  }

  getIsWalletFound(): Observable<boolean> {
    return this.isWalletFound.asObservable();
  }

  setIsWalletFound(isFound: boolean) {
    this.isWalletFound.next(isFound);
  }

  getWallet(): Observable<any> {
    if (this.wallet.getValue() == null && !this.isFetchingWallet) {
      this.getWalletBalance();
    }

    return this.wallet.asObservable();
  }

  setWallet(wallet: Wallet) {
    this.wallet.next(wallet);
  }

  private getWalletBalance() {
    if (this.isFetchingWallet) return;

    this.isFetchingWallet = true;
    this.walletService
      .getWallet()
      .pipe(finalize(() => (this.isFetchingWallet = false)))
      .subscribe({
        next: (data) => {
          this.setWallet(data);
        },
        error: (err) => {
          if (err.error && err.error.code == 'ERR_WALLET_NOT_FOUND') {
            this.setIsWalletFound(false);
          } else {
            console.error('fail to get wallet -', err);
          }
        },
      });
  }
}
