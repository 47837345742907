import { Component, Input } from '@angular/core';
import { Partner } from '../../../core/models/partnership/partner';
import { DefaultLogo } from '../../../core/services/constant';

@Component({
  selector: 'app-partner-card',
  templateUrl: './partner-card.component.html',
})
export class PartnerCardComponent {
  @Input() partner: Partner;
  defaultLogo = DefaultLogo;
}
