<swiper
  #swiper
  [config]="config"
  class="testimonial-partner-swiper"
  [slidesPerView]="1"
  [spaceBetween]="10"
  [pagination]="{ clickable: true }"
  (slideChange)="onSlideChange($event)"
>
  <ng-template swiperSlide *ngFor="let testimonial of testimonials; let i = index">
    <div
      class="tw-m-4 tw-mb-12 tw-flex tw-flex-col tw-items-center tw-gap-4 tw-rounded-lg tw-p-4 tw-shadow-[0px_5px_5px_0px_#00000040] lg:tw-max-w-96"
    >
      <img
        [src]="'../../../../assets/img/partners/' + testimonial.imgSrc"
        [alt]="testimonial.name"
        class="rounded"
      />
      <div class="tw-flex tw-flex-col tw-items-center">
        <h5 class="!tw-m-0 tw-font-body">{{ testimonial.name }}</h5>
        <p class="tw-m-0 tw-font-semibold tw-text-primary">{{ testimonial.title }}</p>
      </div>
      <div>
        <ng-container *ngFor="let quote of testimonial.quotes">
          <p class="tw-z-50 tw-mt-2 tw-text-center tw-text-base tw-text-black">{{ quote }}</p>
        </ng-container>
      </div>
    </div>
  </ng-template>
</swiper>
