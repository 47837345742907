import { FinancialOverviewModel } from './financial-overview.model';

export class FinancialOverview {
  id: number;
  year: string;
  revenue: number;
  grossProfit: number;
  netProfit: number;
  note: string;
  period: string;

  constructor(model: FinancialOverviewModel) {
    this.id = model.id;
    this.year = model.year;
    this.revenue = model.revenue;
    this.grossProfit = model.grossProfit;
    this.netProfit = model.netProfit;
    this.note = model.note;
    this.period = model.reportingPeriod;
  }
}
