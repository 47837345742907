<div class="row" [class]="parentClass">
  <div *ngIf="showLabel" class="col-lg-2 col-md-12 pr-lg-0 d-flex align-items-center">
    <div class="text-black font-size-larger font-weight-extrabold pl-lg-4 mb-1 mb-lg-0">
      {{ 'common.dateRange' | translate }}
    </div>
  </div>
  <div class="col-lg-{{ col }} col-md-5 date-input-wrapper">
    <div class="input-field date-input">
      <input
        class="form-control"
        [matDatepicker]="startDate"
        [(ngModel)]="dateFrom"
        [min]="minFrom"
        [max]="today"
        name="startDate"
        [placeholder]="'common.startDate' | translate"
        (dateChange)="filterDate(1)"
        [readonly]="readonly"
        (click)="readonly ? startDate.open() : false"
      />
      <mat-datepicker-toggle matSuffix [for]="startDate" disableRipple>
        <div class="pitchin-icon pitchin-icon-calendar" matDatepickerToggleIcon></div>
      </mat-datepicker-toggle>
      <mat-datepicker #startDate></mat-datepicker>
    </div>
  </div>
  <div class="date-input-divider tw-flex tw-flex-[0_0_10px] tw-items-center tw-px-0 tw-text-center">
    <span class="tw-inline-block tw-w-full">-</span>
  </div>
  <div class="col-lg-{{ col }} col-md-5 date-input-wrapper">
    <div class="input-field date-input">
      <input
        class="form-control"
        [matDatepicker]="endDate"
        [(ngModel)]="dateTo"
        [max]="maxTo"
        name="endDate"
        [placeholder]="'common.endDate' | translate"
        (dateChange)="filterDate(2)"
        [readonly]="readonly"
        (click)="readonly ? endDate.open() : false"
      />
      <mat-datepicker-toggle matSuffix [for]="endDate" disableRipple>
        <div class="pitchin-icon pitchin-icon-calendar" matDatepickerToggleIcon></div>
      </mat-datepicker-toggle>
      <mat-datepicker #endDate></mat-datepicker>
    </div>
  </div>
  <div class="col-lg-1 col-md-12 mt-1 mt-lg-0" *ngIf="showClear">
    <div class="btn btn-primary h-100 d-inline-flex align-items-center" (click)="clearDates()">
      {{ 'common.clear' | translate }}
    </div>
  </div>
</div>
