<div>
  <div class="c-filter tw-flex tw-w-full tw-flex-wrap tw-items-center lg:tw-flex-nowrap">
    <!-- Search input -->
    <div class="c-filter__section tw-w-full tw-pb-3 lg:tw-w-auto lg:tw-flex-auto lg:tw-pb-0">
      <div class="tw-relative">
        <input
          class="form-control tw-pl-12"
          placeholder="{{ 'campaign.searchPlaceholder' | translate }}"
          [(ngModel)]="params.filterString"
          (keydown.enter)="submit(params, false)"
          name="search"
          required
        />
        <div class="pitchin-icon pitchin-icon-search tw-absolute tw-left-3 tw-top-3"></div>
      </div>
    </div>

    <div class="c-filter__section tw-flex tw-items-center lg:tw-pl-3">
      <!-- Sorting -->
      <div class="dropdown" ngbDropdown>
        <div
          class="d-flex align-items-center cursor-pointer tw-select-none"
          id="filterButton"
          aria-controls="menu"
          ngbDropdownToggle
        >
          <span class="pr-1 tw-font-semibold">{{ 'common.sortBy' | translate }}:</span>
          {{ 'campaign.' + InvestmentFilterType[params.orderBy] | translate }}
          <i class="fa fa-caret-down tw-ml-2 tw-opacity-50"></i>
        </div>
        <div class="dropdown-menu" ngbDropdownMenu role="menu" aria-labelledby="filterButton">
          <div
            role="menuitem"
            *ngFor="let type of InvestmentFilterType | enumToArray"
            class="dropdown-item font-weight-bold font-size-regular cursor-pointer"
            (click)="selectFilterType(type.key)"
            data-t="sortClick"
          >
            {{ 'campaign.' + type.value | translate }}
          </div>
        </div>
      </div>

      <div class="c-filter__divider"></div>

      <!-- Toggle filter starts -->
      <div class="toggle-filter">
        <app-campaign-filters-toggle
          [filterMenus]="filtersMenus"
          [filterTags]="filterTags"
          [selectedMenu]="selectedFilterMenu"
          [selectedMenuIndex]="selectedFilterMenuIndex"
          [portalName]="'#c-filter__dropdown-section'"
          (onSelectedMenuChange)="handleSelectedMenuChange($event)"
          (onFiltersChange)="handleFiltersChange($event)"
        >
        </app-campaign-filters-toggle>

        <!-- Tax eligible -->
        <!-- <div class="toggle-filter__section">
                    <div class="filter-label">
                        {{ "campaign.taxEligibilities" | translate }}
                    </div>

                    <div class="filter-selections">
                        <div class="filter-selection" [class.active]="
                                params.landingPageAngelTaxIncentiveEligibility ==
                                TaxIncentivesEligibility.Yes
                            " (click)="enableATI(TaxIncentivesEligibility.Yes)">
                            {{ "campaign.ati" | translate }}
                        </div>
                        <div class="filter-selection" [class.active]="
                                params.landingPageAngelTaxIncentiveEligibility ==
                                TaxIncentivesEligibility.No
                            " (click)="enableATI(TaxIncentivesEligibility.No)">
                            {{ "common.none" | translate }}
                        </div>
                    </div>
                </div> -->

        <!-- Business types -->
        <!-- <div class="toggle-filter__section">
                    <div class="filter-label">
                        {{ "campaign.businessType" | translate }}
                    </div>
                    <div class="filter-selections">
                        <div class="filter-selection" *ngFor="let type of BusinessType | enumToArray"
                            [class.active]="selectedBusinessTypes.includes(type.key)"
                            (click)="selectBusinessType(type.key)">
                            {{ "campaign." + type.value | translate }}
                        </div>
                    </div>
                </div> -->
      </div>
    </div>
  </div>

  <!-- Where should be teleported to -->
  <div id="c-filter__dropdown-section" class="c-filter__dropdown-section"></div>

  <!-- Sectors -->
  <!-- <div class="toggle-filter__section">
        <div class="filter-label">{{ "campaign.sectors" | translate }}</div>
        <div class="filter-selections">
            <app-select-search-filter
                class="w-100"
                [(list)]="sectors"
                [(selectedList)]="params.selectedSectors"
                [placeholder]="'company.searchSectors'"
                (goSearch)="getSectors($event)"
            >
            </app-select-search-filter>
        </div>
    </div> -->
</div>

<!-- Tags -->
<div class="tw-mt-1" *ngIf="filterTags.length > 0">
  <ul class="filter-tag">
    <li class="filter-tag__item" (click)="handleRemoveTag(tag)" *ngFor="let tag of filterTags">
      {{ tag.label }}
      <i class="fa fa-times"></i>
    </li>
  </ul>
</div>
