<section class="section section-shaped">
  <div class="container">
    <div class="text-primary text-underline cursor-pointer back-button h5" (click)="back()">
      {{ 'common.back' | translate }}
    </div>
    <div class="title text-center mb-5">{{ 'campaign.campaignDetails' | translate }}</div>
    <ng-container *ngIf="campaign">
      <app-campaign-info
        [campaignInfo]="campaign.campaignInfo"
        [tabs]="campaignMenus"
      ></app-campaign-info>
      <div
        class="section"
        *ngIf="campaign.campaignFAQs && campaign.campaignFAQs.length > 0"
        id="faq"
      >
        <div class="row">
          <div class="col-12 d-flex justify-content-center">
            <div class="title-red">
              {{ 'campaign.faq' | translate }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-faqs [faqs]="campaign.campaignFAQs"></app-faqs>
          </div>
        </div>
      </div>
      <div
        class="section"
        *ngIf="campaign.campaignRisks && campaign.campaignRisks.length > 0"
        id="risks"
      >
        <div class="row">
          <div class="col-12 d-flex justify-content-center">
            <div class="title-red">
              {{ 'campaign.risks' | translate }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-risks [risks]="campaign.campaignRisks"></app-risks>
          </div>
        </div>
      </div>
    </ng-container>
    <agmo-loading *ngIf="!campaign"></agmo-loading>
  </div>
</section>
