import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[noSpace]',
})
export class NoSpaceDirective {
  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const pastedText = event.clipboardData?.getData('text/plain');

    if (pastedText) {
      const modifiedText = pastedText.replace(/\s/g, '');
      const input = event.target as HTMLInputElement;
      const currentCursorPosition = input.selectionStart || 0;
      const newValue =
        input.value.substring(0, currentCursorPosition) +
        modifiedText +
        input.value.substring(input.selectionEnd || currentCursorPosition);
      input.value = newValue;
      event.preventDefault();
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === ' ') {
      event.preventDefault();
    }
  }
}
