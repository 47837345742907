import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorService } from '../../../../core/services/error.service';
import { Receipt } from '../../../../core/models/payment/receipt';
import { PaymentService } from '../../../../core/services/api/payment.service';
import { MyApplicationFilterService } from '../../my-application/my-application-filter.service';
import { RaiseFundApplicationProcessModule } from '../../../../core/enum/raise-fund-application-process-module.enum';

@Component({
  selector: 'app-raise-fund-receipt',
  templateUrl: './raise-fund-receipt.component.html',
  styleUrls: ['./raise-fund-receipt.component.scss'],
})
export class RaiseFundReceiptComponent implements OnInit {
  transactionId: string;
  receipt: Receipt;

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorService: ErrorService,
    private paymentService: PaymentService,
    private myApplicationFilterService: MyApplicationFilterService,
  ) {}

  ngOnInit() {
    this.transactionId = this.activatedRoute.snapshot.params.transactionId;
    this.getTransactionDetail();
  }

  getTransactionDetail() {
    this.paymentService.getRaiseFundTransactionDetail(this.transactionId).subscribe(
      (data) => {
        this.receipt = data;

        this.myApplicationFilterService.filter = {
          applicationId: this.receipt.referenceId ? parseInt(this.receipt.referenceId) : null,
          selectedTab: RaiseFundApplicationProcessModule.FundRaiseApplication,
        };
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  print() {
    window.print();
  }
}
