<ng-container>
  <div class="row justify-content-center">
    <div class="col-lg-8 d-flex pt-5 pb-3 title-panel border-bottom">
      <h2 class="font-weight-extrabold align-self-center mb-0">
        {{ 'investment.internetBanking' | translate }}
      </h2>
      <div class="fpx-logo pitchin-icon-fpx"></div>
    </div>
  </div>
  <div class="row py-5 invest-panel justify-content-center">
    <div class="col-lg-8">
      <div class="info mb-4 d-flex">
        <div class="w-75">{{ 'investment.totalCost' | translate }}</div>
        <div class="w-25 text-right">{{ paymentAmount | numberSuffix }}</div>
      </div>
      <form [formGroup]="form" [class.show-form-error]="showFormError" (ngSubmit)="submit()">
        <div class="input-panel">
          <div class="input-label">{{ 'investment.accountType' | translate }}</div>
          <div class="input-field">
            <mat-select
              placeholder="{{ 'investment.selectAccountType' | translate }}"
              class="form-control"
              formControlName="businessModel"
              (selectionChange)="getBankList()"
            >
              <mat-option *ngFor="let type of accountTypes" [value]="type">
                {{ type }}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <div class="input-panel">
          <div class="input-label">{{ 'investment.bank' | translate }}</div>
          <div class="input-field">
            <mat-select
              placeholder="{{ 'investment.selectBank' | translate }}"
              class="form-control"
              formControlName="bankID"
            >
              <mat-option
                *ngFor="let bank of banks"
                [value]="bank.bankId"
                [disabled]="!bank.status"
              >
                {{ bank.bankName }}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <div class="input-panel">
          <div class="input-label">
            {{ 'investment.yourEmail' | translate }} ({{ 'investment.optional' | translate }})
          </div>
          <div class="input-field">
            <input
              type="text"
              class="form-control"
              [placeholder]="'common.emailPlaceholder' | translate"
              formControlName="emailAddress"
            />
            <div
              class="error"
              *ngIf="
                showFormError &&
                form.controls.emailAddress.errors &&
                form.controls.emailAddress.errors.pattern
              "
            >
              {{ 'signUp.emailFormatIncorrect' | translate }}
            </div>
          </div>
        </div>
        <div
          class="term-container text-black font-size-smaller"
          [innerHTML]="'investment.fpxTerms' | translate"
        ></div>
        <div class="input-panel mt-4 text-center">
          <button class="btn btn-primary font-size-larger" type="submit">
            {{ 'investment.payNow' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-container>
