import {
  Directive,
  ElementRef,
  OnInit,
  Renderer2,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Event } from '@angular/router';

@Directive({
  selector: '[agmoUploader]',
})
export class UploaderDirective implements OnInit {
  @Input() accept: string = '*';
  @Input() multiple: boolean = false;
  @Output() selectFiles: EventEmitter<File[]> = new EventEmitter<File[]>();

  fileInput: any;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.appendFileInput();
  }

  appendFileInput(): void {
    this.fileInput = this.renderer.createElement('input');
    this.renderer.setAttribute(this.fileInput, 'type', 'file');
    this.renderer.setStyle(this.fileInput, 'display', 'none');
    this.renderer.appendChild(this.element.nativeElement, this.fileInput);

    if (this.multiple) this.renderer.setAttribute(this.fileInput, 'multiple', 'true');

    if (this.accept) this.renderer.setAttribute(this.fileInput, 'accept', this.accept);

    this.renderer.listen(this.element.nativeElement, 'click', () => {
      this.fileInput.click();
    });

    this.renderer.listen(this.fileInput, 'change', (e) => {
      this.onSelectFiles(e.target.files);
    });

    // drag and drop support

    this.renderer.listen(this.element.nativeElement, 'dragover', (e) => {
      e.preventDefault();
    });

    this.renderer.listen(this.element.nativeElement, 'drop', (e) => {
      e.preventDefault();
      this.onSelectFiles(e.dataTransfer.files);
    });
  }

  onSelectFiles(files: any) {
    if (!files) return;

    if (this.multiple) {
      this.selectFiles.emit(Array.from(files));
    } else {
      this.selectFiles.emit(files[0]);
    }

    this.fileInput.value = '';
  }
}
