import { AuthService } from './../../../core/services/auth/auth.service';
import { AccountService } from './../../../core/services/api/account.service';
import { BlockUiService } from './../../../shared/services/blockUi/block-ui.service';
import { ErrorService } from '../../../core/services/error.service';
import { BusinessDirectory } from './../../../core/models/business-directory/business-directory';
import { Component, OnInit } from '@angular/core';
import { BusinessDirectoryService } from '../../../core/services/api/business-directory.service';
import { finalize } from 'rxjs/operators';
import { DefaultLogo } from '../../../core/services/constant';

@Component({
  selector: 'app-business-directory',
  templateUrl: './business-directory.component.html',
  styleUrls: ['./business-directory.component.scss'],
})
export class BusinessDirectoryComponent implements OnInit {
  businessDirectories: BusinessDirectory[];
  params = {
    currentPage: 1,
    take: 30,
  };
  isLoading: boolean = false;
  total: number = 0;
  showNotify: boolean = false;
  defaultLogo = DefaultLogo;

  constructor(
    private businessDirectoryService: BusinessDirectoryService,
    private errorService: ErrorService,
    private blockUiService: BlockUiService,
    private accountService: AccountService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    if (this.authService.isAuthenticated()) this.showNotify = true;

    this.getList(true);
  }

  getList(reload?: boolean) {
    if (reload) this.params.currentPage = 1;

    this.isLoading = true;
    this.businessDirectories = [];

    this.businessDirectoryService
      .getBusinessList(this.params.currentPage, this.params.take)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.businessDirectories = data.data;
          this.total = data.total;
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  notifyMe(company: BusinessDirectory) {
    this.blockUiService.open();

    this.accountService
      .notifyBusiness(company.id)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        () => {
          company.isSubscribed = !company.isSubscribed;
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }
}
