<div class="row mb-5" *ngIf="isCreator && !isAddNews">
  <div class="col-12 d-flex justify-content-end">
    <div class="btn btn-green" (click)="addNews()">
      {{ (rssUrl ? 'campaign.updateNews' : 'campaign.addNews') | translate }}
    </div>
  </div>
</div>
<ng-container *ngIf="!isAddNews">
  <div class="row">
    <div class="col-md-4 pb-3" *ngFor="let feed of rssFeedItems">
      <div class="news" (click)="openUrl(feed.link)">
        <div class="banner-upload">
          <div class="upload-container">
            <img class="banner-image" [alt]="feed.title" [src]="feed.thumbnail || defaultBanner" />
          </div>
        </div>
        <div class="p-3">
          <div class="news-title text-black font-weight-extrabold line-clamp">
            {{ feed.title }}
          </div>
          <div class="pt-3 news-description">
            {{ feed.pubDate | amDateFormat: 'ddd, DD MMM YYYY' }}
          </div>
          <div class="pt-3 news-description line-clamp" [innerHTML]="feed.description"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 d-flex p-0" *ngIf="total - rssFeedItems.length > 0">
    <div class="view-more" (click)="loadMore()">
      {{ 'common.loadMore' | translate }}
    </div>
  </div>
  <div class="col-12" *ngIf="isLoading">
    <agmo-loading></agmo-loading>
  </div>
  <div
    class="col-12 pb-5 text-center"
    *ngIf="!isLoading && (!rssFeedItems || rssFeedItems.length == 0)"
  >
    <app-empty-state></app-empty-state>
  </div>
</ng-container>
<ng-container *ngIf="isCreator && isAddNews">
  <div class="row mb-4">
    <div class="col-12">
      <h4 class="font-weight-extrabold">
        {{ (rssUrl ? 'campaign.updateNews' : 'campaign.addNews') | translate }}
      </h4>
    </div>
  </div>
  <form
    (ngSubmit)="submit()"
    [formGroup]="form"
    [class.show-form-error]="showFormError"
    *ngIf="form"
  >
    <div class="row">
      <div class="col-lg-12 input-panel">
        <div class="input-label">{{ 'campaign.rssFeedUrl' | translate }}</div>
        <div class="input-field">
          <input
            class="form-control"
            formControlName="rssUrl"
            type="text"
            [placeholder]="'campaign.rssFeedUrlPlaceholder' | translate"
          />
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-6 input-panel d-flex">
        <div class="btn-cancel" (click)="cancel()">{{ 'common.cancel' | translate }}</div>
      </div>
      <div class="col-6 input-panel text-right">
        <button class="btn btn-green" type="submit">
          {{ 'common.submit' | translate }}
        </button>
      </div>
    </div>
  </form>
</ng-container>
