// Reference: http://diveintohtml5.info/detect.html#storage
// Use try..catch to resolve the bugs that happen to older browser when cookies disabled
import { Role } from './../../core/enum/role.enum';
import { LoginProfile } from './../models/account/login-profile';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { MarketSignal } from '../models/mysec/market-signal';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  get userId(): string {
    let storage;

    try {
      storage = localStorage?.getItem('userId') || '';
    } catch (e) {
      storage = '';
    }
    return storage;
  }

  set userId(userId: string) {
    try {
      localStorage.setItem('userId', userId || '');
    } catch (e) {
      return;
    }
  }

  get role(): Role {
    let storage;

    try {
      storage = localStorage?.getItem('role') ? Role[localStorage.getItem('role')] : null;
    } catch (e) {
      storage = null;
    }
    return storage;
  }

  set role(role: Role) {
    try {
      localStorage.setItem('role', Role[role] || '');
    } catch (e) {
      return;
    }
  }

  get deviceId(): string {
    let storage;

    try {
      storage = localStorage?.getItem('deviceId') || '';
    } catch (e) {
      storage = '';
    }
    return storage;
  }

  set deviceId(deviceId: string) {
    try {
      localStorage.setItem('deviceId', deviceId || '');
    } catch (e) {
      return;
    }
  }

  get registrationId(): string {
    let storage;

    try {
      storage = localStorage?.getItem('registrationId') || '';
    } catch (e) {
      storage = '';
    }
    return storage;
  }

  set registrationId(registrationId: string) {
    try {
      localStorage.setItem('registrationId', registrationId || '');
    } catch (e) {
      return;
    }
  }

  get isRememberMe(): boolean {
    let storage;

    try {
      storage = localStorage.getItem('isRememberMe') == 'true';
    } catch (e) {
      storage = false;
    }
    return storage;
  }

  set isRememberMe(isRememberMe: boolean) {
    try {
      localStorage.setItem('isRememberMe', isRememberMe ? isRememberMe.toString() : '');
    } catch (e) {
      return;
    }
  }

  get email(): string {
    let storage;

    try {
      storage = localStorage?.getItem('email') || null;
    } catch (e) {
      storage = null;
    }
    return storage;
  }

  set email(email: string) {
    try {
      localStorage.setItem('email', email || '');
    } catch (e) {
      return;
    }
  }

  get fullName(): string {
    let storage;

    try {
      storage = localStorage?.getItem('fullName') || null;
    } catch (e) {
      storage = null;
    }
    return storage;
  }

  set fullName(fullName: string) {
    try {
      localStorage.setItem('fullName', fullName || '');
    } catch (e) {
      return;
    }
  }

  get tokenExpiry(): Moment {
    let storage;

    try {
      const tokenExpiry = localStorage.getItem('tokenExpiry');
      storage = tokenExpiry ? moment(tokenExpiry) : null;
    } catch (e) {
      storage = null;
    }
    return storage;
  }

  set tokenExpiry(tokenExpiry: Moment) {
    try {
      localStorage.setItem('tokenExpiry', tokenExpiry ? tokenExpiry.toISOString() : '');
    } catch (e) {
      return;
    }
  }

  get timeZone(): string {
    let storage;

    try {
      storage = localStorage.getItem('timeZone') || null;
    } catch (e) {
      storage = null;
    }
    return storage;
  }

  set timeZone(timeZone: string) {
    try {
      localStorage.setItem('timeZone', timeZone || '');
    } catch (e) {
      return;
    }
  }

  get lastViewDateTime(): string {
    let storage;

    try {
      storage = localStorage?.getItem('lastViewDateTime') || null;
    } catch (e) {
      storage = null;
    }
    return storage;
  }

  set lastViewDateTime(lastViewDateTime: string) {
    try {
      localStorage.setItem('lastViewDateTime', lastViewDateTime || '');
    } catch (e) {
      return;
    }
  }

  get announcementBarLastClosedDate(): string {
    let storage;

    try {
      storage = localStorage?.getItem('announcementBarLastClosedDate') || null;
    } catch (e) {
      storage = null;
    }
    return storage;
  }

  set announcementBarLastClosedDate(closedDateTime: string) {
    try {
      localStorage.setItem('announcementBarLastClosedDate', closedDateTime || null);
    } catch (e) {
      return;
    }
  }

  get announcementBarLastModifiedAt(): string {
    let storage;

    try {
      storage = localStorage?.getItem('announcementBarLastModifiedAt') || null;
    } catch (e) {
      storage = null;
    }
    return storage;
  }

  set announcementBarLastModifiedAt(annModifiedAt: string) {
    try {
      localStorage.setItem('announcementBarLastModifiedAt', annModifiedAt || null);
    } catch (e) {
      return;
    }
  }

  getMarketSignal(tickerCode: string): MarketSignal {
    if (!tickerCode) return null;

    let datas: MarketSignal[] = localStorage.getItem('marketSignals')
      ? JSON.parse(localStorage.getItem('marketSignals'))
      : [];
    if (datas) {
      return datas.find((signal) => signal.tickerCode === tickerCode);
    }
    return null;
  }

  setMarketSignal(marketSignal: MarketSignal) {
    let datas: MarketSignal[] = localStorage.getItem('marketSignals')
      ? JSON.parse(localStorage.getItem('marketSignals'))
      : [];
    datas.push(marketSignal);
    localStorage.setItem('marketSignals', JSON.stringify(datas));
  }

  setFromLogin(input: LoginProfile): void {
    this.tokenExpiry = moment(input.accessTokenExpiredAt);
    this.fullName = input.fullName;
    this.role = input.role;
    this.userId = input.id;
    this.email = input.email;
  }

  clear(): void {
    this.tokenExpiry = null;
    this.role = null;
    this.fullName = null;
    this.userId = null;
    this.timeZone = null;
    localStorage.removeItem('marketSignals');
  }
}
