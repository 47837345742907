import { TradingType } from '../../enum/mysec/trading-type.enum';
import { OrderModel } from './order.model';

export class Order {
  id: number;
  volume: number;
  price: number;
  isOrdered: boolean;
  action: TradingType;
  percent?: number;

  constructor(model: OrderModel) {
    this.id = model.id;
    this.volume = model.totalQuantity;
    this.price = model.price;
    this.isOrdered = model.isOrdered;
    this.action = model.action;
    this.percent = 0;
  }
}
