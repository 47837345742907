import { Component, Input } from '@angular/core';
import { BusinessDirectoryInfo } from '../../../core/models/business-directory/business-directory-info';

@Component({
  selector: 'campaign-business-info',
  templateUrl: './campaign-business-info.component.html',
  styleUrls: ['./campaign-business-info.component.scss'],
})
export class CampaignBusinessInfoComponent {
  @Input() businessInfo: BusinessDirectoryInfo;
}
