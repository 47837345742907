import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { InvestmentSortType } from '../../../../core/enum/investment-sort-type.enum';
import { CampaignFiltersMenu } from '../../../../core/models/campaign/campaign-filters-menu';
import { CampaignFiltersTag } from '../../../../core/models/campaign/campaign-filters-tag';
import { SectorList } from '../../../../core/models/company/sector-list';
import { QueryParamsService } from '../../../../core/services/query-params.service';
import { EnumToArrayPipe } from '../../../../shared/pipes/enum-to-array.pipe';
import { BusinessesFilter } from '../businesses-filter';
import { BusinessesFilterService } from '../businesses-filter.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { InvestorShareMarketStatusFilter } from '../../../../core/enum/investor-share-market-status.enum';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-businesses-filters',
  templateUrl: './businesses-filters.component.html',
  styleUrls: ['./businesses-filters.component.scss'],
  providers: [EnumToArrayPipe],
})
export class BusinessesFiltersComponent implements OnInit {
  @Input() params!: BusinessesFilter;
  @Output() paramsChange = new EventEmitter<BusinessesFilter>();
  sectors: SectorList;
  isLogin = false;

  InvestmentSortType: typeof InvestmentSortType = InvestmentSortType;

  filtersMenus: CampaignFiltersMenu[] = [
    {
      key: 'selectedSectors',
      label: 'Sectors',
      isArray: true,
      isMultiSelect: true,
      children: [
        { key: 1, label: 'AdTech & Media' },
        { key: 8, label: 'Agriculture' },
        { key: 58, label: 'Consumer' },
        { key: 85, label: 'E-Commerce' },
        { key: 120, label: 'FinTech' },
        { key: 122, label: 'Food & Beverages' },
        { key: 141, label: 'Healthcare' },
        { key: 192, label: 'Manufacturing' },
        { key: 207, label: 'Mobile Application' },
        { key: 274, label: 'Services' },
        { key: 298, label: 'Technology' },
        { key: 248, label: 'PropTech' },
      ],
    },
  ];
  allFiltersMenus: CampaignFiltersMenu[] = this.filtersMenus.reduce((acc, menu) => {
    if (menu.key === 'more') {
      acc = [...acc, ...menu.childrenGroups];
    } else {
      acc.push(menu);
    }

    return acc;
  }, []);

  InvestorShareMarketStatusFilter = InvestorShareMarketStatusFilter;

  filterTags: Array<CampaignFiltersTag> = [];
  selectedFilterMenu: string;
  selectedFilterMenuIndex: number;

  constructor(
    private businessesFilterService: BusinessesFilterService,
    private queryParamsService: QueryParamsService,
    @Inject(PLATFORM_ID) private platform: Object,
    private authService: AuthService,
  ) {
    this.selectedFilterMenu = '';
    this.selectedFilterMenuIndex = -1;
  }

  ngOnInit(): void {
    this.filterTags = this.getFilterTags(this.params);
    this.queryFilters();
    this.isLogin = this.authService.isAuthenticated();
  }

  handleSearch(newValue: string) {
    this.params = {
      ...this.params,
      filterString: newValue,
    };
    this.submit(this.params, false);
  }

  submit(params: BusinessesFilter, reload?: boolean) {
    this.params = params;
    this.businessesFilterService.filter = params;
    setTimeout(() => {
      this.paramsChange.emit(params);
    }, 1);
  }

  // Toggle filters related
  // ------------------
  getFilterTags(params: any): Array<CampaignFiltersTag> {
    // generate `CampaignFiltersTag` based on the query string
    let tags = [];
    let filters = this.queryParamsService.filterProps(params, [
      'selectedSectors',
      'investorShareMarketStatus',
      'currentPage',
      'filterString',
      'orderBy',
      'take',
    ]);

    Object.keys(filters).forEach((key) => {
      switch (key) {
        case 'sectorIds':
          let sectorIds = filters[key];

          if (sectorIds && !Array.isArray(sectorIds)) {
            sectorIds = sectorIds.split(',');
          }

          if (!Array.isArray(sectorIds)) return;

          sectorIds.map((sectorId) => {
            const tag = this.generateFilterTag('selectedSectors', sectorId);
            if (tag) tags.push(tag);
          });
          break;

        default:
          const tag = this.generateFilterTag(key, filters[key]);
          if (tag) tags.push(tag);
      }
    });

    return tags;
  }

  getParentMenu(menuKey: string | number): CampaignFiltersMenu {
    return this.allFiltersMenus.find((f) => f.key == menuKey);
  }

  generateFilterTag(parentKey: string | number, childKey: string | number): CampaignFiltersTag {
    const menu: CampaignFiltersMenu = this.getParentMenu(parentKey);
    let child: CampaignFiltersMenu;

    if (menu?.children) {
      child = menu.children.find((m) => m.key == childKey);
    }

    return child
      ? {
          key: childKey,
          label: child.label,
          parentKey: parentKey,
          isArray: menu.isArray,
          isMultiSelect: menu.isMultiSelect,
        }
      : null;
  }

  handleSelectedMenuChange(selectedMenu: string) {
    this.selectedFilterMenu = selectedMenu;
    this.selectedFilterMenuIndex = this.filtersMenus.findIndex((m) => m.key === selectedMenu);
  }

  handleFiltersChange(tag: CampaignFiltersTag) {
    // Will emit this function on each click event
    // So when `tag` is `active` will create as new tag
    // Otherwise remove it
    if (tag.isActive) {
      // For `singleSelect` tag, will need to deselect the existing filters
      if (!tag.isMultiSelect) {
        this.removeFilter(tag, true);
      }
      this.filterTags.push(tag);
      this.queryFilters();
    } else {
      this.removeFilter(tag, !tag.isMultiSelect);
      this.queryFilters();
    }
  }

  handleRemoveTag(tag: CampaignFiltersTag) {
    this.removeFilter(tag, false);

    // Clear the query params related to the removed tag
    this.clearQueryParam(tag);

    this.queryFilters();
  }

  removeFilter(tag: CampaignFiltersTag, isRemoveExistingRequired: boolean = false) {
    const menuIndex = this.filterTags.findIndex((t) =>
      isRemoveExistingRequired ? t.parentKey == tag.parentKey : t.key == tag.key,
    );

    if (menuIndex >= 0) this.filterTags.splice(menuIndex, 1);

    // Update the params for the tags
    if (tag.parentKey == 'selectedSectors') {
      delete this.params.selectedSectors;
    }
  }

  queryFilters() {
    // Reset certain filters to raw state
    if (this.filterTags.length === 0) {
      delete this.params.selectedSectors;
    }

    const filters = this.filterTags.reduce((acc, tag) => {
      // Special handling for certain tag that need to render as Array
      if (tag.isArray) {
        let s = [];

        if (Array.isArray(acc[tag.parentKey])) {
          s = acc[tag.parentKey];
        } else if (acc[tag.parentKey]) {
          s.push(acc[tag.parentKey]);
        }

        return {
          ...acc,
          [tag.parentKey]: [...s, tag],
        };
      }

      return {
        ...acc,
        [tag.parentKey]: tag.key,
      };
    }, {});

    this.params = { ...this.params, ...filters };
    this.submit(this.params);
  }

  // Filters related
  // ------------------
  selectFilterType(type: InvestmentSortType) {
    this.params = { ...this.params, ...{ orderBy: type } };
    this.submit(this.params);
  }

  showAll() {
    this.params = new BusinessesFilter();
    this.filterTags = [];
    this.submit(this.params, true);
  }

  filterStatus(status: InvestorShareMarketStatusFilter) {
    if (
      location.pathname == '/dashboard/saved-watchlist' &&
      status == InvestorShareMarketStatusFilter.Watched
    )
      return;

    this.params.investorShareMarketStatus = Array.isArray(this.params.investorShareMarketStatus)
      ? this.params.investorShareMarketStatus
      : [this.params.investorShareMarketStatus];

    if (this.params.investorShareMarketStatus.includes(status))
      this.params.investorShareMarketStatus = this.params.investorShareMarketStatus.filter(
        (item) => item != status,
      );
    else this.params.investorShareMarketStatus.push(status);

    this.submit(this.params, true);
  }

  showAllActive() {
    return (
      (!this.params?.selectedSectors || this.params?.selectedSectors.length == 0) &&
      !this.params?.filterString &&
      this.params?.orderBy == InvestmentSortType.LastDoneShareTransactionAt &&
      (!this.params?.investorShareMarketStatus ||
        this.params?.investorShareMarketStatus?.length == 0)
    );
  }

  clearQueryParam(tag: CampaignFiltersTag) {
    const queryParamsToRemove = {};

    if (tag.parentKey === 'selectedSectors') {
      queryParamsToRemove['sectorIds'] = null;
    } else {
      queryParamsToRemove[tag.parentKey] = null;
    }

    this.queryParamsService.updateUrl(queryParamsToRemove);
  }
}
