<div class="row mb-4">
  <div class="col-12">
    <h4 class="module-title">{{ 'company.documents' | translate }}</h4>
  </div>
</div>

<!-- Public documents -->
<div class="">
  <ul
    class="tw-grid tw-list-none tw-grid-cols-2 tw-gap-4 tw-pl-0 xl:tw-grid-cols-3"
    *ngIf="documentsTotal > 0"
  >
    <li *ngFor="let document of documents">
      <a class="tw-inline-block tw-h-full tw-w-full" [href]="document.url" target="_blank">
        <ng-container
          *ngTemplateOutlet="documentButton; context: { name: document.attachmentName }"
        ></ng-container>
      </a>
    </li>
  </ul>

  <ng-container *ngIf="isDocumentLoading">
    <agmo-loading></agmo-loading>
  </ng-container>

  <ng-container *ngIf="!isDocumentLoading && documentsTotal == 0">
    <app-empty-state [message]="'common.noDocument'"></app-empty-state>
  </ng-container>

  <button
    type="button"
    *ngIf="documentsTotal > documents.length"
    class="tw-block tw-w-full tw-cursor-pointer tw-rounded tw-border tw-border-primary tw-bg-transparent tw-px-3 tw-py-2 tw-text-center tw-font-bold tw-text-primary hover:tw-border-opacity-50"
    (click)="handleLoadMoreDocuments()"
  >
    {{ 'common.loadMore' | translate }}
  </button>
</div>

<!-- For invested investor only -->
<div class="mb-5" *ngIf="!isLoading && attachments.length > 0">
  <hr />

  <h5 class="tw-mb-4 tw-text-lg">{{ 'learn.for.shareholders' | translate }}</h5>

  <ul class="tw-grid tw-list-none tw-grid-cols-2 tw-gap-4 tw-pl-0 xl:tw-grid-cols-3">
    <li *ngFor="let document of attachments">
      <a class="tw-inline-block tw-h-full tw-w-full" [href]="document.url" target="_blank">
        <ng-container
          *ngTemplateOutlet="documentButton; context: { name: document.attachmentName }"
        ></ng-container>
      </a>
    </li>
  </ul>

  <div class="my-3" *ngIf="total - attachments.length > 0">
    <div class="view-more" (click)="loadMore()">
      {{ 'common.loadMore' | translate }}
    </div>
  </div>
</div>

<ng-template #documentButton let-name="name">
  <div
    class="tw-flex tw-h-full tw-w-full tw-items-center tw-rounded tw-bg-divider tw-bg-opacity-50 tw-p-3 tw-text-sm tw-text-body hover:tw-bg-opacity-70 hover:tw-text-body-dark"
  >
    <div class="pitchin-icon pitchin-icon-document tw-mr-2 tw-flex-none"></div>
    {{ name }}
  </div>
</ng-template>
