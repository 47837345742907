import { Component } from '@angular/core';
import { BuyerSeller } from '../../../../core/models/mysec/buy-sell-content';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-buy-sell-tutorial',
  templateUrl: './buy-sell-tutorial.component.html',
  styleUrls: ['./buy-sell-tutorial.component.scss'],
})
export class BuySellTutorialComponent {
  tutorialContent: string;
  // showButtonScrollHeight: number = 900;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    const element = document.getElementsByClassName('buy-sell-parent')[0];

    console.log(element?.getBoundingClientRect().top);

    if (element?.getBoundingClientRect().top <= 260) {
      // Add a class to trigger the animation
      element?.classList.add('active');
    }
  }

  setContent(role) {
    this.tutorialContent = role;
  }

  resetContent() {
    this.tutorialContent = null;
  }

  buyerSellerTutorial: BuyerSeller = {
    'buyer': {
      'title':
        "Here's how your <span class='tw-text-primary tw-font-bold'>buy</span> order gets <span class='tw-text-secondary tw-font-bold'>matched</span>",
      'tabs': [
        {
          'title': 'Buy Existing Order',
          'steps': [
            {
              'description':
                'There are available sell orders at various prices on the order book. You wish to buy 15 shares at a maximum of RM12.00 per share.',
              'content': {
                'orderBook': {
                  'buyOrders': [
                    {
                      'volume': 15,
                      'price': 12.0,
                      'isHighlighted': 'gray',
                      'isBold': true,
                    },
                  ],
                  'sellOrders': [
                    {
                      'volume': 10,
                      'price': 10.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 12,
                      'price': 11.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 20,
                      'price': 11.5,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                },
                'portfolio': {
                  'business': null,
                  'quantity': null,
                  'investmentAmount': null,
                  'shareAveragePrice': null,
                  'isHighlighted': null,
                },
              },
            },
            {
              'description':
                'Your order will be matched with the lowest-priced shares first, in this case: 10 @ RM10.00 and 5 @ RM11.00.',
              'content': {
                'orderBook': {
                  'buyOrders': [
                    {
                      'volume': 15,
                      'price': 12.0,
                      'isHighlighted': 'gray',
                      'isBold': false,
                    },
                  ],
                  'sellOrders': [
                    {
                      'volume': 10,
                      'price': 10.0,
                      'isHighlighted': 'yellow',
                      'isBold': true,
                    },
                    {
                      'volume': 12,
                      'price': 11.0,
                      'isHighlighted': 'yellow',
                      'isBold': true,
                    },
                    {
                      'volume': 20,
                      'price': 11.5,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                },
                'portfolio': {
                  'business': null,
                  'quantity': null,
                  'investmentAmount': null,
                  'shareAveragePrice': null,
                  'isHighlighted': null,
                },
              },
            },
            {
              'description': `Outcome<br>
              <ul>
                <li>You now own 15 shares totalling RM155.00*</li>
                <li>Your ave. price per share is RM10.20</li>
                <li>The order is fully matched & cleared from the order book</li>
                <li>Your shares are now visible on your portfolio</li>
              </ul>
              <p class="tw-text-sm">*excluding trading fees</p>
              `,
              'content': {
                'orderBook': {
                  'buyOrders': [],
                  'sellOrders': [
                    {
                      'volume': 7,
                      'price': 11.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 20,
                      'price': 11.5,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                },
                'portfolio': {
                  'business': 'MyECO Sdn Bhd',
                  'quantity': 15,
                  'investmentAmount': 'RM155.00',
                  'shareAveragePrice': 'RM10.20',
                  'isHighlighted': 'green',
                },
              },
            },
          ],
        },
        {
          'title': 'Buy @ Pre-Market',
          'steps': [
            {
              'description':
                'Before the market opens, there are available buy & sell orders at various prices on the order book. You wish to buy 20 shares at a maximum of RM15.00 per share & are positioned at the top of the order book due to your bid price being the highest.',
              'content': {
                'orderBook': {
                  'buyOrders': [
                    {
                      'volume': 20,
                      'price': 15.0,
                      'isHighlighted': 'gray',
                      'isBold': true,
                    },
                    {
                      'volume': 5,
                      'price': 14.5,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 10,
                      'price': 14.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                  'sellOrders': [
                    {
                      'volume': 15,
                      'price': 11.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 15,
                      'price': 11.5,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 50,
                      'price': 12.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                },
                'portfolio': {
                  'business': null,
                  'quantity': null,
                  'investmentAmount': null,
                  'shareAveragePrice': null,
                  'isHighlighted': null,
                },
              },
            },
            {
              'description':
                'Once the market opens, your order will be matched with the lowest-priced shares first, in this case: 15 @ RM11.00 and 5 @ RM11.50.',
              'content': {
                'orderBook': {
                  'buyOrders': [
                    {
                      'volume': 20,
                      'price': 15.0,
                      'isHighlighted': 'gray',
                      'isBold': false,
                    },
                    {
                      'volume': 5,
                      'price': 14.5,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 10,
                      'price': 14.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                  'sellOrders': [
                    {
                      'volume': 15,
                      'price': 11.0,
                      'isHighlighted': 'yellow',
                      'isBold': true,
                    },
                    {
                      'volume': 15,
                      'price': 11.5,
                      'isHighlighted': 'yellow',
                      'isBold': true,
                    },
                    {
                      'volume': 50,
                      'price': 12.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                },
                'portfolio': {
                  'business': null,
                  'quantity': null,
                  'investmentAmount': null,
                  'shareAveragePrice': null,
                  'isHighlighted': null,
                },
              },
            },
            {
              'description': `Outcome<br>
              <ul>
                <li>You now own 20 shares totalling RM222.50*</li>
                <li>Your ave. price per share is RM11.13</li>
                <li>The order is fully matched & cleared from the order book</li>
                <li>The order is now visible on your portfolio</li>
              </ul>
              <p class="tw-text-sm">*excluding trading fees</p>
              `,
              'content': {
                'orderBook': {
                  'buyOrders': [
                    {
                      'volume': 5,
                      'price': 14.5,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 10,
                      'price': 14.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                  'sellOrders': [
                    {
                      'volume': 10,
                      'price': 11.5,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 50,
                      'price': 12.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                },
                'portfolio': {
                  'business': 'MyECO Sdn Bhd',
                  'quantity': 20,
                  'investmentAmount': 'RM222.50',
                  'shareAveragePrice': 'RM11.13',
                  'isHighlighted': 'green',
                },
              },
            },
          ],
        },
        {
          'title': 'Buy @ Blank Order Book',
          'steps': [
            {
              'description':
                'You wish to buy 20 shares at a maximum of RM15.00 per share. However, there are presently no available sell orders on the order book. Your order is positioned at the top of the order book due to your bid price being the highest.',
              'content': {
                'orderBook': {
                  'buyOrders': [
                    {
                      'volume': 20,
                      'price': 15.0,
                      'isHighlighted': 'gray',
                      'isBold': true,
                    },
                    {
                      'volume': 5,
                      'price': 14.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 10,
                      'price': 13.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                  'sellOrders': [],
                },
                'portfolio': {
                  'business': null,
                  'quantity': null,
                  'investmentAmount': null,
                  'shareAveragePrice': null,
                  'isHighlighted': null,
                },
              },
            },
            {
              'description':
                'Upon waiting, a new sell order for RM14.00 per share is opened. Your order will be matched at 15 @ RM15.00, which is your buy order price.',
              'content': {
                'orderBook': {
                  'buyOrders': [
                    {
                      'volume': 20,
                      'price': 15.0,
                      'isHighlighted': 'gray',
                      'isBold': false,
                    },
                    {
                      'volume': 5,
                      'price': 14.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 10,
                      'price': 13.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                  'sellOrders': [
                    {
                      'volume': 15,
                      'price': 14.0,
                      'isHighlighted': 'yellow',
                      'isBold': true,
                    },
                  ],
                },
                'portfolio': {
                  'business': null,
                  'quantity': null,
                  'investmentAmount': null,
                  'shareAveragePrice': null,
                  'isHighlighted': null,
                },
              },
            },
            {
              'description': `Outcome<br>
              <ul>
                <li>You now own 15 shares totalling RM225.00*</li>
                <li>Your ave. price per share is RM15.00</li>
                <li>The order is partially matched, with 5 shares still unmatched. This will remain on the order book for future matches**</li>
                <li>The order is now visible on your portfolio</li>
              </ul>
              <p class="tw-text-sm">*excluding trading fees; **until the order book reset</p>
              `,
              'content': {
                'orderBook': {
                  'buyOrders': [
                    {
                      'volume': 5,
                      'price': 15.0,
                      'isHighlighted': 'gray',
                      'isBold': false,
                    },
                    {
                      'volume': 5,
                      'price': 14.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 10,
                      'price': 13.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                  'sellOrders': [],
                },
                'portfolio': {
                  'business': 'MyECO Sdn Bhd',
                  'quantity': 15,
                  'investmentAmount': 'RM225.00',
                  'shareAveragePrice': 'RM15.00',
                  'isHighlighted': 'green',
                },
              },
            },
          ],
        },
      ],
    },
    'seller': {
      'title':
        "Here's how your <span class='tw-text-primary tw-font-bold'>sell</span> order gets <span class='tw-text-secondary tw-font-bold'>matched</span>",
      'tabs': [
        {
          'title': 'Sell Existing Order',
          'steps': [
            {
              'description':
                'There are available buy orders at various prices on the order book. You wish to sell 20 of your existing shares at a minimum of RM12.00 per share.',
              'content': {
                'orderBook': {
                  'buyOrders': [
                    {
                      'volume': 12,
                      'price': 13.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 10,
                      'price': 12.8,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 20,
                      'price': 11.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                  'sellOrders': [
                    {
                      'volume': 20,
                      'price': 12.0,
                      'isHighlighted': 'gray',
                      'isBold': true,
                    },
                  ],
                },
                'portfolio': {
                  'business': 'MyECO Sdn Bhd',
                  'quantity': 50,
                  'investmentAmount': 'RM600.00',
                  'shareAveragePrice': 'RM12.00',
                  'isHighlighted': 'yellow',
                },
              },
            },
            {
              'description':
                'Your order will be matched with the highest-priced shares first, in this case: 12 @ RM13.00 and 8 @ RM12.80.',
              'content': {
                'orderBook': {
                  'buyOrders': [
                    {
                      'volume': 12,
                      'price': 13.0,
                      'isHighlighted': 'yellow',
                      'isBold': true,
                    },
                    {
                      'volume': 10,
                      'price': 12.8,
                      'isHighlighted': 'yellow',
                      'isBold': true,
                    },
                    {
                      'volume': 20,
                      'price': 11.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                  'sellOrders': [
                    {
                      'volume': 20,
                      'price': 12.0,
                      'isHighlighted': 'gray',
                      'isBold': false,
                    },
                  ],
                },
                'portfolio': {
                  'business': 'MyECO Sdn Bhd',
                  'quantity': 50,
                  'investmentAmount': 'RM600.00',
                  'shareAveragePrice': 'RM12.00',
                  'isHighlighted': null,
                },
              },
            },
            {
              'description': `Outcome<br>
              <ul>
                <li>You have now sold 20 shares totalling RM258.40*</li>
                <li>Your ave. price per share is RM12.92</li>
                <li>The order is fully matched & cleared from the order book</li>
                <li>The share quantity change is reflected on your portfolio</li>
              </ul>
              <p class="tw-text-sm">*excluding trading fees</p>
              `,
              'content': {
                'orderBook': {
                  'buyOrders': [
                    {
                      'volume': 2,
                      'price': 12.8,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 20,
                      'price': 11.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                  'sellOrders': [],
                },
                'portfolio': {
                  'business': 'MyECO Sdn Bhd',
                  'quantity': 30,
                  'investmentAmount': 'RM341.60',
                  'shareAveragePrice': 'RM11.39',
                  'isHighlighted': 'green',
                },
              },
            },
          ],
        },
        {
          'title': 'Sell @ Pre-Market',
          'steps': [
            {
              'description':
                'Before the market opens, there are available buy & sell orders at various prices on the order book. You wish to sell all 30 of your existing shares at a minimum of RM11.00 per share & are positioned at the top of the order book due to your ask price being the lowest.',
              'content': {
                'orderBook': {
                  'buyOrders': [
                    {
                      'volume': 20,
                      'price': 15.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 10,
                      'price': 14.5,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 10,
                      'price': 14.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                  'sellOrders': [
                    {
                      'volume': 30,
                      'price': 11.0,
                      'isHighlighted': 'gray',
                      'isBold': true,
                    },
                    {
                      'volume': 15,
                      'price': 11.5,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 50,
                      'price': 12.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                },
                'portfolio': {
                  'business': 'MyECO Sdn Bhd',
                  'quantity': 30,
                  'investmentAmount': 'RM330.00',
                  'shareAveragePrice': 'RM11.00',
                  'isHighlighted': 'yellow',
                },
              },
            },
            {
              'description':
                'Once the market opens, the buy orders will be matched first, which means the executed price depends on the sell orders. Your order will be matched with the highest-positioned buy orders, in this case: 20 + 10 @ RM11.00.',
              'content': {
                'orderBook': {
                  'buyOrders': [
                    {
                      'volume': 20,
                      'price': 15.0,
                      'isHighlighted': 'yellow',
                      'isBold': true,
                    },
                    {
                      'volume': 10,
                      'price': 14.5,
                      'isHighlighted': 'yellow',
                      'isBold': true,
                    },
                    {
                      'volume': 10,
                      'price': 14.0,
                      'isHighlighted': 'yellow',
                      'isBold': true,
                    },
                  ],
                  'sellOrders': [
                    {
                      'volume': 30,
                      'price': 11.0,
                      'isHighlighted': 'gray',
                      'isBold': false,
                    },
                    {
                      'volume': 15,
                      'price': 11.5,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 50,
                      'price': 12.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                },
                'portfolio': {
                  'business': 'MyECO Sdn Bhd',
                  'quantity': 30,
                  'investmentAmount': 'RM330.00',
                  'shareAveragePrice': 'RM11.00',
                  'isHighlighted': null,
                },
              },
            },
            {
              'description': `Outcome<br>
              <ul>
                <li>You have now sold 30 shares totalling RM330.00*</li>
                <li>Your ave. price per share is RM11.00</li>
                <li>The order is fully matched & cleared from the order book</li>
                <li>The shares are cleared from your portfolio</li>
              </ul>
              <p class="tw-text-sm">*excluding trading fees</p>
              `,
              'content': {
                'orderBook': {
                  'buyOrders': [
                    {
                      'volume': 10,
                      'price': 14.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                  'sellOrders': [
                    {
                      'volume': 15,
                      'price': 11.5,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 50,
                      'price': 12.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                },
                'portfolio': {
                  'business': null,
                  'quantity': null,
                  'investmentAmount': null,
                  'shareAveragePrice': null,
                  'isHighlighted': 'green',
                },
              },
            },
          ],
        },
        {
          'title': 'Sell @ Blank Order Book',
          'steps': [
            {
              'description':
                'You wish to sell all 20 of your existing shares at a minimum of RM15.00 per share. However, there are presently no buy orders on the order book. You are positioned at the top of the order book due to your ask price being the lowest.',
              'content': {
                'orderBook': {
                  'buyOrders': [],
                  'sellOrders': [
                    {
                      'volume': 20,
                      'price': 15.0,
                      'isHighlighted': 'gray',
                      'isBold': true,
                    },
                    {
                      'volume': 5,
                      'price': 15.5,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 10,
                      'price': 16.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                },
                'portfolio': {
                  'business': 'MyECO Sdn Bhd',
                  'quantity': 20,
                  'investmentAmount': 'RM300.00',
                  'shareAveragePrice': 'RM15.00',
                  'isHighlighted': 'yellow',
                },
              },
            },
            {
              'description':
                'Upon waiting, a new buy order for RM15.00 per share is opened, which meets your ask price. This will be matched with your order, at your ask price of RM15.00 as your order was placed first.',
              'content': {
                'orderBook': {
                  'buyOrders': [
                    {
                      'volume': 10,
                      'price': 15.0,
                      'isHighlighted': 'yellow',
                      'isBold': true,
                    },
                  ],
                  'sellOrders': [
                    {
                      'volume': 20,
                      'price': 15.0,
                      'isHighlighted': 'gray',
                      'isBold': false,
                    },
                    {
                      'volume': 5,
                      'price': 15.5,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 10,
                      'price': 16.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                },
                'portfolio': {
                  'business': 'MyECO Sdn Bhd',
                  'quantity': 20,
                  'investmentAmount': 'RM300.00',
                  'shareAveragePrice': 'RM15.00',
                  'isHighlighted': null,
                },
              },
            },
            {
              'description': `Outcome<br>
              <ul>
                <li>You have now sold 10 shares totalling RM150.00*</li>
                <li>Your ave. price per share is RM15.00</li>
                <li>The order is partially matched, with 10 shares still unmatched. This will remain on the order book for future matches**</li>
                <li>The share quantity change is reflected on your portfolio</li>
              </ul>
              <p class="tw-text-sm">*excluding trading fees; **until the order book reset</p>
              `,
              'content': {
                'orderBook': {
                  'buyOrders': [],
                  'sellOrders': [
                    {
                      'volume': 10,
                      'price': 15.0,
                      'isHighlighted': 'gray',
                      'isBold': true,
                    },
                    {
                      'volume': 5,
                      'price': 15.5,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                    {
                      'volume': 10,
                      'price': 16.0,
                      'isHighlighted': null,
                      'isBold': false,
                    },
                  ],
                },
                'portfolio': {
                  'business': 'MyECO Sdn Bhd',
                  'quantity': 10,
                  'investmentAmount': 'RM150.00',
                  'shareAveragePrice': 'RM15.00',
                  'isHighlighted': 'green',
                },
              },
            },
          ],
        },
      ],
    },
  };
}
