<section
  class="for-founders tw-bg-gradient-to-b tw-from-[#EBF7FF] tw-to-[#FFFFFF] tw-pb-8 tw-pt-[200px] max-[850px]:tw-pt-[150px] lg:tw-pb-20"
>
  <div>
    <div class="container-fluid tw-mb-8">
      <p class="p-text-preheader">FOR FOUNDERS</p>
      <h2 class="p-text-h2">Raise capital and gain a community</h2>
      <p class="p-text-sub1">
        Startup-friendly fundraising campaigns that raise brand awareness and engage with your
        community.
      </p>
    </div>
    <div class="container-fluid">
      <app-testimonial></app-testimonial>
      <div class="tw-text-center">
        <a class="btn btn-primary" routerLink="/raise">Raise capital &#8594;</a>
      </div>
    </div>
  </div>
</section>
