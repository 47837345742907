import { Injectable } from '@angular/core';
import { Role } from '../../enum/role.enum';
import { StorageService } from '../storage.service';

@Injectable()
export class AuthService {
  constructor(private storageService: StorageService) {}

  isAuthenticated = () => {
    return (
      this.storageService.tokenExpiry &&
      this.storageService.role &&
      [Role.IndividualInvestor, Role.CompanyRep, Role.IssuerRep].includes(this.storageService.role)
    );
  };
}
