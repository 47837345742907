import { TeamMemberSingleModel } from './team-member-single.model';
import { AttachmentInfo } from '../../../core/models/attachment/attachment-info';

export class TeamMember {
  id: number;
  name: string;
  position: string;
  details: string;
  attachmentInfo: AttachmentInfo;
  imageUrl: string;

  constructor(model: TeamMemberSingleModel) {
    this.id = model.id;
    this.name = model.name;
    this.position = model.position;
    this.details = model.details;
    this.attachmentInfo = model.attachmentInfo;
    this.imageUrl = model.imageUrl;
  }
}
