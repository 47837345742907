<section class="section section-shaped">
  <div class="container" *ngIf="!isLoading && offerCreation">
    <div class="row">
      <div class="col-lg-5 title-panel" routerLink="/raise/my-application">
        <h4 class="text-primary link">{{ 'common.back' | translate }}</h4>
      </div>
      <div class="col-lg-7 title-panel">
        <h4 class="font-weight-extrabold">{{ 'raise.offerCreation' | translate }}</h4>
      </div>
      <div class="col-lg-12 input-panel">
        <h3 class="font-weight-bold text-break-all">
          {{ fundRaiseApplicationName }}
        </h3>
      </div>
    </div>
    <div class="row border-btm">
      <div class="col-lg-6 input-panel">
        <div class="input-field">{{ 'offerCreation.offeringShare' | translate }}</div>
        <div class="text-black font-weight-bold">
          {{ 'offerCreation.' + ShareType[offerCreation.offeringShare] | translate }}
        </div>
      </div>
      <div class="col-lg-6 input-panel">
        <div class="input-field">
          {{ 'company.atiProgramRegistered' | translate }}
          <div class="hover-dropdown" ngbDropdown placement="bottom-right">
            <div
              class="pitchin-icon pitchin-icon-info-icon-grey ml-2 no-caret"
              ngbDropdownToggle
            ></div>
            <div class="font-size-smaller p-3 hover-text-panel" ngbDropdownMenu>
              <div [innerHTML]="'company.atiDescription' | translate"></div>
            </div>
          </div>
        </div>
        <div class="text-black font-weight-bold">
          {{ (offerCreation.isAngelTaxIncentive ? 'common.yes' : 'common.no') | translate }}
        </div>
      </div>
      <div class="col-lg-6 input-panel">
        <div class="input-field">{{ 'offerCreation.minimumTargetAmount' | translate }}</div>
        <div class="text-black font-weight-bold">
          {{ offerCreation.minimumTargetAmount | numberSuffix }}
        </div>
      </div>
      <div class="col-lg-6 input-panel">
        <div class="input-field">{{ 'offerCreation.maximumTargetAmount' | translate }}</div>
        <div class="text-black font-weight-bold">
          {{ offerCreation.maximumTargetAmount | numberSuffix }}
        </div>
      </div>
      <div class="col-lg-12 input-panel">
        <div class="input-field">{{ 'campaign.equityOffered' | translate }}</div>
        <div class="text-black font-weight-bold" *ngIf="offerCreation.equityOffered; else empty">
          {{ 'campaign.upToPercent' | translate }}
          {{ offerCreation.equityOffered | number: '1.2-2' }}%
        </div>
        <ng-template #empty>-</ng-template>
      </div>
      <div class="col-lg-6 input-panel">
        <div class="input-field">{{ 'offerCreation.companyValuation' | translate }}</div>
        <div class="text-black font-weight-bold">
          {{ offerCreation.companyValuation | numberSuffix }}
        </div>
      </div>
      <div class="col-lg-6 input-panel">
        <div class="input-field">{{ 'offerCreation.minimumSharesIssue' | translate }}</div>
        <div class="text-black font-weight-bold">
          {{ offerCreation.minimumSharesIssued | number }}
        </div>
      </div>
      <div class="col-lg-6 input-panel">
        <div class="input-field">{{ 'offerCreation.pricePerShare' | translate }}</div>
        <div class="text-black font-weight-bold">
          {{ offerCreation.pricePerShare | numberSuffix }}
        </div>
      </div>
      <div class="col-lg-6 input-panel">
        <div class="input-field">
          {{ 'offerCreation.shareCapitalBeforeFundingExercise' | translate }}
        </div>
        <div class="text-black font-weight-bold">
          {{ offerCreation.shareCapitalBeforeFundingExercise | number }}
        </div>
      </div>
      <div class="col-lg-6 input-panel">
        <div class="input-field">{{ 'offerCreation.sharesPerLot' | translate }}</div>
        <div class="text-black font-weight-bold">{{ offerCreation.sharePerLot | number }}</div>
      </div>
      <div class="col-lg-6 input-panel">
        <div class="input-field">{{ 'offerCreation.pricePerLot' | translate }}</div>
        <div class="text-black font-weight-bold">
          {{
            (offerCreation.pricePerShare && offerCreation.sharePerLot
              ? offerCreation.pricePerShare * offerCreation.sharePerLot
              : 0
            ) | numberSuffix
          }}
        </div>
      </div>
      <div class="col-lg-6 input-panel">
        <div class="input-field">{{ 'offerCreation.minimumNumberInLot' | translate }}</div>
        <div class="text-black font-weight-bold">
          {{ offerCreation.minimumNumberInLot | number }}
        </div>
      </div>
      <div class="col-lg-6 input-panel">
        <div class="input-field">{{ 'offerCreation.incrementalAmountInLot' | translate }}</div>
        <div class="text-black font-weight-bold">
          {{ offerCreation.incrementalAmountInLot | number }}
        </div>
      </div>
    </div>
    <div class="row border-btm">
      <div class="col-lg-12 title-panel">
        <h4 class="text-primary font-weight-extrabold px-0">
          {{ 'offerCreation.overSubscription' | translate }}
        </h4>
      </div>
      <ng-container *ngIf="offerCreation.allowOverSubscription">
        <div class="col-lg-6 input-panel">
          <div class="input-field">{{ 'offerCreation.overSubscriptionAmount' | translate }}</div>
          <div class="text-black font-weight-bold">
            {{ offerCreation.overSubscriptionAmount | numberSuffix }}
          </div>
        </div>
        <div class="col-lg-6 input-panel">
          <div class="input-field">{{ 'offerCreation.additionalShareIssued' | translate }}</div>
          <div class="text-black font-weight-bold">
            {{ offerCreation.additionalSharesIssued | number }}
          </div>
        </div>
      </ng-container>
      <div class="col-lg-6 input-panel" *ngIf="!offerCreation.allowOverSubscription">
        <div class="input-field">{{ 'offerCreation.allowOverSubscription' | translate }}</div>
        <div class="text-black font-weight-bold">{{ 'common.no' | translate }}</div>
      </div>
    </div>
    <form
      (ngSubmit)="submit(true, false)"
      [formGroup]="form"
      [class.show-form-error]="showFormError"
    >
      <div class="row">
        <div class="col-lg-12 title-panel d-flex justify-content-between">
          <h4 class="text-primary font-weight-extrabold px-0">
            {{ 'offerCreation.bonusShare' | translate }}
          </h4>
          <button
            type="button"
            class="btn btn-edit"
            (click)="editBonusShare()"
            *ngIf="
              (offerCreation.raiseFundApplicationProcessStatusType ==
                RaiseFundApplicationProcessStatusType.PendingIssuerApproval ||
                offerCreation.raiseFundApplicationProcessStatusType ==
                  RaiseFundApplicationProcessStatusType.Rejected) &&
              isViewBonus
            "
          >
            <i class="pitchin-icon pitchin-icon-edit-black mr-2"></i>
            <div class="text-weight-bold">{{ 'common.edit' | translate }}</div>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 pl-lg-0">
          <div class="input-label mb-0">{{ 'offerCreation.enableBonusShare' | translate }}</div>
          <div class="text-black font-weight-bold" *ngIf="isViewBonus">
            {{ (offerCreation.enableBonusShare ? 'common.yes' : 'common.no') | translate }}
          </div>
          <div class="mt-2" *ngIf="!isViewBonus">
            <mat-slide-toggle formControlName="enableBonusShare" (change)="hideBonusShare()">
            </mat-slide-toggle>
          </div>
        </div>
        <ng-container *ngIf="form.value.enableBonusShare">
          <div class="col-lg-12 py-3 px-0">
            <div class="d-flex">
              <div class="text-black input-field">
                {{ 'offerCreation.bonusPeriod' | translate }}
              </div>
            </div>
            <div class="text-black font-weight-bold mt-1" *ngIf="isViewBonus">
              {{ offerCreation.bonusPeriodStartDate | amDateFormat: 'DD MMM YYYY' }} -
              {{ offerCreation.bonusPeriodEndDate | amDateFormat: 'DD MMM YYYY' }}
            </div>
          </div>
          <ng-container *ngIf="!isViewBonus">
            <div class="col-lg-6 pl-lg-0 date-input-wrap">
              <div class="input-label">{{ 'common.startDate' | translate }}</div>
              <div class="input-field date-input">
                <input
                  class="form-control"
                  [matDatepicker]="bonusPeriodStartDate"
                  formControlName="bonusPeriodStartDate"
                  name="birthday"
                  placeholder="DD/MM/YYYY"
                />
                <mat-datepicker-toggle matSuffix [for]="bonusPeriodStartDate" disableRipple>
                  <div class="pitchin-icon pitchin-icon-calendar" matDatepickerToggleIcon></div>
                </mat-datepicker-toggle>
                <mat-datepicker #bonusPeriodStartDate></mat-datepicker>
              </div>
            </div>
            <div class="col-lg-6 pr-lg-0 date-input-wrap">
              <div class="input-label">{{ 'common.endDate' | translate }}</div>
              <div class="input-field date-input">
                <input
                  class="form-control"
                  [matDatepicker]="bonusPeriodEndDate"
                  formControlName="bonusPeriodEndDate"
                  name="birthday"
                  placeholder="DD/MM/YYYY"
                />
                <mat-datepicker-toggle matSuffix [for]="bonusPeriodEndDate" disableRipple>
                  <div class="pitchin-icon pitchin-icon-calendar" matDatepickerToggleIcon></div>
                </mat-datepicker-toggle>
                <mat-datepicker #bonusPeriodEndDate></mat-datepicker>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="row mt-3" *ngIf="form.value.enableBonusShare">
        <div class="col-lg-12 px-0">
          <div class="text-black input-field">{{ 'offerCreation.bonusDetails' | translate }}</div>
          <div class="table-responsive">
            <table class="table table-striped mt-2">
              <thead>
                <tr>
                  <th scope="col">
                    <div class="d-flex">
                      <div class="font-weight-normal">
                        {{ 'offerCreation.investments' | translate }}
                      </div>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="d-flex justify-content-center">
                      <div class="font-weight-normal">
                        {{ 'offerCreation.shares' | translate }}
                      </div>
                    </div>
                  </th>
                  <th scope="col" width="15%">
                    <div class="d-flex justify-content-center">
                      <div class="font-weight-normal">
                        {{ 'offerCreation.bonus' | translate }}
                      </div>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="d-flex justify-content-center">
                      <div class="font-weight-normal">
                        {{ 'offerCreation.bonusShares' | translate }}
                      </div>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="d-flex justify-content-center">
                      <div class="font-weight-normal">
                        {{ 'offerCreation.totalShares' | translate }}
                      </div>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container formArrayName="bonusShares">
                  <tr
                    *ngFor="let item of bonusFormControl.controls; let i = index"
                    formGroupName="{{ i }}"
                  >
                    <td *ngIf="isViewBonus">
                      {{ item.value.investmentLots | numberSuffix: { numberFormat: '1.2-2' } }}
                    </td>
                    <td *ngIf="!isViewBonus">
                      <div class="form-group mb-0">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span
                              class="input-group-text text-black"
                              [class.invalid]="showFormError && item.controls.investmentLots.errors"
                              >RM</span
                            >
                          </div>
                          <input
                            class="form-control"
                            formControlName="investmentLots"
                            type="text"
                            digitOnly
                            decimal="true"
                            allowPaste="false"
                            [pattern]="OneBillionWithTwoDecimalPattern"
                            (keyup)="getShare(item)"
                          />
                        </div>
                      </div>
                    </td>
                    <td class="text-center">{{ item.value.shares | number: '1.0-0' }}</td>
                    <td *ngIf="isViewBonus" class="text-center">
                      {{ item.value.bonusPercentage | number: '1.2-2' }}%
                    </td>
                    <td *ngIf="!isViewBonus">
                      <div class="form-group mb-0">
                        <div class="input-group">
                          <input
                            class="form-control"
                            formControlName="bonusPercentage"
                            type="text"
                            digitOnly
                            decimal="true"
                            allowPaste="false"
                            [pattern]="OneBillionWithTwoDecimalPattern"
                            [max]="MaxBonusLimit"
                            (keyup)="getShare(item)"
                          />
                          <div class="input-group-append">
                            <span
                              class="input-group-text text-black"
                              [class.invalid]="
                                showFormError && item.controls.bonusPercentage.errors
                              "
                              >%</span
                            >
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="text-center">{{ item.value.bonusShares | number: '1.0-0' }}</td>
                    <td class="text-center">{{ item.value.totalShares | number: '1.0-0' }}</td>
                    <td class="text-center">
                      <div
                        class="pitchin-icon pitchin-icon-delete"
                        (click)="removeBonus(i)"
                        *ngIf="!isViewBonus && bonusFormControl.controls.length > 1"
                      ></div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-lg-12 px-0 d-flex justify-content-end" *ngIf="!isViewBonus">
          <div class="btn btn-primary btn-add" (click)="addBonus()">
            <i class="fa fa-plus mr-2"></i>{{ 'common.add' | translate }}
          </div>
        </div>
      </div>
      <div
        class="row d-flex justify-content-lg-center mt-3"
        *ngIf="
          offerCreation.raiseFundApplicationProcessStatusType ==
            RaiseFundApplicationProcessStatusType.PendingIssuerApproval && isViewBonus
        "
      >
        <div class="col-lg-4 mb-2">
          <div class="btn btn-primary-border font-size-larger w-100" (click)="submit(false, false)">
            {{ 'common.reject' | translate }}
          </div>
        </div>
        <div class="col-lg-4 mb-2">
          <div class="btn btn-tertiary font-size-larger w-100" (click)="submit(true, true)">
            {{ 'common.approve' | translate }}
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-lg-center mt-3" *ngIf="!isViewBonus">
        <div class="col-lg-4 mb-4">
          <div
            class="font-size-larger font-weight-bold pointer text-center cancel"
            (click)="cancelEditBonus()"
          >
            {{ 'common.cancel' | translate }}
          </div>
        </div>
        <div class="col-lg-4 mb-4">
          <button class="btn btn-primary font-size-larger w-100" type="submit">
            {{ 'common.submit' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
  <agmo-loading *ngIf="isLoading"></agmo-loading>
</section>
