<div class="mb-4">
  <h2 class="font-weight-extrabold text-center">{{ dynamicForm.title }}</h2>
  <div [innerHTML]="dynamicForm.description"></div>
</div>
<form
  (ngSubmit)="onSubmit()"
  *ngIf="form"
  [formGroup]="form"
  [class.show-form-error]="showFormError"
>
  <div class="dynamic-form">
    <div class="row question-panel" *ngFor="let formPanel of dynamicForm.formPanels">
      <div class="col-lg-12" *ngFor="let item of formPanel.items">
        <div *ngIf="item.controlType == 'title'">
          <span class="title text-primary text-uppercase font-weight-extrabold">{{
            item.label
          }}</span>
          <div
            class="ml-2 hover-dropdown"
            ngbDropdown
            placement="bottom-right"
            *ngIf="item.hoverText"
          >
            <div class="pitchin-icon pitchin-icon-info-icon-grey no-caret" ngbDropdownToggle></div>
            <div class="font-size-smaller hover-text-panel p-3" ngbDropdownMenu>
              {{ item.hoverText }}
            </div>
          </div>
        </div>
        <div
          *ngIf="item.controlType == 'description'"
          class="input-label mt-4"
          [innerHTML]="item.label"
        ></div>
        <div
          *ngIf="item.controlType == 'description' && item.value"
          class="input-desc"
          [innerHTML]="item.value"
        ></div>
        <div
          class="row mt-4"
          *ngIf="item.controlType != 'title' && item.controlType != 'description'"
        >
          <div class="col-sm-12">
            <app-dynamic-form-question
              [inputClass]="item.class"
              [question]="item"
              [form]="form"
            ></app-dynamic-form-question>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row print-exclude">
    <div class="col-lg-12 text-center">
      <button class="btn btn-primary font-size-larger" type="submit" [disabled]="disableSubmit">
        {{ submitText }}
      </button>
    </div>
  </div>
</form>
