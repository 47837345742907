<div class="modal-body text-center p-5">
  <h5 class="font-weight-extrabold text-primary">
    {{ 'investment.youInvestingIn' | translate }}
  </h5>
  <img [alt]="campaignTitle" [src]="campaignImage || defaultLogo" class="campaign-logo" />
  <h5 class="font-weight-extrabold">{{ campaignTitle }}</h5>
  <h5 class="font-weight-extrabold text-primary mt-5 mb-4">
    {{ 'common.with' | translate }} {{ investmentAmount | numberSuffix }} ({{ lotAmount }}
    {{ (lotAmount > 1 ? 'investment.lots' : 'investment.lot') | translate }})
  </h5>
  <div class="d-flex flex-column">
    <div class="btn btn-primary font-weight-extrabold" (click)="confirm()">
      {{ 'investment.confirmAndPayment' | translate }}
    </div>
    <div class="btn btn-grey font-weight-extrabold" (click)="cancel()">
      {{ 'common.cancel' | translate }}
    </div>
  </div>
</div>
