import { ShareOwnership } from '../../../core/enum/share-ownership.enum';

export class ShareholderFilter {
  take: number;
  currentPage: number;
  ownership: ShareOwnership;
  tickerCode: string;

  constructor() {
    this.currentPage = 1;
    this.take = 20;
    this.ownership = ShareOwnership.All;
    this.tickerCode = '';
  }
}
