export enum InvestmentStatus {
  PendingPayment = 1,
  Paid = 2,
  Cancelled = 3,
  Refunded = 4,
  RefundRequested = 5,
  PaymentApprovalPending = 6,
  Completed = 7,
  PledgeCancelled = 8,
}
