import { SecurityContext } from '@angular/core';
import { ɵDomSanitizerImpl } from '@angular/platform-browser';

export function keepHtml(content: string) {
  const sanitizer = new ɵDomSanitizerImpl(document);
  return sanitizer.sanitize(SecurityContext.HTML, sanitizer.bypassSecurityTrustHtml(content));
}

export const EDITOR_CONFIG = {
  fillEmptyBlocks: false,
  autoParagraph: false,
  placeholder: '',
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'underline',
      'removeFormat',
      'strikethrough',
      '|',
      'fontColor',
      'fontBackgroundColor',
      'subscript',
      'superscript',
      '|',
      'alignment',
      'bulletedList',
      'numberedList',
      'outdent',
      'indent',
      '|',
      'link',
      'insertTable',
      'horizontalLine',
      '|',
      'imageUpload',
      'mediaEmbed',
      '|',
      'undo',
      'redo',
    ],
    shouldNotGroupWhenFull: true,
  },
  image: {
    toolbar: [
      'imageStyle:inline',
      'imageStyle:alignBlockLeft',
      'imageStyle:alignBlockRight',
      '|',
      'imageStyle:alignLeft',
      'imageStyle:alignCenter',
      'imageStyle:alignRight',
      '|',
      'imageStyle:block',
      'imageStyle:side',
      '|',
      'resizeImage:original',
      '|',
      'imageTextAlternative',
    ],
    styles: ['side', 'alignLeft', 'alignCenter', 'alignRight'],
    resizeUnit: 'px',
  },
  mediaEmbed: {
    toolbar: ['mediaEmbed'],
    previewsInData: true,
    // for customizing providers/dimension can refer to https://github.com/ckeditor/ckeditor5/blob/master/packages/ckeditor5-media-embed/src/mediaembedediting.js
  },
  table: {
    contentToolbar: [
      'tableColumn',
      'tableRow',
      'mergeTableCells',
      'tableProperties',
      'tableCellProperties',
    ],
  },
  fontFamily: {
    options: ['Open Sans, sans-serif', 'SF Pro Text, sans-serif'],
    supportAllValues: true,
  },
  fontSize: {
    options: [8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30],
    supportAllValues: true,
  },
  language: 'en',
  heading: {
    options: [
      {
        model: 'header_pitchIN',
        view: {
          name: 'h3',
          classes: 'header_pitchIN',
        },
        title: 'Header',
        class: 'header_pitchIN',
      },
      {
        model: 'sub_header_pitchIN',
        view: {
          name: 'h5',
          classes: 'sub_header_pitchIN',
        },
        title: 'Sub Header',
        class: 'sub_header_pitchIN',
      },
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
    ],
  },
  extraPlugins: [],
};

export const LINK_EDITOR_CONFIG = {
  toolbar: {
    items: ['link'],
    shouldNotGroupWhenFull: true,
  },
};
