import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as forge from 'node-forge';

@Injectable({
  providedIn: 'root',
})
export class EncryptService {
  encryptData(data) {
    var publicKey = forge.pki.publicKeyFromPem(environment.cryptoPubKey);
    var test = publicKey.encrypt(forge.util.encodeUtf8(data), 'RSA-OAEP', {
      md: forge.md.sha256.create(),
    });

    return forge.util.encode64(test);
  }
}
