<ng-container *ngIf="isLoading">
  <agmo-loading></agmo-loading>
</ng-container>

<ng-container *ngIf="!isLoading && campaign">
  <div class="container-fluid">
    <campaign-header
      [campaign]="campaign"
      [campaignInfo]="campaignInfo"
      [isLogin]="isLogin"
      [isCampaignEnded]="isCampaignEnded"
      [businessDirectoryInfo]="businessDirectoryInfo"
      [isInvestNowLoading]="isInvestNowLoading"
      (investNow)="investNow()"
      (saveCampaign)="handleSaveCampaign()"
      (onInvestorCountLoaded)="handleInvestorCount($event)"
    ></campaign-header>

    <campaign-investment-details
      *ngIf="!isCampaignEnded"
      class="md:tw-hidden"
      [campaign]="campaign"
      [isMobile]="true"
      [documents]="documents"
      [isDocumentLoading]="isDocumentLoading"
      [campaignInfo]="campaignInfo"
      [documentsTotal]="documentsTotal"
      (loadMoreDocuments)="handleLoadMoreDocuments()"
    ></campaign-investment-details>
  </div>

  <div id="campaignContent">
    <!-- For Prelive, Live campaigns and for those who previously invested -->
    <ng-container>
      <!-- Tabs & its contents-->
      <nav
        id="tabList"
        class="campaign-tabs navbar navbar-expand-md navbar-light navbar-content tw-sticky tw-top-0 tw-z-[1000] tw-mb-6 tw-bg-white md:tw-mb-12"
      >
        <div class="container-fluid">
          <ul class="navbar-nav mr-auto tw-px-8 md:tw-px-0">
            <ng-container *ngFor="let tab of tabs">
              <li
                *ngIf="!tab.show || tab.show()"
                class="nav-item d-flex"
                [class.active]="selectedTab === tab.id"
              >
                <a
                  class="nav-link tw-select-none"
                  (click)="changeTab(tab.id, $event)"
                  data-t="tabClick"
                >
                  {{ tab.name | translate }}
                  <span class="status-badge tab-number" *ngIf="tab.val">
                    {{ tab.val }}
                  </span>
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
      </nav>

      <div class="campaign-tabs-body container-fluid tw-scroll-mt-32" #tabContent>
        <div class="tw-flex tw-flex-col tw-gap-6 md:tw-flex-row md:tw-gap-6 lg:tw-gap-12">
          <div class="tw-flex-auto">
            <!-- My Investment Tab -->
            <ng-container *ngIf="selectedTab == 8">
              <app-investments
                [myInvestment]="myInvestment"
                [campaignStatus]="campaign.campaignStatus"
                (getMyInvestment)="getMyInvestment()"
              >
              </app-investments>
            </ng-container>

            <!-- Campaign Info Tab -->
            <ng-container *ngIf="selectedTab == 1">
              <ng-container *ngIf="isInsiderView || !isCampaignEnded">
                <campaign-info-tab
                  [campaign]="campaign"
                  [campaignInfo]="campaignInfo"
                  [campaignStatus]="campaign.campaignStatus"
                  (changeTab)="handleWhatsNewClick($event)"
                ></campaign-info-tab>
              </ng-container>
            </ng-container>

            <!-- Financial Tab -->
            <ng-container *ngIf="selectedTab == 2">
              <ng-container *ngIf="isLogin; else loginToView">
                <ng-container
                  *ngIf="isInvitedToInvest || campaign?.isCreator; else registerToInvest"
                >
                  <app-financial-list
                    class="tw-mb-10 tw-block"
                    (pageChange)="getFinancialOverview()"
                    [isLoading]="isFinancialOverviewsLoading"
                    [params]="params"
                    [total]="financialOverviewsTotal"
                    [financialOverviews]="financialOverviews"
                    [isPSTX]="false"
                  ></app-financial-list>
                  <app-documents-reports
                    [campaignId]="campaign.id"
                    [documents]="documents"
                    [isDocumentLoading]="isDocumentLoading"
                    [documentsTotal]="documentsTotal"
                    [myInvestment]="myInvestment"
                    (loadMoreDocuments)="handleLoadMoreDocuments()"
                  ></app-documents-reports>
                </ng-container>
              </ng-container>
            </ng-container>

            <!-- Updates Tab -->
            <ng-container *ngIf="selectedTab == 3">
              <app-updates
                [updates]="updates"
                [total]="totalNewsUpdates"
                [params]="updatesParams"
                [isPublished]="isUpdatesPublished"
                [isLoading]="isUpdatesLoading"
                [isCreator]="campaign.isCreator"
                (getList)="triggerNewUpdates($event)"
                (deleteItem)="deleteUpdates($event)"
                (submitForm)="submitUpdates($event)"
                [campaignStatus]="campaign.campaignStatus"
              ></app-updates>
            </ng-container>

            <!-- Q&A Tab -->
            <ng-container *ngIf="selectedTab == 4">
              <ng-container *ngIf="isLogin; else loginToView">
                <div
                  *ngIf="campaignInfo?.foundersUrl"
                  class="tw-mb-6 tw-flex tw-items-center tw-justify-start tw-gap-2 tw-rounded-md tw-bg-info-light tw-p-4 tw-opacity-80"
                >
                  <div class="pitchin-icon pitchin-icon-calendar"></div>
                  <div
                    class="tw-text-base tw-font-semibold tw-text-black sm:tw-text-xl"
                    [innerHTML]="
                      'campaign.connectWithFoundersBanner'
                        | translate: { foundersUrl: campaignInfo?.foundersUrl }
                        | trustSrc
                    "
                  ></div>
                </div>
                <ng-container
                  *ngIf="isInvitedToInvest || campaign?.isCreator; else registerToInvest"
                >
                  <app-qa-list
                    [companyName]="campaign.name"
                    [campaignId]="campaign.id"
                    (onUpdate)="getCampaignInfo()"
                  ></app-qa-list>
                </ng-container>
              </ng-container>
            </ng-container>

            <!-- Events Tab -->
            <ng-container *ngIf="selectedTab == 5">
              <app-events
                [campaignId]="campaign.id"
                [isCreator]="campaign.isCreator"
                (onUpdate)="getCampaignInfo()"
              ></app-events>
            </ng-container>

            <!-- Investor List -->
            <ng-container *ngIf="selectedTab == 11 && getTabVisibility(11)">
              <ng-container *ngIf="isLogin; else loginToView">
                <ng-container
                  *ngIf="isInvitedToInvest || campaign?.isCreator; else registerToInvest"
                >
                  <app-campaign-investor-list
                    [campaign]="campaign"
                    (onInvestorListLoaded)="getInvestorCount()"
                  ></app-campaign-investor-list>
                </ng-container>
              </ng-container>
            </ng-container>

            <!-- [Ended campaign: Campaign Performance & Campaign Business Info] -->
            <ng-container *ngIf="selectedTab == 12">
              <campaign-performance [campaign]="campaign"></campaign-performance>

              <section
                *ngIf="campaignInfo?.partners?.length"
                class="tw-mb-4 tw-mt-8 xl:tw-w-9/12 xl:tw-pr-4"
              >
                <h3 class="tw-mb-4 tw-text-base tw-font-bold tw-uppercase tw-text-primary">
                  {{ 'partnership.inPartnershipWith' | translate }}
                </h3>
                <div class="tw-flex tw-flex-col tw-gap-4 tw-rounded-md">
                  <article
                    class="tw-flex tw-w-full tw-gap-4 tw-bg-gray-100 tw-p-6"
                    *ngFor="let partner of campaignInfo.partners"
                  >
                    <img
                      [src]="partner?.partnerLogoAttachment?.url ?? defaultLogo"
                      [alt]="partner.partnerName"
                      class="tw-h-24 tw-w-24 tw-min-w-24 tw-rounded-lg"
                    />
                    <div>
                      <h5 class="tw-m-0 tw-text-lg tw-font-bold">{{ partner.partnerName }}</h5>
                      <p class="ck-content tw-m-0 tw-line-clamp-2 tw-text-base tw-text-opacity-90">
                        {{ partner.description }}
                      </p>
                      <a
                        [routerLink]="['/partner', partner.micrositeSlug]"
                        *ngIf="partner.micrositeSlug"
                        class="tw-normal-case tw-text-link hover:tw-text-link hover:tw-underline"
                        >{{ 'common.viewMore' | translate }} &#x2192;</a
                      >
                    </div>
                  </article>
                </div>
              </section>

              <div class="tw-mt-10">
                <campaign-business-info
                  *ngIf="isCampaignEnded"
                  [businessInfo]="businessDirectoryInfo"
                ></campaign-business-info>
              </div>
            </ng-container>
          </div>

          <!-- Right panel -->
          <campaign-investment-details
            *ngIf="!isCampaignEnded && ![8].includes(selectedTab)"
            [campaignInfo]="campaignInfo"
            class="tw-shrink-0 md:tw-w-72"
            [campaign]="campaign"
          ></campaign-investment-details>
        </div>
      </div>

      <campaign-footer [campaign]="campaign"></campaign-footer>
    </ng-container>

    <ng-container *ngIf="isCampaignEnded">
      <ng-container *ngIf="!isMyInvestmentLoading; else skeletonLoading">
        <!-- For Success/Failed/Rejected campaigns -->
        <ng-container>
          <campaign-histories
            [campaign]="campaign"
            [slug]="campaign?.company?.slug"
          ></campaign-histories>
        </ng-container>

        <ng-container>
          <hr />

          <div class="container-fluid">
            <section class="tw-pb-20 tw-pt-5 lg:tw-pt-10">
              <campaign-business-overview [campaign]="campaign"></campaign-business-overview>
            </section>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="notFoundPage">
  <app-not-found-page></app-not-found-page>
</ng-container>

<ng-template #skeletonLoading>
  <div class="container-fluid tw-pb-20">
    <app-skeleton [width]="'60%'" [height]="2.5" [marginBottom]="1.25"></app-skeleton>
    <app-skeleton></app-skeleton>
    <app-skeleton></app-skeleton>
    <app-skeleton [width]="'30%'"></app-skeleton>
  </div>
</ng-template>

<ng-template #loginToView>
  <app-empty-state
    [message]="'common.pleaseLoginAsInvestor'"
    [isRequireLogin]="true"
    [loginRedirectionQueryString]="'selectedTab=' + selectedTab"
  ></app-empty-state>
</ng-template>

<ng-template #registerToInvest>
  <app-empty-state
    [title]="'common.inviteOnlyEmptyStateTitle'"
    [message]="'common.inviteOnlyEmptyStateDescription'"
    [ctaUrl]="!isIssuer ? campaign?.registrationLinkUrl : null"
    [ctaText]="!isIssuer ? 'common.registerInterest' : null"
  >
  </app-empty-state>
</ng-template>
