<div class="tw-mb-6 tw-rounded-b-xl tw-shadow-xl">
  <swiper
    #swiper
    [config]="config"
    class="testimonial-swiper"
    [slidesPerView]="1"
    [spaceBetween]="10"
    [pagination]="{ clickable: true }"
    (slideChange)="onSlideChange($event)"
  >
    <ng-template swiperSlide *ngFor="let testimonial of testimonialSlider; let i = index">
      <div
        class="tw-rounded-t-xl tw-bg-[length:50%] tw-bg-no-repeat max-md:!tw-bg-none md:tw-bg-[length:30%] md:tw-bg-right-bottom md:tw-pb-0 lg:tw-bg-[length:30%]"
        [style.background-image]="'url(assets/img/banner/' + testimonial.backgroundImage + ')'"
        [style.background-color]="testimonial.backgroundColour"
      >
        <div class="tw-gap-0 md:tw-flex lg:tw-gap-6">
          <div class="testimonial tw-basis-full tw-p-8 tw-pb-0 md:tw-p-12 md:tw-pr-8 lg:tw-p-12">
            <p class="tw-text-white">
              {{ testimonial.testimonial }}
            </p>
            <div class="tw-mb-8 tw-flex tw-items-center tw-gap-4 md:tw-mb-0">
              <div class="tw-flex tw-h-12 tw-w-12 tw-shrink-0 tw-overflow-hidden tw-rounded-full">
                <img
                  src="assets/img/campaign/{{ testimonial.authourAvatar }}"
                  alt="{{ testimonial.companyName }} authour avatar"
                />
              </div>
              <div>
                <strong class="tw-text-white">{{ testimonial.authourName }}</strong>
                <p class="tw-mb-0 tw-text-white">
                  {{ testimonial.authourRole }}, {{ testimonial.companyName }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="tw-basis-full tw-from-current tw-from-40% tw-to-transparent tw-p-8 tw-pt-0 md:tw-bg-gradient-to-r md:tw-p-12 md:tw-pl-8 lg:tw-p-12"
            [style.color]="testimonial.backgroundColour"
          >
            <p class="tw-font-bold tw-text-white" [innerHTML]="testimonial.caseStudyContent"></p>
          </div>
        </div>
      </div>
    </ng-template>
  </swiper>
  <div class="tw-flex tw-items-center tw-justify-between tw-gap-10 tw-overflow-x-auto tw-p-4">
    <div *ngFor="let testimonial of testimonialSlider; let i = index">
      <div
        (click)="onButtonClick(i)"
        [ngClass]="
          selectedIndex === i ? 'tw-opacity-100 tw-grayscale-0' : 'tw-opacity-50 tw-grayscale'
        "
      >
        <img
          src="assets/img/logo/{{ testimonial.companyLogoUrl }}"
          alt="{{ testimonial.companyName }} logo"
          class="tw-max-w-[200px] tw-cursor-pointer"
        />
      </div>
    </div>
  </div>
</div>
