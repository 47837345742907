import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'product-badge',
  templateUrl: './product-badge.component.html',
  styleUrls: ['./product-badge.component.scss'],
})
export class ProductBadgeComponent {
  @Input() name: 'ecf' | 'pstx' | 'ieo' = 'ecf';
  @Input() size: 'sm' | 'md' | 'lg' = 'md'; // For pstx only atm

  badges = {
    ecf: {
      redirect: '/equity',
    },
    pstx: {
      redirect: '/pstx',
    },
    ieo: {
      redirect: environment.ieoBaseUrl,
    },
  };
}
