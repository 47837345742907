<section class="section section-shaped">
  <div class="container">
    <div class="row mb-3">
      <div class="col-12">
        <div
          class="text-primary text-underline cursor-pointer back-button h5"
          (click)="investmentEddFormId ? redirect() : confirmCancel()"
        >
          {{ 'common.cancel' | translate }}
        </div>
      </div>
      <div class="col-12 text-center">
        <h2 class="font-weight-extrabold">{{ 'investment.requestForMoreInfo' | translate }}</h2>
      </div>
      <div class="col-12 text-justify mb-4">{{ 'investment.eddFormDescription' | translate }}</div>
    </div>
    <form
      *ngIf="!isLoading"
      (ngSubmit)="submit()"
      [formGroup]="form"
      [class.show-form-error]="showFormError"
    >
      <div class="row">
        <div class="col-lg-12 input-panel">
          <div class="input-label">{{ 'investment.sourceOfWealth' | translate }}*</div>
          <mat-selection-list
            formControlName="sourceOfWealths"
            (selectionChange)="
              changeValidity(form.value.sourceOfWealths.includes(othersId), 'othersSourceOfWealths')
            "
          >
            <mat-list-option
              class="list-option"
              *ngFor="let type of sourceOfWealths"
              [value]="type.id"
              checkboxPosition="before"
              [disableRipple]="true"
            >
              {{ type.name }}
            </mat-list-option>
          </mat-selection-list>
          <input
            *ngIf="form.value.sourceOfWealths.includes(othersId)"
            [readonly]="investmentEddFormId"
            class="form-control mt-2 other-souce"
            formControlName="othersSourceOfWealths"
            type="text"
            placeholder="{{ 'common.pleaseSpecify' | translate }}"
          />
          <div
            class="text-danger"
            *ngIf="showFormError && form.controls.sourceOfWealths.errors?.required"
          >
            {{ 'common.pleaseSelect' | translate }}
          </div>
        </div>
        <div class="col-lg-12 input-panel pb-0">
          <div class="input-label">{{ 'investment.sourceOfWealthDocument' | translate }}*</div>
        </div>
        <div class="col-lg-6 input-panel">
          <ng-container *ngIf="!investmentEddFormId">
            <div class="input-field" *ngIf="!isLoadingWealth">
              <div
                class="document-upload cursor-pointer"
                agmoUploader
                [accept]="accept"
                (selectFiles)="selectFile(1, $event)"
                *ngIf="!sourceOfWealthDoc"
              >
                <div class="document-text text-left">{{ 'signUp.uploadDocument' | translate }}</div>
                <div class="pitchin-icon pitchin-icon-upload"></div>
              </div>
              <div class="document-upload uploaded" *ngIf="sourceOfWealthDoc">
                <div class="document-text text-left">{{ sourceOfWealthDoc.name }}</div>
                <div class="pitchin-icon pitchin-icon-delete" (click)="removeDocument(1)"></div>
              </div>
            </div>
            <agmo-loading *ngIf="isLoadingWealth"></agmo-loading>
            <div
              class="text-danger"
              *ngIf="showFormError && !sourceOfWealthAttachmentInfo.attachmentInfo"
            >
              {{ 'common.pleaseUploadDocument' | translate }}
            </div>
          </ng-container>
          <div
            class="link"
            *ngIf="investmentEddFormId && edd && edd.sourceOfWealthAttachmentUrl"
            (click)="viewDocument(edd.sourceOfWealthAttachmentUrl)"
          >
            {{ 'common.viewDocument' | translate }}
          </div>
        </div>
        <div class="col-lg-12 input-panel">
          <div class="input-label">{{ 'investment.sourceOfFunds' | translate }}*</div>
          <mat-selection-list
            formControlName="sourceOfFunds"
            (selectionChange)="
              changeValidity(form.value.sourceOfFunds.includes(othersId), 'othersSourceOfFunds')
            "
          >
            <mat-list-option
              class="list-option"
              *ngFor="let type of sourceOfFunds"
              [value]="type.id"
              checkboxPosition="before"
              [disableRipple]="true"
            >
              {{ type.name }}
            </mat-list-option>
          </mat-selection-list>
          <input
            *ngIf="form.value.sourceOfFunds.includes(othersId)"
            [readonly]="investmentEddFormId"
            class="form-control mt-2 other-souce"
            formControlName="othersSourceOfFunds"
            type="text"
            placeholder="{{ 'common.pleaseSpecify' | translate }}"
            maxlength="255"
          />
          <div
            class="text-danger"
            *ngIf="showFormError && form.controls.sourceOfFunds.errors?.required"
          >
            {{ 'common.pleaseSelect' | translate }}
          </div>
        </div>
        <div class="col-lg-12 input-panel pb-0">
          <div class="input-label">{{ 'investment.sourceOfFundsDocument' | translate }}*</div>
        </div>
        <div class="col-lg-6 input-panel">
          <ng-container *ngIf="!investmentEddFormId">
            <div class="input-field" *ngIf="!isLoadingFund">
              <div
                class="document-upload cursor-pointer"
                agmoUploader
                [accept]="accept"
                (selectFiles)="selectFile(2, $event)"
                *ngIf="!sourceOfFundsDoc"
              >
                <div class="document-text text-left">{{ 'signUp.uploadDocument' | translate }}</div>
                <div class="pitchin-icon pitchin-icon-upload"></div>
              </div>
              <div class="document-upload uploaded" *ngIf="sourceOfFundsDoc">
                <div class="document-text text-left">{{ sourceOfFundsDoc.name }}</div>
                <div class="pitchin-icon pitchin-icon-delete" (click)="removeDocument(2)"></div>
              </div>
            </div>
            <agmo-loading *ngIf="isLoadingFund"></agmo-loading>
            <div
              class="text-danger"
              *ngIf="showFormError && !sourceOfFundAttachmentInfo.attachmentInfo"
            >
              {{ 'common.pleaseUploadDocument' | translate }}
            </div>
          </ng-container>
          <div
            class="link"
            *ngIf="investmentEddFormId && edd && edd.sourceOfFundAttachmentUrl"
            (click)="viewDocument(edd.sourceOfFundAttachmentUrl)"
          >
            {{ 'common.viewDocument' | translate }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 input-panel pb-0">
          <div class="input-label">{{ 'investment.relationshipToIssuer' | translate }}*</div>
        </div>
        <div class="col-lg-6 input-panel">
          <div class="input-field">
            <mat-select
              placeholder="{{ 'common.selectPlaceholder' | translate }}"
              class="form-control"
              formControlName="relationshipOfInvestorToTheIssuerType"
            >
              <mat-option
                [value]="relation.id"
                *ngFor="let relation of relationshipOfInvestorToTheIssuerType"
              >
                {{ relation.name }}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <div class="col-lg-12 input-panel mb-3">
          <div class="input-label">{{ 'investment.thirdPartyQuestion' | translate }}*</div>
          <div class="input-field">
            <mat-radio-group
              formControlName="isThirdPartyAccount"
              [disabled]="investmentEddFormId"
              #isThirdPartyAccount
              (change)="updateDepositFormValidation(this.form.controls.isThirdPartyAccount.value)"
            >
              <mat-radio-button [value]="true">{{ 'common.yes' | translate }}</mat-radio-button>
              <mat-radio-button [value]="false">{{ 'common.no' | translate }}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <ng-container *ngIf="form.value.isThirdPartyAccount">
        <h4 class="font-weight-bold text-danger mb-3">
          {{ 'investment.depositDetails' | translate }}
        </h4>
        <div class="text-justify mb-4">
          {{ 'investment.depositDetailsDescription' | translate }}
        </div>
        <div class="row">
          <div class="col-lg-12 input-panel pb-0">
            <div class="input-label">
              {{ 'investment.thirdPartyAccountOwnerName' | translate }}*
            </div>
          </div>
          <div class="col-lg-6 input-panel">
            <div class="input-field">
              <input
                class="form-control"
                formControlName="name"
                type="text"
                [readonly]="investmentEddFormId"
                maxlength="100"
              />
            </div>
          </div>
          <div class="col-lg-12 input-panel pb-0">
            <div class="input-label">{{ 'profile.phoneNo' | translate }}*</div>
          </div>
          <div class="col-lg-6 input-panel">
            <div class="input-field phone-input-field">
              <mat-select class="form-control countryCode" formControlName="phoneCountryCode">
                <mat-select-trigger>
                  {{ form.value.phoneCountryCode }}
                </mat-select-trigger>
                <mat-option *ngFor="let country of countries" [value]="country.phonecode">
                  {{ country.name }} ({{ country.phonecode }})
                </mat-option>
              </mat-select>
              <input
                class="form-control"
                formControlName="phoneNumber"
                type="text"
                digitOnly
                [readonly]="investmentEddFormId"
              />
            </div>
            <div
              class="error"
              *ngIf="
                form.controls.phoneNumber.errors &&
                form.controls.phoneNumber.errors.phoneInvalidFormat &&
                showFormError
              "
            >
              {{ 'common.incorrectPhoneNoFormat' | translate }}
            </div>
          </div>
          <div class="col-lg-12 input-panel pb-0">
            <div class="input-label">{{ 'investment.nricpassportRegNo' | translate }}*</div>
          </div>
          <div class="col-lg-6 input-panel">
            <div class="input-field">
              <input
                class="form-control"
                formControlName="identityNo"
                type="text"
                [readonly]="investmentEddFormId"
              />
            </div>
          </div>
          <div class="col-lg-12 input-panel pb-0">
            <div class="input-label">{{ 'investment.correspondenceAddress' | translate }}*</div>
          </div>
          <div class="col-lg-6 input-panel">
            <div class="input-field">
              <input
                class="form-control"
                formControlName="address"
                type="text"
                [readonly]="investmentEddFormId"
                maxlength="512"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 input-panel pb-0">
            <div class="input-label">
              {{ 'investment.natureRelationshipToThirdParty' | translate }}*
            </div>
          </div>
          <div class="col-lg-6 input-panel">
            <div class="input-field">
              <mat-select
                placeholder="{{ 'common.selectPlaceholder' | translate }}"
                class="form-control"
                formControlName="eddNatureOfRelationshipWithThirdParty"
                (selectionChange)="
                  changeValidity(
                    form.value.eddNatureOfRelationshipWithThirdParty == othersId,
                    'eddNatureOfRelationshipWithThirdPartyOthers'
                  )
                "
              >
                <mat-option
                  [value]="relation.id"
                  *ngFor="let relation of eddNatureOfRelationshipWithThirdParty"
                >
                  {{ relation.name }}
                </mat-option>
              </mat-select>
              <input
                *ngIf="form.value.eddNatureOfRelationshipWithThirdParty == othersId"
                class="form-control mt-2"
                formControlName="eddNatureOfRelationshipWithThirdPartyOthers"
                type="text"
                placeholder="{{ 'common.pleaseSpecify' | translate }}"
                maxlength="256"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 input-panel pb-0">
            <div class="input-label">
              {{ 'investment.reasonUsingThirdPartyAccount' | translate }}*
            </div>
          </div>
          <div class="col-lg-6 input-panel">
            <div class="input-field">
              <mat-select
                placeholder="{{ 'common.selectPlaceholder' | translate }}"
                class="form-control"
                formControlName="eddReasonForUsingThirdPartyAccount"
                (selectionChange)="
                  changeValidity(
                    form.value.eddReasonForUsingThirdPartyAccount == othersId,
                    'eddReasonForUsingThirdPartyAccountOthers'
                  )
                "
              >
                <mat-option
                  [value]="reason.id"
                  *ngFor="let reason of eddReasonForUsingThirdPartyAccount"
                >
                  {{ reason.name }}
                </mat-option>
              </mat-select>
              <input
                *ngIf="form.value.eddReasonForUsingThirdPartyAccount == othersId"
                class="form-control mt-2"
                formControlName="eddReasonForUsingThirdPartyAccountOthers"
                type="text"
                placeholder="{{ 'common.pleaseSpecify' | translate }}"
                maxlength="1024"
              />
            </div>
          </div>
        </div>
      </ng-container>
      <div class="row mt-5">
        <div class="col-12 text-center" *ngIf="!investmentEddFormId">
          <button type="submit" class="btn btn-primary btn-submit">
            {{ 'common.submit' | translate }}
          </button>
        </div>
        <div class="col-12">
          <div
            class="text-primary text-underline cursor-pointer back-button h5"
            (click)="investmentEddFormId ? redirect() : confirmCancel()"
          >
            {{ 'common.cancel' | translate }}
          </div>
        </div>
      </div>
    </form>
    <agmo-loading *ngIf="isLoading"></agmo-loading>
  </div>
</section>
