import * as moment from 'moment';
import { BonusModel } from './bonus.model';
import { OfferCreation } from './offer-creation';

export class OfferCreationModel {
  Approve: boolean;
  ApproveBonusShare: boolean;
  BonusPeriodStartDate: string;
  BonusPeriodEndDate: string;
  BonusShares: BonusModel[];
  EnableBonusShare: boolean;

  constructor(model: OfferCreation, approve: boolean, approveBonusShare: boolean) {
    this.Approve = approve;
    this.ApproveBonusShare = approveBonusShare;
    this.EnableBonusShare = model.enableBonusShare;
    this.BonusPeriodStartDate = moment(model.bonusPeriodStartDate).toISOString();
    this.BonusPeriodEndDate = moment(model.bonusPeriodEndDate).toISOString();
    this.BonusShares =
      model.bonusShares && model.bonusShares.length > 0
        ? model.bonusShares.map((bonusShare) => new BonusModel(bonusShare))
        : [];
  }
}
