import { Injectable } from '@angular/core';
import { PopupService } from '../../shared/services/popup/popup.service';
import { FileTypeMime } from '../models/attachment/file-type-mime';
import { FileUploadOption } from '../models/attachment/file-upload-option';
import {
  ExcelFileFormat,
  FileExtension,
  FileNameLimit,
  FileSizeLimit,
  FileType,
  ImageAndPdfFileFormat,
  ImageFileFormat,
  PdfAndExcelFileFormat,
  PDFFileFormat,
  VideoFileFormat,
  PdfAndXlsxFileFormat,
} from './constant';

@Injectable()
export class FileValidationService {
  constructor(private popupService: PopupService) {}

  async validFile(file: File, option: FileUploadOption) {
    const validFileType = this.validFileType(file, option);
    if (!validFileType) {
      return;
    }

    const validSign = await this.validSignature(file, option);
    return validFileType && validSign;
  }

  validFileType(file: File, option: FileUploadOption): boolean {
    // file require validation
    if (file == null) {
      this.popupService.alert('common.fileIsRequired');
      return false;
    }

    const fileType = this.getFileType(file);
    if (!fileType) {
      this.popupService.alert(option.errorMsg || 'common.invalidFileFormat');
      return false;
    }

    // file name sanitize validation
    const sanitize = require('sanitize-filename');
    if (sanitize(file.name) !== file.name) {
      this.popupService.alert('common.fileNameInvalid');
      return false;
    }

    // file name multi extension validation
    let dots = [];
    for (var i = 0; i < file.name.length; i++) {
      if (file.name[i] == '.') {
        dots.push(i);
      }
    }
    if (dots.length > 1) {
      this.popupService.alert('common.fileNameInvalid');
      return false;
    }

    // file name limit validation
    if (file.name.length > (option.fileNameLimit || FileNameLimit)) {
      this.popupService.alert('common.fileNameInvalid');
      return false;
    }

    // file size limit validation
    if (file.size > (option.fileSizeLimit || FileSizeLimit)) {
      this.popupService.alert('common.fileSizeLimit');
      return false;
    }

    // file content-type validation
    if (!option.fileType.includes(file.type)) {
      this.popupService.alert(option.errorMsg || 'common.invalidFileFormat');
      return false;
    }

    // file extension validation & mime sync checking
    if (!fileType.ext.includes(FileExtension.exec(file.name)[1].toLowerCase())) {
      this.popupService.alert(option.errorMsg || 'common.invalidFileFormat');
      return false;
    }

    return true;
  }

  validSignature(file: File, option: FileUploadOption) {
    return new Promise((resolve, reject) => {
      const fileType = this.getFileType(file);

      if (!fileType) {
        this.popupService.alert(option.errorMsg || 'common.invalidFileFormat');
        resolve(false);
        return;
      }

      if (fileType.mime == 'text/csv') {
        resolve(true);
        return;
      }

      var fileReader = new FileReader();
      fileReader.onloadend = (e: any) => {
        var arr = new Uint8Array(e.target.result as ArrayBuffer).subarray(
          fileType.rules.start,
          fileType.rules.end,
        );
        var header = '';

        for (var i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }

        if (!fileType.sign.includes(header)) {
          this.popupService.alert(option.errorMsg || 'common.invalidFileFormat');
        }
        resolve(fileType.sign.includes(header));
      };
      fileReader.readAsArrayBuffer(file);
    });
  }

  getFileType(file: File): FileTypeMime {
    return FileType.find(function (data) {
      return data.mime == file.type;
    });
  }

  errorMessage(fileTypes: string[]): string {
    return fileTypes == PDFFileFormat
      ? 'common.onlyPdfAccepted'
      : fileTypes == ExcelFileFormat
        ? 'common.onlyXlsxAccepted'
        : fileTypes == ImageFileFormat
          ? 'common.onlyImageAccepted'
          : fileTypes == VideoFileFormat
            ? 'common.onlyMp4Accepted'
            : fileTypes == ImageAndPdfFileFormat
              ? 'common.onlyImageAndPdfAccepted'
              : fileTypes == PdfAndExcelFileFormat
                ? 'common.onlyPdfAndExcelAccepted'
                : fileTypes == PdfAndXlsxFileFormat
                  ? 'common.onlyPdfAndXlasxAccepted'
                  : 'common.invalidFileFormat';
  }
}
