import { TradingType } from '../../enum/mysec/trading-type.enum';
import { TradeModel } from './trade.model';

export class Trade {
  price: number;
  quantity: number;
  time: string;
  type: TradingType;
  color: number;

  constructor(model: TradeModel) {
    this.price = model.price;
    this.quantity = model.quantity;
    this.time = model.createdAt;
    this.type = model.type;
    this.color = model.color;
  }
}
