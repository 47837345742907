<div class="container-fluid p-0">
  <!-- Note: PLT-554 Hide the tabs till further notice -->
  <!-- <nav class="navbar navbar-expand-md navbar-light navbar-content">
        <ul class="navbar-nav mr-auto">
            <ng-container *ngFor="let tab of tabs">
                <li class="nav-item" [class.active]="selectedTab === tab.route"
                    *ngIf="!tab.show || tab.show(storageService.role)">
                    <a *ngIf="!tab.isExternal" 
                        class="nav-link" [routerLink]="tab.route" (click)="changeTab(tab.route)">
                        {{ tab.name | translate }}
                    </a>
                    <a *ngIf="tab.isExternal" 
                        class="nav-link" [href]="tab.url">
                        {{ tab.name | translate }}
                    </a>
                </li>
            </ng-container>
        </ul>
    </nav> -->
  <div class="tab-content">
    <router-outlet></router-outlet>
  </div>
</div>
