<section class="section section-shaped">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-2 progress-step" [class.active]="step == 1">
            <div
              class="pitchin-icon pitchin-icon-company"
              [class.pitchin-icon-check-green]="step > 1"
            ></div>
            <div class="progress-title" [class.text-primary]="step >= 1">
              {{ 'company.company' | translate }}
            </div>
          </div>
          <div class="col-lg-1 p-0 px-3 d-none d-lg-flex">
            <div class="progress-line"></div>
          </div>
          <div class="col-lg-2 progress-step" [class.active]="step == 2">
            <div
              class="pitchin-icon pitchin-icon-coins"
              [class.pitchin-icon-check-green]="step > 2"
            ></div>
            <div class="progress-title" [class.text-primary]="step >= 2">
              {{ 'registerInvestor.investorType' | translate }}
            </div>
          </div>
          <div class="col-lg-1 p-0 px-3 d-none d-lg-flex">
            <div class="progress-line"></div>
          </div>
          <div class="col-lg-3 progress-step" [class.active]="step == 3">
            <div
              class="pitchin-icon pitchin-icon-representative"
              [class.pitchin-icon-check-green]="step > 2"
            ></div>
            <div class="progress-title" [class.text-primary]="step >= 3">
              {{ 'signUp.representative' | translate }}
            </div>
          </div>
          <div class="col-lg-1 p-0 px-3 d-none d-lg-flex">
            <div class="progress-line"></div>
          </div>
          <div class="col-lg-2 progress-step" [class.active]="step == 4">
            <div class="pitchin-icon pitchin-icon-documents"></div>
            <div class="progress-title" [class.text-primary]="step >= 4">
              {{ 'company.documents' | translate }}
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-5" *ngIf="step == 1">
          <div class="col-lg-12">
            <h2 class="text-center font-weight-extrabold">
              {{ 'company.companyInfo' | translate }}
            </h2>
            <div class="text-center mb-4">{{ 'company.completeCompanyProfile' | translate }}</div>
            <app-company
              [company]="company"
              [isUpdate]="false"
              (submitForm)="submitCompany($event)"
            >
            </app-company>
          </div>
        </div>
        <div class="row justify-content-center mt-5" *ngIf="step == 2">
          <div class="col-lg-12">
            <h2 class="text-center font-weight-extrabold">
              {{ 'registerInvestor.investorType' | translate }}
            </h2>
            <div class="text-center mb-5">
              {{ 'registerInvestor.selectInvestmentProfile' | translate }}
            </div>
            <app-investor-profile
              [investorProfile]="investorProfile"
              [isCompanyRep]="true"
              [isSettings]="false"
              (submitForm)="submitCorporateProfile($event)"
            ></app-investor-profile>
          </div>
        </div>
        <div class="row justify-content-center mt-5" *ngIf="step == 3">
          <div class="col-lg-8">
            <app-issuer-representative [role]="Role.CompanyRep" (submitForm)="registerRep($event)">
            </app-issuer-representative>
          </div>
        </div>
        <div class="row justify-content-center mt-5" *ngIf="step == 4">
          <div class="col-lg-12">
            <h2 class="text-center font-weight-extrabold">
              {{ 'signUp.uploadDocuments' | translate }}
            </h2>
            <div class="text-center mb-5">
              {{ 'signUp.uploadDocumentDescBusiness' | translate }}
            </div>
            <app-documents
              [attachments]="attachments"
              (uploadDocument)="uploadCompanyDocument($event)"
              (deleteDocument)="deleteCompanyDocument($event)"
              (uploadOtherDocument)="uploadDocument($event)"
            >
            </app-documents>
            <div class="text-center pt-5">
              <div (click)="submit()" class="btn btn-primary">
                {{ 'common.submit' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
