import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-alert-bar',
  templateUrl: './alert-bar.component.html',
  styleUrls: ['./alert-bar.component.scss'],
})
export class AlertBarComponent implements OnChanges {
  @Input() title: string;
  @Input() type: 'success' | 'error' | 'warning' | 'info' = 'info';
  @Input() align: 'top' | 'center' | 'bottom' = 'center';
  @Input() size: 'sm' | 'lg' = 'lg';

  backgroundClass: string = 'tw-bg-info-lightest';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['type']) {
      switch (changes['type'].currentValue) {
        case 'success':
          this.backgroundClass = 'tw-bg-secondary-lightest';
          break;

        case 'error':
          this.backgroundClass = 'tw-bg-primary-light';
          break;

        case 'warning':
          this.backgroundClass = 'tw-bg-warning-light';
          break;

        default:
          this.backgroundClass = 'tw-bg-info-lightest';
      }
    }
  }
}
