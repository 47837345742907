import { CampaignStatus } from '../../../core/enum/campaign-status.enum';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CampaignSectionFilter } from '../../../pages/landing/campaign-section/campaign-section-filter';
import { SavedOffersFilter } from '../../../pages/saved-offers/saved-offers-filter';
import * as moment from 'moment';
import { DefaultBanner, DefaultLogo } from '../../../core/services/constant';
import { Campaign } from '../../../core/models/campaign/campaign';

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.scss'],
})
export class CampaignListComponent implements OnChanges {
  @Input() campaigns: Campaign[];
  @Input() total: number = 0;
  @Input() params: CampaignSectionFilter | SavedOffersFilter;
  @Input() isLoading: boolean;
  @Input() displayPagination: boolean = true;
  @Input() noRecordText: string = 'common.noRecord';
  @Output() getCampaignList: EventEmitter<CampaignSectionFilter | SavedOffersFilter> =
    new EventEmitter();
  defaultBanner = DefaultBanner;
  defaultLogo = DefaultLogo;
  isToday = false;

  CampaignStatus: typeof CampaignStatus = CampaignStatus;

  constructor(private router: Router) {}

  ngOnChanges() {
    const tomorrow = moment().add(1, 'day').startOf('day');

    this.campaigns.forEach((campaign) => {
      const { company, endAt } = campaign;
      const isShariah = company?.sectors?.some((s) => s?.name === 'Shariah') ?? false;

      campaign.isShariah = isShariah;
      campaign.isEnded = moment().isAfter(moment(endAt));
      campaign.isToday = moment().isSame(moment(endAt), 'day');
      campaign.isTomorrow = moment(tomorrow).isSame(moment(endAt), 'day');
    });
  }

  getList() {
    this.getCampaignList.emit(this.params);
  }

  processLinks(e) {
    const element: HTMLElement = e.target;

    if (element?.nodeName === 'A') {
      e.preventDefault();
      const link = element.getAttribute('href');
      this.router.navigate([link]);
    }
  }
}
