<div class="tw-mb-8 tw-overflow-hidden tw-bg-primary">
  <div class="container-fluid bg-img tw-relative">
    <div class="tw-grid tw-pb-14 tw-pt-14 tw-text-white sm:tw-grid-cols-5 lg:tw-pb-20">
      <div class="sm:tw-col-span-3">
        <h1 class="section-title font-weight-extrabold tw-text-white">
          {{ 'campaign.SuccessFundedTitle' | translate }}
        </h1>
        <p class="tw-mb-0">
          {{ 'campaign.SuccessFundedDescription' | translate }}

          <a
            class="tw-font-medium tw-text-white tw-underline hover:tw-text-white"
            [routerLink]="['/raise']"
          >
            {{ 'campaign.SuccessFundedCTA' | translate }}</a
          >
          <i class="tw-pl-1 tw-not-italic">→</i>
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <app-campaign-filters
    class="tw-mb-2 tw-inline-block tw-w-full"
    [params]="params"
    (paramsChange)="updateUrl($event)"
  ></app-campaign-filters>

  <div class="tw-mb-5 tw-mt-3">
    <app-campaign-list
      [campaigns]="campaigns"
      [params]="params"
      [total]="total"
      [isLoading]="isLoading"
      (getCampaignList)="updateUrl($event)"
    ></app-campaign-list>
  </div>
</div>
