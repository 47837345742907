<section class="section section-shaped">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 text-center">
        <img
          class="icon"
          alt="pitchIN e-KYC"
          src="/assets/img/icons/common/checkmark_done_icon@2x.png"
        />
        <h2 class="text-center font-weight-extrabold">{{ 'signUp.ekycInProgress' | translate }}</h2>
        <div class="text-center success-message mb-5">
          {{ 'signUp.ekycInProgressMessage' | translate }}
        </div>
      </div>
    </div>
  </div>
</section>
