<app-announcement-bar></app-announcement-bar>

<app-navbar *ngIf="showUI"></app-navbar>

<div class="main-container">
  <router-outlet></router-outlet>
</div>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

<app-footer *ngIf="showUI" [isExpandFooter]="isExpandFooter"></app-footer>

<div
  class="pitchin-icon pitchin-icon-up-arrow"
  [class.mobile-icon]="deviceType != DeviceType.Web"
  [class.m-fadeOut]="!showScrollUp"
  [class.m-fadeIn]="showScrollUp"
  [class.expand-footer]="isExpandFooter"
  (click)="scrollUp()"
></div>
