import { TermModel } from '../share/term.model';
import { CampaignInfo } from './campaign-info';

export class CampaignInfoModel {
  Name: string;
  VideoUrl: string;
  CampaignId: number;
  Summary: string;
  Problem: string;
  Solution: string;
  Product: string;
  Traction: string;
  Customers: string;
  BusinessModel: string;
  Market: string;
  Competition: string;
  Funding: string;
  Vision: string;
  Founders: string;
  Team: string;
  InvestmentTerms: string;
  Disclosure: string;
  CampaignFAQs: TermModel[];
  CampaignRisks: TermModel[];

  constructor(model: CampaignInfo) {
    this.CampaignId = model.campaignId;
    this.Name = model.name;
    this.VideoUrl = model.videoUrl;
    this.Summary = model.summary;
    this.Problem = model.problem;
    this.Solution = model.solution;
    this.Product = model.product;
    this.Traction = model.traction;
    this.Customers = model.customers;
    this.BusinessModel = model.businessModel;
    this.Market = model.market;
    this.Competition = model.competition;
    this.Funding = model.funding;
    this.Vision = model.vision;
    this.Founders = model.founders;
    this.Team = model.team;
    this.InvestmentTerms = model.investmentTerms;
    this.Disclosure = model.disclosure;
    this.CampaignFAQs =
      model.campaignFAQs && model.campaignFAQs.length > 0
        ? model.campaignFAQs.map((item) => {
            return new TermModel(item);
          })
        : [];
    this.CampaignRisks =
      model.campaignRisks && model.campaignRisks.length > 0
        ? model.campaignRisks.map((item) => {
            return new TermModel(item);
          })
        : [];
  }
}
