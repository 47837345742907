<div class="container-fluid print-include">
  <ng-container *ngIf="receipt">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-8 pt-5 pb-3 title-panel">
        <h2 class="font-weight-extrabold align-self-center mb-0">
          {{ 'investment.paymentReceipt' | translate }}
        </h2>
        <div>
          {{ 'investment.paymentStatusMessage' | translate: { paymentGatewayName: 'FPX' } }}
          {{ receipt.transactionStatus }}!
        </div>
      </div>
    </div>
    <div class="row pb-5 invest-panel justify-content-center">
      <div class="col-lg-6 col-md-8 receipt-panel">
        <div class="mb-3 font-size-smaller">
          {{ receipt.createdAt | amDateFormat: 'D MMM YYYY, hh:mm A' }}
        </div>
        <div class="info mb-4 d-flex">
          <div class="w-75">{{ 'investment.transactionAmount' | translate }}</div>
          <div class="w-25 text-right">{{ receipt.amount | numberSuffix }}</div>
        </div>
        <div class="input-panel">
          <div class="input-label">{{ 'investment.sellerOrderNo' | translate }}</div>
          <div class="input-field">
            {{ receipt.orderNo || '-' }}
          </div>
        </div>
        <div class="input-panel">
          <div class="input-label">{{ 'investment.fpxTransactionNo' | translate }}</div>
          <div class="input-field">
            {{ receipt.transactionId || '-' }}
          </div>
        </div>
        <div class="input-panel">
          <div class="input-label">{{ 'investment.buyerBank' | translate }}</div>
          <div class="input-field">
            {{ receipt.bankName || '-' }}
          </div>
        </div>
        <div class="input-panel">
          <div class="input-label">{{ 'investment.status' | translate }}</div>
          <div
            class="input-field"
            [class.text-green]="receipt.transactionStatus == 'Successful'"
            [class.text-primary]="receipt.transactionStatus != 'Successful'"
          >
            {{ receipt.transactionStatus }}
          </div>
        </div>
      </div>
    </div>
    <div class="row pb-5 justify-content-center print-exclude">
      <div class="col-lg-6 col-md-8 text-center">
        <div class="btn btn-primary font-size-larger" routerLink="/raise/my-application">
          {{ 'common.back' | translate }}
        </div>
        <div class="btn btn-primary-border font-size-larger" (click)="print()">
          {{ 'investment.printReceipt' | translate }}
        </div>
      </div>
    </div>
  </ng-container>
  <agmo-loading *ngIf="!receipt"></agmo-loading>
</div>
