import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TabMenuItem } from '../../core/models/menu/tab-menu-item';
import { Role } from '../../core/enum/role.enum';
import { StorageService } from '../../core/services/storage.service';

@Component({
  selector: 'app-mobile-select-menu',
  templateUrl: './mobile-select-menu.component.html',
  styleUrls: ['./mobile-select-menu.component.scss'],
})
export class MobileSelectMenuComponent implements OnInit {
  @Input() menu: TabMenuItem[];
  @Input() selected: string;
  @Input() icon: string;

  @Output() valueChanged = new EventEmitter<TabMenuItem>();

  selectedParent: TabMenuItem;
  role: Role;

  constructor(private storageService: StorageService) {}

  ngOnInit() {
    if (!this.selectedParent) {
      this.selectedParent = this.getParent(this.menu, this.selected);
    }

    this.role = this.storageService.role;
  }

  getParent(menu: TabMenuItem[], name: string): TabMenuItem {
    for (let item of menu) {
      if (item?.submenus) {
        for (let submenu of item.submenus) {
          if (submenu.name === name) {
            return item;
          }
          if (submenu.submenus) {
            let parentName = this.getParent(menu, name);
            if (parentName) {
              return parentName;
            }
          }
        }
      }
    }
    return null;
  }

  handleChange(menuItem: string) {
    this.valueChanged.emit(JSON.parse(menuItem));
  }
}
