<div class="buy-sell-parent tw-py-8 lg:tw-py-20">
  <div *ngIf="!tutorialContent" class="container-fluid">
    <div class="tw-mx-auto tw-flex tw-max-w-full tw-items-center tw-justify-center">
      <div>
        <div class="tw-mx-auto tw-mb-16 tw-max-w-4xl tw-text-center">
          <p class="p-text-preheader">AUTO-MATCHED ORDERS</p>
          <h3 class="p-text-h3 !tw-font-normal tw-text-[#1a1847]">
            Orders are matched at the best possible price & quantity.
          </h3>
        </div>
      </div>
    </div>
    <div class="tw-mx-auto tw-max-w-full tw-text-center">
      <div
        (click)="setContent('buyer')"
        class="buy-sell-button tw-group tw-relative tw-mx-auto tw-mb-4 tw-h-[100px] tw-max-w-[280px] tw-flex-none tw-rounded-md tw-p-4 tw-text-left tw-shadow-[0_0_0px_2px_black] hover:tw-cursor-pointer hover:tw-bg-primary hover:tw-shadow-none hover:tw-drop-shadow-xl"
      >
        <div class="tw-relative tw-z-[1]">
          <strong class="tw-mb-2 tw-block tw-text-black group-hover:tw-text-white"
            >Buyer &#8594;</strong
          >
          <p class="tw-text-[12px] tw-leading-normal tw-text-black group-hover:tw-text-white">
            See how we auto-match your buy order.
          </p>
        </div>
        <img
          src="/assets/img/icons/common/white-buy-bg.svg"
          alt="hand holding cash"
          class="tw-absolute -tw-top-[1px] tw-right-0 tw-w-[95px] tw-opacity-[15%]"
        />
      </div>
      <div
        (click)="setContent('seller')"
        class="buy-sell-button tw-group tw-relative tw-mx-auto tw-h-[100px] tw-max-w-[280px] tw-flex-none tw-rounded-md tw-p-4 tw-text-left tw-shadow-[0_0_0px_2px_black] tw-delay-75 hover:tw-cursor-pointer hover:tw-bg-primary hover:tw-shadow-none hover:tw-drop-shadow-xl"
      >
        <div class="tw-relative tw-z-[1]">
          <strong class="tw-mb-2 tw-block tw-text-black group-hover:tw-text-white"
            >Seller &#8594;</strong
          >
          <p class="tw-text-[12px] tw-leading-normal tw-text-black group-hover:tw-text-white">
            See how we auto-match your sell order.
          </p>
        </div>
        <img
          src="/assets/img/icons/common/white-sell-bg.svg"
          alt="hand presenting product"
          class="tw-absolute tw-right-0 tw-top-[3px] tw-w-[90px] tw-opacity-[15%]"
        />
      </div>
    </div>
  </div>
  <app-buy-sell-tutorial-content
    *ngIf="tutorialContent == 'buyer'"
    [content]="buyerSellerTutorial.buyer"
    [parentTutorialContent]="tutorialContent"
    class="tw-overflow-x-auto"
  ></app-buy-sell-tutorial-content>
  <app-buy-sell-tutorial-content
    *ngIf="tutorialContent == 'seller'"
    [content]="buyerSellerTutorial.seller"
    [type]="'seller'"
    [parentTutorialContent]="tutorialContent"
    class="tw-overflow-x-auto"
  ></app-buy-sell-tutorial-content>
</div>
