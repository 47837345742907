import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { PopupType } from './popup-type.enum';

@Component({
  selector: 'agmo-popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss'],
})
export class PopupModalComponent implements OnInit {
  PopupType: typeof PopupType = PopupType;
  type: PopupType;
  message: string;
  titleLabel: string;
  okLabel: string;
  cancelLabel: string;
  okClass: string;
  cancelClass: string;
  titleClass: string;
  iconUrl: string;
  showTitle: boolean;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    switch (this.type) {
      case PopupType.success:
        this.iconUrl = '../../../../assets/img/icons/common/check_icon_green@2x.png';
        break;
      case PopupType.confirm:
        this.iconUrl = '../../../../assets/img/icons/common/caution_icon@2x.png';
        break;
      case PopupType.alert:
        this.iconUrl = '../../../../assets/img/icons/common/info_icon_colored.png';
        break;
    }
  }

  ok(): void {
    this.activeModal.close();
  }

  cancel(): void {
    this.activeModal.dismiss();
  }
}
