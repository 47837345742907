<form (ngSubmit)="submit()" [formGroup]="form">
  <div class="row justify-content-center" [class.justify-content-start]="isSettings">
    <div class="col-md-12" [class.col-lg-12]="isSettings">
      <div class="form-group">
        <div
          class="tw-flex tw-flex-wrap lg:tw-flex-row"
          [class.tw-justify-center]="!isSettings || !isCompanyRep"
          [class.tw-justify-start]="isCompanyRep && isSettings"
        >
          <div
            class="col-md-{{ selectedInvestorType.length === 2 ? '5' : '4' }}"
            *ngFor="let investorType of selectedInvestorType"
          >
            <div
              class="card mb-3 tw-cursor-pointer tw-border-2 tw-transition tw-duration-100 tw-ease-in-out hover:tw-border-[#b9adae]"
              [class.active]="form.controls.investorType?.value === investorType.id"
              [style.border-color]="
                form.controls.investorType?.value === investorType.id ? '#C01E27' : ''
              "
              (click)="selectInvestor(investorType.id)"
            >
              <div class="card-body tw-px-8">
                <div class="tw-my-3 tw-flex tw-flex-col tw-items-center">
                  <div
                    *ngIf="!isCompanyRep"
                    class="tw-mb-2 tw-inline-block tw-rounded-lg tw-bg-[#EAEAEA] tw-px-2 tw-text-[0.66rem] tw-uppercase"
                  >
                    {{ investorType.tier | translate }}
                  </div>
                  <div
                    class="tw-mb-5 tw-text-center tw-font-['Poppins'] tw-text-3xl tw-font-light tw-text-black"
                  >
                    {{ investorType.name | translate }}
                  </div>
                </div>

                <div
                  *ngIf="investorType.limit && investorType.limit !== 'registerInvestor.unlimited'"
                  class="tw-max-h-[230px] tw-text-sm tw-font-light tw-text-black md:tw-min-h-[230px]"
                >
                  <div class="tw-mb-2 tw-text-sm tw-font-semibold tw-text-black">
                    {{ 'registerInvestor.annualInvestmentLimit' | translate }}
                  </div>
                  <div *ngIf="investorType.limit.ecf">
                    <img
                      src="assets/img/icons/common/check_icon_green.png"
                      alt="check"
                      class="tw-pr-2"
                    />
                    {{ 'registerInvestor.ecf' | translate }}
                    <ul>
                      <div *ngIf="investorType.limit.ecf.perIssuer">
                        <li class="tw-text-sm">
                          {{ investorType.limit.ecf.perIssuer | translate }}
                        </li>
                      </div>
                      <li class="tw-text-sm">{{ investorType.limit.ecf.total | translate }}</li>
                    </ul>
                  </div>

                  <div *ngIf="investorType.limit.tcf">
                    <img
                      src="assets/img/icons/common/check_icon_green.png"
                      alt="check"
                      class="tw-pr-2"
                    />
                    {{ 'registerInvestor.tcf' | translate }}
                    <ul>
                      <div *ngIf="investorType.limit.tcf.perIssuer">
                        <li class="tw-text-sm">
                          {{ investorType.limit.tcf.perIssuer | translate }}
                        </li>
                      </div>
                      <li class="tw-text-sm">{{ investorType.limit.tcf.total | translate }}</li>
                    </ul>
                  </div>
                </div>

                <div
                  *ngIf="investorType.limit === 'registerInvestor.unlimited'"
                  class="tw-text-sm tw-font-light tw-text-black md:tw-min-h-[230px]"
                >
                  <div class="tw-mb-2 tw-text-sm tw-font-semibold tw-text-black">
                    {{ 'registerInvestor.annualInvestmentLimit' | translate }}
                  </div>
                  <img
                    src="assets/img/icons/common/check_icon_green.png"
                    alt="check"
                    class="tw-pr-2"
                  />
                  {{ 'registerInvestor.unlimited' | translate }}
                </div>

                <hr />

                <div
                  class="max-sm:tw-min-h-full md:tw-min-h-[390px]"
                  [class.md:tw-min-h-[390px]]="!isCompanyRep"
                  [class.md:tw-min-h-[250px]]="isCompanyRep"
                >
                  <div class="tw-md-2 tw-text-sm tw-font-semibold tw-text-black">
                    {{ 'registerInvestor.conditions' | translate }}
                  </div>
                  <div
                    *ngFor="let condKey of objectKeys(investorType.condition); let i = index"
                    class="tw-my-2 tw-text-sm tw-font-light tw-text-black"
                  >
                    <div class="tw-flex tw-items-start">
                      <img
                        src="assets/img/icons/common/check_icon_green.png"
                        alt="check"
                        class="tw-py-1 tw-pr-2"
                      />
                      {{ investorType.condition[condKey] | translate }}
                    </div>
                    <ng-container
                      *ngIf="
                        investorType.subCondition && i === investorType.subConditionPosition - 1
                      "
                    >
                      <ul>
                        <li
                          *ngFor="let subCondKey of objectKeys(investorType.subCond)"
                          class="tw-text-sm tw-font-light tw-text-black"
                        >
                          {{ investorType.subCond[subCondKey] | translate }}
                        </li>
                      </ul>
                    </ng-container>
                  </div>
                </div>

                <div class="tw-mb-4 tw-mt-10 tw-flex tw-justify-center">
                  <div
                    class="btn tw-w-40"
                    [class.btn-primary]="form.controls.investorType?.value === investorType.id"
                    [class.btn-primary-border]="
                      form.controls.investorType?.value !== investorType.id
                    "
                    (click)="selectInvestor(investorType.id)"
                  >
                    <ng-container *ngIf="form.controls.investorType?.value === investorType.id">
                      {{ 'registerInvestor.selected' | translate }}
                    </ng-container>
                    <ng-container *ngIf="form.controls.investorType?.value !== investorType.id">
                      {{ 'registerInvestor.select' | translate }}
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tw-flex tw-items-center tw-border-b tw-border-t tw-border-divider tw-py-5">
    <mat-slide-toggle formControlName="isAnonymous"></mat-slide-toggle>
    <div
      class="tw-ml-4 tw-text-sm tw-text-black"
      [innerHTML]="'registerInvestor.investAsAnAnonymous' | translate"
    ></div>
  </div>
  <div class="tw-flex tw-items-center tw-pt-5">
    <mat-checkbox formControlName="isAccept" class="tw-mr-[8px]"></mat-checkbox>
    <label class="tw-m-0 tw-ml-4 tw-text-sm tw-text-black">{{
      'registerInvestor.declare' | translate
    }}</label>
  </div>
  <div class="tw-flex tw-w-full tw-justify-center" [class.justify-content-start]="isSettings">
    <button type="submit" class="btn btn-primary tw-mt-5 tw-w-3/4" id="confirmButton">
      <ng-container *ngIf="isSettings">
        {{ 'registerInvestor.confirmChanges' | translate }}
      </ng-container>
      <ng-container *ngIf="!isSettings">
        {{ 'registerInvestor.confirm' | translate }}
      </ng-container>
    </button>
  </div>
</form>
