import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import {
  OneBillionPattern,
  OneBillionPatternAllowNegative,
  OneBillionWithTwoDecimalPattern,
  OneBillionWithTwoDecimalPatternAllowNegative,
} from '../../../core/services/constant';
import { QuestionBase } from '../../models/question/question-base';

@Component({
  selector: 'app-dynamic-form-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.scss'],
})
export class DynamicFormQuestionComponent implements OnInit {
  @Input() question: QuestionBase<any>;
  @Input() form: UntypedFormGroup;
  @Input() inputClass: string;

  OneBillionWithTwoDecimalPattern = OneBillionWithTwoDecimalPattern;
  OneBillionWithTwoDecimalPatternAllowNegative = OneBillionWithTwoDecimalPatternAllowNegative;
  OneBillionPatternAllowNegative = OneBillionPatternAllowNegative;
  OneBillionPattern = OneBillionPattern;

  ngOnInit() {
    if (this.question.controlType == 'radio-boolean') {
      this.form.controls[this.question.key].setValue(this.question.value?.toString());
    }

    if (
      this.question.controlType == 'datepicker' &&
      this.question.isToday &&
      !this.question.value
    ) {
      this.form.controls[this.question.key].setValue(moment());
    }
  }
}
