import { Component, OnInit } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { finalize } from 'rxjs/operators';
import { ActivityStatus } from '../../../core/enum/activity-status.enum';
import { ShareFlow } from '../../../core/enum/share-flow.enum';
import { PortfolioHistoryList } from '../../../core/models/portfolio/portfolio-history-list';
import { PortfolioService } from '../../../core/services/api/portfolio.service';
import { FULL_FORMATS } from '../../../core/services/constant';
import { ErrorService } from '../../../core/services/error.service';
import { BlockUiService } from '../../../shared/services/blockUi/block-ui.service';
import { HistoryFilter } from './history-filter';
import { ShareMarketStatus } from '../../../core/enum/mysec/share-market-status.enum';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: FULL_FORMATS }],
})
export class HistoryComponent implements OnInit {
  portfolioHistoryList: PortfolioHistoryList[];
  displayColumns: string[] = [
    'OrderNo',
    'Type',
    'Event',
    'Company',
    'ShareQty',
    'PricePerShare',
    'Amount',
    'Time',
  ];
  params = new HistoryFilter();
  isLoading: boolean = false;
  total: number = 0;
  date: string;
  maxDateRange = {
    amount: 180,
    unitOfTime: 'days',
  };

  ShareMarketStatus: typeof ShareMarketStatus = ShareMarketStatus;
  ShareFlow: typeof ShareFlow = ShareFlow;
  ActivityStatus: typeof ActivityStatus = ActivityStatus;

  constructor(
    private errorService: ErrorService,
    private portfolioService: PortfolioService,
    private blockUiService: BlockUiService,
  ) {}

  ngOnInit(): void {
    this.getPortfolioHistory();
  }

  getPortfolioHistory() {
    this.isLoading = true;

    this.portfolioService
      .getPortfolioHistory(this.params)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.portfolioHistoryList = data.data;
          this.total = data.total;
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  changePage(event?: PageEvent) {
    this.params.currentPage = event.pageIndex + 1;
    this.params.take = event.pageSize;
    this.getPortfolioHistory();
  }

  filterByDate(dateRange: { dateFrom: string; dateTo: string }) {
    dateRange.dateFrom
      ? (this.params.transactionDateFrom = dateRange.dateFrom)
      : delete this.params.transactionDateFrom;

    dateRange.dateTo
      ? (this.params.transactionDateTo = dateRange.dateTo)
      : delete this.params.transactionDateTo;

    if (this.params.transactionDateFrom && this.params.transactionDateTo) {
      this.params.currentPage = 1;
      this.getPortfolioHistory();
    }
  }

  export() {
    this.blockUiService.open();

    this.portfolioService
      .generateHistoryReportPdf(this.params)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        (data) => {
          window.open(data.data);
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }
}
