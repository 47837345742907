import { Role } from './../../../core/enum/role.enum';
import { StorageService } from './../../../core/services/storage.service';
import { BlockUiService } from './../../../shared/services/blockUi/block-ui.service';
import { ErrorService } from './../../../core/services/error.service';
import { AccountService } from './../../../core/services/api/account.service';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { InvestorProfile } from '../../../core/models/user/investor-profile';
import { PopupService } from '../../../shared/services/popup/popup.service';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ICMaskFormat, ImageFileFormat, PassportFormat } from '../../../core/services/constant';
import { IdentificationType } from '../../../core/enum/identification-type.enum';
import { ContainerType } from '../../../core/enum/container-type.enum';
import { isPlatformBrowser } from '@angular/common';
import { FileValidationService } from '../../../core/services/file-validation.service';
import { FileUploadOption } from '../../../core/models/attachment/file-upload-option';
import { AttachmentInfo } from '../../../core/models/attachment/attachment-info';
import { GtmDataLayerService } from '../../../core/services/gtm-data-layer.service';

@Component({
  selector: 'app-register-investor',
  templateUrl: './register-investor.component.html',
  styleUrls: ['./register-investor.component.scss'],
})
export class RegisterInvestorComponent implements OnInit {
  step: number = 1;
  form: UntypedFormGroup;
  showFormError: boolean = false;
  isLoading: boolean = false;
  profileImage: any;
  investorProfile: InvestorProfile;
  accept: string = ImageFileFormat.toString();
  icMaskFormat = ICMaskFormat;
  attachmentInfo: AttachmentInfo;

  IdentificationType: typeof IdentificationType = IdentificationType;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private popupService: PopupService,
    private sanitizer: DomSanitizer,
    private accountService: AccountService,
    private errorService: ErrorService,
    private blockUiService: BlockUiService,
    private router: Router,
    private storageService: StorageService,
    @Inject(PLATFORM_ID) private platform: Object,
    private fileValidationService: FileValidationService,
    private _gtmDataLayerService: GtmDataLayerService,
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.checkSignUpFlow();

      this.form = this.formBuilder.group({
        fullName: ['', Validators.required],
        userIdentityNoType: [IdentificationType.MyKad, Validators.required],
        identityNo: ['', Validators.required],
        aboutMe: [''],
      });

      this.getBasicProfile();
    }
  }

  checkSignUpFlow() {
    this.accountService
      .getSignUpFlowStatus()
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        (flowData) => {
          if (flowData) {
            if (!flowData.isCompleted) this.step = flowData.completedSignUpFlowStage + 1;
            else this.router.navigate(['/']);
          } else {
            this.popupService.alert('common.userNotExists').then(() => {
              this.router.navigate(['/profile/settings/profile-details']);
            });
          }
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  getBasicProfile() {
    this.accountService.profile(true).subscribe(
      (profile) => {
        this.form.patchValue(profile);

        if (!this.form.value.userIdentityNoType)
          this.form.controls.userIdentityNoType.setValue(IdentificationType.MyKad);

        this.profileImage = profile.photoAttachmentUrl;
      },
      (err) => this.errorService.showError(err),
    );
  }

  async selectFile(file: File) {
    const uploadOption: FileUploadOption = {
      fileType: ImageFileFormat,
      errorMsg: 'common.onlyImageAccepted',
    };

    if (await this.fileValidationService.validFile(file, uploadOption)) {
      this.isLoading = true;
      this.profileImage = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));

      this.accountService
        .uploadAttachmentPresignedURL(file, ContainerType.ProfilePicture)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          (data) => {
            this.profileImage = data.url;
            this.attachmentInfo = new AttachmentInfo(data);
          },
          (err) => {
            this.errorService.showError(err);
            this.profileImage = null;
            this.attachmentInfo = null;
          },
        );
    }
  }

  next() {
    if (this.form.invalid) {
      this.popupService.alert('common.missingFieldsAlert');
      this.showFormError = true;
      return;
    }

    this.blockUiService.open();
    let profile = this.form.value;
    profile.attachmentInfo = this.attachmentInfo;

    this.accountService
      .registerIndividual(profile)
      .pipe(
        finalize(() => {
          this.blockUiService.close();

          // When in step 2 (investor profile), log the pageView
          if (isPlatformBrowser(this.platform)) {
            this._gtmDataLayerService.logPageView('/sign-up/individual-investor/2', document.title);
          }
        }),
      )
      .subscribe(
        (data) => {
          this.step++;
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  submit(event: InvestorProfile) {
    this.blockUiService.open();

    this.accountService
      .registerInvestorProfile(event.investorType, event.isAnonymous)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        () => {
          this.storageService.role = Role.IndividualInvestor;
          this.router.navigate(['/sign-up/proceed-e-kyc']);
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  changeIdentificationType() {
    let type = this.form.value.userIdentityNoType;

    if (type != IdentificationType.Passport) {
      this.form.controls.identityNo.setValidators([Validators.required]);
      this.form.controls.identityNo.updateValueAndValidity();
    } else {
      this.form.controls.identityNo.setValidators([
        Validators.required,
        Validators.pattern(PassportFormat),
      ]);
      this.form.controls.identityNo.updateValueAndValidity();
    }
  }
}
