import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ErrorService } from '../../../../core/services/error.service';
import { CampaignService } from '../../../../core/services/api/campaign.service';
import { ConfirmInvestComponent } from './confirm-invest/confirm-invest.component';
import { InvestmentInfo } from '../../../../core/models/investment/investment-info';
import { BlockUiService } from '../../../../shared/services/blockUi/block-ui.service';
import { finalize } from 'rxjs/operators';
import { DefaultLogo, MaxReferalCodeLength } from '../../../../core/services/constant';
import { Campaign } from '../../../../core/models/campaign/campaign';
import { PopupService } from '../../../../shared/services/popup/popup.service';
import { OneBillionPattern } from '../../../../core/services/constant';
import { GtmDataLayerService } from '../../../../core/services/gtm-data-layer.service';
import { InvestmentLimitPopupService } from '../../../../shared/services/investment-limit-popup/investment-limit-popup.service';
import { ShareType } from '../../../../core/enum/share-type.enum';

@Component({
  selector: 'app-invest-now',
  templateUrl: './invest-now.component.html',
  styleUrls: ['./invest-now.component.scss'],
})
export class InvestNowComponent implements OnInit {
  slug: string;
  form: UntypedFormGroup;
  showFormError: boolean = false;
  campaign: Campaign;
  investmentInfo: InvestmentInfo;
  defaultLogo = DefaultLogo;
  OneBillionPattern = OneBillionPattern;
  MaxReferalCodeLength = MaxReferalCodeLength;

  ShareType: typeof ShareType = ShareType;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private campaignService: CampaignService,
    private errorService: ErrorService,
    private router: Router,
    private popupService: PopupService,
    private blockUiService: BlockUiService,
    private gtmDataLayerService: GtmDataLayerService,
    private investmentLimitPopupService: InvestmentLimitPopupService,
  ) {}

  ngOnInit() {
    this.slug = this.activatedRoute.snapshot.params.slug;
    this.buildForm();
    this.getCampaign();
  }

  getCampaign() {
    this.campaignService.getCampaign(this.slug).subscribe({
      next: (data) => {
        // PLT-667 Redirect to campaign details if is private campaign and is not invited
        if (data?.isPrivate && !data?.isInvited) {
          this.router.navigate([`/equity/${data?.slug}`], {
            replaceUrl: true,
          });
          return;
        }

        this.campaign = data;
        this.getCampaignInvestmentInfo();
      },
      error: (err) => {
        this.errorService.showError(err);
      },
    });
  }

  getCampaignInvestmentInfo() {
    this.campaignService.getCampaignInvestmentInfo(this.campaign.id).subscribe({
      next: (data) => {
        this.investmentInfo = data;
      },
      error: (err) => {
        this.errorService.showError(err);
      },
    });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      lotAmount: ['', Validators.required],
      investmentAmount: [0, Validators.required],
      referralCode: [null, Validators.maxLength(MaxReferalCodeLength)],
      isAcceptTerm1: [false, Validators.required],
      isAcceptTerm2: [false, Validators.required],
    });
  }

  calculateInvestmentAmount() {
    let investmentAmount = 0;
    let lotAmount = parseInt(this.form.value.lotAmount);

    if (lotAmount < this.investmentInfo.minimumLots) {
      this.form.controls.lotAmount.setErrors({ lessThanMin: true });
      return;
    } else if (
      lotAmount > this.investmentInfo.minimumLots &&
      (lotAmount - this.investmentInfo.minimumLots) % this.investmentInfo.incrementLot != 0
    ) {
      this.form.controls.lotAmount.setErrors({ incrementFalse: true });
      return;
    } else if (isNaN(lotAmount)) {
      this.form.controls.investmentAmount.setValue(0);
      return;
    }

    investmentAmount =
      lotAmount * this.investmentInfo.sharePerLot * this.investmentInfo.pricePerShare;
    this.form.controls.investmentAmount.setValue(investmentAmount);
  }

  submit() {
    if (this.form.invalid) {
      this.showFormError = true;
      return;
    }

    if (this.form.value.isAcceptTerm1 == false || this.form.value.isAcceptTerm2 == false) {
      this.popupService.alert('common.acceptT&C');
      return;
    }

    this.displayConfirmInvest();
  }

  displayConfirmInvest() {
    const option: any = {
      size: 'md',
      centered: true,
      windowClass: 'modal-550',
      backdropClass: 'backdrop-popup',
    };
    const modalRef = this.modalService.open(ConfirmInvestComponent, option);
    modalRef.componentInstance.campaignTitle = this.campaign.company?.brandName;
    modalRef.componentInstance.campaignImage = this.campaign.company?.companyLogoUrl;
    modalRef.componentInstance.lotAmount = this.form.value.lotAmount;
    modalRef.componentInstance.investmentAmount = this.form.value.investmentAmount;
    modalRef.result.then(
      () => {
        this.invest();
      },
      () => {},
    );
  }

  invest(isContinue: boolean = false) {
    this.blockUiService.open();

    this.campaignService
      .invest(this.campaign.id, this.form.value.lotAmount, this.form.value.referralCode, isContinue)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe({
        next: (data) => {
          this.router.navigate(['payment', data.result.id], { relativeTo: this.activatedRoute });
        },
        error: (err) => {
          if (
            err &&
            err.error &&
            (err.error.code == 'ERR_INVESTMENT_LIMIT_CAMPAIGN' ||
              err.error.code == 'ERR_INVESTMENT_LIMIT_INTERVAL')
          ) {
            this.logErrorMessage(err.error.code);
            this.investmentLimitPopupService.displayExceedInvestmentLimitWarning(
              err.error.message,
              err.error.code === 'ERR_INVESTMENT_LIMIT_CAMPAIGN' ? 'campaign' : 'interval',
              () => {
                this.invest(true);
              },
            );
          } else this.errorService.showError(err);
        },
      });
  }

  logErrorMessage(errorCode: string) {
    if (!errorCode) return;

    this.gtmDataLayerService.logErrorMessage(errorCode);
  }
}
