import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ValidateParamsService } from '../../../../core/services/validate-params.service';
import { CampaignStatus } from '../../../../core/enum/campaign-status.enum';
import { Campaign } from '../../../../core/models/campaign/campaign';
import { CampaignService } from '../../../../core/services/api/campaign.service';
import { SettingService } from '../../../../core/services/api/setting.service';
import { ErrorService } from '../../../../core/services/error.service';
import { QueryParamsService } from '../../../../core/services/query-params.service';
import { CampaignSectionFilter } from '../../campaign-section/campaign-section-filter';
import { TaxIncentivesEligibility } from '../../../../core/enum/tax-incentives-eligibility.enum';
import { InvestmentFilterType } from '../../../../core/enum/investment-filter-type.enum';
@Component({
  selector: 'app-live-campaigns',
  templateUrl: './live-campaigns.component.html',
  styleUrls: ['./live-campaigns.component.scss'],
})
export class LiveCampaignsComponent implements OnInit {
  campaigns: Campaign[];
  params: any;
  total: number = 0;
  isLoading: boolean = false;
  campaignStatusTabs = [
    {
      key: CampaignStatus.Live,
      value: 'equity.LiveOffers',
    },
    {
      key: CampaignStatus.PreLive,
      value: 'equity.PreLive',
    },
  ];
  selectedStatusTab: number = null;
  allowedParams: string[] = [
    ...Object.keys(new CampaignSectionFilter()),
    'partnerIds',
    'partnerSlugs',
    'sectorIds',
  ];
  validationSchema = {
    campaignStatus: {
      allowed: [CampaignStatus.Live, CampaignStatus.PreLive],
      defaultValue: [CampaignStatus.Live, CampaignStatus.PreLive],
    },
    landingPageAngelTaxIncentiveEligibility: {
      allowed: Object.values(TaxIncentivesEligibility),
      defaultValue: 0,
    },
    orderBy: {
      allowed: Object.values(InvestmentFilterType),
      defaultValue: 0,
    },
  };

  constructor(
    private campaignService: CampaignService,
    private settingService: SettingService,
    private errorService: ErrorService,
    private queryParamsService: QueryParamsService,
    private validateParamsService: ValidateParamsService,
    @Inject(PLATFORM_ID) private platform: Object,
  ) {}

  ngOnInit(): void {
    this.getInitialList();
  }

  getInitialList() {
    const statusParams = this.queryParamsService.getParam('campaignStatus');

    const defaultParams = new CampaignSectionFilter(
      [CampaignStatus.Live, CampaignStatus.PreLive],
      30,
    );

    // Set the params directly and the `campaign-filters` will return the `selectedSectors[]`
    // otherwise whenever clicking the status tabs will get reset the sectors
    this.params = this.validateParamsService.sanitize(
      this.validationSchema,
      {
        ...defaultParams,
        ...this.queryParamsService.getParams(),
      },
      this.allowedParams,
    );

    if (statusParams) {
      this.selectedStatusTab = Array.isArray(this.params.campaignStatus) ? null : statusParams;
    }

    // Reserve this function for future
    // this.getSectors(this.queryParamsService.getParam("sectorIds"));
  }

  getList(params: any) {
    this.isLoading = true;
    this.params = params;
    this.campaigns = [];

    this.campaignService
      .getCampaigns(this.params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          delete this.params.sectorIds;
        }),
      )
      .subscribe(
        (data) => {
          this.campaigns = data.data;
          this.total = data.total;
        },
        (err) => {
          // As url can be changed freely, some params might be invalid,
          // so once API detects invalid fields, will have to reset the list
          this.errorService.showError(err, ({ error }) => {
            if (
              error?.code == 'ERR_INVALID_CAMPAIGN_STATUS' ||
              error?.code == 'ERR_INVALID_FIELD'
            ) {
              this.resetList();
              return;
            }
          });
        },
      );
  }

  resetList() {
    this.updateUrl(new CampaignSectionFilter([CampaignStatus.Live, CampaignStatus.PreLive], 30));
  }

  filterByStatus(statuses?: CampaignStatus) {
    let params = {
      ...this.params,
      campaignStatus: statuses ? statuses : [CampaignStatus.Live, CampaignStatus.PreLive],
    };
    this.selectedStatusTab = statuses ? statuses : null;
    this.updateUrl(params);
  }

  getSectors(sectorIds) {
    if (sectorIds) {
      this.settingService.getSectorsByIds(sectorIds).subscribe(
        (data) => {
          this.params.selectedSectors = data;
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
    }
  }

  updateUrl(params: any) {
    if (params.selectedSectors) {
      params['sectorIds'] = params.selectedSectors.map((sector) => sector.id || sector.key);
    }
    this.queryParamsService.updateUrl(params, ['take', 'selectedSectors']);
    this.getList(params);
  }
}
