import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { BlockUiService } from '../blockUi/block-ui.service';
import { InvestmentLimitPopupComponent } from 'src/app/shared/services/investment-limit-popup/investment-limit-popup.component';
import { AccountService } from '../../../../app/core/services/api/account.service';
import { ToastService } from '../../../../app/core/services/toast.service';
import { InvestorType } from '../../../../app/core/enum/investor-type.enum';
import { SharedModule } from '../../shared.module';

@Injectable({
  providedIn: SharedModule,
})
export class InvestmentLimitPopupService {
  constructor(
    private modalService: NgbModal,
    private accountService: AccountService,
    private blockUiService: BlockUiService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  async displayExceedInvestmentLimitWarning(
    message: string,
    exceedLimitType: string,
    callback?: () => void,
  ) {
    const popupOptions = {
      size: 'md',
      centered: true,
      keyboard: false,
      windowClass: 'modal-full-screen',
    } as NgbModalOptions;

    const modalRef = this.modalService.open(InvestmentLimitPopupComponent, popupOptions);
    const investmentConfig = JSON.parse(message);

    modalRef.componentInstance.investmentLimitConfig = investmentConfig;
    modalRef.componentInstance.exceedLimitType = exceedLimitType;

    try {
      const result = await modalRef.result;

      if (result) {
        const changeInvestorType$ = this.accountService.updateProfileInvestment({
          investorType: investmentConfig?.RecommendedInvestorType,
        });

        this.blockUiService.open();

        await lastValueFrom(changeInvestorType$).then(() => {
          if (callback) callback();
        });
      }
    } catch (err) {
      this.blockUiService.close();

      // if click `reduce investment` will also throw exception, which is intended behavior
      // will only handle the exception with proper error obj
      if (!err) return;

      this.toastService.error(
        this.translateService.instant('investment.updateInvestorTypeError', {
          investorType: this.translateService.instant(
            'investment.investorType.' + InvestorType[investmentConfig?.RecommendedInvestorType],
          ),
        }),
      );
    }
  }
}
