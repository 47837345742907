<h2 class="text-center font-weight-extrabold">
  {{
    (role == Role.IssuerRep ? 'registerIssuer.issuerRepresentative' : 'signUp.corporateInvestor')
      | translate
  }}
</h2>
<div class="text-center mb-4">{{ 'signUp.representativeDesc' | translate }}</div>
<form (ngSubmit)="submit()" [formGroup]="form" [class.show-form-error]="showFormError">
  <div class="mb-3">
    <div class="input-field" *ngIf="!isLoading">
      <div class="profile-upload" agmoUploader [accept]="accept" (selectFiles)="selectFile($event)">
        <div class="profile-image pitchin-icon-profile" *ngIf="!profileImage"></div>
        <img class="profile-image" alt="pitchIN User" [src]="profileImage" *ngIf="profileImage" />
        <div class="pitchin-icon pitchin-icon-add"></div>
      </div>
    </div>
    <div class="text-primary text-center pt-3" *ngIf="!profileImage">
      {{ 'common.dragAndDropImage' | translate }}
    </div>
    <div class="text-primary text-center pt-3" *ngIf="profileImage && !isLoading">
      {{ 'common.lookingGood' | translate }}
    </div>
    <agmo-loading *ngIf="isLoading"></agmo-loading>
  </div>
  <div class="mb-4 mt-4">
    <div class="input-label">{{ 'signUp.identificationType' | translate }}</div>
    <div class="input-field">
      <mat-select
        [placeholder]="'common.selectPlaceholder' | translate"
        class="form-control"
        formControlName="userIdentityNoType"
        (selectionChange)="changeIdentificationType(form)"
      >
        <mat-option *ngFor="let type of IdentificationType | enumToArray" [value]="type.key">
          {{ 'signUp.' + type.value | translate }}
        </mat-option>
      </mat-select>
    </div>
  </div>
  <div class="mb-4">
    <div class="input-label">
      {{ 'signUp.representativeNameAsPer' | translate }}
      {{ 'signUp.' + IdentificationType[form.value.userIdentityNoType] | translate }}
    </div>
    <input
      type="text"
      class="form-control"
      placeholder="{{ 'signUp.fullNamePlaceholder' | translate }}"
      formControlName="fullName"
    />
  </div>
  <div class="mb-3">
    <div class="input-label">
      {{ 'signUp.' + IdentificationType[form.value.userIdentityNoType] | translate }}
      {{ 'signUp.number' | translate }}
    </div>
    <div class="input-field">
      <input
        class="form-control"
        *ngIf="form.value.userIdentityNoType != IdentificationType.Passport"
        placeholder="{{ 'signUp.icNumberPlaceholder' | translate }}"
        formControlName="identityNo"
        [mask]="icMaskFormat"
        type="text"
      />
      <input
        class="form-control"
        *ngIf="form.value.userIdentityNoType == IdentificationType.Passport"
        placeholder="{{ 'signUp.passportPlaceholder' | translate }}"
        formControlName="identityNo"
        type="text"
        alphanumeric
      />
      <div
        class="error"
        *ngIf="
          showFormError &&
          form.controls.identityNo.errors &&
          (form.controls.identityNo.errors.pattern || form.controls.identityNo.errors.mask)
        "
      >
        {{ 'signUp.' + IdentificationType[form.value.userIdentityNoType] | translate }}
        {{ 'signUp.number' | translate }}
        {{ 'signUp.incorrectFormat' | translate }}
      </div>
    </div>
  </div>
  <div class="other-issuer-panel">
    <div class="title mb-3">
      {{
        (role == Role.IssuerRep
          ? 'registerIssuer.otherIssuerRepresentative'
          : 'company.corporateRepresentative'
        ) | translate
      }}
    </div>
    <ng-container *ngIf="role == Role.IssuerRep">
      <div class="pb-3 text-justify">
        {{ 'registerIssuer.repDescription' | translate }}
      </div>
      <div class="pb-3 text-justify">
        {{ 'registerIssuer.repEmailDescription' | translate }}
      </div>
    </ng-container>
    <ng-container *ngIf="role == Role.CompanyRep">
      <div class="pb-3 text-justify">
        {{ 'signUp.repDescription' | translate }}
      </div>
      <div class="pb-3 text-justify">
        {{ 'signUp.repEmailDescription' | translate }}
      </div>
    </ng-container>
    <div class="add-issuer-rep">
      <div
        class="btn btn-primary btn-add"
        *ngIf="repFormControl.controls.length < 3"
        (click)="addRep()"
      >
        <i class="fa fa-plus mr-2"></i>
        {{ 'common.add' | translate }}
      </div>
      <ng-container formArrayName="representatives">
        <div
          class="other-rep-panel"
          *ngFor="let rep of repFormControl.controls; let i = index"
          formGroupName="{{ i }}"
        >
          <div class="pitchin-icon pitchin-icon-delete align-self-end" (click)="removeRep(i)"></div>
          <div class="mb-4 mt-4">
            <div class="input-label">{{ 'signUp.identificationType' | translate }}</div>
            <div class="input-field">
              <mat-select
                [placeholder]="'common.selectPlaceholder' | translate"
                class="form-control"
                formControlName="userIdentityNoType"
                (selectionChange)="changeIdentificationType(rep)"
              >
                <mat-option
                  *ngFor="let type of IdentificationType | enumToArray"
                  [value]="type.key"
                >
                  {{ 'signUp.' + type.value | translate }}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="mb-4">
            <div class="input-label">
              {{ 'signUp.representativeNameAsPer' | translate }}
              {{ 'signUp.' + IdentificationType[rep.value.userIdentityNoType] | translate }}
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="{{ 'registerIssuer.namePlaceholder' | translate }}"
              formControlName="fullName"
            />
          </div>
          <div class="mb-4">
            <div class="input-label">
              {{ 'signUp.' + IdentificationType[rep.value.userIdentityNoType] | translate }}
              {{ 'signUp.number' | translate }}
            </div>
            <div class="input-field">
              <input
                class="form-control"
                *ngIf="rep.value.userIdentityNoType != IdentificationType.Passport"
                placeholder="{{ 'signUp.icNumberPlaceholder' | translate }}"
                formControlName="identityNo"
                [mask]="icMaskFormat"
                type="text"
              />
              <input
                class="form-control"
                *ngIf="rep.value.userIdentityNoType == IdentificationType.Passport"
                placeholder="{{ 'signUp.passportPlaceholder' | translate }}"
                formControlName="identityNo"
                type="text"
                alphanumeric
              />
              <div
                class="error"
                *ngIf="
                  showFormError &&
                  rep.controls.identityNo.errors &&
                  (rep.controls.identityNo.errors.pattern || rep.controls.identityNo.errors.mask)
                "
              >
                {{ 'signUp.' + IdentificationType[rep.value.userIdentityNoType] | translate }}
                {{ 'signUp.number' | translate }}
                {{ 'signUp.incorrectFormat' | translate }}
              </div>
            </div>
          </div>
          <div>
            <div class="input-label">{{ 'common.email' | translate }}</div>
            <div class="input-field">
              <input
                class="form-control"
                placeholder="{{ 'common.emailPlaceholder' | translate }}"
                formControlName="email"
                type="text"
              />
            </div>
            <div
              class="error"
              *ngIf="
                showFormError && rep.controls.email.errors && rep.controls.email.errors.pattern
              "
            >
              {{ 'signUp.emailFormatIncorrect' | translate }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="text-center pt-5">
    <button type="submit" class="btn btn-primary">
      {{ 'common.submit' | translate }}
    </button>
  </div>
</form>
