import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weblink',
})
export class WeblinkPipe implements PipeTransform {
  transform(link: string, showDomain?: boolean) {
    link = 'https://' + link.replace(/(^\w+:|^)\/\//, '');
    let newURL: URL;

    try {
      newURL = new URL(link);
    } catch (_) {
      return;
    }

    if (showDomain) {
      link = newURL.hostname.replace('www.', '');
    }

    return link;
  }
}
