import { InvestmentSortType } from '../../../core/enum/investment-sort-type.enum';
import { InvestorShareMarketStatusFilter } from '../../../core/enum/investor-share-market-status.enum';
import { Sector } from '../../../core/models/company/sector';

export class BusinessesFilter {
  take: number;
  currentPage: number;
  selectedSectors: Sector[];
  sectorIds: number | number[];
  filterString: string;
  orderBy: InvestmentSortType;
  investorShareMarketStatus: InvestorShareMarketStatusFilter[];
  isWatchList: boolean;

  constructor() {
    this.currentPage = 1;
    this.take = 20;
    this.selectedSectors = [];
    this.filterString = '';
    this.orderBy = InvestmentSortType.LastDoneShareTransactionAt;
    this.investorShareMarketStatus = [];
    this.isWatchList = false;
  }
}
