<section class="section">
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12">
        <h1 class="title font-weight-bold text-dark text-center">PSTX User Terms and Conditions</h1>
      </div>
    </div>

    <div class="row">
      <div class="mx-auto col-11 col-md-8 offset-md-2">
        <div class="legal-list">
          <!-- <div class="upperalpha-list">
                      
                  </div> -->

          <ol>
            <li>
              <!-- 1 -->
              <h2 class="tw-uppercase">
                Users to be bound by the terms and conditions and the rulebook
              </h2>

              <ol>
                <!-- 1.1 -->
                <li>
                  <p>
                    All Users of the PSTX understand that by accessing, using, or continuing to
                    access and use the PSTX, they have read, understood, and agreed to be bound by
                    all terms and conditions available on the Platform.
                  </p>
                </li>
                <!-- 1.2 -->
                <li>
                  <p>
                    With regards to specifically the use of the PSTX, in the event there is an
                    inconsistency between any terms and conditions with the PSTX Rulebook, the Users
                    shall abide by the PSTX Rulebook.
                  </p>
                </li>
                <!-- 1.3 -->
                <li>
                  <p>
                    The terms and conditions available on the PSTX and the PSTX Rulebook are subject
                    to change. The Platform Operator may suspend any of the provisions of the terms
                    and conditions and make or impose new rules to ensure the orderly and fair
                    operations of the PSTX. Any suspension, variations or changes will be reflected
                    in the current versions of the terms and conditions placed on the Platform.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 2 -->
              <h2>DEFINITIONS AND INTERPRETATIONS</h2>

              <dl class="legal-description-list">
                <dt>Ask</dt>
                <dd>Has the same meaning as Sell Order.</dd>

                <dt>Bid</dt>
                <dd>Has the same meaning as Buy Order.</dd>

                <dt>Buy Order</dt>
                <dd>
                  Means an order that is placed by a Buyer on the PSTX for the purchase of Shares.
                </dd>

                <dt>Buyer</dt>
                <dd>Has the same meaning as Eligible Buyer.</dd>

                <dt>Digital Register</dt>
                <dd>
                  Means a digitized data digital registry system that maintains all the
                  shareholdings of the Issuers.
                </dd>

                <dt>Direct Shareholder</dt>
                <dd>Means as provided in Section 5.</dd>

                <dt>ECF</dt>
                <dd>
                  Means the activity of equity crowdfunding as permitted by the laws and regulations
                  of Malaysia.
                </dd>

                <dt>Eligible Buyer</dt>
                <dd>
                  Means as provided in Section 5. Traders registered on pitchIN who buy shares on
                  pitchIN Secondary.
                </dd>

                <dt>Eligible Seller</dt>
                <dd>
                  Means as provided in Section 5. Shareholder or its nominee holding the equity of
                  the Issuer which is eligible to be sold on pitchIN Secondary Market.
                </dd>

                <dt>Eligible Share</dt>
                <dd>Means shares of an Issuer who has successfully funded through the Platform.</dd>

                <dt>Founder</dt>
                <dd>Has the same meaning as Promoter.</dd>

                <dt>Guidelines</dt>
                <dd>
                  Means the Guidelines on Recognised Markets issued by the SC-GL/6-2015(R11-2024),
                  as may be amended from time to time, and include such other guidelines, orders,
                  directives, by-laws, regulations and policies as may be issued from time to time
                  by the relevant authority in respect of equity crowdfunding in Malaysia.
                </dd>

                <dt>Hosting</dt>
                <dd>
                  Means the offer of the share capital of an Issuer on the Platform for subscription
                  by Investors.
                </dd>

                <dt>Indicative Share Price</dt>
                <dd>Means as provided in Section 9.</dd>

                <dt>Investor</dt>
                <dd>Means a person who invests in any Hosting on the Platform.</dd>

                <dt>Issuer</dt>
                <dd>
                  A private limited company or LLP that has successfully funded through the
                  Platform.
                </dd>

                <dt>Last Traded Price</dt>
                <dd>Means as provided in Section 9.</dd>

                <dt>Nominee Structure</dt>
                <dd>
                  An arrangement which holds equities on behalf of ECF investors in an Issuer while
                  investors maintain full interest in the equity.
                </dd>

                <dt>Operating Schedule</dt>
                <dd>Means as provided in Section 10.</dd>

                <dt>Order</dt>
                <dd>
                  Means either a Buy Order or a Sell Order that is placed on the PSTX by an Eligible
                  Buyer or Eligible Seller.
                </dd>

                <dt>Order Book</dt>
                <dd>Means in accordance with Section 9.</dd>

                <dt>Pitch Nominees</dt>
                <dd>Means as provided in Section 5.</dd>

                <dt>pitchIN</dt>
                <dd>Means Pitch Platforms Sdn Bhd [Registration No. 201501033144 (1158464-T)].</dd>

                <dt>pitchIN Secondary Market</dt>
                <dd>
                  Means a market for the trading of shares of Issuers operated as part of the
                  Platform by the Platform Operator.
                </dd>

                <dt>Platform</dt>
                <dd>
                  Means the platform at www.pitchin.my and any subdomain thereof operated by the
                  Platform Operator to provide ECF services and any other service as may be
                  introduced from time-to-time.
                </dd>

                <dt>Platform Operator</dt>
                <dd>
                  Means Pitch Platforms Sdn Bhd [Registration No. 201501033144 (1158464-T)], being
                  the owner and operator of the Platform, and its subsidiaries (pitchIN).
                </dd>

                <dt>Promoter</dt>
                <dd>
                  The person or persons who started the business of the company before the Issuer
                  successfully funded through the Platform who may be also identified as the
                  Founder. The Platform shall have the discretion to identify and determine the
                  Promoter of an Issuer.
                </dd>

                <dt>PSTX</dt>
                <dd>Has the same meaning as pitchIN Secondary Market.</dd>

                <dt>PSTX Account</dt>
                <dd>Means in accordance with Section 9.</dd>

                <dt>PSTX Team</dt>
                <dd>
                  Means the team of which consists of staff employed by the Platform Operator
                  assigned to carry out the PSTX operations.
                </dd>

                <dt>SC</dt>
                <dd>Means the Securities Commission of Malaysia.</dd>

                <dt>Sell Order</dt>
                <dd>
                  Means an order that is placed by a Seller on the PSTX for the sale of Shares.
                </dd>

                <dt>Seller</dt>
                <dd>Has the same meaning as Eligible Seller.</dd>

                <dt>Shares</dt>
                <dd>
                  Has the same meaning as Eligible Shares. Shares of an Issuer who has successfully
                  funded through the Platform.
                </dd>

                <dt>SSM</dt>
                <dd>Companies Commission of Malaysia (Suruhanjaya Syarikat Malaysia).</dd>

                <dt>Substantial Shareholder</dt>
                <dd>
                  Any shareholder with 20% or more equity in a company hosted on the secondary
                  market.
                </dd>

                <dt>Traders</dt>
                <dd>
                  Means either Eligible Buyers and/or Eligible Sellers of the PSTX collectively.
                </dd>

                <dt>Trading Session</dt>
                <dd>The period when the market is open and matching of orders can take place.</dd>

                <dt>User</dt>
                <dd>
                  Means any person who browses, visits, accesses and/or uses the Platform and/or
                  PSTX, whether active, semi-active or inactive users, and includes the Issuer and
                  Investor that has successfully registered on pitchIN and furnished all required
                  information.
                </dd>
              </dl>
            </li>

            <li>
              <!-- PURPOSE and SCOPE -->
              <h2 class="tw-uppercase">Purpose and Scope</h2>

              <ol>
                <!-- 1.1 -->
                <li>
                  <p>
                    The PSTX functions as a platform for the trading of Eligible Shares of Issuers
                    that have been hosted and successfully funded via ECF on the Platform.
                  </p>
                </li>
                <!-- 1.2 -->
                <li>
                  <p>
                    The aim of PSTX is to create a platform where Investors can trade Eligible
                    Shares and thus improve liquidity in the Eligible Shares of Issuers as well as
                    to promote increased participation in the Platform in a secure, fair and
                    transparent manner for the benefit of the Issuer, the Eligible Buyer, the
                    Eligible Seller and the Platform in parallel.
                  </p>
                </li>
                <!-- 1.3 -->
                <li>
                  <p>
                    The role of the Platform Operator is that of a facilitator and coordinator for
                    the trading between Eligible Buyers and Eligible Sellers in a secure, fair and
                    transparent manner.
                  </p>
                </li>
                <!-- 1.4 -->
                <li>
                  <p>
                    The Platform Operator does not in whatsoever and howsoever manner provide any
                    advice, including but not limited to investment, legal, taxation or other
                    advice, with respect to any aspect of the transactions conducted through PSTX.
                  </p>
                </li>
                <!-- 1.5 -->
                <li>
                  <p>
                    The Platform Operator makes no representation and warranty in whatsoever and
                    howsoever manner on the credit worthiness of the Issuers and viability of any
                    business whose shares are traded on PSTX. The Eligible Buyers and Eligible
                    Sellers should consult with their own professional tax, legal and financial
                    advisors before making any transactions on the Platform as investing in this
                    asset class involves a deal of risk.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- COMMUNICATIONS -->
              <h2 class="tw-uppercase">Communications</h2>

              <ol>
                <!-- 1.1 -->
                <li>
                  <p>
                    Participants will provide true, accurate, current, and complete contact
                    information as may be prompted by any registration forms or information requests
                    on the Platform.
                  </p>
                </li>
                <!-- 1.2 -->
                <li>
                  <p>
                    Notices to relevant participants will be sent by email, the Platform, and/or
                    text messages.
                  </p>
                </li>
                <!-- 1.3 -->
                <li>
                  <p>
                    Any notice sent out by the platform shall be deemed delivered when sent to the
                    contact information on record at the platform system.
                  </p>
                </li>
                <!-- 1.4 -->
                <li>
                  <p>
                    The Participant agrees to receive electronically all documents, communications,
                    notices, contracts, and agreements, schedules, or information statements,
                    arising from or relating to the Participant’s registration on the Platform.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- PARTICIPANTS OF THE PSTX -->
              <h2 class="tw-uppercase">Participants of the PSTX</h2>

              <ol>
                <!-- User -->
                <li>
                  <b>User</b>
                  <p>A registered user of the PSTX;</p>
                  <ol>
                    <!-- 1.1 -->
                    <li>
                      <p>
                        A registered user of the PSTX; means any person who browses, visits,
                        accesses and/or uses the Platform and/or PSTX, whether active, semi-active
                        or inactive users, and includes the Issuer.
                      </p>
                    </li>
                    <!-- 1.2 -->
                    <li>
                      <p>
                        Means the persons who are 18 years of age and/or any duly incorporated legal
                        entity; and
                      </p>
                    </li>
                    <!-- 1.3 -->
                    <li>
                      <p>
                        Who has furnished all the necessary information requested by the Platform.
                      </p>
                    </li>
                  </ol>
                </li>
                <!-- Eligible Issuer -->
                <li>
                  <b>Eligible Issuer</b>
                  <p>
                    Means an Issuer that is eligible to be hosted on PSTX in accordance with the
                    Guidelines and PSTX Rulebook.
                  </p>
                </li>
                <!-- Eligible Seller -->
                <li>
                  <b>Eligible Seller</b>
                  <p>
                    A SSM Shareholder and/or an Investor of the Issuer who is eligible to place Sell
                    Orders on PSTX.
                  </p>
                </li>
                <!-- Eligible Buyer -->
                <li>
                  <b>Eligible Buyer</b>
                  <p>Means any registered User of the PSTX who places a bid on the PSTX.</p>
                </li>
                <!-- Trading Limits -->
                <li>
                  <b>Trading Limits</b>
                  <p>
                    Buyers must abide by the investment and/or trading limits set out by the SC for
                    ECF in the Guidelines, as the following, are applicable to local and foreign
                    investors:
                  </p>
                  <ol>
                    <!-- 1.1 -->
                    <li>
                      <b>Sophisticated investors</b>
                      <p>No restrictions on investment amount;</p>
                    </li>
                    <!-- 1.2 -->
                    <li>
                      <b>Angel investors</b>
                      <p>A maximum of RM500,000 within a 12-month period; and</p>
                    </li>
                    <!-- 1.3 -->
                    <li>
                      <b>Retail investors</b>
                      <p>
                        A maximum of RM5,000 per issuer with a total amount of not more than
                        RM50,000 within a 12-month period.
                      </p>
                    </li>
                  </ol>
                </li>
                <!-- Pitch Nominees -->
                <li>
                  <b>Pitch Nominees</b>
                  <ol>
                    <li>
                      <p>
                        Means Pitch Nominees Sdn Bhd [Registration No. 201601017095 (1188026-P)].
                      </p>
                    </li>
                    <li>
                      <p>
                        For the purposes of PSTX, Pitch Nominees is the nominee shareholder
                        registered in SSM as part of the Nominee Structure in facilitating trading
                        in PSTX.
                      </p>
                    </li>
                  </ol>
                </li>
                <!-- Direct Shareholders -->
                <li>
                  <b>Direct Shareholders</b>
                  <p>
                    Means the shareholders of an Issuer that holds Eligible Shares in the Issuer and
                    is registered directly in the Issuer’s shareholding via SSM, which may include
                    the Promoters.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <!-- PRODUCTS HOSTED -->
              <h2 class="tw-uppercase">Products Hosted</h2>

              <ol>
                <!-- 1.1 -->
                <li>
                  <p>
                    The PSTX will only host the same class and/or classes of shares that were
                    offered to Investors by the Issuers in the respective Issuer’s ECF campaign on
                    the Platform.
                  </p>
                </li>
                <!-- Explanation -->
                <li>
                  <b>Explanation</b>
                  <ol>
                    <!-- 1.1 -->
                    <li>
                      <p>
                        If an Issuer offered Redeemable Preference Shares (RPS) during their ECF
                        campaign, then only their RPS shares will be tradeable on the PSTX, while
                        the ordinary shares of the Issuer held by any other shareholder of the
                        Issuer will not be tradeable on PSTX.
                      </p>
                    </li>
                    <!-- 1.2 -->
                    <li>
                      <p>
                        If an Issuer offered ordinary shares during their ECF campaign, then all of
                        the Issuer’s ordinary shares will be eligible to be tradeable on PSTX.
                      </p>
                    </li>
                    <!-- 1.3 -->
                    <li>
                      <p>
                        If an Issuer issued two or more classes of shares (for example ordinary
                        shares and RPS shares) during an ECF campaign or subsequent ECF campaigns,
                        the PSTX may host the ordinary shares and RPS shares as separate trading
                        counters on the PSTX.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <!-- RISKS -->
              <h2 class="tw-uppercase">Risks</h2>

              <ol>
                <!-- 1.1 -->
                <li>
                  <p>
                    The Eligible Buyers and the Eligible Sellers acknowledge that they understand
                    the risks associated with investing in Issuers and that they may lose all or
                    part of their invested capital.
                  </p>
                </li>
                <!-- 1.2 -->
                <li>
                  <p>
                    The Platform is secured by sophisticated cyber security that maintain the safety
                    and stability of all activities and transactions. However, the Platform may be
                    temporarily unavailable from time to time for maintenance or other reasons. The
                    Platform Operator does not warrant the use of and access to the Platform will be
                    uninterrupted, error free or free of computer viruses or computer bugs or that
                    defects will be corrected, or give any warranty as to their security,
                    functionality, accuracy or reliability. The Platform Operator assumes no
                    responsibility for any monetary losses, error, omission, interruption, deletion,
                    defect, delay in operation or transmission.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- COMPLAINTS AND DISPUTES -->
              <h2 class="tw-uppercase">Complaints and Disputes</h2>

              <ol>
                <!-- 1.1 -->
                <li>
                  <p>
                    In the event the User has any complaints with respect to any aspect of the
                    Platform, the User may report it to the Platform Operator at the contact details
                    provided on the Platform. The Platform Operator shall on a best effort basis
                    respond to the complaints within seven (7) Business Days upon receipt of the
                    complaints.
                  </p>
                </li>
                <!-- 1.2 -->
                <li>
                  <p>
                    In the event of a dispute regarding the interpretation of the rules that govern
                    PSTX, the Platform Operator shall make a final and conclusive decision.
                  </p>
                </li>
                <!-- 1.3 -->
                <li>
                  <p>
                    When a concern regarding a trade or trades is brought to the attention of the
                    Operator within thirty (30) days of its occurrence, the Platform Operator shall
                    conduct an investigation and make known its response to the party concerned
                    within seven (7) Business Days upon receipt of the concern.
                  </p>
                </li>
                <!-- 1.4 -->
                <li>
                  <p>
                    If a dispute or difference shall arise amongst the Platform Operator and a User,
                    a single arbitrator acceptable by all the parties in dispute will be appointed.
                    Failing that, a single arbitrator to be appointed from the Asian International
                    Arbitration Centre (AIAC).
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- COMMENCEMENT OF TRADING -->
              <h2 class="tw-uppercase">Commencement of Trading</h2>

              <ol>
                <!-- 1.1 -->
                <li>
                  <b>Issuer’s Shares Trading Eligibility</b>
                  <ol>
                    <!-- 1.1.1 -->
                    <li>
                      <p>
                        The Trading of an Issuer’s Eligible Shares may commence anytime upon the
                        expiry of the sixth (6th) month from the date of the allotment of the
                        Issuer’s shares to the Investor and/or its nominee pursuant to the ECF
                        campaign.
                      </p>
                    </li>
                    <!-- 1.1.2 -->
                    <li>
                      <p>
                        Shares held by a Promoter shall be allowed to be traded on the PSTX upon the
                        expiry of the sixth (6th) month from the date of the first completed
                        transaction of the Eligible Shares of the Issuer being traded on the PSTX.
                      </p>
                    </li>
                  </ol>
                </li>
                <!-- 1.2 -->
                <li>
                  <b>PSTX Account</b>
                  <ol>
                    <!-- 1.2.1 -->
                    <li>
                      <p>
                        A PSTX Account is the User’s account on PSTX used to store funds for the
                        purposes and/or use for Trading on PSTX.
                      </p>
                    </li>
                    <!-- 1.2.2 -->
                    <li>
                      <p>
                        To begin Trading, Users are required to activate their respective PSTX
                        Accounts.
                      </p>
                    </li>
                  </ol>
                </li>
                <!-- 1.3 -->
                <li>
                  <b>Order Book</b>
                  <p>
                    An Order Book is a list of all open and/or unmatched Orders of Eligible Shares
                    for each Issuer listed on PSTX.
                  </p>
                </li>
                <!-- 1.4 -->
                <li>
                  <b>Indicative Share Price</b>
                  <ol>
                    <!-- 1.4.1 -->
                    <li>
                      <p>
                        An Indicative Share Price is the price of the Eligible Shares at the start
                        of an Issuer’s listing on the PSTX;
                      </p>
                    </li>
                    <!-- 1.4.2 -->
                    <li>
                      <p>
                        An Indicative Share Price of the respective Eligible Shares’ counter will be
                        provided as a guide for Traders on the PSTX, which may be determined through
                        one of the following:
                      </p>
                      <div class="alpha-list">
                        <ol>
                          <li>
                            <p>ECF Share Price;</p>
                          </li>
                          <li>
                            <p>Company Valuation Report;</p>
                          </li>
                          <li>
                            <p>Major secondary trade(s); and/or</p>
                          </li>
                          <li>
                            <p>Subsequent funding rounds of the Issuer.</p>
                          </li>
                        </ol>
                      </div>
                    </li>
                  </ol>
                </li>
                <!-- 1.5 -->
                <li>
                  <b>Last Traded Price</b>
                  <ol>
                    <li>
                      <p>
                        Last Traded Price is the actual price at which Eligible Shares are Traded
                        between Traders on PSTX.
                      </p>
                    </li>
                    <li>
                      <p>
                        The Last Trade Price reflects the real-time price movement of the Bid and
                        Ask of an Issuer’s Eligible Shares.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <!-- 1 -->
              <h2 class="tw-uppercase">Trading Cycle</h2>

              <ol>
                <!-- 1.1 -->
                <li>
                  <b>PSTX Opening</b>
                  <p>PSTX will open on the Trading Days as provided in the Operating Schedule.</p>
                </li>
                <!-- 1.2 -->
                <li>
                  <b>Trading Hours</b>
                  <p>
                    The Trading Hours occur on the Trading Days as provided in the Operating
                    Schedule.
                  </p>
                </li>
                <!-- 1.3 -->
                <li>
                  <b>Pre-Trading Days and Hours</b>
                  <p>
                    Pre-Trading Days and Pre-Trading Hours are defined and understood as provided in
                    the Operating Schedule.
                  </p>
                </li>
                <!-- 1.4 -->
                <li>
                  <b>Post-Trading Day</b>
                  <p>
                    Post-Trading Day is defined and understood as provided in the Operating
                    Schedule.
                  </p>
                </li>
                <!-- 1.5 -->
                <li>
                  <b>Public Holidays</b>
                  <ol>
                    <li>
                      <p>
                        PSTX will be closed for all public holidays observed in the Federal
                        Territory of Kuala Lumpur that falls on a Wednesday or a Thursday.
                      </p>
                    </li>
                    <li>
                      <p>
                        If a public holiday falls on a Wednesday of any given week, the PSTX will
                        only operate on the Thursday of that given week.
                      </p>
                    </li>
                    <li>
                      <p>
                        If both Wednesday and Thursday of any given week are public holidays, the
                        PSTX shall not operate on that week.
                      </p>
                    </li>
                    <li>
                      <p>
                        Notice of non-trading during a public holiday will be given to all Users.
                      </p>
                    </li>
                  </ol>
                </li>
                <!-- 1.6 -->
                <li>
                  <b>Trading Hours Change</b>
                  <p>
                    The Trading Hours are subject to change by the Platform Operator with prior
                    notification of at least three (3) Business Days to be posted in the Platform.
                  </p>
                </li>
                <!-- 1.7 -->
                <li>
                  <b>Order Entry</b>
                  <ol>
                    <li>
                      <p>
                        With the aims of facilitating price discovery as well as creating a
                        transparent and orderly market for the PSTX, Orders placed for the week can
                        be entered by Users starting from the Order Entry Days, provided in the
                        Operating Schedule.
                      </p>
                    </li>
                    <li>
                      <p>
                        With the same aim of market transparency, the PSTX will continue to display
                        all unmatched Orders until the Order Book Reset, as provided in the
                        Operating Schedule.
                      </p>
                    </li>
                    <li>
                      <p>
                        New Order entries will be allowed in accordance with the Order Entry Days.
                      </p>
                    </li>
                    <li>
                      <p>All Orders will be visible to Users on the PSTX.</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Operating Schedule</b>
                  <dl class="legal-description-list">
                    <dt>Order Entry Days</dt>
                    <dd>Starting from Tuesdays (10:00 AM) until Thursdays (5:00 PM).</dd>

                    <dt>Pre-Trading Days</dt>
                    <dd>
                      Refer to the day(s) during the Order Entry Days prior to the Trading Days.
                    </dd>

                    <dt>Pre-Trading Hours</dt>
                    <dd>
                      Refer to the time during the Order Entry Days prior to the Trading Hours.
                    </dd>

                    <dt>Trading Days</dt>
                    <dd>Wednesdays and Thursdays.</dd>

                    <dt>Trading Hours</dt>
                    <dd>10:00 AM – 5:00 PM on the Trading Days.</dd>

                    <dt>Post-Trading Day</dt>
                    <dd>
                      Refers to the Fridays after the Trading Days prior to the Order Book Reset.
                    </dd>

                    <dt>Order Book Reset</dt>
                    <dd>Fridays (10:00 PM).</dd>

                    <dt>MYR Deposits</dt>
                    <dd>
                      Processed daily during business hours (09.30 AM – 04.30 PM).
                      <br /><br />
                      Investor account balances will be updated upon sighting the incoming deposit
                      in the Trust Account.
                    </dd>

                    <dt>MYR Withdrawals</dt>
                    <dd>
                      Processed daily during business hours (09:30 AM – 06:00 PM).<br /><br />
                      Arrival of funds in investors' bank accounts are subject to processing times
                      of relevant banks and bank transaction hours.
                    </dd>
                  </dl>
                </li>
                <li>
                  <b> Scheduled Downtime </b>

                  <p>
                    The Platform Operator shall notify all Users through email and/or by posting a
                    notice on the platform at least forty-eight (48) hours in advance prior to any
                    downtime caused by a scheduled maintenance of the PSTX.
                  </p>
                </li>

                <li>
                  <b> Unscheduled Downtime </b>

                  <ol>
                    <!-- 1 -->
                    <li>
                      <p>
                        PSTX will inform all Users in the event an unscheduled downtime occurs
                        through email and/or by displaying on the landing page of the Platform.
                        Information will contain support channel contact details and estimated
                        recovery time.
                      </p>
                    </li>
                    <!-- 2 -->
                    <li>
                      <p>
                        Further updates will be sent via email and/or displayed on the landing page
                        of the Platform if the downtime is prolonged beyond an initial estimated
                        recovery time.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h2>FEES</h2>
              <ol>
                <li>
                  <p>
                    Fees will be charged to Buyers and Sellers when they trade the Shares of the
                    Issuers.
                  </p>
                </li>
                <li>
                  <p>PSTX Trading Fee Structure will be as follows:</p>
                  <ol>
                    <li>
                      <p>
                        <b>Trading Fees for Trades on PSTX</b>
                        (based on the traded value of the traded Shares in aggregate)
                      </p>
                      <p>
                        <b>NOTE:</b> The Platform Operator is giving a 50% discount for all trades
                        on PSTX until further notice. Minimum fee of RM50 remains.
                      </p>
                      <table>
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">Trading Fees Charged to Sellers</th>
                            <th scope="col">Trading Fees Charged to Buyers</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">For Trades below RM50,000.00</th>
                            <td>
                              <ul id="list-table">
                                <li>3.5% Trading Fee to Sellers</li>
                                <li>Minimum Fee RM50.00</li>
                              </ul>
                            </td>
                            <td>
                              <ul id="list-table">
                                <li>2.5% Trading Fee to Sellers</li>
                                <li>Minimum Fee RM50.00</li>
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">For Trades that are RM50,000.00 and above</th>
                            <td>
                              <ul id="list-table">
                                <li>3.0% Trading Fee to Sellers</li>
                                <li>Minimum Fee RM50.00</li>
                              </ul>
                            </td>
                            <td>
                              <ul id="list-table">
                                <li>2.0% Trading Fee to Sellers</li>
                                <li>Minimum Fee RM50.00</li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </li>
                    <li>
                      <p>
                        <b> Trading Fees for Trades Outside of PSTX </b>
                        (based on the traded value of the traded Shares in aggregate)
                      </p>
                      <p>
                        <b> NOTE: </b>
                        The Platform Operator is giving a 50% discount for all trades on PSTX until
                        further notice. Minimum fee of RM50 remains.
                      </p>
                      <table>
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">Trading Fees Charged to Sellers</th>
                            <th scope="col">Trading Fees Charged to Buyers</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">
                              Existing Direct Shareholders of Issuers, who are transferring their
                              shares to a potential Buyer who will also be listed as a Direct
                              Shareholder
                            </th>
                            <td>No Fees</td>
                            <td>No Fees</td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Direct Shareholders who are transferring their shares to a potential
                              Buyer who will become part of the Nominee Structure
                            </th>
                            <td>
                              Direct Shareholders who are transferring their shares to a potential
                              Buyer who will become part of the Nominee Structure
                            </td>
                            <td>No Fees</td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Trades occurring between Buyers and Sellers whose shares are held by
                              the Nominee Structure
                            </th>
                            <td>
                              Amount equivalent to fifty percent (50%) of the Trading Fees Charged
                              to Sellers based on the Fees for Trades on PSTX
                            </td>
                            <td>
                              Amount equivalent to fifty percent (50%) of the Trading Fees Charged
                              to Buyers based on the Fees for Trades on PSTX
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </li>
                    <li>
                      <b> Costs </b>
                      <p>
                        For any and all other costs that may become chargeable including but not
                        limited to stamp duty, company secretary fees, third-party transfer fees,
                        bank charges and so on will be charged to the relevant participant. As an
                        example, in the event stamp duty is imposed on the transfer, the total
                        transacted consideration which need to be paid by the Buyer will have to
                        include the purchase price, the transaction fee and the stamp duty.
                      </p>
                    </li>
                    <li>
                      <p>
                        All fees are subject to revisions by the Platform Operator from time to time
                        as it deems necessary, including but not limited to, promotional purposes of
                        the PSTX. Any revisions will be published on the PSTX and notice will be
                        sent to Users.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h2>TRADING HALT, SUSPENSION AND DEHOSTING OF ISSUER FROM PSTX</h2>
              <ol>
                <li>
                  <b>Trading Halt </b>
                  <ol>
                    <li>
                      <p>
                        An Issuer can request for a trading halt on its shares for up to two (2)
                        days of the Trading Days to facilitate an announcement which it reasonably
                        believes will have impact on trading of its shares.
                      </p>
                    </li>
                    <li>
                      <p>
                        The Issuer shall request for a suspension of trading in writing with details
                        of the reason for the halt. If approved, a notice shall be published and
                        sent to all users.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <b> Market Suspension by the Platform Operator</b>
                  <ol>
                    <li>
                      <p>
                        The Platform Operator retains the right to suspend the operations of the
                        PSTX if it concludes that an emergency event has taken place. The Platform
                        Operator has the authority to determine when an emergency event has occurred
                        whether such event is under the control of the Platform Operator or
                        otherwise.
                      </p>
                    </li>
                    <li>
                      <p>
                        During Market Suspension, all existing orders will be maintained at the
                        Order Book until the Order Book Reset. No new Orders can be entered and
                        matched throughout the period. However, Orders can be removed during this
                        period.
                      </p>
                    </li>
                    <li>
                      <p>
                        Once the Market Suspension has ended, Users will be able to input new Orders
                        twenty-four (24) hours before the next Trading Day.
                      </p>
                    </li>
                    <li>
                      <p>
                        All Users will be notified of the Market Suspension including the reason(s)
                        and duration by the Platform Operator.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Issuer Suspension by the Platform Operator</b>
                  <ol>
                    <li>
                      <p>
                        The Platform Operator may also suspend trading of a particular Issuer’s
                        market or particular transaction(s) if it concludes that it is in the best
                        interests of the Users and especially the minority shareholders of the
                        Issuers, to do so.
                      </p>
                    </li>
                    <li>
                      <p>Suspension may be imposed in following cases:</p>

                      <div class="alpha-list">
                        <ol>
                          <li>
                            <p>
                              Securities discrepancy concerns (E.g.: the Issuer has issued
                              additional shares without following procedures);
                            </p>
                          </li>
                          <li>
                            <p>Company trading status is unknown;</p>
                          </li>
                          <li>
                            <p>
                              There is reason to believe that market moving information is being
                              held back from the Platform Operator and/or the Users;
                            </p>
                          </li>
                          <li>
                            <p>The Issuer is not responding to communications; and/or</p>
                          </li>
                          <li>
                            <p>
                              Processing of bulk (large) Orders that represents twenty percent (20%)
                              or more in equity ownership of the Issuer, whereby manual reviews
                              would determine that existing shareholders’ engagement needs to be
                              held.
                            </p>
                          </li>
                        </ol>
                      </div>
                    </li>
                    <li>
                      <p>
                        When an Issuer suspension is decided, the Platform Operator shall publish
                        and send a notice to all Users informing of the suspension of the entire
                        PSTX or particular Issuers, the reason(s) for the Trading Halt and the
                        number of days of the suspension.
                      </p>
                    </li>

                    <li>
                      <p>
                        During Issuer Suspension, all existing orders will be maintained at the
                        Order Book until the Order Book Reset. No new orders can be entered and
                        matched throughout the period. However, orders can be removed during this
                        period.
                      </p>
                    </li>

                    <li>
                      <p>
                        Once the Issuer Suspension has ended, new Orders can only be entered
                        twenty-four (24) hours before the next Trading Day.
                      </p>
                    </li>
                    <li>
                      <p>
                        Trading will resume on the next Trading Day following the end of the
                        suspension.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Dehosting of Issuers</b>
                  <ol>
                    <li>
                      <p>
                        The Platform Operator may also suspend trading of a particular Issuer’s
                        market or particular transaction(s) if it concludes that it is in the best
                        interests of the Users and especially the minority shareholders of the
                        Issuers, to do so.
                      </p>
                    </li>
                    <li>
                      <b> Dehosting of Issuers by the PSTX Operator </b>
                      <div class="alpha-list">
                        <ol>
                          <li>
                            <p>
                              The Operator may dehost an Issuer if other trading options become
                              available or if it concludes that it is in the best interest of
                              minority shareholders.
                            </p>
                          </li>
                          <li>
                            <p>
                              In each instance, pitchIN will consult with the Issuer and
                              shareholders, and if necessary, the SC before deciding on dehosting.
                            </p>
                          </li>
                          <li>
                            <p>
                              In each instance, pitchIN will consult with the Issuer and
                              shareholders, and if necessary, the SC before deciding on dehosting.
                            </p>
                          </li>
                          <li>
                            <p>
                              Some instances where pitchIN may initiate de-hosting process are as
                              follows:
                            </p>
                            <div class="lowerroman-list">
                              <ol>
                                <li>
                                  <p>
                                    Company acquired by another entity (after SHA clauses are
                                    complied with);
                                  </p>
                                </li>
                                <li>
                                  <p>IPO – all shares tradeable on other exchanges;</p>
                                </li>
                                <li>
                                  <p>Merger (Operator will review and decide on best option);</p>
                                </li>
                                <li>
                                  <p>
                                    Drag Along rights in a Shareholders Agreement are exercised;
                                    and/or
                                  </p>
                                </li>
                                <li>
                                  <p>Liquidation of the entity.</p>
                                </li>
                              </ol>
                            </div>
                          </li>
                        </ol>
                      </div>
                    </li>
                    <li>
                      <b>Dehosting of Issuers by the Securities Commission</b>
                      <p>
                        The Operator will at all times comply with any request from the Securities
                        Commission without undue delay.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h2>FORCE MAJEURE</h2>
              <ol>
                <li>
                  <p>
                    The Platform Operator shall not be liable to any person and shall not be deemed
                    to be in breach of these terms and conditions by reason of any delay in
                    performing or failure to perform any of its obligations under these terms and
                    conditions if the delay or failure was caused by an event of force majeure.
                  </p>
                </li>
                <li>
                  <p>
                    Without prejudice to the generality of the foregoing provision, force majeure
                    shall include but not limited to the following:
                  </p>
                  <ol>
                    <li>
                      <p>
                        Act of God, including but not limited to fires, explosions, earthquakes,
                        drought, tidal waves and floods, or accident;
                      </p>
                    </li>
                    <li>
                      <p>
                        War, threat of war, act of terrorism or threat of terrorism, sabotage,
                        insurrection, civil disturbance or requisition;
                      </p>
                    </li>

                    <li>
                      <p>
                        Restrictions imposed by any law, regulations, by-laws, prohibitions or
                        measures of any kind on the part of any government, parliamentary or local
                        authority;
                      </p>
                    </li>

                    <li>
                      <p>
                        Interruption of traffic, strikes, lock-outs, or other industrial actions or
                        trade disputes (whether involving the employees of the Platform Operator or
                        third party);
                      </p>
                    </li>

                    <li>
                      <p>
                        Breakdown of internet services for any reason whatsoever, save for the
                        wilful misconduct of the Platform Operator;
                      </p>
                    </li>

                    <li>
                      <p>
                        Other unforeseeable circumstances beyond the control of the Platform
                        Operator against which it would have been unreasonable for the Platform
                        Operator to take precautions and which the Platform Operator cannot avoid
                        even by using its best efforts;
                      </p>
                    </li>

                    <li>
                      <p>
                        Upon the occurrence of any force majeure event, the performance of the
                        Platform Operator’s obligations under these terms shall be suspended during
                        that period of force majeure and the Platform Operator shall be granted an
                        extension of time for performance equal to the period of delay.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h2>DISCLAIMERS</h2>
              <ol>
                <li>
                  <p>Each User shall only be permitted to open one account on the Platform.</p>
                </li>

                <li>
                  <p>
                    Access to the Platform is permitted on a temporary basis subject to these terms
                    and conditions, other terms and conditions, agreement or rules governing the
                    specific services provided by the Platform Operator. The Platform Operator
                    reserves the right to terminate, withdraw, amend or suspend the service provided
                    or the account on the Platform without notice. The Platform Operator will
                    endeavour to allow uninterrupted access to the Platform, but access may be
                    suspended, restricted or terminated from time to time. The Platform Operator
                    shall not be liable to the Issuer and Investor if for any reason the Platform is
                    unavailable at any time or for any period.
                  </p>
                </li>

                <li>
                  <p>
                    The Platform (and the contents thereof) are not intended for distribution to, or
                    use by, any person or entity in any jurisdiction where such distribution or use
                    would be contrary to applicable law or regulation.
                  </p>
                </li>

                <li>
                  <p>
                    The Platform Operator shall not be responsible or liable for the content or
                    accuracy of any information or material downloaded or posted by the Users nor
                    shall the Platform Operator be obliged to edit the content downloaded or posted
                    on the Platform. However, the Platform Operator shall retain the right to remove
                    any material or posting made on the Platform at its absolute discretion.
                  </p>
                </li>

                <li>
                  <p>
                    The User shall use the Platform only for lawful purposes and in a way which does
                    not infringe the rights of any other person or restrict or inhibit any person’s
                    use or enjoyment of the Platform. The User shall not use the Platform or any
                    information accessible on or obtained from it for the purpose of canvassing or
                    soliciting any person or enticing any person away from using the services on our
                    Platform.
                  </p>
                </li>

                <li>
                  <p>
                    Each User warrants that it has taken all reasonable precautions to ensure that
                    any data it uploaded or otherwise submitted to the Platform is free from
                    viruses, Trojans, worms or other material which may have a contaminating or
                    destructive effect on any part of the Platform.
                  </p>
                </li>

                <li>
                  <p>
                    The Platform Operator accepts no responsibility or liability for the User’s use
                    of content on the Platform and such use is entirely at the User’s own risk.
                    While the Platform Operator takes reasonable precautions to prevent the
                    existence of computer viruses or other malicious programs on the Platform, the
                    Platform Operator accepts no liability for them if they do exist. The User shall
                    use, update and maintain appropriate antivirus software on their computer.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h2>LIABILITY</h2>
              <ol>
                <li>
                  <p>
                    The User shall be liable to the Platform Operator for any loss or damage, costs,
                    expenses, liabilities, fines, penalties, demands, or claims of any nature
                    whatsoever suffered by the Platform Operator as a result of any breach of these
                    terms or the fraudulent use of the Platform by the User or its employees,
                    officers, servants, agents or representatives.
                  </p>
                </li>

                <li>
                  <p>
                    The User agrees to indemnify the Platform Operator against all liabilities,
                    costs, expenses, damages and losses (including but not limited to any direct,
                    indirect or consequential losses, loss of profit, loss of reputation and all
                    interest, penalties and legal costs and all other professional costs and
                    expenses) suffered or incurred by, arising out of or in connection with any
                    breach by the User or its employees, officers, servants, agents or
                    representatives of these terms and other terms and conditions as may be
                    formulated from time to time by the Platform Operator.
                  </p>
                </li>

                <li>
                  <p>
                    The Platform Operator shall not be liable to the User for any loss or damage,
                    costs, expenses, liabilities, fines, penalties, demands, or claims of any nature
                    whatsoever which the User may suffer as a result of using the services provided
                    via the Platform except where such loss or damage arises from the Platform
                    Operator’s breach of these terms or was caused by negligence, wilful default or
                    fraud by the Platform Operator or its employees.
                  </p>
                </li>

                <li>
                  <p>
                    The Platform Operator makes no warranties or representations and assume no
                    liability in respect of the User for the content of any other documents
                    submitted to the Platform Operator. The Platform Operator bly advises the User
                    to seek independent advice in respect thereof and the User shall be personally
                    liable for all costs and expenses in respect of such advice.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h2>GENERAL</h2>
              <ol>
                <li>
                  <p>
                    Nothing herein shall be construed to create a partnership, joint venture, agency
                    or employment relationship between the parties. No party has authority to enter
                    into agreements of any kind on behalf of the other party.
                  </p>
                </li>

                <li>
                  <p>
                    No failure on the part of any party to exercise and no delay on the part of any
                    party in exercising any right hereunder will operate as a release or waiver
                    thereof, nor will any single or partial exercise of any right under these terms
                    preclude any other or further exercise of it.
                  </p>
                </li>

                <li>
                  <p>
                    No remedy conferred by any of the provisions of these terms is intended to be
                    exclusive of any other remedy which is otherwise available at law, in equity, by
                    statute or otherwise, and each and every other remedy shall be cumulative and
                    shall be in addition to every other remedy given hereunder or now or hereafter
                    existing at law, in equity, by statute or otherwise. The election of any one or
                    more of such remedies by any of the parties shall not constitute a waiver by
                    such party of the right to pursue any other available remedies.
                  </p>
                </li>

                <li>
                  <p>
                    The Platform Operator reserves the right to amend, vary and/or supplement these
                    terms from time to time. Any amendments, variations and/or supplements to these
                    terms shall be effective when posted on the Platform. The User’s continued use
                    of the Platform on or after the date on which such amendments, variations and/or
                    supplements come into effect shall be construed as the User’s agreement to be
                    bound by the amended terms.
                  </p>
                </li>

                <li>
                  <p>
                    The communications between the Platform Operator and the User shall be made
                    through the Platform, or mailed, personally delivered, faxed or emailed to the
                    recipient at the current address held in the Platform Operator’s record.
                  </p>
                </li>

                <li>
                  <p>
                    These terms shall be binding upon the respective successors-in-title and
                    permitted assigns of the parties. The User shall not assign or transfer its
                    rights, title, interests and benefits under these terms or any of its
                    liabilities and obligations hereunder without the prior written consent of the
                    Platform Operator.
                  </p>
                </li>

                <li>
                  <p>
                    Insofar as these terms are inconsistent with any other terms and conditions,
                    agreement or rulebook established by the Platform Operator to govern the
                    specific service provided to the User, the more relevant and specific terms or
                    document shall apply.
                  </p>
                </li>

                <li>
                  <p>
                    If any provision of these terms or part thereof is or may become under any
                    written law, or is found by any court or administrative body or competent
                    jurisdiction to be illegal, void, invalid, prohibited or unenforceable then:
                  </p>
                  <ol>
                    <li>
                      <p>
                        such provision or part thereof shall be read down or severed only to the
                        extent of such illegality, voidness, invalidity, prohibition or
                        unenforceability;
                      </p>
                    </li>

                    <li>
                      <p>
                        the illegality, voidness, invalidity, prohibition or unenforceability of any
                        provision or part thereof in any jurisdiction shall not affect the legality,
                        validity or enforceability of any other provision or of that provision in
                        any other jurisdiction, and the remaining provisions of these terms shall
                        remain in full force and effect; and
                      </p>
                    </li>

                    <li>
                      <p>
                        the parties shall use their respective best endeavours to negotiate and
                        agree on a substitute provision which is valid and enforceable and achieves
                        to the greatest extent possible the economic, legal and commercial
                        objectives of such illegal, void, invalid, prohibited or unenforceable term,
                        condition, stipulation, provision, covenant or undertaking.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    The parties hereby agree that the provisions contained in these terms and other
                    terms and conditions as may be formulated by the Platform Operator from time to
                    time shall constitute the entire agreement between the parties in respect of the
                    subject matter of these terms and supersede all previous memoranda,
                    expectations, understandings, communications, representations and agreements
                    whether oral or written between them in respect of the subject matter hereof.
                  </p>
                </li>

                <li>
                  <p>
                    These terms shall be governed by and construed in accordance with the laws of
                    Malaysia.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <p>
          You may download the
          <a
            href="https://storage.pitchin.my/download/PSTX-rulebook-(26JUN2024).pdf"
            target="_blank"
            >PSTX Rulebook</a
          >
          for further information.
        </p>
        <p>Last updated: 26 June 2024</p>
      </div>
    </div>
  </div>
</section>
