<div class="section !tw-pb-0">
  <div class="container-fluid">
    <article
      class="tw-overflow-hidden tw-rounded-3xl tw-border tw-border-divider tw-bg-gradient-to-b tw-from-white tw-to-[#D0DBEC] tw-p-10 lg:tw-relative lg:tw-flex lg:tw-h-[500px] lg:tw-items-center"
    >
      <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-4 lg:tw-w-8/12 lg:tw-pr-4">
        <div class="tw-w-full">
          <p
            class="p-text-preheader tw-mb-1 tw-font-heading tw-text-lg tw-font-bold tw-tracking-widest"
          >
            {{ 'landing.partnerPreheader' | translate }}
          </p>
          <h2 class="tw-font-heading tw-text-3xl tw-font-bold lg:tw-text-4xl">
            {{ 'landing.partnerHeader' | translate }}
          </h2>
          <p
            class="tw-w-full tw-font-body tw-text-base !tw-font-normal tw-text-black tw-opacity-80"
          >
            {{ 'landing.partnerDescription' | translate }}
          </p>
        </div>
        <section
          class="logos tw-relative tw-w-full tw-overflow-hidden tw-whitespace-nowrap lg:tw-hidden"
        >
          <div class="logos-slide">
            <div class="tw-flex tw-gap-4">
              <ng-container *ngFor="let slide of doublePartners">
                <img
                  src="assets/img/partners/{{ slide.src }}"
                  alt="{{ slide.name }}"
                  class="tw-z-30 tw-h-60"
                />
              </ng-container>
            </div>
          </div>
        </section>
        <a
          routerLink="/partner"
          class="tw-mt-4 tw-w-fit tw-self-center tw-rounded-md tw-border tw-bg-primary tw-px-4 tw-py-2 tw-text-center tw-text-base tw-font-semibold tw-tracking-wide tw-text-white hover:tw-bg-opacity-80 hover:tw-text-white lg:tw-mt-0 lg:tw-self-start"
          >{{ 'partnership.explore' | translate }} &#x2192;</a
        >
      </div>
      <img
        src="../../../../assets/img/partners/partners-card.png"
        alt="partners"
        class="tw-absolute tw-right-[-2.5rem] tw-top-[-50px] tw-hidden tw-h-[600px] lg:tw-flex"
      />
    </article>
  </div>
</div>
