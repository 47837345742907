import { PresignedUrl } from '../image/presigned-url';

export class AttachmentInfo {
  attachmentInfo: AttachmentInfo;
  encryptedAttachmentInfo: string;
  token: string;
  isDeleted: boolean;

  constructor(presignedUrl?: PresignedUrl) {
    if (presignedUrl) {
      this.encryptedAttachmentInfo = presignedUrl.id;
      this.token = presignedUrl.securityToken;
    } else {
      this.attachmentInfo = null;
      this.encryptedAttachmentInfo = null;
      this.token = null;
      this.isDeleted = false;
    }
  }
}
