<section class="section">
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12">
        <h1 class="title font-weight-bold text-dark text-center">Website Terms of Use</h1>
      </div>
    </div>

    <div class="row">
      <div class="mx-auto col-11 col-md-8 offset-md-2">
        <div class="legal-list">
          <div class="upperalpha-list">
            <ol>
              <!-- A -->
              <li>
                <p>
                  These Terms of Use (“<strong>Terms of Use</strong>”) shall apply to all Users of
                  the Platform.
                </p>
              </li>
              <!-- B -->
              <li>
                <p>
                  By accessing, using, or continuing to access and use the Platform, the User
                  signifies that he has read, understood and agreed to be bound by these Terms of
                  Use regardless of whether the User is a registered member of the Platform.
                </p>
              </li>
              <!-- C -->
              <li>
                <p>
                  Please read these Terms of Use carefully before using the Platform.  If the User
                  violates any of these Terms of Use (which include pitchIN’s Privacy Policy,
                  available on the Platform), or otherwise violate any agreement between the User
                  and the Platform Operator, or the Platform Operator believes or has reason to
                  believe that there is a breach or possible breach of these Terms of Use by the
                  User or any agreement between the User and the Platform Operator, or any agreement
                  between the Platform Operator (including its related companies) and third parties,
                  the Platform Operator may terminate the User’s membership, delete the User’s
                  profile and any content or information that the User has posted on the Platform
                  and/or prohibit the User from using or accessing the Platform (or any portion,
                  aspect or feature thereof), at any time in its sole discretion, with or without
                  notice.
                </p>
              </li>
              <!-- D -->
              <li>
                <p>
                  In addition to these Terms of Use, the User may enter into other agreements with
                  the Platform Operator or others that will govern the User’s use of the Platform. 
                  If there is any contradiction between these Terms of Use and another agreement the
                  User has entered into applicable to specific aspects of the services provided on
                  the Platform, the other agreement shall govern the subject matter to which it
                  applies to the extent of the contradiction. 
                </p>
              </li>
            </ol>
          </div>

          <ol>
            <li>
              <!-- 1 -->
              <h2>DEFINITION AND INTERPRETATION</h2>

              <ol>
                <!-- 1a -->
                <li>
                  <p>
                    Unless the context otherwise requires, the following words shall have the
                    following meanings assigned to it:
                  </p>

                  <dl class="legal-description-list">
                    <dt>“Business Days”</dt>
                    <dd>
                      means a day (excluding Saturdays, Sundays and public holidays) on which banks
                      are open in Kuala Lumpur for the transaction of normal banking business;
                    </dd>

                    <dt>“Platform Operator”</dt>
                    <dd>
                      means Pitch Platforms Sdn. Bhd. [Registration No. 201501033144 (1158464-T)],
                      being the owner and operator of the Platform;
                    </dd>

                    <dt>“Guidelines”</dt>
                    <dd>
                      means the Guidelines on Recognised Markets issued by the SC
                      SC-GL/6-2015(R9-2022), as may be amended from time to time, and include such
                      other guidelines, orders, directives, by-laws, regulations and policies as may
                      be issued from time to time by the relevant authority in respect of equity
                      crowdfunding in Malaysia;
                    </dd>

                    <dt>“Investor”</dt>
                    <dd>means a person who invests in any Hosting on the Platform;</dd>

                    <dt>“Issuer”</dt>
                    <dd>
                      means a person who has been approved by the Platform Operator to be listed on
                      the Platform as an Issuer;
                    </dd>

                    <dt>“Hosting”</dt>
                    <dd>
                      in respect of a particular Hosting, means the offer of the share capital of an
                      Issuer on the Platform for subscription by Investors;
                    </dd>

                    <dt>“Offer Documents”</dt>
                    <dd>
                      means the documents prepared by the Issuer setting out the relevant
                      information of the Hosting to be circulated on the Platform and to the
                      potential Investors;
                    </dd>

                    <dt>“Platform”</dt>
                    <dd>
                      means the platform at www.equity.pitchin.my and any subdomain thereof operated
                      by the Platform Operator to provide equity crowdfunding services and any other
                      service as may be introduced from time to time;
                    </dd>

                    <dt>“SC”</dt>
                    <dd>means the Securities Commission of Malaysia;</dd>

                    <dt>“User”</dt>
                    <dd>
                      means any person who browses, visits, access and/or uses the Platform, whether
                      active, semi-active or inactive user, and includes the Issuer and the
                      Investor, and “Users” means any two or more of them;
                    </dd>
                  </dl>
                </li>

                <!-- 1b -->
                <li>
                  <p>
                    Any reference to a statutory provision shall include such provision and any
                    regulations made in pursuance thereof as from time to time modified, amended,
                    replaced or re-enacted whether before, on or after the date of these Terms. Any
                    reference to any “law” shall include common law applicable in Malaysia and the
                    provisions of statutes, regulations, orders and other subsidiary legislation
                    issued pursuant to such statute, as well as directions, guidelines and circulars
                    issued by any regulatory authority pursuant to any authority granted by any such
                    statute.
                  </p>
                </li>

                <!-- 1c -->
                <li>
                  <p>
                    Unless the context otherwise requires or permits, references to the singular
                    number shall include references to the plural and vice versa, references to a
                    particular gender shall include all genders, and references to natural persons
                    shall include bodies corporate and vice versa.
                  </p>
                </li>

                <!-- 1d -->
                <li>
                  <p>
                    Any reference to a
                    <strong>"day"</strong>, <strong>"week"</strong>, <strong>"month"</strong> or
                    <strong>"year"</strong> is to that day, week, month or year in accordance with
                    the Gregorian calendar.
                  </p>
                </li>

                <!-- 1e -->
                <li>
                  <p>
                    The headings are inserted for convenience only and shall not affect the
                    construction of these Terms of Use.
                  </p>
                </li>

                <!-- 1f -->
                <li>
                  <p>
                    The expression
                    <strong>"Platform Operator"</strong> and <strong>"User"</strong> shall, where
                    the context permits, include their respective successors, personal
                    representatives and permitted assigns.
                  </p>
                </li>

                <!-- 1g -->
                <li>
                  <p>
                    Where a word or phrase is given a defined meaning in these Terms of Use, any
                    other part of speech or other grammatical form in respect of such word or phrase
                    has a corresponding meaning.
                  </p>
                </li>

                <!-- 1h -->
                <li>
                  <p>
                    Any reference to
                    <strong>“these Terms of Use”</strong> is a reference to these Terms of Use and
                    any written amendments, supplementals or novations of these Terms of Use from
                    time to time, and includes a reference to any document which amends, waives, is
                    supplemental to or novates the terms of these Terms of Use.
                  </p>
                </li>

                <!-- 1i -->
                <li>
                  <p>
                    Any reference to
                    <strong> “writing”</strong>, or cognate expressions, includes any communication
                    effected via the Platform, electronic mail, telex, cable, facsimile transmission
                    or other comparable means but shall not include short messaging services,
                    instant messaging services
                  </p>
                </li>

                <!-- 1j -->
                <li>
                  <p>
                    Any reference to
                    <strong>“person”</strong> includes individual, partnership, association, company
                    or corporation.
                  </p>
                </li>

                <!-- 1k -->
                <li>
                  <p>
                    If any period of time is specified from a given day, or the day of a given act
                    or event, it is to be calculated exclusive of that day and if any period of time
                    falls on a day which is not a Business Day, then that period is to be deemed to
                    only expire on the next Business Day.
                  </p>
                </li>

                <!-- 1l -->
                <li>
                  <p>
                    No rule of construction applies to the disadvantage of a party because the party
                    was responsible for the preparation of these Terms of Use or any part of it.
                  </p>
                </li>

                <!-- 1m -->
                <li>
                  <p>
                    All transactions and payments must be completed and cleared or received during
                    the normal business hours of the Platform Operator (09.00 to one 6.00 local time
                    in Kuala Lumpur). Where a transaction is not confirmed before the close of
                    business hours on one Business Day then that transaction will be confirmed and
                    deemed to have become final and binding at the opening of business hours on the
                    next Business Days.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 2 -->
              <h2>ELIGIBILITY</h2>

              <ol>
                <!-- 2a -->
                <li>
                  <p>This Platform is intended solely for Users:</p>

                  <div>
                    <ol>
                      <!-- 2a(i) -->
                      <li>
                        <p>
                          in the case of individual Users, who are of full age and of sound mind,
                          and any registration by, use of or access to the Platform by anyone under
                          18 years of age is void, unauthorised, unlicensed and in violation of
                          these Terms of Use.  By using the Platform, the User represents and
                          warrants that the User is of full age and of sound mind, and that the User
                          agrees to and to abide by all of the Terms of Use herein; and
                        </p>
                      </li>

                      <!-- 2a(ii) -->
                      <li>
                        <p>
                          in the case of corporate Users, who are authorized representatives of the
                          corporate entity, the User represents that the corporate entity that it is
                          representing is a legal entity duly incorporated in accordance with the
                          laws of the place of its incorporation, and have full power, authority and
                          legal right to use and access the Platform, and the User agrees to and
                          abide by all the Terms of Use herein.
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>

                <!-- 2b -->
                <li>
                  <p>
                    Some portions of the Platform that relate to the viewing of Hostings or to
                    making investments on the Issuers are available only to certain qualified,
                    registered and authorised Users of the Platform.  Such portions of our Platform
                    may thus not be available to all Users.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 3 -->
              <h2>LIMITATIONS OF SERVICE</h2>

              <ol>
                <li>
                  <p>
                    The services offered by the Platform Operator on the Platform are in accordance
                    with the Guidelines and the applicable laws, rules and regulations. The Platform
                    Operator is not a registered broker, venture capital fund, or investment advisor
                    and does not conduct any activity that would require such registration. 
                  </p>
                </li>
                <li>
                  <p>
                    The Platform Operator does not provide any advice (including but not limited to
                    investment, legal, taxation or other advice) with respect to any aspect of the
                    transactions conducted through the Platform, other than advice on the technical
                    use of the Platform. Nothing on the Platform or any communications sent to the
                    Users shall constitute or is intended to constitute advice.
                  </p>
                </li>
                <li>
                  <p>
                    Investment overviews on the Platform contain summaries of the purpose and
                    principal business terms of the Hostings.  Such summaries are intended for
                    informational purposes only and do not purport to be complete, and each is
                    qualified in its entirety by reference to the more detailed information
                    contained in the Offer Documents package relating to such Hosting. 
                  </p>
                </li>
                <li>
                  <p>
                    The Platform Operator, by allowing an Issuer to list on the Platform and to
                    carry out the Hosting, makes no representation and warranty on the credit
                    worthiness of the Issuers and viability of any Hostings. The Platform Operator
                    also makes no representation and warranty as to the truthfulness, accuracy and
                    completeness of all information and documents about the Issuers, the Hostings
                    and the Offer Documents on the Platform. Potential Investors shall consult with
                    their own professional tax, legal and financial advisors before making any
                    investment on the Platform.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 4 -->
              <h2>PROHIBITED CONDUCT</h2>

              <ol>
                <li>
                  <p>
                    The User agrees to use the Platform only for purposes that are legal, proper and
                    in accordance with these Terms of Use and any applicable law, rules or
                    regulations.  The User shall not:
                  </p>

                  <div>
                    <ol>
                      <li>
                        <p>
                          use the Platform in any manner that could damage, disable, overburden, or
                          impair the Platform, or interfere with any other party's use and enjoyment
                          of the Platform;
                        </p>
                      </li>
                      <li>
                        <p>
                          attempt to gain unauthorized access to the Platform, or the computer
                          systems or networks connected to the Platform through hacking, password
                          mining or any other means;
                        </p>
                      </li>
                      <li>
                        <p>
                          create user accounts by automated means or under false or fraudulent
                          pretences;
                        </p>
                      </li>
                      <li>
                        <p>
                          transmit any viruses, worms, defects, Trojan horses, or any items of a
                          destructive nature to or through the Platform;
                        </p>
                      </li>
                      <li>
                        <p>
                          defame, abuse, harass, stalk, threaten or otherwise violate the legal
                          rights (such as rights of privacy and publicity) of others;
                        </p>
                      </li>
                      <li>
                        <p>
                          upload, post, email or transmit, or otherwise make available through the
                          Platform any inappropriate, defamatory, infringing, obscene, or unlawful
                          content;
                        </p>
                      </li>
                      <li>
                        <p>
                          upload, post, email or transmit, or otherwise make available through the
                          Platform any content that infringes any patent, trademark, copyright,
                          trade secret or other intellectual or proprietary right of any person,
                          unless the User is the owner of such rights or have the permission of the
                          owner to post such content;
                        </p>
                      </li>
                      <li>
                        <p>
                          upload, post, email or transmit, or otherwise make available through the
                          Platform any materials that promote pyramid schemes, chain letters or
                          disruptive commercial messages or advertisements, or anything else
                          prohibited by law or these Terms of Use;
                        </p>
                      </li>
                      <li>
                        <p>run any form of auto-responder or “spam” on the Platform;</p>
                      </li>
                      <li>
                        <p>
                          use manual or automated software, devices, or other processes to “crawl”
                          or “spider” any page of the Platform, including to engage in the practices
                          of "screen scraping", "database scraping" or any other activity with the
                          purpose of obtaining content or other information;
                        </p>
                      </li>
                      <li>
                        <p>
                          interfere or attempt to interfere with the proper working of the Platform
                          or any activities conducted on the Platform, including to utilize framing
                          techniques to enclose any content or other proprietary information, place
                          pop-up windows over the Platform's pages, or otherwise affect the display
                          of the Platform's pages;
                        </p>
                      </li>
                      <li>
                        <p>
                          download any file posted by another User that the User knows, or
                          reasonably should know, cannot be legally distributed in such manner;
                        </p>
                      </li>
                      <li>
                        <p>
                          impersonate another person or entity, or falsify or delete any author
                          attributions, legal or other proper notices or proprietary designations or
                          labels of the origin or source of any materials;
                        </p>
                      </li>
                      <li>
                        <p>
                          remove any copyright, trademark or other proprietary rights notices
                          contained in or on the Platform;
                        </p>
                      </li>
                      <li>
                        <p>
                          use any robot, spider, site search/retrieval application, or other device
                          to retrieve or index any portion of the Platform or collect information
                          about its Users for any unauthorized purpose;
                        </p>
                      </li>
                      <li>
                        <p>
                          submit content that falsely expresses or implies that such content is
                          sponsored or endorsed by the Platform Operator, any of its affiliates or
                          any third parties;
                        </p>
                      </li>
                      <li>
                        <p>
                          use the Platform for any illegal or unauthorized purpose (including,
                          without limitation, in violation of any Malaysian securities or laws or
                          regulations, any anti-money laundering laws, or self-regulatory
                          organization’s rules or regulations, or equivalent laws or regulations in
                          foreign jurisdictions);
                        </p>
                      </li>
                      <li>
                        <p>
                          promote or provide instructional information about illegal activities or
                          promote physical harm or injury against any group or individual;
                        </p>
                      </li>
                      <li>
                        <p>
                          share or disclose with anyone any information intended to be of
                          confidential nature obtained through the Platform about any Hostings or
                          any Users;
                        </p>
                      </li>
                      <li>
                        <p>
                          share or disclose with anyone any information about another individual,
                          including another person's address, phone number, e-mail address, credit
                          card number or any information obtained from the Platform that may be used
                          to track, contact or impersonate that individual; and
                        </p>
                      </li>
                      <li>
                        <p>
                          contact any Issuers or Investors or other parties involved in the
                          transactions posted on the Platform directly or to attempt to enter into
                          any such transaction with such persons or entities outside of the
                          Platform.
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>
              </ol>
            </li>

            <li>
              <!-- 5 -->
              <h2>ACCOUNT SECURITY & CONTROL</h2>

              <ol>
                <!-- 5a -->
                <li>
                  <p>In consideration of the User’s use of the Platform, the User agrees to:</p>

                  <div>
                    <ol>
                      <li>
                        <p>
                          provide true, accurate, current and complete information about the User as
                          may be prompted by any registration forms on the Platform (<strong
                            >“Registration Data”</strong
                          >) or upon request by the Platform Operator;
                        </p>
                      </li>
                      <li>
                        <p>
                          keep the Platform Operator informed of any change in the User’s e-mail
                          address, home mailing address, telephone number and other information that
                          has been provided to the Platform Operator so that the Platform Operator
                          can maintain communications with the User;
                        </p>
                      </li>
                      <li>
                        <p>
                          maintain and safeguard the security of the User’s login details to login
                          and access the Platform;
                        </p>
                      </li>
                      <li>
                        <p>
                          not to misuse the Platform to the detriment of the Platform Operator and
                          the Platform;
                        </p>
                      </li>
                      <li>
                        <p>
                          ensure that only the User and the User’s authorised agents shall have
                          access to the Platform using the User’s login details;
                        </p>
                      </li>
                      <li>
                        <p>
                          maintain and promptly update the Registration Data, and any other
                          information the User provides to the Platform Operator, to keep it
                          accurate, current and complete;
                        </p>
                      </li>
                      <li>
                        <p>
                          be fully responsible for all use of the User’s account and for any actions
                          that take place using the User’s account; and
                        </p>
                      </li>
                      <li>
                        <p>
                          not to create more than one User account on the Platform or use or access
                          any User other than the User’s own account.
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>

                <!-- 5b -->
                <li>
                  <p>
                    Any access to the Platform by using the User’s IP address or account shall be
                    deemed to have been made by the User. The User shall be responsible for all
                    information and activities carried out on the Platform by anyone using the
                    User’s IP address or account. The User shall immediately notify the Platform
                    Operator in writing of any breach of security, loss, theft or unauthorised use
                    of the IP address or account of the User.
                  </p>
                </li>

                <!-- 5c -->
                <li>
                  <p>
                    The User shall only use and access the Platform for the purposes contemplated by
                    these Terms of Use and for no other purposes, and the User hereby agrees not to
                    adapt or circumvent the systems in place in connection with the Platform, nor
                    access the Platform for any unauthorised, malicious, illegal or fraudulent
                    purpose. The Platform Operator reserves the right at its sole discretion not to
                    act on any instructions received from the User where the Platform Operator in
                    its reasonable opinion, suspects any unauthorised, malicious, illegal or
                    fraudulent activity.
                  </p>
                </li>

                <!-- 5d -->
                <li>
                  <p>
                    The Platform Operator reserves the right at its sole discretion, at any time and
                    from time to time, to prevent, stop and/or disallow by any means, any User to
                    continue using the Platform without assigning any reason whatsoever, and/or to
                    revoke the registration status and deactivate the account of the User from the
                    Platform.
                  </p>
                </li>

                <!-- 5e -->
                <li>
                  <p>
                    The revocation of the status of the User under these Terms of Use shall not
                    result in a termination of any contract or other agreement to which the User is
                    a party at that time.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 6 -->
              <h2>PLATFORM CONTENT</h2>

              <ol>
                <li>
                  <p>
                    All content on the Platform, including but not limited to designs, text,
                    graphics, pictures, video, information, software, music, sound and other files,
                    and their selection and arrangement (“<strong>Platform Content</strong>”), are
                    the proprietary property of the Platform Operator with all rights reserved. No
                    Platform Content may be modified, copied, distributed, framed, reproduced,
                    republished, downloaded, displayed, posted, transmitted, or sold in any form or
                    by any means, in whole or in part, without the Platform Operator’s prior written
                    permission, except that, if the User is eligible for the use of the Platform,
                    the User is granted a limited, non-exclusive, non-transferable and revocable
                    license to access and use the Platform and to download or print a copy of any
                    portion of the Platform Content solely for the User’s personal
                    use, provided that the User shall keep such portions confidential and all
                    copyright or other proprietary notices intact. 
                  </p>
                </li>
                <li>
                  <p>
                    The User shall not republish the Platform Content on any internet, intranet or
                    extranet site or incorporate the information in any other database or
                    compilation, and any other use of the Platform Content is strictly prohibited. 
                    Any use of the Platform or the Platform Content other than as specifically
                    authorized herein, without the prior written permission of the Platform
                    Operator, is strictly prohibited and will terminate the license granted herein. 
                    Such unauthorized use may also violate applicable laws including without
                    limitation copyright and trademark laws and applicable communications
                    regulations and statutes. This license is revocable by us at any time without
                    notice and with or without cause.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 7 -->
              <h2>LINKED PLATFORM</h2>

              <ol>
                <li>
                  <p>
                    The Platform may contain links to third party websites (“<strong
                      >Linked Sites</strong
                    >”). These links are provided only as a convenience. The inclusion of any link
                    is not, and shall not be construed to imply, an affiliation, sponsorship,
                    endorsement, approval, investigation, verification or monitoring by the Platform
                    Operator of any information, materials, products, or services contained in or
                    accessible through any Linked Site.  In no event shall the Platform Operator be
                    responsible for the information contained on any Linked Site or the User’s use
                    of or inability to use any Linked Site.  Access and use of Linked Sites,
                    including the information, material, products, and services on Linked Sites or
                    available through Linked Sites, is solely at the User’s own risk.  The User’s
                    access and use of the Linked Sites are governed by the terms of use and privacy
                    policies of such Linked Sites, and the Platform Operator encourage the User to
                    carefully review all such terms and policies.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 8 -->
              <h2>USER CONTENT</h2>

              <ol>
                <li>
                  <p>
                    The Platform may allow the User and other users to submit, post, transmit and
                    share content with other Users. The User are solely responsible for any such
                    content (which may include photos, profiles, messages, notes, text, information,
                    music, video, contact information for the User or others, advertisements or
                    other content) that the User upload, publish, provide or display (hereinafter,
                    “<strong>post</strong>”) on or through the Platform, or transmit to or share
                    with other Users (collectively, the “<strong>User Content</strong>”).  The User
                    understands and agrees that the Platform Operator may, but is not obligated to,
                    review and delete or remove (without notice) any User Content in its sole
                    discretion, including without limitation, User Content that in the sole judgment
                    of the Platform Operator violates these Terms of Use or any other terms made
                    available on the Platform, might be offensive or illegal, or might violate the
                    rights of, harm, or threaten the safety of, Users or others.
                  </p>
                </li>

                <li>
                  <p>
                    By posting User Content to any part of the Platform, the User automatically
                    grants, and the User represents and warrants that the User have the right to
                    grant, to the Platform Operator an irrevocable, perpetual, non-exclusive,
                    transferable, fully paid, worldwide license (with the right to sublicense) to
                    use, copy, publicly perform, publicly display, reformat, translate, excerpt (in
                    whole or in part) and distribute such User Content for any purpose on or in
                    connection with the Platform or the promotion thereof, to prepare derivative
                    works of, or incorporate into other works, such User Content, and to grant and
                    authorize sublicenses of the foregoing.  The User may remove the User’s User
                    Content from the Platform at any time.  If the User chooses to remove the User’s
                    User Content, the license granted above shall continue to exist and shall not
                    expire.
                  </p>
                </li>

                <li>
                  <p>
                    The User may view personal information posted by other Users on the Platform,
                    but the User are not authorized to disclose or otherwise use such information
                    for any purpose other than assessing the credit/trustworthiness of such other
                    Users.
                  </p>
                </li>

                <li>
                  <p>
                    Although the Platform Operator provides rules for User’s conduct and postings,
                    the Platform Operator does not control and is not responsible for what the Users
                    post on the Platform and is not responsible for any offensive, inappropriate,
                    obscene, unlawful or otherwise objectionable content the User may encounter on
                    the Platform or in connection with any User Content or content by third parties
                    (“<strong>Third Party Content</strong>”). The Platform Operator is not
                    responsible for the conduct, whether online or offline, of any User of the
                    Platform.
                  </p>
                </li>

                <li>
                  <p>
                    The Platform Operator does not guarantee the accuracy of any User Content or
                    Third Party Content.
                  </p>
                </li>

                <li>
                  <p>
                    The Platform Operator shall not be responsible or liable for the content or
                    accuracy of any information or material downloaded or posted by the Users nor
                    shall the Platform Operator be obliged to edit the content downloaded or posted
                    on the Platform, however the Platform Operator shall retain the right to remove
                    any material or posting made on the Platform at its absolute discretion.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 9 -->
              <h2>CONFIDENTIALITY OF INFORMATION</h2>

              <ol>
                <li>
                  <p>
                    If the User receives or otherwise obtains information from the Platform
                    Operator, the Platform or other Users with respect to any Hostings or investment
                    or prospective investment activity on the Platform, the User shall not further
                    disclose or otherwise provide such information to any other party in any manner
                    that allows a personal identification of such transaction or any specific
                    information with respect thereto.
                  </p>
                </li>
                <li>
                  <p>
                    By using and accessing the Platform, the User is entrusted with and shall keep
                    confidential any information the User receives or otherwise obtains from the
                    Platform, including but not limited to any information with respect to any
                    Users, Hostings, investment or prospective investment activity on the Platform,
                    and the User agrees to keep all confidential information the User learned about
                    from the Platform private and confidential.
                  </p>
                </li>
                <li>
                  <p>
                    The User acknowledges that the Platform Operator makes no representation or
                    warranty, express or implied, as to the accuracy or completeness of the
                    information provided on the Platform.  The User agrees that the Platform
                    Operator shall not have any liability to the User relating to or resulting from
                    the use of such information.  In furtherance of the foregoing, the User hereby
                    releases the Platform Operator from any and all liabilities to the User
                    regardless of when such liabilities may arise.
                  </p>
                </li>
                <li>
                  <p>
                    The User agrees not to pursue or engage in any transaction involving any part of
                    any Hostings, investment or prospective investment disclosed to the User on the
                    Platform or through the information disclosed to the User, or to contact
                    directly or indirectly any person or entity learned from the Platform or
                    revealed by the information provided for a Hosting, investment or prospective
                    investment, without the Platform Operator’s prior written consent and
                    participation in such transaction or contact. The User may contact such persons
                    or entities in the ordinary course of business unrelated to the prospective
                    investment or other transaction disclosed on the Platform.
                  </p>
                </li>
                <li>
                  <p>
                    The User shall be responsible for any losses caused to the Platform Operator and
                    any other Users by any use of the User’s account or violations of these
                    confidentiality terms, both unauthorized and authorized. The User acknowledges
                    and agrees that to access certain Platform Content on the Platform, the User may
                    be required to register with the Platform and agree to keep such information
                    confidential. To the extent the User elects to not review such confidential
                    information and documents about any Hostings, investments or prospective
                    investments, then the User acknowledges and agrees that the User assumes the
                    risk that such additional information may be relevant to the User’s decision to
                    invest in a particular Hosting or investment opportunity and the User knowingly
                    accepts the risks of not reviewing such information. The User agrees that the
                    Platform Operator, at its sole discretion and to the extent permitted by law,
                    may access, read, preserve and disclose the User’s account information, usage
                    history and submitted User Content in order to: (a) comply with any applicable
                    law, regulation, legal process, or governmental request; (b) respond to claims
                    that any User Content violates the rights of third parties, including
                    intellectual property rights; (c) enforce these Terms of Use or any other terms
                    and conditions made available on the Platform (including, without limitation the
                    acceptance of these confidentiality terms) and investigate potential violations
                    thereof; (d) detect, prevent, or otherwise address fraud, security, or technical
                    issues; (e) respond to the User’s requests for customer service; or (f) protect
                    the rights, property, or personal safety of the Platform Operator, its Users, or
                    the public.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 10 -->
              <h2>CONTACT</h2>

              <ol>
                <li>
                  <p>The Platform Operator may be contacted at the following contact details:</p>

                  <dl class="legal-description-list">
                    <dt>Email</dt>
                    <dd><a href="mailto:equity@pitchin.my">equity&#64;pitchin.my</a></dd>

                    <dt>Address</dt>
                    <dd>
                      <address class="tw-mb-0">
                        PITCHIN,<br />
                        E-12-08, Menara Suezcap 2, KL Gateway, <br />
                        No. 2, Jalan Kerinchi, <br />
                        59200 Kuala Lumpur
                      </address>
                    </dd>

                    <dt>Attention</dt>
                    <dd>Chief Executive Officer</dd>
                  </dl>
                </li>
              </ol>
            </li>

            <li>
              <!-- 11 -->
              <h2>CONSENT TO ELECTRONIC TRANSACTIONS AND DISCLOSURES</h2>

              <ol>
                <li>
                  <p>
                    Because the Platform Operator operates largely on the Internet, the User shall
                    consent to transact business with the Platform Operator online and
                    electronically. 
                  </p>
                </li>
                <li>
                  <p>
                    The Platform Operator or its affiliate (or a third-party servicer or escrow
                    provider that the Platform Operator may retain) may receive payments, and makes
                    all disbursements, through certified check, online transfer or electronic funds
                    transfers using the bank (or other financial institution) account information
                    provided by the User. The User shall authorise such bank or other financial
                    account to pay any amounts the User agrees to invest, and authorize the Platform
                    Operator (or any such third-party servicer or escrow provider) to make any and
                    all investment disbursements, to such account.  The User agrees to provide the
                    Platform Operator updated information regarding the User’s bank or other account
                    upon the Platform Operator’s request and at any time that the information
                    earlier provided is no longer valid.
                  </p>
                </li>
                <li>
                  <p>
                    As part of doing business with the Platform Operator, the User must also consent
                    to the provision of certain disclosures electronically, either via the Platform
                    or to the email address provided by the User.  By agreeing to these Terms of
                    Use, the User agrees to receive electronically all documents, communications,
                    notices, contracts, and agreements, schedules or information statements, arising
                    from or relating to the User’s registration as an Investor on the Platform, any
                    investments the User may make, the User’s use of the Platform, and the servicing
                    of any investment the User may make (each, a “<strong>Disclosure</strong>”),
                    from the Platform Operator or any service provider the Platform Operator may
                    use. 
                  </p>
                </li>
                <li>
                  <p>
                    The User’s consent to receive Disclosures and transact business electronically,
                    and our agreement to do so, applies to any transaction to which such Disclosures
                    relate.  The User’s consent will remain in effect for so long as the User is a
                    User and, if the User is no longer a User, will continue until such a time as
                    all Disclosures relevant to transactions that occurred while the User was a User
                    have been made.
                  </p>
                </li>
                <li>
                  <p>
                    The User may not withdraw such consent as long as the User has any outstanding
                    investments made through the Platform.  If the User has no outstanding
                    investments made through the Platform and wish to withdraw its consent to doing
                    business electronically, the Platform Operator will terminate the User’s
                    registered user account on the Platform.
                  </p>
                </li>
                <li>
                  <p>
                    The User also expressly consents to receiving calls and messages, including
                    auto-dialed and pre-recorded message calls, and SMS messages (including text
                    messages) from the Platform Operator, its affiliates, marketing partners, agents
                    and others calling at their request or on their behalf, at any telephone numbers
                    that the User has provided or may provide in the future.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 12 -->
              <h2>DISPUTE RESOLUTION</h2>

              <ol>
                <li>
                  <p>
                    The User is solely responsible for the User’s interactions with other Users. The
                    Platform Operator reserves the right, but has no obligation, to monitor disputes
                    between the User and other Users.
                  </p>
                </li>
                <li>
                  <p>
                    In the event any dispute or difference shall arise amongst the Platform Operator
                    and the User and/or between the Users as to the construction of these Terms or
                    as to any matter or thing of whatsoever nature arising thereunder or in
                    connection therewith, including any question regarding its existence, validity
                    or termination, such dispute or difference shall be submitted to a single
                    arbitrator to be appointed by the parties in dispute or, failing agreement
                    within 14 days after either party in dispute has given to the other party in
                    dispute a written request to concur in the appointment of an arbitrator, a
                    single arbitrator to be appointed by the Chairman for the time being of the
                    Asian International Arbitration Centre (“<strong>AIAC</strong>”) and such
                    submission shall be a submission to arbitration in accordance with the Rules of
                    the AIAC as presently in force by which the Parties in dispute agree to be so
                    bound. The place of arbitration shall be Malaysia and the arbitration shall be
                    conducted wholly in the English language.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 13 -->
              <h2>PRIVACY</h2>

              <ol>
                <li>
                  <p>
                    Please review our
                    <a [routerLink]="['/privacy-policy']">Privacy Policy</a>. By using the Platform,
                    the User hereby consents to our Privacy Policy and to having the User’s personal
                    data transferred to and processed in Malaysia or outside of Malaysia.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 14 -->
              <h2>FORCE MAJEURE</h2>

              <ol>
                <li>
                  <p>
                    The Platform Operator shall not be liable to any person and shall not be deemed
                    to be in breach of these Terms of Use by reason of any delay in performing or
                    failure to perform any of its obligations under these Terms if the delay or
                    failure was caused by an event of force majeure.
                  </p>
                </li>
                <li>
                  <p>
                    Without prejudice to the generality of the foregoing provision, force majeure
                    shall include but not limited to the following:
                  </p>

                  <div>
                    <ol>
                      <li>
                        <p>
                          act of God, including but not limited to fires, explosions, earthquakes,
                          drought, tidal waves and floods, or accident;
                        </p>
                      </li>
                      <li>
                        <p>
                          war, threat of war, act of terrorism or threat of terrorism, sabotage,
                          insurrection, civil disturbance or requisition;
                        </p>
                      </li>
                      <li>
                        <p>
                          restrictions imposed by any law, regula tions, by-laws, prohibitions or
                          measures of any kind on the part of any government, parliamentary or local
                          authority;
                        </p>
                      </li>
                      <li>
                        <p>
                          interruption of traffic, strikes, lock-outs, or other industrial actions
                          or trade disputes (whether involving the employees of the Platform
                          Operator or third party);
                        </p>
                      </li>
                      <li>
                        <p>
                          breakdown of internet services for any reason whatsoever, save for the
                          willful misconduct of the Platform Operator;
                        </p>
                      </li>
                      <li>
                        <p>
                          other unforeseeable circumstances beyond the control of the Platform
                          Operator against which it would have been unreasonable for the Platform
                          Operator to take precautions and which the Platform Operator cannot avoid
                          even by using its best efforts.
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>
                <li>
                  <p>
                    Upon the occurrence of any force majeure event, the performance of the Platform
                    Operator’s obligations under these Terms of Use shall be suspended during that
                    period of force majeure and the Platform Operator shall be granted an extension
                    of time for performance equal to the period of delay.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 15 -->
              <h2>CONFLICTS OF INTEREST</h2>

              <ol>
                <li>
                  <p>
                    The Platform Operator shall use its best endeavours to ensure that its interests
                    do not conflict with the interests of the Users. In the event a conflict of
                    interest arises, the Platform Operator shall promptly disclose such conflict to
                    the Users, and parties shall engage in good faith discussions to resolve such
                    matters.
                  </p>
                </li>
                <li>
                  <p>
                    Pursuant to the Guidelines, the Platform Operator shall disclose on its Platform
                    if:
                  </p>

                  <div>
                    <ol>
                      <li>
                        <p>it holds any controlling shares in any of the Issuers; or</p>
                      </li>
                      <li>
                        <p>
                          it pays any referrer or introducer, or receives payment in whatever form,
                          including payment in the form of shares, in connection with an Issuer.
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>
                <li>
                  <p>
                    In the event parties shall be unable to resolve the conflicts pursuant to Clause
                    15.1 within 14 days from the date the conflict is disclosed to the User, Clause
                    12 shall apply to resolve such conflict.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 16 -->
              <h2>RISK WARNINGS</h2>

              <ol>
                <li>
                  <p>
                    The Users, especially the Investors, acknowledge that they are aware of the
                    following:
                  </p>

                  <div>
                    <ol>
                      <li>
                        <p>
                          the Issuers’ business may not proceed as planned under the Offer
                          Documents, and may fail, and that the Investors may lose all or part of
                          his invested capital, and the Investors covenants that he shall not invest
                          more money through the Platform that he can afford to lose without
                          altering his standard of living;
                        </p>
                      </li>
                      <li>
                        <p>
                          any investment made through the Platform may be highly illiquid, and the
                          Investors may be unable to sell his shares as he wishes;
                        </p>
                      </li>
                      <li>
                        <p>
                          there is no guarantee that the business of the Issuers will succeed or
                          make profits;
                        </p>
                      </li>
                      <li>
                        <p>there is no guarantee as to the payment of dividends on the shares;</p>
                      </li>
                      <li>
                        <p>
                          the shareholding of the Investors in the Issuers may be diluted by
                          subsequent fund raising, and the Issuers may issue other classes of shares
                          which rank in priority over the Investor’s shares;
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>

                <li>
                  <p>
                    The Users hereby acknowledge and agree that the Platform Operator shall not, at
                    any time, be liable to the Users in the event of any losses or damages
                    (including but not limited to any direct, indirect or consequential losses, loss
                    of profit, loss of reputation and all interest, penalties and legal costs and
                    all other professional costs and expenses) suffered by the Investors as a result
                    of investing on any Issuers.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 17 -->
              <h2>COMPLAINTS</h2>

              <ol>
                <!-- 17a -->
                <li>
                  <p>
                    In the event the User has any complaints with respect to any aspect of the
                    Platform, the User may report it to the Platform Operator at the contact details
                    provided in Clause 10, and the Platform Operators shall respond to the
                    complaints within 7 Business Days upon receipt of the complaints. The Platform
                    Operator reserves the right at its discretion to carry any investigation in
                    accordance with its internal policies and procedures.
                  </p>
                </li>

                <!-- 17b -->
                <li>
                  <p>
                    The User shall provide any information or documents upon request by the Platform
                    Operator in order to assist the Platform Operator in respect of its
                    investigation in Clause 17.1.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 18 -->
              <h2>DISCLAIMERS</h2>

              <ol>
                <li>
                  <p>
                    The Platform Operator is not a party to any agreement between any Investors and
                    any Issuers. The Platform Operator’s role is strictly limited to providing the
                    Platform and to facilitate the Hostings.
                  </p>
                </li>
                <li>
                  <p>
                    The Platform Operator shall not be responsible or liable for the content or
                    accuracy of any information or material downloaded or posted by the Users nor
                    shall the Platform Operator be obliged to edit the content downloaded or posted
                    on the Platform, however the Platform Operator shall retain the right to remove
                    any material or posting made on the Platform at its absolute discretion.
                  </p>
                </li>
                <li>
                  <p>
                    The Platform Operator cannot guarantee and do not promise any specific results
                    (relating to Hostings, investments or otherwise) from the use of the Platform.
                  </p>
                </li>
                <li>
                  <p>
                    The Platform Operator does not warrant the use of and access to the Platform
                    will be uninterrupted, error free or free of computer viruses or computer bugs
                    or that defects will be corrected, or give any warranty as to their
                    functionality, accuracy or reliability. The Platform may be temporarily
                    unavailable from time to time for maintenance or other reasons. The Platform
                    Operator assumes no responsibility for any error, omission, interruption,
                    deletion, defect, delay in operation or transmission, communications line
                    failure, theft or destruction or unauthorized access to, or alteration of, any
                    User communications. The Platform Operator is not responsible for any problems
                    or technical malfunction of any telephone network or lines, computer online
                    systems, servers or providers, computer equipment, software, failure of email or
                    players on account of technical problems or traffic congestion on the Internet
                    or on the Platform or combination thereof, including injury or damage to Users
                    or to any other person's computer related to or resulting from participating or
                    downloading materials in connection with the Internet and/or in connection with
                    the Platform or Service.
                  </p>
                </li>
                <li>
                  <p>
                    Under no circumstances shall the Platform Operator be responsible for any loss
                    or damage, including any loss or damage to any User Content or personal injury
                    or death, resulting from anyone's use of the Platform, any User Content or Third
                    Party Content posted on or through the Platform or transmitted to Users, or any
                    interactions between Users of the Platform, whether online or offline. Any User
                    Content or Third Party Content should not be relied upon when making any
                    decisions or taking any action of any kind.
                  </p>
                </li>
                <li>
                  <p>
                    The Platform Operator reserves the right to change any and all content contained
                    in the Platform and any services offered through the Platform at any time
                    without notice. Reference to any products, services, processes or other
                    information, by trade name, trademark, manufacturer, supplier or otherwise does
                    not constitute or imply endorsement, sponsorship or recommendation thereof, or
                    any affiliation therewith, by the Platform Operator.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 19 -->
              <h2>INDEMNITY</h2>

              <ol>
                <li>
                  <p>
                    The User agrees to indemnify and hold the Platform Operator, its subsidiaries
                    and affiliates, and each of their directors, officers, agents, contractors,
                    employees and representatives, harmless from and against any loss, liability,
                    claim, demand, damages, penalties, fines, costs and expenses, including
                    reasonable attorney's fees, arising out of or in connection with the User’s use
                    of the Platform, the User’s conduct in connection with the Platform or with
                    other Users of the Platform, or any violation of these Terms of Use, any other
                    terms and conditions made available on the Platform, or of any law or the rights
                    of any third party, any of the User’s User Content, and any Third Party Content
                    the User post or share on or through the Platform.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <!-- 20 -->
              <h2>GENERAL</h2>

              <ol>
                <li>
                  <p>
                    Nothing herein shall be construed to create a partnership, joint venture, agency
                    or employment relationship between the parties. No party has authority to enter
                    into agreements of any kind on behalf of the other party.
                  </p>
                </li>
                <li>
                  <p>
                    No failure on the part of any party to exercise and no delay on the part of any
                    party in exercising any right hereunder will operate as a release or waiver
                    thereof, nor will any single or partial exercise of any right under these Terms
                    preclude any other or further exercise of it.
                  </p>
                </li>
                <li>
                  <p>
                    No remedy conferred by any of the provisions of these Terms is intended to be
                    exclusive of any other remedy which is otherwise available at law, in equity, by
                    statute or otherwise, and each and every other remedy shall be cumulative and
                    shall be in addition to every other remedy given hereunder or now or hereafter
                    existing at law, in equity, by statute or otherwise. The election of any one or
                    more of such remedies by any of the parties shall not constitute a waiver by
                    such party of the right to pursue any other available remedies.
                  </p>
                </li>
                <li>
                  <p>
                    The Platform Operator reserves the right to amend, vary and/or supplement these
                    Terms of Use from time to time. Any amendments, variations and/or supplements to
                    these Terms and Condition shall be effective when posted on the Platform. The
                    User’s continued use of the Platform on or after the date on which such
                    amendments, variations and/or supplements come into effect shall be construed as
                    the User’s agreement to be bound by the amended Terms.
                  </p>
                </li>
                <li>
                  <p>
                    The communications between the Platform Operator and the User shall be made
                    through the Platform, or mailed, personally delivered, faxed or emailed to the
                    recipient at the current address held in the Platform Operator’s record.
                  </p>
                </li>
                <li>
                  <p>
                    These Terms of Use shall be binding upon the respective successors-in-title and
                    permitted assigns of the parties. The User shall not assign or transfer its
                    rights, title, interests and benefits under these Terms or any of its
                    liabilities and obligations hereunder without the prior written consent of the
                    Platform Operator.
                  </p>
                </li>
                <li>
                  <p>
                    If any provision of these Terms of Use or part thereof is or may become under
                    any written law, or is found by any court or administrative body or competent
                    jurisdiction to be illegal, void, invalid, prohibited or unenforceable then:
                  </p>

                  <div>
                    <ol>
                      <li>
                        <p>
                          such provision or part thereof shall be read down or severed only to the
                          extent of such illegality, voidness, invalidity, prohibition or
                          unenforceability;
                        </p>
                      </li>
                      <li>
                        <p>
                          the illegality, voidness, invalidity, prohibition or unenforceability of
                          any provision or part thereof in any jurisdiction shall not affect the
                          legality, validity or enforceability of any other provision or of that
                          provision in any other jurisdiction, and the remaining provisions of these
                          Terms shall remain in full force and effect; and
                        </p>
                      </li>
                      <li>
                        <p>
                          the parties shall use their respective best endeavours to negotiate and
                          agree on a substitute provision which is valid and enforceable and
                          achieves to the greatest extent possible the economic, legal and
                          commercial objectives of such illegal, void, invalid, prohibited or
                          unenforceable term, condition, stipulation, provision, covenant or
                          undertaking.
                        </p>
                      </li>
                    </ol>
                  </div>
                </li>
                <li>
                  <p>
                    The Parties hereby agree that the provisions contained in these Terms of Use and
                    other terms and conditions as may be formulated by the Platform Operator from
                    time to time shall constitute the entire agreement between the parties in
                    respect of the subject matter herein and supersede all previous memoranda,
                    expectations, understandings, communications, representations and agreements
                    whether oral or written between them in respect of the subject matter hereof.
                  </p>
                </li>
                <li>
                  <p>
                    These Terms of Use shall be governed by and construed in accordance with the
                    laws of Malaysia.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </div>

        <p>Last updated: 21 December 2022</p>
      </div>
    </div>
  </div>
</section>
