import { Component, Input } from '@angular/core';

@Component({
  selector: 'agmo-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input() loadingClass: string = 'tw-text-[#333]';
}
