<section class="section section-shaped">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 text-center mb-5">
        <img
          class="icon"
          alt="404 Not Found"
          src="../../../../assets/img/icons/common/error_404_icon@2x.png"
        />
        <h2 class="text-center font-weight-extrabold">
          {{ (isImage ? 'notFound.imageNotFound' : 'notFound.pageNotFound') | translate }}
        </h2>
        <div class="text-center success-message mb-2">
          {{ (isImage ? 'notFound.sorryImageMsg' : 'notFound.sorryMsg') | translate }}
        </div>
        <a class="btn btn-primary mt-3 font-size-larger" routerLink="/" (click)="reset()">{{
          'notFound.goToHomepage' | translate
        }}</a>
      </div>
    </div>
  </div>
</section>
