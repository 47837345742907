import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-two-factor-auth-input',
  templateUrl: './two-factor-auth-input.component.html',
})
export class TwoFactorAuthInputComponent implements OnInit {
  @Input() showTitle: boolean = true;
  @Input() h2: string;
  @Input() description: string = 'twoFactor.enterSixDigit';
  @Input() wrapperClass: string = 'tw-items-center';
  @Output() otpChange = new EventEmitter();

  form: UntypedFormGroup;
  showFormError: boolean = false;

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      this.showFormError = true;
      return;
    }

    this.otpChange.emit(this.form.value.otp);
  }
}
