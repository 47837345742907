<app-signup-layout
  [backgroundImage]="currentSignUpType?.backgroundImage"
  [backgroundClass]="
    role === Role.IssuerRep
      ? 'tw-from-info-darkest tw-to-[#071C31]'
      : 'tw-from-primary tw-to-[#7A2828]'
  "
  [isFullWidth]="isSent"
>
  <div class="row tw-justify-center" *ngIf="!isSent" [@slideInOut]>
    <div class="col-md-8 col-lg-12">
      <a [routerLink]="['/sign-up']" class="p-link-primary tw-mb-2 tw-inline-block tw-text-sm">
        ← {{ 'common.back' | translate }}
      </a>
      <div class="tw-mb-2 tw-flex tw-gap-5 lg:tw-items-center">
        <div class="tw-flex-shrink-0">
          <img
            class="tw-h-12 tw-w-12 lg:tw-h-16 lg:tw-w-16"
            [src]="currentSignUpType?.image"
            [alt]="currentSignUpType?.name"
            width="50"
            height="50"
          />
        </div>
        <h1 class="p-text-h2 tw-mb-0 max-md:!tw-text-2xl">
          <span
            class="tw-text-primary"
            *ngIf="role == Role.IndividualInvestor"
            [innerHTML]="'signUp.becomeAnIndividualInvestor' | translate"
          >
          </span>
          <span
            class="tw-text-primary"
            *ngIf="role == Role.CompanyRep"
            [innerHTML]="'signUp.becomeACorporateInvestor' | translate"
          >
          </span>
          <span
            class="tw-text-info-darkest"
            *ngIf="role == Role.IssuerRep"
            [innerHTML]="'signUp.becomeAnIssuer' | translate"
          >
          </span>
        </h1>
      </div>
      <div class="tw-mb-8 tw-text-sm tw-text-body">
        {{ 'signUp.alreadyHaveAccount' | translate }}
        <a routerLink="/login" class="font-weight-extrabold">
          {{ 'signUp.login' | translate }}
        </a>
      </div>
      <div class="lg:tw-max-w-[80%]">
        <form (ngSubmit)="submit()" [formGroup]="form" [class.show-form-error]="showFormError">
          <!-- (Step 1) For corporate, issuer -->
          <ng-container *ngIf="role !== Role.IndividualInvestor">
            <div class="split-divider">
              <span
                class="split-divider-number"
                [ngClass]="role === Role.CompanyRep ? 'tw-bg-primary' : 'tw-bg-info-darkest'"
                >1</span
              >
            </div>

            <div class="input-panel">
              <label for="companyType" class="input-label asterisk">{{
                'signUp.companyType' | translate
              }}</label>
              <mat-radio-group
                id="companyType"
                formControlName="companyType"
                (change)="handleCompanyTypeChange()"
              >
                <mat-radio-button
                  class="d-block"
                  *ngFor="let type of corporateBusinessType"
                  [value]="type"
                >
                  <div class="d-flex align-items-center">
                    {{ 'company.' + CorporateBusinessType[type] | translate }}
                  </div>
                </mat-radio-button>
              </mat-radio-group>

              <app-validation-error-message
                [showFormError]="showFormError"
                [control]="form.get('companyType')"
              >
              </app-validation-error-message>
            </div>
          </ng-container>

          <!-- (Step 2) For corporate, issuer -->
          <ng-container *ngIf="role !== Role.IndividualInvestor">
            <div class="split-divider">
              <span
                class="split-divider-number"
                [ngClass]="role === Role.CompanyRep ? 'tw-bg-primary' : 'tw-bg-info-darkest'"
                >2</span
              >
            </div>

            <div class="input-panel">
              <label for="companyName" class="input-label asterisk">{{
                'company.registeredName' | translate
              }}</label>
              <input
                id="companyName"
                class="form-control"
                formControlName="companyName"
                type="text"
                maxlength="255"
                [placeholder]="'company.registeredNamePlaceholder' | translate"
                data-t-blur
              />
              <app-validation-error-message
                [inputName]="'company.registeredName' | translate"
                [showFormError]="showFormError"
                [control]="form.get('companyName')"
              >
              </app-validation-error-message>
            </div>

            <div class="input-panel">
              <label for="registrationNumber" class="input-label asterisk">{{
                'company.businessRegistrationNumber' | translate
              }}</label>
              <input
                id="registrationNumber"
                class="form-control"
                formControlName="registrationNumber"
                type="text"
                maxlength="255"
                [placeholder]="registrationNumberPlaceholder | translate"
                (blur)="handleRegistrationNumberBlur($event.target.value)"
                data-t-blur
              />
              <app-validation-error-message
                [inputName]="'company.businessRegistrationNumber' | translate"
                [showFormError]="showFormError"
                [control]="form.get('registrationNumber')"
              >
              </app-validation-error-message>
            </div>

            <div class="input-panel" *ngIf="role === Role.IssuerRep">
              <label for="brandName" class="input-label asterisk">{{
                'company.brandName' | translate
              }}</label>
              <input
                id="brandName"
                class="form-control"
                formControlName="brandName"
                type="text"
                maxlength="255"
                data-t-blur
              />
              <app-validation-error-message
                [inputName]="'company.brandName' | translate"
                [showFormError]="showFormError"
                [control]="form.get('brandName')"
              >
              </app-validation-error-message>
            </div>
          </ng-container>

          <!-- For all roles: fullname, email, password -->
          <div class="split-divider" *ngIf="role !== Role.IndividualInvestor">
            <span
              class="split-divider-number"
              [ngClass]="role === Role.CompanyRep ? 'tw-bg-primary' : 'tw-bg-info-darkest'"
              >3</span
            >
          </div>

          <div class="input-panel">
            <label
              for="fullName"
              class="input-label asterisk"
              *ngIf="role === Role.IndividualInvestor"
              >{{ 'common.fullName' | translate }}</label
            >
            <label
              for="fullName"
              class="input-label asterisk"
              *ngIf="role !== Role.IndividualInvestor"
              >{{
                'signUp.fullNameOf'
                  | translate: { role: role === Role.CompanyRep ? 'corporate' : 'issuer' }
              }}</label
            >
            <input
              id="fullName"
              class="form-control"
              formControlName="fullname"
              type="text"
              maxlength="255"
              [placeholder]="'signUp.fullNamePlaceholder' | translate"
              data-t-blur
            />
            <app-validation-error-message
              [inputName]="
                (role === Role.IndividualInvestor ? 'common.fullName' : 'signUp.fullNameOf')
                  | translate: { role: role === Role.CompanyRep ? 'corporate' : 'issuer' }
              "
              [showFormError]="showFormError"
              [control]="form.get('fullname')"
            >
            </app-validation-error-message>
          </div>

          <div class="input-panel">
            <label for="email" class="input-label asterisk">{{
              (role === Role.IndividualInvestor ? 'common.email' : 'common.workEmail') | translate
            }}</label>
            <input
              id="email"
              class="form-control"
              formControlName="email"
              type="email"
              maxlength="255"
              data-t-blur
            />
            <app-validation-error-message
              [inputName]="
                (role === Role.IndividualInvestor ? 'common.email' : 'common.workEmail') | translate
              "
              [showFormError]="showFormError"
              [control]="form.get('email')"
              [messageForInvalidFormat]="'signUp.emailFormatIncorrect'"
            >
            </app-validation-error-message>
          </div>

          <div class="input-panel">
            <label for="password" class="input-label asterisk">{{
              'login.password' | translate
            }}</label>
            <div class="password-panel">
              <input
                id="password"
                class="form-control"
                [attr.type]="showPassword ? 'text' : 'password'"
                formControlName="password"
                (focus)="onFocus()"
                (blur)="onBlur()"
                (keyup)="checkPassword()"
                maxlength="30"
                data-t-blur
              />
              <div class="show-password" (click)="showPassword = !showPassword">
                <i
                  class="fa"
                  [class.fa-eye-slash]="!showPassword"
                  [class.fa-eye]="showPassword"
                ></i>
              </div>
              <div class="dropdown-menu password-hint" *ngIf="showPasswordHint">
                <div class="d-flex">
                  <div
                    class="fa align-self-center mr-3"
                    [ngClass]="
                      atLeast8Characters ? 'fa-check text-success' : 'fa-times text-danger'
                    "
                  ></div>
                  <div>{{ 'signUp.atLeast8Characters' | translate }}</div>
                </div>
                <div class="d-flex">
                  <div
                    class="fa align-self-center mr-3"
                    [ngClass]="includesDigit ? 'fa-check text-success' : 'fa-times text-danger'"
                  ></div>
                  <div>{{ 'signUp.includesDigit' | translate }}</div>
                </div>
                <div class="d-flex">
                  <div
                    class="fa align-self-center mr-3"
                    [ngClass]="
                      includesUpperAndLowercase ? 'fa-check text-success' : 'fa-times text-danger'
                    "
                  ></div>
                  <div>{{ 'signUp.includesUpperAndLowercase' | translate }}</div>
                </div>
                <div class="d-flex">
                  <div
                    class="fa align-self-center mr-3"
                    [ngClass]="includesSymbol ? 'fa-check text-success' : 'fa-times text-danger'"
                  ></div>
                  <div>{{ 'signUp.includesSymbol' | translate }}</div>
                </div>
              </div>
            </div>
            <app-validation-error-message
              [inputName]="'login.password' | translate"
              [showFormError]="showFormError"
              [control]="form.get('password')"
              [messageForInvalidFormat]="'resetPassword.passwordPattern'"
            >
            </app-validation-error-message>
          </div>

          <div class="input-panel">
            <label for="confirmPassword" class="input-label asterisk">{{
              'signUp.confirmPassword' | translate
            }}</label>
            <div class="password-panel">
              <input
                id="confirmPassword"
                class="form-control"
                [attr.type]="showConfirmPassword ? 'text' : 'password'"
                formControlName="confirmPassword"
                maxlength="30"
                data-t-blur
              />
              <div class="show-password" (click)="showConfirmPassword = !showConfirmPassword">
                <i
                  class="fa"
                  [class.fa-eye-slash]="!showConfirmPassword"
                  [class.fa-eye]="showConfirmPassword"
                ></i>
              </div>
            </div>
            <app-validation-error-message
              [inputName]="'signUp.confirmPassword' | translate"
              [showFormError]="showFormError"
              [control]="form.get('confirmPassword')"
              [messageForNotMatch]="'resetPassword.passwordMismatch'"
            >
            </app-validation-error-message>
          </div>

          <div class="btn-panel tw-mt-1">
            <button type="submit" class="btn btn-green tw-w-full">
              {{ 'signUp.signUpCta' | translate }}
            </button>
          </div>
        </form>
        <div class="mb-4 mt-2 tw-text-xs tw-text-black">
          <span>
            {{
              (role == Role.IssuerRep ? 'signUp.agreeT&CProceeding' : 'signUp.agreeT&CSigning')
                | translate
            }}
            <a
              class="p-link-primary p-link-underline"
              [routerLink]="['/website-terms-conditions']"
              target="_blank"
              >{{ (role == Role.IssuerRep ? 'signUp.termOfService' : 'signUp.tnc') | translate }}</a
            >
            {{ 'common.and' | translate }}
            <a
              class="p-link-primary p-link-underline"
              [routerLink]="['/privacy-policy']"
              target="_blank"
              >{{ 'signUp.privacyPolicy' | translate }}</a
            >.
          </span>
        </div>
      </div>
      <!-- <ng-container *ngIf="role==Role.IndividualInvestor">
                <div class="sign-up-with mb-4 mt-5">
                    <span>{{ 'signUp.orSignUpWith' | translate }}</span>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="social-media">
                        <img alt="pitchIN Facebook Sign Up" src="../../../assets/img/icons/common/facebook_icon_circle@2x.png"
                            (click)="signInOAuth(OAuthType.Facebook)" />
                    </div>
                    <div class="social-media">
                        <img alt="pitchIN Twitter Sign Up" src="../../../assets/img/icons/common/twitter_icon_circle@2x.png"
                            (click)="signInOAuth(OAuthType.Twitter)" />
                    </div>
                    <div class="social-media">
                        <img alt="pitchIN Google Sign Up" src="../../../assets/img/icons/common/google_icon_circle@2x.png"
                            (click)="signInOAuth(OAuthType.Google)" />
                    </div>
                </div>
            </ng-container> -->
    </div>
  </div>
  <div class="row my-5" *ngIf="isSent">
    <div class="col-lg-12 text-center">
      <img
        class="email-img"
        alt="email"
        src="../../../../assets/img/icons/common/email_open_icon@2x.png"
      />
      <h2 class="font-weight-extrabold mb-4 mt-5">{{ 'signUp.verifyYourEmail' | translate }}</h2>
      <div>{{ 'signUp.emailSent' | translate: { email: form.value.email } }}</div>
      <div>{{ 'signUp.clickLinkOnEmail' | translate }}</div>
      <div class="text-primary font-weight-bold mt-4 cursor-pointer" (click)="resendEmail()">
        {{ 'signUp.resendEmail' | translate }}
      </div>
    </div>
  </div>
</app-signup-layout>
