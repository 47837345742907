<div class="row justify-content-center mb-5">
  <div class="col-12">
    <div class="d-flex justify-content-end action-wrap" *ngIf="showActions && !isEdit && isCreator">
      <div
        class="btn"
        (click)="viewDraft()"
        [class.btn-grey]="isPublished"
        [class.btn-primary]="!isPublished"
      >
        {{ (isPublished ? 'campaign.viewDraft' : 'campaign.viewPublished') | translate }}
      </div>
      <div
        class="btn btn-green"
        (click)="addUpdate()"
        *ngIf="
          !(campaignStatus === CampaignStatus.Success || campaignStatus === CampaignStatus.Fail)
        "
      >
        {{ 'campaign.postNewUpdate' | translate }}
      </div>
    </div>
    <ng-container *ngIf="!isEdit">
      <ul class="timeline" *ngIf="!isLoading">
        <li class="timeline-item" *ngFor="let update of updates; let i = index">
          <div class="left">
            <div class="text-green font-weight-bold font-size-larger">
              {{ (isPublished ? update.publishedAt : update.createdAt) | amDateFormat: 'D MMM' }}
            </div>
            <div class="year">
              {{ (isPublished ? update.publishedAt : update.createdAt) | amDateFormat: 'YYYY' }}
            </div>
            <div class="profile d-flex justify-content-end mt-3">
              <img
                class="profile-image"
                [alt]="update.creator?.fullName"
                [src]="update.creator?.photoAttachmentUrl || DefaultProfileImage"
              />
            </div>
          </div>
          <div class="right">
            <div class="d-flex mb-3 align-items-center">
              <div class="font-weight-bold text-black update-title w-100">{{ update.title }}</div>
              <div class="action-panel d-flex" *ngIf="showActions && isCreator">
                <div
                  class="pitchin-icon pitchin-icon-edit-black mr-3"
                  (click)="editUpdates(update)"
                ></div>
                <div
                  class="pitchin-icon pitchin-icon-delete"
                  (click)="deleteUpdates(update.id)"
                ></div>
              </div>
            </div>
            <ng-container>
              <div
                class="description mb-3 ck-content content-display"
                [innerHTML]="update.content | trustSrc"
              ></div>
              <div class="d-flex flex-wrap">
                <div class="time">
                  {{ transformDate(update.modifiedAt) }} {{ 'common.by' | translate }}
                  <span class="creator">{{ update.creator?.fullName }}</span>
                </div>
                <div class="role" *ngIf="update.isCreator">
                  {{ 'campaign.creator' | translate }}
                </div>
              </div>
            </ng-container>
          </div>
        </li>
      </ul>
      <div class="row">
        <div class="col-12" *ngIf="isLoading">
          <agmo-loading></agmo-loading>
        </div>
        <div
          class="col-12 py-5 text-center"
          *ngIf="!isLoading && (!updates || updates.length == 0)"
        >
          <app-empty-state [message]="'common.noUpdate'"></app-empty-state>
        </div>
        <div class="col-12 d-flex my-3 p-0" *ngIf="updates && total > updates.length">
          <div class="view-more" (click)="loadMore()">
            {{ 'common.viewMore' | translate }}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isEdit">
      <div class="row mb-4">
        <div class="col-12">
          <h4 class="font-weight-extrabold">{{ 'campaign.postNewUpdate' | translate }}</h4>
        </div>
      </div>
      <form
        (ngSubmit)="submit(true)"
        [formGroup]="form"
        [class.show-form-error]="showFormError"
        *ngIf="form && ckEditorLoaded"
      >
        <div class="row">
          <div class="col-lg-12 input-panel">
            <div class="input-label">{{ 'campaign.updateTitle' | translate }}</div>
            <div class="input-field">
              <input
                class="form-control"
                formControlName="title"
                type="text"
                [placeholder]="'campaign.updateTitlePlaceholder' | translate"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 input-panel" id="content">
            <div class="input-label">{{ 'campaign.updateContent' | translate }}</div>
            <div class="input-field">
              <ckeditor
                class="ck-editor"
                formControlName="content"
                (focus)="removeRichText($event)"
                [editor]="Editor"
                [config]="ckEditorConfig"
              ></ckeditor>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-6 col-md-4 col-12 input-panel d-flex">
            <div class="btn-cancel" (click)="cancel()">{{ 'common.cancel' | translate }}</div>
          </div>
          <div class="col-lg-3 col-md-4 col-6 input-panel text-right">
            <div class="btn btn-grey w-100" (click)="submit(false)">
              {{ 'common.saveAsDraft' | translate }}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-6 input-panel text-right">
            <button class="btn btn-green w-100" type="submit">
              {{ 'common.submit' | translate }}
            </button>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</div>
