import { CompanyLite } from '../company/company-lite';
import { OrderSummary } from './order-summary';
import { TransactionListModel } from './transaction-list.model';
import { WalletTransaction } from './wallet-transaction';

export class TransactionList {
  company: CompanyLite;
  orderSummary: OrderSummary;
  walletTransactionHistory: WalletTransaction;

  constructor(model: TransactionListModel) {
    this.company = model.company ? new CompanyLite(model.company) : null;
    this.orderSummary = model.orderSummary ? new OrderSummary(model.orderSummary) : null;
    this.walletTransactionHistory = model.walletTransactionHistory
      ? new WalletTransaction(model.walletTransactionHistory)
      : null;
  }
}
