<div class="tw-flex" [ngClass]="size === 'sm' ? 'tw-gap-2.5' : 'tw-gap-5'">
  <div
    *ngIf="showImage"
    class="p-skeleton-image tw-mt-1 tw-flex-shrink-0"
    [ngClass]="size === 'sm' ? 'tw-h-10 tw-w-10' : 'tw-h-20 tw-w-20'"
  >
    <img
      class="logo-image tw-flex-shrink-0 !tw-object-cover"
      [ngClass]="
        size === 'sm'
          ? 'tw-h-10 tw-w-10 tw-border tw-border-solid tw-border-divider !tw-shadow-none'
          : ''
      "
      [alt]="name"
      [src]="imageUrl || defaultLogo"
    />
  </div>

  <div class="tw-w-full">
    <!-- Pop up thumbnail -->
    <ng-container *ngIf="size === 'sm'">
      <div class="tw-flex tw-gap-2.5 max-md:tw-flex-wrap md:tw-gap-5">
        <div class="tw-w-1/2">
          <div
            *ngIf="companyName"
            class="p-skeleton-bar company-name tw-line-clamp-1 tw-font-bold tw-text-black"
            [ngClass]="nameClass"
          >
            {{ companyName }}
          </div>
          <div *ngIf="companyDescription">
            <div
              class="p-skeleton-bar tw-text-body tw-text-opacity-70"
              [ngClass]="descriptionClass"
            >
              {{ companyDescription }}
            </div>
          </div>
        </div>
        <div class="tw-w-1/2">
          <div
            *ngIf="counterName"
            class="p-skeleton-bar company-name tw-line-clamp-1 tw-font-bold tw-text-black"
            [ngClass]="nameClass"
          >
            {{ counterName }}
          </div>
          <div *ngIf="counterDescription">
            <div
              class="p-skeleton-bar tw-break-all tw-text-body tw-text-opacity-70"
              [ngClass]="descriptionClass"
            >
              {{ counterDescription }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
