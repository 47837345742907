import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { finalize } from 'rxjs';
import { CampaignHistoryInfo } from '../../../core/models/business-directory/campaign-history-info';
import { Campaign } from '../../../core/models/campaign/campaign';
import { BusinessDirectoryService } from '../../../core/services/api/business-directory.service';
import { ErrorService } from '../../../core/services/error.service';

@Component({
  selector: 'campaign-histories',
  templateUrl: './campaign-histories.component.html',
  styleUrls: ['./campaign-histories.component.scss'],
})
export class CampaignHistoriesComponent implements OnChanges {
  @Input() slug: string;
  @Input() campaign: Campaign;

  isLoading: boolean = true;
  campaignHistories: CampaignHistoryInfo[] = [];
  total: number = 0;
  params = {
    currentPage: 0,
    take: 30,
  };

  constructor(
    private businessDirectoryService: BusinessDirectoryService,
    private errorService: ErrorService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['campaign']) {
      this.getCampaignHistories();
    }
  }

  getCampaignHistories() {
    this.isLoading = true;

    this.businessDirectoryService
      .getCampaignHistory(this.slug, this.params.currentPage, this.params.take)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          // Exclude current campaign
          this.campaignHistories = data.data.filter((item) => item.id !== this.campaign.id);

          this.total = data?.total - 1; // exclude current ones
        },
        error: (err) => {
          // Some companies may not have the details, just skip the error
          if (err?.error?.code === 'ERR_COMPANY_NOT_FOUND') return;

          this.errorService.showError(err);
        },
      });
  }

  onPageChange(page: number) {
    // Remove two ways binding from `[page]` to avoid double api calls
    // So has to update the `currentPage` manually
    if (page !== this.params.currentPage) {
      this.params.currentPage = page;
      this.getCampaignHistories();
    }
  }
}
