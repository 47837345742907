<header
  class="headroom headroom--top headroom--pinned tw-w-full tw-bg-white"
  [ngClass]="{ opened: !isCollapsed }"
>
  <div class="tw-bg-white">
    <div
      class="container-fluid tw-flex tw-flex-wrap tw-items-center md:tw-flex-nowrap lg:tw-items-stretch"
    >
      <a
        routerLink="/"
        class="tw-relative tw-mr-4 tw-inline-block tw-shrink-0 tw-py-0.5"
        aria-label="pitchIN Homepage"
      >
        <div class="pitchin-icon pitchin-icon-app tw-h-[4.5rem] tw-w-20 tw-py-2.5"></div>
        <div
          *ngIf="isDevelopmentMode"
          class="tw-absolute -tw-right-2 tw-top-2 tw-rounded-full tw-border-[3px] tw-border-white tw-bg-primary tw-p-1 tw-text-[10px] tw-font-semibold tw-text-white"
        >
          {{ environment }}
        </div>
      </a>

      <button
        class="hamburger tw-ml-auto lg:!tw-hidden"
        type="button"
        (click)="isCollapsed = !isCollapsed"
        [attr.aria-expanded]="!isCollapsed"
        aria-controls="navbar_global"
      >
        <span></span>
        <span></span>
        <span></span>
      </button>

      <div class="tw-hidden lg:tw-flex lg:tw-flex-1">
        <ng-container *ngTemplateOutlet="navbar"></ng-container>
      </div>
    </div>
  </div>

  <div
    *ngIf="showNotificationBar"
    class="tw-bg-[#ffc830] tw-py-1.5 tw-text-center tw-text-sm tw-font-medium tw-text-body-dark"
  >
    <div class="container-fluid">
      {{ 'announcementContent' | translate }}
    </div>
  </div>
</header>

<div class="lg:tw-hidden">
  <ng-container *ngTemplateOutlet="navbar"></ng-container>
</div>

<ng-template #navbar>
  <nav
    class="header__nav-wrapper tw-mx-auto tw-flex tw-w-full lg:tw-justify-between"
    (touchmove)="disableSlide($event)"
    [ngClass]="{ opened: !isCollapsed }"
  >
    <div class="nav-border tw-border-0 tw-border-solid tw-border-b-divider">
      <ul class="tw-h-full tw-w-full lg:tw-flex lg:tw-items-center lg:tw-pl-5">
        <ng-container *ngFor="let menu of menu.mainMenu">
          <li
            *ngIf="!menu.show || menu.show(role)"
            class="header__nav-item dropdown--mouseover"
            [class.active]="isActiveMenu(menu.url) || isActiveSubmenu(menu.submenus)"
            ngbDropdown
            #dropdownRef
          >
            <ng-container *ngIf="menu.submenus">
              <a
                (click)="clearServiceFilter(menu.submenus && menu.submenus.length > 0)"
                ngbDropdownToggle
              >
                {{ menu.name | translate }}
                <i
                  class="fa fa-caret-down d-none d-lg-inline-block my-auto tw-ml-2"
                  *ngIf="!menu.icon && menu.submenus"
                ></i>
                <i
                  class="fa fa-caret-down ml-2 d-lg-none pull-right my-auto"
                  *ngIf="menu.submenus"
                ></i>
              </a>
            </ng-container>
            <ng-container *ngIf="!menu.submenus">
              <a [routerLink]="[menu.url]" (click)="clearServiceFilter(false)">
                {{ menu.name | translate }}
              </a>
            </ng-container>
            <div
              class="dropdown-menu dropdown-menu--mobile-expand"
              ngbDropdownMenu
              *ngIf="menu.submenus"
            >
              <ng-container *ngFor="let sub of menu.submenus">
                <ng-container *ngIf="!sub.show || sub.show(role)">
                  <ng-container *ngIf="sub.isExternal; else internalLinkTemplate">
                    <a
                      class="dropdown-item font-size-regular py-3 lg:!tw-py-2.5"
                      [href]="sub.url"
                      (click)="clearServiceFilter(false)"
                      [target]="sub.isOpenNewTab ? '_blank' : '_self'"
                    >
                      <ng-container
                        *ngTemplateOutlet="linkTemplate; context: { sub: sub }"
                      ></ng-container>
                    </a>
                  </ng-container>
                </ng-container>

                <ng-template #internalLinkTemplate>
                  <a
                    class="dropdown-item font-size-regular py-3 lg:!tw-py-2.5"
                    [routerLink]="sub.url"
                    [class.active]="isActiveMenu(sub.url, true)"
                    (click)="clearServiceFilter(false)"
                  >
                    <ng-container
                      *ngTemplateOutlet="linkTemplate; context: { sub: sub }"
                    ></ng-container>
                  </a>
                </ng-template>

                <ng-template #linkTemplate let-sub="sub">
                  <div class="tw-flex tw-items-center tw-gap-3 tw-pr-1.5">
                    <div class="tw-flex">
                      <i
                        class="ni pitchin-icon {{ sub?.icon }} tw-w-10 tw-h-10"
                        *ngIf="sub.icon"
                      ></i>
                    </div>
                    <div class="tw-whitespace-nowrap tw-leading-tight">
                      <div>{{ sub?.name | translate }}</div>
                      <small class="tw-opacity-80">{{ sub?.description | translate }}</small>
                    </div>
                  </div>
                </ng-template>
              </ng-container>

              <div class="dropdown-divider"></div>

              <a
                class="dropdown-item font-size-smaller cursor-pointer !lg:tw-font-medium"
                *ngFor="let footermenu of menu.footermenus"
                [routerLink]="footermenu.url"
                [class.active]="isActiveMenu(footermenu.url, true)"
                (click)="clearServiceFilter(false)"
              >
                <div class="tw-flex tw-items-center tw-gap-3 tw-pr-1.5">
                  {{ footermenu.name | translate }}
                </div>
              </a>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>

    <div class="navbar-list--right tw-pl-auto">
      <ul class="tw-h-full tw-w-full lg:tw-flex lg:tw-items-center">
        <ng-container *ngIf="profile || registerInProgress; then showProfile; else hideProfile">
        </ng-container>

        <ng-template #showProfile>
          <!-- Notification starts -->
          <li
            class="header__nav-item header__nav-item--notification dropdown"
            ngbDropdown
            #notificationDropdown
            *ngIf="notifications?.length > 0"
          >
            <a
              class="no-caret header__dot tw-block tw-cursor-pointer"
              role="button"
              ngbDropdownToggle
            >
              <i class="pitchin-icon pitchin-icon-notification tw-hidden lg:tw-block"></i>
              <span class="header__dot-indicator dot" *ngIf="incomingNotification"></span>
              <span class="d-lg-none">
                {{ 'common.notifications' | translate }}
              </span>
            </a>
            <div
              class="dropdown-menu notification-panel"
              ngbDropdownMenu
              infiniteScroll
              [infiniteScrollDistance]="1"
              (scrolled)="onNotificationScroll()"
              [scrollWindow]="false"
            >
              <div class="d-flex align-items-center tw-px-4">
                <a
                  class="text-primary w-50 tw-cursor-pointer !tw-px-0"
                  role="button"
                  ngbDropdownToggle
                >
                  <i class="back-icon mr-3"></i>

                  {{ 'common.notifications' | translate }}
                </a>
                <div class="w-50 text-right cursor-pointer tw-text-xs" (click)="markAllIsRead()">
                  {{ 'common.markAllAsRead' | translate }}
                </div>
              </div>
              <div *ngIf="notifications?.length > 0">
                <div
                  class="dropdown-item d-flex cursor-pointer"
                  *ngFor="let notification of notifications"
                >
                  <div
                    class="notification-left position-relative"
                    (click)="markIsRead(notification)"
                  >
                    <div class="dot" *ngIf="!notification.isRead"></div>
                    <div [class.font-weight-bold]="!notification.isRead">
                      {{ notification.notification.title }}
                    </div>
                    <div class="notification-date">
                      {{ notification.notificationTimeAgo }}
                    </div>
                  </div>
                  <div class="notification-right pt-1">
                    <div
                      class="pitchin-icon pitchin-icon-delete"
                      (click)="deleteNotification(notification.id)"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="dropdown-item" *ngIf="notifications?.length == 0">
                {{ 'common.noNotifications' | translate }}
              </div>
            </div>
          </li>

          <!-- Profile after logged in -->
          <!---Management Menu--->
          <li
            class="header__nav-item header__nav-item--management-lg dropdown--mouseover"
            ngbDropdown
            placement="bottom-end"
            *ngIf="profile && !registerInProgress"
            #managementDropdown
            [class.active]="isActiveMenu('/dashboard')"
          >
            <!-- PLT-1358 Dashboard for investor without dropdown -->
            <a *ngIf="role !== Role.IssuerRep" [routerLink]="['dashboard/portfolio']">
              {{ 'Dashboard' | translate }}
            </a>
            <a
              *ngIf="role === Role.IssuerRep"
              class="nav-link no-caret header__dot tw-cursor-default lg:tw-cursor-pointer"
              ngbDropdownToggle
            >
              {{ 'Management' | translate }}
              <i class="fa fa-caret-down d-none d-lg-inline-block my-auto tw-ml-2"></i>
            </a>

            <div class="dropdown-menu-right tw-py-0" ngbDropdownMenu>
              <div class="tw-pb-6 tw-pt-4">
                <ng-container *ngIf="profile && !registerInProgress">
                  <div
                    class="dropdown-row !tw-px-0"
                    [ngClass]="
                      role == Role.IssuerRep ? 'issuer-menu-dropdown' : 'investor-menu-dropdown'
                    "
                  >
                    <ng-container *ngFor="let menu of menu.managementMenu">
                      <div
                        class="dropdown-col dropdown-menu-section tw-basis-1/2"
                        *ngIf="!menu.show || menu.show(role)"
                      >
                        <div class="tw-px-5 tw-py-2">
                          <div class="main-title p-text-preheader" [ngClass]="menu.cssClass">
                            {{ menu.title | translate }}
                          </div>
                          <div class="sub-title">{{ menu.secondTitle | translate }}</div>
                          <div class="underline tw-mb-1 tw-mt-4"></div>
                        </div>
                        <div
                          *ngIf="menu.submenus"
                          class="dropdown-menu-sub-wrapper tw-flex tw-flex-col"
                        >
                          <div
                            class="dropdown-sub-col !tw-w-full"
                            *ngFor="let submenu of menu.submenus"
                          >
                            <ng-container *ngIf="submenu?.submenus; else normalMenuLink">
                              <ng-container *ngIf="!submenu.show || submenu.show(role)">
                                <span
                                  class="tw-ml-5 tw-text-xs tw-font-semibold tw-uppercase tw-text-gray-400"
                                  >{{ submenu.title | translate }}</span
                                >
                                <div *ngFor="let nestedSubmenu of submenu.submenus">
                                  <ng-template
                                    *ngTemplateOutlet="
                                      menuLink;
                                      context: { submenu: nestedSubmenu }
                                    "
                                  ></ng-template>
                                </div>
                              </ng-container>
                            </ng-container>

                            <ng-template #normalMenuLink>
                              <ng-template
                                *ngTemplateOutlet="menuLink; context: { submenu: submenu }"
                              ></ng-template>
                            </ng-template>
                            <!-- <ng-container *ngIf="!submenu.show || submenu.show(role)">
                                                                <a *ngIf="!submenu.isExternal"
                                                                    class="dropdown-item tw-py-1 tw-px-5 "
                                                                    [routerLink]="submenu.route"
                                                                    routerLinkActive="active"
                                                                    (click)="clearServiceFilter(false)">
                                                                    <div class="tw-w-full tw-whitespace-normal" [innerHTML]="submenu.title | translate"></div>
                                                                </a>
                                                                <a *ngIf="submenu.isExternal"
                                                                    class="dropdown-item tw-py-1 tw-px-5"
                                                                    [href]="submenu.url">
                                                                    <div class="tw-w-full tw-whitespace-normal" [innerHTML]="submenu.title | translate"></div>
                                                                </a>
                                                            </ng-container> -->
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>
          </li>
          <li
            class="header__nav-item header__nav-item--profile dropdown--mouseover d-none d-lg-block"
            ngbDropdown
            #profileDropdown
          >
            <a
              class="nav-link no-caret header__dot tw-cursor-default lg:tw-cursor-pointer"
              role="button"
              ngbDropdownToggle
            >
              <span
                *ngIf="(profile && profile.ekycStatus != EkycStatus.Verified) || registerInProgress"
                class="header__dot-indicator dot tw-hidden lg:tw-block"
              ></span>
              <img
                class="profile-image"
                alt="pitchIN User"
                [src]="(profile && profile.photoAttachmentUrl) || defaultProfileImage"
              />
            </a>
            <div class="dropdown-menu-right !tw-min-w-[350px] lg:tw-pt-0" ngbDropdownMenu>
              <ng-container *ngTemplateOutlet="profilePanel"></ng-container>
              <ul class="lg:tw-pt-2.5">
                <!-- Avoid showing the menu when registration still in progress, as require profile to complete before verify ekyc -->
                <ng-container *ngTemplateOutlet="profileMenu"></ng-container>
              </ul>
            </div>
          </li>
          <li class="header__nav-item dropdown--mouseover lg:!tw-hidden" ngbDropdown>
            <div class="lg:tw-pt-0" ngbDropdownToggle>
              <ng-container *ngTemplateOutlet="profilePanel"></ng-container>
            </div>
            <ul
              class="dropdown-menu dropdown-menu--mobile-expand dropdown-menu-right lg:tw-pt-2.5"
              ngbDropdownMenu
            >
              <!-- Avoid showing the menu when registration still in progress, as require profile to complete before verify ekyc -->
              <ng-container *ngTemplateOutlet="profileMenu"></ng-container>
            </ul>
          </li>
          <li
            class="header__nav-item dropdown--mouseover tw-border-0 tw-border-b tw-border-solid tw-border-b-divider lg:!tw-hidden"
            ngbDropdown
            *ngIf="profile && !registerInProgress"
            #managementDropdown
          >
            <!-- PLT-1358 Dashboard for investor without dropdown -->
            <a
              *ngIf="role !== Role.IssuerRep"
              [routerLink]="['dashboard/portfolio']"
              (click)="clearServiceFilter(false)"
            >
              {{ 'Dashboard' | translate }}
            </a>
            <a
              *ngIf="role === Role.IssuerRep"
              class="nav-link no-caret header__dot tw-cursor-default lg:tw-cursor-pointer"
              ngbDropdownToggle
            >
              {{ 'Management' | translate }}
              <i class="fa fa-caret-down ml-2 pull-right my-auto"></i>
            </a>
            <div
              class="dropdown-menu dropdown-menu--mobile-expand dropdown-menu-right"
              ngbDropdownMenu
            >
              <div class="menu-list tw-py-2 lg:tw-pb-0 lg:tw-pt-2.5">
                <ng-container *ngIf="profile && !registerInProgress">
                  <ng-container *ngFor="let menu of menu.managementMenu">
                    <div *ngIf="!menu.show || menu.show(role)" class="menu-item">
                      <p class="main-title pl-4 tw-pt-2" [ngClass]="menu.cssClass">
                        {{ menu.title | translate }}
                      </p>
                      <p class="sub-title pl-4">{{ menu.secondTitle | translate }}</p>
                      <div *ngFor="let submenu of menu.submenus">
                        <ng-container *ngIf="!submenu.show || submenu.show(role)">
                          <ng-container *ngIf="submenu?.submenus; else normalMenuLinkMobile">
                            <span
                              class="tw-ml-5 tw-text-xs tw-font-semibold tw-uppercase tw-text-gray-400"
                              >{{ submenu.title | translate }}</span
                            >
                            <div *ngFor="let nestedSubmenu of submenu.submenus">
                              <ng-template
                                *ngTemplateOutlet="
                                  menuLinkMobile;
                                  context: { submenu: nestedSubmenu }
                                "
                              ></ng-template>
                            </div>
                          </ng-container>

                          <ng-template #normalMenuLinkMobile>
                            <ng-template
                              *ngTemplateOutlet="menuLinkMobile; context: { submenu: submenu }"
                            ></ng-template>
                          </ng-template>
                        </ng-container>
                      </div>

                      <!-- <ng-container *ngFor="let submenu of menu.submenus">
                                                <ng-container *ngIf="submenu?.submenus; else normalMenuLink">
                                                    <ng-container *ngFor="let submenu of submenu.submenus">
                                                        
                                                        <ng-container *ngFor="let nestedSubmenu of submenu">
                                                            <a class="dropdown-item dropdown-sub-item"
                                                                *ngIf="!nestedSubmenu.show || nestedSubmenu.show(role)"
                                                                [routerLink]="nestedSubmenu.route" routerLinkActive="active"
                                                                (click)="clearServiceFilter(false)"
                                                                [innerHTML]="nestedSubmenu.title | translate">
                                                                
                                                            </a>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container> -->
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </li>
        </ng-template>

        <!-- Login and register buttons -->
        <ng-template #hideProfile>
          <li class="header__nav-item">
            <a
              role="button"
              href="/login"
              (click)="handleLoginRedirection($event); clearServiceFilter(false)"
            >
              {{ 'common.login' | translate }}
            </a>
          </li>
          <li class="header__nav-item header__nav-item--highlight">
            <a
              role="button"
              routerLink="/sign-up"
              (click)="clearServiceFilter(false)"
              data-t="signUpClick"
              data-t-location="navbar"
            >
              {{ 'common.signUp' | translate }}
            </a>
          </li>
        </ng-template>
        <ng-template #profilePanel>
          <a
            class="dropdown-item !tw-flex tw-shrink-0 tw-gap-2 tw-rounded-tl tw-rounded-tr tw-border-0 tw-border-b tw-border-solid tw-border-b-divider !tw-pb-5 !tw-pt-4 hover:tw-bg-dropdown-hover"
            [routerLink]="registerInProgress ? signUpLink : '/profile/settings'"
            (click)="clearServiceFilter(true)"
          >
            <img
              class="profile-image tw-w-5"
              alt="Profile Image"
              [src]="(profile && profile.photoAttachmentUrl) || defaultProfileImage"
            />
            <div class="tw-text-body-dark">
              <div *ngIf="role" class="tw-text-xs tw-font-light tw-text-gray-400">
                {{ 'common.' + Role[role] | translate }}
              </div>
              <div
                *ngIf="profile && profile.fullName"
                class="text-break word-spacing tw-text-base tw-font-bold"
              >
                {{ profile.fullName }}
              </div>
              <div *ngIf="profile && profile.email" class="text-break tw-text-sm tw-font-medium">
                {{ profile.email }}
              </div>
            </div>
            <i class="fa fa-caret-down tw-ml-auto tw-self-center lg:tw-hidden"></i>
          </a>
        </ng-template>
        <ng-template #profileMenu>
          <li>
            <a
              *ngIf="
                profile &&
                !(
                  profile?.ekycStatus === EkycStatus.Verified ||
                  profile?.ekycStatus === EkycStatus.NotRequired
                ) &&
                !registerInProgress
              "
              class="dropdown-item highlighted"
              routerLink="/proceed-e-kyc"
              (click)="clearServiceFilter(false)"
            >
              {{ 'settings.completeEKyc' | translate }}
            </a>
          </li>
          <ng-container *ngIf="profile && !registerInProgress">
            <ng-container *ngFor="let menu of menu.tabMenu">
              <ng-container *ngIf="!menu.show || menu.show(role)">
                <li
                  class="tw-flex tw-items-center tw-justify-between tw-gap-2.5"
                  [ngClass]="role != Role.IssuerRep ? menu.cssClass : ''"
                >
                  <!-- Show topup options for pstx -->
                  <ng-container *ngIf="menu.name === 'settings.pstxWallet'; else normalLink">
                    <a
                      class="dropdown-item !tw-flex tw-items-center tw-justify-between tw-gap-2 active:!tw-text-black"
                      [routerLink]="['/pstx', 'account']"
                      [queryParams]="wallet?.balance === 0 ? { 'topup': true } : {}"
                      (click)="closeDropdown(); clearServiceFilter(false)"
                    >
                      <span>{{ menu.name | translate }}</span>
                      <span *ngIf="!isWalletFound" class="topup-btn topup-btn--info">
                        {{ 'mysec.activateWallet' | translate }}
                      </span>
                      <span
                        *ngIf="isWalletFound && wallet?.status"
                        class="topup-btn topup-btn--success"
                      >
                        <span *ngIf="wallet?.balance !== 0; else topup">
                          {{ wallet?.balance | numberSuffix: { numberFormat: '1.2-2' } }}
                        </span>
                        <ng-template #topup>
                          {{ 'mysec.TopUp' | translate }}
                        </ng-template>
                      </span>
                    </a>
                  </ng-container>

                  <ng-template #normalLink>
                    <a
                      *ngIf="!menu.isExternal"
                      class="dropdown-item"
                      [routerLink]="menu.route"
                      (click)="clearServiceFilter(false)"
                    >
                      {{ menu.name | translate }}
                    </a>
                    <a *ngIf="menu.isExternal" class="dropdown-item" [href]="menu.url">
                      {{ menu.name | translate }}
                    </a>
                  </ng-template>
                </li>
              </ng-container>
            </ng-container>
          </ng-container>
          <li>
            <a class="dropdown-item tw-cursor-pointer" (click)="logout()">
              {{ 'common.logout' | translate }}
            </a>
          </li>
        </ng-template>
      </ul>
    </div>
  </nav>
</ng-template>

<ng-template #menuLink let-submenu="submenu">
  <ng-container *ngIf="!submenu.show || submenu.show(role)">
    <a
      *ngIf="!submenu.isExternal"
      class="dropdown-item tw-px-5 tw-py-1"
      [routerLink]="submenu.route"
      routerLinkActive="active"
      (click)="clearServiceFilter(false)"
    >
      <div class="tw-w-full tw-whitespace-normal" [innerHTML]="submenu.title | translate"></div>
    </a>
    <a *ngIf="submenu.isExternal" class="dropdown-item tw-px-5 tw-py-1" [href]="submenu.url">
      <div class="tw-w-full tw-whitespace-normal" [innerHTML]="submenu.title | translate"></div>
    </a>
  </ng-container>
</ng-template>

<ng-template #menuLinkMobile let-submenu="submenu">
  <ng-container *ngIf="!submenu.show || submenu.show(role)">
    <a
      class="dropdown-item dropdown-sub-item"
      *ngIf="!submenu.show || submenu.show(role)"
      [routerLink]="submenu.route"
      routerLinkActive="active"
      (click)="clearServiceFilter(false)"
      [innerHTML]="submenu.title | translate"
    >
    </a>
  </ng-container>
</ng-template>

<div
  *ngIf="isDevelopmentMode"
  class="devmode-bar tw-fixed tw-left-0 tw-top-0 tw-z-[8] tw-w-[100vw] tw-bg-primary tw-py-1 tw-text-center tw-text-xs tw-font-bold tw-text-white"
>
  You are currently in {{ environment }} environment
</div>
