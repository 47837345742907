import { Injectable } from '@angular/core';
import { debounceTime, fromEvent, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvalidControlScrollService {
  scrollToFirstInvalidControl() {
    const field: HTMLElement = document.querySelector('form [formcontrolname].ng-invalid');

    this.scrollToElement(field);
  }

  scrollToFormControl(formControlName: string) {
    const field: HTMLElement = document.querySelector(
      "form [formcontrolname='" + formControlName + "']",
    );

    this.scrollToElement(field);
  }

  scrollToElement(element: HTMLElement) {
    window.scroll({
      top: this.getTopOffset(element),
      left: 0,
      behavior: 'smooth',
    });

    if (!element) return;

    fromEvent(window, 'scroll')
      .pipe(debounceTime(100), take(1))
      .subscribe(() => element.focus());
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 150;
    return controlEl?.getBoundingClientRect().top + window.scrollY - labelOffset;
  }
}
