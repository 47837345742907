import { Injectable } from '@angular/core';
import { MyApplicationFilter } from './my-application-filter';

@Injectable({
  providedIn: 'root',
})
export class MyApplicationFilterService {
  filter: MyApplicationFilter;

  clear() {
    this.filter = new MyApplicationFilter();
  }
}
