import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TwoFactorSecretResponse } from '../../models/two-factor-auth/secret-responce';
import { BackupCodeResponse } from '../../models/two-factor-auth/backup-code-response';

@Injectable({ providedIn: 'root' })
export class TwoFactorservice {
  private readonly route = environment.apiServer + 'Api/v1/Users/{role}';

  constructor(private readonly http: HttpClient) {}

  getTwoFactorSecret() {
    return this.http.get(this.route + '/signUpFlow/setup2FASecret').pipe(
      map((data: TwoFactorSecretResponse) => {
        return data.result;
      }),
    );
  }

  verifyTwoFactor(code: string) {
    const params = {
      code,
    };

    return this.http.get(this.route + '/signUpFlow/verify2FACode', { params }).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getBackupCodes() {
    return this.http.get(this.route + '/signUpFlow/get2FABackupCodes').pipe(
      map((data: BackupCodeResponse) => {
        return data.result;
      }),
    );
  }
  removeTwoFactor(code: string) {
    const params = {
      code,
    };
    return this.http.delete(this.route + '/signUpFlow/remove2FASecret', { params: params }).pipe(
      map((data: BackupCodeResponse) => {
        return data.result;
      }),
    );
  }
}
